import { Breadcrumbs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link, withRouter,useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    div: {
        backgroundColor: "#fff",
        padding: '15px 13px',
        margin: '-5px -10px 10px -10px'
    },
}));

function ContractsV2Breadcrumbs({ breadcrumbItems, currentDirectory, selectContractsIds, handleRefresh,
    actions: { moveDirectoryOrContracts, resetSelectedContracts } }) {
    const classes = useStyles();
    const currentLocation = useLocation().pathname;
    const isContractsDetailsPage=currentLocation.includes('/cms/contract/edit') 
    const isContractsViewPage=currentLocation.includes("cms/contract/view")


    const handleDrop = (e) => {
        e.preventDefault();
        const droppedItems = JSON.parse(e.dataTransfer.getData('items'));
        onDrop(droppedItems, e.target.id);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const onDrop = (droppedItems, folderId) => {
        const destinationDirectoryId = folderId.split('folder-')[1];
        let contractIds = [];
        let directoryIds = [];
        if (selectContractsIds.length > 0) {
            for (let item of selectContractsIds) {
                const splittedItem = item.split('-');
                if (splittedItem[0] === 'contracts') {
                    contractIds.push(splittedItem[1]);
                } else if (splittedItem[0] === 'folder') {
                    directoryIds.push(splittedItem[1])
                }
            }
        } else {
            for (let item of droppedItems) {
                const splittedItem = item.split('-');
                if (splittedItem[0] === 'contracts') {
                    contractIds.push(splittedItem[1]);
                } else if (splittedItem[0] === 'folder') {
                    directoryIds.push(splittedItem[1])
                }
            }
        }
        const payload = {
            contractIds,
            directoryIds,
            destinationDirectoryId: destinationDirectoryId === 'root' ? undefined : destinationDirectoryId
        }
        moveDirectoryOrContracts(payload, handleCallback);
    };

    const handleCallback = () => {
        handleRefresh();
        resetSelectedContracts();
    }

    const getBreadCrumbsItems = () => { 
        if(!isContractsDetailsPage && !isContractsViewPage) return breadcrumbItems
        if(!currentDirectory) return []
        else return [...breadcrumbItems,{id:currentDirectory?.parentId,name:currentDirectory?.parentName}]
    }

    return (
        <>
            <div className={`${classes.div} bread-crumb`}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                    <Link id={`folder-root`} onDragOver={handleDragOver} onDrop={handleDrop} className='breadcrumb-link' to={`/cms/contracts/root`} color="inherit" >
                        Contracts
                    </Link>
                    {getBreadCrumbsItems().map((breadcrumb, index) =>
                        <Link id={`folder-${breadcrumb.id}`} onDragOver={handleDragOver} onDrop={handleDrop} className='breadcrumb-link' to={`/cms/contracts/${breadcrumb.id}`} key={index.toString()}  color="inherit" >
                            {breadcrumb.name}
                        </Link>
                    )}
                    {!isContractsDetailsPage && !isContractsViewPage && currentDirectory &&
                        <Typography className='breadcrumb-active' >{currentDirectory.parentName}</Typography>
                    }


                    {isContractsDetailsPage &&
                        <Typography className='breadcrumb-active' >Edit Contract</Typography>
                    }

                    {isContractsViewPage && 
                        <Typography className='breadcrumb-active'>Contract Details</Typography>
                    }

                </Breadcrumbs>
            </div>
        </>
    )
}

export default withRouter(ContractsV2Breadcrumbs as any)