import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/payers';
import * as API from '../api/payers';
import { sessionErrorHandling } from './session';

function* getPayers(action) {
    try {
        yield put({ type: actions.PAYERS_PENDING });
        const payload = yield call(API.getPayers);
        yield put({ type: actions.PAYERS_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYERS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updatePayerStatus(action) {
    try {
        yield put({ type: actions.PAYERS_UPDATE_STATUS_PENDING });
        const {
            id,
            enabled,
            callback
        } = action.payload;
        yield call(API.updatePayerStatus, id, enabled);

        yield put({ type: actions.PAYERS_UPDATE_STATUS_FULFILLED, payload: { id: id, enabled: enabled } });

        if (callback) {
            callback(callback);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYERS_UPDATE_STATUS_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        yield put({ type: actions.PAYER_CREATE_PENDING });
        const {
            payer,
            callback,
            shouldReloadGrid
        } = action.payload;

        const payload = {
            ...payer,
        }
        yield call(API.createPayer, payload);
        yield put({ type: actions.PAYER_CREATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        yield put({ type: actions.PAYER_UPDATE_PENDING });
        const {
            payer,
            callback,
            shouldReloadGrid
        } = action.payload;

        yield call(API.updatePayer, payer);
        yield put({ type: actions.PAYER_UPDATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updateBulk(action) {
    try {
        yield put({ type: actions.PAYER_UPDATE_BULK_PENDING });
        const {
            payer,
            callback,
            shouldReloadGrid
        } = action.payload;

        yield call(API.updateBulkPayer, payer);
        yield put({ type: actions.PAYER_UPDATE_BULK_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_UPDATE_BULK_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* createPayerContract(action) {
    try {
        yield put({ type: actions.PAYER_CONTRACT_CREATE_PENDING });
        const {
            payerContract,
            callback,
            shouldReloadGrid
        } = action.payload;

        const payload = {
            ...payerContract,
        }
        yield call(API.createPayerContract, payload);
        yield put({ type: actions.PAYER_CONTRACT_CREATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_CONTRACT_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updatePayerContract(action) {
    try {
        yield put({ type: actions.PAYER_CONTRACT_UPDATE_PENDING });
        const {
            payerContract,
            callback,
            shouldReloadGrid
        } = action.payload;

        yield call(API.updatePayerContract, payerContract);
        yield put({ type: actions.PAYER_CONTRACT_UPDATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_CONTRACT_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* selectDeselectAll(action) {
    try {
        yield put({ type: actions.PAYER_SELECT_ALL_PENDING });
        const {
            payload,
            callback,
        } = action.payload;
        yield call(API.selectDeselectAll, payload);
        yield put({ type: actions.PAYER_SELECT_ALL_FULFILLED });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_SELECT_ALL_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayerContractsTiny(action) {
    try {
        yield put({ type: actions.PAYER_GET_PAYER_CONTRACTS_TINY_PENDING });
        const payload = yield call(API.getPayerContractsTiny);
        yield put({ type: actions.PAYER_GET_PAYER_CONTRACTS_TINY_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_GET_PAYER_CONTRACTS_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayersTiny(action) {
    try {
        yield put({ type: actions.PAYERS_TINY_PENDING });
        const payload = yield call(API.getPayersTiny);
        yield put({ type: actions.PAYERS_TINY_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYERS_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayersScorecard(action) {
    try {
        const { options } = action.payload;
        yield put({ type: actions.PAYERS_SCORECARD_PENDING });
        const payload = yield call(API.getPayersScoreCard, options);
        yield put({ type: actions.PAYERS_SCORECARD_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYERS_SCORECARD_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteBulk(action) {
    try {
        yield put({ type: actions.PAYER_DELETE_BULK_PENDING });
        const {
            payer,
            callback,
            shouldReloadGrid
        } = action.payload;

        yield call(API.deletePayerContracts, payer);
        yield put({ type: actions.PAYER_DELETE_BULK_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_DELETE_BULK_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* payerImport(action) {
    try {
        //const { options, callback } = action.payload;
        yield put({ type: actions.PAYER_INFO_IMPORT_PENDING });
        const payload = yield call(API.importPayers);
        yield put({ type: actions.PAYER_INFO_IMPORT_FULFILLED, payload });
        // if (callback) {
        //     callback();
        // }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_INFO_IMPORT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* remitPayerImport(action) {
    try {
        // const { options, callback } = action.payload;
        yield put({ type: actions.REMIT_PAYER_IMPORT_PENDING });
        const payload = yield call(API.importRemitPayers);
        yield put({ type: actions.REMIT_PAYER_IMPORT_FULFILLED, payload });
        // if (callback) {
        //     callback();
        // }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.REMIT_PAYER_IMPORT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* remitPayersStatusAll(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.REMIT_PAYER_ENABLE_ALL_PENDING });
        const payload = yield call(API.remitPayersStatusAll, options);
        yield put({ type: actions.REMIT_PAYER_ENABLE_ALL_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.REMIT_PAYER_ENABLE_ALL_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}


function* createRemitPayer(action) {
    try {
        yield put({ type: actions.REMIT_PAYER_CREATE_PENDING });
        const {
            payer,
            callback,
            shouldReloadGrid
        } = action.payload;

        const payload = {
            ...payer,
        }
        yield call(API.remitPayersCreate, payload);
        yield put({ type: actions.REMIT_PAYER_CREATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.REMIT_PAYER_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updateRemitPayer(action) {
    try {
        yield put({ type: actions.REMIT_PAYER_UPDATE_PENDING });
        const {
            payer,
            callback,
            shouldReloadGrid
        } = action.payload;

        yield call(API.remitPayersUpdate, payer);
        yield put({ type: actions.REMIT_PAYER_UPDATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.REMIT_PAYER_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* bulkUpdateRemitPayer(action) {
    try {
        yield put({ type: actions.REMIT_PAYER_BULK_UPDATE_PENDING });
        const {
            payer,
            callback,
            shouldReloadGrid
        } = action.payload;

        yield call(API.remitPayersBulkUpdate, payer);
        yield put({ type: actions.REMIT_PAYER_BULK_UPDATE_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.REMIT_PAYER_BULK_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* remitPayerToleranceMatch(action) {
    try {
        yield put({ type: actions.REMIT_PAYER_TOLERANCE_MATCH_PENDING });
        const {
            callback,
        } = action.payload;

        const payload = yield call(API.remitPayerToleranceMatch);
        yield put({ type: actions.REMIT_PAYER_TOLERANCE_MATCH_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.REMIT_PAYER_TOLERANCE_MATCH_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* remitPayerMapping(action) {
    try {
        yield put({ type: actions.REMIT_PAYER_CLAIM_MAPPING_PENDING });
        const {
            options,
            callback,
        } = action.payload;

        const payload = yield call(API.mapRemitPayer, options);
        yield put({ type: actions.REMIT_PAYER_CLAIM_MAPPING_FULFILLED, payload });

        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.REMIT_PAYER_CLAIM_MAPPING_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* claimPayerDependency(action) {
    try {
        yield put({ type: actions.CLAIM_PAYERS_DEPENDENCY_PENDING });
        const {
            options, callback
        } = action.payload;

        const payload = yield call(API.getPayersDependency, options);
        yield put({ type: actions.CLAIM_PAYERS_DEPENDENCY_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_PAYERS_DEPENDENCY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* claimPayersDelete(action) {
    try {
        yield put({ type: actions.CLAIM_PAYERS_DELETE_PENDING });
        const {
            options, callback
        } = action.payload;

        const payload = yield call(API.deletePayers, options);
        yield put({ type: actions.CLAIM_PAYERS_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_PAYERS_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* remitPayerDependency(action) {
    try {
        yield put({ type: actions.REMIT_PAYERS_DEPENDENCY_PENDING });
        const {
            options, callback
        } = action.payload;

        const payload = yield call(API.getRemitPayersDependency, options);
        yield put({ type: actions.REMIT_PAYERS_DEPENDENCY_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.REMIT_PAYERS_DEPENDENCY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* remitPayersDelete(action) {
    try {
        yield put({ type: actions.REMIT_PAYERS_DELETE_PENDING });
        const {
            options, callback
        } = action.payload;

        const payload = yield call(API.deleteRemitPayers, options);
        yield put({ type: actions.REMIT_PAYERS_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.REMIT_PAYERS_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* payerContractsDependency(action) {
    try {
        yield put({ type: actions.PAYER_CONTRACT_DEPENDENCY_PENDING });
        const {
            options, callback
        } = action.payload;

        const payload = yield call(API.getPayerContractDependency, options);
        yield put({ type: actions.PAYER_CONTRACT_DEPENDENCY_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_CONTRACT_DEPENDENCY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.PAYERS_RESET });
}

function* payerPlansList() {
    try {
        yield put({ type: actions.PAYER_PLAN_LANDING_PENDING });
        const payload = yield call(API.listPayerPlans);
        yield put({ type: actions.PAYER_PLAN_LANDING_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || {
            error: ""
        };
        yield put({
            type: actions.PAYER_PLAN_LANDING_REJECTED,
            payload: errorMessage
        });
        yield call(sessionErrorHandling, error);
    }
}

function* createPayerPlan(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.PAYER_PLAN_CREATE_PENDING });
        const payload = yield call(API.createPayerPlan, values);
        yield put({ type: actions.PAYER_PLAN_CREATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid, values.name);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_PLAN_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updatePayerPlan(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.PAYER_PLAN_UPDATE_PENDING });
        const payload = yield call(API.updatePayerPlan, values);
        yield put({ type: actions.PAYER_PLAN_UPDATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_PLAN_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deletePayerPlan(action) {
    try {
        const { planId, callback } = action.payload;
        yield put({ type: actions.PAYER_PLAN_DELETE_PENDING });
        const payload = yield call(API.deletePayerPlan, planId);
        yield put({ type: actions.PAYER_PLAN_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_PLAN_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayerPlan(action) {
    try {
        const { planId } = action.payload;
        yield put({ type: actions.PAYER_PLAN_GET_PENDING });
        const payload = yield call(API.getPayerPlan, planId);
        yield put({ type: actions.PAYER_PLAN_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_PLAN_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayerPlansTiny(action) {
    try {
        const { callback, newPlan } = action.payload;
        yield put({ type: actions.PAYER_PLAN_GET_TINY_PENDING });
        const payload = yield call(API.getPayerPlansTiny);
        yield put({ type: actions.PAYER_PLAN_GET_TINY_FULFILLED, payload });
        if(callback) {
            callback(payload, newPlan);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_PLAN_GET_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayerPlanDependencies(action) {
    try {
        const { id } = action.payload;
        yield put({ type: actions.PAYER_PLAN_DELETE_DEPENDENCIES_PENDING });
        const payload = yield call(API.getPayerPlanDependency, id);
        yield put({ type: actions.PAYER_PLAN_DELETE_DEPENDENCIES_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_PLAN_DELETE_DEPENDENCIES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* exportPayers(action) {
    try {
        const { payerIds, callback } = action.payload;
        yield put({ type: actions.PAYER_INFO_EXPORT_PENDING });
        const payload = yield call(API.exportPayers, payerIds);
        yield put({ type: actions.PAYER_INFO_EXPORT_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_INFO_EXPORT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* exportRemitPayers(action) {
    try {
        const { payerIds, callback } = action.payload;
        yield put({ type: actions.REMIT_PAYER_EXPORT_PENDING });
        const payload = yield call(API.exportRemitPayers, payerIds);
        yield put({ type: actions.REMIT_PAYER_EXPORT_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.REMIT_PAYER_EXPORT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* checkContractsAssociation(action) {
    try {
        yield put({ type: actions.PAYER_CONTRACT_ASSOCIATION_PENDING });
        const {
            options, callback
        } = action.payload;

        const payload = yield call(API.checkContractsAssociation, options.ids, options.payerContractId);
        yield put({ type: actions.PAYER_CONTRACT_ASSOCIATION_FULFILLED, payload });
        if (callback) {
            callback(payload);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_CONTRACT_ASSOCIATION_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* createPayerIdMapping(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.PAYER_ID_MAPPING_CREATE_PENDING });
        const payload = yield call(API.createPayerIdMapping, values);
        yield put({ type: actions.PAYER_ID_MAPPING_CREATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_ID_MAPPING_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* updatePayerIdMapping(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.PAYER_ID_MAPPING_UPDATE_PENDING });
        const payload = yield call(API.editPayerIdMapping, values);
        yield put({ type: actions.PAYER_ID_MAPPING_UPDATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_ID_MAPPING_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* bulkUpdatePayerIdMapping(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.PAYER_ID_MAPPING_BULK_UPDATE_PENDING });
        const payload = yield call(API.bulkEditPayerIdMapping, values);
        yield put({ type: actions.PAYER_ID_MAPPING_BULK_UPDATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_ID_MAPPING_BULK_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayerIdMapping(action) {
    try {
        const { payerIdMappingId } = action.payload;
        yield put({ type: actions.PAYER_ID_MAPPING_GET_PENDING });
        const payload = yield call(API.getPayerIdMapping, payerIdMappingId);
        yield put({ type: actions.PAYER_ID_MAPPING_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_ID_MAPPING_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayerIdMappingDependencies(action) {
    try {
        const { id } = action.payload;
        yield put({ type: actions.PAYER_ID_MAPPING_DELETE_DEPENDENCIES_PENDING });
        const payload = yield call(API.getPayerIdMappingDependency, id);
        yield put({ type: actions.PAYER_ID_MAPPING_DELETE_DEPENDENCIES_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_ID_MAPPING_DELETE_DEPENDENCIES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deletePayerIdMapping(action) {
    try {
        const { id, callback } = action.payload;
        yield put({ type: actions.PAYER_ID_MAPPING_DELETE_PENDING });
        const payload = yield call(API.deletePayerIdMapping, id);
        yield put({ type: actions.PAYER_ID_MAPPING_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_ID_MAPPING_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* uploadPayerIdMapping(action) {
    try {
        const { file, callback } = action.payload;
        yield put({ type: actions.PAYER_ID_MAPPING_UPLOAD_PENDING });
        const payload = yield call(API.uploadPayerIdMapping, file);
        yield put({ type: actions.PAYER_ID_MAPPING_UPLOAD_FULFILLED, payload });

        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { errorCallback } = action.payload;
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_ID_MAPPING_UPLOAD_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
        if (errorCallback) {
            errorCallback();
        }
    }
}

function* downloadPayerIdMapping(action) {
    try {
        const { callback } = action.payload;
        yield put({ type: actions.PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_PENDING });
        const payload = yield call(API.downloadPayerIdTemplate);
        yield put({ type: actions.PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_FULFILLED });

        if (callback) {
            callback(payload);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getPayerIdMappingTiny(action) {
    try {
        const { values, callback } = action.payload;
        yield put({ type: actions.PAYER_ID_MAPPING_TINY_PENDING });
        const payload = yield call(API.getPayerIdMappingTiny, values);
        yield put({ type: actions.PAYER_ID_MAPPING_TINY_FULFILLED, payload });
        if(callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PAYER_ID_MAPPING_TINY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.PAYERS_LIST, getPayers);
    yield takeEvery(actions.PAYERS_UPDATE_STATUS, updatePayerStatus);
    yield takeEvery(actions.PAYER_CREATE, create);
    yield takeEvery(actions.PAYER_UPDATE, update);
    yield takeEvery(actions.PAYER_UPDATE_BULK, updateBulk);
    yield takeEvery(actions.PAYER_CONTRACT_CREATE, createPayerContract);
    yield takeEvery(actions.PAYER_CONTRACT_UPDATE, updatePayerContract);
    yield takeEvery(actions.PAYER_SELECT_ALL, selectDeselectAll);
    yield takeEvery(actions.PAYER_GET_PAYER_CONTRACTS_TINY, getPayerContractsTiny);
    yield takeEvery(actions.PAYERS_TINY_LIST, getPayersTiny);
    yield takeEvery(actions.PAYERS_SCORECARD_LIST, getPayersScorecard);
    yield takeEvery(actions.PAYER_DELETE_BULK, deleteBulk);
    yield takeEvery(actions.PAYER_INFO_IMPORT, payerImport);
    yield takeEvery(actions.REMIT_PAYER_IMPORT, remitPayerImport);
    yield takeEvery(actions.REMIT_PAYER_ENABLE_ALL, remitPayersStatusAll);
    yield takeEvery(actions.REMIT_PAYER_CREATE, createRemitPayer);
    yield takeEvery(actions.REMIT_PAYER_UPDATE, updateRemitPayer);
    yield takeEvery(actions.REMIT_PAYER_BULK_UPDATE, bulkUpdateRemitPayer);
    yield takeEvery(actions.REMIT_PAYER_TOLERANCE_MATCH, remitPayerToleranceMatch);
    yield takeEvery(actions.REMIT_PAYER_CLAIM_MAPPING, remitPayerMapping);
    yield takeEvery(actions.CLAIM_PAYERS_DEPENDENCY, claimPayerDependency);
    yield takeEvery(actions.CLAIM_PAYERS_DELETE, claimPayersDelete);
    yield takeEvery(actions.REMIT_PAYERS_DEPENDENCY, remitPayerDependency);
    yield takeEvery(actions.REMIT_PAYERS_DELETE, remitPayersDelete);
    yield takeEvery(actions.PAYER_CONTRACT_DEPENDENCY, payerContractsDependency);
    yield takeEvery(actions.PAYER_PLAN_LANDING, payerPlansList);
    yield takeEvery(actions.PAYER_PLAN_CREATE, createPayerPlan);
    yield takeEvery(actions.PAYER_PLAN_UPDATE, updatePayerPlan);
    yield takeEvery(actions.PAYER_PLAN_DELETE, deletePayerPlan);
    yield takeEvery(actions.PAYER_PLAN_GET, getPayerPlan);
    yield takeEvery(actions.PAYER_PLAN_GET_TINY, getPayerPlansTiny);
    yield takeEvery(actions.PAYER_PLAN_DELETE_DEPENDENCIES, getPayerPlanDependencies);
    yield takeEvery(actions.PAYER_INFO_EXPORT, exportPayers);
    yield takeEvery(actions.REMIT_PAYER_EXPORT, exportRemitPayers);
    yield takeEvery(actions.PAYER_CONTRACT_ASSOCIATION, checkContractsAssociation);
    yield takeEvery(actions.PAYER_ID_MAPPING_CREATE, createPayerIdMapping);
    yield takeEvery(actions.PAYER_ID_MAPPING_UPDATE, updatePayerIdMapping);
    yield takeEvery(actions.PAYER_ID_MAPPING_BULK_UPDATE, bulkUpdatePayerIdMapping);
    yield takeEvery(actions.PAYER_ID_MAPPING_GET, getPayerIdMapping);
    yield takeEvery(actions.PAYER_ID_MAPPING_DELETE_DEPENDENCIES, getPayerIdMappingDependencies);
    yield takeEvery(actions.PAYER_ID_MAPPING_DELETE, deletePayerIdMapping);
    yield takeEvery(actions.PAYER_ID_MAPPING_UPLOAD, uploadPayerIdMapping);
    yield takeEvery(actions.PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE, downloadPayerIdMapping);
    yield takeEvery(actions.PAYER_ID_MAPPING_TINY, getPayerIdMappingTiny);
}