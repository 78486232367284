import React, { forwardRef } from "react";

const HeaderDropdownToggle = forwardRef((props, ref) => {
  const handleClick = (e) => {
    e.preventDefault();
    props.onClick(e);
  };

  return (
    <div
      ref={ref}
      className="kt-header__topbar-wrapper"
      onClick={handleClick}
    >
      {props.children}
    </div>
  );
});

export default HeaderDropdownToggle;
