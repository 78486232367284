import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { Loadable,DependentsList } from '../../../common';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControlAuto: {
        width: "85%"
    },
}));

function DependencyDialog(props) {
    const { intl, open, claimStatusId, getDependenciesPending, deletePending, claimStatusName, canDelete,dependentRecords,
        handleRefresh, initialValues, claimStatusList, claimStatusListPending,
        actions: { setDependencyDialog, getClaimStatusDeleteDependencies, deleteClaimStatus, 
            claimStatusesDependencyReset, getClaimStatuses }} = props;

    const classes = useStyles();

    useEffect(() => {
        if (open && claimStatusId) {
            getClaimStatuses();
        }
    }, [open, claimStatusId, getClaimStatuses]);

    useEffect(() => {
        if (open && claimStatusId) {
            getClaimStatusDeleteDependencies(claimStatusId)
        }
    }, [open, claimStatusId, getClaimStatusDeleteDependencies]);

    const handleDialogClose = () => {
        setDependencyDialog(null);
        claimStatusesDependencyReset();
    }

    const handleDeleteCallback = () => {
        setDependencyDialog(null);
        claimStatusesDependencyReset();
        handleRefresh(true);
    }

    const handleDelete = () => {
        if (canDelete) {
            deleteClaimStatus({id: claimStatusId, updatedId: null}, handleDeleteCallback);
        }
    }

    return (
        (<Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={getDependenciesPending || deletePending || claimStatusListPending}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Delete ({claimStatusName})
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleDialogClose}
                                size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                {canDelete &&
                    <Grid>
                        <Typography>Are you sure you want to delete this custom claim status?  It cannot be recovered.</Typography>
                    </Grid> 
                }
                {!canDelete && canDelete != null && 
                    <>
                    <DependentsList dependents={dependentRecords} isSimpleDependency={true}/>

                    <Grid>
                        <Typography>There are claims and search criteria in the system that have the status that you are trying to delete.</Typography>
                        <Typography>Would you like to bulk update the claim status for all claims and search criteria that contain the status you are trying to delete?</Typography>
                    </Grid>
                    <br />

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors : any = {};

                            if (!values.status) {
                                errors.status = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            return errors
                        }}
                        onSubmit={(
                            values,
                            {  }
                        ) => {
                            deleteClaimStatus({id: claimStatusId, updatedId: values?.status?.value}, handleDeleteCallback);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            setFieldValue,
                        
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={
                                                classes.formControlAuto
                                            }
                                        >
                                            
                                            <Autocomplete
                                                onChange={(e, option) =>
                                                    setFieldValue(
                                                        "status",
                                                        option
                                                    )
                                                }
                                                isOptionEqualToValue={(option, value) => option.name === value.name}

                                                value={values.status}
                                                options={claimStatusList}
                                                
                                                getOptionLabel={option =>
                                                    option.name || ""
                                                }

                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        label="Status"
                                                        error={Boolean(
                                                            touched.status &&
                                                                errors.status
                                                        )}
                                                        helperText={
                                                            touched.status &&
                                                            errors.status
                                                        }
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        container
                                        className="justify-content-end pt-2"
                                        style={{ marginTop: "20px" }}
                                    >
                                        <Button color="inherit" type="button" onClick={handleDialogClose} variant="contained" className="mr-3">
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={getDependenciesPending || deletePending || claimStatusListPending}
                                        >
                                            Update & Delete
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                    </>}
                </DialogContent>
                {canDelete && <DialogActions className='p-4'>
                    <Button color="inherit" variant="contained" onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        disabled={getDependenciesPending || deletePending || claimStatusListPending}
                        color="primary"
                    >
                        Delete
                    </Button>
                </DialogActions>}
            </Loadable>
        </Dialog>)
    );
}

export default DependencyDialog
