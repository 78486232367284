import { FieldArray } from "formik";
import React from "react";

import { Grid, IconButton, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete } from '@mui/material';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '85%',
    },
    portlet: {
        'box-shadow': 'none',
    },
    formControl1: {
        width: '85%',
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    iconButton: {
        marginTop: theme.spacing(3),
    },
    autoComplete: {
        width: '85%',
    },
}));


export default function RuleNPIRateFieldArray({
    isPaySourceTypeSchedule, values, handleBlur, handleChange, setFieldValue, touched, errors, feeScheduleLabels,
    handleFeeScheduleLabelToRateXrefChange,payMethod
}) {

    const classes = useStyles();

    function getHelperText(key, index) {
        if(touched.ruleToNpirateXref) {
            if(touched.ruleToNpirateXref[index]) {
                return touched.ruleToNpirateXref[index][key];
            }
            return false;
        }
        return false;
    }

    function getError(key, index) {
        if(errors.ruleToNpirateXref) {
            if(errors.ruleToNpirateXref[index]) {
                return errors.ruleToNpirateXref[index][key];
            }
            return false;
        }
        return false;
    }

    return (
        (<FieldArray
                name="ruleToNpirateXref"
                render={({ remove, insert }) => (
                    <Grid container>
                        <Grid item xs={12}>
                            {values.ruleToNpirateXref.map((npiRateItem, index) => {
                                return (
                                    (<Grid item xs={12} key={index}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <TextField
                                                    type="number"
                                                    label="NPI"
                                                    name={`ruleToNpirateXref.${index}.npi`}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={npiRateItem.npi}
                                                    onInput={(e: React.FormEvent<HTMLInputElement>) => {
                                                        const target = e.currentTarget;
                                                        target.value = Math.max(0, parseInt(target.value)).toString().slice(0, 15)
                                                    }}
                                                    helperText={getHelperText('npi', index) && getError('npi', index)}
                                                    error={Boolean(getHelperText('npi', index) && getError('npi', index))}                         
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    type="number"
                                                    // label={isPaySourceTypeSchedule ? 'Percentage' : 'Rate'}
                                                    label={payMethod?.name ? payMethod?.name : isPaySourceTypeSchedule ? 'Percentage' : 'Rate'}
                                                    name={`ruleToNpirateXref.${index}.rate`}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={npiRateItem.rate}
                                                    helperText={getHelperText('rate', index) && getError('rate', index)}
                                                    error={Boolean(getHelperText('rate', index) && getError('rate', index))}                         
                                                />
                                            </Grid>
                                            {values.feeScheduleId && isPaySourceTypeSchedule && feeScheduleLabels && feeScheduleLabels.length > 0 && 
                                                <Grid item xs={3}>
                                                    <Autocomplete
                                                    fullWidth
                                                    className={classes.autoComplete}
                                                    value={feeScheduleLabels.find(x=> x.id === npiRateItem?.feescheduleLabelToRateXRefId) ?? null}
                                                    disableClearable={false}
                                                    onChange={(e, value) => handleFeeScheduleLabelToRateXrefChange(`ruleToNpirateXref.${index}.feescheduleLabelToRateXRefId`, setFieldValue, value, values.feeScheduleId)}
                                                    isOptionEqualToValue={(option, value) => value ? option.id === value.id : null}
                                                    //name={`ruleToNpirateXref.${index}.feescheduleLabelToRateXRefId`}
                                                    options={feeScheduleLabels}
                                                    getOptionLabel={(option) => option.label}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            name={`ruleToNpirateXref.${index}.feescheduleLabelToRateXRefId`}
                                                            label="Rate Label"
                                                            error={getError(`feescheduleLabelToRateXRefId`, index)}
                                                            helperText={getError(`feescheduleLabelToRateXRefId`, index)}
                                                            margin="normal" />
                                                    }
                                                />
                                                </Grid>
                                            }

                                            {(index >= 0 && values.ruleToNpirateXref.length > 1) && (
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        edge="start"
                                                        aria-label="Delete"
                                                        onClick={() => remove(index)}
                                                        className={classes.iconButton}
                                                        size="large">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}

                                            {index === 0 && (
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        edge="start"
                                                        color="primary"
                                                        aria-label="Add"
                                                        onClick={() => insert(values.ruleToNpirateXref.length, {
                                                            rate: '',
                                                            npi: '',
                                                        })}
                                                        className={classes.iconButton}
                                                        size="large">
                                                        <AddCircleIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}

                                            
                                        </Grid>
                                    </Grid>)
                                );
                            })}
                        </Grid>
                    </Grid>
                )}
            />)
    );
}
