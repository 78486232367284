import { FormControl, FormControlLabel, Snackbar, Switch } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import MuiAlert from '@mui/material/Alert';
import { Formik } from "formik";
import React, { useEffect } from "react";
import { Loadable } from '../../../common';
import { contractValidationSchema } from '../../../store/validations/contract';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import { Button, Checkbox, Chip, Grid, TextField, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { BackButton } from "../../../common/BackButton";
import { MODULE_TYPE_VENDOR } from "../../../store/constants/contract";
import ContactFieldArray from "../../contractsPage/ContractsEditPage/ConfigurationFieldArrays/ContactFieldArray";
import { DocumentUpload } from "../../contractsPage/DocumentUpload";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '85%',
  },
  description: {
    width: '83%',
  },
  formControl1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '85%',
  },
  portlet: {
    'box-shadow': 'none',
  },
  successIcon: {
    color: 'green'
  },
  errorIcon: {
    color: 'red'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  formControlType: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: '85%',
  },
  checkbox: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
  },
  panel: {
    marginBottom: theme.spacing(1),
    'box-shadow': 'none !important',
    border: '1px solid #dddddd',
    borderRadius: 4,
  },
  switch: {
    //margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1)
  },
  formControlAuto: {
    marginTop: theme.spacing(5),
    width: '85%',
  },
  paper: {
    marginTop: theme.spacing(1),
  },
  facilityFormControlAuto: {
    marginTop: theme.spacing(2),
    width: '85%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function VendorsEditPage({
  intl, initialValues, contractsGetPending, contractId, contactTemplate, history,
  contractsCreatePending, contractsUpdatePending, mode, clientId, alertUsers, facilities, departments, glCodes, locations, resourceTypes,
  actions: { contractGet, contractCreate, contractUpdate, contractDocumentList, contractGetAlertUsers, contractGetFacilities,
    getGLCode, getLocations, getResourceTypes, getDepartments, vendorEditReset
  } }) {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const isEdit = mode === 'edit';

  const handleCallback = (newContractId) => {
    history.push(`/vendor/edit/${newContractId}`);
  }

  const handleEditCallback = () => {
    //setOpen(true);
    history.push(`/vendor/view/${contractId}`);
  }

  useEffect(() => {
    return () => {
      vendorEditReset();
    }
  }, []);

  useEffect(() => {
    contractGetFacilities();
  }, [contractGetFacilities]);

  useEffect(() => {
    getResourceTypes();
    getLocations();
    getGLCode();
    getDepartments();
  }, [getResourceTypes, getLocations, getGLCode, getDepartments]);

  useEffect(() => {
    contractGetAlertUsers(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractGetAlertUsers]);

  useEffect(() => {
    if (mode === 'edit') {
      contractGet(contractId);
    }
  }, [mode, contractGet, contractId]);

  function handleUploadCallback() {
    setOpen(true)
    contractDocumentList(contractId);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const filterOptions = createFilterOptions<{ name: string; email: string }>({
      stringify: option => option.name + ' ' + option.email,
  });

  const facilityFilterOption = createFilterOptions<{ name: string }>({
    stringify: option => option.name
  });



  const createAbleFilterOption = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option);
    if (inputValue !== '' && !isExisting) {
      filtered.push(`Add ${inputValue}`);
    }
    return filtered;
  }

  const customeHandleVendorFields = (key, arrItems, setFieldValue) => (e, value) => {
    if (value !== null && !arrItems.some(loc => loc === value)) {
      const temp = value.replace("Add ", "")
      setFieldValue(key, temp)
    } else {
      setFieldValue(key, value)
    }
  }

  return (
    <Loadable loading={contractsGetPending || contractsCreatePending || contractsUpdatePending}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Document successfully uploaded!
        </Alert>
      </Snackbar>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={contractValidationSchema}
        validate={(values: any) => {
          const errors: { effectiveDate?: string; terminationDate?: string } = {};
          if (!values.effectiveDate) {
            errors.effectiveDate = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          if (!values.terminationDate) {
            errors.terminationDate = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          return errors;
        }}
        onSubmit={(values) => {
          let payload = {
            name: values.name,
            effectiveDate: values.effectiveDate,
            terminationDate: values.terminationDate,
            isPayer: values.isPayer === "true" ? true : false,
            description: values.description,
            notification: values.notification,
            vendorContacts: values.vendorContacts,
            contractStateId: values.contractStateId,
            notes: values.notes,
            location: values.location || '',
            department: values.department || '',
            glCode: values.glCode || '',
            resourceType: values.resourceType || '',

            ...(values.facilityIds && values.facilityIds.length > 0 ? { 
              facilityIds: values.facilityIds.map(facility => facility.id) 
            } : {}),

            ...(values.alertContacts && values.alertContacts.length > 0? {
              alertContactIds: values.alertContacts.map(contact => contact.id)
            } : {}),
            id: undefined ,
            renew: undefined,
            alert: undefined,
          }

          if (mode === 'create') {
            contractCreate(payload, handleCallback);
          }
          else {
            payload = {
              ...payload,
              id: values.id,
              renew: values.renew,
              alert: values.alert,
              vendorContacts: values.vendorContacts.map(contact => (
                {
                  ...contact,
                  contractId: contractId
                }
              )),
            }
            contractUpdate(values.id, payload, handleEditCallback);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
            <Portlet>
              <PortletHeader
                title={`${isEdit ? 'Edit' : 'Add'} Vendor Contract `}
                toolbar={
                  <PortletHeaderToolbar>
                    <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/vendor" />
                    <Button
                      variant="contained"
                      color="primary"
                      className='ml-3'
                      type="submit"
                      disabled={contractsCreatePending || contractsUpdatePending || (!dirty && isEdit)}
                    >
                      {(contractsCreatePending || contractsUpdatePending) ? 'Saving..' : 'Save'}
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className="root">
                  <Grid container>
                    <Grid item xs={9}>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            required
                            label="Name"
                            name="name"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            helperText={touched.name && errors.name}
                            error={Boolean(touched.name && errors.name)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            required
                            type="date"
                            label="Effective Date"
                            name="effectiveDate"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.effectiveDate}
                            helperText={touched.effectiveDate && errors.effectiveDate}
                            error={Boolean(touched.effectiveDate && errors.effectiveDate)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            type="date"
                            label="Termination Date"
                            name="terminationDate"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.terminationDate}
                            helperText={touched.terminationDate && errors.terminationDate}
                            error={Boolean(touched.terminationDate && errors.terminationDate)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {mode === 'edit' && (
                      <Grid item xs={9}>
                        <Grid container>
                          <Grid item xs={6}>
                            <TextField
                              type="date"
                              label="Renew"
                              name="renew"
                              className={classes.textField}
                              margin="normal"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.renew}
                              helperText={touched.renew && errors.renew}
                              error={Boolean(touched.renew && errors.renew)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              type="date"
                              label="Alert"
                              name="alert"
                              className={classes.textField}
                              margin="normal"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.alert}
                              helperText={touched.alert && errors.alert}
                              error={Boolean(touched.alert && errors.alert)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={9}>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            type="text"
                            multiline
                            rows={3}
                            label="Description"
                            name="description"
                            className={classes.description}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            helperText={touched.description && errors.description}
                            error={Boolean(touched.description && errors.description)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            type="text"
                            multiline
                            rows={3}
                            label="Notes"
                            name="notes"
                            className={classes.description}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.notes}
                            helperText={touched.notes && errors.notes}
                            error={Boolean(touched.notes && errors.notes)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl className={classes.formControlAuto}>
                            <Autocomplete
                              onChange={(e, value) => setFieldValue("alertContacts", value)}
                              filterOptions={filterOptions}
                              value={values.alertContacts}
                              multiple
                              options={alertUsers}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name}
                                </li>
                              )}
                              renderTags={(tagValue, getTagProps) => {
                                const numTags = tagValue.length;
                                return (
                                  <Typography variant="caption">
                                    {tagValue.slice(0, 2).map((option, index) => (
                                      <Chip
                                        size="small"
                                        color="secondary"
                                        key={index}
                                        label={option.name}
                                        {...getTagProps({ index })}
                                      />
                                    ))}
                                    {numTags > 2 && ` + ${numTags - 2}`}
                                  </Typography>
                                )
                              }
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Alert Receipients"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.facilityFormControlAuto}>
                            <Autocomplete
                              onChange={(e, value) => setFieldValue("facilityIds", value)}
                              filterOptions={facilityFilterOption}
                              value={values.facilityIds}
                              multiple
                              options={facilities}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option, { selected }) => {
                                return (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.name}
                                  </li>
                                )
                              }}
                              renderTags={(tagValue, getTagProps) => {
                                const numTags = tagValue.length;
                                return (
                                  <Typography variant="caption">
                                    {tagValue.slice(0, 2).map((option, index) => (
                                      <Chip
                                        size="small"
                                        color="secondary"
                                        key={index}
                                        label={option.name}
                                        {...getTagProps({ index })}
                                      />
                                    ))}
                                    {numTags > 2 && ` +${numTags - 2}`}
                                  </Typography>
                                )
                              }
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Facilities"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid >
                    <Grid item xs={9}>
                      <Grid container className="mt-4">
                        {/* <Grid item xs={6}>
                          <FormGroup>
                            <Typography component="div">
                              <Grid component="label" container alignItems="center" spacing={0}>
                                <Grid item>
                                  <FormControl>
                                    <FormLabel>Contract Type</FormLabel>
                                    <RadioGroup
                                      className={classes.group}
                                      row
                                      aria-label="isPayer"
                                      name="isPayer"
                                      value={values.isPayer}
                                      onChange={handleChange}

                                    >
                                      <FormControlLabel
                                        value="true"
                                        control={<Radio />}
                                        label="Payer"
                                        disabled={isEdit}
                                      />
                                      <FormControlLabel
                                        value="false"
                                        control={<Radio />}
                                        label="Vendor"
                                        disabled={isEdit}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </Grid> */}
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="notification"
                                color="primary"
                                checked={values.notification}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={classes.switch}
                                value={values.notification}
                              />
                            }
                            label="Notification"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={9} container>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControlAuto}>
                          <Autocomplete
                            onChange={customeHandleVendorFields('location', locations, setFieldValue)}
                            filterOptions={createAbleFilterOption}
                            value={values.location}
                            options={locations}
                            // disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            renderInput={params => (
                              <TextField {...params}
                                className={"mt-0"}
                                error={Boolean(touched.location && errors.location)}
                                helperText={touched.location && errors.location}
                                label="Location"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControlAuto}>
                          <Autocomplete
                            onChange={customeHandleVendorFields('department', departments, setFieldValue)}
                            filterOptions={createAbleFilterOption}
                            value={values.department}
                            options={departments}
                            // disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            renderInput={params => (
                              <TextField {...params}
                                className={"mt-0"}
                                error={Boolean(touched.department && errors.department)}
                                helperText={touched.department && errors.department}
                                label="Departments"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl className={classes.formControlAuto}>
                          <Autocomplete
                            onChange={customeHandleVendorFields('glCode', glCodes, setFieldValue)}
                            filterOptions={createAbleFilterOption}
                            value={values.glCode}
                            options={glCodes}
                            // disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            renderInput={params => (
                              <TextField {...params}
                                className={ "mt-0"}
                                error={Boolean(touched.glCode && errors.glCode)}
                                helperText={touched.glCode && errors.glCode}
                                label="GL Code"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl className={classes.formControlAuto}>
                          <Autocomplete
                            onChange={customeHandleVendorFields('resourceType', resourceTypes, setFieldValue)}
                            filterOptions={createAbleFilterOption}
                            value={values.resourceType}
                            options={resourceTypes}
                            // disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            renderInput={params => (
                              <TextField {...params}
                                className={"mt-0"}
                                error={Boolean(touched.resourceType && errors.resourceType)}
                                helperText={touched.resourceType && errors.resourceType}
                                label="Resource Types"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    {
                      isEdit && (
                        <Grid item xs={10} className="mt-4">
                          <DocumentUpload contractId={contractId} handleUploadCallback={handleUploadCallback} module={MODULE_TYPE_VENDOR} />
                        </Grid>
                      )
                    }
                    <Grid item xs={10} className="mt-3">
                      <ContactFieldArray
                        mode={mode}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        vendorContacts={values.vendorContacts}
                        contactTemplate={contactTemplate}
                      />
                    </Grid>
                  </Grid >
                </div >
              </PortletBody >
            </Portlet >
          </form >
        )
        }
      </Formik >
    </Loadable >
  );
}