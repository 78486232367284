import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";

export default function ConfirmationDialog(props) {
    const {
        open,
        handleClose,
        handlePreviousClaim,
        handleNextClaim,
        nextPreviousState
    } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog-title"
            fullWidth
        >
            <DialogTitle id="confirm-dialog-title">
                Are you sure you want to discard your changes?
            </DialogTitle>
            <DialogContent>
                <p>
                    Are you sure you want to continue without saving your
                    comments?
                </p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                        nextPreviousState === "next"
                            ? handleNextClaim(null, true)
                            : handlePreviousClaim(null, true)
                    }
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
