/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { orderBy } from "lodash";
import Draggable from 'react-draggable'; // The default


import { Button, InputLabel, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { Loadable } from '../../../../common';

import KTOffcanvas from "../../../../../_metronic/_assets/js/offcanvas";

const useStyles1 = makeStyles(theme => ({
  button: {
    marginTop: 10
  },
  input: {
    display: "none"
  },
  toolbar: {
    border: '1px solid rgba(224, 224, 224, 1)',
    zIndex: 100,
    marginTop: "30px"
  }
}));

export default function SwitchClient({
    history,
    clients,
    loading,
    isSuperAdmin,
    isPartner,
    isPartnerUser,
    selectedClient:currentSelectedClient,
    actions: {
        clientListTiny,
        switchClient,
        resetPageFilter,
        resetPricerFilter,
        getClientProducts,
        resetSearchCriteria,
        getSettings
    },
}) {
    const classes = useStyles1();
    const [clientId, setClientId] = React.useState(null);
    const [isClientInactive, setClientInactive] = React.useState(false);
    const [sidebar, setSidebar] = React.useState(null);
    const [isPanelOpen, setPanelOpen] = React.useState(false);
    let [activeDrags, setActiveDrags] = React.useState(0);

    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (isSuperAdmin || isPartner || isPartnerUser) {
            clientListTiny(); //fetch client list
        }

        const panel = document.getElementById("switch_client_kt_quick_panel"); //setting up sidebar
        // eslint-disable-next-line
        const offCanvas = new KTOffcanvas(panel, {
            overlay: true,
            baseClass: "kt-quick-panel",
            closeBy: "switch_client_kt_quick_panel_close_btn",
            toggleBy: "switch_client_kt_quick_panel_toggler_btn",
            setPanelOpen: setPanelOpen,
        });
        setSidebar(offCanvas);

        const selectedClient = clients.find(
            (client) => client.clientId === currentSelectedClient
        );
        if (selectedClient) {
            if (!selectedClient.active) {
                setClientInactive(true);
                document
                    .getElementById("switch_client_kt_quick_panel_toggler_btn")
                    .click();
                setPanelOpen(true);
            } else {
                setClientInactive(false);
            }
            setClientId(selectedClient);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clients.length]);

    const handleClick = (event) => {
        if (clientId) {
            if (isPartner || isPartnerUser) {
                // recall client product api for partners only
                getClientProducts(clientId.clientId);
            }
            switchClient(clientId.clientId, closeSidebar); // Update state locally and remotely
            resetPageFilter();
            resetPricerFilter();
        }
        localStorage.removeItem("isCriteriaCleared");
        sessionStorage.removeItem("pages");
        sessionStorage.removeItem("duplicated");
        resetSearchCriteria();
    };

    const closeSidebar = () => {
        getSettings()
        setPanelOpen(false);
        setClientInactive(false);
        sidebar.hide();
        history.push("/");
    };

    const handleSideBarClose = () => {
        sidebar.hide();
        setPanelOpen(false);
    };

    const onStart = () => {
        setActiveDrags(++activeDrags);
    };

    const onStop = () => {
        setActiveDrags(--activeDrags);
    };

    const dragHandlers = { onStart: onStart, onStop: onStop };

    return (<>
        {isPanelOpen && (
            <div
                onClick={isClientInactive ? undefined : handleSideBarClose}
                className="kt-quick-panel-overlay"
            ></div>
        )}
        <React.Fragment key={"right"}>
            <div
                style={{ padding: "2.5rem" }}
                id="switch_client_kt_quick_panel"
                className="kt-quick-panel"
            >
                {!isClientInactive && (
                    <a
                        href="#"
                        className="kt-quick-panel__close"
                        id="switch_client_kt_quick_panel_close_btn"
                        onClick={handleSideBarClose}
                    >
                        <i className="flaticon2-delete" />
                    </a>
                )}
                <Loadable loading={loading}>
                    <div className="kt-quick-panel__content">
                        {isClientInactive && (
                            <div className="text-danger pb-4">
                                <b>
                                    Selected Client is deactivated. Please
                                    switch to another client to use the
                                    application.
                                </b>
                            </div>
                        )}
                        <React.Fragment>
                            <InputLabel htmlFor="client">Client</InputLabel>
                            <Autocomplete
                                onChange={(event, newValue) => {
                                    setClientId(newValue);
                                }}
                                value={clientId}
                                getOptionDisabled={(option) =>
                                    !option.active
                                }
                                options={orderBy(clients, ["name"])}
                                getOptionLabel={(option) =>
                                    option.name ? option.name : ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                    value.name === option.name
                                }
                                renderInput={(params) => (
                                    <TextField {...params} fullWidth />
                                )}
                            />
                        </React.Fragment>
                        <Button
                            disabled={
                                isClientInactive &&
                                currentSelectedClient === clientId?.clientId
                            }
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={handleClick}
                        >
                            Change
                        </Button>
                    </div>
                </Loadable>
            </div>
        </React.Fragment>
        {(isSuperAdmin || isPartner || isPartnerUser) && (
            <Draggable axis="y" {...dragHandlers}>
                <ul className={classes.toolbar + " kt-sticky-toolbar"}>
                    <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip id="documentations-tooltip">
                                Switch Account
                            </Tooltip>
                        }
                    >
                        <li
                            className="kt-sticky-toolbar__item kt-sticky-toolbar__item--warning"
                            data-placement="left"
                        >
                            <a
                                id="switch_client_kt_quick_panel_toggler_btn"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="flaticon2-gear" />
                            </a>
                        </li>
                    </OverlayTrigger>
                </ul>
            </Draggable>
        )}
    </>);
}
