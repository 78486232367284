import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createFloatingPoint } from '../../../util/format';
import { PopoverLink } from '../../popoverLink';

const useStyles = makeStyles(theme => ({
    helpIcon: {
        color: 'red',
        padding: 0,
        minWidth: 'auto',
    }
}));

export default function SummaryTile({ title, value, color, cardPadding, helpText=null, isCurrency = true, isVertical = false }) {
    const classes = useStyles();

    function hoverColumn(text) {
        return (
            <PopoverLink
                columnData={<i className={`${classes.helpIcon} fas fa-question-circle text-muted`}></i>}
                hoverData={[
                    {
                        data: text
                    }
                ]}
            />
        );
    }

    return (
        <Box>
            <CardContent style={cardPadding && { padding: cardPadding }}>
                {isVertical ? <Typography gutterBottom>
                    <b className={`kt-font-${color}`}>{title}</b>
                    {helpText && (
                        <span> {hoverColumn(helpText)} </span>
                    )}
                    <br /><b>{isCurrency ? `$ ${createFloatingPoint(value)}` : value} </b>
                </Typography> :
                    <Typography gutterBottom>
                        <b className={`kt-font-${color}`}>{title}</b>:
                        {helpText && (
                            <span> {hoverColumn(helpText)} </span>
                        )}
                        <b>{isCurrency ? `$ ${createFloatingPoint(value)}` : value} </b>
                    </Typography>
                }
            </CardContent>
        </Box>
    );
}

SummaryTile.prototype = {
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
}