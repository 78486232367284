import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { fileExecutionsLanding } from '../../../store/actions/pages';
import { dateEquators, integerEquators, StringEquators } from '../../../store/constants/pages';
import { createNumberDataField, createPlainDateField, createProgressBarField, createStringDataField, createTimestampField, createUploadsStatusField } from '../../../util/format';
import FileExecutionComponent from './FileExecutionPage';

const selectFields = createSelector(
    () => {
        return [
            createNumberDataField('id', 'Id', { sortable: true, equators: integerEquators }),
            createStringDataField('batchId', 'Batch Id', { sortable: true, equators: StringEquators }),
            createProgressBarField('processedClaims', 'Progress', 'processedFiles', 'totalFiles', 'Files'),
            createStringDataField('executedBy', 'Executed By', { sortable: true, equators: StringEquators }),
            createTimestampField('executedAt', 'Executed At', { sortable: true, equators: dateEquators }),
            createUploadsStatusField('state', 'Status', 'exception', { equators: StringEquators, returnWholeRow: true })
        ]
    }
);

const selectFileExecutionsData = createSelector(
    (state) => state.pages['file-executions-landing'].list,
    (data) => {
        return data
    }
);

export const FileExecution = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state as never),
            fileExecutionsData: selectFileExecutionsData(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            fileExecutionsLanding: fileExecutionsLanding
        }, dispatch)
    })
)(injectIntl(FileExecutionComponent));