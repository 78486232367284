import React, { useEffect, useState } from 'react';
import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../../partials/content/Portlet";
import { Grid } from '@mui/material';
import { PagedTable } from '../../../common/pagedTable';
import Helmet from 'react-helmet';

const DEFAULT_SORT = [{ id: 'pxCode', direction: 'asc' }];

export default function AllowablesPage(props) {
    const {
        //selectAllStatusPending, allowablesListPending, updateStatusPending, 
        data, fields,
        //checkboxStatus,
        actions: {
            allowableLanding,
            // updateStatus,
            // selectDeselectAll,
            searchReset,
            searchGetByName,
            resetPagedTable
        }
    } = props;

    useEffect(() => {
        const pageName = {
            name: 'allowable-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name)
        }
    }, [searchGetByName]);

    const [refresh, setRefresh] = useState(Promise.resolve());

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    // const handleCheckbox = (allowable) => {
    //     updateStatus(allowable.id, !allowable.enabled);
    // };

    // const handleSelectAll = () => {
    //     const ids = data.map(({ id }) => id);
    //     const payload = {
    //         ids,
    //         enabled: !checkboxStatus,
    //     };
    //     selectDeselectAll(payload, getList);
    // }

    function handleLoad(sort, filter, pagination) {
        allowableLanding(pagination, sort, filter);
    }

    return (
        <>
            <Helmet title="Negotiated Rates" />
            <Portlet>
                <PortletHeader title="Negotiated Rates"
                    name="allowable-landing"
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                    handleRefresh={handleRefresh}
                />
                <PortletBody>
                    <Grid container>
                        <Grid item md={12}>
                            <PagedTable
                                name="allowable-landing"
                                fields={fields}
                                data={data}
                                onLoad={handleLoad}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                criteriasSaveEnabled={true}
                                showSearchFilters={true}
                            />
                        </Grid>
                    </Grid>
                </PortletBody>
            </Portlet>
        </>
    );
}