import React, { useState, useEffect } from 'react';
import { getStartDate, getEndDate, RelativeDateRange } from '../../util/date';
import moment from 'moment';

import {
    MenuItem,
    Grid,
    ClickAwayListener,
    Box,
    TextField,
    colors,
    IconButton,
    Tooltip,
    Switch,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import './DateRangeControl.scss';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { searchPeriods } from '../../store/constants/date';

const useStyles = makeStyles(theme => ({
    textField: {
        // maxWidth: 245,
        width: (props:any) => props?.width ?? '100%',
        marginTop: (props:any) => props.marginTop ?? theme.spacing(1),
        marginLeft: (props:any) => props.marginLeft ?? 4,
        marginRight: (props:any) => props.marginRight ?? 4,
    },
    dateField: {
        width: '80%',
        marginTop: theme.spacing(1),
    },
    container: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    dateSection: {
        backgroundColor: colors.grey[50],
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        paddingBottom: 0
    },
    formControl: {
        width: 220,
    },
    actionSection: {
        justifyContent: 'flex-end',
        marginTop: -14,
    },
}));

interface DateRangeControlProps {
    fiscalYear?: string;
    refresh: () => void;
    filterName: string;
    setDates: (startDate: string, endDate: string, enabled: boolean, customField: string, relativeFilter: string) => void;
    fields?: Array<{ value: string, label: string }>;
    customFilter?: Array<{ field: string, equator?: string, value: string, relativeFilter?: string }>;
    hasCustomField?: boolean;
    relativeFilter?: string;
    relativeFilterValue?: string;
    setRelativeFilter?: (value: string) => void;
    disabled?: boolean;
    variant?: 'standard' | 'outlined' | 'filled';
    size?: 'small' | 'medium';
    style?: any;
    defaultControlEnabled?: boolean;
    isForClaims?: boolean;
    marginTop?: number;
    icon?: JSX.Element;
    marginLeft?: number;
    marginRight?: number;
}

/**
 * DateRangeControl component allows users to select a date range with various options such as relative filters and custom fields.
 * 
 * @param {Object} props - The properties object.
 * @param {string} props.fiscalYear - The fiscal year to be used for date calculations.
 * @param {Function} props.refresh - Function to refresh the data based on the selected date range.
 * @param {string} props.filterName - The name of the filter to be displayed.
 * @param {Function} props.setDates - Function to set the selected start and end dates.
 * @param {Array} props.fields - Array of fields available for selection.
 * @param {Array} props.customFilter - Array of custom filters applied.
 * @param {boolean} props.hasCustomField - Flag indicating if custom fields are available.
 * @param {string} props.relativeFilter - The relative filter value.
 * @param {string} props.relativeFilterValue - The value of the relative filter.
 * @param {Function} props.setRelativeFilter - Function to set the relative filter.
 * @param {boolean} props.disabled - Flag indicating if the control is disabled.
 * @param {Object} props.style - Custom styles to be applied to the component.
 * @param {boolean} props.defaultControlEnabled - Flag indicating if the control is enabled by default.
 * @param {boolean} props.isForClaims - Flag indicating if the control is used for claims.
 * 
 * @returns {JSX.Element} The DateRangeControl component.
 */
export default function DateRangeControl({
    fiscalYear,
    refresh,
    filterName,
    setDates,
    variant = 'standard',
    size,
    fields,
    customFilter,
    icon,
    hasCustomField,
    relativeFilter,
    relativeFilterValue,
    setRelativeFilter,
    disabled,
    style,
    defaultControlEnabled,
    isForClaims = false
}: DateRangeControlProps): JSX.Element {
    const classes = useStyles(style);

    const [open, setOpen] = useState(false);
    const [dateError, setDateError] = useState<string | null>(null);
    const [shouldCallAPI, setShouldCallApi] = useState(false);
    const [values, setValues] = useState({
        relativeFilter: 'Last12Months',
        startdate: getStartDate('Last12Months'),
        enddate: getEndDate('Last12Months'),
        enabled: defaultControlEnabled ?? true,
        customField: 'remitdate'
    });

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (relativeFilterValue) {
            setValues(prevState => {
                return {
                    ...prevState,
                    relativeFilter: relativeFilterValue
                }
            });
        } else if (relativeFilter && !relativeFilterValue) {
            setValues(prevState => {
                return {
                    ...prevState,
                    relativeFilter
                }
            });
        }
    }, [relativeFilter, relativeFilterValue])

    useEffect(() => {
        if (fields && fields.length > 0) {
            if (customFilter && customFilter.length > 0) {
                const ef = customFilter.filter((cf) => {
                    return fields.find((f) => {
                        return cf.field === f.value;
                    });
                });

                const startEF = ef.find(item => item.equator === 'greater_than_or_equals_to');
                const endEF = ef.find(item => item.equator === 'less_than_or_equals_to');

                if (ef.length > 0) {
                    setValues(prevState => {
                        return {
                            ...prevState,
                            enabled: true,
                            customField: ef[0].field,
                            startdate: startEF ? startEF?.value : getStartDate('Last12Months'),
                            enddate: endEF ? endEF?.value : getEndDate('Last12Months'),
                            relativeFilter: ef[0].relativeFilter ?? 'Last12Months'
                        }
                    });
                } else {
                    setValues(prevState => {
                        return {
                            ...prevState,
                            customField: fields[0].value,
                            enabled: false,
                        }
                    });
                }
            } else {
                setValues(prevState => {
                    return {
                        ...prevState,
                        enabled: false,
                        customField: fields[0].value,
                    }
                });
            }
        }
    }, !isForClaims ? [fields, customFilter] : [customFilter]);

    useEffect(() => {
        if (
            isForClaims &&
            fields?.length === 1 &&
            values?.customField === "remitdate"
        ) {
            setValues({
                ...values,
                customField: "servicedate",
                enabled: false,
            });
        }
        if (
            isForClaims &&
            fields?.length === 2 && !values?.enabled
        ) {
            const ef = customFilter.filter((cf) => {
                return fields.find((f) => {
                    return cf.field === f.value;
                });
            });

            const startEF = ef.find(item => item.equator === 'greater_than_or_equals_to');
            const endEF = ef.find(item => item.equator === 'less_than_or_equals_to');
            if (ef.length > 0) {
                setValues(prevState => {
                    return {
                        ...prevState,
                        enabled: true,
                        customField: ef[0].field,
                        startdate: startEF ? startEF?.value : getStartDate('Last12Months'),
                        enddate: endEF ? endEF?.value : getEndDate('Last12Months'),
                        relativeFilter: ef[0].relativeFilter ?? 'Last12Months'
                    }
                });
            }

        }



    }, [fields]);

    const handleRelativeFilterChange = (event: React.ChangeEvent<{ value: string }>) => {
        const value = event.target.value as RelativeDateRange;
                
        if (setRelativeFilter) {
            setRelativeFilter(value);// set RelativeFilter As Custom
        }
        setShouldCallApi(true);
        setValues(prevState => {
            return {
                ...prevState,
                relativeFilter: value,
                startdate: getStartDate(value, fiscalYear),
                enddate: getEndDate(value, fiscalYear),
            }
        });
        if (getStartDate(value, fiscalYear) < getEndDate(value, fiscalYear)) {
            setDateError(null);
        }
    };

    const handleCustomFieldChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setShouldCallApi(true);
        setValues(prevState => {
            return {
                ...prevState,
                customField: event.target.value as string
            }
        });
    }

    const startDateHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setRelativeFilter) {
            setRelativeFilter('custom');// set RelativeFilter As Custom
        }

        setValues(prevState => ({
            ...prevState,
            startdate: event.target.value,
        }));
        setShouldCallApi(true);

        if (new Date(event.target.value) < new Date(values.enddate)) {
            setDateError(null);
        } else {
            setDateError("Start date can not be after End date");
        }
    };

    const endDateHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setRelativeFilter) {
            setRelativeFilter('custom');// set RelativeFilter As Custom
        }

        setValues(prevState => ({
            ...prevState,
            enddate: event.target.value,
        }));
        setShouldCallApi(true);

        if (new Date(event.target.value) > new Date(values.startdate)) {
            setDateError(null);
        } else {
            setDateError("End date can not be before Start date");
        }

    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked && setRelativeFilter) {
            setRelativeFilter('custom');
        }
        setValues(prevState => {
            return {
                ...prevState,
                enabled: event.target.checked,
            }
        });
        setShouldCallApi(true);
    }

    const handleFilterApply = () => {
        if (new Date(values.startdate) > new Date(values.enddate) && values.enabled) {
            return
        }
        handleClickAway();
        setDates(values.startdate, values.enddate, values.enabled, values.customField, values.relativeFilter);

        if (refresh) {
            refresh();
        }
        setShouldCallApi(false);
    }

    function getFormattedDisplayDate() {
        if (!values.enabled) {
            return '[DISABLED]';
        }
        const startDate = moment(values.startdate, "YYYY/MM/DD");
        const endDate = moment(values.enddate, "YYYY/MM/DD");

        return `${startDate.format("MM/DD/YYYY")}  -  ${endDate.format("MM/DD/YYYY")}`;
    }

    function getFormattedLabel() {
        if (!values.enabled) {
            return filterName ?? 'Date Filter';
        }
        const label = hasCustomField ? getCustomLabel() : 'Date Range';
        return label;
    }

    const handleLostFocus = () => {
        if (new Date(values.startdate) > new Date(values.enddate) && values.enabled) {
            return
        }
        if (shouldCallAPI) {
            handleFilterApply();
            setShouldCallApi(false);
        } else {
            handleClickAway();
        }
    }

    const getCustomLabel = () => {
        const field = fields.find(item => item.value === values.customField)
        return field && field?.label
    }

    return (
        <ClickAwayListener onClickAway={handleLostFocus}>
            <div className='MyDatePicker'>
                <TextField
                    disabled={disabled}
                    name="Date"
                    variant={variant}
                    InputProps={{ startAdornment : icon }}
                    size={size}
                    label={getFormattedLabel()}
                    value={getFormattedDisplayDate()}
                    className={classes.textField}
                    onClick={handleClick}
                />
                {open ? (
                    <Box className='mdp-container'>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container className={classes.container}>
                                    <Grid item xs={9}>
                                        {hasCustomField && fields.length > 1 &&
                                            <div className='mb-4'>
                                                <TextField
                                                    disabled={!values.enabled}
                                                    select
                                                    label="Field"
                                                    className={classes.formControl}
                                                    value={values.customField}
                                                    onChange={handleCustomFieldChange}
                                                    SelectProps={{
                                                        MenuProps: { disablePortal: true }
                                                    }}
                                                >
                                                    {fields.map(({ value, label }) => (
                                                        <MenuItem key={value} value={value}>{label}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </div>
                                        }
                                        <TextField
                                            select
                                            disabled={!values.enabled}
                                            label="Relative Filter"
                                            className={classes.formControl}
                                            value={values.relativeFilter}
                                            onChange={handleRelativeFilterChange}
                                            SelectProps={{
                                                MenuProps: { disablePortal: true }
                                            }}
                                        >
                                            {searchPeriods.map(item => <MenuItem key={item.id} value={item.id}>{item.value} </MenuItem>)}
                                            <MenuItem disabled value="custom">Custom</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container className={classes.actionSection}>
                                            <Grid item xs={4}>
                                                <Tooltip title="Apply">
                                                    <IconButton size='small' onClick={handleFilterApply}>
                                                        <CheckCircleOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Tooltip title="Cancel">
                                                    <IconButton size='small' edge='end' onClick={handleClickAway}>
                                                        <HighlightOffIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container className={classes.dateSection}>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="date"
                                            disabled={!values.enabled}
                                            label="Start Date"
                                            name="startdate"
                                            className={classes.dateField}
                                            margin="normal"
                                            onChange={startDateHandler}
                                            value={values.startdate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="date"
                                            disabled={!values.enabled}
                                            label="End Date"
                                            name="enddate"
                                            className={classes.dateField}
                                            margin="normal"
                                            onChange={endDateHandler}
                                            value={values.enddate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {dateError &&
                                    <Typography variant="caption" display="block" gutterBottom className='text-danger px-3'>{dateError}</Typography>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Switch
                                        checked={values.enabled}
                                        onChange={handleSwitchChange}
                                        name="enabled"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}