import React, { useState, useEffect } from 'react';
import { FormControl, TextField, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Loadable } from '../../loadable';

const useStyles = makeStyles(theme => ({
    column: {
        flexBasis: '25%',
        margin: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(2),
        width: 200,
    },
    checkbox: {
        margin: 0,
        padding: 0,
    }
}));

export default function DropControl(props) {
    const classes = useStyles();
    const {
        items, label, selectAllLabel, loading,
        noOptionsText, onChange, defaultSelect
    } = props;

    const [selectedOptions, setSelectedOptions] = useState([]);
    const allSelected = items.length === selectedOptions.length;
    const handleToggleOption = (selectedOptions) =>
        setSelectedOptions(selectedOptions);
    const handleClearOptions = () => setSelectedOptions([]);
    const getOptionLabel = (option) => option;

    useEffect(() => {
        if (defaultSelect && items.length > 0) {
            setSelectedOptions(items);
            onChange(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, defaultSelect]);

    const handleSelectAll = (isSelected) => {
        if (isSelected) {
            setSelectedOptions(items);
        } else {
            handleClearOptions();
        }
    };

    const handleToggleSelectAll = () => {
        handleSelectAll && handleSelectAll(!allSelected);
    };

    const handleChange = (event, selectedOptions, reason) => {
        if (reason === "select-option" || reason === "remove-option") {
            if (selectedOptions.find((option) => option === selectAllLabel)) {
                handleToggleSelectAll();
                let result = [];
                result = items.filter((el) => el !== selectAllLabel);
                return onChange(result);
            } else {
                handleToggleOption && handleToggleOption(selectedOptions);
                return onChange(selectedOptions);
            }
        } else if (reason === "clear") {
            handleClearOptions && handleClearOptions();
        }
    };
    const optionRenderer = (props, option, { selected }) => {
        const selectAllProps =
            items.length > 0 && option === selectAllLabel // To control the state of 'Select All' checkbox
                ? { checked: allSelected }
                : {};
        return (
            <li {...props}>
                <Checkbox
                    color="secondary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={selected}
                    {...selectAllProps}
                />
                {getOptionLabel(option)}
            </li>
        );
    };
    const inputRenderer = (params) => (
        <TextField
            {...params}
            label={label}
        />
    );

    const renderTags = (newValues) => {
        if (items.length === newValues.length) {
            return '[All]';
        }
        if (newValues.length >= 1) {
            return `${newValues.length} Selected`;
        }
        return '';
    };

    const filter = createFilterOptions();
    return (
        (<div className="column">
            <Loadable size={20} loading={loading}>
                <FormControl className={classes.formControl}>
                    <Autocomplete
                        multiple
                        //limitTags={limitTags} NOT supporting current version
                        options={items}
                        value={selectedOptions}
                        disableCloseOnSelect
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={(option, value) => option === value}
                        noOptionsText={noOptionsText}
                        renderTags={(value) => renderTags(value)}
                        filterOptions={(options, params) => {
                            if (items.length > 0) {
                                const filtered = filter(options, params);
                                return [selectAllLabel, ...filtered];
                            } else if (items.length === 0) {
                                const filtered = filter(options, params);
                                return [...filtered];
                            }
                        }}
                        onChange={handleChange}
                        renderOption={optionRenderer}
                        renderInput={inputRenderer}
                    />
                </FormControl>
            </Loadable>
        </div >)
    );

}

DropControl.defaultProps = {
    limitTags: 2,
    items: [],
    selectedValues: [],
    getOptionLabel: (value) => value,
    loading: false,
};