import React from 'react';
import { TableCell, TableSortLabel, Tooltip, Typography } from '@mui/material';


import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(theme => ({
    ellipsis: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 100,
    },
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        width: 400,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    fixedCell: {
        zIndex: 3,
        left: ({ left }) => left + 'px'
    },
    cellStyles: {
        // backgroundColor: props => props.backgroundColor,
    }
}));

export default function HeaderColumn(props) {

    const { field, onSort, columnOrder, direction,colSpan } = props;
    const { id, label, options } = field;
    const { truncateHeader, fieldType } = options || false;
    const { sortable, positionFixed, left = 0, backgroundColor = 'transparent' } = options || {};

    const classes = useStyles({ left, backgroundColor });

    return (
        <>
            {truncateHeader ?
                <Tooltip
                    arrow
                    classes={{ tooltip: classes.tooltip }}
                    title={<Typography color="inherit">{label}</Typography>}
                >
                    <TableCell
                        sortDirection={direction}
                        
                    >
                        {sortable ? (
                            <TableSortLabel
                                active={columnOrder === id}
                                direction={direction}
                                onClick={() => onSort({ id, direction, fieldType })}
                            >
                                <div className={classes.ellipsis}>
                                    {label}
                                </div>
                            </TableSortLabel>
                        ) : (
                            <div className={classes.ellipsis}>
                                {label}
                            </div>
                        )
                        }
                    </TableCell>
                </Tooltip>
                :
                <TableCell
                    className={`${classes.cellStyles} ${positionFixed ? classes.fixedCell : null}`}
                    sortDirection={direction}
                    colSpan={colSpan}
                >
                    {sortable ? (
                        <TableSortLabel
                            active={columnOrder === id}
                            direction={direction}
                            onClick={() => onSort({ id, direction, fieldType })}
                        >
                            {label}
                        </TableSortLabel>
                    ) : (
                        <>
                            {label}
                        </>
                    )
                    }
                </TableCell>
            }
        </>
    )
}