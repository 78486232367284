import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { Grid, IconButton } from '@mui/material';

function APIKeyShowComponent({ value, actualKey,actualAppKey,isAppId, actions: { getAPIKeyValue, resetActualKey,getAppIdValue,resetActualAppIdKey } }) {
    const [isCopied, setCopied] = useState(false);

    const handleViewAPIKey = () => {

        if(isAppId)
        {
            getAppIdValue(value.id, handleCallback)
            return
        }

        getAPIKeyValue(value.id, handleCallback);
    };

    const handleCallback = (payload) => {
    }

    const handleHideKey = () => {
        if(isAppId)
        {
            resetActualAppIdKey()
            return
        }
        resetActualKey();
    }

    const handleCopyIcon = () => {
        const apiKey = document.getElementById('actual-key').textContent;
        navigator.clipboard.writeText(apiKey);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }
    return (<>
        {((!isAppId && actualKey) || (actualAppKey && isAppId)) ?
            <Grid container alignItems='center'>
                <span id='actual-key' >{isAppId ? actualAppKey: actualKey}</span>
                <IconButton
                    className='p-1 ml-2'
                    aria-label="toggle api key visible"
                    onClick={handleCopyIcon}
                    color={isCopied ? 'secondary' : 'primary'}
                    size="large">
                    <CopyIcon />
                </IconButton>
                <IconButton
                    className='p-1 ml-2'
                    aria-label="toggle api key visible"
                    onClick={handleHideKey}
                    size="large">
                    <VisibilityOff />
                </IconButton>
            </Grid> :
            <Grid container alignItems='center'>
                <span> ***************************************** </span>
                <IconButton
                    className='p-1 ml-2'
                    onClick={handleViewAPIKey}
                    aria-label="toggle api key visible"
                    size="large">
                    <Visibility />
                </IconButton>
            </Grid>
        }
    </>);
}

export default APIKeyShowComponent
