import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FieldArray, Formik } from 'formik';
import { useState } from 'react';
import { renderErrorMessage } from '../../../../util/error';

const useStyles = makeStyles(theme => ({
    textField: {
        width: '85%',
    },
    formControl: {
        width: '85%',
        marginTop: theme.spacing(2),
    },
    dialogContent: {
        margin: '0px 10px 0px 10px',
        padding: '10px 20px',
    },
    iconButton: {
        marginTop: theme.spacing(3),
    }
}));


function RuleModifierMultiProcedureFieldArray({ intl, initialValues, open, selectedModifierIndex, handleClose, handleSetArrayValue,selectedModifierMultiProcedurePercentage=undefined,
}) {
    const classes = useStyles();
    const [occuranceError, setOccuranceError] = useState(false);

    const handleOccuranceChange = (values, setFieldValue, index) => (e) => {
        if (values.percentageArray.some(item => item.occurance === e.target.value)) {
            setFieldValue(`percentageArray[${index}].occurance`, '');
            setOccuranceError(true);
        } else {
            setOccuranceError(false);
            setFieldValue(`percentageArray[${index}].occurance`, e.target.value);
        }
    }

    const renderOccuranceErrorMessage = () => {
        return renderErrorMessage(occuranceError, 'Can not duplicate occurance');
    }

    function getHelperText(touched, key, index) {
        if (touched.percentageArray) {
            if (touched.percentageArray[index]) {
                return touched.percentageArray[index][key];
            }
            return false;
        }
        return false;
    }

    function getError(errors, key, index) {
        if (errors.percentageArray) {
            if (errors.percentageArray[index]) {
                return errors.percentageArray[index][key];
            }
            return false;
        }
        return false;
    }

    function handleCloseDialog() {
        handleClose();
        setOccuranceError(false);
    }

    const occurances = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
        (<Dialog
                fullWidth
                scroll="paper"
                maxWidth="md"
                open={open} onClose={handleCloseDialog}>
            <DialogTitle>
                <Grid container alignItems='center'>
                    <Grid item xs={11}>
                        Modifier MultiProcedure Percentage
                    </Grid>
                    <Grid item xs={1} className='text-right'>
                        <IconButton aria-label="close" onClick={handleCloseDialog} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={values => {
                    const errors = {};

                    const procedurePercentageError = values.percentageArray.map((modifier) => {
                        const percentageError = {};
                        let hasError = false;

                        if (!modifier.value) {
                            percentageError['value'] = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                            hasError = true;
                        }
                        if (!modifier.occurance) {
                            percentageError['occurance'] = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                            hasError = true;
                        }
                        if (hasError) {
                            return percentageError;
                        }

                        return false;
                    });
                    const filteredProcedurePercentageError = procedurePercentageError.filter(value => Object.keys(value).length > 0);

                    if (filteredProcedurePercentageError.length > 0) {
                        errors['percentageArray'] = procedurePercentageError;
                    }

                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    const formattedArr = values.percentageArray.map(item => ({ occurance: item.occurance, value: item.value }))
                    handleSetArrayValue(`ruleToModifierXref.${selectedModifierIndex}.modifierMultiProcedurePercentage`, formattedArr)
                    handleCloseDialog();
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting
                }) => (

                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        <DialogContent className={classes.dialogContent} dividers>
                            <Grid xs={12}>
                                {renderOccuranceErrorMessage()}
                            </Grid>
                            <FieldArray
                                name="percentageArray"
                                render={({ remove, insert }) => (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {values.percentageArray.map((percentageItem, index) => {
                                                return (
                                                    (<Grid item xs={12} key={index}>
                                                        <Grid container>
                                                            <Grid item xs={5}>
                                                                <FormControl className={classes.formControl}>
                                                                    <InputLabel htmlFor={`percentageArray[${index}].occurance`}>Occurrence</InputLabel>
                                                                    <Select
                                                                        fullWidth
                                                                        required
                                                                        value={percentageItem.occurance}
                                                                        onChange={handleOccuranceChange(values, setFieldValue, index)}
                                                                        onBlur={handleBlur}
                                                                        input={<Input id={`percentageArray[${index}].occurance`} />}
                                                                        inputProps={{
                                                                            name: `percentageArray[${index}].occurance`,
                                                                            id: `percentageArray[${index}].occurance`,
                                                                        }}
                                                                        error={Boolean(getHelperText(touched, 'occurance', index) && getError(errors, 'occurance', index))}
                                                                    >
                                                                        {occurances.map((item, index) =>
                                                                            <MenuItem
                                                                                value={item}
                                                                                key={index.toString()}
                                                                            >
                                                                                {item}
                                                                            </MenuItem>)}
                                                                    </Select>
                                                                    <FormHelperText error>{getHelperText(touched, 'occurance', index) && getError(errors, 'occurance', index)}</FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <TextField
                                                                    type="number"
                                                                    label="Percentage"
                                                                    name={`percentageArray[${index}].value`}
                                                                    className={classes.textField}
                                                                    margin="normal"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={percentageItem.value}
                                                                    helperText={getHelperText(touched, 'value', index) && getError(errors, 'value', index)}
                                                                    error={Boolean(getHelperText(touched, 'value', index) && getError(errors, 'value', index))}
                                                                />
                                                            </Grid>

                                                            {(index >= 0 && values.percentageArray.length > 1) && (
                                                                <Grid item xs={1}>
                                                                    <IconButton
                                                                        edge="start"
                                                                        aria-label="Delete"
                                                                        onClick={() => remove(index)}
                                                                        className={classes.iconButton}
                                                                        size="large">
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            )}

                                                            {index === 0 && (
                                                                <Grid item xs={1}>
                                                                    <IconButton
                                                                        edge="start"
                                                                        color="primary"
                                                                        aria-label="Add"
                                                                        onClick={() => insert(values.percentageArray.length, {
                                                                            occurance: '',
                                                                            value: ''
                                                                        })}
                                                                        className={classes.iconButton}
                                                                        size="large">
                                                                        <AddCircleIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            )}

                                                        </Grid>
                                                    </Grid>)
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                )}
                            />
                        </DialogContent>
                        <DialogActions className='p-4'>
                            <Button variant="contained" onClick={handleCloseDialog} type='button'>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                type='submit'
                                color='primary'
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </form>

                )}
            </Formik>
        </Dialog >)
    );
}

export default RuleModifierMultiProcedureFieldArray