import { Grid, IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef, useState } from "react";
import { CustomLabel, PagedTable } from '../../../../../../common';

import { Edit as EditIcon } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GetAppIcon from '@mui/icons-material/GetApp';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import SettingsIcon from '@mui/icons-material/Settings';
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BackButton } from "../../../../../../common/BackButton";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../../../../partials/content/Portlet";
import { ROLE_ADMIN, ROLE_PARTNER_ADMIN, ROLE_SUPERADMIN } from '../../../../../../store/constants/roles';
import { asDate } from '../../../../../../util/date';
import { FeeScheduleBreadCrumb } from "../../../../../feeSchedulePage/FeeScheduleBreadCrumb";
import { DeleteItems } from "./DeleteItems";
import { DependencyDialog } from "./DependencyDialog";
import { EnableDialog } from "./EnableDialog";

const DEFAULT_SORT = [{ id: 'code', direction: 'asc' }];
const DEFAULT_HIDDEN_FIELDS = ['rate1','rate2','rate3','rate4','rate5','rate6','rate7','rate8','rate9','rate10'];

const useStyles = makeStyles(theme => {
    return {
        portlet: {
            'box-shadow': 'none',
            // '& .kt-portlet__head': {
            //     paddingLeft: 0
            // }
        },
        pt:{
            paddingTop:"20px !important"
        },
        fullWidth:{
            width:"100%"
        },
        wFit:{
            width:"fit-content"
        }
    }
});

function FeeScheduleItems(props) {
    const { fields, data, feeScheduleId, defaultFilter, filters, feeSchedule,  
        exportTemplatePending, uploadFilePending, userRole,
        actions: {
            feeScheduleItemsLanding, resetGroups, resetTableData, setRateDialog,
            setServiceFromFeeSchedule, searchGetByName, searchReset, feeScheduleItemErrorReset,
            downloadTemplate, uploadFile, importExportReset, resetPagedTable, 
            setEnableDialog, setFilter, setFeeScheduleImportLabelDepdendencyDialog,
            getFeeschedule
        }
    } = props;

    const search = useLocation().search;
    const directoryId = new URLSearchParams(search).get('directoryId');

    const classes = useStyles();
    const [refresh, setRefresh] = useState(Promise.resolve());
    const [file, setFile] = useState(null);

    const importRef = useRef(null)

    const pxCode = props.match?.params?.pxCode;
    const parentDirectory = props.match?.params?.directoryId;

    useEffect(() => {
        if (pxCode && pxCode!=='null') {
            const codeFilter = {
                field: 'code',
                equator: 'equals',
                value: pxCode,
            }
            setFilter([codeFilter]);
        }
    }, [setFilter, pxCode]);

    function handleLoad(sort, filter, pagination) {
        const options = {
            feeScheduleId: feeScheduleId,
            inputRequest: {
                page: {
                    ...pagination,
                },
                filter: filter || null,
                sort: sort || [],
            }
        };
        feeScheduleItemsLanding(options);
    }

    useEffect(() => {
        return function cleanup() {
            importExportReset();
        }
    }, [importExportReset]);

    useEffect(() => {
        return function cleanup() {
            resetGroups();
        };
    }, [resetGroups]);

    useEffect(() => {
        const pageName = {
            name: 'feeschedule-items-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleRefresh() {
        resetTableData();
        setRefresh(Promise.resolve());
    }

    const handleRedirectToService = () => {
        setServiceFromFeeSchedule(true, feeScheduleId)
        props.history.push(`/cms/codeservice/edit/new`);
    }

    const handleRedirectToFeeSchedule = () => {
        props.history.push('/cms/feeschedules')
    }

    const handleClose = (shouldReloadGrid) => {
        setRateDialog(null);
        feeScheduleItemErrorReset();
        if (shouldReloadGrid === true) {
            handleRefresh();
        }
    }

    const handleAddFeeScheduleItem = () => {
        props.history.push(`/cms/feeschedulesrate/edit/${feeScheduleId}/new`);
        // setRateDialog({ open: true, mode: 'create' });
    }

    const handleEditFeeScheduleItem = (feeScheduleItem) => {
        props.history.push(`/cms/feeschedulesrate/edit/${feeScheduleId}/${feeScheduleItem.id}`);
        // setRateDialog({ open: true, mode: 'edit', feeScheduleItem });
    }

    function handlePayerConfirmation(isEnable) {
        setEnableDialog({ open: true, isEnableClicked: isEnable })
    }

    function handleImportFileInput() {
        importRef.current.click();
    }

    function handleFileChange(e) {
        let tempFile = e.target.files[0];
        setFile(tempFile);
        uploadFile(feeSchedule.id, tempFile, uploadFileCallback);
        e.target.value = null;
    }
    function uploadFileCallback(dependencyResponse) {
        setFile(null);
        if(dependencyResponse && !dependencyResponse.canDelete && dependencyResponse.records.length > 0) {
            setFeeScheduleImportLabelDepdendencyDialog({ open: true, dependencies: dependencyResponse.records });
        }
        else {
            getFeeschedule(feeScheduleId);
            handleRefresh();
        }
    }

    function handleDowloadTemplate() {
        downloadTemplate(feeSchedule.id, downloadTemplateCallback);
    }

    function downloadTemplateCallback({ file, fileName }) {
        let url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    }

    function tableLevelActions() {
        return (
            <>
                {(userRole === ROLE_SUPERADMIN || userRole === ROLE_PARTNER_ADMIN || userRole === ROLE_ADMIN) &&
                    <DeleteItems handleRefresh={handleRefresh} />
                }
                {(filters.length > 0 && data.length > 0) && (
                    <Grid item>
                        <Tooltip title="Code Service">
                            <IconButton aria-label="codeService" size='small' onClick={() => handleRedirectToService()}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}

                <Grid item>
                    <Tooltip title="Enable">
                        <IconButton aria-label="enable" size='small' onClick={() => handlePayerConfirmation(true)}>
                            <CheckCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Disable">
                        <IconButton aria-label="disable" size='small' onClick={() => handlePayerConfirmation(false)}>
                            <NotInterestedIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Export Fee Schedule Rates">
                        <IconButton aria-label="download" size='small' onClick={handleDowloadTemplate}
                            disabled={exportTemplatePending}
                        >
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Import Fee Schedule Rates">
                        <IconButton aria-label="download" size='small'
                            onClick={handleImportFileInput}
                            disabled={uploadFilePending}
                        >
                            <i className="fas fa-upload"></i>
                            <input
                                onChange={handleFileChange}
                                ref={importRef}
                                type="file"
                                hidden
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    function feeScheduleItemActions(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    aria-label="Edit"
                    onClick={() => handleEditFeeScheduleItem(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
            </div>
        );
    }


    return (
        <>
           {(directoryId || parentDirectory) && <FeeScheduleBreadCrumb directoryId={directoryId || parentDirectory || 'root'} handleRefresh={handleRefresh as any} />}
            <Portlet className={classes.portlet}>
                <PortletHeader
                    title="Fee Schedule Items"
                    handleRefresh={handleRefresh}
                    name="feeschedule-items-landing"
                    shouldNotSetDefault={pxCode ? true : false}
                    includeAdvanceFilters={true}
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                    toolbar={
                        <PortletHeaderToolbar className={classes.fullWidth}>
                            <Grid container spacing={1}>
                                {feeSchedule &&
                                    <Grid item xs={10}>
                                        <Grid item xs={12} >
                                            <Grid container className={`text-dark px-3 ${classes.wFit}`}>
                                                <Grid item lg={6} md={6} sm={8}>
                                                    <CustomLabel label="Name" data={feeSchedule.name} />
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={8}>
                                                    <CustomLabel label="Effective From" data={asDate(feeSchedule.effectiveDate)} />
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={8}>
                                                    <CustomLabel label="Expiration Date" data={asDate(feeSchedule.expirationDate)} />
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={8}>
                                                    <CustomLabel label="Description" data={feeSchedule.description} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item className={`d-flex align-items-start justify-content-end ${classes.pt}`} sx={{pr:"10px"}} xs={2}>
                                    <BackButton defaultRoute="/cms/feeschedules/root" />
                                </Grid>
                            </Grid>
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <PagedTable
                        name="feeschedule-items-landing"
                        fields={fields}
                        data={data}
                        onLoad={handleLoad}
                        refresh={refresh}
                        defaultSort={DEFAULT_SORT}
                        defaultFilter={defaultFilter}
                        isCollapsible={false}
                        onAdd={handleAddFeeScheduleItem}
                        title="FeeScheduleItem"
                        criteriasSaveEnabled={true}
                        hasCheckbox={true}
                        showSelectAll={true}
                        // loading={updateStatusPending}
                        // collapsibleItem={renderCollapsibleItem}

                        hasActions={true}
                        renderActionColumn={feeScheduleItemActions}
                        tableLevelActions={tableLevelActions}
                        tableLevelLoading={exportTemplatePending || uploadFilePending}
                        // collapsibleItem={renderCollapsibleItem}
                        showSearchFilters={true}
                        showFilterColumnsDialog={true}
                        defaultHiddenFields={DEFAULT_HIDDEN_FIELDS}
                        shouldRenderLazy={true}
                        
                    />
                </PortletBody>
                {/* <FeeScheduleItemDialog feeScheduleId={feeScheduleId} handleClose={handleClose} /> */}
                <EnableDialog handleRefresh={handleRefresh} />
                <DependencyDialog />
            </Portlet>

        </>
    );
}

export default withRouter(FeeScheduleItems)
