import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import { Typography } from "@mui/material";

export default function Line({ title, desc, data, handlePointClick, lastIndex }) {
    const ref = useRef();

    useEffect(() => {
        const chart = new Chart(ref.current, {
            type: 'line',
            data,
            options: {
                responsive: true,
                showTooltips: true,
                scales: {
                    yAxes: [{
                        stacked: false
                    }],
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 20
                        }
                    }]
                },
                onClick: (e, activeElements) => handlePointClick(activeElements),
                tooltips: {
                    callbacks: {
                        footer: (item, b) => {
                            if (item && item.length > 0) {
                                if (item[0].index !== lastIndex) {
                                    return 'Click to view archived details'
                                }
                            }
                        }
                    }
                }
            }
        });

        return () => {
            chart.destroy();
        };
    }, [data, handlePointClick, lastIndex]);

    return (
        <div>
            <div className="kt-widget14__header text-center">
                <h6>{title}</h6>
                <span className="kt-widget14__desc">{desc}</span>
            </div>
            <div className="kt-widget14__chart">
                <canvas ref={ref} />
            </div>
        </div>
    );
}