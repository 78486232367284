import React, { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(theme => ({

    clickTypography: {
        // textDecoration: 'underline',
        color: '#2739c1',
        cursor: 'pointer'
    },

}));

function CopyButton({ text, claimId, history }) {

    const classes = useStyles();

    const [isCopied, setCopied] = useState(false);

    const handleCopyIcon = () => {
        const cellText = document.getElementById(`cell-text-${text}`).textContent;
        navigator.clipboard.writeText(cellText);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }

    const handleRedirectToClaimView = () => {
        history.push(`/cms/claimAnalysis/view/${claimId}`);
    }

    return (
        (<Grid className='d-flex align-items-center'>
            <Grid item>
                <span className={classes.clickTypography} onClick={handleRedirectToClaimView} id={`cell-text-${text}`}>{text}</span>
            </Grid>
            <Grid item>
                <IconButton
                    className='p-1 ml-1'
                    aria-label="toggle api key visible"
                    onClick={handleCopyIcon}
                    color={isCopied ? 'secondary' : 'primary'}
                    size="large">
                    <CopyIcon />
                </IconButton>
            </Grid>
        </Grid>)
    );
}

export default withRouter(CopyButton);
