import React from 'react';
import { FieldArray } from 'formik';
import { Grid, TextField, IconButton, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const useStyles = makeStyles(theme => ({
    iconButton: {
        marginTop: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
    },
    textField: {
        margin: theme.spacing(1),
        width: '80%'
    },
    label: {
        margin: theme.spacing(1),
    },
    clearButton: {
        marginTop: theme.spacing(3),
        marginLeft: '10px',
        //color: 'rgba(0, 0, 0, 0.54)'
        color: '#d9534f',
    },
    deleteIcon: {
        color: '#d9534f',
    }
}));

export default function SignatureLabelsArray({ values, handleBlur, handleChange, touched, setFieldValue, errors }) {
    const classes = useStyles();

    function getHelperText(index) {
        return touched?.signatureLabels ? touched?.signatureLabels[index] : false;
    }

    function getError(index) {
        return errors?.signatureLabels  ? errors?.signatureLabels[index] : false;
    }

    function handleClearFields() {
        setFieldValue(`signatureLabels`, ['']);
    }

    return (
        (<FieldArray
            name="signatureLabels"
            render={({ remove, insert }) => (
                values.signatureLabels.map((signatureLabel, index) => {
                    return (
                        (<Grid container key={index}>
                            <Grid item xs={6} md={7}>
                                <TextField
                                    name={`signatureLabels.${index}`}
                                    type="text"
                                    label='Signature Label'
                                    margin="normal"
                                    value={signatureLabel || ''}
                                    className={classes.textField}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    helperText={getHelperText(index) && getError(index)}
                                    error={Boolean(getHelperText(index) && getError(index))}
                                />
                            </Grid>
                            {(index >= 0 && values.signatureLabels.length > 1) && (
                                <Grid item xs={1} md={1}>
                                    <IconButton
                                        edge="start"
                                        aria-label="Delete"
                                        onClick={() => remove(index)}
                                        className={classes.iconButton}
                                        size="large">
                                        <DeleteIcon className={classes.deleteIcon} />
                                    </IconButton>
                                </Grid>
                            )}
                            {index === 0 && (
                                <Grid item xs={5} md={4}>
                                    <Grid container>
                                        <IconButton
                                            edge="start"
                                            color="primary"
                                            aria-label="Add"
                                            onClick={() => { 
                                                if(values.signatureLabels.length < 5) {
                                                    insert(values.signatureLabels.length, '')
                                                }
                                            }}
                                            className={classes.iconButton}
                                            size="large">
                                            <AddCircleIcon />
                                        </IconButton>
                                        {/* <Button
                                            size="small"
                                            onClick={() => handleClearFields(setFieldValue)}
                                            className={classes.clearButton}
                                            startIcon={<DeleteIcon />}
                                        >
                                            Clear All
                                        </Button> */}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>)
                    );
                })
            )}
        />)
    );
}