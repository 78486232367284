import React from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import SearchIcon from '@mui/icons-material/Search';
import { isNumber } from 'lodash';

const useStyles = makeStyles(theme => ({
    popOver: {
        zIndex:10000000
    },
    searchBtn:{
        background:'#196540 !important',
        borderColor:'white !important'
    },
    borderNone:{
        border:"none !important"
    },
    marginLeft:{
        marginLeft:"10px"
    }
}));

export default function SplitButton({ options, onMenuClick, className, handleSubmit, disabled,searchAction,pageName }:{
    options: string[],
    onMenuClick: (option: string, callback: () => void, handleSubmit: () => void, index: number) => void,
    className?: string,
    handleSubmit: () => void,
    disabled?: boolean,
    searchAction?: string,
    pageName?: string
}) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const callback = () => {
        setSelectedIndex(0);
    }

    const handleClick = (event, index = undefined ) => {
        const number = isNumber(index) ? index : selectedIndex;
        const selectedOption = options[number];
        if (selectedOption === 'Search') {
            handleSubmit();
        } else {
            onMenuClick(selectedOption, callback, handleSubmit, number);
        }
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        handleClick(event, index)
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup 
                    className={`${className} ${options[selectedIndex]!=="Search" ? classes.marginLeft : ''}`} 
                    variant="contained" 
                    color="primary" 
                    ref={anchorRef} 
                    aria-label="split button"
                >
                    <Button
                        className={`${classes.searchBtn} ${disabled ? classes.borderNone : ''} `} 
                        onClick={handleClick} 
                        startIcon={ 
                            pageName!=='claimAnalysis-landing' && disabled && options[selectedIndex] === "Search" ?
                                <SearchIcon/> 
                            : null
                        }
                    >
                        {options[selectedIndex]}
                    </Button>

                   {pageName==='claimAnalysis-landing' && !disabled ?
                        <Button
                            className={`${classes.searchBtn} ${disabled && classes.borderNone}`}
                            color="primary"
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    : null }
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={classes.popOver} transition>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                          !disabled && searchAction!==option && <MenuItem
                                                key={option}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                                disabled={option !== 'Search' && disabled}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}