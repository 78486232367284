import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { CodesEditDialog, Loadable, PopoverLink } from '../../../common';
import { renderErrorMessage } from '../../../util/error';
import { commaSeparatedPattern } from '../../../util/validation';
// eslint-disable-next-line no-restricted-imports
import Card from 'react-bootstrap/Card';
// eslint-disable-next-line no-restricted-imports
import CircularProgress from '@mui/material/CircularProgress';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  TextareaAutosize,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { Autocomplete } from '@mui/material';
import RuleMultiPrecidenceFieldArray from './RuleMultiPrecidenceFieldArray';

import BackButton from "../../../common/BackButton/BackButton.js";
import { SplitButton } from "../../../common/splitButton";
import { CONTRACTED_SERVICE_OUTPATIENT, CONTRACTED_SERVICE_PROFESSIONAL, MODIFIER_PAY_METHOD_MULTI_PROCEDURE, PAY_METHOD_2ND_DOLLAR, PAY_METHOD_CASE_RATE, PAY_METHOD_PER_DIEM, PAY_METHOD_STOP_LOSS, PAY_SOURCE_CHARGE, PAY_SOURCE_SCHEDULE, RULE_APPLY_AT_DATE_OF_SERVICE } from '../../../store/constants/contract';
import { FeeScheduleSelection } from "../../feeSchedulePage/feeScheduleSelectionComponent";
import { RuleNPIRoleFieldArray } from "./NPIRoleFieldArray";
import RuleModifierFieldArray from "./RuleModifierFieldArray";
import RuleNPIRateFieldArray from "./RuleNPIRateFieldArray";
import RuleSelectionCriteriaFieldArray from "./RuleSelectionCriteriaFieldArray";
import SomeTimesPayCodes from "./subConfigurations/SomeTimesPayCodes.js";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  textService: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '45%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '90%'
  },
  textFieldInline: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '90%'
  },
  formControl: {
    marginLeft: theme.spacing(1),
    width: '90%',
    marginTop: theme.spacing(2),
  },
  formControl1: {
    marginLeft: theme.spacing(5),
    width: '90%',
    marginTop: theme.spacing(1),
    maxWidth: '300px',
  },
  formToggle: {
    marginLeft: theme.spacing(1),
    width: '90%',
    marginTop: theme.spacing(4),
  },
  formToggleCard: {
    marginLeft: theme.spacing(1),
    width: '90%',
  },
  textArea: {
    marginLeft: theme.spacing(1),
    width: '95%',
  },
  ruleGroups: {
    maxHeight: '65vh',
    overflow: 'auto',
  },
  switchFormControl: {
    marginLeft: 0,
    marginTop: theme.spacing(3)
  },
  helpIcon: {
    color: 'red',
    padding: 0,
    minWidth: 'auto',
  },
  list: {
    marginTop: theme.spacing(3),
  },
  codesLabel: {
    marginLeft: theme.spacing(1),
    marginTop: 19,
    fontSize: '0.8rem',
    marginBottom: 6
  },
  textareaAutosize: {
    marginLeft: theme.spacing(1),
    width: '95%',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
    '&:focus': {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    },
    '&:focus-visible': {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    }
  },
  errorColor: {
    color: '#fd397a',
  },
  helperText: {
    marginLeft: '8px'
  },
  textAreaError: {
    borderColor: '#fd397a',
    borderWidth: '2px'
  },
  helpClass: {
    position: 'relative',
    top: 10,
    marginLeft: -12,
  },
  feeScheduleLabel: {
    marginTop: '-30px',
    marginLeft:'5px',
    fontSize:'11px'
  },
  criteriaORLabel: {
    marginTop: '3px'
  },
  criteriaToggle: {
    marginBottom: '0 !important'
  },
  autoComplete: {
    marginLeft: theme.spacing(5),
    width: '90%',
    maxWidth: '300px',
  },
}));

export default function RulesEditPage({
  intl, initialValues, rulesGetPending, ruleId, ruleTypes, methodTypes, feeSchedules, history,
  rulesCreatePending, rulesUpdatePending, mode, serviceId, payMethods, selectedServerGroups = [],
  contractId, serviceType, codeServices, codeServicesTinyPending, paySources, multiprocPrecedences, contract,
  rulesValidateCodesPending, rulesValidateCodesError, rulesValidateCodesErrorMessage, modifiers, modifiersPayMethods,
  ruleCriteria, feeScheduleLabels, isRateExist, getFeescheduleRateLabelPending, getFeescheduleLabelsPending,directoryId, 
  actions: {
    ruleGet, ruleCreate, ruleUpdate, ruleErrorReset, ruleGetMethodTypes, ruleGetPayMethods, getContract,
    ruleGetTypes, contractFeeScheduleList, codeServicesListTiny, ruleGetPaySources, ruleGetMultiPrecedences,
    ruleCodesValidate, getModifiers, getModifiersPayMethods, getRuleCriteria, resetCodesValidate, getFeeScheduleLabels,
    getFeescheduleRateLabel
  } }) {
  const classes = useStyles();
  const isEdit = mode === 'edit';
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [shouldReset, setShouldReset] = useState(false);
  const [searchAction, setSearchAction] = useState('Search');
  const [isWarnOpen, setWarnOpen] = useState(false);
  const formikRef = useRef<any>();
  const [isCodesOpen, setCodeOpen] = useState(false);
  const [selectedFeeScheduleLabelRates, setSelectedFeeScheduleLabelRates] = useState([]);

  const handleCallback = () => {
    history.push(`/cms/contract/view/${contractId}/${directoryId || 'root'}`);
  }

  useEffect(() => {
    getRuleCriteria();
  }, [getRuleCriteria]);

  useEffect(() => {
    getModifiersPayMethods();
  }, [getModifiersPayMethods])

  useEffect(() => {
    getModifiers();
  }, [getModifiers])

  useEffect(() => {
    setSelectedGroups(selectedServerGroups);
  }, [selectedServerGroups]);

  useEffect(() => {
    if (contract === undefined) {
      getContract(contractId)
    }
  }, [contract, getContract]);

  useEffect(() => {
    if (mode === 'edit' && initialValues && initialValues.feeScheduleId) {
      getFeeScheduleLabels(initialValues.feeScheduleId, getFeescheduleLabelsCallback, initialValues.feescheduleLabelToRateXRefId, null);
    }
    // if (mode === 'edit' && initialValues && initialValues.feeScheduleId, initialValues.feescheduleLabelToRateXRefId) {
    //   getFeescheduleRateLabel(initialValues.feeScheduleId, initialValues.feescheduleLabelToRateXRefId, 
    //     `feescheduleLabelToRateXRefId`, setFieldValue,feescheduleLabelToRateXRefChangeCallback);
    // }
  }, [mode, initialValues, getFeeScheduleLabels]);

  const renderValidationMessage = () => {
    return renderErrorMessage(rulesValidateCodesError, rulesValidateCodesErrorMessage);
  }

  const ruleTypesCallback = (rTypes) => {
    if (rTypes && rTypes.length > 0 && !isEdit) {
      const ruleType = rTypes.find(x => x.name === 'RULE');
      if (ruleType) {
        if (formikRef.current) {
          formikRef.current.setFieldValue('ruleTypeId', ruleType.id);
        }
      }
    }
  }

  useEffect(() => {
    ruleGetMethodTypes();
    ruleGetTypes(ruleTypesCallback);
    ruleGetPayMethods();
    contractFeeScheduleList();
    codeServicesListTiny();
    ruleGetPaySources();
    ruleGetMultiPrecedences();
  }, [ruleGetMethodTypes, ruleGetTypes, ruleGetPayMethods,
    contractFeeScheduleList, codeServicesListTiny, ruleGetPaySources, ruleGetMultiPrecedences
  ]);

  useEffect(() => {
    if (mode === 'edit') {
      ruleGet(ruleId);
    }
  }, [mode, ruleGet, ruleId]);

  useEffect(() => {
    return function cleanup() {
      ruleErrorReset();
      resetCodesValidate();
    }
  }, [ruleErrorReset]);

  useEffect(() => {
    if (formikRef.current && formikRef.current.errors) {
      const errors = formikRef.current.errors;
      if (rulesValidateCodesError) {
        formikRef.current.setFieldError('carveoutCodes', 'Codes are incorrect');
      } else {
        delete errors.carveoutCodes;
        formikRef.current.setErrors(errors);
      }
    }
  }, [rulesValidateCodesError]);

  function handleGroupSelect(event, checked, id) {
    if (checked) {
      setSelectedGroups([...selectedGroups, id]);
    } else {
      const filteredGroups = selectedGroups.filter(x => x !== id);
      setSelectedGroups(filteredGroups);
    }
  }

  function isRuleType(ruleTypeId, keyword) {
    if (ruleTypeId && ruleTypes) {
      const ruleType = ruleTypes.find(x => x.id === ruleTypeId);
      if (ruleType) {
        return ruleType.name === keyword;
      }
    }
    return false;
  }

  // function isMethodTypeDisbled(isCodeService) {
  //   return isCodeService === 'true';
  // }

  function IsPaySourceTypeSchedule(paySourceId) {
    if (paySources && paySources.length > 0) {
      const paySource = paySources.find(x => x.id === paySourceId);
      if (paySource && paySource.name === PAY_SOURCE_SCHEDULE) {
        return true;
      }
    }

    return false;
  }

  function isPayMethod2ndDollar(payMethodId) {
    if (payMethods && payMethods.length > 0) {
      const payMethod = payMethods.find(item => item.id === payMethodId)
      if (payMethod && payMethod.name === PAY_METHOD_2ND_DOLLAR) {
        return true
      }
    }
    return false
  }

  function isPayMethodStopLoass(payMethodId) {
    if (payMethods && payMethods.length > 0) {
      const payMethod = payMethods.find(item => item.id === payMethodId)
      if (payMethod && payMethod.name === PAY_METHOD_STOP_LOSS) {
        return true
      }
    }
    return false
  }

  function isPayMethodMultiProcedure(payMethodId) {
    if (modifiersPayMethods && modifiersPayMethods.length > 0) {
      const payMethod = modifiersPayMethods.find(item => item.id === payMethodId)
      if (payMethod && payMethod.name === MODIFIER_PAY_METHOD_MULTI_PROCEDURE) {
        return true
      }
    }
    return false
  }

  function IsPaySourceTypeChargeOrSchedule(paySourceId) {
    if (paySources && paySources.length > 0) {
      const paySource = paySources.find(x => x.id === paySourceId);
      if (paySource && (paySource.name === 'Charge' || paySource.name === 'Schedule')) {
        return true;
      }
    }

    return false;
  }
  function IsPaySourceType(paySourceId, targetType) {
    if (paySources && paySources.length > 0) {
      const paySource = paySources.find(x => x.id === paySourceId);
      if (paySource && paySource.name === targetType) {
        return true;
      }
    }
    return false;
  }

  const saveAndResetHandler = (callBack) => {
    setShouldReset(true);
    callBack();

  }
  const onMenuClick = (option, callback, callbackSubmit, actionIndex) => {
    setSearchAction(option);

    if (callback) {
      callback();
    }
    if (option === 'Save and Add') {
      saveAndResetHandler(callbackSubmit);
    } else if (option === 'Save' && actionIndex === 0) {
      callbackSubmit();
    }
  };

  const handleCarveoutCodeChange = (setFieldValue) => (e) => {
    const withoutLineBreaks = e.target.value.replace(/[\r\n]/gm, '');
    setFieldValue('carveoutCodes', withoutLineBreaks)
  }

  function handleCarveoutCodeBlur() {
    if (formikRef.current && formikRef.current.values && formikRef.current.values.carveoutCodes) {
      ruleCodesValidate({
        "codes": formikRef.current.values.carveoutCodes,
      });
    }
  }

  const handlePayMethodChange = (setFieldValue) => (e) => {
    setFieldValue("paymethodId", e.target.value);
    if (payMethods && payMethods.length > 0) {
      const payMethod = payMethods.find(x => x.id === e.target.value);
      if (payMethod) {
        if (payMethod.name === PAY_METHOD_CASE_RATE || payMethod.name === PAY_METHOD_PER_DIEM) {
          setFieldValue("isCircuitBreaker", true)
        } else if (payMethod.name === PAY_METHOD_2ND_DOLLAR) {
          setFieldValue('threshold', true);
        } else if (payMethod.name === PAY_METHOD_STOP_LOSS) {
          setFieldValue('applyAt', 'Claim');
        }
      }
    }
  }

  function handleClose() {
    setWarnOpen(false);
  }

  const serviceChangeHandler = (setFieldValue) => (e) => {
    if (e.target.value === 'none') {
      setFieldValue('codeServiceId', '');
    } else {
      setFieldValue('codeServiceId', e.target.value);
    }
  }

  function handleRuleChange(event, handleChange, setFieldValue) {
    handleChange(event);
    if (isRuleType(event.target.value, 'DEFAULT')) {
      setFieldValue('codeSelectionType', 'Codes');
      // if rule type is default  then change paysource to charge 
      if (paySources && paySources.length > 0) {
        const paySource = paySources.find(x => x.name === PAY_SOURCE_CHARGE);
        if (paySource) {
          setFieldValue('paySourceId', paySource?.id);
        }
      }
    }
  }
  function hoverColumn(text) {
    return (
      <PopoverLink
        columnData={<i className={`${classes.helpIcon} fas fa-question-circle text-info`}></i>}
        hoverData={[
          {
            data: text
          }
        ]}
      />
    );
  }

  function handleCodesOpen() {
    setCodeOpen(true);
  }
  function handleCodesClose() {
    setCodeOpen(false);
  }
  const handleCodesChange = (setFieldValue) => (codes) => {
    const withoutLineBreaks = codes.replace(/[\r\n]/gm, '');
    setFieldValue('carveoutCodes', withoutLineBreaks);
    ruleCodesValidate({ "codes": withoutLineBreaks })
  }

  const handleApplyAtChange = (setFieldValue) => (e) => {
    setFieldValue('applyAt', e.target.value);
    if (e.target.value !== RULE_APPLY_AT_DATE_OF_SERVICE) {
      setFieldValue('dosMaxRate', 0);
    }
  }

  const handleCodeSelectionChange = (setFieldValue) => (e) => {
    setFieldValue('codeSelectionType', e.target.value);

    // if code selection is Fee Schedule then change paysource to schedule 
    if (e.target.value === 'Fee Schedule' && paySources && paySources.length > 0) {
      const paySource = paySources.find(x => x.name === PAY_SOURCE_SCHEDULE);
      if (paySource) {
        setFieldValue('paySourceId', paySource?.id);
      }
    }

  }

  const handleFeeScheduleLabelToRateXrefChange = (id, setFieldValue, value, feeScheduleId) => {
    if(value && feeScheduleId) {
      getFeescheduleRateLabel(feeScheduleId, value.id, id, setFieldValue, feescheduleLabelToRateXRefChangeCallback);
    }
    else {
      setFieldValue(id, value ? value.id : null);
    }
  }

  const feescheduleLabelToRateXRefChangeCallback = (id, setFieldValue, labelId, newValue) => {
    setSelectedFeeScheduleLabelRates(prevItems => {
      const index = prevItems.findIndex(item => item.id === id);
      if (index !== -1) {
        // Update existing item
        const updatedItems = [...prevItems];
        updatedItems[index] = { ...updatedItems[index], value: newValue };
        return updatedItems;
      } else {
        // Add new item
        return [...prevItems, { id, value: newValue }];
      }
    });
    if(setFieldValue)
      setFieldValue(id, labelId);
  }

  const getFeescheduleLabelsCallback = (newFeeScheduleLabels, feeScheduleId, feescheduleLabelToRateXRefId, setFieldValue) => {
    if (!newFeeScheduleLabels || newFeeScheduleLabels.length === 0 || !setFieldValue) {
      setFieldValue('feescheduleLabelToRateXRefId', null);
    }
    else {
      let foundLabel = newFeeScheduleLabels.find(x => x.id === feescheduleLabelToRateXRefId) || newFeeScheduleLabels.find(x => x.rateColumn === "Rate") || newFeeScheduleLabels[0];
      if(foundLabel){
        setFieldValue('feescheduleLabelToRateXRefId', foundLabel);
        getFeescheduleRateLabel(feeScheduleId, foundLabel.id, 'feescheduleLabelToRateXRefId', setFieldValue, feescheduleLabelToRateXRefChangeCallback);
      }
    }
  }

  

  
  

  const actions = ['Save', 'Save and Add'];

  return (<>
    <Loadable loading={rulesGetPending || rulesCreatePending || rulesUpdatePending || codeServicesTinyPending || getFeescheduleLabelsPending || getFeescheduleRateLabelPending}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        innerRef={formikRef}
        // validationSchema={mode === 'create' ? addValidationSchema : addValidationSchema}
        validate={values => {
          const errors : Record<string,string> = {};
          const npiRegex = /^\d{10}$/;
          if (!values.name) {
            errors.name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          // else if (!values.name.match(alphaNumericSpacePattern)) {
          //   errors.name = "Only alpha numeric values are allowed";
          // }

          if (!values.ruleTypeId) {
            errors.ruleTypeId = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          if (isRuleType(values.ruleTypeId, 'DEFAULT')) {
            if (!values.methodId) {
              errors.methodId = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
          }
          if (values.codeSelectionType === 'Codes' && !isRuleType(values.ruleTypeId, 'DEFAULT')) {
            if (!values.methodId) {
              errors.methodId = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
          }

          if (values.maxRate) {
            if (!values.maxRateValue) {
              errors.maxRateValue = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
          }
          if (values.threshold) {
            if (!values.thresholdValue) {
              errors.thresholdValue = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
          }

          if (!values.paymethodId) {
            errors.paymethodId = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.paySourceId) {
            errors.paySourceId = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.description) {
            errors.description = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          if (!values.payvalue && values.payvalue !== 0) {
            errors.payvalue = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          if (values.applyAt === RULE_APPLY_AT_DATE_OF_SERVICE && (values.dosMaxRate === '' || values.dosMaxRate === null)) {
            errors.dosMaxRate = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if ((isRuleType(values.ruleTypeId, 'CARVEOUT') ||
            isRuleType(values.ruleTypeId, 'RULE'))
            && values.codeSelectionType === "Codes"
            && !values.carveoutCodes) {
            errors.carveoutCodes = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          if (!IsPaySourceTypeSchedule(values.paySourceId)
            && (isRuleType(values.ruleTypeId, 'RULE') || isRuleType(values.ruleTypeId, 'CARVEOUT'))
            && values.codeSelectionType === "Code Service"
            && !values.codeServiceId) {
            errors.codeServiceId = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if ((IsPaySourceTypeSchedule(values.paySourceId) || values.codeSelectionType === "Fee Schedule") &&
            !values.feeScheduleId) {
            errors.feeScheduleId = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if ((IsPaySourceTypeSchedule(values.paySourceId) || values.codeSelectionType === "Fee Schedule") &&
            values.feeScheduleId && !values.feescheduleLabelToRateXRefId) {
              errors.feescheduleLabelToRateXRefId = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
          }

          if ((IsPaySourceTypeSchedule(values.paySourceId) || values.codeSelectionType === "Fee Schedule") &&
            values.feeScheduleId && values.feescheduleLabelToRateXRefId) {
              if(selectedFeeScheduleLabelRates && selectedFeeScheduleLabelRates.find(x=> x.id === 'feescheduleLabelToRateXRefId')
                && selectedFeeScheduleLabelRates.find(x=> x.id === 'feescheduleLabelToRateXRefId').value) {
                  errors.feescheduleLabelToRateXRefId = 'This rate label has no value.';
              }
          }

          if (rulesValidateCodesError) {
            errors.carveoutCodes = intl.formatMessage({
              id: "RULE.INVALID.CODES"
            });
          }
          if (Boolean(values.useMultiProcedurePercent) === true) {
            const multiPrecidenceError = values.multiProcedurePercents.map((multiProc) => {
              const multiPrecidenceError = {};
              let hasError = false;
              
              if (!multiProc.percentage && multiProc.percentage!==0) {
                multiPrecidenceError['percentage'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }
              if (multiProc.percentage<0) {
                multiPrecidenceError['percentage'] = "Only Positive Values";
                hasError=true

              }

              if (!multiProc.precedenceId) {
                multiPrecidenceError['precedenceId'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }

              if (hasError) {
                return multiPrecidenceError;
              }

              return false;
            });

            const filteredArray = multiPrecidenceError.filter(value => Object.keys(value).length > 0);

            if (filteredArray.length > 0) {
              errors['multiProcedurePercents'] = multiPrecidenceError;
            }
          }

          if (values.isSometimesPay) {
            if (!commaSeparatedPattern.test(values.sometimesPayCodes)) {
              errors.sometimesPayCodes = 'Codes should be only in comma separated format';
            }
          }

          if (IsPaySourceTypeChargeOrSchedule(values.paySourceId) && Boolean(values.useNpiRate) === true) {
            const ruleNPIRateError = values.ruleToNpirateXref.map((multiProc, index) => {
              const ruleNPIRateError = {};
              
              let hasError = false;

              if (!multiProc.npi) {
                ruleNPIRateError['npi'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }

              if (multiProc.npi && !npiRegex.test(multiProc.npi)) {
                ruleNPIRateError['npi'] = "NPI Must be a 10 Digit Number";
                hasError = true;
              }

              if (multiProc.rate?.length === 0) {
                ruleNPIRateError['rate'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }
              if (IsPaySourceType(values.paySourceId, 'Schedule') && multiProc.rate?.length !== 0 && (multiProc.rate < 0)) {
                ruleNPIRateError['rate'] = 'The percentage must be greater than 0';
                hasError = true;
              }

              if ((IsPaySourceTypeSchedule(values.paySourceId) || values.codeSelectionType === "Fee Schedule") &&
                values.feeScheduleId && multiProc.feescheduleLabelToRateXRefId) {
                  if(selectedFeeScheduleLabelRates && selectedFeeScheduleLabelRates.find(x=> x.id === `ruleToNpirateXref.${index}.feescheduleLabelToRateXRefId`)
                    && selectedFeeScheduleLabelRates.find(x=> x.id === `ruleToNpirateXref.${index}.feescheduleLabelToRateXRefId`).value) {
                      ruleNPIRateError['feescheduleLabelToRateXRefId'] = 'This rate label has no value.';
                      hasError = true;
                  }
              }

              if (hasError) {
                return ruleNPIRateError;
              }

              return false;
            });

            const filteredArray = ruleNPIRateError.filter(value => Object.keys(value).length > 0);

            if (filteredArray.length > 0) {
              errors['ruleToNpirateXref'] = ruleNPIRateError;
            }
          }

          if (serviceType === CONTRACTED_SERVICE_PROFESSIONAL && Boolean(values.useNpiRole) === true) {
            const ruleNPIRoleError = values.ruleToNpiroleXref.map((multiProc, index) => {
              const ruleNPIRoleError = {};
              let hasError = false;

              if (!multiProc.npiRoleId) {
                ruleNPIRoleError['npiRoleId'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }

              if (multiProc.rate?.length === 0) {
                ruleNPIRoleError['rate'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }

              if ((IsPaySourceTypeSchedule(values.paySourceId) || values.codeSelectionType === "Fee Schedule") &&
                values.feeScheduleId && multiProc.feescheduleLabelToRateXRefId) {
                  if(selectedFeeScheduleLabelRates && selectedFeeScheduleLabelRates.find(x=> x.id === `ruleToNpiroleXref.${index}.feescheduleLabelToRateXRefId`)
                    && selectedFeeScheduleLabelRates.find(x=> x.id === `ruleToNpiroleXref.${index}.feescheduleLabelToRateXRefId`).value) {
                      ruleNPIRoleError['feescheduleLabelToRateXRefId'] = 'This rate label has no value.';
                      hasError = true;
                  }
              }

              if (hasError) {
                return ruleNPIRoleError;
              }

              return false;
            });

            const filteredArray = ruleNPIRoleError.filter(value => Object.keys(value).length > 0);

            if (filteredArray.length > 0) {
              errors['ruleToNpiroleXref'] = ruleNPIRoleError;
            }

          }
          
          // Modifier Error
          if (values.useModifiers) {
            const ruleToModifierError = values.ruleToModifierXref.map((modifier) => {
              const modifierError = {};
              let hasError = false;

              if (!modifier.modifierId) {
                modifierError['modifierId'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }

              if (!modifier.payMethodId) {
                modifierError['payMethodId'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }

              if (isPayMethodMultiProcedure(modifier.payMethodId)) {
                const emptyItem = modifier?.modifierMultiProcedurePercentage?.find(item => item.occurance === '' || item.value === '');
                if (emptyItem) {
                  modifierError['modifierMultiProcedurePercentage'] = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                  hasError = true;
                }
              } else {
                if (modifier.value?.length === 0) {
                  modifierError['value'] = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                  hasError = true;
                }
                if (modifier?.value<0) {
                  modifierError['value'] = "Only Positive Values";
                  hasError=true
                }
              }

              if (hasError) {
                return modifierError;
              }

              return false;
            });
            const filteredModifierError = ruleToModifierError.filter(value => Object.keys(value).length > 0);

            if (filteredModifierError.length > 0) {
              errors['ruleToModifierXref'] = ruleToModifierError;
            }
          }

          // Rule Selection Criteria Error
          const ruleCriteriaError = values.ruleSelectionCriteriaXref.map((crite,index) => {
            const criteriaError = {};

            let hasError = false;
            if (crite.ruleSelectionCriteriaId !== null) {
              if (!crite.equator) {
                criteriaError['equator'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }

              if (!crite.value) {
                criteriaError['value'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }
            }


            for (let i = index - 1; i >= 0; i--) {
              const previous = values.ruleSelectionCriteriaXref[i];
              const doesCriteriaNameMatces =
                  crite.ruleSelectionCriteriaId?.name ===
                  previous.ruleSelectionCriteriaId?.name;
              const validationMsg =
                  "Duplicate selection criteria are not allowed. Please review your input";

              if (
                  crite.isBetween &&
                  previous.isBetween &&
                  crite.startsAt === previous.startsAt &&
                  crite.endsAt === previous.endsAt &&
                  doesCriteriaNameMatces
              ) {
                  criteriaError["startsAt"] = validationMsg;
                  hasError = true;
                  break;
              } else if (
                  crite.value === previous.value &&
                  crite.equator === previous.equator &&
                  crite.contractSelectionCriteriaId?.name ===
                      previous.contractSelectionCriteriaId?.name
              ) {
                  criteriaError["value"] = validationMsg;
                  hasError = true;
                  break;
              }
          }

            if (hasError) {
              return criteriaError;
            }


            return false;

          });
          const filteredCriteriaError = ruleCriteriaError.filter(value => Object.keys(value).length > 0);

          if (filteredCriteriaError.length > 0) {
            errors['ruleSelectionCriteriaXref'] = ruleCriteriaError;
          }

          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {

          if (IsPaySourceTypeSchedule(values.paySourceId)
            && isRuleType(values.ruleTypeId, 'RULE')
            && !values.codeServiceId
            && !isWarnOpen
            && values.codeSelectionType === 'Code Service') {

            setWarnOpen(true)
            return
          }

          let payload = {
            name: values.name,
            ruleTypeId: values.ruleTypeId,
            methodId: values.methodId,
            paymethodId: values.paymethodId,
            payvalue: values.payvalue,
            feeScheduleId: (IsPaySourceTypeSchedule(values.paySourceId) || values.codeSelectionType === 'Fee Schedule') ? values.feeScheduleId : null,
            feescheduleLabelToRateXRefId: (IsPaySourceTypeSchedule(values.paySourceId) || values.codeSelectionType === 'Fee Schedule') ? (values.feescheduleLabelToRateXRefId ?? null) : null,
            paySourceId: values.paySourceId,
            contractServiceId: serviceId,
            description: values.description,
            usePaidCode: values.usePaidCode,
            payInAddition: values.payInAddition,
            fromDate: values.fromDate,
            toDate: values.toDate,
            isCircuitBreaker: values.isCircuitBreaker,
            lesserOf: values.lesserOf,
            codeSelectionType: values.codeSelectionType,
            applyAt: values.applyAt,
            maxRate: values.maxRate,
            threshold: values.threshold,
            precedence: values.precedence,
            useNpiRole: values.useNpiRole,
            useModifiers: values.useModifiers,
            ignoreClaim: values.ignoreClaim,
            ignoreCmsConfiguration: values.ignoreCmsConfiguration,
            ruleSelectionCriteriaXref: values.ruleSelectionCriteriaXref
              .filter(x => x.ruleSelectionCriteriaId !== null || x.equator !== '' || x.value !== '')
              .map(item => ({ ruleSelectionCriteriaId: item.ruleSelectionCriteriaId.id, equator: item.equator, value: item.value })),

            isSometimesPay: values.isSometimesPay,
            sometimesPayCodes: values.isSometimesPay ? values.sometimesPayCodes : null,
            isCriteriaOperatorAnd: true,
          } as Record<string, any>;

          if (values.useNpiRole) {
            payload = {
              ...payload,
              ruleToNpiroleXref: values.ruleToNpiroleXref
                .filter(x => x.npiRoleId !== '' || x.rate !== '')
                .map(x=> { 
                  if(payload.feeScheduleId === null) { x.feescheduleLabelToRateXRefId = null; }
                  return x;
                })
            }
          }

          if (values.useModifiers) {
            payload = {
              ...payload,
              ruleToModifierXref: values.ruleToModifierXref
                .filter(x => x.modifierId !== '' || x.payMethodId !== '' || x.value !== '')
                .map(item => ({ ...item, modifierId: item.modifierId?.id }))
            }
          }

          if (isRuleType(values.ruleTypeId, 'RULE') && serviceType === CONTRACTED_SERVICE_OUTPATIENT) {
            payload = {
              ...payload,
              groupIds: selectedGroups,
            }
          }

          if (!isRuleType(values.ruleTypeId, 'DEFAULT')) {
            if (values.codeSelectionType === 'Code Service') {
              payload = {
                ...payload,
                carveoutCodes: '',
                codeServiceId: values.codeServiceId,
              }
            } else {
              payload = {
                ...payload,
                carveoutCodes: values.carveoutCodes,
                codeServiceId: null,
              }
            }
          }

          payload = {
            ...payload,
            useMultiProcedurePercent: values.useMultiProcedurePercent,
            multiProcedurePercentageToRuleXref: values.useMultiProcedurePercent ? values.multiProcedurePercents.filter(x => x.precedenceId !== '' || x.percentage !== '') : [],
          }

          if (IsPaySourceTypeSchedule(values.paySourceId)) {
            payload = {
              ...payload,
              considerFsTc: values.considerFsTc
            }
          }

          if (IsPaySourceTypeChargeOrSchedule(values.paySourceId)) {
            payload = {
              ...payload,
              useNpiRate: values.useNpiRate,
              ruleToNpirateXref: values.useNpiRate ? values.ruleToNpirateXref.filter(x => x.npi !== '' || x.rate !== '').map(x=> { 
                if(payload.feeScheduleId === null) { x.feescheduleLabelToRateXRefId = null; }
                return x;
              }) : []
            }
          }
          if (values.maxRate) {
            payload = {
              ...payload,
              maxRateValue: values.maxRateValue
            }
          }
          if (values.threshold) {
            payload = {
              ...payload,
              thresholdValue: values.thresholdValue
            }
          }
          if (values.applyAt === RULE_APPLY_AT_DATE_OF_SERVICE) {
            payload = {
              ...payload,
              dosMaxRate: values.dosMaxRate
            }
          } else {
            payload = {
              ...payload,
              dosMaxRate: 0
            }
          }
          if (mode === 'create') {
            if (shouldReset) {
              ruleCreate(payload, resetForm);
              setShouldReset(false);
            } else {
              ruleCreate(payload, handleCallback);
            }
          }
          else {
            payload = {
              ...payload,
              id: values.id,
            }
            ruleUpdate(values.id, payload, handleCallback);
          }
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty
        }) => (
          <>
            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.root}>
              <Helmet title="Contract Management" />
              <Portlet>
                <PortletHeader
                  title={`${contract?.name || ''} (${serviceType || ''})`}
                  showTitle={true}
                  toolbar={
                    <PortletHeaderToolbar>
                      <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute={`/cms/contract/view/${contractId}`} />
                      <SplitButton
                        className={'ml-3'}
                        options={actions}
                        handleSubmit={handleSubmit}
                        onMenuClick={onMenuClick}
                        disabled={rulesCreatePending || rulesUpdatePending || (isEdit && !dirty)}
                      />
                    </PortletHeaderToolbar>
                  }
                />
                <PortletBody>
                  <Grid container>
                    <Grid item xl={9} lg={10} md={12} sm={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            required
                            label="Rule Name"
                            name="name"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            helperText={touched.name && errors.name}
                            error={Boolean(touched.name && errors.name)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Description"
                            name="description"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            required
                            helperText={(touched.description && errors.description) ?? 'This description is used in the MRF as additional notes.'}
                            error={Boolean(touched.description && errors.description)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="role">Rule Type</InputLabel>
                            <Select
                              required
                              value={values.ruleTypeId}
                              onChange={(event) => handleRuleChange(event, handleChange, setFieldValue)}
                              error={Boolean(touched.ruleTypeId && errors.ruleTypeId)}
                              input={<Input id="ruleTypeId" />}
                              inputProps={{
                                name: "ruleTypeId",
                                id: "ruleTypeId"
                              }}
                            >
                              {ruleTypes.map(type => (
                                <MenuItem
                                  key={type.id}
                                  value={type.id}
                                >
                                  {type.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error>{touched.ruleTypeId && errors.ruleTypeId}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="paySourceId">Pay Source</InputLabel>
                            <Select
                              disabled={isRuleType(values.ruleTypeId, 'DEFAULT') || values.codeSelectionType === 'Fee Schedule'}
                              value={values.paySourceId}
                              onChange={handleChange}
                              error={Boolean(touched.paySourceId && errors.paySourceId)}
                              input={<Input id="paySourceId" />}
                              inputProps={{
                                name: "paySourceId",
                                id: "paySourceId"
                              }}
                            >
                              {paySources.map(paySource => (
                                <MenuItem
                                  key={paySource.id}
                                  value={paySource.id}
                                >
                                  {paySource.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error>{touched.paySourceId && errors.paySourceId}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl disabled={isPayMethodStopLoass(values.paymethodId)} component="fieldset" className={classes.formToggle}>
                            <FormLabel component="legend">Apply rule at level:</FormLabel>
                            <RadioGroup
                              aria-label="applyAt"
                              name="applyAt"
                              value={values.applyAt}
                              onChange={handleApplyAtChange(setFieldValue)}
                              row
                            >
                              <FormControlLabel value="Charge" control={<Radio />} label="Charge" />
                              <FormControlLabel value="Rule" control={<Radio />} label="Rule" />
                              <FormControlLabel value="Claim" control={<Radio />} label="Claim" />
                              {(serviceType === CONTRACTED_SERVICE_OUTPATIENT || serviceType === CONTRACTED_SERVICE_PROFESSIONAL) &&
                                <FormControlLabel value={RULE_APPLY_AT_DATE_OF_SERVICE} control={<Radio />} label="Date of Service" />
                              }
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} className="mt-3">
                          <Card>
                            <Card.Header as="h5">Code Selection</Card.Header>
                            <Card.Body>
                              <Card.Subtitle className="mb-2 text-muted">codes can be defined as code service, feeschedule or listed down in Codes textarea</Card.Subtitle>
                              <Grid container>
                                <Grid item xs={12}>
                                  {renderValidationMessage()}
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl component="fieldset" className={classes.formToggleCard}>
                                    <RadioGroup
                                      aria-label="codeSelectionType"
                                      name="codeSelectionType"
                                      value={values.codeSelectionType}
                                      onChange={handleCodeSelectionChange(setFieldValue)}
                                      row
                                    >
                                      <FormControlLabel disabled={isRuleType(values.ruleTypeId, 'DEFAULT')} value="Code Service" control={<Radio />} label="Code Service" />
                                      <FormControlLabel disabled={isRuleType(values.ruleTypeId, 'DEFAULT')} value="Fee Schedule" control={<Radio />} label="Fee Schedule" />
                                      <FormControlLabel disabled={isRuleType(values.ruleTypeId, 'DEFAULT')} value="Codes" control={<Radio />} label="Codes" />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                {isRuleType(values.ruleTypeId, 'DEFAULT') || values.codeSelectionType === 'Code Service' && (
                                  <Grid item xs={4}>
                                    <FormControl className={classes.formControl}>
                                      <InputLabel htmlFor="codeServiceId">Service</InputLabel>
                                      <Select
                                        value={values.codeServiceId}
                                        onChange={serviceChangeHandler(setFieldValue)}
                                        error={Boolean(touched.codeServiceId && errors.codeServiceId)}
                                        input={<Input id="codeServiceId" />}
                                        inputProps={{
                                          name: "codeServiceId",
                                          id: "codeServiceId"
                                        }}
                                      >
                                        <MenuItem
                                          value='none'
                                        >
                                          None
                                        </MenuItem>
                                        {codeServices.map(codeService => (
                                          <MenuItem
                                            key={codeService.id}
                                            value={codeService.id}
                                          >
                                            {codeService.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <FormHelperText error>{touched.codeServiceId && errors.codeServiceId}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                )}
                                {values.codeSelectionType === 'Codes' &&
                                  // !isRuleType(values.ruleTypeId, 'DEFAULT')) &&
                                  (
                                    <Grid item xs={4}>
                                      <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="methodId">Method Type</InputLabel>
                                        <Select
                                          value={values.methodId}
                                          onChange={handleChange}
                                          error={Boolean(touched.methodId && errors.methodId)}
                                          input={<Input id="methodId" />}
                                          inputProps={{
                                            name: "methodId",
                                            id: "methodId"
                                          }}
                                        >
                                          {methodTypes.map(methodType => (
                                            <MenuItem
                                              key={methodType.id}
                                              value={methodType.id}
                                            >
                                              {methodType.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        <FormHelperText error>{touched.methodId && errors.methodId}</FormHelperText>
                                      </FormControl>
                                    </Grid>
                                  )}

                                {IsPaySourceTypeSchedule(values.paySourceId) && (
                                  <>
                                    <Grid item xs={12} >
                                      <Box display='flex' alignItems='center' sx={{minWidth: '100%'}}>
                                          <Box sx={{flexGrow:0, minWidth: 0, overflow: 'hidden'}}>
                                          <FormControl className={classes.formControl} >
                                            <InputLabel className={`MuiInputLabel-shrink ${classes.feeScheduleLabel}`}>Fee Schedule</InputLabel>
                                            <FeeScheduleSelection value={values?.feeScheduleId ? values?.feeScheduleName : ''} 
                                            defaultValue='Search Fee Schedule' 
                                            handleSetFeeSchedule={(rowData)=>{setFieldValue("feeScheduleId",rowData?.id)
                                              setFieldValue("feeScheduleName",rowData?.name)
                                              setFieldValue("feescheduleLabelToRateXRefId", null)
                                              setFieldValue("ruleToNpirateXref", values.ruleToNpirateXref?.map(x=> ({...x, feescheduleLabelToRateXRefId: null})) ?? [])
                                              setFieldValue("ruleToNpiroleXref", values.ruleToNpiroleXref?.map(x=> ({...x, feescheduleLabelToRateXRefId: null})) ?? [])
                                              getFeeScheduleLabels(rowData?.id, getFeescheduleLabelsCallback, null, setFieldValue);
                                          }} />
                                            <FormHelperText error>{touched.feeScheduleId && errors.feeScheduleId}</FormHelperText>
                                          </FormControl>
                                          </Box>
                                          
                                          {values.feeScheduleId && feeScheduleLabels &&  
                                          <Autocomplete
                                              fullWidth
                                              className={classes.autoComplete}
                                              value={feeScheduleLabels.length > 0 ? (feeScheduleLabels.find(x => x.id === values.feescheduleLabelToRateXRefId) || feeScheduleLabels.find(x => x.rateColumn === "Rate") || feeScheduleLabels[0]) : values?.selectedfeescheduleLabelToRateXRef ?? null}
                                              disableClearable={true}
                                              onChange={(e, value) => handleFeeScheduleLabelToRateXrefChange(`feescheduleLabelToRateXRefId`, setFieldValue, value, values.feeScheduleId)}
                                              isOptionEqualToValue={(option, value) => value ? option.id === value.id : null}
                                              options={feeScheduleLabels}
                                              getOptionLabel={(option) => option.label}
                                              renderInput={(params) =>
                                                  <TextField {...params}
                                                      name={`feeScheduleLabels`}
                                                      label="Rate Label"
                                                      error={Boolean(errors.feescheduleLabelToRateXRefId)}
                                                      helperText={errors.feescheduleLabelToRateXRefId}
                                                      margin="normal" />
                                              }
                                          />
                                        }

                                      </Box>
                                        


                                      
                                    </Grid>
                                    
                                  </>
                                )}
                                {((isRuleType(values.ruleTypeId, 'CARVEOUT')
                                  || isRuleType(values.ruleTypeId, 'RULE'))
                                  && (values.codeSelectionType === 'Codes')) && (
                                    <>
                                      <Grid item xs={8}>
                                        <FormLabel className={`${classes.codesLabel} ${(Boolean(touched.carveoutCodes && errors.carveoutCodes)) ? classes.errorColor : ''}`} disabled={values.isCodeService === 'true'} id="carveout-codes">Codes *
                                          <IconButton onClick={handleCodesOpen} className="p-1" size="large">
                                            <ZoomInIcon />
                                          </IconButton>
                                        </FormLabel>
                                        <TextareaAutosize
                                          aria-labelledby="carveout-codes"
                                          minRows='3'
                                          maxRows='8'
                                          // placeholder="Codes"
                                          name="carveoutCodes"
                                          className={`${classes.textareaAutosize} ${(Boolean(touched.carveoutCodes && errors.carveoutCodes)) ? classes.textAreaError : ''}`}
                                          onBlur={() => handleCarveoutCodeBlur()}
                                          onChange={handleCarveoutCodeChange(setFieldValue)}
                                          value={values.carveoutCodes}
                                        />
                                        <p className={`${(Boolean(touched.carveoutCodes && errors.carveoutCodes)) && classes.errorColor} ${classes.helperText} MuiFormHelperText-root`}>e.g A1000-A1099; 100; 101; 70001-70010; 90001;</p>
                                      </Grid>
                                      {rulesValidateCodesPending && (
                                        <Grid item xs={1} className='pt-4'>
                                          <CircularProgress size={25} />
                                        </Grid>
                                      )}
                                    </>
                                  )}
                              </Grid>
                            </Card.Body>
                          </Card>
                        </Grid>
                        <Grid item xs={12} className="mt-5">
                          <Card>
                            <Card.Header as="h5">Payment</Card.Header>
                            <Card.Body>
                              <Grid container>
                                <Grid item xs={4}>
                                  <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="paymethodId">Pay Method</InputLabel>
                                    <Select
                                      required
                                      value={values.paymethodId}
                                      onChange={handlePayMethodChange(setFieldValue)}
                                      error={Boolean(touched.paymethodId && errors.paymethodId)}
                                      input={<Input id="paymethodId" />}
                                      inputProps={{
                                        name: "paymethodId",
                                        id: "paymethodId"
                                      }}
                                    >
                                      {payMethods.map(paymethod => (
                                        <MenuItem
                                          key={paymethod.id}
                                          value={paymethod.id}
                                        >
                                          {paymethod.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <FormHelperText error>{touched.paymethodId && errors.paymethodId}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    required
                                    label="Pay Value"
                                    name="payvalue"
                                    className={classes.textField}
                                    margin="normal"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.payvalue}
                                    helperText={touched.payvalue && errors.payvalue}
                                    error={Boolean(touched.payvalue && errors.payvalue)}
                                  />
                                </Grid>
                                {values.applyAt === RULE_APPLY_AT_DATE_OF_SERVICE &&
                                  <Grid item xs={4}>
                                    <TextField
                                      type="number"
                                      required
                                      label="DOS Max Rate"
                                      name="dosMaxRate"
                                      className={classes.textField}
                                      margin="normal"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.dosMaxRate}
                                      helperText={touched.dosMaxRate && errors.dosMaxRate}
                                      error={Boolean(touched.dosMaxRate && errors.dosMaxRate)}
                                    />
                                  </Grid>
                                }
                                {/* <Grid item xs={4}>
                                  <ul className={classes.list}>
                                    <li>58.25% of rate defined by fee schedule</li>
                                    <li>$1684.25 per diem</li>
                                  </ul>
                                </Grid> */}
                              </Grid>
                            </Card.Body>
                          </Card>
                        </Grid>
                        <Grid item xs={12} className="mt-5">
                          <Card>
                            <Card.Header as="h5">Service Date Range (Optional)</Card.Header>
                            <Card.Body>
                              <Grid container>
                                <Grid item xs={4}>
                                  <TextField
                                    type="date"
                                    label="From Date"
                                    name="fromDate"
                                    className={classes.textField}
                                    margin="normal"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.fromDate}
                                    helperText={touched.fromDate && errors.fromDate}
                                    error={Boolean(touched.fromDate && errors.fromDate)}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    type="date"
                                    label="To Date"
                                    name="toDate"
                                    className={classes.textField}
                                    margin="normal"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.toDate}
                                    helperText={touched.toDate && errors.toDate}
                                    error={Boolean(touched.toDate && errors.toDate)}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                              </Grid>

                            </Card.Body>
                          </Card>
                        </Grid>
                        <Grid item xs={12} className="mt-5">
                          <Card>
                            <Card.Header as="h5">Selection Criteria (Optional)</Card.Header>
                            <Card.Body>
                              <RuleSelectionCriteriaFieldArray
                                values={values}
                                ruleCriteria={ruleCriteria}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                                setFieldValue={setFieldValue}
                              />
                            </Card.Body>
                          </Card>
                        </Grid>
                        <Grid item xs={12} className="mt-5">
                          <Card>
                            <Card.Header as="h5">Configurations (Optional)</Card.Header>
                            <Card.Body>
                              <Grid container>
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    className={classes.switchFormControl}
                                    control={
                                      <Switch
                                        checked={values.payInAddition}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="payInAddition"
                                      />
                                    }
                                    label="Pay In Addition"
                                  />
                                  <span className={classes.helpClass}>
                                    {hoverColumn('Pays in addition to per diem, case rates, and other circuit breakers')}
                                  </span>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    className={classes.switchFormControl}
                                    control={
                                      <Switch
                                        checked={values.isCircuitBreaker}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="isCircuitBreaker"
                                      />
                                    }
                                    label="Circuit Breaker"
                                  />
                                  <span className={classes.helpClass}>
                                    {hoverColumn('Stops processing of other rules when hit')}
                                  </span>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    className={classes.switchFormControl}
                                    control={
                                      <Switch
                                        checked={values.lesserOf}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="lesserOf"
                                      />
                                    }
                                    label="Lesser Of"
                                  />
                                  <span className={classes.helpClass}>
                                    {hoverColumn('Applies the lesser of charge amount or fee schedule')}
                                  </span>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item xl={3} lg={4} xs={4}>
                                      <FormControlLabel
                                        className={classes.switchFormControl}
                                        control={
                                          <Switch
                                            checked={values.maxRate}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name="maxRate"
                                          />
                                        }
                                        label="Max Rate"
                                      />
                                      <span className={classes.helpClass}>
                                        {hoverColumn('Maximum dollar amount allowed either by Charge Line, rule, or claim level')}
                                      </span>
                                    </Grid>
                                    <Grid item xl={9} lg={8} xs={8}>
                                      {(values.maxRate) &&
                                        <Grid item xs={6}>
                                          <TextField
                                            required
                                            // label="Max Rate Value"
                                            name="maxRateValue"
                                            className={`ml-0 ${classes.textFieldInline}`}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.maxRateValue}
                                            helperText={touched.maxRateValue && errors.maxRateValue}
                                            error={Boolean(touched.maxRateValue && errors.maxRateValue)}
                                            InputProps={{
                                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                          />
                                        </Grid>
                                      }
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item xl={3} lg={4} xs={4}>
                                      <FormControlLabel
                                        className={classes.switchFormControl}
                                        control={
                                          <Switch
                                            disabled={isPayMethod2ndDollar(values.paymethodId)}
                                            checked={values.threshold}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name="threshold"
                                          />
                                        }
                                        label="Threshold"
                                      />
                                      <span className={classes.helpClass}>
                                        {hoverColumn('Dollar amount that must be met in order for rule to trigger')}
                                      </span>
                                    </Grid>
                                    <Grid item xs={5}>
                                      {values.threshold &&
                                        <Grid item xl={9} lg={8} xs={8}>
                                          <TextField
                                            required
                                            // label="Threshold Value"
                                            name="thresholdValue"
                                            className={`ml-0 ${classes.textFieldInline}`}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.thresholdValue}
                                            helperText={touched.thresholdValue && errors.thresholdValue}
                                            error={Boolean(touched.thresholdValue && errors.thresholdValue)}
                                            InputProps={{
                                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                          />
                                        </Grid>
                                      }
                                    </Grid>
                                  </Grid>

                                </Grid>
                                {IsPaySourceTypeSchedule(values.paySourceId) && (
                                  <>
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        className={classes.switchFormControl}
                                        control={
                                          <Switch
                                            name="usePaidCode"
                                            color="primary"
                                            checked={values.usePaidCode}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.usePaidCode}
                                          />
                                        }
                                        label="Paid Code"
                                      />
                                      <span className={classes.helpClass}>
                                        {hoverColumn('Grouping by APC or other paid codes in fee schedule to only be paid once per code')}
                                      </span>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Grid item xs={4}>
                                        <FormControlLabel
                                          className={classes.switchFormControl}
                                          control={
                                            <Switch
                                              name="considerFsTc"
                                              color="primary"
                                              checked={values.considerFsTc}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.considerFsTc}
                                            />
                                          }
                                          label="Consider Fee Schedule Technical Component"
                                        />
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item xl={3} lg={4} xs={4}>
                                      <FormControlLabel
                                        className={classes.switchFormControl}
                                        control={
                                          <Switch
                                            name="useMultiProcedurePercent"
                                            color="primary"
                                            checked={values.useMultiProcedurePercent}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.useMultiProcedurePercent}
                                          />
                                        }
                                        label="Multiprocedure Percents"
                                      />
                                      <span className={classes.helpClass}>
                                        {hoverColumn('Tiered rates for multiple procedures based on CPT or Paid Codes')}
                                      </span>
                                    </Grid>
                                    {values.useMultiProcedurePercent && (
                                      <Grid item xl={9} lg={8} xs={8}>
                                        <RuleMultiPrecidenceFieldArray
                                          values={values}
                                          multiprocPrecedences={multiprocPrecedences}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          touched={touched}
                                          errors={errors}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                                {IsPaySourceTypeChargeOrSchedule(values.paySourceId) && <>
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid item xl={3} lg={4} xs={4}>
                                        <FormControlLabel
                                          className={classes.switchFormControl}
                                          control={
                                            <Switch
                                              name="useNpiRate"
                                              color="primary"
                                              checked={values.useNpiRate}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.useNpiRate}
                                            />
                                          }
                                          label="Billing NPI"
                                        />
                                      </Grid>
                                      {values.useNpiRate && (
                                        <Grid item xl={9} lg={8} xs={8}>
                                          <RuleNPIRateFieldArray
                                            isPaySourceTypeSchedule={IsPaySourceType(values.paySourceId, 'Schedule')}
                                            values={values}
                                            payMethod={payMethods.find((item)=>item?.id===values.paymethodId)}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            handleBlur={handleBlur}
                                            touched={touched}
                                            errors={errors}
                                            feeScheduleLabels={feeScheduleLabels}
                                            handleFeeScheduleLabelToRateXrefChange = {handleFeeScheduleLabelToRateXrefChange}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>}

                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item xl={3} lg={4} xs={4}>
                                      <FormControlLabel
                                        className={classes.switchFormControl}
                                        control={
                                          <Switch
                                            name="isSometimesPay"
                                            color="primary"
                                            checked={values.isSometimesPay}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.isSometimesPay}
                                          />
                                        }
                                        label="Sometimes Pay"
                                      />
                                    </Grid>
                                    {values.isSometimesPay && (
                                      <>
                                        <Grid item xl={8} lg={8} xs={8}>
                                          <SomeTimesPayCodes values={values} errors={errors} touched={touched} setFieldValue={setFieldValue} />
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>

                                {serviceType === CONTRACTED_SERVICE_PROFESSIONAL &&
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid item xl={3} lg={4} xs={4}>
                                        <FormControlLabel
                                          className={classes.switchFormControl}
                                          control={
                                            <Switch
                                              name="useNpiRole"
                                              color="primary"
                                              checked={values.useNpiRole}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.useNpiRole}
                                            />
                                          }
                                          label="Rendering NPI"
                                        />
                                      </Grid>
                                      {values.useNpiRole && (
                                        <Grid item xl={9} lg={8} xs={8}>
                                          <RuleNPIRoleFieldArray
                                            isPaySourceTypeSchedule={IsPaySourceType(values.paySourceId, 'Schedule')}
                                            values={values}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            handleBlur={handleBlur}
                                            touched={touched}
                                            errors={errors}
                                            feeScheduleLabels={feeScheduleLabels}
                                            handleFeeScheduleLabelToRateXrefChange = {handleFeeScheduleLabelToRateXrefChange}
                                          />
                                        </Grid>)}
                                    </Grid>
                                  </Grid>
                                }
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item xl={3} lg={4} xs={4}>
                                      <FormControlLabel
                                        className={classes.switchFormControl}
                                        control={
                                          <Switch
                                            name="useModifiers"
                                            color="primary"
                                            checked={values.useModifiers}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.useModifiers}
                                          />
                                        }
                                        label="Modifiers"
                                      />
                                    </Grid>
                                    {values.useModifiers && (
                                      <Grid item xl={9} lg={8} xs={8}>
                                        <RuleModifierFieldArray
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          setFieldValue={setFieldValue}
                                          touched={touched}
                                          errors={errors}
                                          isPayMethodMultiProcedure={isPayMethodMultiProcedure}

                                          modifiers={modifiers}
                                          modifiersPayMethods={modifiersPayMethods}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid item xs={4}>
                                    <FormControlLabel
                                      className={classes.switchFormControl}
                                      control={
                                        <Switch
                                          name="ignoreClaim"
                                          color="primary"
                                          checked={values.ignoreClaim}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.ignoreClaim}
                                        />
                                      }
                                      label="Ignore Claim"
                                    />
                                    <span className={classes.helpClass}>
                                      {hoverColumn('Ignores claim if Rule matches')}
                                    </span>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid item xs={4}>
                                    <FormControlLabel
                                      className={classes.switchFormControl}
                                      control={
                                        <Switch
                                          name="ignoreCmsConfiguration"
                                          color="primary"
                                          checked={values.ignoreCmsConfiguration}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.ignoreCmsConfiguration}
                                        />
                                      }
                                      label="Ignore CMS Configuration"
                                    />
                                  </Grid>
                                </Grid>

                              </Grid>
                            </Card.Body>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* {isRuleType(values.ruleTypeId, 'RULE') && serviceType === CONTRACTED_SERVICE_PROFESSIONAL && (
                    <Grid item xs={5} >
                      <div className={classes.ruleGroups}>
                        <h4>Groups</h4>
                        <GroupsTreeView ruleId={ruleId === 'new' ? 0 : ruleId} selected={false} selectedGroups={selectedGroups} handleGroupSelect={handleGroupSelect} />
                      </div>
                    </Grid>
                  )} */}
                  </Grid>
                </PortletBody>
              </Portlet>
            </form>
            <Dialog open={isWarnOpen} onClose={handleClose}>
              <DialogTitle>
                Warning!
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Saving a rule with no code service selected will cause all items in the fee schedule to be considered
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" type='button'>
                  Let me Add one
                </Button>
                <Button
                  onClick={handleSubmit as any}
                  className="text-danger"
                  type='button'
                >
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
            <CodesEditDialog
              open={isCodesOpen}
              codes={values.carveoutCodes}
              handleClose={handleCodesClose}
              handleCodesChange={handleCodesChange(setFieldValue)}
            />
          </>
        )}
      </Formik>
    </Loadable>
  </>);
}