export const STATISTICS_GET = 'STATISTICS_GET';
export const STATISTICS_GET_PENDING = 'STATISTICS_GET_PENDING';
export const STATISTICS_GET_FULFILLED = 'STATISTICS_GET_FULFILLED';
export const STATISTICS_GET_REJECTED = 'STATISTICS_GET_REJECTED';

export const STATISTICS_GET_FILES = 'STATISTICS_GET_FILES';
export const STATISTICS_GET_FILES_PENDING = 'STATISTICS_GET_FILES_PENDING';
export const STATISTICS_GET_FILES_FULFILLED = 'STATISTICS_GET_FILES_FULFILLED';
export const STATISTICS_GET_FILES_REJECTED = 'STATISTICS_GET_FILES_REJECTED';

export const STATISTICS_GET_REMITS_AND_PAYERS = 'STATISTICS_GET_REMITS_AND_PAYERS';
export const STATISTICS_GET_REMITS_AND_PAYERS_PENDING = 'STATISTICS_GET_REMITS_AND_PAYERS_PENDING';
export const STATISTICS_GET_REMITS_AND_PAYERS_FULFILLED = 'STATISTICS_GET_REMITS_AND_PAYERS_FULFILLED';
export const STATISTICS_GET_REMITS_AND_PAYERS_REJECTED = 'STATISTICS_GET_REMITS_AND_PAYERS_REJECTED';

export const STATISTICS_SET_FILTER = 'STATISTICS_SET_FILTER';
export const REMIT_PAYERS_STATISTICS_SET_FILTER="REMIT_PAYERS_STATISTICS_SET_FILTER"
export const STATISTICS_SET_REMITS_AND_PAYERS = 'STATISTICS_SET_REMITS_AND_PAYERS';


export const STATISTICS_ERROR_RESET = 'STATISTICS_ERROR_RESET';
export const STATISTICS_RESET = 'STATISTICS_RESET';

export const get = (filters) => ({
    type: STATISTICS_GET,
    payload: { filters }
});

export const getFiles = (callback) => ({
    type: STATISTICS_GET_FILES,
    payload: { callback }
});

export const errorReset = (...errorKeys) => ({
    type: STATISTICS_ERROR_RESET,
    payload: { errorKeys },
});

export const setStatisticsFilter = (filters) => ({
    type: STATISTICS_SET_FILTER,
    payload: { filters },
});

export const setRemitsAndPayersStatisticsFilter = (filters) => ({
    type: REMIT_PAYERS_STATISTICS_SET_FILTER,
    payload: { filters },
});

export const resetStats = () => ({
    type: STATISTICS_RESET
});

export const getRemitsAndPayersStatistics = (frequency,schema) => ({
    type: STATISTICS_GET_REMITS_AND_PAYERS,
    payload: { frequency,schema }
});

export const setRemitsAndPayersStatistics = (data) =>( {
    type: STATISTICS_SET_REMITS_AND_PAYERS,
    payload:{...data}
});