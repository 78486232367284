import React, { useEffect } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { CodesEditDialog, Loadable } from '../../../../common';
import {
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Switch,
  FormLabel,
  IconButton,
  TextareaAutosize,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { contractValidationSchema } from '../../../../store/validations/contract';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { getPaginationFilter } from "../../../../util/pagination";
import { useState } from "react";
import { BackButton } from "../../../../common/BackButton";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  textService: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '45%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '90%'
  },
  formControl: {
    marginLeft: theme.spacing(1),
    width: '90%',
    marginTop: theme.spacing(2),
  },
  textArea: {
    marginLeft: theme.spacing(1),
    width: '95%',
  },

  switch: {
    marginLeft: theme.spacing(1),
  },
  textareaAutosize: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  codesLabel: {
    marginLeft: theme.spacing(1),
    marginTop: 4,
    fontSize: '0.8rem',
    marginBottom: 6
  },
  textareaAutosize: {
    marginLeft: theme.spacing(1),
    width: '90%',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
    '&:focus': {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    },
    '&:focus-visible': {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    }
  },
  errorColor: {
    color: '#fd397a',
  },
  helperText: {
    marginLeft: '8px'
  },
  textAreaError: {
    borderColor: '#fd397a',
    borderWidth: '2px'
  },
  helpClass: {
    position: 'relative',
    top: 5,
    marginLeft: -12,
  },
}));


export default function CodeServicesEditPage(props) {
  const { intl, methodTypes, serviceId,
    initialValues, serviceGetPending, serviceCreatePending, serviceUpdatePending, methodTypesPending, contractsCreatePending,
    contractsUpdatePending, mode, serviceTypes, serviceDeleteError, serviceDeleteErrorMessage, isServiceFromFeeSchedule, feeScheduleFilter,
    feeSchedulePage, feeScheduleSort, feeScheduleId,
    actions: { ruleGetMethodTypes, serviceGet, serviceCreate, serviceUpdate, serviceGetServiceType, getFeeScheduleCodes, setServiceFromFeeSchedule } } = props;

  const [isCodesOpen, setCodeOpen] = useState(false);
  const [selectedCodeType, setCodesType] = useState(null);

  const classes = useStyles();
  const isEdit = mode === 'edit';

  const handleCallback = () => {
    props.history.push(`/cms/codeservice`);
  }

  const handleEditCallback = () => {
    props.history.push(`/cms/codeservice`);
  }

  useEffect(() => {
    ruleGetMethodTypes();
    serviceGetServiceType();
  }, [ruleGetMethodTypes, serviceGetServiceType]);

  useEffect(() => {
    if (mode === 'edit') {
      serviceGet(serviceId);
    }
    return () => {
      setServiceFromFeeSchedule(false, null);
    }
  }, [mode, serviceGet, serviceId]);

  useEffect(() => {
    if (isServiceFromFeeSchedule) {
      const paginationFilters = getPaginationFilter([], feeScheduleFilter);
      const options = {
        feeScheduleId,
        inputRequest: {
          page: {
            ...feeSchedulePage,
          },
          filter: paginationFilters || null,
          sort: feeScheduleSort || [],
        }
      };
      getFeeScheduleCodes(options);
    }
  }, [feeScheduleFilter, feeScheduleId, feeSchedulePage, feeScheduleSort, getFeeScheduleCodes, isServiceFromFeeSchedule]);

  function handleCodesOpen(_type) {
    setCodeOpen(true);
    setCodesType(_type)
  }
  function handleCodesClose() {
    setCodeOpen(false);
  }
  const handleCodesChange = (setFieldValue) => (codes) => {
    const withoutLineBreaks = codes.replace(/[\r\n]/gm, '');
    setFieldValue(`${selectedCodeType}`, withoutLineBreaks);
  }

  const handlePrimaryCodeChange = (setFieldValue) => (e) => {
    const withoutLineBreaks = e.target.value.replace(/[\r\n]/gm, '');
    setFieldValue('primaryCodes', withoutLineBreaks)
  }

  const handleQualifierCodeChange = (setFieldValue) => (e) => {
    const withoutLineBreaks = e.target.value.replace(/[\r\n]/gm, '');
    setFieldValue('qualifierCodes', withoutLineBreaks)
  }

  return (
    (<Loadable loading={serviceCreatePending || serviceUpdatePending || serviceGetPending || methodTypesPending}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={contractValidationSchema}
        validate={values => {
          const errors = {};
          if (!values.name) {
            errors.name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          if (!values.serviceTypeId) {
            errors.serviceTypeId = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          if (!values.primaryRuleMethodId) {
            errors.primaryRuleMethodId = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          if (!values.primaryCodes) {
            errors.primaryCodes = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          return errors;
        }}
        onSubmit={(values) => {
          let payload = {
            description: values.description,
            primaryRuleMethodId: values.primaryRuleMethodId,
            primaryCodes: values.primaryCodes.trim(),
            qualifierRuleMethodId: values.qualifierRuleMethodId,
            qualifierCodes: values.qualifierCodes ? values.qualifierCodes.trim() : '',
            serviceTypeId: values.serviceTypeId,
            name: values.name,
            isExclusion: values.isExclusion
          }

          if (mode === 'create') {
            payload = {
              ...payload,
            }
            serviceCreate(payload, handleCallback);
          }
          else {
            payload = {
              ...payload,
              id: values.id,
            }
            serviceUpdate(values.id, payload, handleEditCallback);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
            <Portlet>
              <PortletHeader
                title={isEdit ? 'Edit Service' : 'Add Service'}
                toolbar={
                  <PortletHeaderToolbar>
                    <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/cms/codeservice" />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      type="submit"
                      disabled={contractsCreatePending || contractsUpdatePending || (isEdit && !dirty)}
                    >
                      {(contractsCreatePending || contractsUpdatePending) ? 'Saving..' : 'Save'}
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className="root">
                  <Grid container>
                    <Grid item xs={7}>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            required
                            label="Name"
                            name="name"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            helperText={touched.name && errors.name}
                            error={Boolean(touched.name && errors.name)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl required className={classes.formControl}>
                            <InputLabel htmlFor="serviceTypeId">Service Type</InputLabel>
                            <Select
                              value={values.serviceTypeId}
                              onChange={handleChange}
                              error={Boolean(touched.serviceTypeId && errors.serviceTypeId)}
                              input={<Input id="serviceTypeId" />}
                              inputProps={{
                                name: "serviceTypeId",
                                id: "serviceTypeId"
                              }}
                            >
                              {serviceTypes.map(serviceType => (
                                <MenuItem
                                  key={serviceType.id}
                                  value={serviceType.id}
                                >
                                  {serviceType.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error>{touched.serviceTypeId && errors.serviceTypeId}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={7}>
                      <Grid container>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="primaryRuleMethodId">Primary Rule</InputLabel>
                            <Select
                              value={values.primaryRuleMethodId}
                              onChange={handleChange}
                              error={Boolean(touched.primaryRuleMethodId && errors.primaryRuleMethodId)}
                              input={<Input id="primaryRuleMethodId" />}
                              inputProps={{
                                name: "primaryRuleMethodId",
                                id: "primaryRuleMethodId"
                              }}
                            >
                              {methodTypes.map(methodType => (
                                <MenuItem
                                  key={methodType.id}
                                  value={methodType.id}
                                >
                                  {methodType.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error>{touched.primaryRuleMethodId && errors.primaryRuleMethodId}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormLabel className={`${classes.codesLabel} ${(Boolean(touched.primaryCodes && errors.primaryCodes)) ? classes.errorColor : ''}`} disabled={values.isCodeService === 'true'} id="carveout-codes">Primary Codes *
                            <IconButton
                              onClick={() => handleCodesOpen('primaryCodes')}
                              className="p-1"
                              size="large">
                              <ZoomInIcon />
                            </IconButton>
                          </FormLabel>
                          <TextareaAutosize
                            aria-labelledby="primaryCodes"
                            multiline
                            minRows={1}
                            minRows='3'
                            maxRows='8'
                            label="Primary Codes"
                            // placeholder="Codes"
                            name="primaryCodes"
                            className={`${classes.textareaAutosize} ${(Boolean(touched.primaryCodes && errors.primaryCodes)) ? classes.textAreaError : ''}`}
                            onBlur={handleBlur}
                            onChange={handlePrimaryCodeChange(setFieldValue)}
                            value={values.primaryCodes}
                            helperText="e.g A1000-A1099; 100; 101; 70001-70010; 90001;"
                            error={Boolean(touched.primaryCodes && errors.primaryCodes)}
                          />
                          <p className={`${(Boolean(touched.primaryCodes && errors.primaryCodes)) && classes.errorColor} ${classes.helperText} MuiFormHelperText-root`}>e.g A1000-A1099; 100; 101; 70001-70010; 90001;</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={7}>
                      <Grid container>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="qualifierRuleMethodId">Qualifier Rule</InputLabel>
                            <Select
                              value={values.qualifierRuleMethodId}
                              onChange={handleChange}
                              error={Boolean(touched.qualifierRuleMethodId && errors.qualifierRuleMethodId)}
                              input={<Input id="qualifierRuleMethodId" />}
                              inputProps={{
                                name: "qualifierRuleMethodId",
                                id: "qualifierRuleMethodId"
                              }}
                            >
                              {methodTypes.map(methodType => (
                                <MenuItem
                                  key={methodType.id}
                                  value={methodType.id}
                                >
                                  {methodType.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error>{touched.qualifierRuleMethodId && errors.qualifierRuleMethodId}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormLabel className={`${classes.codesLabel} ${(Boolean(touched.qualifierCodes && errors.qualifierCodes)) ? classes.errorColor : ''}`} disabled={values.isCodeService === 'true'} id="carveout-codes">Qualifier Codes *
                            <IconButton
                              onClick={() => handleCodesOpen('qualifierCodes')}
                              className="p-1"
                              size="large">
                              <ZoomInIcon />
                            </IconButton>
                          </FormLabel>
                          <TextareaAutosize
                            aria-labelledby="qualifierCodes"
                            multiline
                            minRows={1}
                            minRows='3'
                            maxRows='8'
                            label="Qualifier Codes"
                            // placeholder="Codes"
                            name="qualifierCodes"
                            className={`${classes.textareaAutosize} ${(Boolean(touched.qualifierCodes && errors.qualifierCodes)) ? classes.textAreaError : ''}`}
                            onBlur={handleBlur}
                            onChange={handleQualifierCodeChange(setFieldValue)}
                            value={values.qualifierCodes}
                            helperText="e.g A1000-A1099; 100; 101; 70001-70010; 90001;"
                            error={Boolean(touched.qualifierCodes && errors.qualifierCodes)}
                          />
                          <p className={`${(Boolean(touched.qualifierCodes && errors.qualifierCodes)) && classes.errorColor} ${classes.helperText} MuiFormHelperText-root`}>e.g A1000-A1099; 100; 101; 70001-70010; 90001;</p>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="isExclusion"
                                color="primary"
                                checked={values.isExclusion}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={classes.switch}
                                value={values.isExclusion}
                              />
                            }
                            label="Exclude"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={7}>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            multiline
                            rows={3}
                            label="Description"
                            name="description"
                            className={classes.textArea}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </PortletBody>
            </Portlet>
            <CodesEditDialog
              open={isCodesOpen}
              codes={selectedCodeType === 'primaryCodes' ? values.primaryCodes : selectedCodeType === 'qualifierCodes' ? values.qualifierCodes : ''}
              handleClose={handleCodesClose}
              handleCodesChange={handleCodesChange(setFieldValue)}
              label={selectedCodeType === 'primaryCodes' ? "Primary Codes" : "Qualifier Codes"}
            />
          </form>
        )}
      </Formik>
    </Loadable>)
  );
}