import React, { useEffect, useState } from 'react';
import {
    Portlet, PortletBody, PortletHeader, PortletHeaderToolbar
} from "../../partials/content/Portlet";
import {
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';
import { Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DataTable, DateRangeControl } from '../../common';
import { getLast12Months } from '../../util/date';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    formControlAuto: {
        width: '100%',
        margin: theme.spacing(1),
    },
    portlet: {
        display: 'flex',
        flexGrow: 1,
    },
    switch: {
        marginRight: 0,
        marginTop: 20,
        marginLeft: 0
    },
}));

export default function PayerScoreCard({
    data, fields, payersOptions, sort, order, getScoreCardPending, filter,
    actions: {
        getPayersScoreCard,
        getPayerContractsTiny,
        setSort, setOrder, setFilter
    }
}) {
    const classes = useStyles();

    const [refresh, setRefresh] = useState(Promise.resolve());
    const [paidOnly, setPaidOnly] = useState(true);
    const [dateData, setDateData] = useState({
        beginDate: getLast12Months().startdate,
        endDate: getLast12Months().enddate,
        isDateFilterActive: true
    })

    useEffect(() => {
        getPayerContractsTiny();
    }, [getPayerContractsTiny]);

    useEffect(() => {
        if (payersOptions.length > 0) {
            setFilter(payersOptions)
        }
    }, [payersOptions, setFilter]);

    useEffect(() => {
        getPayersScoreCard(
            {
                PayerContractId: null,
                PaidOnly: paidOnly,
                FilterDates: dateData.isDateFilterActive,
                BeginDate: dateData.beginDate,
                EndDate: dateData.endDate
            }
        );
    }, [getPayersScoreCard, paidOnly, refresh]);


    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    const filterOptions = createFilterOptions<any>({
        stringify: option => option.displayName,
    });

    const setDates = (beginDate, endDate, isDateFilterActive) => {
        setDateData({
            beginDate,
            endDate,
            isDateFilterActive
        });
    }

    const handlePaidOnlyChange = (e) => {
        setPaidOnly(e.target.checked);
    }

    return (<>
        <Helmet title="Payer Scorecard" />
        <Portlet>
            <PortletHeader
                title="Payer Scorecard"
                name="payers-scorecard-landing"
                handleRefresh={handleRefresh}
                // showSearchFilters={true}
                toolbar={
                    <PortletHeaderToolbar className={classes.portlet}>
                        <Grid container spacing={3} justifyContent="flex-end" className='pr-3'>
                            <Grid item md={2} className='text-right'>
                                <FormControlLabel
                                    className={classes.switch}
                                    control={
                                        <Switch
                                            checked={paidOnly}
                                            size="small"
                                            color="primary"
                                            onChange={handlePaidOnlyChange}
                                        />
                                    }
                                    label="Paid Only?"
                                    labelPlacement="start"
                                />
                            </Grid>
                            <Grid item md={3}>
                                <DateRangeControl
                                    refresh={handleRefresh}
                                    filterName="Date Filter"
                                    setDates={setDates}
                                />
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <FormControl className={classes.formControlAuto}>
                                    <Autocomplete
                                        fullWidth
                                        onChange={(e, value) => setFilter(value)}
                                        filterOptions={filterOptions}
                                        value={filter}
                                        multiple
                                        options={payersOptions}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.displayName}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.displayName}
                                            </li>
                                        )}
                                        renderTags={(tagValue, getTagProps) => {
                                            const numTags = tagValue.length;
                                            return (
                                                <Typography variant="caption">
                                                    {tagValue.slice(0, 2).map((option, index) => (
                                                        <Chip
                                                            size="small"
                                                            color="secondary"
                                                            key={index}
                                                            label={option.displayName}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))}
                                                    {numTags > 2 && ` + ${numTags - 2}`}
                                                </Typography>
                                            )
                                        }
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Payers"
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </PortletHeaderToolbar>
                }
            />
            <PortletBody>
                <Grid container>
                    <Grid item md={12}>
                        <DataTable
                            fields={fields}
                            data={data}
                            order={order}
                            onOrder={setOrder}
                            sort={sort}
                            onSort={setSort}
                            hasActions={false}
                            loading={getScoreCardPending}
                            name="Payers Scorecard"
                            handleRefresh={handleRefresh}
                        />
                    </Grid>
                </Grid>
            </PortletBody>
        </Portlet>
    </>);
}