import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { getClaimStatuses } from '../../../store/actions/claimAnalysis';
import { claimStatusesDependencyReset, deleteClaimStatus, getClaimStatusDeleteDependencies, setDependencyDialog } from '../../../store/actions/claimStatus';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.claimStatus.dependencyDialog;

        const selectClaimStatus = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.claimStatus) {
                    return dependencyDialog?.claimStatus
                }
                return null
            }
        );
    
        const selectDropdownValues = (state) => state?.claimAnalysis?.claimStatuses;

        const selectClaimStatusDropdownValues = createSelector(
            selectClaimStatus,
            selectDropdownValues,
            (claimStatus,dropdownValues) => dropdownValues?.filter((item)=>(claimStatus?.id!==item.id && {label:item.name,value:item.id,name:item.name})) || []
            
        );
        
        
        

        const selectOpen = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.open) {
                    return true
                }
                return false
            }
        );
       
        const selectClaimStatusId = createSelector(
            selectClaimStatus,
            (claimStatus) => {
                if (claimStatus && claimStatus?.id) {
                    return claimStatus?.id
                }
                return ''
            }
        );

        const selectClaimStatusName = createSelector(
            selectClaimStatus,
            (claimStatus) => {
                if (claimStatus && claimStatus?.name) {
                    return claimStatus?.name
                }
                return ''
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            claimStatusId: selectClaimStatusId(state),
            canDelete: state.claimStatus.canDelete ?? null,
            dependentRecords: state.claimStatus.recordsDependency ?? null,
            getDependenciesPending: state.claimStatus.getDependencies.pending,
            deletePending: state.claimStatus.delete.pending,
            claimStatusListPending: state.claimAnalysis.getClaimStatuses.pending,
            claimStatusName: selectClaimStatusName(state),
            claimStatusList:selectClaimStatusDropdownValues(state),
            initialValues: {status:null},
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getClaimStatusDeleteDependencies: getClaimStatusDeleteDependencies,
            deleteClaimStatus: deleteClaimStatus,
            setDependencyDialog: setDependencyDialog,
            getClaimStatuses: getClaimStatuses,
            claimStatusesDependencyReset: claimStatusesDependencyReset
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));