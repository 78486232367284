import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Switch } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

function FilterColumns(props) {
    const { open, handleDialogClose, fields, applyColumnFiter, _hiddenFields } = props;

    const [hiddenFields, setHiddenFields] = useState(_hiddenFields);

    const handleCheckBoxChange = (fieldId) => {
        const currentIndex = hiddenFields.indexOf(fieldId);
        const newHiddenFields = [...hiddenFields];

        if (currentIndex === -1) {
            newHiddenFields.push(fieldId);
        } else {
            newHiddenFields.splice(currentIndex, 1);
        }
        setHiddenFields(newHiddenFields);
    };

    const handleClose = () => {
        handleDialogClose();
        setHiddenFields(_hiddenFields);
    }

    return (
        (<div>
            <Dialog fullWidth open={open}>
                <DialogTitle>
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            Manage Columns
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" onClick={handleClose} size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        {fields.filter(field => field.options?.reorder !== false).map((field, index) =>
                            <Grid key={`key-${index}`} xs={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() => handleCheckBoxChange(field.id)}
                                                checked={!(hiddenFields.find(item => field.id === item))}
                                            />
                                        }
                                        label={field.label}
                                    />
                                </FormGroup>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container className='justify-content-end py-2 px-3'>
                        <Button onClick={applyColumnFiter(hiddenFields)} color="primary" variant='contained'>
                            Apply
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>)
    );
}

export default FilterColumns
