import React from "react";
import { connect } from "react-redux";
import { animated } from "react-spring";
import * as builder from "../ducks/builder";

function KtContent({ children, contentContainerClasses }) {
  // https://www.react-spring.io/docs/hooks/use-transition
  // https://codesandbox.io/embed/jp1wr1867w

  return (
    <animated.div
      className={`kt-container ${contentContainerClasses} kt-grid__item kt-grid__item--fluid kt-fit-height kt-flex-column`}
    >
      {children}
    </animated.div>
  );
}

const mapStateToProps = store => ({
  contentContainerClasses: builder.selectors.getClasses(store, {
    path: "content_container",
    toString: true
  })
});

export default connect(mapStateToProps)(KtContent);
