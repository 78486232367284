import React, {useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormLabel,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    DialogActions,
    Button,
    Typography,
    IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GroupsTreeView } from '../../GroupsTreeView';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function GroupsDialog(props) {
    const {
        open, handleClose, ruleId,
    } = props;
    const classes = useStyles();
    const [selected, setSelected] = useState(true);

    function handleRadioChange(event) {
        setSelected(event.target.value === "true" ? true : false );
    }

    return (
        (<Dialog
                fullWidth
                scroll="paper"
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="form-dialog-title">
            <DialogTitle>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">Groups</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container justifyContent="flex-end">
                            <FormControl component="fieldset">
                                <RadioGroup 
                                    row aria-label="group" 
                                    name="group" 
                                    value={selected} 
                                    onChange={handleRadioChange}>
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio color="primary" />}
                                        label="All"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio color="primary" />}
                                        label="Selected"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={() => handleClose(false)}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <GroupsTreeView ruleId={ruleId} selected={selected}/>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose(false)}
                    variant="contained"
                    color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>)
    );
}