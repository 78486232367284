import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { resetPage, set } from '../../store/actions/pages';

import { downloadReportById, resetErrorMessages, stopExecution } from '../../store/actions/executions';
import { executionsLanding } from '../../store/actions/pages';
import { getByName, reset } from '../../store/actions/search';
import { dateEquators, integerEquators, StringEquators } from '../../store/constants/pages';
import { selectCanAccessExecutionLogs } from '../../store/selectors/access/contractManagement';
import { selectSessionRole } from '../../store/selectors/session';
import { createNumberDataField, createProgressBarField, createStringDataField, createTimestampField } from '../../util/format';
import ExecutionPageComponent from './ExecutionPage';

const selectFields = createSelector(
    () => [
        createNumberDataField('id', 'Execution Id', { equators: integerEquators, sortable: true, }),
        createStringDataField('payerContract.displayName', 'Payer Contract', {
            sortable: true, equators: StringEquators, secondLevel: 'displayName'
        }),
        createStringDataField('source', 'Source', { sortable: true, equators: StringEquators }),
        createStringDataField('state', 'State', { sortable: true, equators: StringEquators }),
        createProgressBarField('processedClaims', 'Progress', 'processedClaims', 'totalClaims', 'Claims'),
        createStringDataField('criteria', 'Criteria', { equators: StringEquators, clickPopover: true,customPopoverTitle:"Criteria" }),
        createTimestampField('executedAt', 'Execution Date', { sortable: true, equators: dateEquators }),
        createStringDataField('executedBy', 'Executed By', { sortable: true, equators: StringEquators }),
    ]
);

const selectExecutionsData = createSelector(
    (state) => state.pages['executions-landing'].list,
    (data) => data
);

export const ExecutionPage = connect(

    () => {
        return (state, props) => ({
            fields: selectFields(state as never),
            executionsData: selectExecutionsData(state),
            userRole: selectSessionRole(state),
            hasExecutionLogsAccess: selectCanAccessExecutionLogs(state),
            stopPending: state.executions.stop.pending,
            downloadReportPending: state.executions.downloadReportById.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            executionsLanding: executionsLanding,
            searchGetByName: getByName,
            searchReset: reset,
            setFilter: (value) => set('executions-landing', 'filter', value),
            stopExecution: stopExecution,
            downloadReportById: downloadReportById,
            resetErrorMessages: resetErrorMessages,
            resetPagedTable: resetPage,
            resetCriteriaWaiting: (pageName) => set(pageName, 'waitForCriteriaFilter', false),
        }, dispatch)
    })
)(injectIntl(ExecutionPageComponent));