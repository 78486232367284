import React, { useEffect } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Loadable } from '../../../../common';

import {
  Portlet, PortletBody,
  PortletHeader, PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import {
  Button,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Switch,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { BackButton } from "../../../../common/BackButton";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '80%',
  },
  textArea: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '80%',
  },
  textFieldOrder: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20%',
  },
  switch: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%',
  },
  formControlType: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '58%',
  },
}));

export default function DashboardConfigurationEditPage(props) {
  const {
    intl, initialValues, dashboardsGetPending, dashboardTypes,
    dashboardsCreatePending, dashboardsUpdatePending, mode,
    dashboardId,
    actions: {
      dashboardCreate, dashboardUpdate,
      dashboardTypesList, clientListTiny, dashboardGet,
    } } = props;

  const classes = useStyles();
  const isEdit = mode === 'edit';

  const handleCallback = () => {
    props.history.push('/admin/dashboards?persist=true');
  }

  useEffect(() => {
    clientListTiny();
    dashboardTypesList();
  }, [clientListTiny, dashboardTypesList]);

  useEffect(() => {
    if (mode === 'edit') {
      dashboardGet(dashboardId);
    }
  }, [dashboardId, mode, dashboardGet]);

  return (
    <Loadable loading={dashboardsGetPending || dashboardsCreatePending || dashboardsUpdatePending}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {};

          if (!values.name) {
            errors.name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.qS_Id) {
            errors.qS_Id = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.order) {
            if (values.order <= 0) {
              errors.order = intl.formatMessage({
                id: "AUTH.VALIDATION.ORDER_GREATER_THAN_MIN"
              });
            } else {
              errors.order = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
          }

          if (values.customFilters) {
            if (!values.dashboardTypeId) {
              errors.dashboardTypeId = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
          }

          if (mode === 'create') {
            if (!values.clientId) {
              errors.clientId = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
          }

          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          let payload = {
            name: values.name,
            qS_Id: values.qS_Id,
            description: values.description,
            active: values.active,
            customFilters: values.customFilters,
            order: values.order,
          }

          if (values.customFilters) {
            payload = {
              ...payload,
              dashboardTypeId: values.dashboardTypeId
            }
          }

          if (mode === 'create') {
            payload = {
              ...payload,
              clientId: values.clientId,
            }
            dashboardCreate(payload, handleCallback);
          }
          else {
            payload = {
              ...payload,
              id: values.id,
            }
            dashboardUpdate(values.id, payload, handleCallback);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          dirty
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
            <Portlet>
              <PortletHeader
                title={isEdit ? 'Edit Dashboard' : 'Add new Dashboard'}
                toolbar={
                  <PortletHeaderToolbar>
                    <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/admin/dashboards?persist=true" />
                    <Button
                      variant="contained"
                      color="primary"
                      className='ml-3'
                      type="submit"
                      disabled={dashboardsCreatePending || dashboardsUpdatePending || (isEdit && !dirty)}
                    >
                      Save
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className={classes.container}>
                  <div className="row">
                    <div className="col-md-6">
                      {!isEdit ? (
                        <React.Fragment>
                          <FormControl className={classes.formControl} error={Boolean(touched.clientId && errors.clientId)}>
                            <InputLabel htmlFor="clientId">Select Client</InputLabel>
                            <Select
                              value={values.clientId}
                              onChange={handleChange}
                              inputProps={{
                                name: "clientId",
                                id: "clientId"
                              }}
                            >
                              {props.clients.map(client => (
                                <MenuItem
                                  key={client.clientId}
                                  value={client.clientId}
                                >
                                  {client.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>{touched.clientId && errors.clientId}</FormHelperText>
                          </FormControl>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <TextField
                            disabled
                            label="Client Name"
                            value={values.clientName}
                            className={classes.textField}
                            margin="normal"
                          />
                        </React.Fragment>
                      )}


                      <TextField
                        required
                        label="Dashboard Name"
                        name="name"
                        className={classes.textField}
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        helperText={touched.name && errors.name}
                        error={Boolean(touched.name && errors.name)}
                      />
                      <TextField
                        required
                        label="Dashboard Id"
                        name="qS_Id"
                        className={classes.textField}
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.qS_Id}
                        helperText={touched.qS_Id && errors.qS_Id}
                        error={Boolean(touched.qS_Id && errors.qS_Id)}
                      />
                      {/* <FormControl className={classes.formControlType} error={Boolean(touched.dashboardTypeId && errors.dashboardTypeId)}>
                        <InputLabel htmlFor="dashboardTypeId">Select Dashboard Type</InputLabel>
                        <Select
                          value={values.dashboardTypeId}
                          onChange={handleChange}
                          name="dashboardTypeId"
                        >
                          {dashboardTypes.map(({ id, name }) => (
                            <MenuItem
                              key={id}
                              value={id}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{touched.dashboardTypeId && errors.dashboardTypeId}</FormHelperText>
                      </FormControl> */}
                      <TextField
                        label="Order No"
                        name="order"
                        type="number"
                        className={classes.textField}
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.order}
                        helperText={touched.order && errors.order}
                        error={Boolean(touched.order && errors.order)}
                      />
                      <TextField
                        multiline
                        rows="3"
                        label="Description"
                        name="description"
                        className={classes.textArea}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                      />
                      {/* <FormControlLabel
                        control={
                          <Switch
                            name="customFilters"
                            checked={values.customFilters}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={classes.switch}
                            value={values.customFilters}
                          />
                        }
                        label="Apply Custom Filters"
                      /> */}
                      <FormControlLabel
                        control={
                          <Switch
                            name="active"
                            checked={values.active}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={classes.switch}
                            value={values.active}
                          />
                        }
                        label="Active"
                      />
                    </div>
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </form>
        )}
      </Formik>
    </Loadable>
  );
}
