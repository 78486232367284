import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import {
    create, errorReset, get, getMethodTypes, getMultiPrecedences, getPayMethods, getPaySources, getRuleCriteria, getRuleTypes, resetCodesValidate, validate
} from '../../../../store/actions/rules';
import { tiny } from '../../../../store/actions/services';
import {
    createRuleSelector, selectMethodTypes,
    selectMultiprocPrecedences, selectPayMethods, selectPaySources, selectRuleTypes, selectSelectedGroups
} from "../../../../store/selectors/rules";

import { selectCodeServicesTiny } from "../../../../store/selectors/services";

import { feeScheduleLabelsTiny, get as getContract, listFeeSchedule } from '../../../../store/actions/contracts';
import { createContractedServiceSelector, createContractSelector, selectFeeScheduleLabelsTiny, selectFeeScheduleLanding } from '../../../../store/selectors/contracts';

import { getFeescheduleRateLabel } from '../../../../store/actions/feeScheduleItems';
import { getModifiersPayMethods, modifiersLanding } from '../../../../store/actions/modifiers';
import { RULE_CRITERIA_STRING } from '../../../../store/constants/contract';
import { asIsoDate } from '../../../../util/date';
import CopyRuleComponent from './CopyRule';

const multiProcedureTemplate = {
    precedenceId: '',
    percentage: '',
}

const npiRateTemplate = {
    npi: '',
    rate: ''
}

const npiRoleTemplate = {
    npiRoleId: '',
    rate: ''
}

const modifierTemplate = {
    modifierId: null,
    payMethodId: '',
    value: ''
}

const selectionCriteriaTemplate = {
    ruleSelectionCriteriaId: null,
    equator: '',
    value: ''
}

export const CopyRulePage = connect(
    () => {
        const selectContractId = (state, props) => props.match.params.contractId;
        const selectContract = createContractSelector(selectContractId) as any
        const selectRuleId = (state, props) => props.match.params.ruleId;
        const selectServiceId = (state, props) => props.match.params.serviceId;

        const selectModifiers = (state, props) => state.modifiers.data;
        const selectModifersPayMethod = (state, props) => state.modifiers.payMethods;
        const selectRuleCriteria = (state, props) => state.rules.criteria;

        const selectRule = createRuleSelector(selectRuleId) as any

        const selectSelectedService = createContractedServiceSelector(selectContractId, selectServiceId);

        const serviceType = createSelector(
            selectSelectedService,
            (service) => {
                if (service && service.serviceType) {
                    return service.serviceType.name;
                }
            }
        ) as any

        const selectFilteredCodeServices = createSelector(
            selectCodeServicesTiny,
            selectSelectedService,
            (codeServices, service) => {
                if (codeServices && service && service.serviceType) {
                    return codeServices.filter(x => x.serviceType === service.serviceType.name).sort((a, b) => a.name.localeCompare(b.name))
                }
                return [];
            }
        );

        const selectSortedFeeSchedule = createSelector(
            selectFeeScheduleLanding,
            (feeSchedule) => {
                return feeSchedule.sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        const getDefaultMultiProcedurePercents = (rule) => {
            if (rule && rule.multiProcedurePercentageToRuleXref?.length > 0) {
                return rule.multiProcedurePercentageToRuleXref;
            }
            return [multiProcedureTemplate];
        }

        const getDefaultRuleToNpirateXref = (rule) => {
            if (rule && rule.ruleToNpirateXref?.length > 0) {
                return rule.ruleToNpirateXref;
            }
            return [npiRateTemplate];
        }

        const getDefaultRuleToNpiroleXref = (rule) => {
            if (rule && rule.ruleToNpiroleXref?.length > 0) {
                return rule.ruleToNpiroleXref;
            }
            return [npiRoleTemplate];
        }

        const getDefaultModifierXref = (rule, modifiers) => {
            if (rule && rule.ruleToModifierXref?.length > 0) {
                return rule.ruleToModifierXref.map(item => {
                    const modifier = modifiers.find(mdfr => mdfr.id === item.modifierId);
                    return {
                        modifierId: modifier ?? null,
                        payMethodId: item.payMethodId,
                        value: item.value,
                        modifierMultiProcedurePercentage: item.modifierMultiProcedurePercentage?.map(item => ({ occurance: item.occurance, value: item.value })) ?? []
                    }
                });
            }
            return [modifierTemplate];
        }

        const getDefaultSelectionCriteria = (rule, ruleCriteria) => {
            if (rule && rule.ruleSelectionCriteriaXref?.length > 0) {
                return rule.ruleSelectionCriteriaXref.map(item => {
                    const criteria = ruleCriteria.find(rc => rc.id === item.ruleSelectionCriteriaId);
                    return {
                        ...item,
                        ruleSelectionCriteriaId: criteria,
                        type: criteria ? criteria.fieldType : RULE_CRITERIA_STRING
                    }
                })
            }
            return [selectionCriteriaTemplate];
        }

        const selectSortedFeeScheduleLabels = createSelector(
            selectFeeScheduleLabelsTiny,
            (labels) => {
                return labels.sort((a, b) => a.label.localeCompare(b.label))
            }
        );

        const selectInitialValues = createSelector(
            selectRule,
            selectModifiers,
            selectRuleCriteria,
            (rule, modifiers, ruleCriteria) => {
                if (rule) {
                    const payload = {
                        id: rule.id,
                        name: `Copy - ${rule.name}`,
                        ruleTypeId: rule.ruleTypeId,
                        methodId: rule.methodId,
                        paymethodId: rule.paymethodId,
                        payvalue: rule.payvalue,
                        feeScheduleId: rule.feeScheduleId,
                        feeScheduleName: rule.feeSchedule?.name ?? '',
                        feescheduleLabelToRateXRefId: rule.feescheduleLabelToRateXRefId ?? null,
                        contractServiceId: rule.contractServiceId,
                        description: rule.description,
                        carveoutCodes: rule.carveoutCodes,
                        codeServiceId: rule.codeServiceId || '',
                        paySourceId: rule.paySourceId,
                        usePaidCode: rule.usePaidCode,
                        useMultiProcedurePercent: rule.useMultiProcedurePercent,
                        multiProcedurePercents: getDefaultMultiProcedurePercents(rule),
                        useNpiRate: rule.useNpiRate,
                        ruleToNpirateXref: getDefaultRuleToNpirateXref(rule),
                        useNpiRole: rule.useNpiRole,
                        ruleToNpiroleXref: getDefaultRuleToNpiroleXref(rule),
                        precedence: rule.precedence,
                        payInAddition: rule.payInAddition,
                        fromDate: asIsoDate(rule.fromDate),
                        toDate: asIsoDate(rule.toDate),
                        isCircuitBreaker: rule.isCircuitBreaker,
                        lesserOf: rule.lesserOf,
                        codeSelectionType: rule.codeSelectionType,
                        maxRate: rule.maxRate,
                        maxRateValue: rule.maxRateValue,
                        threshold: rule.threshold,
                        thresholdValue: rule.thresholdValue,
                        applyAt: rule.applyAt,
                        useModifiers: rule.useModifiers ?? false,
                        ruleToModifierXref: getDefaultModifierXref(rule, modifiers),
                        ruleSelectionCriteriaXref: getDefaultSelectionCriteria(rule, ruleCriteria),
                        ignoreClaim: rule.ignoreClaim,
                        considerFsTc: rule.considerFsTc,
                        isCriteriaOperatorAnd: rule.isCriteriaOperatorAnd ?? true,
                    }

                    return payload;
                }
                return {
                    name: '',
                    ruleTypeId: '',
                    methodId: '',
                    payvalue: '',
                    feeScheduleId: '',
                    feeScheduleName: '',
                    feescheduleLabelToRateXRefId: null,
                    paymethodId: '',
                    gpciLocality: '',
                    contractServiceId: '',
                    description: '',
                    groupIds: [],
                    carveoutCodes: '',
                    codeServiceId: '',
                    paySourceId: '',
                    usePaidCode: false,
                    useMultiProcedurePercent: false,
                    multiProcedurePercents: [
                        multiProcedureTemplate,
                    ],
                    useNpiRate: false,
                    ruleToNpirateXref: [npiRateTemplate],
                    useNpiRole: false,
                    ruleToNpiroleXref: [npiRoleTemplate],
                    precedence: "",
                    payInAddition: false,
                    fromDate: null,
                    toDate: null,
                    isCircuitBreaker: false,
                    lesserOf: false,
                    maxRate: false,
                    codeSelectionType: "Codes",
                    maxRateValue: '',
                    threshold: false,
                    thresholdValue: '',
                    applyAt: 'Charge',
                    useModifiers: false,
                    ruleToModifierXref: [modifierTemplate],
                    ruleSelectionCriteriaXref: [selectionCriteriaTemplate],
                    ignoreClaim: false,
                    considerFsTc: false,
                    isCriteriaOperatorAnd: true,
                }
            }
        ) as any

        return (state, props) => ({
            contract: selectContract(state, props),
            //create dashboard
            rulesCreatePending: state.rules.create.pending,

            // update dashboard
            rulesUpdatePending: state.rules.update.pending,
            rulesGetPending: state.rules.get.pending,

            //codeservices
            codeServicesTinyPending: state.services.getTiny.pending,

            //validation
            rulesValidateCodesPending: state.rules.validateCodes.pending,
            rulesValidateCodesError: state.rules.validateCodes.error,
            rulesValidateCodesErrorMessage: state.rules.validateCodes.errorMessage,

            //criteria:
            ruleCriteria: selectRuleCriteria(state, props),

            // edit
            rule: selectRule(state, props),
            initialValues: selectInitialValues(state, props),
            contractId: selectContractId(state, props),
            serviceId: selectServiceId(state, props),
            serviceType: serviceType(state, props),
            ruleId: selectRuleId(state, props),
            ruleTypes: selectRuleTypes(state, props),
            methodTypes: selectMethodTypes(state, props),
            feeSchedules: selectSortedFeeSchedule(state, props),
            feeScheduleLabels: selectSortedFeeScheduleLabels(state, props),
            payMethods: selectPayMethods(state, props),
            selectedServerGroups: selectSelectedGroups(state, props),
            codeServices: selectFilteredCodeServices(state, props),
            paySources: selectPaySources(state, props),
            multiprocPrecedences: selectMultiprocPrecedences(state, props),
            modifiers: selectModifiers(state, props),
            modifiersPayMethods: selectModifersPayMethod(state, props),
            getFeescheduleRateLabelPending: state.feeScheduleItems.getFeeScheduleLabelRate.pending,
            getFeescheduleLabelsPending: state.contracts.feeScheduleLabelsList.pending,
            directoryId:props?.match?.params?.directoryId,

        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            ruleCreate: create,
            ruleGet: get,
            ruleGetMethodTypes: getMethodTypes,
            ruleGetTypes: getRuleTypes,
            ruleGetPayMethods: getPayMethods,
            ruleErrorReset: errorReset,
            contractFeeScheduleList: listFeeSchedule,
            codeServicesListTiny: tiny,
            ruleGetPaySources: getPaySources,
            ruleGetMultiPrecedences: getMultiPrecedences,
            getContract: getContract,
            ruleCodesValidate: validate,
            getModifiers: modifiersLanding,
            getModifiersPayMethods: getModifiersPayMethods,
            getRuleCriteria: getRuleCriteria,
            resetCodesValidate: resetCodesValidate,
            getFeeScheduleLabels: feeScheduleLabelsTiny,
            getFeescheduleRateLabel: getFeescheduleRateLabel,
        }, dispatch)
    })
)(injectIntl(CopyRuleComponent));
