import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    DialogActions,
    Button,
    Typography,
    IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { PagedTable } from '../../../../common';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    closeButton: {
        color: theme.palette.grey[500],
    },
    content: {
        maxHeight: 350,
    }
}));

const DEFAULT_SORT = [{ id: 'code', direction: 'asc' }];

export default function CodesDialog(props) {
    const {
        open, handleClose, totalCount, fields, descriptionData,
        title, serviceId, codeServiceDescriptionListPending, selectedField,
        actions: { getCodeServiceDescription }
    } = props;
    const classes = useStyles();

    const [refresh, setRefresh] = useState(Promise.resolve());

    function handleLoad(sort, filter, pagination) {
        getCodeServiceDescription(pagination, sort, filter, serviceId, selectedField);
    }

    return (
        (<Dialog
                fullWidth
                scroll="paper"
                maxWidth="lg"
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="form-dialog-title">
            <DialogTitle>
                <Grid container alignItems='center'>
                    <Grid item xs={11}>
                        <Typography variant="h6">{`${title} (${totalCount || 0})`}</Typography>
                    </Grid>
                    <Grid item xs={1} className='text-right'>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={() => handleClose(false)}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <div className={classes.content}>
                    <PagedTable
                        name="code-service-description-landing"
                        title="Service Code Description"
                        fields={fields}
                        data={descriptionData}
                        onLoad={handleLoad}
                        refresh={refresh}
                        defaultSort={DEFAULT_SORT}
                        stickyHeader={true}
                        tableInModal={true}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12} className='py-2 px-4 text-right'>
                        <Button onClick={() => handleClose(false)} variant="contained">
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>)
    );
}