import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => {
    return {
        releaseNotesItem: {
            marginBottom: '10px'
        },
    }
});

export default function ReleaseNotesItem(props) {
    const { id, title, description } = props;
    const classes = useStyles();

    return (
        <li key={id} className={classes.releaseNotesItem}>
            <Typography variant="h6">
                {title}
            </Typography>
            <Typography variant="body1">
                {description}
            </Typography>
        </li>
    );
}

ReleaseNotesItem.prototype = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}