import { Dialog, DialogContent, DialogTitle, DialogContentText,DialogActions,Button } from '@mui/material';
import React from 'react';


function StopExecutionsDialog(props) {
    const { open,stopExecutionCallback,stopExecution,stopExecutionId,
        actions: { setStopExecutionDialog } } = props;

    
    const handleStopConfirmClose = (params) => {
        setStopExecutionDialog(null)   
    }

    function handleDialogClose() {
        setStopExecutionDialog({open:false})

        
    }

    function handleStopExecution() {
        handleDialogClose();
        stopExecution(stopExecutionId, stopExecutionCallback);
        
    }

    return (
        (<Dialog open={open} onClose={handleStopConfirmClose}>
            <DialogTitle>
                Stop Execution
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to stop this execution?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleStopConfirmClose}>
                    Cancel
                </Button>
                <Button
                    onClick={handleStopExecution}
                    color="primary">
                    Stop
                </Button>
            </DialogActions>
        </Dialog>)
    );
}

export default StopExecutionsDialog;
