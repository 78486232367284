import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { getFeeschedule, resetGroups } from '../../../../../../store/actions/contracts';
import { downloadTemplate, errorReset, importExportReset, setEnableDialog, setFeeScheduleImportLabelDepdendencyDialog, setRateDialog, uploadFile } from '../../../../../../store/actions/feeScheduleItems';
import { feeScheduleItemsLanding, resetPage } from '../../../../../../store/actions/pages';
import { dateEquators, integerEquators, StringEquators } from '../../../../../../store/constants/pages';
import { createCurrencyDataField, createIconDataField, createModifiersFieldWithColons, createPlainDateField, createStringDataField } from '../../../../../../util/format';

import { set } from '../../../../../../store/actions/pages';
import { getByName, reset } from '../../../../../../store/actions/search';
import { setServiceFromFeeSchedule } from '../../../../../../store/actions/services';
import { selectGroups } from '../../../../../../store/selectors/contracts';
import { selectSessionRole } from '../../../../../../store/selectors/session';
import FeeScheduleItemsComponent from './FeeScheduleItems';


const MODIFERS_FIELDS = [
    { id: 'codeModifier1', label: 'Modifier 1' },
    { id: 'codeModifier2', label: 'Modifier 2' },
    { id: 'codeModifier3', label: 'Modifier 3' },
    { id: 'codeModifier4', label: 'Modifier 4' },
]

const selectFeeScheduleLabelToRateXRef = createSelector(
    (state) => state.contracts.feeSchedule,
    (feeSchedule) => {
        const labels = [];
        for (let index = 0; index <= 10; index++) {
            let label = index === 0 ? `Rate` : `Rate ${index}`;
            if(feeSchedule && feeSchedule.feescheduleLabelToRateXRef && feeSchedule.feescheduleLabelToRateXRef.length > 0) {
                label = feeSchedule.feescheduleLabelToRateXRef.find(x=> x.rateColumn === `Rate${index===0?'':index}`)?.label ?? label;
            }
            labels.push({ label: label, rate: (index === 0 ? `rate` : `rate${index}`) });
        }
        return labels;
    }
);

const selectFields = createSelector(
    selectFeeScheduleLabelToRateXRef,
    (state) => state.pages['feeschedule-items-landing'],
    (rateLabels) => [
        createStringDataField('code', 'Code', { sortable: true, equators: StringEquators }),
        createStringDataField('codeModifier1', 'Code Modifier 1', { sortable: true, equators: StringEquators, hideFromGrid: true }),
        createStringDataField('codeModifier2', 'Code Modifier 2', { sortable: true, equators: StringEquators, hideFromGrid: true }),
        createStringDataField('codeModifier3', 'Code Modifier 3', { sortable: true, equators: StringEquators, hideFromGrid: true }),
        createStringDataField('codeModifier4', 'Code Modifier 4', { sortable: true, equators: StringEquators, hideFromGrid: true }),
        createModifiersFieldWithColons('codeModifier1', 'Code Modifier', { sortable: true, equators: StringEquators, returnWholeRow: true, hideFromFilter: true,list:MODIFERS_FIELDS }),
        createStringDataField('codeType.name', 'Type', { sortable: true, equators: StringEquators, secondLevel: 'name' }),
        createStringDataField('description', 'Description', { sortable: true, equators: StringEquators, truncate: true }),
        createStringDataField('grouping', 'Grouping', { sortable: true, equators: StringEquators }),
        createStringDataField('paidCode', 'Paid Code', { sortable: true, equators: StringEquators }),
        createStringDataField('paidCodeType.name', 'Paid CodeType', { sortable: true, equators: StringEquators, secondLevel: 'name' }),
        createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
        createPlainDateField('effectiveDate', 'Effective Date', { fieldType: 'Date', sortable: true, equators: dateEquators, whiteSpace: 'pre' }),
        createPlainDateField('expirationDate', 'Expiration Date', { fieldType: 'Date', sortable: true, equators: dateEquators, whiteSpace: 'pre'}),
        createCurrencyDataField('rate', rateLabels?.find(x=> x.rate === 'rate').label ?? '', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('rate1', rateLabels?.find(x=> x.rate === 'rate1').label ?? '', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('rate2', rateLabels?.find(x=> x.rate === 'rate2').label ?? '', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('rate3', rateLabels?.find(x=> x.rate === 'rate3').label ?? '', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('rate4', rateLabels?.find(x=> x.rate === 'rate4').label ?? '', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('rate5', rateLabels?.find(x=> x.rate === 'rate5').label ?? '', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('rate6', rateLabels?.find(x=> x.rate === 'rate6').label ?? '', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('rate7', rateLabels?.find(x=> x.rate === 'rate7').label ?? '', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('rate8', rateLabels?.find(x=> x.rate === 'rate8').label ?? '', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('rate9', rateLabels?.find(x=> x.rate === 'rate9').label ?? '', { sortable: true, equators: integerEquators }),
        createCurrencyDataField('rate10', rateLabels?.find(x=> x.rate === 'rate10').label ?? '', { sortable: true, equators: integerEquators }),
    ]
);

const selectData = createSelector(
    (state) => state.pages['feeschedule-items-landing'].list,
    (data) => data,
);

const selectDefaultFilter = createSelector(
    selectGroups,
    (groups) => {
        return groups.map(group => {
            return {
                equator: 'equals',
                field: 'grouping',
                value: group.name
            }
        });
    }
);

export const FeeScheduleItemsView = (connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state),
            data: selectData(state),
            defaultFilter: selectDefaultFilter(state),
            filters: state.pages['feeschedule-items-landing'].filter,
            userRole: selectSessionRole(state),
            exportTemplatePending: state.feeScheduleItems.downloadTemplate.pending,
            uploadFilePending: state.feeScheduleItems.uploadFile.pending,
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            feeScheduleItemsLanding: feeScheduleItemsLanding,
            resetGroups: resetGroups,
            setServiceFromFeeSchedule: setServiceFromFeeSchedule,
            searchGetByName: getByName,
            searchReset: reset,
            feeScheduleItemErrorReset: errorReset,
            downloadTemplate: downloadTemplate,
            uploadFile: uploadFile,
            importExportReset: importExportReset,
            resetPagedTable: resetPage,
            setEnableDialog: setEnableDialog,
            resetTableData: () => set('feeschedule-items-landing', 'list', []),
            setFilter: (value) => set('feeschedule-items-landing', 'filter', value),
            setRateDialog: setRateDialog,
            setFeeScheduleImportLabelDepdendencyDialog: setFeeScheduleImportLabelDepdendencyDialog,
            getFeeschedule: getFeeschedule, //
        }, dispatch)
    })
)(injectIntl(FeeScheduleItemsComponent)));                                