import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton, TablePagination } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';

import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { DataTable, DataFilter, TablePaginationActions } from '../../common';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteConfirmation } from '../../common/DeleteConfirmation';
import { ModifierEditDialog } from './ModifiersEditPage';

const DEFAULT_SORT = [{ id: 'modifier', direction: 'asc' }];

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    icon: {
        fontSize: 17,
        marginTop: 1,
    },
    textField: {
        width: 400,
    },
}));

export default function ModifiersComponent(props) {
    const { modifierFields, ModifiersData, order, filter, sort, getModifiersPending, deleteModifiersPending,
        actions: {
            modifiersLanding, deleteModifier,
            setOrder, setFilter, setSort, modifierReset
        }
    } = props;

    const [isEditOpen, setEditOpen] = useState(false);
    const [selectModifier, setSelectedModifier] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        modifiersLanding();
        return () => {
            modifierReset();
            setFilter('');
        }
    }, [modifiersLanding]);

    const handleRefresh = () => {
        modifiersLanding();
        setSelectedModifier(null);
    }

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    const handleEditClose = (shouldReload) => {
        setEditOpen(false);
        setSelectedModifier(null);
        if (shouldReload) {
            handleRefresh();
        }
    }

    const handleAddModifier = () => {
        setEditOpen(true);
    }

    const handleEditModifier = (rowData) => {
        setEditOpen(true);
        setSelectedModifier(rowData);
    }

    const handleDeleteModifier = (rowData) => {
        deleteModifier(rowData.id, handleRefresh)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    function modifierAction(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleEditModifier(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
                <DeleteConfirmation handleDelete={() => handleDeleteModifier(rowData)} />
            </div>
        );
    }

    return (<>
        <Helmet title="Modifiers" />
        <Portlet>
            <PortletHeader
                title="Modifiers"
                handleRefresh={handleRefresh}
                name="modifiers-landing"
                toolbar={
                    <PortletHeaderToolbar>
                        <DataFilter
                            onChange={handleSearchInput}
                            placeholder="Search"
                            cssClass={classes.textField}
                            filter={filter}
                        />
                    </PortletHeaderToolbar>
                }
            />
            <PortletBody>
                <div className="row">
                    <div className="col-md-12">
                        <DataTable
                            name="modifiers-landing"
                            fields={modifierFields}
                            data={ModifiersData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                            handleRefresh={handleRefresh}
                            defaultSort={DEFAULT_SORT}
                            onAdd={handleAddModifier}
                            // tableLevelActions={tableLevelActions}
                            loading={getModifiersPending || deleteModifiersPending}
                            title="modifiers"
                            order={order}
                            onOrder={setOrder}
                            sort={sort}
                            onSort={setSort}
                            stickyHeader={true}
                            showPagination={true}
                            footerPagination={() => (
                                <TablePagination
                                    rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                    colSpan={modifierFields.length + 2}
                                    count={ModifiersData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            )}

                            hasActions={true}
                            renderActionColumn={modifierAction}
                        />
                    </div>
                </div>
            </PortletBody>
        </Portlet>
        <ModifierEditDialog open={isEditOpen} handleClose={handleEditClose} modifier={selectModifier} />
    </>);
}