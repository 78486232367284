/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Button,
    Dialog,
    DialogContent,
    Grid,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { HourglassFull } from '@mui/icons-material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Loadable } from '../../../../common';
import { DATE_TIMESTAMP_FORMAT } from '../../../../store/constants/date';
import { PRICERS_GET_NEGOTIATED_RATES_STATE_INVALID, PRICERS_GET_NEGOTIATED_RATES_STATE_PROCESSED } from '../../../../store/constants/pricer';
import { asDate } from '../../../../util/date';

const useStyles = makeStyles(theme => ({
    skipButton: {
        borderColor: '#ffc107',
        backgroundColor: '#ffc107',
        "&:hover": {
            borderColor: '#e0a800',
            backgroundColor: '#e0a800',
        },
    },
    labelDiv: {
        width: 170
    },
    viewLink: {
        fontWeight: 'bold'
    },
    tableHead: {
        backgroundColor: '#fafafa'
    },
}));

interface ShoppableStepProps {
    intl: any;
    history: any;
    activeStep: number;
    getStatsLoading: boolean;
    getNegotiatedRatesStatePending: boolean;
    stats: Array<{
        facilityName: string;
        totalShoppable: number;
        required: number;
        shoppable: number;
        total: number;
        lastUpdatedAt: string | null;
        lastUpdatedBy: string;
    }>;
    handleNext: () => void;
    handleBack: () => void;
    cleanupAndProcessPending: boolean;
    handleRedirectToNegotiatedRates: () => void;
    negotiatedRatesState: {
        value: string;
        updatedBy: string;
        updatedAt: string;
    } | null;
    actions: {
        getShoppablesStats: () => void;
        getNegotiatedRatesState: (callback: (state: any) => void) => void;
        errorReset: () => void;
        cleanupAndProcess: (callback: () => void) => void;
        getPricerConfigContracts: () => void;
        setConfigFromShoppables: (value: boolean) => void;
    };
}

function ShoppableStep({ history, activeStep, getStatsLoading, getNegotiatedRatesStatePending, stats, handleNext, handleBack,
    cleanupAndProcessPending, handleRedirectToNegotiatedRates,
    negotiatedRatesState, actions: { getShoppablesStats, getNegotiatedRatesState, errorReset, cleanupAndProcess,
        getPricerConfigContracts, setConfigFromShoppables } }: ShoppableStepProps & RouteComponentProps) {

    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isExecutionDialogOpen, setExecutionDialogOpen] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (activeStep === 1) {
            getShoppablesStats();
        }
        return () => {
            errorReset()
        }
    }, [activeStep, getShoppablesStats, errorReset]);

    const handleGetNegotiatedRatesState = () => {
        getNegotiatedRatesState(handleStateCallback);
    }

    const handleStateCallback = (_state) => {
        if (_state?.value === PRICERS_GET_NEGOTIATED_RATES_STATE_INVALID) {
            setConfigFromShoppables(true);
            setDialogOpen(true);
        } else if (_state?.value === PRICERS_GET_NEGOTIATED_RATES_STATE_PROCESSED) {
            setConfigFromShoppables(false);
            handleNext();
        }
    }

    // skip payer contract selection and directly move to negotiated stats
    const handleCleanupAndProcess = () => {
        getPricerConfigContracts();
        cleanupAndProcess(handleCleanupCallback);
    }

    const handleCleanupCallback = () => {
        handleDialogClose();
        setExecutionDialogOpen(true);
        //handleRedirectToNegotiatedRates();
    }

    const handleRedirectToShoppables = () => {
        history.push('/pricer/shoppable')
    }

    const handleRedirectToExecutionPage = () => {
        setExecutionDialogOpen(false);
        history.push('/pricer/executions');
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    return (<>
        <Loadable loading={getStatsLoading || getNegotiatedRatesStatePending}>
            <Grid item xs={12} className="mt-3">
                <Card>
                    <Card.Header as="h5" className='text-center'>Shoppables</Card.Header>
                    <Card.Body className='text-dark px-5 py-4'>
                        <Grid container>
                            <Grid item md={12} container>
                                <Table aria-label="Shoppables Table">
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            <TableCell align='left'>Facility </TableCell>
                                            <TableCell align='left'>Total Shoppables ( 
                                                <Link
                                                    className={classes.viewLink}
                                                    component="button"
                                                    variant="body2"
                                                    onClick={handleRedirectToShoppables}
                                                >
                                                    View
                                                </Link>
                                            )</TableCell>
                                            <TableCell align='left'>Required </TableCell>
                                            <TableCell align='left'>Shoppable </TableCell>
                                            <TableCell align='left'>Total </TableCell>
                                            <TableCell align='left'>Last Updated At </TableCell>
                                            <TableCell align='left'>Last Updated By </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stats.map((stat, index) => (
                                            <TableRow key={index}>
                                                <TableCell align='left'>{stat.facilityName}</TableCell>
                                                <TableCell align='left'>{stat.totalShoppable}</TableCell>
                                                <TableCell align='left'>{stat.required}</TableCell>
                                                <TableCell align='left'>{stat.shoppable}</TableCell>
                                                <TableCell align='left'>{stat.total}</TableCell>
                                                <TableCell align='left'>{asDate( stat.lastUpdatedAt, 'date-time') || 'N/A'}</TableCell>
                                                <TableCell align='left'>{stat.lastUpdatedBy}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                        <Grid className='pt-3 justify-content-end' container>
                            <Button
                                size='small'
                                onClick={handleBack}
                                variant="contained"
                                className='mr-2'
                                disabled={getNegotiatedRatesStatePending}>
                                Back
                            </Button>
                            <Button
                                size='small'
                                onClick={handleGetNegotiatedRatesState}
                                variant="contained"
                                color="primary"
                                disabled={getNegotiatedRatesStatePending || (stats.length > 0 ? false : true)}
                            >
                                {getNegotiatedRatesStatePending ? <HourglassFull /> : 'Next'}
                            </Button>
                        </Grid>
                    </Card.Body>
                </Card>
            </Grid>
        </Loadable>
        <Dialog
            fullWidth
            scroll="paper"
            maxWidth="sm"
            open={isDialogOpen}
            aria-labelledby="form-dialog-title">
            <DialogContent className='p-4'>
                <Loadable loading={cleanupAndProcessPending}>
                    <Grid container className='pt-2'>
                        <Grid>
                            <p>Charge Master has been updated by user {negotiatedRatesState?.updatedBy} on {moment.utc(negotiatedRatesState?.updatedAt).local().format(DATE_TIMESTAMP_FORMAT)}. Click OK to reprocess negotiated rates for all payers </p>
                        </Grid>
                    </Grid>
                    <Grid container className='justify-content-end pt-3'>
                        <Button onClick={handleDialogClose} variant="contained" className={`mr-3`}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCleanupAndProcess}
                        >
                            OK
                        </Button>
                    </Grid>
                </Loadable>
            </DialogContent>
        </Dialog>
        <Dialog
            fullWidth
            scroll="paper"
            maxWidth="sm"
            open={isExecutionDialogOpen}
            aria-labelledby="form-dialog-title">
            <DialogContent className='p-4'>
                <Loadable loading={cleanupAndProcessPending}>
                    <Grid container className='pt-2'>
                        <Grid>
                            <p> Your request has been submitted. Please check in the execution logs page. </p>
                        </Grid>
                    </Grid>
                    <Grid container className='justify-content-end pt-3'>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleRedirectToExecutionPage}
                        >
                            OK
                        </Button>
                    </Grid>
                </Loadable>
            </DialogContent>
        </Dialog>
    </>);
}
export default withRouter(ShoppableStep)
