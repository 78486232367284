import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { changePrecedence, claimStatusesReset, claimStatusLanding, createEditReset, setClaimStatusEditDialog, setDependencyDialog } from '../../store/actions/claimStatus';
import { set } from '../../store/actions/pages';
import { StringEquators } from '../../store/constants/pages';
import { createIconDataField, createStringDataField } from '../../util/format';
import ClaimStatusPageComponent from './ClaimStatusPage';
import { createFilteredSortedDataSelector } from "../../util/selector";
import { RootState } from "../../store/reducers";

const DEFAULT_SORT = [{ id: 'precedence', orderBy: 'asc' }];

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Status Display Name', { sortable: false, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('type', 'Status Type', { sortable: false, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createIconDataField('preRecovery', 'Pre-Recovery?', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: false }),
    ]
);

export const selectClaimStatusList = (state) => state.claimStatus.data;

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`claim-status-landing`] ? state.pages[`claim-status-landing`].sort : null,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`claim-status-landing`] ? state.pages[`claim-status-landing`].filter : null,
    selectSort,
    selectClaimStatusList,
);

export const ClaimStatusPage = injectIntl(
    connect(
        (state : RootState, props) => ({
            data: selectFilteredData(state),
            fields: selectFields(state as never),
            order: state.pages['claim-status-landing'].order,
            filter: state.pages['claim-status-landing'].filter,
            sort: selectSort(state, props),
            statusChangePrecedencePending: state.claimStatus.changePrecedence.pending
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                claimStatusLanding: claimStatusLanding,
                claimStatusesReset: claimStatusesReset,
                createEditReset: createEditReset,
                setClaimStatusEditDialog: setClaimStatusEditDialog,
                setDependencyDialog: setDependencyDialog,
                changePrecedence: changePrecedence,
                setFilter: (value) => set(`claim-status-landing`, 'filter', value),
                setOrder: (value) => set(`claim-status-landing`, 'order', value),
                setSort: (value) => set(`claim-status-landing`, 'sort', value),
            }, dispatch)
        })
    )(ClaimStatusPageComponent));