import { FormGroup, Grid, Switch, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { DataFilter } from '../../../../../../common';

function RulePageTopFilter(props) {
    const { handleSearchInput, cssClass, actions: { setRuleStatus } } = props;
    const [isEnabled, setEnabled] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setRuleStatus(isEnabled);
        }, 200);
    }, [setRuleStatus, isEnabled])

    const handleStatusChanged = (event) => {
        setEnabled(event.target.checked);
    }

    return (
        <>
            <Grid item>
                <DataFilter
                    onChange={handleSearchInput}
                    placeholder="Search"
                    cssClass={cssClass}
                />
            </Grid>
            <Grid item className='pl-3'>
                <FormGroup>
                    <Tooltip title='Toggle to view enabled rules or all rules'>
                        <Typography component="">
                            <Grid className='mb-0' component="label" container alignItems="center" spacing={0}>
                                <Grid item>All</Grid>
                                <Grid item>
                                    <Switch
                                        checked={isEnabled}
                                        onChange={handleStatusChanged}
                                        name="enabled" />
                                </Grid>
                                <Grid item>Enabled</Grid>
                            </Grid>
                        </Typography>
                    </Tooltip>

                </FormGroup>
            </Grid>
        </>
    )
}

export default RulePageTopFilter
