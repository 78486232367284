import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { FormGroup, Grid, Typography, Switch } from '@mui/material';
import { FieldArray, getIn } from "formik";
import React from "react";

import {
    Portlet,
    PortletBody
} from "../../../../partials/content/Portlet";

import {
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 8,
        width: '90%'
    },
    formControl1: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: '90%'
    },
    portlet: {
        'box-shadow': 'none',
        margin: 8,
    },
    successIcon: {
        color: 'green'
    },
    errorIcon: {
        color: 'red'
    },
    borderDiv: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2),
        borderBottom: '1px solid #e0e0e0'
    },
    toggle: {
        marginTop: "10px",
        paddingLeft: '10px'
    }
}));


export default function BucketFieldArray({
    values, handleBlur, customhandleBlur, customftpUserhandleBlur, handleChange, touched, errors,
    bindArrayPush, mode, parserList, bucketValidity, ftpUserValidity, setFieldValue, clientsBuckets
}) {
    const classes = useStyles();
    const isEdit = mode === 'edit';


    const hasError = (index, name) => {
        return Boolean(getIn(touched, `buckets.${index}.${name}`) &&
            getIn(errors, `buckets.${index}.${name}`));
    }

    const getHelperText = (index, name) => {
        return getIn(touched, `buckets.${index}.${name}`) &&
            getIn(errors, `buckets.${index}.${name}`);
    }

    const isBucketValid = (index) => {
        const buckets = values.buckets;
        let matches = [];
        for (let i = 0; i < buckets.length; i++) {
            if (buckets[i].name === buckets[index].name) {//find index of all occurrences of same name
                matches.push(i);
            }
        }
        if (buckets[index].name && matches.length > 1 && (index !== matches[0])) {//if there is another bucket with same name
            return true;
        }

        //validation check from API
        const hasError = bucketValidity[`done-buckets.${index}.name`];
        return Boolean(hasError);

    }

    const isftpUserValid = (index) => {

        const buckets = values.buckets;
        let matches = [];
        for (let i = 0; i < buckets.length; i++) {
            if (buckets[i].ftpUser.name === buckets[index].ftpUser.name) {//find index of all occurrences of same name
                matches.push(i);
            }
        }
        if (buckets[index].ftpUser.name && matches.length > 1 && (index !== matches[0])) {//if there is another ftp users with same name
            return true;
        }

        //validation check from API
        const hasError = ftpUserValidity[`done-buckets.${index}.ftpUser.name`];
        return Boolean(hasError);

    }
    const handleUsekeyToggle = (value, index) => {
        setFieldValue(`buckets.${index}.ftpUser.hasPassword`, value)
    }

    return (<>
        <div>
            <FieldArray
                name="buckets"
                render={({ remove, push }) => (
                    <div>
                        {bindArrayPush(push)}
                        {values.buckets && values.buckets.length > 0 ? (
                            values.buckets.map((bucket, index) => {
                                return (
                                    (<Portlet key={`key-${index}`} className={classes.portlet + ' mx-0'}>
                                        <FormGroup className={classes.toggle}>
                                            <Typography component="div">
                                                <Grid component="label" className='mb-0' container alignItems="center" spacing={0}>
                                                    <Grid item>Use Public Key</Grid>
                                                    <Grid item>
                                                        <Switch
                                                            disabled={bucket?.id && bucket?.id !== 0}
                                                            name={`buckets.${index}.ftpUser.hasPassword`}
                                                            checked={bucket.ftpUser.hasPassword || false}
                                                            color="primary"
                                                            onChange={(e) => handleUsekeyToggle(e.target.checked, index)}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>
                                                    <Grid item>Use Password</Grid>
                                                </Grid>
                                            </Typography>
                                        </FormGroup>
                                        <PortletBody className="p-0">
                                            <div>
                                                <div className="row" key={`key-${index}`}>
                                                    <div className="col-md-5">
                                                        <TextField
                                                            required
                                                            label="Bucket Name"
                                                            name={`buckets.${index}.name`}
                                                            className={classes.textField}
                                                            margin="normal"
                                                            onBlur={customhandleBlur}
                                                            onChange={handleChange}
                                                            value={bucket.name}
                                                            disabled={bucket?.id && bucket?.id !== 0}
                                                            helperText={
                                                                isBucketValid(index) ?
                                                                    'Bucket is not available.' :
                                                                    getHelperText(index, 'name')
                                                            }
                                                            error={hasError(index, 'name') || isBucketValid(index)}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">
                                                                    {(!bucket?.clientId || bucket?.id === 0) && bucket.name && (isBucketValid(index) ? (
                                                                        <CloseIcon className={classes.errorIcon} />
                                                                    ) : (
                                                                        <CheckIcon className={classes.successIcon} />
                                                                    ))}

                                                                </InputAdornment>,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <FormControl
                                                            required
                                                            margin="normal"
                                                            className={classes.formControl1}
                                                            error={hasError(index, 'parser')}
                                                            disabled={bucket?.id && bucket?.id !== 0}
                                                        >
                                                            <InputLabel htmlFor="parser">Select Parser</InputLabel>
                                                            <Select
                                                                value={bucket.parser}
                                                                onChange={handleChange}
                                                                error={hasError(index, 'parser')}
                                                                input={<Input id={`parser-buckets.${index}.parser`} />}
                                                                inputProps={{
                                                                    name: `buckets.${index}.parser`,
                                                                    id: `buckets.${index}.parser`
                                                                }}
                                                            >
                                                                {parserList.map(parser => (
                                                                    <MenuItem
                                                                        key={parser.name}
                                                                        value={parser.name}
                                                                    >
                                                                        {parser.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            <FormHelperText error={true}>{getHelperText(index, 'parser')}</FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                    {(mode === "create" || bucket?.id == 0) && (values?.buckets?.length > 1) &&
                                                        <div className="col-md-1 pt-3 text-right">
                                                            <IconButton
                                                                edge="start"
                                                                color="default"
                                                                aria-label="Delete"
                                                                onClick={() => remove(index)}
                                                                size="large">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <TextField
                                                            required
                                                            label="Ftp User Name"
                                                            inputProps={{
                                                                autocomplete: 'new-username',
                                                                form: {
                                                                  autocomplete: 'off',
                                                                },
                                                              }}
                                                            name={`buckets.${index}.ftpUser.name`}
                                                            className={classes.textField}
                                                            margin="normal"
                                                            onBlur={customftpUserhandleBlur}
                                                            onChange={handleChange}
                                                            value={bucket.ftpUser.name}
                                                            disabled={bucket?.id && bucket?.id !== 0}
                                                            helperText={
                                                                isftpUserValid(index) ?
                                                                    'User is not available.' :
                                                                    getHelperText(index, 'ftpUser.name')
                                                            }
                                                            error={hasError(index, 'ftpUser.name') || isftpUserValid(index)}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">
                                                                    {(!bucket?.clientId || bucket?.id === 0) && bucket.ftpUser.name && (isftpUserValid(index) ? (
                                                                        <CloseIcon className={classes.errorIcon} />
                                                                    ) : (
                                                                        <CheckIcon className={classes.successIcon} />
                                                                    ))}

                                                                </InputAdornment>,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <TextField
                                                            label="Description"
                                                            name={`buckets.${index}.description`}
                                                            autoComplete='description'
                                                            className={classes.textField}
                                                            margin="normal"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={bucket.description}
                                                            disabled={bucket?.clientId && bucket?.id !== 0}
                                                            helperText={getHelperText(index, 'description')}
                                                            error={hasError(index, 'description')}
                                                        />
                                                    </div>
                                                </div>
                                                {(mode === 'create' || bucket?.id === 0) && bucket?.ftpUser?.hasPassword && (
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <TextField
                                                                required
                                                                label="Password"
                                                                inputProps={{
                                                                    autocomplete: 'new-password',
                                                                    form: {
                                                                      autocomplete: 'off',
                                                                    },
                                                                  }}
                                                                name={`buckets.${index}.ftpUser.password`}
                                                                className={classes.textField}
                                                                type="password"
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={bucket.ftpUser.password}
                                                                helperText={getHelperText(index, 'ftpUser.password')}
                                                                error={hasError(index, 'ftpUser.password')}
                                                            />
                                                        </div>

                                                        <div className="col-md-5">
                                                            <TextField
                                                                required
                                                                label="Confirm Password"
                                                                name={`buckets.${index}.ftpUser.confirmPassword`}
                                                                className={classes.textField}
                                                                type="password"
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={bucket.ftpUser.confirmPassword}
                                                                helperText={getHelperText(index, 'ftpUser.confirmPassword')}
                                                                error={hasError(index, 'ftpUser.confirmPassword')}
                                                            />
                                                        </div>
                                                    </div>
                                                )}


                                            {isEdit  &&  bucket?.ftpUser?.hasPassword &&  bucket?.id!==0 &&                                                   
                                                <div className='row'>
                                                        {clientsBuckets && clientsBuckets[index]?.ftpUser?.hasPassword && 
                                                        <div className="col-md-5">
                                                            <TextField
                                                                type="password"
                                                                label="Current Password"
                                                                name={`buckets.${index}.password`}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={bucket.ftpUser.password}
                                                                autoComplete='current-password'
                                                                disabled={isEdit}
                                                            />
                                                        </div>}


                                                        <div className="col-md-5">
                                                            <TextField
                                                                type="password"
                                                                label="New Password"
                                                                autoComplete='new-password'

                                                                name={`buckets.${index}.ftpUser.newPassword`}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={bucket.ftpUser.newPassword || ''}
                                                                // disabled={isEdit}
                                                                helperText={getHelperText(index, 'ftpUser.newPassword')}
                                                                error={hasError(index, 'ftpUser.newPassword')}
                                                            />
                                                        </div>

                                                        <div className="col-md-5">
                                                            <TextField
                                                                type="password"
                                                                label="Confirm New Password"
                                                                autoComplete='confirm-new-password'

                                                                name={`buckets.${index}.ftpUser.confirmNewPassword`}
                                                                className={classes.textField}
                                                                margin="normal"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={bucket.ftpUser.confirmNewPassword || ''}
                                                                // disabled={isEdit}
                                                                helperText={getHelperText(index, 'ftpUser.confirmNewPassword')}
                                                                error={hasError(index, 'ftpUser.confirmNewPassword')}
                                                            />
                                                        </div>

                                                    </div>
                                                }

                                                <div className="row">


                                                    {!bucket.ftpUser?.hasPassword &&

                                                        <div className="col-md-5">
                                                            <TextField
                                                                multiline
                                                                rows={3}
                                                                maxRows={3}

                                                                label="Public Key"
                                                                className={classes.textField}
                                                                margin="normal"
                                                                name={`buckets.${index}.ftpUser.publicKey`}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={bucket.ftpUser.publicKey}
                                                                // disabled={isEdit}
                                                                helperText={getHelperText(index, 'ftpUser.publicKey') || 'A valid Public key format is required'}
                                                                error={hasError(index, 'ftpUser.publicKey')}
                                                            />
                                                        </div>
                                                    }

                                                    <div className="col-md-5">
                                                        <TextField
                                                            multiline
                                                            rows={3}
                                                            maxRows={3}
                                                            label="Allowed IP/Mask"
                                                            className={classes.textField}
                                                            margin="normal"
                                                            name={`buckets.${index}.ftpUser.allowedIp`}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={bucket?.ftpUser?.allowedIp || ''}
                                                            helperText={getHelperText(index, 'ftpUser.allowedIp') || 'IP must be in comma-separated CIDR format, e.g., 192.168.1.0/24,10.8.0.100/32'}
                                                            error={hasError(index, 'ftpUser.allowedIp')}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-md-11">
                                                        <div className={classes.borderDiv}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </PortletBody>
                                    </Portlet>)
                                );
                            })
                        ) : (
                            <h2>No bucket found</h2>
                        )}
                    </div>
                )}
            />
        </div>
    </>);
}
