import { Grid, IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import { Edit as EditIcon } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import classNames from 'classnames';
import { PagedTable } from '../../../common/pagedTable';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { EnableDialog } from './EnableDialog';
import { PayerDialog } from './PayerDialog';

const DEFAULT_SORT = [{ id: 'displayName', direction: 'asc' }];

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    icon: {
        fontSize: 17,
        marginTop: 1,
    }
}));

export default function PayerInfoComponent(props) {
    const {
        updateStatusPending, payerData, payerFields,
        checkboxStatus, selectAllStatusPending, payerContracts,
        actions: {
            // updatePayerStatus,
            payersLanding,
            // payersGetPayerContractsTiny,
            searchReset, searchGetByName,
            resetPagedTable,
            setPayerInfoEditDialog,
            setPayerInfoEnableDialog,
            resetTableData
        }
    } = props;

    useEffect(() => {
        const pageName = {
            name: 'pricer-payerinfo-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name)
        }
    }, [searchGetByName]);

    // useEffect(() => {
    //     payersGetPayerContractsTiny();
    // }, [payersGetPayerContractsTiny]);

    const [refresh, setRefresh] = useState(Promise.resolve());

    const classes = useStyles();

    // useEffect(() => {
    //     getPayers();
    // }, [getPayers]);


    const handlePayerEdit = (payer) => {
        setPayerInfoEditDialog({ open: true, payer: payer });
    };

    const handleAddPayer = () => {
        setPayerInfoEditDialog({ open: true, payer: null });
    }

    const handleClose = (shouldReloadGrid) => {
        setPayerInfoEditDialog(null);
        if (shouldReloadGrid === true) {
            resetTableData([]);
            handleRefresh();
        }
    }

    function handleLoad(sort, filter, pagination) {
        payersLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    function handlePayerConfirmation(isEnable) {

        setPayerInfoEnableDialog({ open: true, isEnableClicked: isEnable })
    }

    function tableLevelActions() {
        return (
            <>
                <Grid item>
                    <Tooltip title="Enable">
                        <IconButton aria-label="enable" size='small' onClick={() => handlePayerConfirmation(true)}>
                            <CheckCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Disable">
                        <IconButton aria-label="disable" size='small' onClick={() => handlePayerConfirmation(false)}>
                            <i className={classNames("fa fa-solid fa-ban", classes.icon)}></i>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    function payerInfoActions(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    className={classes.IconButton}
                    aria-label="Edit"
                    onClick={() => handlePayerEdit(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
            </div>
        );
    }


    return (
        <>
            <Helmet title="Payer Info" />
            <Portlet>
                <PortletHeader
                    title="Payer Info"
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                    handleRefresh={handleRefresh}
                    name="pricer-payerinfo-landing"
                    toolbar={
                        <PortletHeaderToolbar>

                        </PortletHeaderToolbar>
                    }
                    includeAdvanceFilters={true}
                />
                <PortletBody>
                    <div className="row">
                        <div className="col-md-12">
                            <PagedTable
                                name="pricer-payerinfo-landing"
                                title="Payer Info"
                                fields={payerFields}
                                data={payerData}
                                onLoad={handleLoad}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                hasCheckbox={true}
                                showSelectAll={true}
                                tableLevelActions={tableLevelActions}
                                selectAllChecked={checkboxStatus}
                                loading={updateStatusPending || selectAllStatusPending}
                                onAdd={handleAddPayer}
                                criteriasSaveEnabled={true}

                                hasActions={true}
                                renderActionColumn={payerInfoActions}
                                showSearchFilters={true}
                            />
                        </div>
                    </div>
                </PortletBody>
                <PayerDialog payerContracts={payerContracts} handleClose={handleClose} />
                <EnableDialog handleClose={handleClose} />
            </Portlet>
        </>
    );
}