import React from "react";
import { SwitchClient } from '../../pages/administration/ClientsPage/switchClient/index'

export default class StickyToolbar extends React.Component {
  render() {
    return (
      <>
        <SwitchClient />
      </>
    );
  };
}