import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { pricerExecutionsLanding, resetPage, set } from '../../../store/actions/pages';
import { pricerDownloadReportById, pricerResetErrorMessages, pricerStopExecution } from '../../../store/actions/executions';
import { getByName, reset } from '../../../store/actions/search';
import { dateEquators, integerEquators, StringEquators } from '../../../store/constants/pages';
import { selectCanAccessExecutionLogs } from '../../../store/selectors/access/contractManagement';
import { selectSessionRole } from '../../../store/selectors/session';
import { createExecutionStatusField, createNumberDataField, createProgressBarField, createStringDataField, createTimestampField } from '../../../util/format';
import PricerExecutionPageComponent from './PricerExecutionPage';

const selectFields = createSelector(
    () => [
        createNumberDataField('id', 'Execution Id', { equators: integerEquators, sortable: true, }),
        createStringDataField('payerContract', 'Payer Contract', { sortable: true, equators: StringEquators }),
        createStringDataField('deploymentType', 'Deployment Type', { sortable: true, equators: StringEquators }),
        createExecutionStatusField('state', 'State', { sortable: true, equators: StringEquators }),
        createNumberDataField('totalCount', 'Total Count', { sortable: true, equators: integerEquators }),
        createStringDataField('runId', 'Run Id', { sortable: true, equators: StringEquators }),
        createTimestampField('executedAt', 'Execution Date', { sortable: true, equators: dateEquators }),
        createStringDataField('executedBy', 'Executed By', { sortable: true, equators: StringEquators }),
    ]
);

const selectExecutionsData = createSelector(
    (state) => state.pages['pricer-executions-landing'].list,
    (data) => data
);

export const PricerExecutionPage = connect(

    () => {
        return (state, props) => ({
            fields: selectFields(state as never),
            executionsData: selectExecutionsData(state),
            userRole: selectSessionRole(state),
            hasExecutionLogsAccess: selectCanAccessExecutionLogs(state),
            stopPending: state.executions.pricerStop.pending,
            downloadReportPending: state.executions.pricerDownloadReportById.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            executionsLanding: pricerExecutionsLanding,
            searchGetByName: getByName,
            searchReset: reset,
            setFilter: (value) => set('pricer-executions-landing', 'filter', value),
            stopExecution: pricerStopExecution,
            downloadReportById: pricerDownloadReportById,
            resetErrorMessages: pricerResetErrorMessages,
            resetPagedTable: resetPage,
            resetCriteriaWaiting: (pageName) => set(pageName, 'waitForCriteriaFilter', false),
        }, dispatch)
    })
)(injectIntl(PricerExecutionPageComponent));