import React, { useEffect, useState } from "react";
import {
    Table,
    Button,
    Breadcrumbs,
    Box,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
    InputAdornment,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Tooltip,
    Chip
} from  "@mui/material";
import { makeStyles } from "@mui/styles";
import { RowCheckbox } from "../../../common/dataTable/rowCheckbox";
import { PagedTable } from "../../../common";
import { Loadable, TableLevelAction } from "../../../common";
import CloseIcon from "@mui/icons-material/Close";
import HeaderColumn from "../../../common/dataTable/HeaderColumn";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';

const useStyles:any = makeStyles(theme => {
    return {
        searchBox: {
            float: "left",
            position: "absolute",
            left: 0,
            width: "100%",
            background: "#fff",
            borderRadius: "2px",
            border: "1px #bdbbbb solid",
            overflow: "hidden",
            zIndex: 999,
            minHeight: "100px",
            padding: "10px"
        },
        root: {
            height: 240,
            flexGrow: 1,
            maxWidth: 400
        },
        selectionTableRow: {
            cursor: "pointer"
        },
        folderDiv: {
            cursor: "pointer",
            textDecoration: "underline"
        },
        backBox: {
            width: "100%",
            marginBottom: "20px",
            textAlign: "left"
        },
        selectionRow: {
            cursor: "pointer"
        },
        chipBox: {
            paddingTop: '8px'
        },
        clearIcon:{
            fontSize:'14px',
            marginLeft:'5px',
        },
        inline:{
            display:'inline',
            marginLeft:"10px"
        },
        "padding-0":{
            padding:0
        },
        "padding-4":{
            padding:"4px"
        },
        breadCrumbItem:{
            textDecoration: "underline",
            color: "blueviolet",
            cursor: 'pointer'
        }
    };
});
function ContractSearchComponent({data = [],
    intl,
    fields,
    sort,
    pending,
    handleSetContract,
    defaultValue,
    value,
    icon=undefined,
    isMulti=false,
    chipBoxClass=undefined,
    iconButtonClass=undefined,
    tableName='',
    tableFields=[],
    tableData=[],
    tableLoad=undefined,
    handleSetPaginatedData=undefined,
    tableRefresh=undefined,
    handlePaginatedRefresh=undefined,
    isEdit=false,
    tableTitle=undefined,
    setPaginatedSearch=undefined,
    // contractKey=undefined,
    tablePending=false,
    prePopulatedValues=undefined,
    isKeyDownSearch=false,
    selected,
    isPaginatedSearch=false,
    title=undefined,
    actions: {contractsSearch, setSort }}:any) {
    const [isOpen, setOpen] = useState(false);
    const [searchText, setSearchText] = useState({text:"",clearAble:false});
    const [directoryId, setDirectoryId] = useState([]);
    const classes = useStyles();
    

    useEffect(() => {
            
        if (isOpen && !isPaginatedSearch) {            
            contractsSearch({
                keyword: searchText.text,
                directoryId:
                    directoryId.length === 0
                        ? null
                        : directoryId[directoryId.length - 1]?.id
            });
        }
       
    }, [contractsSearch, isOpen]);
    useEffect(() => {
        if (isMulti && isOpen === false && selected?.length >= 0) {
                handleSetContract([...selected]);
        }
    },!isEdit?  [isOpen]:[isOpen,prePopulatedValues]);
    
    const handleClick = (event) => {

        if (
            event?.target?.getAttribute("data-testid") === "CloseIcon" ||
            event?.target?.parentElement?.getAttribute("data-testid") ===
                "CloseIcon"
        )
            return;
        setSearchText({text:"",clearAble:false});
        setOpen(true);
    };

    const handleKeyDown = (event) => {
        if(!isKeyDownSearch) return;

        if (
            event?.target?.getAttribute("data-testid") === "CloseIcon" ||
            event?.target?.parentElement?.getAttribute("data-testid") ===
                "CloseIcon"
        )
            return;

            let keyword = event.target.value;

        if (event.key === "Enter" && keyword?.length>2) {
            setSearchText({ text: "", clearAble: false });
            setOpen(true);
        }
};

    const handleDialogClose = () => {
        if(setPaginatedSearch){setPaginatedSearch('')}
        setDirectoryId([])
        setOpen(false);
        setSearchText({text:"",clearAble:false});
    };
    function handleSubmit() {
        const isRemovingOrApplyingSearch=searchText?.text?.length >= 3 || searchText?.text?.length === 0
        setSearchText({...searchText,clearAble:searchText.text.length>=3 ? true:false});

        if(isKeyDownSearch && searchText?.text?.length >= 3)
        {
            setPaginatedSearch(searchText.text)
            handlePaginatedRefresh()

            return
        }
        if (isRemovingOrApplyingSearch && !isKeyDownSearch) {
            contractsSearch({
                keyword: searchText.text,
                directoryId:
                    directoryId.length === 0
                        ? null
                        : directoryId[directoryId.length - 1]?.id
            });
        }
    }
    function handleRowClick(rowData) {
        handleSetContract(
            rowData,
            directoryId?.length === 0
                ? "root"
                : directoryId[directoryId?.length - 1]?.id
        );
        if (isMulti) {
            return;
        }
        setSearchText({ text: rowData.name, clearAble: false });
        handleDialogClose();
    }   
    const handlePaginatedRowClick = (rowData) => {
        handleSetPaginatedData(rowData)
        handleDialogClose();

    }
    const handleRefresh = () => {
        setSearchText({text:'',clearAble:false});
        if(isKeyDownSearch)
            {
                setPaginatedSearch('')
                handlePaginatedRefresh()
                return
            }
        contractsSearch({
            keyword: "",
            directoryId: directoryId[directoryId.length - 1]?.id
        }); 
    };
    const handleSort = ({ id, direction, fieldType }) => {
        if (!setSort) {
            return;
        }
        const sorts = [...sort];
        const updatedSort = { id, fieldType, direction: "asc" };
        const oldSort = sorts.find(sort => {
            return sort.id === id;
        });
        // sort exists, toggle sort
        if (oldSort && oldSort.id === id) {
            const index = sorts.indexOf(oldSort);
            updatedSort.direction =
                oldSort.direction === "asc" ? "desc" : "asc";
            sorts[index] = updatedSort;
        }
        // new sort
        else {
            updatedSort.direction = "desc";
            sorts.splice(0, sorts.length);
            sorts.push(updatedSort);
        }
        setSort(sorts);
    };
    const { id: columnOrder, direction } =
        (sort && sort.length > 0 && sort[0]) || {};

    const handleRedirectToDirectory = (dId, name, fromBreadCrumbs = false) => {
        let resultArray = [];
        if (fromBreadCrumbs) {
            for (const item of directoryId) {
                const isHierarchyEnded = item?.id === dId;
                resultArray.push({
                    ...item,
                    viewOnly: isHierarchyEnded ? true : false,
                });
                if (isHierarchyEnded) {
                    if (resultArray.length === 1) {
                        resultArray = [];
                    }
                    break;
                }
            }
            setDirectoryId([...resultArray]);
        } else {
            setDirectoryId(
                directoryId?.length === 0
                    ? [
                          { name: "Contracts", id: undefined },
                          { id: dId, name, viewOnly: true },
                      ]
                    : [
                          ...directoryId?.map((item) => ({
                              ...item,
                              viewOnly: false,
                          })),
                          { id: dId, name, viewOnly: true },
                      ]
            );
        }
        contractsSearch({
            keyword: "",
            directoryId: fromBreadCrumbs
                ? resultArray[resultArray?.length - 1]?.id
                : dId,
        });
    };

    const getData = () => {
        return data;
    };
    const renderSearchTextField = () => {
        return (
            <>
                <TextField
                    helperText={isKeyDownSearch && "Minimum 3 Characters"}
                    fullWidth
                    label="Search"
                    onKeyDown={(event)=>{
                        if(event.key === 'Enter')
                        {
                            handleSubmit()
                        }
                        }}
                    value={searchText.text}
                    onChange={event => setSearchText({...searchText, text:event.target.value})}
                    // variant='outlined'
                    name="searchTextField"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleSubmit}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </>
        );
    };
    
    return (
        <>
            <ClickAwayListener onClickAway={handleDialogClose}>
                <div className={`${classes.chipBox} ${icon && classes.inline} ${chipBoxClass}`}>
                    {defaultValue ? (
                        <Chip style={{maxWidth: '480px'}}
                            label={
                               (!isMulti) ? searchText.text != "" && !isOpen
                                    ? (searchText.text.length > 50 ? `${searchText.text.substring(0, 50)}...` : searchText.text)
                                    : (value.length > 50 ? `${value.substring(0, 50)}...` : value)
                                    || (defaultValue.length > 50 ? `${defaultValue.substring(0, 50)}...` : defaultValue)
                                  
                                    || "Search Contracts":value?.length===0 ? defaultValue :value?.map((item)=>item?.name + " , ")
                            } 
                            title={
                                searchText.text != "" && !isOpen
                                    ? searchText.text : value
                                    || defaultValue || "Search Contracts"
                            }
                            onClick={handleClick}
                        />
                    ) : (
                        <IconButton
                            aria-label="Search"
                            onClick={handleClick}
                            // onKeyDown={handleKeyDown}
                            className={`${icon ? classes['padding-0']: classes['padding-4']} ${iconButtonClass}` }
                        >
                        {    icon ||
                            <Tooltip title="Search">
                                <SearchIcon />
                            </Tooltip>}                            
                        </IconButton>
                    )}
                    {isOpen ? (
                        <Dialog
                            maxWidth="md"
                            fullWidth
                            open={isOpen}
                            onClose={handleDialogClose}
                        >
                            <Loadable loading={pending || tablePending}>
                                <DialogTitle>
                                    <Grid container alignItems="center">
                                        <Grid item xs={3}>
                                            <Typography variant="h6">
                                                {title || "Contracts Search"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {renderSearchTextField()}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            className="text-right"
                                        >
                                            <IconButton
                                                aria-label="close"
                                                onClick={handleDialogClose}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent dividers>
                                    {!isPaginatedSearch && <Grid spacing={1} container>
                                    <Grid item xs={10}>
                                            <Breadcrumbs separator=">" aria-label="breadcrumb">
                                                {directoryId?.map((item) =>
                                                    <Typography key={item?.id}
                                                        className={!item?.viewOnly && classes.breadCrumbItem}
                                                        onClick={() => !item?.viewOnly && handleRedirectToDirectory(item?.id, item?.name, true)}>
                                                        {item?.name}
                                                    </Typography>
                                                )
                                                }
                                            </Breadcrumbs>
                                    </Grid>

                                        <Grid item xs={2}>
                                            <TableLevelAction
                                                handleRefresh={handleRefresh}
                                                name={""}
                                                loading={false}
                                                actionLevelGrid={null}
                                            />
                                        </Grid>


                                        <Grid item xs={12}>
                                            <Box
                                                className={`${classes.backBox}`}
                                            >
                                               
                                                {searchText.clearAble  && (
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleRefresh}
                                                    >
                                                        Clear Search <ClearIcon className={`${classes.clearIcon}`} />
                                                    </Button>
                                                )}
                                            </Box>
                                            <Table stickyHeader={true}>
                                                <TableHead>
                                                    <TableRow
                                                        className={`fee-schedule-thr`}
                                                    >
                                                        {fields?.map(
                                                            (field, index) =>
                                                                !field?.options
                                                                    .hideFromGrid && (
                                                                    <HeaderColumn
                                                                        key={`column-${index}`}
                                                                        field={
                                                                            field
                                                                        }
                                                                        onSort={
                                                                            handleSort
                                                                        }
                                                                        direction={
                                                                            direction
                                                                        }
                                                                        columnOrder={
                                                                            columnOrder
                                                                        }
                                                                        colSpan={
                                                                            index ===
                                                                                0 &&
                                                                            4
                                                                        }
                                                                    />
                                                                )
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {getData()?.length === 0 &&
                                                        getData()?.length === 0 && (
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell
                                                                    colSpan={5}
                                                                >
                                                                    No records
                                                                    found!
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    {getData()?.map(item => {
                                                        return (
                                                            item ?.isDirectory &&
                                                            <TableRow className="fee-schedule-row" key={item?.id}>
                                                                <TableCell
                                                                    id={`folder-${item.id}`}
                                                                    colSpan={4}
                                                                >
                                                                    <div
                                                                        id={`folder-${item.id}`}
                                                                        className={
                                                                            classes.folderDiv
                                                                        }
                                                                        onClick={() =>
                                                                            handleRedirectToDirectory(
                                                                                item.id,
                                                                                item.name
                                                                            )
                                                                        }
                                                                    >
                                                                        <IconButton
                                                                            id={`folder-${item.id}`}
                                                                            className="p-1 mr-2"
                                                                        >
                                                                            <i
                                                                                id={`folder-${item.id}`}
                                                                                style={{
                                                                                    color:
                                                                                        "#FFCA35"
                                                                                }}
                                                                                className="fas fa-folder"
                                                                            ></i>
                                                                        </IconButton>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </TableCell>
                                                               
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {getData()?.map(item => {
                                                        return (
                                                       
                                                            !item?.isDirectory && (
                                                                <TableRow
                                                                key={item?.id}
                                                                    className={`fee-schedule-row ${classes.selectionRow}`}
                                                                    onClick={() =>
                                                                      !isMulti &&  handleRowClick(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                 
                                                                    <TableCell
                                                                        className="pl-4"
                                                                        colSpan={4}
                                                                    >
                                                                        {isMulti && (
                                                                            <RowCheckbox
                                                                                rowDataId={`${item.id}`}
                                                                                pageName={
                                                                                    "contractsv2-landing"
                                                                                }
                                                                                customClasses="p-1"
                                                                            />
                                                                        )}
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </TableCell>
                                                                    
                                                                </TableRow>
                                                            )
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>}


                                    {isPaginatedSearch && 
                                    
                                     <div className="row">
                                        {searchText.clearAble  && (
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleRefresh}
                                                    >
                                                        Clear Search <ClearIcon className={`${classes.clearIcon}`} />
                                                    </Button>
                                                )}
                                                                    <div className="col-md-12">
                                                                        <PagedTable
                                                                            onRowClick={(rowData)=>handlePaginatedRowClick(rowData)}
                                                                            name={tableName}
                                                                            fields={tableFields}
                                                                            data={tableData}
                                                                            onLoad={tableLoad}
                                                                            refresh={tableRefresh}
                                                                            defaultSort={[]}
                                                                            title={tableTitle}
                                                                            // tableLevelLoading={tablePending}
                                                                        />
                                                                    </div>
                                                                </div>
                                    }
                                </DialogContent>
                                <DialogActions className="p-4">
                                    <Button
                                        variant="contained"
                                        onClick={handleDialogClose}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Loadable>
                        </Dialog>
                    ) : null}
                </div>
            </ClickAwayListener>
        </>
    );
}
export default ContractSearchComponent;