import { Grid, IconButton, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import { DataTable } from '../../../../common';
import PageviewIcon from '@mui/icons-material/Pageview';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Parser } from 'json2csv';

function ClaimLineItemSection(props) {

    const { claimnumber, fields, filteredData, order, sort, claimLineItemPending,
        refreshLineItems, actions: { setOrder, setSort, setAdjustment, resetClaimLineItem } } = props;

    useEffect(() => {
        return () => {
            resetClaimLineItem();
        }
    }, [resetClaimLineItem])

    const hoverData = (service) => {
        const hoverColumns = new Map();
        const contractNameColumns = [
            {
                label: "Name",
                data: service?.rule?.name
            }, {
                label: "Type",
                data: service?.rule?.ruleType?.name
            }, {
                label: "Pay Method",
                data: service?.rule?.payMethod?.name
            }, {
                label: "Pay Value",
                data: service?.rule?.payValue
            },
        ];

        let pxCodeColumns = [
            {
                label: "",
                data: service.pxcodeDescription
            }
        ];
        if (service?.feeScheduleId && service.pxcode) {
            if (service.pxcode.toString().includes(':')) {
                const splitedPxCode = service.pxcode?.split(":");
                pxCodeColumns.push({
                    type: "LINK",
                    label: "More Details",
                    data: `/cms/feeschedules/view/${service.feeScheduleId}/${splitedPxCode[0]}`,
                })
            } else {
                pxCodeColumns.push({
                    type: "LINK",
                    label: "More Details",
                    data: `/cms/feeschedules/view/${service.feeScheduleId}/${service.pxcode}`,
                })
            }

        }
        const revCodeColumns = [
            {
                label: "",
                data: service.revcodeDescription
            }
        ];

        hoverColumns.set('rule', contractNameColumns);
        hoverColumns.set('pxcode', pxCodeColumns);
        hoverColumns.set('revcode', revCodeColumns);

        return hoverColumns;
    }

    const handleExportClick = () => {
        let csvFields = fields.map((field) => {
            return {
                label: field.label,
                value: field.id === 'rule' ? 'rule.name' : field.id,
            }
        });

        csvFields = [
            ...csvFields,
            {
                label: 'DRG',
                value: 'drg',
            }
        ];
        const json2csvParser = new Parser({ fields: csvFields });
        const csv = json2csvParser.parse(filteredData);

        const blob = new Blob([csv], { type: 'text/csv' });

        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${claimnumber}_claimDetails.csv`;
        a.click();
    }

    const tableLevelAction = () => {
        return (
            <>
                <Grid item>
                    <Tooltip title="Export">
                        <IconButton aria-label="export" size='small' onClick={handleExportClick} disabled={false}>
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }


    const handleViewServiceAdjustment = (rowData) => {
        setAdjustment({
            open: true,
            type: 'SERVICE',
            id: rowData?.servicepaymentid
        });
    }


    const claimActions = (rowData) => {
        return (
            <div className='d-flex'>
                <IconButton
                    color="default"
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleViewServiceAdjustment(rowData)}
                    size="large">
                    <Tooltip title="View Service Adjustments">
                        <PageviewIcon />
                    </Tooltip>
                </IconButton>
            </div>
        );
    }

    function actionLevelGrid() {
        return <Grid container className='mt-24'>
            <Grid item md={12}>
                <h6 className="">{`Total Line Items : ${filteredData?.length ?? 0}`}</h6>
            </Grid>
        </Grid>
    }

    return (
        <DataTable
            fields={fields}
            data={filteredData}
            order={order}
            onOrder={setOrder}
            sort={sort}
            onSort={setSort}
            loading={claimLineItemPending}
            displayTotal={true}
            hoverData={hoverData}
            tableLevelActions={tableLevelAction}
            handleRefresh={() => {
                refreshLineItems();
            }}
            actionLevelGrid={actionLevelGrid}
            stickyHeader={true}
            tableInModal={true}

            hasActions={true}
            customActionColumnName='Adjustments'
            renderActionColumn={claimActions}
        />
    )
}

export default ClaimLineItemSection
