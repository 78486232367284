import { FieldArray } from "formik";
import React from "react";

import {
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginTop: theme.spacing(4),
        width: '85%',
        '& .MuiInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
          },
          }
    },
    portlet: {
        'box-shadow': 'none',
    },
    formControl1: {
        width: '85%',
    },
    button: {
        margin: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    iconButton: {
        marginTop: theme.spacing(3),
    }
}));


export default function RuleMultiPrecidenceFieldArray(props) {
    const {
        values, handleBlur, handleChange, touched, errors, mode,
        multiprocPrecedences,
    } = props;

    const classes = useStyles();
    const isEdit = mode === 'edit';

    function getHelperText(key, index) {
        if (touched.multiProcedurePercents) {
            if (touched.multiProcedurePercents[index]) {
                return touched.multiProcedurePercents[index][key];
            }
            return false;
        }
        return false;
    }

    function getError(key, index) {
        if (errors.multiProcedurePercents) {
            if (errors.multiProcedurePercents[index]) {
                return errors.multiProcedurePercents[index][key];
            }
            return false;
        }
        return false;
    }

    return (
        (<FieldArray
            name="multiProcedurePercents"
            render={({ remove, insert }) => (
                <Grid container>
                    <Grid item xs={8}>
                        {values.multiProcedurePercents.map((multiProc, index) => {
                            return (
                                (<Grid item xs={12} key={index}>
                                    <Grid container>

                                        <Grid item xs={5}>
                                            <FormControl
                                                required
                                                margin="normal"
                                                className={classes.formControl1}
                                                disabled={isEdit}
                                            >
                                                <InputLabel htmlFor="precedenceId">Precedence</InputLabel>
                                                <Select
                                                    value={multiProc.precedenceId}
                                                    onChange={handleChange}
                                                    // helperText={getHelperText('precedenceId', index) && getError('precedenceId', index)}
                                                    error={Boolean(getHelperText('precedenceId', index) && getError('precedenceId', index))}
                                                    input={<Input id={`multiProcedurePercents.${index}.precedenceId`} />}
                                                    inputProps={{
                                                        name: `multiProcedurePercents.${index}.precedenceId`,
                                                        id: `multiProcedurePercents.${index}.precedenceId`
                                                    }}
                                                >
                                                    {multiprocPrecedences.map(mp => (
                                                        <MenuItem
                                                            key={mp.name}
                                                            value={mp.id}
                                                        >
                                                            {mp.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <TextField
                                                type="number"
                                                name={`multiProcedurePercents.${index}.percentage`}
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={multiProc.percentage}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                }}
                                                helperText={getHelperText('percentage', index) && getError('percentage', index)}
                                                error={Boolean(getHelperText('percentage', index) && getError('percentage', index))}
                                            />
                                        </Grid>
                                        {(index >= 0 && values.multiProcedurePercents.length > 1) && (
                                            <Grid item xs={1}>
                                                <IconButton
                                                    edge="start"
                                                    aria-label="Delete"
                                                    onClick={() => remove(index)}
                                                    className={classes.iconButton}
                                                    size="large">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                        {index === 0 && (
                                            <Grid item xs={1}>
                                                <IconButton
                                                    edge="start"
                                                    color="primary"
                                                    aria-label="Add"
                                                    disabled={values.multiProcedurePercents.length === multiprocPrecedences.length}
                                                    onClick={() => insert(multiprocPrecedences.length - 1, {
                                                        precedenceId: '',
                                                        percentage: '',
                                                    })}
                                                    className={classes.iconButton}
                                                    size="large">
                                                    <AddCircleIcon />
                                                </IconButton>
                                            </Grid>
                                        )}

                                        
                                    </Grid>
                                </Grid>)
                            );
                        })}
                    </Grid>
                </Grid>
            )}
        />)
    );
}
