import React, { useEffect } from 'react'
import { Loadable } from '../../../common';
import { Formik } from 'formik';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../partials/content/Portlet';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from "react-router-dom";
import { BackButton } from '../../../common/BackButton';
import { GridExpandMoreIcon } from '@mui/x-data-grid';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: '90%',
    },
    accordion: {
        backgroundColor: 'transparent',
        boxShadow: '0px 0px 13px 0px rgba(82, 63, 105, 0.1) !important',
        border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    accordionSummary: {
        backgroundColor: '#fff',
    }
}));

function OPPSEditPage(props) {
    const { intl, initialValues, history, mode, createPending, editPending, getPending,
        oppsId, actions: { createOPPS, getOPPS, editOPPS } } = props;

    const classes = useStyles();

    useEffect(() => {
        if (mode === 'edit') {
            getOPPS(oppsId)
        }
    }, [getOPPS, mode, oppsId]);

    const handleCallback = () => {
        history.push('/cms/opps');
    }

    return (
        <Loadable loading={createPending || editPending || getPending}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={values => {
                    const errors : Record<string,string> = {};
                    if (values.name === '') {
                        errors.name = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.effectiveFrom === '') {
                        errors.effectiveFrom = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.effectiveTill === '') {
                        errors.effectiveTill = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.providerCcn === '') {
                        errors.providerCcn = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.cbsaActualGeographicLocation === '') {
                        errors.cbsaActualGeographicLocation = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.countyCode === '') {
                        errors.countyCode = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.stateCode === '') {
                        errors.stateCode = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.cbsaWageIndexLocation === '') {
                        errors.cbsaWageIndexLocation = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.costOfLivingAdjustment === '') {
                        errors.costOfLivingAdjustment = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.hospitalQualityIndicator === '') {
                        errors.hospitalQualityIndicator = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.intermediaryNumber === '') {
                        errors.intermediaryNumber = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.medicarePerformanceAdjustment === '') {
                        errors.medicarePerformanceAdjustment = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.providerType === '') {
                        errors.providerType = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.specialPaymentIndicator === '') {
                        errors.specialPaymentIndicator = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.specialWageIndex === '') {
                        errors.specialWageIndex = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.supplementalWageIndexIndicator === '') {
                        errors.supplementalWageIndexIndicator = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    if (values.waiverIndicator === '') {
                        errors.waiverIndicator = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                    return errors;
                }}
                onSubmit={(values) => {
                    let payload = {
                        name: values.name,
                        wageIndex: values.wageIndex,
                        outpatientCostToChargeRatio: values.outpatientCostToChargeRatio,
                        implantCostToChargeRatio: values.implantCostToChargeRatio,
                        outlierMultiple: values.outlierMultiple,
                        opFixedDollarThreshold: values.opFixedDollarThreshold,
                        effectiveFrom: values.effectiveFrom,
                        effectiveTill: values.effectiveTill,
                        providerCcn: values.providerCcn,
                        cbsaActualGeographicLocation: values.cbsaActualGeographicLocation,
                        countyCode: values.countyCode,
                        stateCode: values.stateCode,
                        cbsaWageIndexLocation: values.cbsaWageIndexLocation,
                        costOfLivingAdjustment: values.costOfLivingAdjustment ?? 0,
                        hospitalQualityIndicator: values.hospitalQualityIndicator,
                        intermediaryNumber: values.intermediaryNumber,
                        medicarePerformanceAdjustment: values.medicarePerformanceAdjustment ?? 0,
                        providerType: values.providerType,
                        specialPaymentIndicator: values.specialPaymentIndicator,
                        specialWageIndex: values.specialWageIndex ?? 0,
                        supplementalWageIndexIndicator: values.supplementalWageIndexIndicator,
                        waiverIndicator: values.waiverIndicator,
                        id : undefined as number
                    }
                    if (mode === 'create') {
                        createOPPS(payload, handleCallback);
                    } else {
                        payload = {
                            ...payload,
                            id: parseInt(oppsId)
                        }
                        editOPPS(payload, handleCallback);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    dirty
                }) => (
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        <Portlet>
                            <PortletHeader
                                title='OPPS Configuration'
                                toolbar={
                                    <PortletHeaderToolbar>
                                        <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/cms/opps" />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className='ml-3'
                                            type="submit"
                                            disabled={createPending || editPending || (mode === 'edit' && !dirty)}
                                        >
                                            Save
                                        </Button>
                                    </PortletHeaderToolbar>
                                }
                            />
                            <PortletBody>
                                <div className="root">
                                    <Grid container>
                                        <Grid xs={8}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        required
                                                        label="Name"
                                                        name="name"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        helperText={touched.name && errors.name}
                                                        error={Boolean(touched.name && errors.name)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type='number'
                                                        required
                                                        label="Wage Index"
                                                        name="wageIndex"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.wageIndex}
                                                        helperText={touched.wageIndex && errors.wageIndex}
                                                        error={Boolean(touched.wageIndex && errors.wageIndex)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type='number'
                                                        required
                                                        label="Outpatient Cost to Charge Ratio"
                                                        name="outpatientCostToChargeRatio"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.outpatientCostToChargeRatio}
                                                        helperText={touched.outpatientCostToChargeRatio && errors.outpatientCostToChargeRatio}
                                                        error={Boolean(touched.outpatientCostToChargeRatio && errors.outpatientCostToChargeRatio)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type='number'
                                                        required
                                                        label="Implant Cost To Charge Ratio"
                                                        name="implantCostToChargeRatio"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.implantCostToChargeRatio}
                                                        helperText={touched.implantCostToChargeRatio && errors.implantCostToChargeRatio}
                                                        error={Boolean(touched.implantCostToChargeRatio && errors.implantCostToChargeRatio)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type='number'
                                                        required
                                                        label="Outlier Multiple"
                                                        name="outlierMultiple"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.outlierMultiple}
                                                        helperText={touched.outlierMultiple && errors.outlierMultiple}
                                                        error={Boolean(touched.outlierMultiple && errors.outlierMultiple)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type='number'
                                                        required
                                                        label="Op Fixed Dollar Threshold"
                                                        name="opFixedDollarThreshold"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.opFixedDollarThreshold}
                                                        helperText={touched.opFixedDollarThreshold && errors.opFixedDollarThreshold}
                                                        error={Boolean(touched.opFixedDollarThreshold && errors.opFixedDollarThreshold)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        required
                                                        type="date"
                                                        label="Effective From"
                                                        name="effectiveFrom"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.effectiveFrom}
                                                        helperText={touched.effectiveFrom && errors.effectiveFrom}
                                                        error={Boolean(touched.effectiveFrom && errors.effectiveFrom)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        required
                                                        type="date"
                                                        label="Effective Till"
                                                        name="effectiveTill"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.effectiveTill}
                                                        helperText={touched.effectiveTill && errors.effectiveTill}
                                                        error={Boolean(touched.effectiveTill && errors.effectiveTill)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            
                                        </Grid>
                                        
                                    </Grid>
                                    <br />
                                    <Accordion className={classes.accordion} defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<GridExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            className={classes.accordionSummary}
                                        >
                                            <Typography variant='h6'>Provider Data</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="Provider Ccn"
                                                        name="providerCcn"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.providerCcn}
                                                        helperText={touched.providerCcn && errors.providerCcn}
                                                        error={Boolean(touched.providerCcn && errors.providerCcn)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="Cbsa Actual Geographic Location"
                                                        name="cbsaActualGeographicLocation"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.cbsaActualGeographicLocation}
                                                        helperText={touched.cbsaActualGeographicLocation && errors.cbsaActualGeographicLocation}
                                                        error={Boolean(touched.cbsaActualGeographicLocation && errors.cbsaActualGeographicLocation)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="County Code"
                                                        name="countyCode"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.countyCode}
                                                        helperText={touched.countyCode && errors.countyCode}
                                                        error={Boolean(touched.countyCode && errors.countyCode)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="State Code"
                                                        name="stateCode"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.stateCode}
                                                        helperText={touched.stateCode && errors.stateCode}
                                                        error={Boolean(touched.stateCode && errors.stateCode)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="Cbsa Wage Index Location"
                                                        name="cbsaWageIndexLocation"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.cbsaWageIndexLocation}
                                                        helperText={touched.cbsaWageIndexLocation && errors.cbsaWageIndexLocation}
                                                        error={Boolean(touched.cbsaWageIndexLocation && errors.cbsaWageIndexLocation)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        type='number'
                                                        label="Cost of Living Adjustment"
                                                        name="costOfLivingAdjustment"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.costOfLivingAdjustment}
                                                        helperText={touched.costOfLivingAdjustment && errors.costOfLivingAdjustment}
                                                        error={Boolean(touched.costOfLivingAdjustment && errors.costOfLivingAdjustment)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="Hospital Quality Indicator"
                                                        name="hospitalQualityIndicator"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.hospitalQualityIndicator}
                                                        helperText={touched.hospitalQualityIndicator && errors.hospitalQualityIndicator}
                                                        error={Boolean(touched.hospitalQualityIndicator && errors.hospitalQualityIndicator)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="Intermediary Number"
                                                        name="intermediaryNumber"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.intermediaryNumber}
                                                        helperText={touched.intermediaryNumber && errors.intermediaryNumber}
                                                        error={Boolean(touched.intermediaryNumber && errors.intermediaryNumber)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        type="number"
                                                        label="Medicare Performance Adjustment"
                                                        name="medicarePerformanceAdjustment"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.medicarePerformanceAdjustment}
                                                        helperText={touched.medicarePerformanceAdjustment && errors.medicarePerformanceAdjustment}
                                                        error={Boolean(touched.medicarePerformanceAdjustment && errors.medicarePerformanceAdjustment)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="Provider Type"
                                                        name="providerType"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.providerType}
                                                        helperText={touched.providerType && errors.providerType}
                                                        error={Boolean(touched.providerType && errors.providerType)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="Special Payment Indicator"
                                                        name="specialPaymentIndicator"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.specialPaymentIndicator}
                                                        helperText={touched.specialPaymentIndicator && errors.specialPaymentIndicator}
                                                        error={Boolean(touched.specialPaymentIndicator && errors.specialPaymentIndicator)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        type="number"
                                                        label="Special Wage Index"
                                                        name="specialWageIndex"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.specialWageIndex}
                                                        helperText={touched.specialWageIndex && errors.specialWageIndex}
                                                        error={Boolean(touched.specialWageIndex && errors.specialWageIndex)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="Supplemental Wage Index Indicator"
                                                        name="supplementalWageIndexIndicator"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.supplementalWageIndexIndicator}
                                                        helperText={touched.supplementalWageIndexIndicator && errors.supplementalWageIndexIndicator}
                                                        error={Boolean(touched.supplementalWageIndexIndicator && errors.supplementalWageIndexIndicator)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        required
                                                        label="Waiver Indicator"
                                                        name="waiverIndicator"
                                                        className={classes.textField}
                                                        margin="normal"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.waiverIndicator}
                                                        helperText={touched.waiverIndicator && errors.waiverIndicator}
                                                        error={Boolean(touched.waiverIndicator && errors.waiverIndicator)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </PortletBody>
                        </Portlet>
                    </form>
                )}
            </Formik>
        </Loadable>
    )
}

export default OPPSEditPage
