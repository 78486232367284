import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react'
import Loadable from '../../../../../../../common/loadable/Loadable';

function EnableDialog(props) {
    const { open, isEnableClicked, selectedItems, handleRefresh, updateStatusPending,
        actions: { setEnableDialog, setSelected, feeScheduleItemUpdateStatus } } = props;

    function handleClose() {
        setEnableDialog(null);
        setSelected([]);
    }

    function handleDialogConfirmation() {
        const payload = {
            ids: selectedItems,
            enabled: isEnableClicked,
        };
        feeScheduleItemUpdateStatus(payload, updateStatusCallback);
    }

    const updateStatusCallback = () => {
        setEnableDialog(null);
        setSelected([]);
        handleRefresh();
    }

    return (
        (<div>
            <Dialog open={open} onClose={handleClose}>
                <Loadable loading={updateStatusPending}>
                    <DialogTitle>
                        {!isEnableClicked ? 'Disable' : 'Enable'} Records?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {selectedItems.length > 0 ? (
                                `Are you sure you want to ${!isEnableClicked ? 'disable' : 'enable'} records?`
                            ) : (
                                `Please select records to ${!isEnableClicked ? 'disable' : 'enable'}`
                            )}

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDialogConfirmation}
                            disabled={selectedItems.length < 1}
                            color="primary">
                            {!isEnableClicked ? 'Disable' : 'Enable'}
                        </Button>
                    </DialogActions>
                </Loadable >
            </Dialog>
        </div>)
    );
}

export default EnableDialog
