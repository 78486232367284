export function combinePaginationFilters(operator, filters) {
    if (!Array.isArray(filters) || filters.length === 0) {
        return null;
    }
    const operands = filters.filter(Boolean);
    if (operands.length === 0) {
        return null;
    }
    // if (operands.length === 1) {
    //     return operands[0];
    // }

    if (operator === 'and') {
        return { operator, filters: operands };
    }
    return { operator, operands };
}

export interface Filter {
    field: string;
    equator?: string;
    value: any;
}

export interface TimeFilter {
    lowRange: any;
    highRange: any;
}

export interface CombinedFilter {
    operator: string;
    filters?: Filter[];
    operands?: CombinedFilter[];
}

export function getPaginationFilter(
    filters: Filter[], 
    timeFilterField: string | null, 
    timeFilter: TimeFilter | null, 
    customFilter: Filter[]
): CombinedFilter | null {
    if ((!Array.isArray(filters) || filters.length === 0) && !timeFilterField && (!Array.isArray(customFilter) || customFilter.length === 0)) {
        return null;
    }
    let mergedFilters = filters;
    if(customFilter && customFilter.length > 0) {
        if(filters) {
            mergedFilters = [
                ...filters,
                ...customFilter,
            ];
        } else {
            mergedFilters = [
                ...customFilter,
            ]
        }
    }

    let groups = Object
        // Convert to a `Map<fieldId, Array<filter>>`.
        .entries(mergedFilters.reduce((map, filter) => {
            if (filter && filter.field) {
                let list = map[filter.field];
                if (!list) {
                    map[filter.field] = list = [];
                }
                list.push(filter);
            }
            return map;
        }, {} as Record<string, Filter[]>))
        // Convert  queries into operators based requests.
        .map(([fieldId, list]) => list.map((filter) => {
            let { equator } = filter;
            if (!equator) {
                equator = 'equals';
            }

            return { id : fieldId, equator, value: filter.value };
        }))
        .map((group) => combinePaginationFilters('and', group));

    if (timeFilterField && timeFilter && timeFilter.lowRange) {
        const timeFilters = [
            {
                equator: 'greater_than',
                id: 'percentage',
                value: timeFilter.lowRange,
            },
            {
                equator: 'less_than',
                id: 'percentage',
                value: timeFilter.highRange
            }
        ];

        groups = [
            ...groups,
            {
                operator: 'and',
                filters: [...timeFilters],
            }
        ]
    }

    // `AND` all groups together.
    return combinePaginationFilters('And', groups);
}

export function getTimeFilter(timeFilterField, timeFilter) {
    if (!timeFilter || !timeFilterField) {
        return null;
    }
}
export function getTableSearchFilter(value){
   return {

        operator:"and"
           ,operands: [
                {
                    "operator": "and",
                    "filters": [
                        {
                            "id": "quickSearchTerm",
                            "equator": "like",
                            value
                        }
                    ]
                }
            ]
        }
}