import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react'
import { Loadable } from '../../../../../../common';
import { Formik } from 'formik';
import { Autocomplete } from '@mui/material';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
    textField: {
        width: '90%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    autoComplete: {
        width: '90%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

}));

function CloneRule(props) {
    const { intl, open, ruleId, ruleName, initialValues, handleClose, getContractsPending, contractedServices, serviceTypeObj, existingContractId,
        getContractedServicePending, contracts, cloneRulePending, actions: { getTinyContracts, getContractedServices, ruleClone } } = props;


    const classes = useStyles();

    useEffect(() => {
        if (existingContractId) {
            getContractedServices(existingContractId);
        }
    }, [getContractedServices, existingContractId]);

    useEffect(() => {
        if (open) {
            getTinyContracts();
        }
    }, [open, getTinyContracts]);

    const handleContractChange = (setFieldValue, value) => {
        setFieldValue('targetContractId', value);
        if (value && value.id) {
            getContractedServices(value.id);
        }
    }

    const getTargetServiceId = (targetContractId) => {
        const targetService = contractedServices.find(item => item.contractId === targetContractId && item.serviceTypeId === serviceTypeObj.id);
        return targetService.id ?? null
    }

    const handleCallback = () => {
        handleClose(true);
    }

    return (
        (<div>
            <Dialog fullWidth maxWidth="md" open={open}>
                <DialogTitle>
                    Clone Rule ({ruleName})
                </DialogTitle>
                <DialogContent dividers>
                    <Loadable loading={cloneRulePending || getContractsPending}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validate={values => {
                                const errors = {};
                                if (!values.newRuleName) {
                                    errors.newRuleName = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                                if (!values.targetContractId) {
                                    errors.targetContractId = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                const payload = {
                                    ruleId: ruleId,
                                    newRuleName: values.newRuleName,
                                    targetContractId: values.targetContractId.id,
                                    targetContractedServiceId: getTargetServiceId(values.targetContractId.id)
                                }
                                ruleClone(payload, handleCallback);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                label="New Rule name"
                                                name={`newRuleName`}
                                                className={classes.textField}
                                                margin="normal"
                                                type={'text'}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.newRuleName}
                                                helperText={touched.newRuleName && errors.newRuleName}
                                                error={Boolean(touched.newRuleName && errors.newRuleName)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                className={classes.autoComplete}
                                                value={values.targetContractId}
                                                onChange={(e, value) => handleContractChange(setFieldValue, value)}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                name={`targetContractId`}
                                                options={contracts}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        required
                                                        name={`targetContractId`}
                                                        label="Contract"
                                                        margin="normal"
                                                        helperText={touched.targetContractId && errors.targetContractId}
                                                        error={Boolean(touched.targetContractId && errors.targetContractId)}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                disabled
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Contracted Service"
                                                className={classes.textField}
                                                margin="normal"
                                                type={'text'}
                                                value={serviceTypeObj?.name}
                                            />
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid container justifyContent="flex-end">
                                        <Button onClick={() => handleClose(false)} variant="contained">
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={`ml-3`}
                                            type="submit"
                                            disabled={cloneRulePending || getContractedServicePending}
                                        >
                                            {(cloneRulePending) ? 'Saving Changes' : 'Clone'}
                                        </Button>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Loadable>
                </DialogContent>
            </Dialog>
        </div>)
    );
}

export default CloneRule
