import {
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Divider,
    Tabs,
    Tab,
    Grid,
    Typography,
    Switch,
    Toolbar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Card as BCard } from 'react-bootstrap';
import { Portlet } from '../../../partials/content/Portlet';

import { Loadable, VanillaJSONEditor } from '../../../common';

const useStyles = makeStyles(theme => ({
    textField: {
        // width: 400,
    },
    clearButton: {
        color: "#d9534f"
    },
    summaryView: {
        marginTop: "20px",
        marginRight: "10px",
        maxHeight: "700px",
        overflowY: "auto",
        border: '1px solid #ebedf2',
        borderRadius: '0.25rem'
    },
    summaryViewList:{
        paddingInlineStart: "15px",
        paddingTop: "20px",
        listStyleType: "none"
    },
    summaryViewListItem:{
        textDecoration: "underline",
        color: "#263AC1",
        marginBottom: "15px",
        cursor:'pointer'
    },
    active:{
        color: "blueviolet",
    },
    summaryViewHeader:{
        height:'52px',
        display:"grid",
        alignItems:'center'
    },
    switch : {
        margin: 0
    }
}));

function ClaimJsonView(props) {
    const { isFromModel, claimNumber, getJsonPending, claimId, claimJson,consolidatedClaimsData,getconsolidatedClaimsPending,
        actions: { getClaimJSONView, resetJSONView, getModelConsolidatedClaims, getConsolidatedClaims, 
            resetConsolidatedClaimsView, resetModelConsolidatedClaimsView,resetLookupId } } = props;

    const [mode, setMode] = useState('claims');

    const [hideEmptyColumns, setHideEmptyColumns] = useState(true);

    const [currentSummaryId, setCurrentSummaryId] = useState(null);

    const classes = useStyles();    
    

    useEffect(() => {
        if (claimNumber) {
            getClaimJSONView(claimNumber);
            return () => {
                resetJSONView();
                resetLookupId()
            }
        }
    }, [claimNumber, getClaimJSONView, resetJSONView]);

    useEffect(() => {
        if (claimId) {
            isFromModel ? getModelConsolidatedClaims(claimId.replace('/', ',')) : getConsolidatedClaims(claimId)
            return () => {
                resetConsolidatedClaimsView();
                resetModelConsolidatedClaimsView();
            }
        }
    }, [claimId, isFromModel, getConsolidatedClaims, resetConsolidatedClaimsView]);

    const handleConsolidatedClaims = (conSummaryId) => {
        if(conSummaryId!==currentSummaryId)
        {   
            getClaimJSONView(conSummaryId)
        }
        setCurrentSummaryId(conSummaryId);
    }
    
    const areConsolidatedClaimsNotEmpty = () => {
        return !getconsolidatedClaimsPending && consolidatedClaimsData?.length>0
        
    }


    return (
        <>
            <Portlet >
                <DialogTitle style={{ cursor: "move" }} id="claim-json-dialog">
                    <Typography variant="h6">
                        {" "}
                        Data View ({claimNumber})
                    </Typography>
                </DialogTitle>
                <Divider />

                <Grid container>
                    <Grid item xs={areConsolidatedClaimsNotEmpty() ? 9:12}>
                        <DialogContent>
                            <Loadable loading={getJsonPending}>
                                <Toolbar>
                                    <Tabs value={mode} onChange={ ( e, x ) => setMode( x ) }>
                                        <Tab label={`Claims (${ claimJson.json.claims?.length || 0 })`} value="claims" />
                                        <Tab label={`Payments (${ claimJson.json.payments?.length || 0 })`} value="payments" disabled={ !claimJson.json.payments?.length } />
                                        <Tab label="Tree View" value="tree" />
                                        <Tab label="Text View" value="text" />
                                    </Tabs>

                                    {mode === 'payments' || mode == "claims" ?
                                        <FormControlLabel
                                            className={classes.switch}
                                            control={
                                                <Switch
                                                    checked={hideEmptyColumns}
                                                    onChange={(_, x) => setHideEmptyColumns(x)}
                                                />
                                            }
                                            label="Remove Empty Columns"
                                        />  
                                    : null}                              
                                </Toolbar>

                                {claimJson && (
                                    <div className="vanilla-json-editor">

                                        <VanillaJSONEditor
                                            content={ mode === 'tree' || mode === 'text' ? claimJson : {
                                                json : (
                                                    hideEmptyColumns ?
                                                        removeEmptyColumns(claimJson.json[ mode ])
                                                    :
                                                        claimJson.json[ mode ] 
                                                ) || []
                                            }}
                                            readOnly={false}
                                            navigationBar={false}
                                            statusBar={false}
                                            mode={mode === 'payments' || mode == "claims" ? 'table' : mode}
                                            mainMenuBar={false}
                                            onChangeMode={newMode =>
                                                setMode(newMode)
                                            }
                                        />
                                    </div>
                                )}
                            </Loadable>
                        </DialogContent>
                    </Grid>

                 {
                 areConsolidatedClaimsNotEmpty() &&
                 
                  <Grid item xs={3}>
                        <div
                            className={classes.summaryView}
                        >
                            <BCard.Header
                                className={classes.summaryViewHeader}
                                as="h5"
                            >
                                Summary View{" "}
                                {currentSummaryId && `(${currentSummaryId})`}
                            </BCard.Header>
                            <Loadable
                                loading={
                                    getconsolidatedClaimsPending ||
                                    getJsonPending
                                }
                            >
                                <ul
                                    className={`${classes.summaryViewList} mb-0`}
                                >
                                    {consolidatedClaimsData?.map(item => (
                                        <li
                                            className={`${
                                                classes.summaryViewListItem
                                            } ${currentSummaryId === item &&
                                                classes.active}`}
                                            onClick={() =>
                                                handleConsolidatedClaims(item)
                                            }
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </Loadable>
                        </div>
                    </Grid>}
                </Grid>
            </Portlet>
        </>
    );
}

function removeEmptyColumns( arr ){
    return arr && arr.map(obj => {
        return Object.keys(obj).reduce((acc, key) => {
            // remove empty columns
            if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
                acc[key] = typeof obj[key] === 'string' ? (
                    // remove time from date strings
                    (obj[key].match(/^\d\d\d\d-\d\d-\d\d 00:00:00$/) ? obj[key].replace(/ 00:00:00$/, "") : // convert string to number if possible
                    obj[key].match(/^\d+$/) ?
                        Number(obj[key]) :
                    obj[key])
                ) :
                // process nested arrays recursively
                obj[key] instanceof Array ?
                    removeEmptyColumns(obj[key]) :
                obj[key];
            }
            return acc;
        }, {});
    });
}

export default ClaimJsonView