import React, { useEffect } from "react";
import { Formik } from "formik";
import { Loadable } from "../../../../common";

import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import { Button, TextField, Grid, FormControl, InputLabel, Select, Input, MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import { BackButton } from "../../../../common/BackButton";
import { ROLE_API } from "../../../../store/constants/roles";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%"
    },
    autoComplete: {
        margin: theme.spacing(1),
        width: "100%"
    }
}));

const DURATIONS = [30, 60, 90, 120];

export default function APIKeyEditPage(props) {
    const {
        intl,
        createPending,
        getUsersPending,
        initialValues,
        users,
        tinyList,
        actions: { getUsersByRole, createAPIKey, getTinyList }
    } = props;


    const classes = useStyles();

    useEffect(() => {
        getUsersByRole(ROLE_API);
    }, [getUsersByRole]);

    useEffect(() => {
        getTinyList();
    }, [getTinyList]);

    const handleCallback = () => {
        props.history.push("/admin/api-key-management");
    };

    return (
        (<Loadable loading={createPending || getUsersPending}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={values => {
                    const errors = {};

                    if (!values.userId) {
                        errors.userId = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    return errors;
                }}


                
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    let payload = {
                        userId: values.userId?.userId,
                        duration: values.duration,
                        applicationId:values?.applicationId
                    };
                     
                    createAPIKey(payload, handleCallback);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    dirty,
                    setFieldValue
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                        className={classes.container}
                    >
                        <Portlet>
                            <PortletHeader
                                title={"Add API key"}
                                toolbar={
                                    <PortletHeaderToolbar>
                                        <BackButton
                                            defaultRoute={`/admin/api-key-management`}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            type="submit"
                                            disabled={!dirty}
                                        >
                                            Add
                                        </Button>
                                    </PortletHeaderToolbar>
                                }
                            />
                            <PortletBody>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Autocomplete
                                                    className={
                                                        classes.autoComplete
                                                    }
                                                    value={values.userId}
                                                    disableClearable={false}
                                                    onChange={(e, value) =>
                                                        setFieldValue(
                                                            "userId",
                                                            value
                                                        )
                                                    }
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => option === value}
                                                    name={`userId`}
                                                    options={users}
                                                    getOptionLabel={option =>
                                                        option.username
                                                    }
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            name={`userId`}
                                                            label="User"
                                                            margin="normal"
                                                            helperText={
                                                                touched.userId &&
                                                                errors.userId
                                                            }
                                                            error={Boolean(
                                                                touched.userId &&
                                                                    errors.userId
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel htmlFor="duration">
                                                        Application Name
                                                    </InputLabel>
                                                    <Select
                                                        value={values.applicationId}
                                                        onChange={handleChange}
                                                        error={Boolean(
                                                            touched.applicationId &&
                                                                errors.applicationId
                                                        )}
                                                        input={
                                                            <Input id="applicationId" />
                                                        }
                                                        inputProps={{
                                                            name: "applicationId",
                                                            id: "applicationId"
                                                        }}
                                                    >
                                                        {tinyList.map(
                                                            (item, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={`key-${item?.name}-${index}`}
                                                                        value={
                                                                            item?.id
                                                                        }
                                                                    >
                                                                        {
                                                                            item?.name
                                                                        }
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    className={
                                                        classes.formControl
                                                    }
                                                >
                                                    <InputLabel htmlFor="duration">
                                                        Duration
                                                    </InputLabel>
                                                    <Select
                                                        value={values.duration}
                                                        onChange={handleChange}
                                                        error={Boolean(
                                                            touched.duration &&
                                                                errors.duration
                                                        )}
                                                        input={
                                                            <Input id="duration" />
                                                        }
                                                        inputProps={{
                                                            name: "duration",
                                                            id: "duration"
                                                        }}
                                                    >
                                                        {DURATIONS.map(
                                                            (item, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={`key-${item}-${index}`}
                                                                        value={
                                                                            item
                                                                        }
                                                                    >
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </PortletBody>
                        </Portlet>
                    </form>
                )}
            </Formik>
        </Loadable>)
    );
}
