import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { payerIdMappingLanding } from '../../store/actions/pages';
import { StringEquators } from '../../store/constants/pages';
import { createIconDataField, createStringDataField } from '../../util/format';
import PayerIdMappingPageComponent from './PayerIdMappingPage';
import { downloadPayerIdMapping, payerIdMappingCreateEditReset, setPayerIdMappingDependencyDialog, setPayerIdMappingEditDialog, uploadPayerIdMapping } from "../../store/actions/payers";
import { selectTinyContractsByState } from "../../store/selectors/contracts";
import { getTinyContractsByState } from "../../store/actions/contracts";
import { setV2 } from "../../store/actions/pagesV2";

export const PayerIdMappingPage = connect(
        () => {
            
            const selectFields = createSelector(
                selectTinyContractsByState,
                (tinyContracts) => [
                    createStringDataField('payerName', 'Payer Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
                    createStringDataField('payerId', 'Payer ID', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
                    createStringDataField('contract.name', 'Contract', { sortable: true, secondLevel: 'name', isDropdown: true, dropdownValues: tinyContracts.map(contract => contract.name), equators: StringEquators, whiteSpace: 'break-spaces' }),
                    createIconDataField('active', 'Active', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true })
                ]
            );
            
            const selectFilteredData = createSelector(
                (state) => state.pages['payer-id-mapping-landing'].list,
                (data) => {
                    return data
                }
            );

            const selectCheckboxStatus = createSelector(
                selectFilteredData,
                (payerIds: any) => {
                    const activePayers = payerIds?.filter(payer => payer.active).length;
                    if (payerIds.length === activePayers) {
                        return true;
                    }
                    return false;
                }
            );

            return (state, props) => ({
                data: selectFilteredData(state),
                fields: selectFields(state as never),
                mappingDownloadTemplatePending: state.payers.downloadPayerIdMapping.pending, 
                mappingUploadPending: state.payers.uploadPayerIdMapping.pending,
                checkboxStatus: selectCheckboxStatus(state)
            });
        },
        (dispatch) => ({
            actions: bindActionCreators({
                payerIdMappingLanding: payerIdMappingLanding,
                createEditReset: payerIdMappingCreateEditReset,
                setDependencyDialog: setPayerIdMappingDependencyDialog,
                setEditDialog: setPayerIdMappingEditDialog,
                getTinyContractsByState: getTinyContractsByState,
                mappingUpload: uploadPayerIdMapping,
                mappingDownloadTemplate: downloadPayerIdMapping,
                setSelectedItems: (value) => setV2('payer-id-mapping-landing', 'selected', value),
            }, dispatch)
        })
    )(injectIntl(PayerIdMappingPageComponent));