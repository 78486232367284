import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import PayerIdMappingEditPageComponent from './PayerIdMappingEditPage';
import { createPayerIdMapping, editBulkPayerIdMapping, editPayerIdMapping, getPayerContractsTiny, payerIdMappingCreateEditReset, setPayerIdMappingEditDialog } from "../../../store/actions/payers";
import { selectPayerContracts } from "../../../store/selectors/payers";
import { getTinyContractsByState } from "../../../store/actions/contracts";
import { selectTinyContractsByState } from "../../../store/selectors/contracts";
import { setV2 } from "../../../store/actions/pagesV2";

export const PayerIdMappingEditPage = injectIntl(
    connect(() => {

        const selectEditDialog = (state) => state.payers.editPayerIdMappingDialog;
        const selectSelectedPayerIds = (state, props) => state.pagesV2['payer-id-mapping-landing'].selected;

        const selectPayerIdMapping = createSelector(
            selectEditDialog,
            (editDialog) => {
                if (editDialog && editDialog?.payerIdMapping) {
                    return editDialog?.payerIdMapping
                }
                return null
            }
        )

        const selectOpen = createSelector(
            selectEditDialog,
            (editDialog) => {
                if (editDialog && editDialog?.open) {
                    return true
                }
                return false
            }
        )

        const selectBulk = createSelector(
            selectEditDialog,
            (editDialog) => {
                if (editDialog && editDialog?.bulk) {
                    return true
                }
                return false
            }
        )

        const selectMode = createSelector(
            selectPayerIdMapping,
            selectBulk,
            (mapping, isBulk) => {
                if(isBulk) {  return 'edit'; }
                return mapping ? 'edit' : 'create';
            }
        )

        const selectSortedTinyContracts = createSelector(
            selectTinyContractsByState,
            (contracts) => {
                return contracts.sort((a, b) => a.name.localeCompare(b.name))
            }
        );

        

        const selectInitialValues = createSelector(
            selectMode,
            selectPayerIdMapping,
            selectSortedTinyContracts,
            (mode, mapping, contracts) => {
                if (mode === 'edit' && mapping) {
                    return {
                        id: mapping.id,
                        payerName: mapping.payerName,
                        payerId: mapping.payerId,
                        contractId: contracts?.find(x=> x.id === mapping.contractId) ?? null,
                        active: mapping.active
                    }
                }
                return {
                    id: '',
                    payerName: '',
                    payerId: '',
                    contractId: null,
                    active: false
                }
            }
        )
        return (state, props) => ({
            open: selectOpen(state),
            initialValues: selectInitialValues(state),
            mode: selectMode(state),
            createPending: state.payers.createPayerIdMapping.pending,
            editPending: state.payers.updatePayerIdMapping.pending,
            tinyContracts: selectSortedTinyContracts(state as never),
            selectedPayerIds: selectSelectedPayerIds(state, props),
            isBulk: selectBulk(state)
        })
    },
        (dispatch) => ({
            actions: bindActionCreators({
                createPayerIdMapping: createPayerIdMapping,
                editPayerIdMapping: editPayerIdMapping,
                createEditReset: payerIdMappingCreateEditReset,
                setEditDialog: setPayerIdMappingEditDialog,
                editBulkPayerIdMapping: editBulkPayerIdMapping,
                setSelectedItems: (value) => setV2('payer-id-mapping-landing', 'selected', value)
            }, dispatch)
        })
    )(PayerIdMappingEditPageComponent));