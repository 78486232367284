import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_PUT
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getClaimStatusList = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/claimstatus/list`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getClaimStatus = (claimStatusId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configurations/claimstatus/${claimStatusId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createClaimStatus = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/claimstatus`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const editClaimStatus = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/claimstatus`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const setPrecedence = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/claimstatus/precedences`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options)
    }).then(apiHandleResponse);
};

const getClaimStatusDependency = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/claimstatus/dependencies/${id}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const deleteClaimStatus = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/configuration/claimstatus/delete`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options)
    }).then(apiHandleResponse);
};

export {
    getClaimStatusList,
    createClaimStatus,
    editClaimStatus,
    getClaimStatus,
    setPrecedence,
    getClaimStatusDependency,
    deleteClaimStatus
};