import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { ReleaseNotesItem } from '../ReleaseNotesItem';

const useStyles = makeStyles(theme => {
    return {
        releaseNotesItem: {
            marginBottom: '10px'
        },
        serviceIcon: {
            marginRight: '10px'
        },
        sectionDiv: {
            margin: '25px 5px'
        },
    }
});

export default function ReleaseNotesSection(props) {
    const { title, items, icon } = props;
    const classes = useStyles();

    return (
        <Grid className={classes.sectionDiv}>
            <Typography variant="h5" className="kt-font-info mb-3" gutterBottom>
                {icon && (<img className={classes.serviceIcon} alt={title} src={`${toAbsoluteUrl(icon)}`} />)}
                <b>{title}</b>
            </Typography>
            <ul>
                {items.map((note, index) => (
                    <ReleaseNotesItem key={note.id} id={note.id} title={note.title} description={note.description} />
                ))}
            </ul>
        </Grid>
    );
}

ReleaseNotesSection.prototype = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    icon: PropTypes.string
}