import { Button, FormGroup, Grid, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Loadable } from '../../../../common';
import { UserComments } from '../../../../common/userComments';
import { asIsoDate } from '../../../../util/date';

const useStyles = makeStyles(theme => ({

    switchLabel: {
        maxWidth: '200px',
        marginBottom: 0
    },

}));

function CommentsForm({ claimId, intl, acknowledgeClaimPending, claimAcknowledged, followUpFlag, claimFollowUpAlert, defaultFollowupDate,
    comments, getCommentsPending,comment, actions: { acknowledgeClaim, getClaimComments,setComment } 
}) {
    const classes = useStyles();

    const [acknowledged, setAcknowledged] = useState(false);
    const [flag, setFlag] = useState(false);
    const [followupAlert, setFollowupAlert] = useState('');

    useEffect(() => {
        setAcknowledged(claimAcknowledged)
    }, [claimAcknowledged]);

    useEffect(() => {
        setFlag(followUpFlag)
    }, [followUpFlag]);

    useEffect(() => {
        if (claimFollowUpAlert) {
            setFollowupAlert(asIsoDate(claimFollowUpAlert))
        }
    }, [claimFollowUpAlert]);

    const commentChangeHandler = (e) => {
        setComment(e.target.value)
    }
    const followupAlertChangeHandler = (e) => {
        setFollowupAlert(e.target.value);
    }
    const handleClearFollowupDate = () => {
        setFollowupAlert('');
    }
    const acknowledgedToggleHandler = (e) => {
        setAcknowledged(e.target.checked)
    }
    const flagToggleHandler = (e) => {
        setFlag(e.target.checked);
        if (e.target.checked && !followupAlert) {
            setFollowupAlert(defaultFollowupDate);
        }
    }

    const isFollowDateUnChanged = () => {
        const temp = asIsoDate(claimFollowUpAlert) ?? '';
        return temp === followupAlert
    }

    const handleCallback = () => {
        setComment('');
        getClaimComments(claimId);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let payload = {
            analysisId: [claimId],
            acknowledge: acknowledged,
            flag: flag
        } as {
            analysisId: any[];
            acknowledge: boolean;
            flag: boolean;
            comment: string;
            followupAlert?: string;
        }

        if (comment.length > 0) {
            payload = {
                ...payload,
                comment
            }
        }
        if (flag) {
            payload = {
                ...payload,
                followupAlert
            }
        }
        acknowledgeClaim(payload, handleCallback, true)
    }

    return (
        <Loadable loading={acknowledgeClaimPending || getCommentsPending}>
            <form onSubmit={handleFormSubmit}>
                <Grid item xs={12} md={12} >
                    <Grid container alignItems='center'>
                        <Grid item md={4}>
                            <h5 className='mb-0'>Comments</h5>
                        </Grid>
                        <Grid item md={8}>
                            <Grid container alignItems='center'>
                                <Grid item md={12}>
                                    <Grid container spacing={3} className='justify-content-end align-items-center'>
                                        <Grid item md={4}>
                                            {flag && <>
                                                <TextField
                                                    fullWidth
                                                    label="Followup Alert Date (Optional)"
                                                    name="followupAlert"
                                                    value={followupAlert}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        min: asIsoDate()
                                                    }}
                                                    type="date"
                                                    onChange={followupAlertChangeHandler}
                                                />
                                            </>}
                                        </Grid>
                                        {/* <Grid item>
                                            {flag &&
                                                <IconButton className='mt-4' onClick={handleClearFollowupDate}>
                                                    <ClearIcon />
                                                </IconButton>
                                            }
                                        </Grid> */}
                                        <Grid item>
                                            <FormGroup>
                                                <Typography component="div">
                                                    <Grid component="label" className={classes.switchLabel} container alignItems="center" spacing={0}>
                                                        <Grid item>
                                                            <Switch checked={acknowledged} onChange={acknowledgedToggleHandler} name="acknowledged" />
                                                        </Grid>
                                                        <Grid item>Acknowledge</Grid>
                                                    </Grid>
                                                </Typography>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item>
                                            <FormGroup >
                                                <Typography component="div">
                                                    <Grid component="label" className={classes.switchLabel} container alignItems="center" spacing={0}>
                                                        <Grid item>
                                                            <Switch checked={flag} onChange={flagToggleHandler} name="flag" />
                                                        </Grid>
                                                        <Grid item>Follow Up</Grid>
                                                    </Grid>
                                                </Typography>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid container className='pt-2 align-items-center'>
                        <Grid item xs={4} md={4} justifyContent="flex-end" container>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className='ml-2'
                                disabled={acknowledgeClaimPending ||
                                    (
                                        claimAcknowledged === acknowledged
                                        && comment.length === 0
                                        && flag === followUpFlag
                                        && isFollowDateUnChanged()
                                    )
                                }
                            >
                                {(acknowledgeClaimPending) ? 'Saving..' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid> */}
                </Grid>
                <hr />
                <Grid className='pt-2 mb-4' item xs={12} md={12}>
                    <TextField
                        required={claimAcknowledged !== acknowledged}
                        label="Enter Comment"
                        name="comment"
                        fullWidth
                        multiline
                        variant='outlined'
                        value={comment}
                        onChange={commentChangeHandler}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className='ml-2 btn-block'
                                        disabled={acknowledgeClaimPending ||
                                            (
                                                claimAcknowledged === acknowledged
                                                && comment.length === 0
                                                && flag === followUpFlag
                                                && isFollowDateUnChanged()
                                            )
                                        }
                                    >
                                        {(acknowledgeClaimPending) ? 'Saving..' : 'Save'}
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} >
                    <UserComments data={comments?.reverse()} />
                </Grid>
            </form>
        </Loadable>
    )
}

export default CommentsForm
