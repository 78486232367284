import { createReducer } from '../../util';
import * as actions from '../actions/claimStatus';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    claimStatus: null,
    editDialog: null, //{open:true,claimStatus:obj}
    dependencyDialog: null, //{open:true,claimStatus:obj}
    canDelete: null,
    recordsDependency:null,
    list: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    changePrecedence: {
        ...initialStatusState,
    },
    getDependencies: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
};

export default createReducer(initialState, {
    [actions.CLAIM_STATUS_LANDING_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
        data: []
    }),
    [actions.CLAIM_STATUS_LANDING_FULFILLED]: (state, npiRoles) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: npiRoles,
    }),
    [actions.CLAIM_STATUS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_STATUS_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.CLAIM_STATUS_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_STATUS_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_STATUS_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.CLAIM_STATUS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_STATUS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_STATUS_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.CLAIM_STATUS_GET_FULFILLED]: (state, claimStatus) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        claimStatus
    }),
    [actions.CLAIM_STATUS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_STATUS_CREATE_UPDATE_RESET]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
        create: {
            ...initialStatusState,
        },
    }),

    [actions.CLAIM_STATUS_SET_EDIT_DIALOG]: (state, { editDialog }) => ({
        ...state,
        editDialog
    }),

    [actions.CLAIM_STATUS_RESET]: (state) => ({
        ...state,
        data: []
    }),

    [actions.CLAIM_STATUS_CHANGE_PRECEDENCE_PENDING]: (state) => ({
        ...state,
        changePrecedence: {
            pending: true,
        },
    }),
    [actions.CLAIM_STATUS_CHANGE_PRECEDENCE_FULFILLED]: (state) => ({
        ...state,
        changePrecedence: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_STATUS_CHANGE_PRECEDENCE_REJECTED]: (state, errorMessage) => ({
        ...state,
        changePrecedence: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_STATUS_DELETE_DEPENDENCIES_PENDING]: (state) => ({
        ...state,
        getDependencies: {
            pending: true,
        },
    }),
    [actions.CLAIM_STATUS_DELETE_DEPENDENCIES_FULFILLED]: (state, canDelete) => ({
        ...state,
        getDependencies: {
            ...initialStatusState,
        },
        canDelete:canDelete?.canDelete,
        recordsDependency:canDelete?.records
    }),
    [actions.CLAIM_STATUS_DELETE_DEPENDENCIES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_STATUS_SET_DEPENDENCY_RESET]: (state) => ({
        ...state,
        canDelete: null
    }),

    [actions.CLAIM_STATUS_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.CLAIM_STATUS_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_STATUS_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_STATUS_SET_DEPENDENCY_DIALOG]: (state, { dependencyDialog }) => ({
        ...state,
        dependencyDialog
    }),
    
});
