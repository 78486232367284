import { Input as MuiInput, TextField as MuiTextField, styled } from "@mui/material";
import { NumberInput, PurePtr } from "@pure-ptr/react";
import React, { ComponentProps } from "react";

type InputProps = Omit<ComponentProps<typeof MuiInput>, 'placeholder'> & {
    valuePtr: PurePtr<string | number>
    placeholder?: string | number
}

type PtrComponentProps<C extends React.ComponentType, T = string> = {
    valuePtr : PurePtr<T>
} & Omit<ComponentProps<C>, 'value' | 'onChange' | 'error' >

export function Input({ valuePtr, placeholder, ...rest }: InputProps ) {
    return <MuiInput
        {...rest}
        value={String(valuePtr.value ?? '')}
        placeholder={placeholder != null ? String(placeholder) : undefined}
        onChange={({ target }) => valuePtr.set(
            rest.type === 'number'
                ? Number(target.value)
                : target.value
        )}
        error={Boolean(valuePtr.error)} />;
}

export const TextField = ({ valuePtr, ...rest } : PtrComponentProps<typeof MuiTextField>) =>
    <MuiTextField
        {...rest}
        value={String(valuePtr.value ?? '')}
        onChange={({ target }) => valuePtr.set( target.value )}
        error={ valuePtr.error } 
    />;

export const NumericInput = styled(NumberInput)({
    border: '1px solid #D4CCC1',
    borderRadius: '4px',
    color: '#4A3E32',
    padding: '1px 4px',
    margin: '0',
    width: '100%',
    textAlign: 'right',
    transition: 'border-color 0.3s, box-shadow 0.3s, background-color 0.3s',

    '&:focus': {
        borderColor: '#C8B090', /* Base Sepia (Soft) */
        backgroundColor: '#FAF9F7', /* Soft Peach */
        boxShadow: '0 0 4px 0 rgba(200, 176, 144, 0.5)', /* Subtle glow */
        outline: 'none'
    },

    '&.invalid' : {
        borderColor: 'red',
        outlineColor: 'red'
    }
})