import React, { useState, useEffect, useCallback } from 'react';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import {
    Grid,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    FormHelperText,
    Tooltip,
    IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PagedTable } from '../../../common/pagedTable';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { EnableDialog } from './EnableDialog.js';
import { ExportButton } from './ExportButton/index.js';

const DEFAULT_SORT = [
    // { id: 'cpt', direction: 'asc' }
];

const useStyles = makeStyles(theme => ({
    // root: {
    //     display: 'flex',
    //     overflowX: 'auto',
    //     flexDirection: 'column',
    // },
    container: {
        flexGrow: 1,
    },
    portlet: {
        display: 'flex',
        flexGrow: 1,
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    loader: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    formControl: {
        paddingRight: theme.spacing(1),
        width: '100%',
    },
    icon: {
        fontSize: 17,
        marginTop: 1,
    }
}));

export default function ShoppablePage(props) {
    const { updateStatusPending,
        shoppableData, shoppableFields, checkboxStatus, counts,
        facilities, subDomain, customFilter,
        shoppablesExportPending,
        actions: {
            getCounts,
            updateStatus,
            shoppableLanding,
            pricersGetFacilities,
            searchGetByName,
            searchReset,
            setCustomFilter,
            resetPagedTable,
            setEnableDialog
        }
    } = props;
    const classes = useStyles();

    const [refresh, setRefresh] = useState(Promise.resolve());
    const [facility, setFacility] = useState('');
    const [facilityError, setFacilityError] = useState(true);

    useEffect(() => {
        if (facility) {
            getCounts(facility);
        }
    }, [facility, getCounts]);

    useEffect(() => {
        if (facility) {
            setFacilityError(false);
        } else {
            setFacilityError(true);
        }
    }, [facility]);

    useEffect(() => {
        pricersGetFacilities(subDomain);
    }, [subDomain, pricersGetFacilities]);

    useEffect(() => {
        const pageName = {
            name: 'shoppable-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name)
        }
    }, [searchGetByName]);

    useEffect(() => {
        customFilter.forEach(filter => {
            if (filter.field === 'facilityId' && facility !== filter.value) {
                setFacility(filter.value);
            }
        });
    }, [customFilter]);

    function handleLoad(sort, filter, pagination) {
        if (filter !== null) {
            shoppableLanding(pagination, sort, filter);
        }
    }

    const handleFacilityChange = useCallback((name) => {
        setFacility(name);
        const cf = {
            field: 'facilityId',
            equator: 'equals',
            value: name,
        }
        if (customFilter && customFilter.length > 0) {
            const existingFilter = customFilter.filter(x => x.field !== 'facilityId');
            setCustomFilter([
                ...existingFilter,
                cf,
            ]);
        } else {
            setCustomFilter([cf]);
        }
        handleRefresh();
    }, [setFacility]);

    useEffect(() => {
        if (facilities.length === 1) {
            handleFacilityChange(facilities[0].id);
        }
    }, [facilities, handleFacilityChange]);

    function handleRefresh() {
        setRefresh(Promise.resolve());
        setEnableDialog(null);
    }
    function handleEnableCallback() {
        handleRefresh();
        getCounts(facility);
    }

    function handlePayerConfirmation(isEnable) {
        setEnableDialog({
            open: true,
            isEnableClicked: isEnable
        });
    }

    function tableLevelActions() {
        return (
            <>
                <Grid item>
                    <ExportButton shoppableDataLength={shoppableData.length} fields={shoppableFields} />
                </Grid>

                <Grid item>
                    <Tooltip title="Enable">
                        <IconButton aria-label="enable" size='small' onClick={() => handlePayerConfirmation(true)}>
                            <CheckCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Disable">
                        <IconButton aria-label="disable" size='small' onClick={() => handlePayerConfirmation(false)}>
                            <i className={classNames("fa fa-solid fa-ban", classes.icon)}></i>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    function actionLevelGrid() {
        return <Grid container>
            <Grid item xl={2} lg={3} md={4} sm={4}>
                <h6 className="">{`Required : ${counts.requiredServicesCount ? counts.requiredServicesCount : 0}/70`}</h6>
            </Grid>
            <Grid item xl={2} lg={3} md={4} sm={4}>
                <h6 className="">{`Shoppable : ${counts.shoppableServicesCount ? counts.shoppableServicesCount : 0}/230`}</h6>
            </Grid>
            <Grid item xl={2} lg={3} md={4} sm={4}>
                <h6 className="">{`Total : ${counts.totalServiceCount ? counts.totalServiceCount : 0}/300`}</h6>
            </Grid>
        </Grid>
    }

    return (<>
        <Helmet title="Shoppable" />
        <Portlet>
            <PortletHeader
                title={'Shoppable'}
                showSearchFilters={true}
                showSearchFiltersDropdown={false}
                name="shoppable-landing"
                handleRefresh={handleRefresh}
                includeAdvanceFilters={true}
                toolbar={
                    <PortletHeaderToolbar className={classes.portlet}>
                        <Grid container spacing={1} justifyContent="flex-end">
                            <Grid item xs={8} md={4}>
                                <FormControl className={classes.formControl} error={facilityError}>
                                    <InputLabel className={classes.formLabel} htmlFor="facility">Select Facility</InputLabel>
                                    <Select
                                        value={facility}
                                        onChange={(event) => handleFacilityChange(event.target.value)}
                                        inputProps={{
                                            name: "facility",
                                            id: "facility"
                                        }}
                                    >
                                        {facilities.map(({ id, name }) => (
                                            <MenuItem
                                                key={name}
                                                value={id}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {facilityError && (
                                        <FormHelperText error>Please select a facility</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </PortletHeaderToolbar>
                }
            />
            <PortletBody>
                <Grid container>
                    <Grid item md={12}>
                        <PagedTable
                            name="shoppable-landing"
                            fields={shoppableFields}
                            data={shoppableData}
                            onLoad={handleLoad}
                            refresh={refresh}
                            defaultSort={DEFAULT_SORT}
                            tableLevelActions={tableLevelActions}
                            actionLevelGrid={actionLevelGrid}
                            hasCheckbox={true}
                            showSelectAll={true}
                            loading={updateStatusPending}
                            // tableLevelLoading={shoppablesExportPending}
                            criteriasSaveEnabled={true}
                            showSearchFilters={true}
                        />
                    </Grid>
                </Grid>
                <Grid container className="mt-5">
                    <Grid item md={2}>
                        <h6 className="">{`Required : ${counts.requiredServicesCount ? counts.requiredServicesCount : 0}/70`}</h6>
                    </Grid>
                    <Grid item md={2}>
                        <h6 className="">{`Shoppable : ${counts.shoppableServicesCount ? counts.shoppableServicesCount : 0}/230`}</h6>
                    </Grid>
                    <Grid item md={2}>
                        <h6 className="">{`Total : ${counts.totalServiceCount ? counts.totalServiceCount : 0}/300`}</h6>
                    </Grid>
                </Grid>
            </PortletBody>
        </Portlet>
        <EnableDialog handleEnableCallback={handleEnableCallback} />
    </>);
}