import React, { useState } from 'react';
import classnames from 'classnames';
import {
    Button,
} from '@mui/material';

export default function DialogLink({ Dialog, title, theme = 'primary' }:{
    Dialog: any,
    title: string,
    theme?: React.ComponentProps<typeof Button>['color']
}) {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }
    return (
        <>
            <Button
                className={classnames("kt-footer__menu-link")}
                color={theme}
                onClick={handleOpen}
            >
                {title}
            </Button>

            <Dialog handleClose={handleClose} open={open} />
        </>
    );
};