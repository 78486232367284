import { Formik } from "formik";
import React, { useEffect } from "react";
import { Loadable } from "../../../../common";

import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import { Button, Grid, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { BackButton } from "../../../../common/BackButton";
import { ROLE_API } from "../../../../store/constants/roles";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%"
    },
    autoComplete: {
        margin: theme.spacing(1),
        width: "100%"
    }
}));

const DURATIONS = [30, 60, 90, 120];

export default function AppIdEditPage(props) {
    const {
        intl,
        createPending,
        getUsersPending,
        initialValues,
        users,
        tinyList,
        actions: { getUsersByRole, createAppId }
    } = props;


    const classes = useStyles();

    useEffect(() => {
        getUsersByRole(ROLE_API);
    }, [getUsersByRole]);


    const handleCallback = () => {
        props.history.push("/admin/app-id-management");
    };

    return (
        <Loadable loading={createPending || getUsersPending}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={values => {
                    const errors = {};


                    if (!values.name) {

                        errors.name = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                   

                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    let payload = {
                       ...values
                    };
                    createAppId(payload, handleCallback);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    dirty,
                    setFieldValue
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                        className={classes.container}
                    >
                        <Portlet>
                            <PortletHeader
                                title={"Add App Id"}
                                toolbar={
                                    <PortletHeaderToolbar>
                                        <BackButton
                                            defaultRoute={`/admin/app-id-management`}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            type="submit"
                                            disabled={!dirty}
                                        >
                                            Add
                                        </Button>
                                    </PortletHeaderToolbar>
                                }
                            />
                            <PortletBody>
                                <Grid container>
                                    <Grid item xs={10}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            label="Application Name"
                                            name="name"
                                            className={classes.textField}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            helperText={touched.name && errors.name}
                                            error={Boolean(touched.name && errors.name)}
                                        />
                                        </Grid>
                                        <Grid item xs={6}>
                                        <TextField
                                            
                                            label="Description"
                                            name="description"
                                            className={classes.textField}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.description}
                                            helperText={touched.description && errors.description}
                                            error={Boolean(touched.description && errors.description)}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </PortletBody>
                        </Portlet>
                    </form>
                )}
            </Formik>
        </Loadable>
    );
}
