import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { getExtension, getPhone } from '../../../../util/phone';
import UsersEditPageComponent from './UsersEditPage';

import { listTinyByClientId, tinyReset } from '../../../../store/actions/dashboards';
import { list } from '../../../../store/actions/roles';
import { activateUser, create, deactivateUser, errorReset, get, update } from '../../../../store/actions/users';

import { getProductsForUserEdit, resetProductsForUserEdit } from '../../../../store/actions/clients';
import { getPartnersClientsProducts, getPartnersTiny, resetPartnerProducts } from '../../../../store/actions/partners';
import { selectClientListTiny, selectClientProductsForUserEdit } from "../../../../store/selectors/clients";
import { selectDashboardTinyByClient } from "../../../../store/selectors/dashboards";
import { selectPartnersProducts, selectPartnersTiny } from '../../../../store/selectors/partners';
import { selectUserRolesBasedOnRole } from '../../../../store/selectors/roles';
import { selectIsAdmin, selectIsPartner, selectIsSuperAdmin, selectSessionUserClientId, selectSessionUserPartnerId } from "../../../../store/selectors/session";
import { createUserSelector } from "../../../../store/selectors/users";

export const UsersEditPage = connect(
    () => {
        const selectUserId = (state, props) => props.match.params.userId;

        const selectMode = (state, props) => props.match.params.userId === 'new' ? 'create' : 'edit';

        const selectUser = createUserSelector(selectUserId);

        // Remove System as Partners can't be assigned to SYSTEM.
        const selectPartners = createSelector(
            selectPartnersTiny,
            (partners) => {
                return partners?.filter(x => x.name !== 'SYSTEM');
            }
        );

        const selectInitialValues = createSelector(
            selectMode,
            selectUser,
            selectClientListTiny,
            selectPartners,
            selectIsAdmin,
            (mode, user, clients, partners, isAdmin) => {
                if (mode === 'edit' && user) {
                    let clientId = clients.find(client => client.clientId === user.clientId) ?? null;
                    if (isAdmin) {
                        clientId = user.clientId
                    }
                    return {
                        partnerId: partners?.find(partner => partner.id === user.partnerId) ?? null,
                        id: user.id,
                        username: user.username,
                        role: user.role,
                        name: user.name,
                        email: user.email,
                        title: user.title,
                        clientId: clientId,
                        clientName: user.clientName,
                        alert: user.alert,
                        phone: getPhone(user.phone || ''),
                        ext: getExtension(user.phone || ''),
                        notes: user.notes || '',
                        hasPricerAccess: user.hasPricerAccess,
                        hasCMSAccess: user.hasCMSAccess,
                        forceResetPW: user.forceResetPW,
                        sendWelcomeEmail: user.sendWelcomeEmail,
                        passwordRotationDays: user.passwordRotationDays,
                        hasCMSClaimAnalysisAccess: user.hasCMSClaimAnalysisAccess,
                        hasClaimProcessorAccess: user.hasClaimProcessorAccess,
                        hasEstimatorAccess: user.hasEstimatorAccess,
                        hasVendorManagementAccess: user.hasVendorManagementAccess,
                        hasDashboardAccess: user.hasDashboardAccess
                    }
                }

                return {
                    partnerId: null,
                    username: '',
                    password: '',
                    confirmPassword: '',
                    role: 'user',
                    name: '',
                    email: '',
                    title: '',
                    phone: '',
                    ext: '',
                    notes: '',
                    alert: false,
                    clientId: null,
                    clientName: '',
                    hasPricerAccess: false,
                    hasCMSAccess: false,
                    forceResetPW: true,
                    sendWelcomeEmail: false,
                    passwordRotationDays: 90,
                    hasCMSClaimAnalysisAccess: false,
                    hasClaimProcessorAccess: false,
                    hasEstimatorAccess: false,
                    hasVendorManagementAccess: false,
                    hasDashboardAccess: false
                }
            }
        );

        const selectUserStatus = createSelector(selectUser, (user) => user?.userActive ?? false);

        const selectProducts = createSelector(selectClientProductsForUserEdit, selectPartnersProducts,
            (clientProducts, partnersProducts) => {
                return {
                    hasDashboardAccess: clientProducts?.hasDashboardAccess || partnersProducts?.hasDashboardAccess,
                    hasCMSAccess: clientProducts?.hasCMSAccess || partnersProducts?.hasCMSAccess,
                    hasEstimatorAccess: clientProducts?.hasEstimatorAccess || partnersProducts?.hasEstimatorAccess,
                    hasPricerAccess: clientProducts?.hasPricerAccess || partnersProducts?.hasPricerAccess,
                    hasVendorManagementAccess: clientProducts?.hasVendorManagementAccess || partnersProducts?.hasVendorManagementAccess,
                }
            }
        );

        return (state, props) => ({

            // Loaders
            usersCreatePending: state.users.create.pending,
            usersUpdatePending: state.users.update.pending,
            clientsGetPending: state.users.get.pending,
            dashboardListTinyPending: state.dashboards.tiny.pending,
            usersActivatePending: state.users.deactivate.pending,
            usersDeactivatePending: state.users.activate.pending,

            roles: selectUserRolesBasedOnRole(state, props),
            clients: selectClientListTiny(state),
            isSuperAdmin: selectIsSuperAdmin(state),
            isAdmin: selectIsAdmin(state),
            isPartner: selectIsPartner(state),
            partners: selectPartners(state),
            sessionUserClientId: selectSessionUserClientId(state),
            sessionUserPartnerId: selectSessionUserPartnerId(state),
            dashboardsTiny: selectDashboardTinyByClient(state),

            // edit
            user: selectUser(state, props),
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            userId: selectUserId(state, props),
            userActive: selectUserStatus(state, props),
            clientProducts: selectClientProductsForUserEdit(state),
            partnerProducts: selectPartnersProducts(state),
            getClientProductsPending: state.clients.getProductsForUserEdit.pending,
            getPartnersProductsPending: state.partners.getProducts.pending,
            getPartnerPending: state.partners.getTinyPartners.pending
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            userCreate: create,
            userUpdate: update,
            userGet: get,
            rolesList: list,
            dashboardListTiny: listTinyByClientId,
            dashboardListTinyReset: tinyReset,
            deactivateUser: deactivateUser,
            activateUser: activateUser,
            errorReset: errorReset,
            getProductsForUserEdit: getProductsForUserEdit,
            resetProductsForUserEdit: resetProductsForUserEdit,
            getPartnersTiny: getPartnersTiny,
            getPartnersClientsProducts: getPartnersClientsProducts,
            resetPartnerProducts: resetPartnerProducts
        }, dispatch)
    })
)(injectIntl(UsersEditPageComponent));