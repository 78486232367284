import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";

export default function ConfirmationDialog(props) {
    const { open, handleClose, handleSubmit } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog-title"
            fullWidth
        >
            <DialogTitle id="confirm-dialog-title">Are you Sure?</DialogTitle>
            <DialogContent>
                <p>
                    Are you sure you want to clear this client's configurations?
                </p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    color="secondary"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
