import { Button, Link } from '@mui/material'
import React from 'react'
import { useLocation, withRouter } from 'react-router-dom';
import { getLastFiveBreadCrumbLocations,getLastFiveBreadCrumbLocationsLocalStorage,getBackPath } from '../../router/RouterHelpers';

function BackButton(props) {
    const currentLocation = useLocation().pathname;
    

    return (
        (<Button
            onClick={()=>getBackPath(currentLocation,props)}
            variant="contained"
            className=''
            type='button'
            color="inherit">
            {props.text ? props.text : 'Back'}
        </Button>)
    );
}

export default withRouter(BackButton);
