import { Grid, Box, Popover, TableCell, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const useStyles = makeStyles(theme => ({
    ellipsis: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: props => props.width ? props.width : 250,
        cursor: 'pointer'
    },
    popover: {
        fontWeight: 500
    },
    closeButton: {
        color: theme.palette.grey[500],
        padding: 6,
        cursor:'pointer'
    },
    grid: {
        borderBottom: '1px solid #94c83d',
        padding: theme.spacing(1),
        
    },
    justifyEnd:{
        justifyContent: 'flex-end'
    },
    justifysSbetween:{
        justifyContent: 'space-between'
    },
    horizontalScroll:{
        overflowX:'auto'
    },
    popoverTitle:{
        display:'grid',
        alignItems:'center',
        fontWeight:500
    }
}))

function ClickPopover({ columnData,customPopoverTitle }) {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'rowcolumn-clickable-popover' : undefined;

    return (
        <>
            <TableCell className={classes.ellipsis} onClick={handleClick}>
                {columnData}
            </TableCell>
            {columnData && columnData.length > 0 &&
                <Popover
                    className={classes.popover}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: { maxWidth: '300px' },
                    }}
                >
                    <Grid container className={`${customPopoverTitle ? classes.justifysSbetween:classes.justifyEnd} ${classes.grid}`}>
                        {customPopoverTitle && <Typography className={classes.popoverTitle}>{customPopoverTitle}</Typography>}
                        <Box aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </Box>
                    </Grid>
                    <Typography className={`${classes.horizontalScroll} py-3 px-4`}>
                        {columnData}
                    </Typography>
                </Popover>
            }
        </>
    )
}

export default ClickPopover