import { Grid, TablePagination } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { DataFilter, DataTable, TablePaginationActions } from '../../../common';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../partials/content/Portlet';

const useStyles = makeStyles(theme => ({
    textField: {
        width: 400,
    },
}));

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];

function QSDataSetPage(props) {
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);

    const classes = useStyles();

    const {
        fields, dataSetList, sort, order, getDataSetPending, filter,
        actions: {
            getQuickSightsDataSetList, setFilter,
            setSort, setOrder, quickSightsDataSetListReset
        }
    } = props;

    useEffect(() => {
        getQuickSightsDataSetList();
        return () => {
            quickSightsDataSetListReset();
        }
    }, [getQuickSightsDataSetList, quickSightsDataSetListReset]);

    const handleRefresh = () => {
        getQuickSightsDataSetList();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    function actionLevelGrid() {
        return <Grid container>
            <Grid item md={12}>
                <h6 className="">The data retrieval takes a couple of seconds to a few minutes. Please do not refresh the page while loading</h6>
            </Grid>
        </Grid>
    }

    return (<>
        <Helmet title="QS Datasets" />
        <Portlet>
            <PortletHeader
                title="QS Datasets"
                name="s3-listners-landing"
                toolbar={
                    <PortletHeaderToolbar>
                        <DataFilter
                            onChange={handleSearchInput}
                            placeholder="Search"
                            cssClass={classes.textField}
                            filter={filter}
                        />
                    </PortletHeaderToolbar>
                }
            />
            <PortletBody>
                <Grid container>
                    <Grid item xs={12}>
                        <DataTable
                            name="s3-listners-landing"
                            fields={fields}
                            data={dataSetList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                            handleRefresh={handleRefresh}
                            defaultSort={DEFAULT_SORT}
                            loading={getDataSetPending}
                            title="modifiers"
                            order={order}
                            onOrder={setOrder}
                            sort={sort}
                            onSort={setSort}
                            stickyHeader={true}
                            actionLevelGrid={getDataSetPending ? actionLevelGrid : undefined}
                            showPagination={true}
                            footerPagination={() => (
                                <TablePagination
                                    rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                    colSpan={fields.length + 2}
                                    count={dataSetList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </PortletBody>
        </Portlet>
    </>);
}

export default QSDataSetPage