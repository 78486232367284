import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { IconButton, Tooltip, TablePagination } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { DataTable, DataFilter, TablePaginationActions } from '../../../common';
import Helmet from 'react-helmet';
import { Edit as EditIcon } from '@mui/icons-material';
import { DependencyDialog } from "./DependencyDialog";
import { Delete as DeleteIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  textField: {
    width: 400,
  },
  IconButton: { padding: 6 }
}));

export default function PartnersPage(props) {
  const classes = useStyles();
  const {
    partnersFilteredData, fields, sort, partnerListPending, order, filter,
    partnerActivatePending, partnerDeactivatePending,
    actions: { setSort, setOrder, partnetsList, setFilter, activatePartner, deactivatePartner, setDependencyDialog }
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  useEffect(() => {
    partnetsList();
    return () => {
      setFilter('')
    }
  }, [partnetsList]);

  const handlePartnerEdit = (partner) => {
    props.history.push(`/admin/partners/edit/${partner.id}`);
  }

  const handleRefresh = () => {
    partnetsList();
  }

  const handleSearchInput = (value) => {
    setFilter(value);
  }

  const handleAddPartner = () => {
    props.history.push('/admin/partners/edit/new');
  }

  const handleChangeStatus = ({ id, active }) => {
    if (active) {
      deactivatePartner(id, handleRefresh);
    } else {
      activatePartner(id, handleRefresh);
    }
  }

  const handleDependencyDialog = (rowData) => {
    setDependencyDialog({ partnerId: rowData.id, open: true, name: rowData.name })
  }


  function codeServicesAction(rowData) {
    return (
      <div className='d-flex'>

        <IconButton
          color="primary"
          className='p-1'
          aria-label="Edit"
          onClick={() => handlePartnerEdit(rowData)}
          size="large">
          <Tooltip title="Edit">
            <EditIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          onClick={() => handleDependencyDialog(rowData)}
          color={"primary"}
          className='p-1'
          aria-label="Delete"
          size="large">
          <Tooltip title={"Delete Partner"} >
            <DeleteIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          color="primary"
          className='p-1'
          aria-label="Edit"
          onClick={() => handleChangeStatus(rowData)}
          size="large">
          {rowData.active ?
            <Tooltip title="Deactivate">
              <i className="fas fa-check-circle text-success"></i>
            </Tooltip>
            :
            <Tooltip title="Activate">
              <i className="fas fa-check-circle text-secondary"></i>
            </Tooltip>
          }
        </IconButton>
      </div >
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (<>
    <Helmet title="Partners Page" />
    <Portlet>
      <PortletHeader
        title="Partners"
        toolbar={
          <PortletHeaderToolbar>
            <DataFilter
              onChange={handleSearchInput}
              placeholder="Search"
              cssClass={classes.textField}
              filter={filter}
            />
          </PortletHeaderToolbar>
        }
      />
      <PortletBody>
        <DataTable
          fields={fields}
          data={partnersFilteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          order={order}
          onOrder={setOrder}
          sort={sort}
          onSort={setSort}
          loading={partnerListPending || partnerActivatePending || partnerDeactivatePending}
          onAdd={handleAddPartner}
          name='Partner'
          handleRefresh={handleRefresh}
          showPagination={true}
          footerPagination={() => (
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
              colSpan={fields.length + 2}
              count={partnersFilteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          )}

          hasActions={true}
          renderActionColumn={codeServicesAction}
        />
      </PortletBody>
    </Portlet>
    <DependencyDialog handleRefresh={handleRefresh} />
  </>);
}
