import React from 'react';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => {
    return {
        label: {
            fontSize: '1rem'
        },
        content: {
            wordBreak: 'break-word',
            textDecoration: "underline",
            color: "#5867dd",
            cursor: "pointer"
        }
    }
});

export default function CustomClickableLabel(props) {
    const { label, data, ruleId, onHandleCarveoutClick } = props
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.label} variant="subtitle2" display="inline" >{`${label}: `}</Typography>
            <Typography onClick={() => onHandleCarveoutClick(data, label, ruleId)} className={classes.content} variant="subtitle1" display="inline">{data}</Typography>
        </>
    );
}
