import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';

import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../partials/content/Portlet";
import { PagedTable } from '../../common';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { DependencyDialog } from './DependencyDialog';

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];

const useStyles = makeStyles((theme) => ({
}));

export default function PpsPage({ fields, data, history,
    actions: {
        drgLanding, setDependencyDialog,
        createEditReset
    }
}) {
    const [refresh, setRefresh] = useState(Promise.resolve());

    const classes = useStyles();

    function handleLoad(sort, filter, pagination) {
        drgLanding(pagination, sort, filter);
    }

    useEffect(() => {
        return () => {
            createEditReset();
        }
    }, [createEditReset]);

    const handleRefresh = () => {
        setRefresh(Promise.resolve());
    }

    const handleAddPps = () => {
        history.push('/cms/ipps/edit/new');
    }

    const handleEditPps = (rowData) => {
        history.push(`/cms/ipps/edit/${rowData.id}`);
    }

    const handleDeletePps = (rowData) => {
        setDependencyDialog(
            { ippsId: rowData.id, name: rowData.name, open: true }
        );
    }

    function ppsActions(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleEditPps(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
                <IconButton
                    onClick={() => handleDeletePps(rowData)}
                    color={"primary"}
                    className='p-1'
                    aria-label="Delete"
                    size="large">
                    <Tooltip title={"Delete IPPS"} >
                        <DeleteIcon />
                    </Tooltip>
                </IconButton>
            </div>
        );
    }

    return (
        <>
            <Helmet title="IPPS Configuration" />
            <Portlet>
                <PortletHeader
                    title="IPPS Configuration"
                    handleRefresh={handleRefresh}
                    name="drg-landing"
                    showSearchFilters={false}
                />
                <PortletBody>
                    <div className="row">
                        <div className="col-md-12">
                            <PagedTable
                                name="drg-landing"
                                title="drg"
                                fields={fields}
                                data={data}
                                onLoad={handleLoad}
                                onAdd={handleAddPps}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                criteriasSaveEnabled={false}

                                hasActions={true}
                                renderActionColumn={ppsActions}
                                showSearchFilters={false}
                            />
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
            <DependencyDialog handleRefresh={handleRefresh} />
        </>
    );
}
