import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';

import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../partials/content/Portlet";
import { PagedTable } from '../../common';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteConfirmation } from '../../common/DeleteConfirmation';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { DependencyDialog } from './DependencyDialog';

const DEFAULT_SORT = [];

const useStyles = makeStyles((theme) => ({
}));

export default function OPPSPage(props) {
    const { fields, data, history,
        actions: {
            oppsLanding, setDependencyDialog,
            createEditReset
        }
    } = props;

    const [refresh, setRefresh] = useState(Promise.resolve());

    const classes = useStyles();

    function handleLoad(sort, filter, pagination) {
        oppsLanding(pagination, sort, filter);
    }

    useEffect(() => {
        return () => {
            createEditReset();
        }
    }, [createEditReset]);

    const handleRefresh = () => {
        setRefresh(Promise.resolve());
    }

    const handleAddOPPS = () => {
        history.push('/cms/opps/edit/new');
    }

    const handleEditOPPS = (rowData) => {
        history.push(`/cms/opps/edit/${rowData.id}`);
    }

    const handleDeleteOPPS = (rowData) => {
        setDependencyDialog(
            {
                oppsId: rowData.id,
                open: true,
                name: rowData.name
            }
        )
    }

    function oppsActions(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleEditOPPS(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
                <IconButton
                    onClick={() => handleDeleteOPPS(rowData)}
                    color={"primary"}
                    className='p-1'
                    aria-label="Delete"
                    size="large">
                    <Tooltip title={"Delete OPPS"} >
                        <DeleteIcon />
                    </Tooltip>
                </IconButton>
            </div>
        );
    }

    return (
        <>
            <Helmet title="OPPS Configuration" />
            <Portlet>
                <PortletHeader
                    title="OPPS Configuration"
                    handleRefresh={handleRefresh}
                    name="opps-landing"
                    showSearchFilters={false}
                />
                <PortletBody>
                    <div className="row">
                        <div className="col-md-12">
                            <PagedTable
                                name="opps-landing"
                                title="opps"
                                fields={fields}
                                data={data}
                                onLoad={handleLoad}
                                onAdd={handleAddOPPS}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                criteriasSaveEnabled={false}

                                hasActions={true}
                                renderActionColumn={oppsActions}
                                showSearchFilters={false}
                            />
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
            <DependencyDialog handleRefresh={handleRefresh} />
        </>
    );
}
