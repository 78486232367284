import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { DataTable } from '../../../../common';
import CloseIcon from '@mui/icons-material/Close';
import { ExecutionErrorDialog } from '../../ExecutionErrorDialog';

function ProcessedClaimsDialog({ openPtr, intl, fields, filteredData, order, sort, actions: { setOrder, setSort } }) {
    const handleClose = () => {
        openPtr.set(false)
    }

    function renderErrorMessage(rowData) {
        return <div className='d-flex'>
            {rowData?.error ? <ExecutionErrorDialog executionError={rowData?.error} /> : <span>N/A</span>}
        </div>
    }

    return (
        (<Dialog maxWidth='lg' open={openPtr.value} onClose={handleClose}>
            <DialogTitle>
                <Grid container alignItems='center'>
                    <Grid item xs={10}>
                        <Typography variant="h6">
                            Processed Claims
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className='text-right'>
                        <IconButton aria-label="close" onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    <DataTable
                        fields={fields}
                        data={filteredData}
                        order={order}
                        onOrder={setOrder}
                        sort={sort}
                        onSort={setSort}
                        hasActions={true}
                        customActionColumnName='Message'
                        renderActionColumn={renderErrorMessage}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions className='p-3'>
                <Button onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>)
    );
}

export default ProcessedClaimsDialog
