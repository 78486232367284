import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { Loadable } from '../../../common';
import CloseIcon from '@mui/icons-material/Close';
import { DropzoneArea } from 'material-ui-dropzone'
import './dropzone.css'
import { Formik } from 'formik';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dropZone: {
        minHeight: 150,
        border: 'none',
        backgroundColor: '#EAEAEA',
        borderRadius: '20px'
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: '100%',
    },
}));

function UploadDialog(props) {
    const { intl, open, uploadFileCallback, handleClose, uploadFilePending, initialValues, actions: { uploadFiles } } = props;
    const classes = useStyles();

    const handleFileChangeHandler = (setFieldValue) => (_files) => {
        setFieldValue('files', _files)
    }

    return (
        (<Dialog
                maxWidth="md"
                fullWidth open={open}
                // onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">Upload Files</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={uploadFilePending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};

                            if (!values.fileType) {
                                errors.fileType = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.environment) {
                                errors.environment = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (values.files && values.files.length === 0) {
                                errors.files = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            const formData = new FormData()
                            for (const file of values.files) {
                                formData.append('Files', file);
                            }
                            formData.append('Environment', values.environment);
                            formData.append('FileType', values.fileType);
                            uploadFiles(formData, uploadFileCallback);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                <Grid container>
                                    <Grid item xs={6} className='pr-4'>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="fileType">Select File Type</InputLabel>
                                            <Select
                                                value={values.fileType}
                                                onChange={handleChange}
                                                error={Boolean(touched.fileType && errors.fileType)}
                                                input={<Input id="fileType" />}
                                                inputProps={{
                                                    name: "fileType",
                                                    id: "fileType"
                                                }}
                                            >
                                                <MenuItem
                                                    value='CHARGE'
                                                >
                                                    Charge
                                                </MenuItem>
                                                <MenuItem
                                                    value='PAYMENT'
                                                >
                                                    Payment
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} className='pl-4'>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="environment">Select Environment</InputLabel>
                                            <Select
                                                value={values.environment}
                                                onChange={handleChange}
                                                error={Boolean(touched.environment && errors.environment)}
                                                input={<Input id="environment" />}
                                                inputProps={{
                                                    name: "environment",
                                                    id: "environment"
                                                }}
                                            >
                                                <MenuItem
                                                    value='SANDBOX'
                                                >
                                                    Sandbox
                                                </MenuItem>
                                                <MenuItem
                                                    value='PRODUCTION'
                                                >
                                                    Production
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} className="mb-2 pt-4 dropzone-grid">
                                        <DropzoneArea
                                            dropzoneClass={classes.dropZone}
                                            dropzoneParagraphClass='mb-3'
                                            previewGridClasses={{ container: 'px-3 py-4' }}
                                            onChange={handleFileChangeHandler(setFieldValue)}
                                            filesLimit={10}
                                            showAlerts={['error']}
                                            useChipsForPreview={true}
                                            maxFileSize={50000000}
                                        />
                                        {errors.files && touched.files && <div className='pt-2 pl-2'>
                                            <span className='text-danger'>Please upload atleast one file</span>
                                        </div>}
                                    </Grid>
                                    <Grid container className='justify-content-end pt-2'>
                                        <Button type='button' onClick={handleClose} variant="contained" className='mr-3'>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            Upload
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>

                </Loadable>
            </DialogContent>
        </Dialog>)
    );
}

export default UploadDialog