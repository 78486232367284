import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReleaseNotesSection } from '../ReleaseNotesSection';
import { Element } from 'react-scroll';

const useStyles = makeStyles(theme => {
    return {
        titleDiv: {
            padding: '16px 24px',
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
        },
        underline: {
            textDecoration: 'underline',
            cursor: 'pointer',
            marginTop: '10px'
        },
        mainDiv: {
            padding: '16px 24px',
        },
    }
});

export default function ReleaseDetailItem(props) {
    const { release } = props;
    const classes = useStyles();
    const features =  release.releaseNotes.filter(x=> x.type.toLowerCase() === 'feature');
    const bugs =  release.releaseNotes.filter(x=> x.type.toLowerCase() === 'defect');

    return (
        <>
            <Element name={`section-${release?.id}`} className="element">
                <Grid container className={classes.titleDiv}>
                    <Grid xs={12} md={12} xl={12}>
                        <Typography variant="h5" >
                            <b>Release - { release?.version }</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid className={classes.mainDiv}>
                    <Grid xs={12} md={12} xl={12}>
                        { features && features.length > 0 && (
                            <>
                                <ReleaseNotesSection title={`What's New`} items={features} icon={`/media/version/favoritestar.png`} />
                            </>
                        )}
                        { features && features.length > 0 && bugs && bugs.length > 0 && (
                            <hr />
                        )}
                        { bugs && bugs.length > 0 && (
                            <ReleaseNotesSection title={`Bugs Fixes`} items={bugs} icon={`/media/version/tools.png`} />
                        )}
                    </Grid>
                </Grid>
            </Element>
        </>
    );
}

ReleaseDetailItem.prototype = {
    release: PropTypes.object.isRequired
}