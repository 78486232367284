import React, { useEffect,useState } from 'react';
import { Helmet } from 'react-helmet';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../../partials/content/Portlet';
import { Loadable,DataTable } from '../../../../common';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BarChart } from '../../../../common/widgets/BarChart';
import { asIsoDate } from '../../../../util/date';

const useStyles = makeStyles(theme => ({
    filterFormControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        maxWidth: 150,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 300,
    },
}));

function TrimStatsPage({
    getStatsPending,
    filters,
    clients,
    remitFields,
    claimFields,
    remitAndPayersStatistics,
    selectedClient,
    actions: {
        setStatisticsFilter,
        setRemitsAndPayersStatistics,
        getRemitsAndPayersStatistics,
    },
}) {
    const classes = useStyles();
    const [claimsSort, setClaimsSort] = useState([{ id: "creationDate", orderBy: "asc" }]);
    const [remitsSort, setRemitsSort] = useState([{ id: "checkEffectiveDate", orderBy: "asc" }]);


    const processDataForChart = (data) => {
        // the purpose for this whole below aggreegation is so we can get exact count and amount of both as per the date 
        const aggregatedData = {};
        data.remits.forEach((item) => {
            const dateKey = asIsoDate(item.checkEffectiveDate)
                .slice(0,filters.frequency==="monthly"? 7:10);
            if (!aggregatedData[dateKey]) {
                aggregatedData[dateKey] = {
                    remitCount: 0,
                    claimCount: 0,
                    remitAmount: 0,
                    claimAmount: 0,
                };
            }
            aggregatedData[dateKey].remitCount += item.count;
            aggregatedData[dateKey].remitAmount += item.paidAmount;
        });
        // Process claims based on creationDate
        data.claims.forEach((item) => {
            const dateKey = asIsoDate(item.creationDate)
                .slice(0, filters.frequency==="monthly"? 7:10);
            if (!aggregatedData[dateKey]) {
                aggregatedData[dateKey] = {
                    remitCount: 0,
                    claimCount: 0,
                    remitAmount: 0,
                    claimAmount: 0,
                };
            }
            aggregatedData[dateKey].claimCount += item.count;
            aggregatedData[dateKey].claimAmount += item.claimAmount;
        });

        const labels = Object.keys(aggregatedData).sort();
        const remitCounts = labels.map(
            (label) => aggregatedData[label].remitCount
        );
        const claimCounts = labels.map(
            (label) =>
                aggregatedData[label].claimCount != 0 &&
                aggregatedData[label].claimCount
        );
        const remitAmounts = labels.map(
            (label) => aggregatedData[label].remitAmount
        );
        const claimAmounts = labels.map(
            (label) => aggregatedData[label].claimAmount
        );

        return {
            labels: labels,
            datasets: [
                {
                    label: "Remit Counts",
                    data: remitCounts,
                    backgroundColor: "rgba(54, 162, 235, 0.6)",
                    borderColor: "rgba(54, 162, 235, 1)",
                    borderWidth: 2,
                    stack: "Stack 0",
                    tooltipData: remitAmounts, 
                },
                {
                    label: "Claim Counts",
                    data: claimCounts,
                    backgroundColor: "rgba(255, 99, 132, 0.6)",
                    borderColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 2,
                    stack: "Stack 0",
                    tooltipData: claimAmounts, 
                },
            ],
        };
    };

    useEffect(() => {
        if (!filters.clientId) {
            getRemitsAndPayersStatistics(filters.frequency, selectedClient);
            return;
        }
        getRemitsAndPayersStatistics(filters.frequency, filters.clientId);
    }, [filters]);

    const handleFilterChange = (e) => {
        setStatisticsFilter({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const handleSort = (sort, tableId) => {
        let data = remitAndPayersStatistics[tableId];

        sort.forEach((item) => {
            const isDescending = item?.direction === "desc";
            const id = item?.id;

            if (data[0] && id in data[0]) {
                data.sort((a, b) => {
                    const isDate = id.toLowerCase().includes("date");

                    if (isDescending)
                        return isDate
                            ? new Date(b[id]).getTime() -
                                  new Date(a[id]).getTime()
                            : b[id] - a[id];
                    return isDate
                        ? new Date(a[id]).getTime() - new Date(b[id]).getTime()
                        : a[id] - b[id];
                });
            }
        });

        setRemitsAndPayersStatistics({
            remitAndPayersStatistics: {
                ...remitAndPayersStatistics,
                [tableId]: data,
            },
        });
        if (tableId === "remits") {
            setRemitsSort(sort);
            return;
        }
        setClaimsSort(sort);
    };


    return (
        <>
            <Helmet title="Statistics" />
            <Portlet fit="true">
                <Loadable loading={getStatsPending}>
                    <PortletHeader
                        title="Client Statistics"
                        toolbar={
                            <PortletHeaderToolbar>
                                <Grid container>
                                    <Grid>
                                        <FormControl
                                            className={
                                                classes.filterFormControl
                                            }
                                        >
                                            <InputLabel>Frequency</InputLabel>
                                            <Select
                                                value={filters.frequency}
                                                onChange={handleFilterChange}
                                                name="frequency"
                                            >
                                                <MenuItem value="daily">
                                                    Daily
                                                </MenuItem>
                                                <MenuItem value="weekly">
                                                    Weekly
                                                </MenuItem>
                                                <MenuItem value="monthly">
                                                    Monthly
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid>
                                        <FormControl
                                            className={
                                                classes.filterFormControl
                                            }
                                        >
                                            <InputLabel>Client</InputLabel>
                                            <Select
                                                value={
                                                    filters.clientId ||
                                                    selectedClient
                                                }
                                                onChange={handleFilterChange}
                                                name="clientId"
                                            >
                                                {clients.map((client) => (
                                                    <MenuItem
                                                        key={client.clientId}
                                                        value={client.schema}
                                                    >
                                                        {client.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </PortletHeaderToolbar>
                        }
                    />

                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12} className="">
                                    <Portlet>
                                        <PortletHeader
                                            title="Claim Statistics"
                                            showTitle={true}
                                            toolbar={<></>}
                                        />
                                        <PortletBody className="pl-0">
                                        {/* @ts-ignore:next-line  */}
                                            <DataTable
                                                fields={claimFields}
                                                data={
                                                    remitAndPayersStatistics?.claims || []
                                                }
                                                displayTotal={true}
                                                onSort={(sortData)=>handleSort(sortData,"claims")}
                                                sort={claimsSort}

                                            />
                                        </PortletBody>
                                    </Portlet>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12} className="">
                                    <Portlet>
                                        <PortletHeader
                                            title="Remit Statistics"
                                            showTitle={true}
                                            toolbar={<></>}
                                        />
                                        <PortletBody className="pl-0">
                                        {/* @ts-ignore:next-line  */}
                                            <DataTable
                                                fields={remitFields}
                                                data={
                                                    remitAndPayersStatistics?.remits || []
                                                }
                                                displayTotal={true}
                                                onSort={(sortData)=>handleSort(sortData,"remits")}
                                                sort={remitsSort}
                                            />
                                        </PortletBody>
                                    </Portlet>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <PortletBody fit="true">
                        <Grid container className="p-4">
                            <Grid md={12}>
                                <Portlet fluid="true" fit="true">
                                    <BarChart
                                        height="400px"
                                        options={{
                                            type: "bar",
                                            options: {
                                                title: { display: false },
                                                tooltips: {
                                                    callbacks: {
                                                        title: function (
                                                            tooltipItems
                                                        ) {
                                                            return tooltipItems[0]
                                                                .label; 
                                                        },
                                                        label: function (
                                                            tooltipItem
                                                        ) {
                                                            const datasetIndex =
                                                                tooltipItem.datasetIndex;
                                                            const dataPoint =
                                                                tooltipItem.yLabel; // Count value
                                                            const processedData =
                                                                processDataForChart(remitAndPayersStatistics);
                                                            const paidAmount =
                                                                datasetIndex ===
                                                                0
                                                                    ? processedData
                                                                          ?.datasets?.[0]
                                                                          ?.tooltipData[
                                                                          tooltipItem
                                                                              .index
                                                                      ] // For remits
                                                                    : processedData
                                                                          ?.datasets?.[1]
                                                                          ?.tooltipData[
                                                                          tooltipItem
                                                                              .index
                                                                      ]; 
                                                            return [
                                                                datasetIndex ===
                                                                0
                                                                    ? `Remit Counts: ${dataPoint}`
                                                                    : `Claim Counts: ${dataPoint}`,
                                                                `Amount: ${paidAmount.toLocaleString()}`,
                                                            ];
                                                        },
                                                    },
                                                },
                                                legend: { display: true },
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                barRadius: 4,
                                                scales: {
                                                    xAxis: {
                                                        suggestedMin: 0,
                                                        beginAtZero: true,
                                                        min: 0,
                                                    },
                                                    yAxis: {
                                                        suggestedMin: 0,
                                                        beginAtZero: true,
                                                        min: 0,
                                                    },

                                                    y: { suggestedMin: 0 },
                                                    x: { suggestedMin: 0 },

                                                    xAxes: [
                                                        {
                                                            stacked: true,
                                                            beginAtZero: true,
                                                            min: 0,
                                                        },
                                                    ],
                                                    yAxes: [
                                                        {
                                                            ticks: {},

                                                            stacked: true,
                                                            beginAtZero: true,
                                                            min: 0,
                                                        },
                                                    ],
                                                },
                                                layout: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        bottom: 25,
                                                    },
                                                },
                                            },
                                        }}
                                        data={
                                            remitAndPayersStatistics &&
                                            processDataForChart(remitAndPayersStatistics)
                                        }
                                        title="Stats"
                                        desc=""
                                    />
                                </Portlet>
                            </Grid>
                            
                        </Grid>
                    </PortletBody>
                </Loadable>
            </Portlet>
        </>
    );
}

export default TrimStatsPage
