import { FormControl, FormHelperText, IconButton, Input, InputLabel, MenuItem, Select } from '@mui/material';
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Loadable } from '../../../common';
import { BackButton } from '../../../common/BackButton';

import { Button, Grid, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card } from "react-bootstrap";
import {
    Portlet,
    PortletBody,
    PortletHeader, PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { getBackPath } from "../../../router/RouterHelpers";
import { DirectoryEditDialog } from "../DirectoryEditDialog";
import { FeeScheduleBreadCrumb } from "../FeeScheduleBreadCrumb";
import { DependencyDialog } from "./DependencyDialog";

const useStyles = makeStyles(theme => ({
    textField: {
        width: '100%',
    },
    selectField: {
        width: '100%',
    },
    description: {
        width: '100%',
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: '100%',
    },
    portlet: {
        'box-shadow': 'none',
        marginBottom: 0,
        "& .kt-portlet__head": {
            borderColor: theme.palette.secondary.main,
            // marginLeft: theme.spacing(3.5),
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paper: {
        pointerEvents: 'auto',
        width: 300,
    },
    underline: {
        textDecoration: 'underline',
    },
    button: {
        "&:hover": {
            color: 'white',
        },
        marginTop: theme.spacing(.5),
    },
    portletHeader: {
        marginLeft: "0px !important"
    },
    iconButton: {
        marginTop: theme.spacing(3),
    },
    clearButton: {
        marginTop: theme.spacing(3),
        marginLeft: '10px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
}));

export default function FeeScheduleEditPage({
    initialValues, feeScheduleId, intl, open, feeScheduleCreatePending, feeScheduleUpdatePending, mode, feescheduleTypes,
    feeScheduleGetPending, directoryId, additionalRates, history, defaultRoute,
    actions: { getFeeScheduleTypes, createFeeSchedule, getFeeschedule, updateFeeSchedule,getHierarchy, setFeeScheduleLabelDepdendencyDialog } 
}) {
    const currentLocation = useLocation().pathname;

    const classes = useStyles();
    const isEdit = mode === 'edit';

    useEffect(() => {
        getFeeScheduleTypes()
    
    }, [getFeeScheduleTypes]);

    useEffect(() => {
        if(isEdit)
            {getFeeschedule(feeScheduleId);
}    }, [ feeScheduleId, getFeeschedule]);

    useEffect(() => {
        getHierarchy()
    }, [getHierarchy])



    const handleEditCallback = (id, dependencyResponse) => {
        // props.history.push("/cms/feeschedules/root");
        if(dependencyResponse && !dependencyResponse.canDelete && dependencyResponse.records.length > 0) {
            setFeeScheduleLabelDepdendencyDialog({ open: true, dependencies: dependencyResponse.records });
        }
        else {
            getBackPath(currentLocation, { history, defaultRoute });
        }
    }

    const handleAddLabelField = (feescheduleLabelToRateXRef, setFieldValue) => {
        if (feescheduleLabelToRateXRef.length < 10) {
            setFieldValue("feescheduleLabelToRateXRef", [...feescheduleLabelToRateXRef, 
            {   id: 0,
                label: '',
                rateColumn: additionalRates?.find(x=> x.id === `Rate`).name,
                feeScheduleId: feeScheduleId,
            }]);
        }
    };

    const handleRateChange = (selectedRate, index, values, setFieldValue) => {
        const newLabels = [...values.feescheduleLabelToRateXRef];
        newLabels[index] = {...newLabels[index], rateColumn: selectedRate};
        setFieldValue('feescheduleLabelToRateXRef', newLabels);
    };

    const handleRateLabelChange = (index, newValue, values, setFieldValue) => {
        const newLabels = [...values.feescheduleLabelToRateXRef];
        newLabels[index] = {...newLabels[index], label: newValue};
        setFieldValue('feescheduleLabelToRateXRef', newLabels);
    };

    const handleLabelDelete = (index, values, setFieldValue) => {
        const filteredLabels = values.feescheduleLabelToRateXRef.filter((_, i) => i!== index);
        setFieldValue('feescheduleLabelToRateXRef', filteredLabels);
    };

    const getFilteredRates = (currentIndex, values) => {
        const selectedRates = values.feescheduleLabelToRateXRef.map(x => x.rateColumn);
        return additionalRates.filter(({ id }) => !selectedRates.includes(id) || selectedRates[currentIndex] === id);
    };

    function handleClearFields(setFieldValue) {
        setFieldValue(`feescheduleLabelToRateXRef`, [{   
            id: 0,
            label: '',
            rateColumn: additionalRates?.find(x=> x.id === `Rate`).name,
            feeScheduleId: feeScheduleId
        }]);
    }

    return (<>
        <FeeScheduleBreadCrumb directoryId={directoryId}  />
        <Loadable loading={feeScheduleCreatePending || feeScheduleUpdatePending || feeScheduleGetPending}>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validate={values => {
                        const errors : Record<string,any> = {};

                        if (!values.name) {
                            errors.name = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.feeScheduleTypeId) {
                            errors.feeScheduleTypeId = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if (!values.effectiveDate) {
                            errors.effectiveDate = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if (!values.expirationDate) {
                            errors.expirationDate = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if (values.expirationDate < values.effectiveDate) {
                            errors.expirationDate = 'Expiration Date can not be before effective date';
                        }

                        if(values.feescheduleLabelToRateXRef && values.feescheduleLabelToRateXRef.length > 0 && values.feescheduleLabelToRateXRef.filter(x=> x.label && x.rateColumn).length === 0) {
                            if(!errors.feescheduleLabelToRateXRef) { errors.feescheduleLabelToRateXRef = []; }
                            errors.feescheduleLabelToRateXRef[0] = 'Please enter at least one label.';
                        }

                        //check that labels must be unique
                        if(values.feescheduleLabelToRateXRef && values.feescheduleLabelToRateXRef.length > 1) {
                            const labels = values.feescheduleLabelToRateXRef.filter(x=> x.label).map(item => item.label);
                            const labelCount = labels.reduce((acc, label) => {
                                acc[label] = (acc[label] || 0) + 1;
                                return acc;
                            }, {});
                            values.feescheduleLabelToRateXRef.forEach((item, index) => {
                                if(labelCount[item.label] > 1) {
                                    if(!errors.feescheduleLabelToRateXRef) { errors.feescheduleLabelToRateXRef = []; }
                                    errors.feescheduleLabelToRateXRef[index] = 'Labels must be unique.';
                                }
                            });
                        }

                        return errors;
                    }

                    }
                    onSubmit={(values) => {
                        let payload = {
                            name: values.name,
                            effectiveDate: values.effectiveDate,
                            expirationDate: values.expirationDate,
                            description: values.description,
                            feeScheduleTypeId: values.feeScheduleTypeId,
                            directoryId: directoryId === 'root' ? undefined : directoryId
                        } as any

                        if(values.feescheduleLabelToRateXRef.length > 0 && values.feescheduleLabelToRateXRef.filter(x => x.label !== null && x.label !== '' && x.rateColumn !== null && x.rateColumn !== '').length > 0) {
                            payload = {
                                ...payload,
                                LabelRateXref: values?.feescheduleLabelToRateXRef.filter(x => x.label !== null && x.label !== '' && x.rateColumn !== null && x.rateColumn !== '')
                            }
                        }
                        else {
                            payload = {
                                ...payload,
                                LabelRateXref: []
                            }
                        }

                        if (mode === 'create') {
                            createFeeSchedule(payload, handleEditCallback);
                        }
                        else {
                            payload = {
                                ...payload,
                                id: values.id,
                            }
                            updateFeeSchedule(payload, handleEditCallback);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        dirty
                    }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                                        <Portlet>

                                                        <PortletHeader
                                toolbar={
                                    <PortletHeaderToolbar className='w-100'>
                                        <Grid container alignItems="center">
                                            

                                            
                                            <Grid md={12} container className="justify-content-end">


                                                <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/cms/feeschedules/root" />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className='ml-3'
                                                    type="submit"
                                                    disabled={!dirty}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </PortletHeaderToolbar>
                                }
                            />

                            <PortletBody>


                            <div className="root">
                                <Grid container>
                                    <Grid item xs={12} md={9}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    label="Name"
                                                    name="name"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    helperText={touched.name && errors.name}
                                                    error={Boolean(touched.name && errors.name)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel htmlFor="feeScheduleTypeId">Fee Schedule Type</InputLabel>
                                                    <Select
                                                        required
                                                        className={classes.selectField}
                                                        value={values.feeScheduleTypeId}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.feeScheduleTypeId && errors.feeScheduleTypeId)}
                                                        input={<Input id="feeScheduleTypeId" />}
                                                        inputProps={{
                                                            name: "feeScheduleTypeId",
                                                            id: "feeScheduleTypeId"
                                                        }}
                                                    >
                                                        {feescheduleTypes.map(feeScheduleType => (
                                                            <MenuItem
                                                                key={feeScheduleType.id}
                                                                value={feeScheduleType.id}
                                                            >
                                                                {feeScheduleType.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText error>{touched.feeScheduleTypeId && errors.feeScheduleTypeId}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    type="date"
                                                    label="Effective Date"
                                                    name="effectiveDate"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.effectiveDate}
                                                    helperText={touched.effectiveDate && errors.effectiveDate}
                                                    error={Boolean(touched.effectiveDate && errors.effectiveDate)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    type="date"
                                                    label="Expiration Date"
                                                    name="expirationDate"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.expirationDate}
                                                    helperText={touched.expirationDate && errors.expirationDate}
                                                    error={Boolean(touched.expirationDate && errors.expirationDate)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            minRows={2}
                                            type="text"
                                            multiline
                                            label="Description"
                                            name="description"
                                            className={classes.description}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.description}
                                            helperText={touched.description && errors.description}
                                            error={Boolean(touched.description && errors.description)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    {values.feescheduleLabelToRateXRef.length > 0 && (
                                        <Grid item xs={12} md={9} >
                                            <Card className={`col-md-12 mt-3 p-0`}>
                                                <Card.Header>
                                                    <Grid container className="align-items-center">
                                                        <Grid item xs={12}>
                                                            <h5 className="mb-0">Labels for Rates </h5>
                                                        </Grid>
                                                    </Grid>
                                                </Card.Header>
                                                <Card.Body>
                                                <Grid container spacing={3}>
                                                    {values.feescheduleLabelToRateXRef.map((additionalRateLabel, index) => (
                                                        <React.Fragment key={index}>
                                                            <Grid item xs={5} md={5}>
                                                                <TextField
                                                                    type="text"
                                                                    label='label'
                                                                    margin="normal"
                                                                    value={additionalRateLabel.label || ''}
                                                                    className={classes.textField}
                                                                    onBlur={handleBlur}
                                                                    onChange={(e) => handleRateLabelChange(index, e.target.value, values, setFieldValue)}
                                                                    fullWidth
                                                                    helperText={touched.feescheduleLabelToRateXRef && errors.feescheduleLabelToRateXRef && errors.feescheduleLabelToRateXRef[index]}
                                                                    error={Boolean(touched.feescheduleLabelToRateXRef && errors.feescheduleLabelToRateXRef && errors.feescheduleLabelToRateXRef[index])}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5} md={5}>
                                                                <FormControl className={classes.formControl}>
                                                                    <InputLabel htmlFor="ratelabel">Rate</InputLabel>
                                                                    <Select
                                                                        className={classes.selectField}
                                                                        value={additionalRateLabel.rateColumn || ''}
                                                                        onChange={(event) => handleRateChange(event.target.value, index, values, setFieldValue)}
                                                                        inputProps={{
                                                                            name: "name",
                                                                            id: "id",
                                                                        }}
                                                                    >
                                                                        {getFilteredRates(index, values).map(({id, name}) => (
                                                                            <MenuItem
                                                                                key={id}
                                                                                value={id}
                                                                            >
                                                                                {name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            {index === 0 && (
                                                                <Grid item xs={3} md={2}>
                                                                    <Grid container>
                                                                        <IconButton
                                                                            edge="start"
                                                                            color="primary"
                                                                            aria-label="Add"
                                                                            onClick={() => handleAddLabelField(values.feescheduleLabelToRateXRef, setFieldValue)}
                                                                            className={classes.iconButton}
                                                                            size="large">
                                                                            <AddCircleIcon />
                                                                        </IconButton>
                                                                        <Button
                                                                            size="small"
                                                                            onClick={() => handleClearFields(setFieldValue)}
                                                                            className={classes.clearButton}
                                                                            startIcon={<DeleteIcon />}
                                                                        >
                                                                            Clear All
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            )}

                                                            {index !== 0 && (
                                                                <Grid item xs={1}>
                                                                    {values.feescheduleLabelToRateXRef.length > 1 && (
                                                                        <IconButton
                                                                            edge="start"
                                                                            aria-label="Delete"
                                                                            onClick={() => handleLabelDelete(index, values, setFieldValue)}
                                                                            className={classes.iconButton}
                                                                            size="large">
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </Grid>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </Grid>
                                                </Card.Body>
                                            </Card>
                                        </Grid>
                                    )}
                                    
                                </Grid>
                                <hr />
                                {/* <Grid className="d-flex justify-content-end">
                                    <Button variant="contained" onClick={handleDialogClose} color="default">
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className='ml-3'
                                        type="submit"
                                        disabled={feeScheduleCreatePending || feeScheduleUpdatePending || (!dirty && isEdit)}
                                    >
                                        {(feeScheduleCreatePending || feeScheduleUpdatePending) ? 'Saving..' : 'Save'}
                                    </Button>
                                </Grid> */}
                            </div>
                            </PortletBody>

                            </Portlet>

                        </form>
                    )}
                </Formik>
        </Loadable >
        <DirectoryEditDialog />
        <DependencyDialog />
    </>);
}