import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import EditDrgComponent from './EditDrgDialog';
import { updateHospitalServices } from '../../../store/actions/hospitalServices';


const selectDrgEditDialog = (state) => state.hospitalServices.drgDialog;

const selectOpen = createSelector(
    selectDrgEditDialog,
    (drgDialog) => {
        if (drgDialog && drgDialog?.open) {
            return true
        }
        return false
    }
);

export const EditDrgDialog = connect(

    () => {
        const selectInitialValues:any = createSelector(
            selectDrgEditDialog,
            (drgDialogValues) => {
                return {
                    id: drgDialogValues?.data?.id ?? '',
                    drg: drgDialogValues?.data?.drg ?? '',
                    type: drgDialogValues?.data?.type ?? '',
                    title: drgDialogValues?.data?.title ?? '',
                    weights: drgDialogValues?.data?.weights ?? '',
                    facilityId: drgDialogValues?.data?.facilityId ?? ''
                }
            }
        )
        return (state:any, props) => ({
            initialValues: selectInitialValues(state),
            pending: state.hospitalServices.update.pending,
            open: selectOpen(state),


        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            updateHospitalServices
        }, dispatch)
    })
)(injectIntl(EditDrgComponent));