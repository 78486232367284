import React, { useEffect } from 'react';
import { Portlet } from '../../../partials/content/Portlet';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BasicDetailsSection } from './BasicDetailsSection';
import { ClaimLineItemSection } from './ClaimLineItemSection.js';
import { ClaimAdjustments } from './ClaimAdjustments';
import { ServiceAdjustments } from './ServiceAdjustments';
import { CommentsForm } from './CommentsForm';

const useStyles = makeStyles(theme => {
    return {
        portlet: {
            'box-shadow': 'none',
        },
        lineItemGrid: {
            padding: '0px 24px',
        },
        lineItemBox: {
            padding: '0 24px 24px 24px',
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
            maxHeight: '500px',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        commentsBox: {
            marginTop: '16px',
            marginBottom: '24px',
            padding: '12px 24px',
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
        }
    }
});

function ClaimViewPage({ intl, claimId, actions: { getClaimAllDetails, getClaimLineItems, getHistory, resetClaimLineItem, getClaimComments, getTinyContracts,setClaimCommentsConfirmationDialog,setClaimComments } }) {
    const classes = useStyles();

    useEffect(() => {
        getTinyContracts();
        getClaimAllDetails(claimId);
        getClaimLineItems(claimId);
        getHistory(claimId);
        getClaimComments(claimId);
        setClaimCommentsConfirmationDialog({open:false,state:''})
        setClaimComments('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimId]);


    const refreshLineItems = () => {
        resetClaimLineItem();
        getClaimLineItems(claimId);
    }

    const reprocessClaimCallback = () => {
        refreshLineItems();
        getHistory(claimId);
        getClaimAllDetails(claimId);
    }

    return (
        <>
            <Portlet className={classes.portlet}>

                <BasicDetailsSection reprocessClaimCallback={reprocessClaimCallback} claimId={claimId} />

                <Grid className={classes.lineItemGrid}>
                    <Grid className={classes.lineItemBox}>
                        <ClaimLineItemSection refreshLineItems={refreshLineItems} />
                    </Grid>
                    <Grid className={classes.commentsBox}>
                        <CommentsForm claimId={claimId} />
                    </Grid>
                </Grid>
            </Portlet>
            <ClaimAdjustments claimId={claimId}/>
            <ServiceAdjustments claimId={claimId}/>
        </>
    )
}

export default ClaimViewPage
