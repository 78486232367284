import { Chip, Grid, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { toAbsoluteUrl } from '../../../../_metronic';
import { Loadable, PopoverLink } from '../../../common';
import { Portlet, PortletBody, PortletHeader } from '../../../partials/content/Portlet';
import RefreshIcon from '@mui/icons-material/Refresh';
import { STATUS_DISCONNECTED } from '../../../store/constants/controlRoom';

const useStyles = makeStyles(theme => ({
    serviceIcon: {
        height: '140px'
    },
    serviceDiv: {
        border: '1px solid black',
        borderRadius: '20px',
        width: '260px',
        padding: '15px 0px',
        // backgroundColor: '#13a86e3d'
    },
    heading: {
        color: 'black',
        fontWeight: 600,
        marginTop: '5px'
    },
    gridCol: {
        paddingTop: '20px',
        paddingRight: '20px'
    },
    disconnectedColor: {
        backgroundColor: '#d9534f',
        color: '#fff'
    },
}));

function HealthCheckPage(props) {
    const { redShiftHealthPending, redshiftHealth, rdsHealthPending, rdsHealth,
        ecsHealth, ecsHealthPending, networkHealth, networkHealthPending,
        sqsHealth, sqsHealthPending, s3Health, s3HealthPending,
        cloudfrontHealth, cloudfrontHealthPending, cloudwatchHealth, cloudwatchHealthPending,
        secretManagerHealth, secretManagerHealthPending,
        actions: { getHealthOfRedshift, getHealthOfRDS, getHealthOfECS, getHealthOfNetwork, getHealthOfSQS,
            getHealthOfS3, getHealthOfCloudwatch, getHealthOfCloudfront, getHealthOfSecretManager } } = props;

    const classes = useStyles();

    useEffect(() => {
        getHealthOfRedshift()
    }, [getHealthOfRedshift]);

    useEffect(() => {
        getHealthOfRDS();
    }, [getHealthOfRDS]);

    useEffect(() => {
        getHealthOfECS();
    }, [getHealthOfECS]);

    useEffect(() => {
        getHealthOfNetwork();
    }, [getHealthOfNetwork]);

    useEffect(() => {
        getHealthOfSQS();
    }, [getHealthOfSQS]);

    useEffect(() => {
        getHealthOfS3();
    }, [getHealthOfS3]);

    useEffect(() => {
        getHealthOfCloudwatch();
    }, [getHealthOfCloudwatch]);

    useEffect(() => {
        getHealthOfCloudfront();
    }, [getHealthOfCloudfront]);

    useEffect(() => {
        getHealthOfSecretManager();
    }, [getHealthOfSecretManager]);

    const getErrorDetails = (service) => {
        return [
            {
                label: 'Error',
                data: service?.error
            },
            {
                label: 'Additonal Information',
                data: service?.additonalInformation
            }
        ]
    }

    return (<>
        <Loadable
            loading={redShiftHealthPending || rdsHealthPending || ecsHealthPending || networkHealthPending || sqsHealthPending ||
                s3HealthPending || cloudfrontHealthPending || cloudwatchHealthPending || secretManagerHealthPending}
        >
            <Helmet title="Health Check" />
            <Portlet>
                <PortletHeader
                    title="Health Check"
                    toolbar={<></>}
                />
                <PortletBody>
                    <Grid xs={12}>
                        <Grid container>
                            <Grid className={classes.gridCol}>
                                <Grid className={classes.serviceDiv}>
                                    <Grid className='text-center' container>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img className={classes.serviceIcon} alt="Unauthorized Image" src={`${toAbsoluteUrl("/media/awsHealth/redshift.png")}`} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <IconButton onClick={getHealthOfRedshift} className='p-1' size="large">
                                                <RefreshIcon style={{ fontSize: 35 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid className='text-center'>
                                        <span class={classes.heading}>Redshift </span>
                                    </Grid>
                                    <Grid className='text-center'>
                                        {redshiftHealth?.status === STATUS_DISCONNECTED ?
                                            <PopoverLink
                                                columnData={
                                                    <Chip
                                                        style={{ fontWeight: '600' }}
                                                        label={`Status: ${STATUS_DISCONNECTED}`}
                                                        className={classes.disconnectedColor}
                                                    />
                                                }
                                                hoverData={getErrorDetails(redshiftHealth)}
                                                title={'Error Details'}
                                            /> :
                                            <Chip
                                                style={{ fontWeight: '600' }}
                                                label={`Status: ${redshiftHealth?.status}`}
                                                className='text-white MuiChip-colorSecondary'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.gridCol}>
                                <Grid className={`${classes.serviceDiv}`}>
                                    <Grid className='text-center' container>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img className={classes.serviceIcon} alt="Unauthorized Image" src={`${toAbsoluteUrl("/media/awsHealth/apiserver.png")}`} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <IconButton onClick={getHealthOfNetwork} className='p-1' size="large">
                                                <RefreshIcon style={{ fontSize: 35 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid className='text-center'>
                                        <span class={classes.heading}>API Server </span>
                                    </Grid>
                                    <Grid className='text-center'>
                                        {networkHealth?.status === STATUS_DISCONNECTED ?
                                            <PopoverLink
                                                columnData={
                                                    <Chip
                                                        style={{ fontWeight: '600' }}
                                                        label={`Status: ${STATUS_DISCONNECTED}`}
                                                        className={classes.disconnectedColor}
                                                    />
                                                }
                                                hoverData={getErrorDetails(networkHealth)}
                                                title={'Error Details'}
                                            /> :
                                            <Chip
                                                style={{ fontWeight: '600' }}
                                                label={`Status: ${networkHealth?.status}`}
                                                className='text-white MuiChip-colorSecondary'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.gridCol}>
                                <Grid className={classes.serviceDiv}>
                                    <Grid className='text-center' container>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img className={classes.serviceIcon} alt="Unauthorized Image" src={`${toAbsoluteUrl("/media/awsHealth/rds.png")}`} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <IconButton onClick={getHealthOfRDS} className='p-1' size="large">
                                                <RefreshIcon style={{ fontSize: 35 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid className='text-center'>
                                        <span class={classes.heading}>RDS </span>
                                    </Grid>
                                    <Grid className='text-center'>
                                        {rdsHealth?.status === STATUS_DISCONNECTED ?
                                            <PopoverLink
                                                columnData={
                                                    <Chip
                                                        style={{ fontWeight: '600' }}
                                                        label={`Status: ${STATUS_DISCONNECTED}`}
                                                        className={classes.disconnectedColor}
                                                    />
                                                }
                                                hoverData={getErrorDetails(rdsHealth)}
                                                title={'Error Details'}
                                            /> :
                                            <Chip
                                                style={{ fontWeight: '600' }}
                                                label={`Status: ${rdsHealth?.status}`}
                                                className='text-white MuiChip-colorSecondary'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.gridCol}>
                                <Grid className={classes.serviceDiv}>
                                    <Grid className='text-center' container>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img className={classes.serviceIcon} alt="Unauthorized Image" src={`${toAbsoluteUrl("/media/awsHealth/sqs.png")}`} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <IconButton onClick={getHealthOfSQS} className='p-1' size="large">
                                                <RefreshIcon style={{ fontSize: 35 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid className='text-center'>
                                        <span class={classes.heading}>SQS </span>
                                    </Grid>
                                    <Grid className='text-center'>
                                        {sqsHealth?.status === STATUS_DISCONNECTED ?
                                            <PopoverLink
                                                columnData={
                                                    <Chip
                                                        style={{ fontWeight: '600' }}
                                                        label={`Status: ${STATUS_DISCONNECTED}`}
                                                        className={classes.disconnectedColor}
                                                    />
                                                }
                                                hoverData={getErrorDetails(sqsHealth)}
                                                title={'Error Details'}
                                            /> :
                                            <Chip
                                                style={{ fontWeight: '600' }}
                                                label={`Status: ${sqsHealth?.status}`}
                                                className='text-white MuiChip-colorSecondary'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.gridCol}>
                                <Grid className={classes.serviceDiv}>
                                    <Grid className='text-center' container>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img className={classes.serviceIcon} alt="Unauthorized Image" src={`${toAbsoluteUrl("/media/awsHealth/ecs.png")}`} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <IconButton className='p-1' onClick={getHealthOfECS} size="large">
                                                <RefreshIcon style={{ fontSize: 35 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid className='text-center'>
                                        <span class={classes.heading}>ECS </span>
                                    </Grid>
                                    <Grid className='text-center'>
                                        {ecsHealth?.status === STATUS_DISCONNECTED ?
                                            <PopoverLink
                                                columnData={
                                                    <Chip
                                                        style={{ fontWeight: '600' }}
                                                        label={`Status: ${STATUS_DISCONNECTED}`}
                                                        className={classes.disconnectedColor}
                                                    />
                                                }
                                                hoverData={getErrorDetails(ecsHealth)}
                                                title={'Error Details'}
                                            /> :
                                            <Chip
                                                style={{ fontWeight: '600' }}
                                                label={`Status: ${ecsHealth?.status}`}
                                                className='text-white MuiChip-colorSecondary'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.gridCol}>
                                <Grid className={classes.serviceDiv}>
                                    <Grid className='text-center' container>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img className={classes.serviceIcon} alt="Unauthorized Image" src={`${toAbsoluteUrl("/media/awsHealth/s3.png")}`} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <IconButton onClick={getHealthOfS3} className='p-1' size="large">
                                                <RefreshIcon style={{ fontSize: 35 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid className='text-center'>
                                        <span class={classes.heading}>S3 </span>
                                    </Grid>
                                    <Grid className='text-center'>
                                        {s3Health?.status === STATUS_DISCONNECTED ?
                                            <PopoverLink
                                                columnData={
                                                    <Chip
                                                        style={{ fontWeight: '600' }}
                                                        label={`Status: ${STATUS_DISCONNECTED}`}
                                                        className={classes.disconnectedColor}
                                                    />
                                                }
                                                hoverData={getErrorDetails(s3Health)}
                                                title={'Error Details'}
                                            /> :
                                            <Chip
                                                style={{ fontWeight: '600' }}
                                                label={`Status: ${s3Health?.status}`}
                                                className='text-white MuiChip-colorSecondary'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.gridCol}>
                                <Grid className={classes.serviceDiv}>
                                    <Grid className='text-center' container>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img className={classes.serviceIcon} alt="Unauthorized Image" src={`${toAbsoluteUrl("/media/awsHealth/cloudwatch.png")}`} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <IconButton onClick={getHealthOfCloudwatch} className='p-1' size="large">
                                                <RefreshIcon style={{ fontSize: 35 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid className='text-center'>
                                        <span class={classes.heading}>Cloudwatch </span>
                                    </Grid>
                                    <Grid className='text-center'>
                                        {cloudwatchHealth?.status === STATUS_DISCONNECTED ?
                                            <PopoverLink
                                                columnData={
                                                    <Chip
                                                        style={{ fontWeight: '600' }}
                                                        label={`Status: ${STATUS_DISCONNECTED}`}
                                                        className={classes.disconnectedColor}
                                                    />
                                                }
                                                hoverData={getErrorDetails(cloudwatchHealth)}
                                                title={'Error Details'}
                                            /> :
                                            <Chip
                                                style={{ fontWeight: '600' }}
                                                label={`Status: ${cloudwatchHealth?.status}`}
                                                className='text-white MuiChip-colorSecondary'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.gridCol}>
                                <Grid className={classes.serviceDiv}>
                                    <Grid className='text-center' container>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img className={classes.serviceIcon} alt="Unauthorized Image" src={`${toAbsoluteUrl("/media/awsHealth/cloudfront.png")}`} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <IconButton onClick={getHealthOfCloudfront} className='p-1' size="large">
                                                <RefreshIcon style={{ fontSize: 35 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid className='text-center'>
                                        <span class={classes.heading}>Cloudfront </span>
                                    </Grid>
                                    <Grid className='text-center'>
                                        {cloudfrontHealth?.status === STATUS_DISCONNECTED ?
                                            <PopoverLink
                                                columnData={
                                                    <Chip
                                                        style={{ fontWeight: '600' }}
                                                        label={`Status: ${STATUS_DISCONNECTED}`}
                                                        className={classes.disconnectedColor}
                                                    />
                                                }
                                                hoverData={getErrorDetails(cloudfrontHealth)}
                                                title={'Error Details'}
                                            /> :
                                            <Chip
                                                style={{ fontWeight: '600' }}
                                                label={`Status: ${cloudfrontHealth?.status}`}
                                                className='text-white MuiChip-colorSecondary'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.gridCol}>
                                <Grid className={classes.serviceDiv}>
                                    <Grid className='text-center' container>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <img className={classes.serviceIcon} alt="Unauthorized Image" src={`${toAbsoluteUrl("/media/awsHealth/secretmanager.png")}`} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <IconButton onClick={getHealthOfSecretManager} className='p-1' size="large">
                                                <RefreshIcon style={{ fontSize: 35 }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid className='text-center'>
                                        <span class={classes.heading}>Secret Manager </span>
                                    </Grid>
                                    <Grid className='text-center'>
                                        {secretManagerHealth?.status === STATUS_DISCONNECTED ?
                                            <PopoverLink
                                                columnData={
                                                    <Chip
                                                        style={{ fontWeight: '600' }}
                                                        label={`Status: ${STATUS_DISCONNECTED}`}
                                                        className={classes.disconnectedColor}
                                                    />
                                                }
                                                hoverData={getErrorDetails(secretManagerHealth)}
                                                title={'Error Details'}
                                            /> :
                                            <Chip
                                                style={{ fontWeight: '600' }}
                                                label={`Status: ${secretManagerHealth?.status}`}
                                                className='text-white MuiChip-colorSecondary'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </PortletBody>
            </Portlet>
        </Loadable>
    </>);
}

export default HealthCheckPage