import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { CustomLabel, DependentsList, EllipsisText, Loadable } from '../../../common';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function DirectoryDependencyDialog(props) {
    const { open, directoryId, directoryDependencies, directoryDependencyPending, directoryDeletePending, directoryName,
        handleRefresh, actions: { setFeeScheduleDirectoryDependencyDialog, getFeeScheduleDirectoryDependencies, deleteFeeScheduleDirectory, resetDirectoryDependencies } } = props;

    const classes = useStyles();

    useEffect(() => {
        if (open && directoryId) {
            getFeeScheduleDirectoryDependencies(directoryId)
        }
    }, [open, directoryId, getFeeScheduleDirectoryDependencies]);

    const handleDialogClose = () => {
        resetDirectoryDependencies();
        setFeeScheduleDirectoryDependencyDialog(null);
    }

    const handleDeleteCallback = () => {
        setFeeScheduleDirectoryDependencyDialog(null);
        handleRefresh(true);
    }

    const handleDelete = () => {
        if (directoryDependencies.length > 0) return
        deleteFeeScheduleDirectory(directoryId, handleDeleteCallback);
    }

    return (
        (<Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={directoryDependencyPending || directoryDeletePending}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Dependencies ({directoryName})
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleDialogClose}
                                size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    <Grid>
                        {directoryDependencies.length > 0 ?
                            <Typography>Cannot delete this fee schedule directory. Please delete all the dependencies first.</Typography> :
                            <Typography>No dependency found. Please click to delete</Typography>
                        }
                    </Grid>
                    {directoryDependencies.length > 0 && <Grid className='pt-2'>
                        <DependentsList dependents={directoryDependencies} />
                    </Grid>}
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        disabled={directoryDependencyPending || directoryDeletePending || directoryDependencies.length}
                        color="primary"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Loadable >
        </Dialog>)
    );
}

export default DirectoryDependencyDialog
