import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box, Button, ButtonGroup, ClickAwayListener,
    Grid,
    Grow,
    IconButton, Paper, Popper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PurePtr, useStatePtr } from '@pure-ptr/react';
import React from 'react';
import { NumericInput } from '../../common/FormControls';
import { asMonthDate, DateRange } from '../../util/date';
import { asDollars, asPercentage } from '../../util/format';
import { Forecast, YearlyRevenueProjection } from './state';

export const RevenueProjection = ({ yearlyRevenuePtr, dateRange } : {
    yearlyRevenuePtr : PurePtr<YearlyRevenueProjection>
    dateRange : DateRange
}) => {
    const selectedGroupIdPtr = useStatePtr( null as string );

    return (
        <Grid container spacing={0} style={{ height: '100%', overflowY: 'scroll'}}>
        <Grid xs={4} item>
            <h5>Current Reimbursement: {asMonthDate(dateRange.startdate)} - {asMonthDate(dateRange.enddate)}</h5>
            <Grid>
                <CurrentRate 
                    selectedGroupIdPtr={selectedGroupIdPtr}   
                    selectedContractForCurrent={yearlyRevenuePtr.value}/>
            </Grid>
        </Grid>

        <Grid xs={8} item style={{ overflowX: 'scroll' }}>
            <Grid container style={{ flexWrap: 'nowrap' }}>
                { yearlyRevenuePtr.value.years.map( ( year, index ) => (
                    <Grid item className='pl-3' key={index}>
                        <h5>{ year }</h5>
                        <Grid>
                            <Comparison
                                periodPtr={yearlyRevenuePtr}
                                selectedGroupIdPtr={selectedGroupIdPtr}
                                index={index + 1 }
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </Grid>
    )
}

export const CurrentRate = ({ selectedContractForCurrent, selectedGroupIdPtr } : {
    selectedContractForCurrent: YearlyRevenueProjection,
    selectedGroupIdPtr: PurePtr<string>
}) => {
    const classes = useTableStyles({ color: 'blue' });

    return (
        <TableContainer component={Paper}>
            <Table size="small" classes={{ root: classes.table }}>
                <TableHead>
                    <TableRow classes={{ root : classes.headerRow }}>
                        <TableCell align='left'>Rev. Code </TableCell>
                        <TableCell width={80} align='left'>Unit </TableCell>
                        <TableCell width={100} align='left'>Rate </TableCell>
                        <TableCell width={120} align='left'>Total </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {selectedContractForCurrent?.groups?.map(group => {
                        return (
                            <React.Fragment key={group.id}>
                                <TableRow 
                                    classes={{ root : classes.groupRow }}
                                    onClick={() => 
                                        selectedGroupIdPtr.set(
                                            selectedGroupIdPtr.value === group.id ? 
                                                null : 
                                                group.id 
                                        )
                                    }
                                >
                                    <TableCell colSpan={3} align='left'>
                                        { selectedGroupIdPtr.value === group.id ? 
                                            <ArrowDropDownIcon /> 
                                        : 
                                            <ArrowRightIcon /> 
                                        }

                                        { group.service } 
                                    </TableCell>
                                    <TableCell align='right'>{ asDollars( group.summary[0].total )}</TableCell>
                                </TableRow>

                                {selectedGroupIdPtr.value === group.id && group.revenueProjections.map(projection => {
                                    return <TableRow key={projection.revCode.code} classes={{ root : classes.editRow }}>
                                        <TableCell align='left'>{projection.revenue.revCode}: {projection.revCode.description}</TableCell>
                                        <TableCell align='right'>{projection.revenue.units} </TableCell>
                                        <TableCell align='right'>{asDollars(projection.forecast[0].rate)} </TableCell>
                                        <TableCell align='right'>{asDollars(projection.summary[0].total)} </TableCell>
                                    </TableRow>
                                })}
                            </React.Fragment>
                        )
                    })}

                    <TableRow classes={{ root : classes.totalsRow}}>
                        <TableCell colSpan={3} align='right'>
                           Total:
                        </TableCell>
                        <TableCell align='right'>
                            { asDollars( selectedContractForCurrent.summary[0].total )}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const colors = {
    blue : {
        primary: "#CCE7F6",
        secondary: "#E1F4FA"
    },

    green : {
        primary: "#CDE8D7",
        secondary: "#D9F6E3"
    },

    sepia :{
        primary: "#C8B090",
        secondary: "#Fffaf5"
    }
}

const useTableStyles = makeStyles( _ => {
    return({
    table: {
        "& .MuiTableRow-root" : {
            height: 33,

            '& .MuiTableCell-root' : {
                textWrapMode: 'nowrap'
            }
        }
    },
    groupRow: {
        "& .MuiTableCell-root" : {
            fontWeight: 600,
            cursor : 'pointer'
        }
    },

    editRow : {
        "& .MuiTableCell-root" : {
            backgroundColor: colors.sepia.secondary,
        }
    },

    headerRow: {
        "& .MuiTableCell-root" : {
            fontWeight: 600,
            height: 40,
            backgroundColor: ( props : { color : string } ) => colors[props.color].primary
        }
    },

    totalsRow: {
        "& .MuiTableCell-root" : {
            fontWeight: 600,
            borderStyle: 'double',
            borderWidth: '4px 0 0 0',
            borderColor: 'gray',
        }
    },

    unchangedRow: {
        "& .MuiTableCell-root" : {
            color: 'grey',
            backgroundColor: colors.sepia.secondary,
        }
    }
})});

import ClearIcon from '@mui/icons-material/Clear';

export const Comparison = ({ periodPtr, index, selectedGroupIdPtr } :{
    periodPtr : PurePtr<YearlyRevenueProjection>,
    index : number
    selectedGroupIdPtr : PurePtr<string>
}) => {
    const classes = useTableStyles({ color: 'green' });
    
    const summary = periodPtr.value.summary[index];


    return (
        <TableContainer component={Paper}>
            <Table size="small" classes={{ root : classes.table }}>
                <TableHead>
                    <TableRow classes={{
                        root : classes.headerRow 
                    }}>
                        <TableCell style={{ minWidth: 60 }} align='left'>Code</TableCell>
                        <TableCell style={{ minWidth: 100 }} align='left'>
                            <UpdateValueHeader
                                label='Units'
                                periodPtr={periodPtr}
                                index={index}
                                column='units'
                            />
                        </TableCell>
                        <TableCell style={{ minWidth: 120 }} align='left'>
                            <UpdateValueHeader
                                label='Rate'
                                periodPtr={periodPtr}
                                index={index}
                                column='rate'
                            />
                        </TableCell>
                        <TableCell align='left'>Total</TableCell>
                        <TableCell align='left'>Variance</TableCell>
                        <TableCell align='left'>%</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {periodPtr.at('groups').map((groupPtr, gIndex) => {
                        const group = groupPtr.value,
                            summary = group.summary[index];
                            
                        return (
                            <React.Fragment key={group.id}>
                                <TableRow classes={{ root : classes.groupRow }}
                                    onClick={() => 
                                        selectedGroupIdPtr.set(
                                            selectedGroupIdPtr.value === group.id ? 
                                                null : 
                                                group.id 
                                        )
                                    }
                                >
                                    <TableCell colSpan={3} align='left'>
                                        { selectedGroupIdPtr.value === group.id ? 
                                            <ArrowDropDownIcon /> 
                                        : 
                                            <ArrowRightIcon /> 
                                        }
                                        {group.service}
                                    </TableCell>
                                    <TableCell align='right'>{asDollars(summary.total)} </TableCell>
                                    <TableCell align='right'>{asDollars(summary.variance)} </TableCell>
                                    <TableCell align='left'>{asPercentage(summary.increase)} </TableCell>
                                </TableRow>

                                {selectedGroupIdPtr.value === group.id && 
                                    groupPtr.at('revenueProjections')
                                        .map( projectionPtr => {
                                            const [ ratePtr, unitsPtr ] = projectionPtr
                                                                            .at('forecast').at(index)
                                                                            .pick('rate', 'units'),
                                                    { value } = projectionPtr,
                                                summary  = value.summary[index];

                                            return (
                                                <TableRow 
                                                    key={ value.revCode.code } 
                                                    classes={{ root : unitsPtr.isTruthy || ratePtr.isTruthy ? classes.editRow : classes.unchangedRow }}
                                                >
                                                    <TableCell align='left'>
                                                        <Tooltip title={value.revCode.description}>
                                                            <Box>{ value.revCode.code }</Box>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        <NumericInput nullable positive valuePtr={ unitsPtr }
                                                            placeholder={ projectionPtr.value.filledForecast[ index - 1 ].units }
                                                        />                               
                                                    </TableCell>

                                                    <TableCell className='py-0' align='right'>
                                                        <NumericInput nullable positive valuePtr={ ratePtr }
                                                            placeholder={asDollars(projectionPtr.value.filledForecast[ index - 1 ].rate)}
                                                        />
                                                    </TableCell>

                                                    <TableCell align='right'>{asDollars(summary.total)} </TableCell>
                                                    <TableCell align='right'>{asDollars(summary.variance)} </TableCell>
                                                    <TableCell align='left'>{asPercentage(summary.increase)} </TableCell>
                                                </TableRow>
                                            )
                                        })
                                }
                            </React.Fragment>
                        )
                    }

                    )}
                    <TableRow classes={{ root : classes.totalsRow }}>
                        <TableCell colSpan={3} align='right'>
                           Total:
                        </TableCell>
                        <TableCell align='right'>{ asDollars( summary.total )} </TableCell>
                        <TableCell align='right'>{ asDollars( summary.variance )}</TableCell>
                        <TableCell>{ asPercentage( summary.increase ) }</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const UpdateValueHeader = ({ label, periodPtr, index, column } :{
    label : string,
    periodPtr : PurePtr<YearlyRevenueProjection>,
    index : number,
    column : keyof Forecast
}) => {
    const anchorPtr = useStatePtr(null as HTMLElement);

    const percentPtr = useStatePtr(5);

    return (
        <Box justifyContent="space-between" display="flex" alignItems="center">
            { label }
            <IconButton size='small'
                onClick={ e => anchorPtr.set(e.currentTarget) }
            >
                <EditIcon fontSize='small'/>
            </IconButton>
            
            <Popper
                sx={{
                zIndex: 1,
                }}
                open={Boolean(anchorPtr.value)}
                anchorEl={anchorPtr.value}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                    transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper sx={{ padding: 1 }}>        
                    <ClickAwayListener onClickAway={ () => anchorPtr.set( null )}>
                        <Stack direction="column" spacing={1}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Box sx={{ width: 40}}>
                                    <NumericInput integer valuePtr={percentPtr} />
                                </Box>
                                
                                <Box>%</Box>

                                <ButtonGroup>
                                <Button size='small'
                                    onClick={ () => {
                                        periodPtr.at('groups').update( groups =>
                                            groups.map( group => group.modify( index, column, 1 + percentPtr.value / 100 ))
                                        );

                                        anchorPtr.set( null );
                                    }}
                                >
                                    More
                                </Button>
                                <Button size='small'
                                    onClick={ () => {
                                        periodPtr.at('groups').update( groups =>
                                            groups.map( group => group.modify( index, column, 1 - percentPtr.value / 100 ))
                                        );

                                        anchorPtr.set( null );
                                    }}
                                >
                                    Less
                                </Button>
                                </ButtonGroup>
                            </Stack>
                            <Stack direction="row-reverse" spacing={1}  alignItems="center">
                                <Button startIcon={<ClearIcon/>} size='small'
                                    onClick={ () => {
                                        periodPtr.at('groups').update( groups =>
                                            groups.map( group => group.clear( index, column ))
                                        );

                                        anchorPtr.set( null );
                                    }}
                                >
                                    Clear
                                </Button>
                            </Stack>
                        </Stack>
                    </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
        </Box>
    )
}