import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { createFloatingPoint } from '../../../util/format';
export default function DataTile({ title, value, color = undefined, icon = undefined, isTwoDecimal = true, cardPadding = undefined, textVariant  = undefined}) {
    return (
        <Card className='d-flex' variant='outlined'>
            <Box className={`bg-${color} d-flex align-items-center justify-content-center`}>
                <i style={{ fontSize: "18px" }} className={icon ? `${icon} px-5 text-white` : ''}></i>
            </Box>
            <Box>
                <CardContent style={ { padding:cardPadding? cardPadding:"10px" }}>
                    <Typography className={`kt-font-${color}`} gutterBottom>
                        <b>{title}</b>
                    </Typography>
                    <Typography className='text-left' variant={textVariant ?? 'h5'} component="div" >
                        {isTwoDecimal ? `$ ${createFloatingPoint(value)}` : value}
                    </Typography>
                </CardContent>
            </Box>
        </Card >
    );
}

DataTile.prototype = {
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
    icon: PropTypes.string,
    isTwoDecimal: PropTypes.bool
}