import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { claimBulkProcess, claimProcess, processBulkOnDemand, setProcessedDialog } from '../../../store/actions/claimAnalysis';
import ProcessClaimComponent from './ProcessClaimDialog';

export const ProcessClaimDialog = connect(
    () => {
        const selectPageRecord = createSelector(
            (state) => state.pages['claimAnalysis-landing'],
            (table) => table.page
        );

        const selectSignificance = createSelector(
            (state) => state.pages['claimAnalysis-landing'],
            (table) => {
                return table.significance;
            }
        );

        return (state, props) => ({
            claimPageRecord: selectPageRecord(state),
            significanceFlag: selectSignificance(state),

            claimProcessPending: state.claimAnalysis.claimprocess.pending,
            claimBulkProcessPending: state.claimAnalysis.claimBulkProcess.pending,
            claimBulkOnDemandPending: state.claimAnalysis.processBulkOnDemand.pending,
            claimBulkProcessError: state.claimAnalysis.claimBulkProcess.error,
            claimBulkProcessErrorMessage: state.claimAnalysis.claimBulkProcess.errorMessage,
            claimprocessError: state.claimAnalysis.claimprocess.error,
            claimprocessErrorMessage: state.claimAnalysis.claimprocess.errorMessage,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            claimAnalysisProcess: claimProcess,
            claimAnalysisBulkProcess: claimBulkProcess,
            processBulkOnDemand: processBulkOnDemand,
            setProcessedDialog: setProcessedDialog
        }, dispatch)
    })
)(injectIntl(ProcessClaimComponent));