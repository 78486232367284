import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { Loadable } from '../../../common';
import { LineChart } from '../../../common/widgets/LineChart';

const useStyles = makeStyles(theme => ({
    clearButton: {
        color: '#d9534f',
    },
}));

function ClaimHistory(props) {
    const {
        open, handleClose, claimHistory, lineChartData, lineChartLabels,
        actions: { setArchivedClaim } } = props;

    const classes = useStyles();

    useEffect(() => {

    }, []);

    const closeHistoryHandler = () => {
        handleClose();
    }

    const handlePointClick = (activeElements) => {
        if (activeElements && activeElements.length > 0) {
            // Disabling click on last index as it is current variance
            if (activeElements[0]._index < claimHistory.length) {
                const selectedClaim = claimHistory[activeElements[0]._index]
                setArchivedClaim(selectedClaim);

                // closing Claim History and Claim Drilldown
                closeHistoryHandler();
            }
        }
    }

    return (
        (<div>
            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="lg"
                open={open}
                onClose={closeHistoryHandler}
                aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            Claim History
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" onClick={closeHistoryHandler} size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Loadable loading={false}>
                        <Grid container justifyContent='center'>
                            <Grid item xs={8}>
                                <LineChart title="Claim Variance Over Time" data={{
                                    labels: lineChartLabels,
                                    datasets: [{
                                        label: '',
                                        data: lineChartData,
                                        fill: false,
                                        borderColor: 'rgb(10, 187, 135)',
                                        tension: 1,
                                        pointRadius: 5,
                                        pointHoverRadius: 11,
                                        backgroundColor: 'rgb(10, 187, 135)',
                                        lineTension: 0.2
                                    }]
                                }}
                                    handlePointClick={handlePointClick}
                                    lastIndex={claimHistory.length} // dsiable click on last index of the array as it is current variance
                                />
                            </Grid>
                        </Grid>
                    </Loadable>
                </DialogContent>
            </Dialog>
        </div>)
    );
}

export default ClaimHistory