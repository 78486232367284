import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    FormControlLabel,
    Switch,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';

import { Formik } from "formik";
import { Loadable } from '../../../common';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
    root: {
        flexGrow: 1,
    },
    checkboxlabel: {
        marginLeft: 0
    },
    switch: {
        margin: theme.spacing(1),
    },
    autocompleteWithAdd: {
        backgroundColor: "#0c4da2",
        fontStyle: 'italic'
    }
}));

export default function PayerPlanDropdown(props) {
    const {
        intl, payerPlansList, setDropDownFieldValue, defaultValue, payerPlanCreatePending, touched, errors,
        actions: { getPayerPlansTiny, createPayerPlan }
    } = props;
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState({
        planName: '',
        isMedicare: false
    });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getPayerPlansTiny();
    }, [getPayerPlansTiny]);

    function handleClose() {
        setOpen(false);
    }

    function handleCallback(reload, planName) {
        handleClose();
        getPayerPlansTiny(handleCallbackDropdown, planName);
    }

    function handleCallbackDropdown(payload, newPlan) {
        if(payload.some(x=> x.name === newPlan)) {
            payerPlansList.push(payload.find(x=> x.name === newPlan));
            setDropDownFieldValue("plan", payerPlansList.find(x=> x.name === newPlan));
        }
    }

    const filterOptions = createFilterOptions({
        stringify: option => option.name,
    });

    return (<>
        <Autocomplete
            onChange={(event, newValue) => {
                if (newValue && newValue.inputValue) {
                    setOpen(true);
                    setInitialValues({
                        planName: newValue.inputValue,
                        isMedicare: false,
                    });
                } else {
                    setDropDownFieldValue("plan", newValue);
                }
            }}
            filterOptions={(options, params) => {
                
                const filtered = filterOptions(options, params);
                if (params.inputValue !== '' && !payerPlansList.some(x=> x.name === params.inputValue)) {
                    filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`,
                        id: 0
                    });
                }
                
                return filtered;
            }}
            value={defaultValue}
            name={`plan`}
            options={payerPlansList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Plan"
                    required
                    helperText={
                        touched.plan &&
                        errors.plan
                    }
                    error={Boolean(
                        touched.plan &&
                            errors.plan
                    )}
                />
            )}
            isOptionEqualToValue={(
                option,
                value
            ) => option === value}
        />
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{'Add Payer Plan'}</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={payerPlanCreatePending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};
                            if (!values.planName) {
                                errors.name = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            let payload = {
                                name: values.planName,
                                isMedicare: values.isMedicare
                            }
                            
                            createPayerPlan(payload, handleCallback);
                            
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                <div className={classes.root}>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                required
                                                label="Plan Name"
                                                name="planName"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.planName}
                                                helperText={touched.planName && errors.planName}
                                                error={Boolean(touched.planName && errors.planName)}
                                            />
                                        </Grid>
                                        <Grid className="mt-3" item xs={8}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="isMedicare"
                                                        color="primary"
                                                        checked={values.isMedicare}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="ml-0"
                                                        value={values.isMedicare}
                                                    />
                                                }
                                                label="Medicare"
                                            />
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={2}>
                                            <Button onClick={handleClose} variant="contained" className={classes.button}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                type="submit"
                                                disabled={payerPlanCreatePending}
                                            >
                                                {payerPlanCreatePending ? 'Saving' : 'Save'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
    </>);
}