import React, { useState } from "react";
import { FieldArray } from "formik";

import {
    Button,
    FormControl,
    InputLabel,
    Select,
    Input,
    TextField,
    MenuItem,
    FormHelperText,
    Grid,
    Paper,
    IconButton,
    InputAdornment,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { dateEquators, integerEquators, StringEquators } from "../../../store/constants/pages";
import { Autocomplete } from '@mui/material';
import { RULE_CRITERIA_DATE, RULE_CRITERIA_INT, RULE_CRITERIA_STRING } from "../../../store/constants/contract";
import { PopoverLink } from '../../../common/popoverLink'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    portlet: {
        'box-shadow': 'none',
    },
    formControl: {
        width: '85%',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    autoComplete: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    iconButton: {
        marginTop: theme.spacing(3),
    },
    clearButton: {
        marginTop: theme.spacing(3),
        marginLeft: '10px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
}));

export default function RuleSelectionCriteriaFieldArray({
    values, handleBlur, handleChange, touched, errors, ruleCriteria, setFieldValue
}) {

    const classes = useStyles();

    function getHelperText(key, index) {
        if (touched.ruleSelectionCriteriaXref) {
            if (touched.ruleSelectionCriteriaXref[index]) {
                return touched.ruleSelectionCriteriaXref[index][key];
            }
            return false;
        }
        return false;
    }

    function getError(key, index) {
        if (errors.ruleSelectionCriteriaXref) {
            if (errors.ruleSelectionCriteriaXref[index]) {
                return errors.ruleSelectionCriteriaXref[index][key];
            }
            return false;
        }
        return false;
    }

    function handleCriteriaChangeHandler(index, criteriaObj) {
        setFieldValue(`ruleSelectionCriteriaXref.${index}.ruleSelectionCriteriaId`, criteriaObj);
        setFieldValue(`ruleSelectionCriteriaXref.${index}.type`, criteriaObj?.fieldType); // criteria Type i.e. INT, DATE, STRING
        setFieldValue(`ruleSelectionCriteriaXref.${index}.equator`, '');
        setFieldValue(`ruleSelectionCriteriaXref.${index}.value`, '');
    }

    function getEquators(fieldType) {
        switch (fieldType) {
            case RULE_CRITERIA_STRING:
                return StringEquators.equatorArr
            case RULE_CRITERIA_DATE:
                return dateEquators.equatorArr
            case RULE_CRITERIA_INT:
                return integerEquators.equatorArr
            default:
                return StringEquators.equatorArr
        }
    }

    function handleClearFields() {
        setFieldValue(`ruleSelectionCriteriaXref`, [{
            ruleSelectionCriteriaId: null,
            equator: '',
            value: ''
        }]);
    }

    function hoverColumn(text) {
        return (
            <PopoverLink
                columnData={<i className={`fas fa-question-circle text-info`}></i>}
                hoverData={[
                    {
                        data: text
                    }
                ]}
            />
        );
    }

    return (
        (<FieldArray
                name="ruleSelectionCriteriaXref"
                render={({ remove, insert }) => (
                    <Grid container>
                        <Grid item xs={12}>
                            {values.ruleSelectionCriteriaXref.map((selectionCriteria, index) =>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            className={classes.autoComplete}
                                            value={selectionCriteria.ruleSelectionCriteriaId}
                                            disableClearable={false}
                                            onChange={(e, value) => { handleCriteriaChangeHandler(index, value) }}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            //name={`ruleSelectionCriteriaXref.${index}.ruleSelectionCriteriaId`}
                                            options={ruleCriteria}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    name={`ruleSelectionCriteriaXref.${index}.ruleSelectionCriteriaId`}
                                                    label="Criteria"
                                                    margin="normal" />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor={`ruleSelectionCriteriaXref.${index}.equator`}>Equator</InputLabel>
                                            <Select
                                                required
                                                value={selectionCriteria?.equator}
                                                onChange={handleChange}
                                                disabled={!selectionCriteria?.ruleSelectionCriteriaId}
                                                input={<Input id={`ruleSelectionCriteriaXref.${index}.equator`} />}
                                                inputProps={{
                                                    name: `ruleSelectionCriteriaXref.${index}.equator`,
                                                    id: `ruleSelectionCriteriaXref.${index}.equator`,
                                                }}
                                                error={Boolean(getHelperText('equator', index) && getError('equator', index))}
                                            >
                                                {getEquators(selectionCriteria?.type).map(equator => (
                                                    <MenuItem
                                                        key={equator.value}
                                                        value={equator.value}
                                                    >
                                                        {equator.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText error>{getHelperText('equator', index) && getError('equator', index)}</FormHelperText>

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Value"
                                            name={`ruleSelectionCriteriaXref.${index}.value`}
                                            className={classes.textField}
                                            margin="normal"
                                            disabled={selectionCriteria?.equator === ''}
                                            type={selectionCriteria?.type === RULE_CRITERIA_INT ? 'number' : selectionCriteria.type === RULE_CRITERIA_DATE ? 'date' : 'text'}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={selectionCriteria?.value}
                                            InputLabelProps={selectionCriteria.type === RULE_CRITERIA_DATE ?
                                                {
                                                    shrink: true,
                                                } : {

                                                }
                                            }
                                            helperText={getHelperText('value', index) && getError('value', index)}
                                            error={Boolean(getHelperText('value', index) && getError('value', index))}

                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <span>
                                                        {hoverColumn(selectionCriteria?.type === RULE_CRITERIA_INT ?
                                                            'Only single numeric value is allowed' :
                                                            'Add single string value or use comma separation for multiple values'
                                                        )}
                                                    </span>
                                                </InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={3}>
                                        <Grid container>
                                        {(index >= 0 && values.ruleSelectionCriteriaXref.length > 1) && (
                                            <IconButton
                                                edge="start"
                                                aria-label="Delete"
                                                onClick={() => remove(index)}
                                                className={classes.iconButton}
                                                size="large">
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                        {index === 0 && (
                                            <>
                                            <IconButton
                                                edge="start"
                                                color="primary"
                                                aria-label="Add"
                                                onClick={() => insert(values.ruleSelectionCriteriaXref.length, {
                                                    ruleSelectionCriteriaId: null,
                                                    equator: '',
                                                    value: ''
                                                })}
                                                className={classes.iconButton}
                                                size="large">
                                                <AddCircleIcon />
                                            </IconButton>
                                            {/* <Button
                                                size="small"
                                                onClick={handleClearFields}
                                                className={classes.clearButton}
                                                startIcon={<DeleteIcon />}
                                            >
                                                Clear All
                                            </Button> */}
                                            </>
                                        )}
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            />)
    );
}
