import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

export default function TextMaskCustom(props) {
  const { inputRef, mask, ...other } = props;

  return (
    (<MaskedInput
      {...other}
      ref={(ref) => {
        if (ref) {
          if (typeof inputRef === 'function') {
            inputRef(ref.inputElement);
          } else if (inputRef?.current !== undefined) {
            inputRef.current = ref.inputElement;
          }
        }
      }}
      mask={mask ?? ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />)
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};