import React, { useEffect } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Loadable } from '../../../../common';
import { alphaNumericPattern } from '../../../../util/validation';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import { Button, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { BackButton } from "../../../../common/BackButton";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textArea: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function DashboardDataSourcesEditPage(props) {
  const {
    intl, initialValues, dashboardDataSourcesGetPending, dashboardDataSourceId,
    dashboardDataSourcesCreatePending, dashboardDataSourcesUpdatePending, mode,
    actions: { dashboardDataSourceGet, dashboardDataSourceCreate, dashboardDataSourceUpdate, dashboardDataSourceErrorReset } } = props;

  const classes = useStyles();
  const isEdit = mode === 'edit';

  const handleCallback = () => {
    props.history.push('/admin/dashboardDataSources?persist=true');
  }

  useEffect(() => {
    if (mode === 'edit') {
      dashboardDataSourceGet(dashboardDataSourceId);
    }
  }, [mode, dashboardDataSourceGet, dashboardDataSourceId]);

  useEffect(() => {
    return function cleanup() {
      dashboardDataSourceErrorReset();
    }
  }, [dashboardDataSourceErrorReset]);

  return (
    <Loadable loading={dashboardDataSourcesGetPending || dashboardDataSourcesCreatePending || dashboardDataSourcesUpdatePending}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {};

          if (!values.name) {
            errors.name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (!values.name.match(alphaNumericPattern)) {
            errors.name = "Only alpha numeric values are allowed";
          }

          if (!values.viewName) {
            errors.viewName = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          let payload = {
            name: values.name,
            viewName: values.viewName,
            description: values.description,
          }
          if (mode === 'create') {
            dashboardDataSourceCreate(payload, handleCallback);
          }
          else {
            payload = {
              ...payload,
              id: values.id,
            }
            dashboardDataSourceUpdate(values.id, payload, handleCallback);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          dirty
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
            <Portlet>
              <PortletHeader
                title={isEdit ? 'Edit DataSource' : 'Add new DataSource'}
                toolbar={
                  <PortletHeaderToolbar>
                    <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/admin/dashboardDataSources?persist=true" />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      type="submit"
                      disabled={dashboardDataSourcesCreatePending || dashboardDataSourcesUpdatePending || (isEdit && !dirty)}
                    >
                      {(dashboardDataSourcesCreatePending || dashboardDataSourcesUpdatePending) ? 'Saving Changes' : 'Save Changes'}
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className={classes.container}>
                  <div className="row">
                    <div className="col-md-6">
                      <TextField
                        required
                        label="Name"
                        name="name"
                        className={classes.textField}
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        helperText={touched.name && errors.name}
                        error={Boolean(touched.name && errors.name)}
                      />
                      <TextField
                        required
                        label="View Name"
                        name="viewName"
                        className={classes.textField}
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.viewName}
                        helperText={touched.viewName && errors.viewName}
                        error={Boolean(touched.viewName && errors.viewName)}
                      />
                      <TextField
                        label="Description"
                        name="description"
                        className={classes.textArea}
                        multiline
                        rows="4"
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        helperText={touched.description && errors.description}
                        error={Boolean(touched.description && errors.description)}
                      />
                    </div>
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </form>
        )}
      </Formik>
    </Loadable>
  );
}
