import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Select,
    InputLabel,
    FormHelperText,
    MenuItem,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Loadable } from "../../../common";

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "85%",
    },
    formControlAuto: {
        marginTop: theme.spacing(5),
        width: "85%",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dropZone: {
        minHeight: 150,
        border: "none",
        backgroundColor: "#EAEAEA",
        borderRadius: "20px",
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    contractSelection: {
        "& .MuiAutocomplete-endAdornment": { display: "none" },
    },
    selectionAutocomplete: {
        cursor: "pointer",
        width: "100%",
    },
    selectionIcon: {
        width: "100%",
        margin: "0 !important",
        padding: "0 !important",
    },
    selectionIconButton: {
        width: "100%",
        "&:hover": {
            background: "none !important",
        },

        "& .MuiIconButton-label": {
            width: "100% !important",
            display: "block",
        },
    },

    contractsChip: {
        margin: "0 3px 5px 0",
    },
}));

function EditDrgDialog(props) {
    const {
        intl,
        open,
        setEditDrgDialog,
        handleRefresh,
        pending,
        initialValues,
        facilities,
        actions: { updateHospitalServices },
    } = props;
    const classes = useStyles();

    const handleClose = () => {
        setEditDrgDialog(null);
    };

    const updateCallback = () => {
        handleRefresh();
        handleClose();
    };

    useEffect(() => {
        return ()=> {
            setEditDrgDialog(null);
        };
    },[]);

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">Edit DRG</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={pending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={(values) => {
                            const errors: any = {};

                            if (!values.facilityId) {
                                errors.facilityId = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { }) => {
                            const payload = {
                                id: values.id,
                                facilityId: values?.facilityId,
                            };

                            updateHospitalServices(payload, updateCallback);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Drg"
                                            className={classes.textField}
                                            margin="normal"
                                            value={values.drg}
                                            disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            label="Type"
                                            className={classes.textField}
                                            margin="normal"
                                            value={values.type}
                                            disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            label="Title"
                                            className={classes.textField}
                                            margin="normal"
                                            value={values.title}
                                            disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            label="Weights"
                                            className={classes.textField}
                                            margin="normal"
                                            value={values.weights}
                                            disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl
                                            className={classes.formControl}
                                        >
                                            <InputLabel htmlFor="role">
                                                Facility*
                                            </InputLabel>
                                            <Select
                                                required
                                                value={values.facilityId}
                                                onChange={handleChange}
                                                error={Boolean(
                                                    touched.facilityId &&
                                                        errors.facilityId
                                                )}
                                                inputProps={{
                                                    name: "facilityId",
                                                    id: "facilityId",
                                                }}
                                            >
                                                {facilities.map(
                                                    ({ id, name,active }) => (
                                                        <MenuItem
                                                            key={name}
                                                            value={id}
                                                            disabled={!active}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                            <FormHelperText error>
                                                {touched.facilityId &&
                                                    errors.facilityId}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid
                                        container
                                        className="justify-content-end pt-2"
                                    >
                                        <Button
                                            type="button"
                                            color="inherit"
                                            onClick={handleClose}
                                            variant="contained"
                                            className="mr-3"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
    );
}

export default EditDrgDialog;
