/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { Route, RouteComponentProps, withRouter } from "react-router-dom";
import { LayoutContextConsumer } from "../LayoutContext";

interface SubHeaderProps extends RouteComponentProps {
  htmlClassService: {
    classes: {
      subheader: string;
      subheader_container: string;
    };
  };
  subheaderMobileToggle?: boolean;
}

class SubHeader extends React.Component<SubHeaderProps> {
  subheaderCssClasses = this.props.htmlClassService.classes.subheader;
  subheaderContainerCssClasses = this.props.htmlClassService.classes
    .subheader_container;

  render() {
    return (
      <div
        id="kt_subheader"
        className={`kt-subheader ${this.subheaderCssClasses} kt-grid__item`}
      >
        <div className={`kt-container ${this.subheaderContainerCssClasses}`}>
          <div className="kt-subheader__main">
            {this.props.subheaderMobileToggle && (
              <button
                className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}

            <LayoutContextConsumer>
              {({ subheader: { title, description } }) => (
                <>
                  <h3 className="kt-subheader__title">{title}</h3>
                  {(description) && (
                    <>
                      <span className="kt-subheader__separator kt-subheader__separator--v" />
                      <span className="kt-subheader__desc">{description}</span>
                    </>
                  )}
                </>
              )}
            </LayoutContextConsumer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    "subheader.mobile-toggle"
  )
});

export default withRouter(connect(mapStateToProps)(SubHeader));
