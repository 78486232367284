import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../../../partials/content/Portlet';
import { Loadable } from '../../../../common';
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SummaryTile } from '../../../../common/widgets/SummaryTile';
import { Card } from 'react-bootstrap';
import { BarChart } from '../../../../common/widgets/BarChart';
import { FilesGrid } from '../FilesGrid';

const useStyles = makeStyles(theme => ({
    filterFormControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        maxWidth: 150,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 300,
    },
}));

function TrimStatsPage(props) {
    const classes = useStyles();
    const { getStatsPending, stats, dataset, filters, clients, actions: { setStatisticsFilter, statisticsGet, resetStats } } = props;


    useEffect(() => {
        statisticsGet(filters);
        return () => {
            resetStats();
        }
    }, [filters, statisticsGet]);

    const handleFilterChange = (e) => {
        resetStats();
        setStatisticsFilter({
            ...filters,
            [e.target.name]: e.target.value
        });
    }

    const customTooltipData = (tooltipItems) => {
        if (tooltipItems) {
            const yIndex = tooltipItems[0].datasetIndex;
            const xIndex = tooltipItems[0].index;
            const otherInfo = dataset?.datasets[yIndex]?.data[xIndex]?.otherInfo;
            return [
                ['Total Claim Payments', `$${otherInfo?.totalClaimPayments}`, 0],
                ['Total Claim Service Payments', `$${otherInfo?.totalClaimServicePayments}`, 1],
                ['Total Institutional Charges', `$${otherInfo?.totalInstitutionalCharges}`, 2],
                ['Total Professional Charges', `$${otherInfo?.totalProfessionalCharges}`, 3],
                ['Total Provider Adjustments', `$${otherInfo?.totalProviderAdjustments}`, 4],
            ]
        }
        return false
    };

    return (
        <>
            <Helmet title="Statistics" />
            <Portlet fit="true">
                <Loadable loading={getStatsPending}>
                    <PortletHeader
                        title="Client Statistics"
                        toolbar={
                            <PortletHeaderToolbar>
                                <Grid container>
                                    <Grid>
                                        <FormControl className={classes.filterFormControl}>
                                            <InputLabel>Frequency</InputLabel>
                                            <Select
                                                value={filters.frequency}
                                                onChange={handleFilterChange}
                                                name="frequency"
                                            >
                                                <MenuItem value="daily">Daily</MenuItem>
                                                <MenuItem value="weekly">Weekly</MenuItem>
                                                <MenuItem value="monthly">Monthly</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid>
                                        <FormControl className={classes.filterFormControl}>
                                            <InputLabel>State</InputLabel>
                                            <Select
                                                value={filters.state}
                                                onChange={handleFilterChange}
                                                name="state"
                                            >
                                                <MenuItem value="all">All</MenuItem>
                                                <MenuItem value="duplicates">Duplicate</MenuItem>
                                                <MenuItem value="completed">Completed</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid>
                                        <FormControl className={classes.filterFormControl}>
                                            <InputLabel>Client</InputLabel>
                                            <Select
                                                value={filters.clientId}
                                                onChange={handleFilterChange}
                                                name="clientId"
                                            >
                                                <MenuItem value="all">All</MenuItem>
                                                {clients.map(client => <MenuItem key={client.clientId} value={client.clientId}>{client.name}</MenuItem>)}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </PortletHeaderToolbar>
                        }
                    />
                    <PortletBody fit="true">
                        <Grid container className='p-4'>
                            <Grid md={12}>
                                <Card className='d-flex p-3' variant='outlined'>
                                    <Grid container spacing={2}>
                                        <Grid item sm={4} className='pb-0'>
                                            <SummaryTile title="Total Files Uploaded" value={stats?.totalFilesUploaded} color="warning" textVariant='h6' cardPadding='5px 10px' isCurrency={false} />
                                        </Grid>
                                        <Grid item sm={4} className='pb-0'>
                                            <SummaryTile title="Total Institutional Charges" value={stats?.totalInstitutionalCharges} color="success" textVariant='h6' cardPadding='5px 10px' />
                                        </Grid>
                                        <Grid item sm={4} className='pb-0'>
                                            <SummaryTile title="Total Professional Charges" value={stats?.totalProfessionalCharges} color="success" textVariant='h6' cardPadding='5px 10px' />
                                        </Grid>
                                        <Grid item sm={4} className='pt-0 mt-2'>
                                            <SummaryTile title="Total Claim Payments" value={stats?.totalClaimPayments} color="info" textVariant='h6' cardPadding='0px 10px' />
                                        </Grid>
                                        <Grid item sm={4} className='pt-0 mt-2'>
                                            <SummaryTile title="Total Claim Service Payments" value={stats?.totalClaimServicePayments} color="info" textVariant='h6' cardPadding='0px 10px' />
                                        </Grid>
                                        <Grid item sm={4} className='pt-0 mt-2'>
                                            <SummaryTile title="Total Provider Adjustments" value={stats?.totalProviderAdjustments} color="danger" textVariant='h6' cardPadding='0px 10px' />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid md={12}>
                                <Portlet fluid="true" fit="true">
                                    <BarChart
                                        loading={false}
                                        height='400px'
                                        options={
                                            {
                                                type: "bar",
                                                options: {
                                                    title: { display: false },
                                                    tooltips: {
                                                        // Disable the on-canvas tooltip
                                                        enabled: false,

                                                        custom: function (tooltipModel, b) {
                                                            // Tooltip Element
                                                            var tooltipEl = document.getElementById('chartjs-tooltip');

                                                            // Create element on first render
                                                            if (!tooltipEl) {
                                                                tooltipEl = document.createElement('div');
                                                                tooltipEl.id = 'chartjs-tooltip';
                                                                tooltipEl.innerHTML = '<table></table>';
                                                                tooltipEl.style.backgroundColor = "#000";
                                                                tooltipEl.style.opacity = 0.5;
                                                                tooltipEl.style.borderColor = "#333333";
                                                                tooltipEl.style.borderRadius = "10px";
                                                                tooltipEl.style.minWidth = "200px";
                                                                // tooltipEl.style.color = "#ffffff";
                                                                tooltipEl.style.textAlign = "left";
                                                                tooltipEl.style.borderWidth = "2px";
                                                                tooltipEl.style.borderStyle = "solid";
                                                                document.body.appendChild(tooltipEl);
                                                            }

                                                            // Hide if no tooltip
                                                            if (tooltipModel.opacity === 0) {
                                                                tooltipEl.style.opacity = 0;
                                                                return;
                                                            }

                                                            // Set caret Position
                                                            tooltipEl.classList.remove('above', 'below', 'no-transform');
                                                            if (tooltipModel.yAlign) {
                                                                tooltipEl.classList.add(tooltipModel.yAlign);
                                                            } else {
                                                                tooltipEl.classList.add('no-transform');
                                                            }

                                                            function getBody(bodyItem) {
                                                                return bodyItem.lines;
                                                            }

                                                            // Set Text
                                                            if (tooltipModel.body) {
                                                                var titleLines = tooltipModel.title || [];
                                                                var bodyLines = tooltipModel.body.map(getBody);

                                                                var innerHtml = '<thead>';

                                                                titleLines.forEach(function (title) {
                                                                    innerHtml += `<tr><th class="text-white font-weight-600">` + title + '</th></tr>';
                                                                });

                                                                innerHtml += '</thead><tbody>';

                                                                bodyLines.forEach(function (body, i) {
                                                                    var colors = tooltipModel.labelColors[i];
                                                                    var style = 'backgroundColor:' + colors.backgroundColor;
                                                                    style += '; border-color:' + colors.borderColor;
                                                                    style += '; border-width: 2px';
                                                                    var span = '<span style="' + style + '"></span>';
                                                                    innerHtml += `<tr><td style="color:${tooltipModel.labelColors[0]?.backgroundColor}" class="font-14 font-weight-600">` + span + body + '<hr/ class="tootlip-hr m- p-0 my-2">' + '</td></tr>';
                                                                });


                                                                const subItems = customTooltipData(tooltipModel.dataPoints);
                                                                if (subItems && subItems.length) {
                                                                    for (let arrItem of subItems) {
                                                                        innerHtml += `<tr><th class="pt-2 font-weight-normal tooltip-stats-color-${arrItem[2]}">` + arrItem[0] + ': ' + arrItem[1] + '</th></tr>';
                                                                    }
                                                                }

                                                                innerHtml += '</tbody>';

                                                                var tableRoot = tooltipEl.querySelector('table');
                                                                tableRoot.innerHTML = innerHtml;
                                                            }

                                                            // `this` will be the overall tooltip
                                                            var position = this._chart.canvas.getBoundingClientRect();

                                                            // Display, position, and set styles for font
                                                            tooltipEl.style.opacity = 1;
                                                            tooltipEl.style.position = 'absolute';
                                                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                                                            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                                                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                                                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                                                            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                                                            tooltipEl.style.pointerEvents = 'none';
                                                        },
                                                    },
                                                    legend: { display: false },
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                    barRadius: 4,
                                                    scales: {
                                                        xAxes: [{ stacked: true }],
                                                        yAxes: [{ stacked: true, }]
                                                    },
                                                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 25 } }
                                                }
                                            }
                                        }
                                        data={dataset}

                                        title="Stats"
                                        desc=""
                                    />
                                </Portlet>
                            </Grid>
                            <Grid md={12}>
                                <FilesGrid />
                            </Grid>
                        </Grid>

                    </PortletBody>
                </Loadable>
            </Portlet>
        </>
    )
}

export default TrimStatsPage
