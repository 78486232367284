import React from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { toAbsoluteUrl } from "../../../../../_metronic";
import { Grid, Paper } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    grid: {
        margin: '0 auto',
        padding: '6% 2rem 1rem'
    }
}));


export default function ForgotPasswordConfirmation(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container maxwidth="sm">
                <Grid className={classes.grid} item xs={6}>
                    <Paper className={classes.paper}>
                        <div className="kt-login__logo">
                            <a href="/#">
                                <img alt="logo" src={toAbsoluteUrl("/media/logos/logo-mini-md.v3.png?v=4")} />
                            </a>
                        </div>
                        <div className="kt-login__head">
                            <div className="kt-login__title py-4">
                                <h3>
                                    {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
                                    <FormattedMessage id="AUTH.GENERAL.CONFIRMATION" />
                                </h3>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div role="alert" className="alert alert-success">
                                    <div className="alert-text">An email for reset password has been sent to your email address. Please check your email.</div>
                                </div>
                                <Link
                                    to="/auth/login"
                                    className="kt-link"
                                >
                                    Back to Login
                                </Link>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}