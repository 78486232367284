import React, { useState } from 'react';
import { SketchPicker } from 'react-color'
import { Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({

    root: {
        marginTop: theme.spacing(1),
    },
    swatch: {
        background: '#fff',
        borderRadius: '1px',
        cursor: 'pointer',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        padding: '5px',
        display: 'inline-block'
    },
    color: {
        width: '36px',
        height: '14px',
    },
}));

export default function ColorPicker(props) {

    const classes = useStyles();
    const { value, onChange, tooltip } = props;
    const [displayPicker, setDisplayPicker] = useState(false);

    function handleClick() {
        setDisplayPicker(!displayPicker);
    }

    function handleColorChange(color, event) {
        if (color) {
            onChange(color.hex);
        }
    }

    function handleChangeComplete() {
        setDisplayPicker(false);
    }

    return (
        <div className={classes.root}>
            <Tooltip title={tooltip} placement="right">
                <div className={classes.swatch} onClick={handleClick}>
                    <div className={classes.color} style={{ backgroundColor: value }} onClick={handleClick} />
                </div>
            </Tooltip>
            {displayPicker && (
                <SketchPicker
                    color={value}
                    disableAlpha={true}
                    onChange={(color) => handleColorChange(color)}
                    onChangeComplete={handleChangeComplete}
                />
            )}
        </div>
    );
}