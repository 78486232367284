import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/claimStatus';
import * as API from '../api/claimStatus';
import { sessionErrorHandling } from './session';

function* list() {
    try {
        yield put({ type: actions.CLAIM_STATUS_LANDING_PENDING });
        const payload = yield call(API.getClaimStatusList);
        yield put({ type: actions.CLAIM_STATUS_LANDING_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_STATUS_LANDING_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* create(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.CLAIM_STATUS_CREATE_PENDING });
        const payload = yield call(API.createClaimStatus, values);
        yield put({ type: actions.CLAIM_STATUS_CREATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_STATUS_CREATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* update(action) {
    try {
        const { values, callback, shouldReloadGrid } = action.payload;
        yield put({ type: actions.CLAIM_STATUS_UPDATE_PENDING });
        const payload = yield call(API.editClaimStatus, values);
        yield put({ type: actions.CLAIM_STATUS_UPDATE_FULFILLED, payload });
        if (callback) {
            callback(shouldReloadGrid);
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_STATUS_UPDATE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getClaimStatus(action) {
    try {
        const { claimStatusId } = action.payload;
        yield put({ type: actions.CLAIM_STATUS_GET_PENDING });
        const payload = yield call(API.getClaimStatus, claimStatusId);
        yield put({ type: actions.CLAIM_STATUS_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_STATUS_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* changePrecedence(action) {
    try {
        yield put({ type: actions.CLAIM_STATUS_CHANGE_PRECEDENCE_PENDING });
        const { options, callback } = action.payload;
        const payload = yield call(API.setPrecedence, options);
        yield put({ type: actions.CLAIM_STATUS_CHANGE_PRECEDENCE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_STATUS_CHANGE_PRECEDENCE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getDependencies(action) {
    try {
        const { id } = action.payload;
        yield put({ type: actions.CLAIM_STATUS_DELETE_DEPENDENCIES_PENDING });
        const payload = yield call(API.getClaimStatusDependency, id);
        yield put({ type: actions.CLAIM_STATUS_DELETE_DEPENDENCIES_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_STATUS_DELETE_DEPENDENCIES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteClaimStatus(action) {
    try {
        const { values, callback } = action.payload;
        yield put({ type: actions.CLAIM_STATUS_DELETE_PENDING });
        const payload = yield call(API.deleteClaimStatus, values);
        yield put({ type: actions.CLAIM_STATUS_DELETE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_STATUS_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.CLAIM_STATUS_LANDING, list);
    yield takeEvery(actions.CLAIM_STATUS_CREATE, create);
    yield takeEvery(actions.CLAIM_STATUS_UPDATE, update);
    yield takeEvery(actions.CLAIM_STATUS_GET, getClaimStatus);
    yield takeEvery(actions.CLAIM_STATUS_CHANGE_PRECEDENCE, changePrecedence);
    yield takeEvery(actions.CLAIM_STATUS_DELETE_DEPENDENCIES, getDependencies);
    yield takeEvery(actions.CLAIM_STATUS_DELETE, deleteClaimStatus);
    
}