import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { set } from '../../../../store/actions/pages';
import { dateEquators } from '../../../../store/constants/pages';
import { selectProcessedClaims } from '../../../../store/selectors/claimAnalysis';
import { createCurrencyDataField, createDateField, createPlainDateField, createStatusField, createStringDataField } from '../../../../util/format';
import { createFilteredSortedDataSelector } from '../../../../util/selector';
import ProcessedClaimsDialogComponent from './ProcessedClaimsDialog';

export const ProcessedClaimsDialog = connect(
    () => {
        const DEFAULT_SORT = [{ id: 'claimNumber', orderBy: 'asc' }];

        const selectFields = createSelector(
            () => [
                createStringDataField('claimNumber', 'Claim Number', { sortable: true }),
                createStringDataField('visitType', 'Visit', { sortable: true }),
                createStringDataField('contractName', 'Contract', { sortable: true }),
                createStringDataField('payerName', 'Payer', { sortable: true }),
                createCurrencyDataField('chargeAmount', 'Charged Amount', { sortable: true, calculateTotal: true }),
                createCurrencyDataField('paidAmount', 'Allowable', { sortable: true, calculateTotal: true }),
                createDateField('creationDate', 'Created At', { sortable: true, equators: dateEquators }),
                createPlainDateField('serviceDate', 'Service Date', { sortable: true, equators: dateEquators }),
                createStatusField('processingState', 'Status', { returnWholeRow: true, })
            ]
        );
        const selectSort = createSelector(
            (state, props) => state.pages[`processed-claims-landing`].sort,
            (sort) => sort || DEFAULT_SORT,
        );

        const selectFilteredData = createFilteredSortedDataSelector(
            selectFields as any, // TODO: error! It cn't work!
            (state, props) => state.pages[`processed-claims-landing`].filter,
            selectSort,
            selectProcessedClaims,
        );

        return (state, props) => ({
            fields: selectFields(state as never),
            filteredData: selectFilteredData(state),
            order: state.pages[`processed-claims-landing`].order,
            filter: state.pages[`processed-claims-landing`].filter,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setOrder: (value) => set(`processed-claims-landing`, 'order', value),
            setSort: (value) => set(`processed-claims-landing`, 'sort', value),
        }, dispatch)
    })
)(injectIntl(ProcessedClaimsDialogComponent));