import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { set } from '../../../store/actions/pages';
import SettingsComponent from './SettingsPage';
import { selectSettings } from '../../../store/selectors/settings';
import { list } from '../../../store/actions/settings';
import { selectCanAccessCMS } from '../../../store/selectors/access';
import { selectHasEstimatorAccess } from '../../../store/selectors/session';
import { selectCanAccessPricer } from '../../../store/selectors/access/pricer';
import { has } from 'lodash';

const selectFilteredData = createSelector(
    (state, props) => state.pages[`settings-landing`].filter,
    selectSettings,
    selectCanAccessCMS,
    selectHasEstimatorAccess,
    selectCanAccessPricer,
    (filter, settings, hasCMSAccess, hasEstimatorAccess, hasPricerAccess) => {
        let tempSettings = [...settings];
        if (!hasCMSAccess) {
            tempSettings = tempSettings.filter(setting => setting.productTypes !== 'Contract Management');
        }
        if (!hasEstimatorAccess && !hasPricerAccess) {
            tempSettings = tempSettings.filter(setting => setting.productTypes !== 'Pricer, Estimator');
        }
        if (filter === 'All') {
            return tempSettings
        }
        return tempSettings.filter(setting => setting.productTypes.includes(filter))
    }
);

const selectSearchOptions = createSelector(
    selectSettings,
    selectCanAccessCMS,
    selectHasEstimatorAccess,
    selectCanAccessPricer,
    (searches, hasCMSAccess, hasEstimatorAccess, hasPricerAccess) => {
        let uniqueNames = [...new Set(searches.map(data => data.productTypes.trim()))];
        let tempnames = [...uniqueNames];
        if (!hasCMSAccess) {
            tempnames = tempnames.filter(name => name !== 'Contract Management');
        }


        // splitting modules names if they are comma separated
        let concatinatedNames = [];
        for (let item of tempnames) {
            if (item.includes(',')) {
                let splitNames = item.split(',');
                for (let sn of splitNames) {
                    if (sn.toString().trim() === 'Pricer' && hasPricerAccess && !concatinatedNames.some(x=> x.id.trim() === 'Pricer')) {
                        concatinatedNames.push({ id: sn.trim(), name: sn.trim() });
                    }
                    if (sn.toString().trim() === 'Estimator' && hasEstimatorAccess && !concatinatedNames.some(x=> x.id.trim() === 'Estimator')) {
                        concatinatedNames.push({ id: sn.trim(), name: sn.trim() });
                    }
                }
            } else if(!concatinatedNames.some(x=> x.id.trim() === item.trim())) {
                concatinatedNames.push({ id: item.trim(), name: item.trim() });
            }

        }
        return concatinatedNames
    }
);

export const SettingsPage = connect(
    () => {
        return (state, props) => ({
            settingPending: state.settings.get.pending,
            filteredData: selectFilteredData(state, props),

            filter: state.pages[`settings-landing`].filter,
            searchOptions: selectSearchOptions(state),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getSettings: list,
            setFilter: (value) => set(`settings-landing`, 'filter', value),
            setOrder: (value) => set(`settings-landing`, 'order', value),
            setSort: (value) => set(`settings-landing`, 'sort', value),
        }, dispatch)
    })
)(injectIntl(SettingsComponent));