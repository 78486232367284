import React from 'react';
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic";
import { Link } from "react-router-dom";
import { DialogLink, Loadable } from "../../../common";

import {
    Button,
    IconButton,
    FormControl,
    InputLabel,
    TextField,
    Input,
    InputAdornment,
    FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LicenseAgreementDialog } from '../../../content';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Helmet from 'react-helmet';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        width: '100%',
    }
}));

function LoginPage(props) {
    const { intl, initialValues, loginPending, loginError, loginErrorMessage, idleAlert, clientProductPending, actions: { sessionLogin, setIdleAlert } } = props;
    const classes = useStyles();

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword = (setFieldValue, values) => {
        setFieldValue('showPassword', !values.showPassword);
    }

    const handleSessionAlertClose = () => {
        setIdleAlert(false);
    }

    return (<>
        <Helmet title="Login" />
        <div className="kt-login__container">
            <Loadable loading={loginPending || clientProductPending}>
                <div className="kt-login__logo">
                    <a href="/#">
                        <img alt="logo" src={toAbsoluteUrl("/media/logos/logo-light.v3.png?v=4")} />
                    </a>
                </div>
                <div className="kt-login__signin">
                    <div className="kt-login__head">
                        <div className="kt-login__title">
                            <h3>
                                {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
                                <FormattedMessage id="AUTH.LOGIN.TITLE" />
                            </h3>
                        </div>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};

                            if (!values.username) {
                                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                                errors.username = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.password) {
                                errors.password = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values) => {
                            handleSessionAlertClose();
                            sessionLogin(values.username, values.password, values.rememberMe);
                        }}
                    >
                        {({
                            values,
                            status,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                        }) => {
                            return (
                                (<form
                                    noValidate={true}
                                    autoComplete="off"
                                    className="kt-form"
                                    onSubmit={handleSubmit}
                                >
                                    {loginError && (
                                        <div role="alert" className="alert alert-danger">
                                            <div className="alert-text">{loginErrorMessage}</div>
                                        </div>
                                    )}
                                    {idleAlert && (
                                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            Your session has expired. Please login again
                                            <button onClick={handleSessionAlertClose} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                    <div className="input-group">
                                        <TextField
                                            type="username"
                                            label="Username"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="username"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.username}
                                            helperText={touched.username && errors.username}
                                            error={Boolean(touched.username && errors.username)}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <FormControl variant="standard" className={classes.input}>
                                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                            <Input
                                                name="password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => handleClickShowPassword(setFieldValue, values)}
                                                            onMouseDown={handleMouseDownPassword}
                                                            size="large">
                                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                error={Boolean(touched.password && errors.password)}
                                            />
                                            <FormHelperText className="Mui-error">{touched.password && errors.password}</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className="row kt-login__extra">
                                        <div className="col">
                                            <label className="kt-checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="rememberMe"
                                                    checked={values.rememberMe}
                                                    onChange={handleChange}
                                                /> Remember me
                                                <span></span>
                                            </label>
                                        </div>
                                        <div className="col text-right">
                                            <Link
                                                to="/auth/forgot-password"
                                                className="kt-link kt-login__link-forgot"
                                            >
                                                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="kt-login__actions">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            type="submit"
                                            disabled={loginPending || clientProductPending}
                                            size="large"
                                        >
                                            {(loginPending || clientProductPending) ? 'Loading...' : 'Sign In'}
                                        </Button>
                                    </div>
                                </form>)
                            );
                        }}
                    </Formik>
                </div>
                <div className="row kt-login__extra">
                    <div className="col text-center">
                        <DialogLink
                            title="CPT Disclaimer"
                            Dialog={LicenseAgreementDialog}
                        />
                    </div>
                </div>
            </Loadable>
        </div>
    </>);
}

export default LoginPage;