import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton, TablePagination } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';

import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import { DataFilter, DataTable, TablePaginationActions } from '../../common';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ClaimStatusEditPage } from './ClaimStatusEditPage';
import { DependencyDialog } from './DependencyDialog';
import { reorderArray } from '../../util';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    icon: {
        fontSize: 17,
        marginTop: 1,
    },
    textField: {
        width: 400,
    },
}));

const DEFAULT_SORT = [{ id: 'precedence', direction: 'asc' }];

export default function ClaimStatusPage({ fields, data, order, filter, sort, statusChangePrecedencePending, 
    actions: { claimStatusLanding, createEditReset, setClaimStatusEditDialog, setDependencyDialog, 
        setOrder, setFilter, setSort, claimStatusesReset, changePrecedence }
}) {

    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [tempStatuses, setTempStatuses] = useState([]);

    useEffect(() => {
        setTempStatuses([...data])
    }, [data])

    useEffect(() => {
        claimStatusLanding();
        return () => {
            claimStatusesReset();
            setFilter('');
        }
    }, [claimStatusLanding]);

    const handleRefresh = () => {
        claimStatusLanding();
    }

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    const handleAddClaimStatus = () => {
        setClaimStatusEditDialog({ open: true, claimStatus: null });
    }

    const handleEditClaimStatus = (rowData) => {
        setClaimStatusEditDialog({ open: true, claimStatus: rowData });
    }

    const handleDeleteClaimStatus = (rowData) => {
        setDependencyDialog({ open: true, claimStatus: rowData });
    }

    const handleClose = (shouldReloadGrid) => {
        createEditReset();
        setClaimStatusEditDialog(null);
        if (shouldReloadGrid === true) {
            handleRefresh();
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDragAndDrop = (prevIndex, newIndex) => {      
        const reorderedData = reorderArray(tempStatuses, prevIndex, newIndex);
        setTempStatuses(reorderedData);
        handleChangePrecedence(reorderedData);
    }

    const handleChangePrecedence = (statusesArray) => {
        const formattedData = statusesArray.map((status, index) => {
            return {
                statusId: status.id,
                precedence: index + 1
            }
        });
        changePrecedence({ claimStatusPrecedences: formattedData }, handleRefresh)
    }

    function claimStatusActions(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleEditClaimStatus(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
                {rowData && rowData.type === 'Custom' &&
                    <IconButton
                        color="primary"
                        className='p-1'
                        aria-label="Delete"
                        onClick={() => handleDeleteClaimStatus(rowData)}
                        size="large">
                        <Tooltip title="Delete">
                            <DeleteIcon />
                        </Tooltip>
                    </IconButton>
                }
            </div>
        );
    }

    return (
        <>
            <Helmet title="Claim Statuses" />
            <Portlet>
                <PortletHeader
                    title="Claim Statuses"
                    handleRefresh={handleRefresh}
                    name="claim-status-landing"
                    showSearchFilters={false}
                    toolbar={
                        <PortletHeaderToolbar>
                            <DataFilter
                                onChange={handleSearchInput}
                                placeholder="Search"
                                cssClass={classes.textField}
                                filter={filter}
                            />
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <div className="row">
                        <div className="col-md-12">
                            <DataTable
                                name="claim-status-landing"
                                fields={fields}
                                data={data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                                handleRefresh={handleRefresh}
                                defaultSort={DEFAULT_SORT}
                                onAdd={handleAddClaimStatus}
                                // tableLevelActions={tableLevelActions}
                                loading={statusChangePrecedencePending}
                                title="claimstatuses"
                                order={order}
                                onOrder={setOrder}
                                sort={sort}
                                onSort={setSort}
                                stickyHeader={true}
                                onDragEnd={handleDragAndDrop}
                                isDnDEnabled={true}
                                showPagination={true}
                                footerPagination={() => (
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                        colSpan={fields.length + 2}
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                )}

                                hasActions={true}
                                renderActionColumn={claimStatusActions}
                            />
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
            <ClaimStatusEditPage
                    handleClose={handleClose} />
            <DependencyDialog handleRefresh={handleRefresh} />
        </>
    );
}
