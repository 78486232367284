import React from 'react';
import { get } from 'lodash';

import { TableCell, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PopoverLink } from '../popoverLink';
import { ClickPopover } from '../ClickPopover';

const useStyles = makeStyles(theme => ({
    ellipsis: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: props => 200,
        cursor: 'pointer',
        '@media (max-width: 1440px)': {
            maxWidth: props => props.width ? props.width : 150,
        },
    },
    customButton: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: props => 200,
        '@media (max-width: 1440px)': {
            maxWidth: props => props.width ? props.width : 150,
        },
    },
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        width: 400,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    iconClass: {
        fontSize: '20px',
    },
    underline: {
        textDecoration: 'underline',
        color: "#2739c1",
    },
    fixedCell: {
        position: 'sticky', left: 0, backgroundColor: "#fff",
        left: ({ left }) => left + 'px',
        whiteSpace: ({ whiteSpace }) => whiteSpace ?? null,
    },
    wrapText: {
        whiteSpace: ({ whiteSpace }) => whiteSpace ?? null,
        width: props => props.width ? props.width : null,
    },
    wordBreak: {
        wordBreak: ({ wordBreak }) => wordBreak ?? null
    },
    clickTypography: {
        // textDecoration: 'underline',
        color: '#2739c1',
        cursor: 'pointer'
    },
    textColumn: {
        backgroundColor: props => props.backgroundColor,
        width: props => props.width ? props.width : null,
    }
}));

function RowColumn(props) {

    const { field, rowData,
        field: { options: { customCell, clickPopover, truncate, onClick, icon, labelStyle, onHover, titleSubstring, hidden, checkedClass, positionFixed, left = 0,
            returnWholeRow, whiteSpace, width, clickCustomClass = "", backgroundColor = 'transparent', wordBreak, customPopoverTitle } },
        handleClickColumn, hoverData, index
    } = props;
    const classes = useStyles({ left, whiteSpace, width, backgroundColor, wordBreak });
    const columnData = field.renderString(returnWholeRow ? rowData : get(rowData, field.id));

    function clickableColumn() {
        return (
            <TableCell
                onClick={() => handleClickColumn(field.id, rowData, index)}
                className={`${clickCustomClass} ${classes.ellipsis} ${positionFixed ? classes.fixedCell : null}`}
            >
                <span className={classes.clickTypography}>{columnData}</span>
            </TableCell >
        );
    }
    function hoverColumn() {
        const hData = hoverData(rowData);
        const dFields = hData.get(field.id);

        function getPopoverTitle() {
            const headerText = customPopoverTitle ?? columnData;
            return titleSubstring ? `${headerText.substring(0, 15)}...` : headerText
        }
        return (
            <TableCell
                className={`${classes.ellipsis} ${positionFixed ? classes.fixedCell : null}`}
            >
                <PopoverLink
                    columnData={columnData}
                    rowData={rowData}
                    hoverData={dFields}
                    title={getPopoverTitle()}
                />
            </TableCell>
        );
    }

    function clickPopoverColumn() {
        return (
            <>
                <ClickPopover columnData={columnData} customPopoverTitle={customPopoverTitle}/>
            </>
        );
    }

    function trucateColumn() {
        return (
            <Tooltip
                arrow
                classes={{ tooltip: classes.tooltip }}
                title={<Typography color="inherit">{columnData}</Typography>}
            >
                <TableCell className={classes.ellipsis}>
                    {columnData}
                </TableCell>
            </Tooltip>
        );
    }

    function iconColumn() {
        const iconClassName = icon ? icon : "fas fa-check-circle";
        const colorClass = checkedClass ? checkedClass : "text-success";
        return (
            <TableCell className={classes.iconClass}>
                {columnData ? (
                    <i className={`${iconClassName} ${colorClass} ml-2 ${positionFixed ? classes.fixedCell : null}`}></i>
                ) : (
                    <i className={`${iconClassName} text-secondary ml-2 ${positionFixed ? classes.fixedCell : null}`}></i>
                )}
            </TableCell>
        );
    }

    function textColumn() {
        return (
            <TableCell
                className={`${classes.textColumn} ${positionFixed ? classes.fixedCell : classes.wrapText} ${classes.wordBreak}`}
            >
                <span className={labelStyle}>{columnData}</span>
            </TableCell>
        );
    }

    function customColumn() {
        return (
            <TableCell
                className={`${clickCustomClass} ${classes.customButton} ${positionFixed ? classes.fixedCell : null}`}
            >
                {columnData}
            </TableCell>
        );
    }

    function renderColumn() {
        if (onHover) {
            return hoverColumn();
        } else if (customCell) {
            return customColumn();
        }
        else if (onClick) {
            return clickableColumn();
        }
        else if (truncate && columnData?.length > 0) {
            return trucateColumn();
        }
        else if (icon) {
            return iconColumn();
        }
        else if (clickPopover) {
            return clickPopoverColumn();
        }
        else {
            return textColumn();
        }
    }

    return (
        <>
            {renderColumn()}
        </>
    )
}

export default React.memo(RowColumn);