import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import { DialogLink } from "../../../app/common/dialogLink";
import { LicenseAgreementDialog, EulaDialog } from '../../../app/content';

const Footer: React.FC<{ 
  footerClasses: string; 
  footerContainerClasses: string; 
}> = ({ footerClasses, footerContainerClasses }) => {
  return (
    <div
      className={`kt-footer ${footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
      id="kt_footer"
    >
      <div className={`kt-container ${footerContainerClasses}`}>
        <div className="kt-footer__copyright">
          &nbsp;
        </div>
        <div className="kt-footer__menu">
          <DialogLink
            title={`CPT copyright ${new Date().getFullYear()} American Medical Association`}
            Dialog={LicenseAgreementDialog}
          />
          <DialogLink
            title="End User License Agreement"
            Dialog={EulaDialog}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: any) => ({
  fluid:
    objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true
  })
});

export default connect(mapStateToProps)(Footer);