import { createSelector } from "reselect";
import { RECOVERED_AMOUNT_ID, PAYER_MAPPING_ID } from "../constants/settings";
import { selectSelectedClient, selectClientListTiny } from "./clients";

export const selectSettings = (state, props) => state.settings.data;
export const selectRelease = (state, props) => state.settings.release;
export const selectReleaseNotes = (state, props) => state.settings.releaseNotes;
export const selectPayerIdMappingFlag = (state) => state.settings.data?.find(setting => setting.id === PAYER_MAPPING_ID && setting.value && setting.value.toLowerCase() === 'true')?.value as Boolean || false;

export const selectRecoveredAmountFlag = createSelector(
    selectSettings,
    (settings) => {
        const recoveredAmountFlag = settings.find(setting => setting.id === RECOVERED_AMOUNT_ID);
        return recoveredAmountFlag && recoveredAmountFlag.value && recoveredAmountFlag.value.toLowerCase() === 'true' ? true : false;
    }
);