import React, { useEffect } from 'react';
import {
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Card } from 'react-bootstrap';
import { DataTable, Loadable } from '../../../../common';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    skipButton: {
        borderColor: '#ffc107',
        backgroundColor: '#ffc107',
        "&:hover": {
            borderColor: '#e0a800',
            backgroundColor: '#e0a800',
        },
    },
}));

const DEFAULT_SORT = [{ id: 'displayName', direction: 'asc' }];


interface PayerContractStepProps {
    intl: any;
    history: any;
    activeStep: number;
    processContractsPending: boolean;
    getContractsPending: boolean;
    contractsData: any;
    handleNext: () => void;
    handleBack: () => void;
    handleCallNegotiatedAPI: (bool: boolean) => void;
    order: any;
    sort: any;
    filter: any;
    selectedItems: any;
    contractsFields: any;
    negotiatedRatesState: {
        value: string;
        updatedBy: string;
        updatedAt: string;
    } | null;
    actions: {
        getPricerConfigContracts: () => void;
        setSort: (value: any) => void;
        setOrder: (value: any) => void;
        setFilter: (value: any) => void;
        setSelected: (value: []) => void;
        processContracts: (payload: any, callback: () => void) => void;
        getConfigNegotiatedRates: (payload: any, callback: () => void) => void;
    };
}

function PayerContractStep({
    intl, history, processContractsPending, getContractsPending, handleNext, handleBack,
    order, sort, selectedItems, filter, contractsFields, contractsData, handleCallNegotiatedAPI,
    actions: { setFilter, setSort, setOrder, getPricerConfigContracts,
        setSelected, processContracts, getConfigNegotiatedRates }
}: PayerContractStepProps & RouteComponentProps) {
    const [isConfirmOpen, setConfirmOpen] = useState(false);
    const [isExecutionDialogOpen, setExecutionDialogOpen] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        getPricerConfigContracts();
        return () => {
            setSelected([]);
        }
    }, [getPricerConfigContracts]);

    const handleRefresh = () => {
        getPricerConfigContracts();
    }

    const handleOpenConfirmDialog = () => {
        setConfirmOpen(true);
    }
    const handleCloseConfirmDialog = () => {
        setConfirmOpen(false);
    }
    const handleDialogConfirmation = () => {
        handleCloseConfirmDialog();
        handleCallNegotiatedAPI(false); // disable API call on 4th step
        const payload = selectedItems;
        processContracts(payload, handleProcessCallback)
    }

    const handleSkip = () => {
        const payload = selectedItems;
        getConfigNegotiatedRates(payload, handleCallback);
    }

    const handleRedirectToExecutionPage = () => {
        setExecutionDialogOpen(false);
        //history.push('/pricer/executions');
    }

    const handleCallback = () => {
        handleNext();
    }
    const handleProcessCallback = () => {
        setExecutionDialogOpen(true);
    }


    return (
        (<Loadable loading={processContractsPending || getContractsPending}>
            <Grid item xs={12} className="mt-3">
                <Card>
                    <Card.Header as="h5" className='text-center'>Contracts</Card.Header>
                    <Card.Body className='text-dark px-5 py-4'>
                        <Grid container>
                            <Grid item md={12} className="col-md-12">
                                <DataTable
                                    name="pricer-config-contracts-landing"
                                    fields={contractsFields}
                                    data={contractsData}
                                    handleRefresh={handleRefresh}
                                    defaultSort={DEFAULT_SORT}
                                    loading={false}
                                    title="Contracts"
                                    order={order}
                                    onOrder={setOrder}
                                    sort={sort}
                                    onSort={setSort}
                                    stickyHeader={true}
                                    hasCheckbox={true}
                                    showSelectAll={contractsData.some(contract => contract.disabledCheckbox === true) ? false : true}
                                    pageName="pricer-config-contracts-landing"
                                />
                            </Grid>
                        </Grid>
                        <Grid className='pt-2 justify-content-end' container>
                            <Button onClick={handleBack} variant="contained" className='mr-2'>
                                Back
                            </Button>
                            <Button
                                onClick={handleSkip}
                                variant="contained"
                                className={`${classes.skipButton} mr-2`}
                                disabled={processContractsPending}
                            >
                                Skip
                            </Button>
                            <Button
                                onClick={handleOpenConfirmDialog}
                                variant="contained"
                                color="primary"
                                disabled={processContractsPending}
                            >
                                Start
                            </Button>
                        </Grid>
                    </Card.Body>
                </Card>
            </Grid>
            <Dialog open={isConfirmOpen} onClose={handleCloseConfirmDialog}>
                <DialogTitle>
                    Pricer
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedItems.length === 0
                            ? <p className='text-danger'>Please select atleast one contract for process</p>
                            : 'Are you sure to process negotiated rates from latest contracts?'
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDialogConfirmation}
                        disabled={selectedItems.length === 0}
                        color="primary">
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
            fullWidth
            scroll="paper"
            maxWidth="sm"
            open={isExecutionDialogOpen}
            aria-labelledby="form-dialog-title">
            <DialogContent className='p-4'>
            <Grid container className='pt-2'>
                <Grid>
                    <p> Your request has been submitted. Please check in the execution logs page. </p>
                </Grid>
                </Grid>
                <Grid container className='justify-content-end pt-3'>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRedirectToExecutionPage}
                    >
                        OK
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
        </Loadable>)
    );
}
export default withRouter(PayerContractStep)