import { Immutable } from "@pure-ptr/react";
import { Revenue, RevenueCode, RevenueCodeCollection, RevenueCollection } from "../../store/api/claimAnalysis";
import { asMonthDate, DateRange } from "../../util/date";

export class Summary extends Immutable {
    total = 0;
    variance = 0;
    increase = 0;

    static fromTotals( totals : number[] ) : Summary[] {
        return Summary.array( totals, ( total, i ) => ({
             total,
             variance : i > 0 ? total - totals[i - 1] : 0,
             increase : i > 0 ? ( total - totals[i - 1] ) / totals[i - 1] : 0
        }));
    }
}

export class Forecast extends Immutable {
    units = null as number
    rate = null as number

    static getFilledLastValue( forecasts : Forecast[] ) : Forecast[] {
        let units = 0, rate = 0;

        return Forecast.array( forecasts, forecast => {
            units = forecast.units ?? units,
            rate = forecast.rate ?? rate;

            return { units, rate };
        })
    }
}

class RevenueProjection extends Immutable {
    revenue : Revenue = null
    forecast = [] as Forecast[]
    revCode : RevenueCode = null

    // Computed properties...
    filledForecast = [] as Forecast[]
    summary = [] as Summary[]

    initialize(){
        this.filledForecast = Forecast.getFilledLastValue( this.forecast );
        this.summary = Summary.fromTotals( 
            this.filledForecast.map( x => x.units * x.rate ) 
        );
    }

    static parse( years : number, revCodes : Readonly<RevenueCodeCollection>) : ( revenue : Revenue ) => Partial<RevenueProjection> {
        return revenue => ({
            revenue,
            revCode : revCodes.get( revenue.revCode ),
            forecast : [ 
                Forecast.object({
                    units : revenue.units, 
                    rate : revenue.rate 
                }),
                ...Array( years ).fill( Forecast.object({}) )
            ]
        })
    }

    clear( idx : number, what : keyof Forecast ) {
        return this.set({
            forecast : this.forecast.map(
                ( x, i ) => 
                    i === idx ? 
                        x.set({ [what] : null }) : 
                        x
            )
        } as any);
    }

    modify( idx : number, what : keyof Forecast, multiplier : any ) : this {
        return this.set({
            forecast : this.forecast.map( ( x, i ) =>
                i === idx ? 
                    x.set({
                        [what] : Math.round( 100 * ( this.filledForecast[ i - 1 ][what] as number) * multiplier ) / 100
                    }) 
                : 
                    x
            ) 
        } as any);
    }
}

function aggregateSummary( items : ({ summary : Summary[] }[] ), length : number ) : Summary[] {
    return Summary.fromTotals( 
        items.reduce( 
            ( acc, x ) => 
                acc.map( ( total, i ) => total + x.summary[i].total ),

            Array( length ).fill( 0 )
        )
    );
}

export class ServiceRevenueProjection extends Immutable {
    revenueProjections : RevenueProjection[] = []
    service : string = null
    nYears = 0

    get id(){ return this.service }

    initialize(){
        this.summary = aggregateSummary( this.revenueProjections, this.nYears + 1);
    }

    // Computed properties...
    summary = [] as Summary[]

    clear( idx : number, what : keyof Forecast ) : this {
        return this.set({
            revenueProjections : this.revenueProjections.map( x => x.clear( idx, what ))
        } as any);
    }

    modify( idx : number, what : keyof Forecast, multiplier : any ) : this {
        return this.set({
            revenueProjections : this.revenueProjections.map( x => x.modify( idx, what, multiplier ))
        } as any);
    }
}

export class YearlyRevenueProjection extends Immutable {
    groups : ServiceRevenueProjection[] = []
    years = [] as string[]

    // Computed properties...
    summary = [] as Summary[]

    initialize(){
        this.summary = aggregateSummary( this.groups, this.years.length + 1);
    }

    static fromResponse( response : Readonly<RevenueCollection>, years : number, revCodes : Readonly<RevenueCodeCollection>, dateRange : DateRange ) : YearlyRevenueProjection {
        // Parse claims...
        const claims = RevenueProjection.array( response.items, RevenueProjection.parse( years, revCodes ) );

        // Group claims...
        const groups = (Map as any).groupBy( claims, x => x.revCode.service );

        return YearlyRevenueProjection.object({
            years : Array( years )
                .fill( 0 )
                .map( ( _, i ) => `Year ${i + 1}: ${asMonthDate(dateRange.startdate)} - ${asMonthDate(dateRange.enddate)}` ),

            groups : ServiceRevenueProjection.array( 
                groups, 
                ([ service, claims ]) => ServiceRevenueProjection.object({
                    service, 
                    revenueProjections: claims,
                    nYears : years
                })
            )
        })
    }
}
