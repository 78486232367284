import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import {
    Portlet, PortletBody,
    PortletHeader, PortletHeaderToolbar
} from "../../partials/content/Portlet";

import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import { routes } from '../../router/Routes';

import { BreadCrumb } from '../../common/Breadcrumb';
import makeStyles from '@mui/styles/makeStyles';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TableLevelAction } from '../../common';
import { AcknowledgeDialog } from './AcknowledgeDialog';
import { FlagDialog } from './FlagDialog';

import { UpdateOutlined } from '@mui/icons-material';
import FlagIcon from '@mui/icons-material/Flag';
import GetAppIcon from '@mui/icons-material/GetApp';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { PurePtr, useStatePtr } from '@pure-ptr/react';
import { ClaimAnalysisDataGrid } from './ClaimAnalysisGrid';
import { ClaimAnalysisTopFilters } from './ClaimAnalysisTopFilters';
import { ClaimStatistics } from './ClaimStatistics';
import { ProcessClaimDialog } from './ProcessDialog';
import { pageStore, useGlobalStore, pageGridFilters } from '../../common/NewDataGrid';
import UpdateStatusDialog from './UpdateStatusDialog';
import { SearchTitle } from '../../common';
import { IgnoreFiltersDialog } from './ServerSideSearchAndIgnoreFiltersAcknowledgeDialog';
import { useFitHeight } from '../../../_metronic/layout/Layout';

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        zIndex: 100,
    },
    formControl: {
        margin: theme.spacing(1),
        width: 200,
    },
    formControlClaim: {
        margin: theme.spacing(1),
        width: 71
    },
    formControlStatus: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 4,
        marginRight: 4,
        width: 140,
        '@media (max-width: 1440px)': {
            width: 110,
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        pointerEvents: 'auto',
        width: 300,
    },
    switch: {
        marginRight: 0,
        marginTop: 20,
        marginLeft: 0
    },
    gridMDMargin: {
        '@media (max-width: 1023px)': {
            paddingLeft: 10,
        }
    },
    IconButton: {
        padding: 6
    },
    statisticsContainer:{
        padding:'20px',
        paddingBottom:0
    }
}));

const { usePageStore, useGridFilters } = pageStore('claimAnalysis-landing', {
    sort: [{ id: 'expectedPay', direction: 'asc' }]
});

export default function ClaimAnalysisPage({
    intl,
    claimStatusList,
    hasClaimProcessorAccess, 
    defaultCustomFilters, 
    selectedClientName,
    pending,
    actions: {
        claimAnalysisLanding,
        claimAnalysisSetDrilldown,
        downloadAnalysisReport,
        searchGetByName,
        searchReset,
        flagClaims,
        getTinyContracts,
        getPayersTiny,
        getClaimStatuses,
        getNpiRoles,
        resetClaimProcessError,
        resetPagedTable,
        setFlagToggle,
        setBulkFlagged,
        setOpenPlayDialog,
        setBulkAcknowledge,
        setMulti
    }
} ) {
    useFitHeight();

    const getByNamePending = useGlobalStore( x => x.search.getByName.pending );

    const claimAnalysisDownloadReportPending = useGlobalStore( x => x.claimAnalysis.download.pending );

    const claimsSearchTerm = usePageStore( x => x.searchTerm );
    const filterAcknowledgement = usePageStore( x => x.ignoreAcknowledgement );


    const claimsData = usePageStore((x) => x.list);
    const significance = usePageStore( x => x.significance );

    const relativeFilter = useGlobalStore( x => 
        x.settings.data.find( setting => 
            setting.id === 'SEARCH_PERIOD_ID' 
        )
        ?.value ?? '' 
    );

    const classes = useStyles();
    const firstUpdate = useRef(true);
    const [refresh, setRefresh] = useState(Promise.resolve());


    useEffect(() => {
        getTinyContracts();
    }, [getTinyContracts]);

    useEffect(() => {
        getClaimStatuses();
    }, [getClaimStatuses]);

    useEffect(() => {
        getPayersTiny();
    }, [getPayersTiny]);
    useEffect(() => {
        getNpiRoles()
    }, [getNpiRoles]);

    useEffect(() => {
        const pageName = {
            name: 'claimAnalysis-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            resetPagedTable();
            searchReset();

            claimAnalysisSetDrilldown({
                open: false,
            });
        }
    }, [searchGetByName]);


    function handleLoad(sort, filter, pagination) {

        let newOperands=filter?.operands?.map(operand => {
            const newFilters = operand?.filters?.map(filter => {
                if (filter.id === 'contract.name' && filter.value.includes(',') && filter.equator.includes('equal')) {
                    return {
                        ...filter,
                        value: `"${filter.value}"`
                    };
                }
                return filter;
            });
            return {
                ...operand,
                filters: newFilters
            };
        });
        // @ts-ignore
        let commaEscapedFilters={...filter,operands:newOperands} || null;

        claimAnalysisLanding(pagination, sort,!filter ? null : commaEscapedFilters, claimsSearchTerm?.length > 0 ? false:significance);
        // HACK - stats will be called inside landing API to avoid render.
        // getClaimAnalysisStats(pagination, sort, filter);
    }

    const tableFilter = useGridFilters(),
        claimPageRecord = tableFilter.pagination;

    const handleAcknowledge = ({ id }) => {
        setBulkAcknowledge(false);
        selectedClaimsIdsPtr.set([id]);
        dialogsOpenPtr.at('acknowledgeDialog').set(true);
    }

    const handleAcknowledgeClose = (shouldReloadGrid) => {
        dialogsOpenPtr.at('acknowledgeDialog').set(false);
        dialogsOpenPtr.at('flagDialog').set(false);
        setFlagToggle(false);

        if (shouldReloadGrid) {
            resetPagedTable();
            handleRefresh();
        }
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    function handleBulkAcknowledge() {
        dialogsOpenPtr.at('acknowledgeDialog').set(true);
        setBulkAcknowledge(true);
    }

    function handleDowloadTemplate() {
        let operandsWithoutBoth = tableFilter.filters.operands.filter(operand =>
            !operand.filters.some(filter => filter.value === 'both')
        );
        let filterWithoutBoth = {
            ...tableFilter.filters,
            operands: operandsWithoutBoth
        };
        const payload = {
            page: null,
            filter: filterWithoutBoth || null,
            sort: tableFilter.sort || [],
        };
        downloadAnalysisReport(payload,significance, downloadTemplateCallback);
    }

    function downloadTemplateCallback(blob) {
        const displayName = `${selectedClientName.replace(/ /g, "_")}_ClaimAnalysisReport`;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${displayName.replace(/\s/g, '')}.csv`;
        a.click();
    }

    const handleProcessClaimCallback = () => {
        setOpenPlayDialog(false);
    }

    const handleSingleFlag = (flag, { id }) => {
        // add optional date if flag is true
        if (flag) {
            dialogsOpenPtr.at('flagDialog').set(true);
            setFlagToggle(flag); // pre set in Flag Dialog
            setBulkFlagged(false);
            selectedClaimsIdsPtr.set([id]);
        } else {
            // directly call api on flag is false
            let payload = {
                analysisIds: [id],
                flag: flag,
                followupAlert: ''
            }
            flagClaims(payload, handleAcknowledgeClose, true);
        }
    }

    const handleBulkFlag = () => {
        dialogsOpenPtr.at('flagDialog').set(true);
        setBulkFlagged(true);
    }

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        handleRefresh();
    }, [significance]);

    function handleFilterClear() {
        
            setMulti({
            filter: [],
            selectedFilter: null,
            customFilter: defaultCustomFilters,
            list: [],
            significance:true,
            searchTerm:"",
            ignoreAcknowledgement:false,
            showIgnoredFilterDialog:false
            });
            localStorage.setItem("isCriteriaCleared", "true")
            handleRefresh()
    }
    const setclaimSearchIgnoredDialogStatus = (obj) => {
        setMulti(obj)
        if(obj?.ignoreAcknowledgement)
        {
            handleRefresh()
        }
    }

    const selectedClaimsIdsPtr = useStatePtr<number[]>([]);

    const dialogsOpenPtr = useStatePtr(()=>({
        acknowledgeDialog: false,
        flagDialog: false,
        processClaimsDialog: false,
        updateStatusDialog: false
    }))

    return (
        <>
            <Helmet title="Claim Analysis" />
            <Grid container sx={{ position: "relative" }}>
                <Grid item xs={12}>
                    <BreadCrumb routes={routes} isFromClaims={true} />
                </Grid>

                <Grid
                    sx={{
                        position: "absolute",
                        right: "10px",
                    }}
                >
                    <SearchTitle
                        title="Claim Analysis"
                        name={"claimAnalysis-landing"}
                        handleRefresh={handleRefresh}
                        shouldNotSetDefault={false}
                        showSearchCriteriaDropdown={true}
                    />
                </Grid>
            </Grid>
            <Portlet>
                <Box className={classes.statisticsContainer}>
                    <ClaimStatistics />
                </Box>
                <PortletHeader
                    title="Claim Analysis"
                    name="claimAnalysis-landing"
                    handleRefresh={handleRefresh}
                    showSearchFilters={true}
                    handleFilterClear={handleFilterClear}
                    includeAdvanceFilters={true}
                    toolbar={
                        <PortletHeaderToolbar>
                            <ClaimAnalysisTopFilters handleRefresh={handleRefresh} relativeFilter={relativeFilter} />
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <Stack direction="column" spacing={1} sx={{ height: '100%', minHeight: 280 }}>
                        <TableLevelAction
                            handleRefresh={handleRefresh}
                            actions={ () =>
                                <>
                                    <Grid item>
                                        <Tooltip title="Update Status">
                                            <IconButton aria-label="process" size='small' onClick={() => dialogsOpenPtr.at('updateStatusDialog').set(true) } disabled={claimAnalysisDownloadReportPending}>
                                                <UpdateOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                    {hasClaimProcessorAccess && (
                                        <Grid item>
                                            <Tooltip title="Process Claims">
                                                <IconButton aria-label="process" size='small' 
                                                    onClick={ () => dialogsOpenPtr.at('processClaimsDialog').set(true) } 
                                                    disabled={claimAnalysisDownloadReportPending}
                                                >
                                                    <PlayCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}

                                    <Grid item>
                                        <Tooltip title="Export">
                                            <IconButton aria-label="export" size='small' 
                                                onClick={handleDowloadTemplate} 
                                                disabled={claimAnalysisDownloadReportPending}
                                            >
                                                <GetAppIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                    <Grid item>
                                        <Tooltip title="Follow">
                                            <IconButton aria-label="follow" size='small' onClick={handleBulkFlag}>
                                                <FlagIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                    <Grid item>
                                        <Tooltip title="Acknowledge">
                                            <IconButton aria-label="acknowledge" size='small' onClick={handleBulkAcknowledge}>
                                                <CheckCircleIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </>
                            }
                            
                            loading={claimAnalysisDownloadReportPending}
                            actionLevelGrid={ () =>
                                <Grid container>
                                    <Grid item md={12}>
                                    <h6 className="">{`Total Records : ${(claimsData?.length > 0 && claimPageRecord?.totalRecords) ? claimPageRecord?.totalRecords : 0}`}</h6>
                                    </Grid>
                                </Grid>
                            }
                        />

                        <ClaimAnalysisDataGrid
                            selectedClaimsIdsPtr={selectedClaimsIdsPtr}
                            data={claimsData}
                            handleAcknowledge={handleAcknowledge}
                            handleSingleFlag={handleSingleFlag}
                            loading={ pending || getByNamePending }

                            paginationFilters={tableFilter}
                            onLoad={handleLoad}
                            refresh={refresh}
                            showSearchFilters={true}
                        />
                    </Stack>

                </PortletBody>
            </Portlet>

            <AcknowledgeDialog
                open={dialogsOpenPtr.value.acknowledgeDialog}
                handleClose={handleAcknowledgeClose}
                selectedItemsPtr={selectedClaimsIdsPtr}
            />

            <FlagDialog
                handleClose={handleAcknowledgeClose}
                openPtr={dialogsOpenPtr.at('flagDialog')}
                selectedItemsPtr={selectedClaimsIdsPtr}
            />

            <ProcessClaimDialog
                openPtr={dialogsOpenPtr.at('processClaimsDialog')
                    .onChange( open => {
                        if (!open) {
                            resetClaimProcessError();                        
                        }
                    })
                }
                
                selectedItemsPtr={selectedClaimsIdsPtr}

                handleProcessClaimCallback={handleProcessClaimCallback}
                claimsData={claimsData}
                filter={tableFilter.filters}
            />

            <UpdateStatusDialog 
                openPtr={dialogsOpenPtr.at('updateStatusDialog')}
                selectedItemsIdsPtr={selectedClaimsIdsPtr}
                claimStatusList={claimStatusList?.map((item)=>({label:item.name,value:item.id,name:item.name}))} 
                handleRefresh={handleRefresh}
            />
            <IgnoreFiltersDialog setclaimSearchIgnoredDialogStatus={setclaimSearchIgnoredDialogStatus}/>
        </>
    );
}