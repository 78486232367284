import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Card } from 'react-bootstrap';
import { toAbsoluteUrl } from "../../../../_metronic";
import { CustomLabel } from '../../../common';
import { asDate } from '../../../util/date';

const useStyles = makeStyles(theme => ({
    addressCardStyles:{
        border:'none', width: '100%'
    },
    disclaimerTextStyle:{
        display:'inline-block'

    },
    disclaimerPreviewCard:{
        border:'none',
        width: '100%'
    },
    signaturesCard:{
        border:'none',justifyContent:'center'

    },
    signaturesCardContainer:{
        display:'flex',marginBottom:'20px',columnGap:'10px'
    },
    signaturesCardBox:{
        width:'350px',display:'flex',columnGap:'5px'
    },
    signaturesCardText:{
        minWidth:'max-content',position:'relative',top:'5px'
    },
    signaturesField:{
        width:'100%',borderBottom:'1px solid'
    },
    signaturesDateFieldContainer:{
        width:'140px',display:'flex',columnGap:'5px'
    },
    signaturesDateFieldText:{
        position:'relative',top:'5px'
    },
    signaturesDate:{
        width:'100%',borderBottom:'1px solid'
    },
    marginAuto: {
        margin: 'auto'
    },
    RowTotal: {
        backgroundColor: '#EAEEF5',
    },
    previewtabletr: {
        "& td": {
            padding: '6px 24px 6px 16px',
            '@media (max-width: 1440px)': {
                padding: "6px 5px 6px 5px"
            },
            '@media (max-width: 1280px)': {
                padding: "3px 5px 3px 5px"
            },
            '@media (max-width: 1024px)': {
                padding: "6px 5px 6px 5px"
            }
        },
    },
    width100: {
        width: '100%'
    }
}));

export default function PreviewContent({ values, brand, file }) {
    const classes = useStyles();

    return (
        <Grid container spacing={2} id='modal-html'>
            <Grid container md={12} alignItems="center">
            <div id='print-logo-img' className={`kt-login__logo text-center my-5 ${classes.marginAuto}`}>
                <a href="/#">
                    <img alt="logo" 
                    src={values?.usePricerLogo ? (brand?.logoPath ? `https://${values?.pricerBucket}.s3.amazonaws.com/${brand?.logoPath}` : toAbsoluteUrl("/media/logos/logo-light.v3.png?v=4")) 
                    : (file ? URL.createObjectURL(file) :  values?.logoPath ? 
                    values?.logoPath.includes('media/logos/') ? toAbsoluteUrl("/media/logos/logo-light.v3.png?v=4") : toAbsoluteUrl(values?.logoPath) 
                    : toAbsoluteUrl("/media/logos/logo-light.v3.png?v=4"))}
                    />
                </a>
            </div>
            </Grid>
            <Card id='card-1' className={classes.marginAuto}>
                <Card.Header as="h5">Claim Estimation</Card.Header>
                <Card.Body>
                    <Grid container md={12} className='px-3 py-2'>
                        <Grid item xs={5} className='py-1'>
                            <CustomLabel label="Estimation Id" data={`12345`} />
                        </Grid>
                        <Grid item xs={5} className='py-1'>
                            <CustomLabel label="Patient Name" data={`Test Patient name`} />
                        </Grid>
                        <Grid item xs={5} className='py-1'>
                            <CustomLabel label="Contract" data={`Test Contract`} />
                        </Grid>
                        <Grid item xs={5} className='py-1'>
                            <CustomLabel label="Claim Type" data={`Professional`} />
                        </Grid>
                        <Grid item xs={5} className='py-1'>
                            <CustomLabel label="Total Claim Amount" data={`$${'120.00'}`} />
                        </Grid>
                        <Grid item xs={5} className='py-1'>
                            <CustomLabel label="Allowable" data={`$${'52.00'}`} />
                        </Grid>
                        <Grid item xs={5} className='py-1'>
                            <CustomLabel label="Anticipated Service Date" data={asDate()} />
                        </Grid>
                    </Grid>
                </Card.Body>
            </Card>
            <Card className={`mt-4 ${classes.marginAuto} ${classes.width100}`}  id='card-2'>
                <Card.Header as="h5">Charge Estimation</Card.Header>
                <Card.Body>
                    <Table aria-label="Preview Table">
                        <TableHead>
                            <TableRow className={classes.previewtabletr}>
                                <TableCell key={1} align='left'>Rule </TableCell>
                                <TableCell key={2} align='left'>Revenue Code </TableCell>
                                <TableCell key={3} align='left'>Procedure Code </TableCell>
                                <TableCell key={4} align='left'>Units </TableCell>
                                <TableCell key={5} align='left'>Per Unit Charge Amount </TableCell>
                                <TableCell key={6} align='left'>Total Charge Amount </TableCell>
                                <TableCell key={7} align='left'>Allowable </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={1} className={classes.previewtabletr}>
                                <TableCell key={1} align='left'>Default </TableCell>
                                <TableCell key={2} align='left'>300 </TableCell>
                                <TableCell key={3} align='left'>8070 </TableCell>
                                <TableCell key={4} align='left'>2 </TableCell>
                                <TableCell key={5} align='left'>$ 10.00 </TableCell>
                                <TableCell key={6} align='left'>$ 20.00 </TableCell>
                                <TableCell key={7} align='left'>$ 12.00 </TableCell>
                            </TableRow>
                            <TableRow key={2} className={classes.previewtabletr}>
                                <TableCell key={1} align='left'>Default </TableCell>
                                <TableCell key={2} align='left'>360 </TableCell>
                                <TableCell key={3} align='left'>66982 </TableCell>
                                <TableCell key={4} align='left'>2 </TableCell>
                                <TableCell key={5} align='left'>$ 50.00 </TableCell>
                                <TableCell key={6} align='left'>$ 100.00 </TableCell>
                                <TableCell key={7} align='left'>$ 40.00 </TableCell>
                            </TableRow>
                            <TableRow key={3} className={`${classes.RowTotal} ${classes.previewtabletr}`}>
                                <TableCell key={1} align='left'> </TableCell>
                                <TableCell key={2} align='left'> </TableCell>
                                <TableCell key={3} align='left'> </TableCell>
                                <TableCell key={4} align='left'> </TableCell>
                                <TableCell key={5} align='left'>$ 60.00 </TableCell>
                                <TableCell key={6} align='left'>$ 120.00 </TableCell>
                                <TableCell key={7} align='left'>$ 52.00 </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card.Body>
            </Card>
            <Card className={`mt-4 ${classes.signaturesCard}`} id='card-3'>
                <Grid container md={12} className='px-3 py-2'>
                {values.signatureLabels.filter(x=> x !== '')?.map((item)=>
                    <Box className={`${classes.marginAuto}`} >
                    <Box className={`${classes.signaturesCardContainer}`} >
                        <Box className={`${classes.signaturesCardBox}`}>
                            <Typography className={`${classes.signaturesCardText}`}>
                                {item}
                            </Typography>
                            <Box className={`${classes.signaturesField}`} />
                        </Box>
                        <Box className={`${classes.signaturesDateFieldContainer}`} >
                        <Typography className={`${classes.signaturesDateFieldText}`} >
                            Date</Typography>
                            <Box className={`${classes.signaturesDate}`}/>
                        </Box>
                    </Box>
                    </Box>
                    )
                }
                </Grid>
            </Card>

            <Card className={`mt-4 ${classes.disclaimerPreviewCard}`} id='card-4'>
                <Grid container md={12}>
                    <Typography><Box className={`ql-editor ${classes.disclaimerTextStyle}`} dangerouslySetInnerHTML={{__html: values?.disclaimer}} /></Typography>
                </Grid>
            </Card>

            <Card className={`mt-4  ${classes.addressCardStyles}`} id='card-5'>
                <Grid container md={12} alignItems='center'>
                    <Grid item xs={6}>
                        <Typography>{`Test Memorial Hospital`} , {`(123) 456-7890`}</Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Grid container className='justify-content-end'>
                            <Typography>Date Time: {asDate()}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
}