import { Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

function Announcement({ text, backgroudColor }) {
    const [isOpen, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    }

    return (<>
        {isOpen && <Grid container alignItems='center' style={{ backgroundColor: "#94c83dbd", paddingLeft: '25px', paddingRight: '25px' }}
            className="w-100 py-2 text-dark">
            <Grid xs={11}>
                <span><b>{text}</b></span>
            </Grid>
            <Grid xs={1} className='text-right'>
                <IconButton className='p-1' aria-label="close" onClick={handleClose} size="large">
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Grid>}
    </>);
}

export default Announcement
