import React, { useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles(theme => {
    return {
        editorWrapper: {
            padding: '1rem 0rem 3rem 0rem'
        },
        richTextEditor: {
            height: '250px',
            '& .ql-snow.ql-toolbar': {
                background: '#eaecec'
            }
        }
    }
});

function RichTextEditor(props) {
    const {
        value, fieldName, setFieldValue
    } = props;

    const classes = useStyles();
    const quill = useRef();

    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
            {'indent': '-1'}, {'indent': '+1'}],
            ['link']
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
    ];

    return (
        <div className={classes.editorWrapper}>
            <QuillEditor
                ref={(el) => (quill.current = el)}
                className={classes.richTextEditor}
                theme="snow"
                value={value}
                formats={formats}
                modules={modules}
                onChange={(value) => setFieldValue(fieldName, value)}
                placeholder='Write something here'
            />
        </div>
    )
}



export default RichTextEditor;
