import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { Loadable } from '../../../common';
import CloseIcon from '@mui/icons-material/Close';
import { HistoricalImport } from '../HistoricalImport';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function ImportConfirmationDialog({intl, open, handleRefresh,
    actions: { setHopsitalServicesImportConfirmationDialog, setHopsitalServicesImportDialog }
}) {

    const classes = useStyles();

    const handleDialogClose = () => {
        setHopsitalServicesImportConfirmationDialog(null);
    }

    const handleImport = () => {
        setHopsitalServicesImportConfirmationDialog(null);
        setHopsitalServicesImportDialog({open: true})
    }


    return (<>
        <Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={false}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Confirmation
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleDialogClose}
                                size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    {
                        <Grid>
                            <Typography>Are you sure you want to import DRGs from historical data?</Typography>
                            <Typography>It will impacted on all shoppables and negotiated rates?</Typography>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleImport}
                        variant="contained"
                        color="primary"
                    >
                        Import
                    </Button>
                </DialogActions>
            </Loadable>
        </Dialog>
        <HistoricalImport handleRefresh={handleRefresh} />
    </>);
}

export default ImportConfirmationDialog
