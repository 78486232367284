import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useData, useStatePtr } from '@pure-ptr/react';
import React from 'react';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../partials/content/Portlet';

import DescriptionIcon from '@mui/icons-material/Description';
import FilterListIcon from '@mui/icons-material/FilterList';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import SaveIcon from '@mui/icons-material/Save';

import GetAppIcon from '@mui/icons-material/GetApp';
import { DateRangePicker } from '../../common/dateRangeControl';
import { ContentRow, SelectFromArray } from '../../common/FormControls';
import { RevenueCodeCollection, RevenueCollection } from '../../store/api/claimAnalysis';
import { tiny as fetchTinyContracts, TinyContract } from '../../store/api/contracts';
import { getCriteriaByPageName } from '../../store/api/search';
import { getLast12Months } from '../../util/date';
import { YearlyRevenueProjection } from './state';
import { RevenueProjection } from './table';

export function NewContractModelSimulator() {
    const yearlyRevenuePtr = useStatePtr( null as YearlyRevenueProjection ),
        selectedContractPtr = useStatePtr(null as TinyContract ),
        selectedSearchCriteriaPtr = useStatePtr( null as { id: number, criteriaName: string } ),
        dialogPtr = useStatePtr( null as string );

    const dateRangePtr = useStatePtr( getLast12Months );

    const { data, isPending, error } = useData( async () => {
        const revCodes = await RevenueCodeCollection.fetch();
        
        const contracts = await fetchTinyContracts();
        selectedContractPtr.set( contracts[0] );

        const searchCriteriaOptions = [
            { id: 0, criteriaName: 'Custom Search' },
            ...( await getCriteriaByPageName({ name: 'claimAnalysis-landing' }) )
        ];
        selectedSearchCriteriaPtr.set( searchCriteriaOptions[0] );

        return {
            revCodes,
            contracts,
            searchCriteriaOptions
        }
    }, []);

    const { isPending: isRevenuePending, error: revenueError } = useData( async () => {
        if( selectedContractPtr.isTruthy && data?.revCodes){
            const revenue = await RevenueCollection.fetch( selectedContractPtr.value?.id ?? 0, dateRangePtr.value );

            yearlyRevenuePtr.set( 
                YearlyRevenueProjection.fromResponse( revenue, 3, data.revCodes, dateRangePtr.value )
            );
        }
    }, [ selectedContractPtr.value, dateRangePtr.value, data?.revCodes ])

    const isCustomDateRange = selectedSearchCriteriaPtr.value?.id === 0;

    return isPending ? <div>Loading...</div> : (
        <Portlet fluidHeight>
            <PortletHeader
                toolbar={
                    <PortletHeaderToolbar fullWidth>
                        <ContentRow spacing={2} 
                            right={<>
                                <Button startIcon={<SaveIcon />} onClick={ () => dialogPtr.set("save")}>
                                    Save
                                </Button>
                                <Button startIcon={<OpenInBrowserIcon />}>
                                    Open
                                </Button>
                                <Button startIcon={<GetAppIcon />}>
                                    Download
                                </Button>
                            </>}
                        >
                            <Box display="flex" width={200}>
                                <SelectFromArray
                                    options={data.searchCriteriaOptions}
                                    disableClearable
                                    icon={<FilterListIcon />}
                                    getOptionLabel={ x => x.criteriaName }
                                    placeholder="Custom Search Criteria"
                                    valuePtr={selectedSearchCriteriaPtr}
                                    label="Search Criteria"
                                />
                            </Box>

                            <Box display="flex" width={200}>
                                <SelectFromArray
                                    disabled={!isCustomDateRange}
                                    options={data.contracts}
                                    icon={<DescriptionIcon />}
                                    disableClearable
                                    getOptionLabel={ x => x.name }
                                    getOptionKey={ x => x.id.toString() }
                                    valuePtr={selectedContractPtr}
                                    label="Contract"
                                />
                            </Box>

                            <Box display="flex" width={200}>
                                <DateRangePicker
                                    disabled={!isCustomDateRange}
                                    valuePtr={dateRangePtr} 
                                    label="Date Range" 
                                />
                            </Box>
                        </ContentRow>
                    </PortletHeaderToolbar>
                }
            />
            <PortletBody>
                { isRevenuePending || !yearlyRevenuePtr.isTruthy ?
                    <div>Loading...</div>
                :
                    <RevenueProjection yearlyRevenuePtr={yearlyRevenuePtr} dateRange={dateRangePtr.value} />
                }
            </PortletBody>

            <ConfirmDialog
                openPtr={dialogPtr.equals('save')}
                title="Save Estimation"
                action="Save"
                content="Are you sure you want to save this item?"
                onConfirm={() => undefined}
            >
                <TextField label="Name" required fullWidth />
            </ConfirmDialog>
        </Portlet>
    )
}

const ConfirmDialog = ({ openPtr, action, title, content, children, onConfirm }) => {
    const onClose = () => openPtr.set(false);
    return (
        <Dialog
            open={openPtr.value}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { content }
                </DialogContentText>
                { children }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={()=>{
                    onConfirm();
                    onClose();
                }}>
                    {action}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
