import { createReducer } from '../../util';
import * as actions from '../actions/statistics';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    stats: [],
    files: [],
    remitAndPayersStatistics: null,
    filters: {
        frequency: 'daily',
        state: 'all',
        clientId: 'all'
    },
    remitsAndPayersfilters: {
        frequency: 'monthly',
        clientId: ''
    },
    get: {
        ...initialStatusState,
    },
    getFiles: {
        ...initialStatusState,
    },
    getRemitPayersAndStatisticsPending: {
        ...initialStatusState,
    },
};

export default createReducer(initialState, {

    [actions.STATISTICS_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.STATISTICS_GET_FULFILLED]: (state, stats) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        stats: stats,
    }),
    [actions.STATISTICS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


    //Remit & Payer Stats
    [actions.STATISTICS_GET_REMITS_AND_PAYERS_PENDING]: (state) => ({
        ...state,
        getRemitPayersAndStatisticsPending: {
            pending: true,
        },
    }),
    [actions.STATISTICS_GET_REMITS_AND_PAYERS_FULFILLED]: (
        state,
        remitAndPayersStatistics
    ) => ({
        ...state,
        getRemitPayersAndStatisticsPending: {
            ...initialStatusState,
        },
        remitAndPayersStatistics: {
            ...remitAndPayersStatistics,
            claims: remitAndPayersStatistics?.claims?.sort(
                (a, b) =>
                    new Date(a["creationDate"]).getTime() -
                    new Date(b["creationDate"]).getTime()
            ),
            remits: remitAndPayersStatistics?.remits?.sort(
                (a, b) =>
                    new Date(a["checkEffectiveDate"]).getTime() -
                    new Date(b["checkEffectiveDate"]).getTime()
            ),
        },
    }),
    [actions.STATISTICS_GET_REMITS_AND_PAYERS_REJECTED]: (
        state,
        errorMessage
    ) => ({
        ...state,
        getRemitPayersAndStatisticsPending: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    //Remit & Payer Stats

    [actions.STATISTICS_SET_REMITS_AND_PAYERS]: (
        state,
        { remitAndPayersStatistics }
    ) => ({
        ...state,
        remitAndPayersStatistics,
    }),

    [actions.STATISTICS_GET_FILES_PENDING]: (state) => ({
        ...state,
        getFiles: {
            pending: true,
        },
    }),
    [actions.STATISTICS_GET_FILES_FULFILLED]: (state, files) => ({
        ...state,
        getFiles: {
            ...initialStatusState,
        },
        files: files,
    }),
    [actions.STATISTICS_GET_FILES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getFiles: {
            // ...initialStatusState,
            error: true,
            errorMessage,
            pending: false
        },
    }),
    [actions.STATISTICS_SET_FILTER]: (state, { filters }) => ({
        ...state,
        filters
    }),
    [actions.REMIT_PAYERS_STATISTICS_SET_FILTER]: (state, { filters }) => ({
        ...state,
        remitsAndPayersfilters:filters
    }),
    [actions.STATISTICS_RESET]: (state) => ({
        ...state,
        stats: []
    }),
});