import { Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import React from 'react';

export function Message({ children, theme = 'info' } :{
    children: JSX.Element[] | JSX.Element | string,
    theme: string
}) {
    const [open, setOpen] = React.useState(true);
    return (
        <Collapse in={open}>
            <Alert
                severity={theme as any /* ??? something weird in TS typings there, likely broken */}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {children}
            </Alert>
        </Collapse>
    );
}