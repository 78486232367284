import React, { useEffect,useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    FormControl,
    Checkbox,
    Typography,
    Chip,
    FormControlLabel,
    Switch,
    Tooltip,
    FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';

import { Formik } from "formik";
import { Loadable } from '../../../common';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { PayerPlanDropdown } from '../PayerPlanDropdown';
import { ContractsSearchComponent } from '../../contractsPage/ContractsSearchComponent';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
    root: {
        flexGrow: 1,
    },
    checkboxlabel: {
        marginLeft: 0
    },
    switch: {
        margin: theme.spacing(1),
    },
    contractsDropdown:{
        position:"relative",
        top:"5px"
    },
    selectionAutocomplete:{
        cursor:"pointer",
        width:'100%'
    },
    selectionIcon:{
        width:"100%",
        margin:'0 !important',
        padding:'0 !important' 
    },
    selectionIconButton:{
        width:"100%",
        '&:hover': {
        background: 'none !important',
    },

    '& .MuiIconButton-label':{
        width:"100% !important",
        display:"block"
    },

     },

    contractsChip:{
        margin:"0 3px 5px 0",
    }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function PayerContractDialog({
    open, handleClose, mode, intl, initialValues, payerContractCreatePending,
    payerContractUpdatePending, getPayerPlansTinyPending, contractsList,
    actions: { payerContractCreate, payerContractUpdate, contractTiny, getPayerPlansTiny,setSelectedContracts, 
        checkContractsAssociation }
}) {

    const classes = useStyles();
    let shouldReloadGrid = false;
    const [isSliced, setIsSliced] = useState(true)

    const [isContractAssociated, setIsContractAssociated] = useState(false);
    const [contractAssociatedMsg, setContractAssociatedMsg] = useState('');

    useEffect(() => {
        contractTiny();
    }, [contractTiny]);

    useEffect(() => {
        getPayerPlansTiny();
    }, [getPayerPlansTiny]);

    useEffect(() => {
        setSelectedContracts(initialValues?.contracts?.map((item) => String(item?.id)))
        return () => {
            setSelectedContracts([])
        };
    }, [initialValues?.contracts]);

    const contractsAssociatedCallback = (payload) => {
        setIsContractAssociated(payload?.isAssociated ?? false);
        setContractAssociatedMsg(payload?.errorMessage ?? '');
    }

    const handleContractsChange = (contractIds, payerContractId) => {
        setIsContractAssociated(false);
        setContractAssociatedMsg('');

        if (mode === 'create') {
            checkContractsAssociation({ids: contractIds, payerContractId: null}, contractsAssociatedCallback);
        }
        else {
            checkContractsAssociation({ids: contractIds, payerContractId: payerContractId}, contractsAssociatedCallback);
        }
    }

    function handleCallback(reload) {
        handleClose(reload);
    }

    const filterOptions = createFilterOptions({
        stringify: (option:any) => option.name + ' ' + option.email,
    });

    const returnContractsMappingData = (data) => {
        return isSliced ?  data.slice(0, 2) : data
      }
  

    return (
        (<Dialog maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{mode === 'edit' ? 'Edit Payer Contract' : 'Add Payer Contract'}</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={payerContractCreatePending || payerContractUpdatePending || getPayerPlansTinyPending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors : any = {};
                            if (!values.displayName) {
                                errors.displayName = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.plan) {
                                errors.plan = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            shouldReloadGrid = true;
                            let payload = {
                                displayName: values.displayName,
                                autoClaimAnalysis: values.autoClaimAnalysis,
                                includeInMachineReadable: values.includeInMachineReadable,
                                contractIds: undefined,
                                payerPlanId: undefined,
                                id: undefined
                            }
                            if (values.contracts) {
                                payload = {
                                    ...payload,
                                    contractIds: values.contracts.map(contract => contract.id),
                                }
                            }
                            if (values.plan) {
                                payload = {
                                    ...payload,
                                    payerPlanId: values.plan.id,
                                }
                            }
                            if (mode === 'create') {
                                payerContractCreate(payload, handleCallback, shouldReloadGrid);
                            }
                            else {
                                payload = {
                                    ...payload,
                                    id: values.id,
                                }
                                payerContractUpdate(payload, handleCallback, shouldReloadGrid);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <div className={classes.root}>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                required
                                                label="Display Name"
                                                name="displayName"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.displayName}
                                                helperText={touched.displayName && errors.displayName}
                                                error={Boolean(touched.displayName && errors.displayName)}
                                            />
                                        </Grid>

                                        <Grid item className={`mt-2`}  xs={12} md={12}>
                                                                    
                                              <ContractsSearchComponent isMulti={true}
                                              contractKey="id"
                                              handleSetContract={(selected)=> {
                                                    let contracts = [...contractsList]?.filter((item)=> selected?.includes(String(item?.id)));
                                                    setFieldValue("contracts", contracts)
                                                    handleContractsChange(contracts?.map((item) => item?.id), values.id);
                                                }
                                            } chipBoxClass={classes.selectionIcon} iconButtonClass={classes.selectionIconButton} icon={ <Autocomplete

                                                onKeyDown={(e) => { e.preventDefault(); }}
                                                className={`${classes.selectionAutocomplete}`}

                                                filterOptions={filterOptions}
                                                open={false}

                                                value={values.contracts}
                                                onChange={(obj, updatedContracts, event) => {
                                                    if (event === "clear") {
                                                        setFieldValue("contracts", [])
                                                        setSelectedContracts([])                                                                        }
                                                    if (event === "removeOption") {
                                                        let updatedContractsIds = updatedContracts?.map((item) => String(item?.id));
                                                        setFieldValue("contracts", updatedContracts)
                                                        setSelectedContracts(updatedContractsIds);
                                                        handleContractsChange(updatedContractsIds, values.id);
                                                    }
                                                }}
                                                multiple
                                                options={contractsList}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(option:any, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                    </React.Fragment>
                                                )}
                                                renderTags={(tagValue, getTagProps) => {
                                                    const numTags = tagValue.length;
                                                    return (
                                                        <Typography variant="caption">
                                                            {returnContractsMappingData(tagValue)?.map((option, index) => (
                                                                <Chip
                                                                    size="small"
                                                                    color="secondary"
                                                                    key={index}
                                                                    label={option.name}
                                                                    {...getTagProps({ index })}
                                                                    className={classes.contractsChip}
                                                                />
                                                            ))}
                                                            {numTags > 2 && <Chip data-testid="CloseIcon" size="small" sx={{cursor:'pointer'}} onClick={()=>setIsSliced(!isSliced)} label={isSliced ? ` +${numTags - 2}` : '...'}/> }
                                                            </Typography>
                                                    )
                                                }
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Contracts"
                                                    />
                                                )}
                                            />} />
                                            <FormHelperText error>{isContractAssociated && contractAssociatedMsg}</FormHelperText>
                                                                </Grid>

                                        {/* <Grid className="mt-2" item xs={12} md={12}>

                            <div className={classes.contractsDropdown}>
                                            <ContractsSearchComponent defaultValue="Select Contracts" value={values.contracts || ""} isMulti={true}
                                            
                                            handleSetContract={(selected)=>
                                              setFieldValue("contracts", [...contractsList]?.filter((item)=>
                                                selected?.includes(String(item?.id))))
                                            }
                                            />

</div> */}
                                            {/* <Autocomplete
                                                onChange={(e, value) => setFieldValue("contracts", value)}
                                                filterOptions={filterOptions}
                                                value={values.contracts}
                                                multiple
                                                options={contractsList}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.name}
                                                renderOption={( props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                    </li>
                                                )}
                                                renderTags={(tagValue, getTagProps) => {
                                                    const numTags = tagValue.length;
                                                    return (
                                                        <Typography variant="caption">
                                                            {tagValue.slice(0, 2).map((option, index) => (
                                                                <Chip
                                                                    size="small"
                                                                    color="secondary"
                                                                    key={index}
                                                                    label={option.name}
                                                                    {...getTagProps({ index })}
                                                                />
                                                            ))}
                                                            {numTags > 2 && ` +${numTags - 2}`}
                                                        </Typography>
                                                    )
                                                }
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Contracts"
                                                    />
                                                )}
                                            /> */}
                                        {/* </Grid> */}
                                        <Grid className="mt-2" item xs={12} md={12}>
                                            <PayerPlanDropdown setDropDownFieldValue={setFieldValue} defaultValue={values.plan} touched={touched} errors={errors} />
                                        </Grid>
                                        <Grid className="mt-3" item xs={8}>
                                            <Tooltip
                                                arrow
                                                title={<Typography color="inherit">
                                                    Automatically analyze new claims
                                                </Typography>}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name="autoClaimAnalysis"
                                                            color="primary"
                                                            checked={values.autoClaimAnalysis}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="ml-0"
                                                            value={values.autoClaimAnalysis}
                                                        />
                                                    }
                                                    label="Auto Analyze"
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid className="mt-3" item xs={8}>
                                            <Tooltip
                                                arrow
                                                title={<Typography color="inherit">
                                                    Include in Machine Readable File
                                                </Typography>}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name="includeInMachineReadable"
                                                            color="primary"
                                                            checked={values.includeInMachineReadable}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="ml-0"
                                                            value={values.includeInMachineReadable}
                                                        />
                                                    }
                                                    label="Include in Machine Readable File"
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <Button onClick={handleClose} variant="contained" className={classes.button}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                type="submit"
                                                disabled={payerContractCreatePending || payerContractUpdatePending}
                                            >
                                                {(payerContractCreatePending || payerContractUpdatePending) ? 'Saving' : 'Save'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>)
    );
}