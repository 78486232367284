import React, { useState } from 'react'
import ErrorIcon from '@mui/icons-material/Error';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    IconButton: {
        padding: 6
    },
    iconColor: {
        color: '#fd397a'
    }
}));

function ExecutionErrorDialog({ executionError }) {

    const classes = useStyles();
    const [isErrorDialogOpen, setErrorDialog] = useState(false);

    const handleDialogOpen = () => {
        setErrorDialog(true);
    }

    const handleDialogClose = () => {
        setErrorDialog(false);
    }

    return (<>
        <IconButton
            className={classes.IconButton}
            aria-label="Execution Error"
            onClick={handleDialogOpen}
            size="large">
            <Tooltip title='Execution Error'>
                <ErrorIcon className={classes.iconColor} />
            </Tooltip>
        </IconButton>
        <Dialog fullWidth open={isErrorDialogOpen} onClose={handleDialogClose}>
            <DialogTitle>
                Execution Error
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    {executionError}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}

export default ExecutionErrorDialog