import { createReducer } from '../../util';
import * as actions from '../actions/settings';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    release: null,
    releaseNotes: null,
    recoveredAmountFlag: false,
    get: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    getReleaseVersion: {
        ...initialStatusState
    },
    getReleaseNotes: {
        ...initialStatusState
    }
};

export default createReducer(initialState, {
    [actions.SETTINGS_LIST_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
        data: []
    }),
    [actions.SETTINGS_LIST_FULFILLED]: (state, data) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        data: data,
    }),
    [actions.SETTINGS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.SETTINGS_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.SETTINGS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
    }),
    [actions.SETTINGS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.RELEASE_VERSION_PENDING]: (state) => ({
        ...state,
        getReleaseVersion: {
            pending: true,
        },
    }),
    [actions.RELEASE_VERSION_FULFILLED]: (state, release) => ({
        ...state,
        getReleaseVersion: {
            ...initialStatusState,
        },
        release
    }),
    [actions.RELEASE_VERSION_REJECTED]: (state, errorMessage) => ({
        ...state,
        getReleaseVersion: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.RELEASE_NOTES_PENDING]: (state) => ({
        ...state,
        getReleaseNotes: {
            pending: true,
        },
    }),
    [actions.RELEASE_NOTES_FULFILLED]: (state, releaseNotes) => ({
        ...state,
        getReleaseNotes: {
            ...initialStatusState,
        },
        releaseNotes
    }),
    [actions.RELEASE_NOTES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getReleaseNotes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
});