import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import ClaimAndRemitsPageComponent from './Claims&RemitsPage';
import { createSelector } from 'reselect';
import { selectClientListTiny } from '../../../../store/selectors/clients';
import { get, resetStats, setRemitsAndPayersStatisticsFilter,getRemitsAndPayersStatistics,setRemitsAndPayersStatistics } from '../../../../store/actions/statistics';
import { createCurrencyDataField,createNumberDataField ,createDateField, createPlainDateField} from '../../../../util/format';

const selectClaimFields:any = createSelector(
    () => [
        createCurrencyDataField('claimAmount', 'Claim Amount', { sortable: true, calculateTotal: true }),
        createNumberDataField('count', 'Count'),
        createDateField('creationDate', 'Creation Date',{ sortable: true}),
    ]
);

const selectRemitFields:any = createSelector(
    () => [
        createCurrencyDataField('paidAmount', 'Paid Amount', { sortable: true, calculateTotal: true }),
        createNumberDataField('count', 'Count'),
        createPlainDateField('checkEffectiveDate', 'Check EFT Date',{ sortable: true}),
    ]
);


const selectSelectedClient = createSelector(
    selectClientListTiny,
    (state) => state.dashboards.selectedClient,
    (clients: any, selectedClient: number) =>
        clients?.find((item) => item?.clientId === selectedClient)?.schema
);

export const ClaimsAndRemitsPage = injectIntl(
    connect(
        (state:any) => ({

            filters:state.statistics.remitsAndPayersfilters ,
            clients: selectClientListTiny(state),
            getStatsPending: state.statistics.getRemitPayersAndStatisticsPending.pending,
            claimFields: selectClaimFields(),
            remitFields: selectRemitFields(),
            remitAndPayersStatistics: state.statistics.remitAndPayersStatistics,
            selectedClient:selectSelectedClient(state)
            

        }),
        (dispatch) => ({
            actions: bindActionCreators({
                setStatisticsFilter: setRemitsAndPayersStatisticsFilter,
                statisticsGet: get,
                resetStats: resetStats,
                getRemitsAndPayersStatistics,
                setRemitsAndPayersStatistics
            }, dispatch)
        })
    )(ClaimAndRemitsPageComponent));