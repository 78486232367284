import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { Loadable } from '../../../../common';
import CloseIcon from '@mui/icons-material/Close';
import { ModifiersImport } from '../ModifiersImport';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function ImportConfirmationDialog({intl, open, handleRefresh, isDuplicate, 
    actions: { setMRFModifiersImportConfirmationDialog, setMRFModifiersImportDialog }
}) {

    const classes = useStyles();

    const handleDialogClose = () => {
        setMRFModifiersImportConfirmationDialog(null);
    }

    const handleImport = (duplicate) => {
        setMRFModifiersImportConfirmationDialog(null);
        setMRFModifiersImportDialog({open: true, isDuplicate: duplicate});
    }


    return (<>
        <Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={false}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Confirmation
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleDialogClose}
                                size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    {
                        <Grid>
                            {!isDuplicate && (
                                <Typography>Are you sure you want to import Modifiers?</Typography>
                            )}
                            {isDuplicate && (
                                <Typography>During Import would you like to update with latest percentages from the contracts? 
                                    If no then we will keep percentages you have already set here and just import new ones.</Typography>
                            )}
                        </Grid>
                    }
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    {isDuplicate && (
                        <>
                        <Button
                            onClick={() => handleImport(false)}
                            variant="contained"
                            color="secondary"
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => handleImport(true)}
                            variant="contained"
                            color="primary"
                        >
                            Yes
                        </Button>
                        </>
                    )}
                    {!isDuplicate && (
                        <Button
                            onClick={() => handleImport(false)}
                            variant="contained"
                            color="primary"
                        >
                            Import
                        </Button>
                    )}
                </DialogActions>
            </Loadable>
        </Dialog>
        <ModifiersImport handleRefresh={handleRefresh} />
    </>);
}

export default ImportConfirmationDialog
