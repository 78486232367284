/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { history } from "./app/util"
import { Routes } from "./app/router/Routes";
import {
  I18nProvider,
  LayoutSplashScreen,
  ThemeProvider,
} from "./_metronic";
import CacheBuster from 'react-cache-buster';
import pack from '../package.json';

export default function App({ store, Layout, persistor, basename }) {
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    (<CacheBuster
        currentVersion={pack.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<LayoutSplashScreen />} //If not pass, nothing appears at the time of new version check.
      >
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <Router history={history}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <LastLocationProvider>
              {/* Provide Metronic theme overrides. */}
                <ThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <Routes Layout={Layout} />
                  </I18nProvider>
                </ThemeProvider>
              </LastLocationProvider>
            </Router>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </CacheBuster>)
    /* Provide Redux store */
  );
}
