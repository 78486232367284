import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { CustomLabel, Loadable } from '../../../common';
import CloseIcon from '@mui/icons-material/Close';
import { EllipsisText } from '../../../common/EllipsisText';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function DependencyDialog({intl, open, selectedItems, deleteHospitalServicesPending, handleRefresh, 
    actions: { setSelected, deleteHospitalServices, setHopsitalServicesDependencyDialog }
}) {

    const classes = useStyles();

    const handleDialogClose = () => {
        setHopsitalServicesDependencyDialog(null);
        handleRefresh();
    }

    const handleDeleteCallback = () => {
        setHopsitalServicesDependencyDialog(null);
        setSelected([]);
        handleRefresh();
    }

    const handleDelete = () => {
        deleteHospitalServices(selectedItems, handleDeleteCallback);
    }


    return (
        (<Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={deleteHospitalServicesPending}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Delete
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleDialogClose}
                                size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    {selectedItems.length === 0 ? <Grid>
                        <Typography>No Hospital Service selected. Please select atleast one for delete.</Typography>
                    </Grid> :
                        <Grid>
                            <Typography>Are you sure you want to delete selected Hospital Service?</Typography>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        disabled={selectedItems.length === 0 || deleteHospitalServicesPending}
                        color="primary"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Loadable >
        </Dialog>)
    );
}

export default DependencyDialog
