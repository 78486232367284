import React from 'react';
import { Grid, IconButton, Tooltip, CircularProgress, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CachedIcon from '@mui/icons-material/Cached';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableChartIcon from '@mui/icons-material/TableChart';
import ReorderIcon from '@mui/icons-material/Shuffle';

const useStyles = makeStyles(theme => ({
    loader: {
        marginTop: theme.spacing(.5),
    },
}));

interface TableLevelActionProps {
    actions?: () => React.ReactNode;
    handleRefresh?: () => void;
    name?: string;
    onAdd?: () => void;
    loading?: boolean;
    actionLevelGrid?: () => React.ReactNode;
    handleColumnsDialogOpen?: () => void;
    showFilterColumnsDialog?: boolean;
    showReorderColumnsDialog?: boolean;
    handleReorderColumnsDialogOpen?: () => void;
}

export default function TableLevelAction({ actions, handleRefresh, name, onAdd, loading, actionLevelGrid, handleColumnsDialogOpen, showFilterColumnsDialog,
    showReorderColumnsDialog, handleReorderColumnsDialogOpen } : TableLevelActionProps) {

    const classes = useStyles();

    return (
        <Box alignItems='center' display='flex' justifyContent='space-between'>
            <Box sx={{width:'70%'}}>
                {actionLevelGrid && actionLevelGrid()}
            </Box>
            <Box>
                <Grid container justifyContent="flex-end" spacing={1}>
                    {loading && (
                        <Grid item>
                            <CircularProgress color='primary' size={15} className={classes.loader} />
                        </Grid>
                    )}
                    {onAdd && (
                        <Grid item>
                            <Tooltip title={`Add ${name}`}>
                                <IconButton aria-label="add" size='small' onClick={onAdd}>
                                    <AddCircleIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                    {actions && actions()}
                    {showFilterColumnsDialog &&
                        <Grid item>
                            <Tooltip title="Manage Columns">
                                <IconButton aria-label="refresh" size='small' onClick={handleColumnsDialogOpen}>
                                    <TableChartIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }
                    {showReorderColumnsDialog && <Grid item>
                        <Tooltip title="Reorder Columns">
                            <IconButton aria-label="refresh" size='small' onClick={handleReorderColumnsDialogOpen}>
                                <ReorderIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>}
                    {handleRefresh && (
                        <Grid item>
                            <Tooltip title="Refresh">
                                <IconButton aria-label="refresh" size='small' onClick={handleRefresh}>
                                    <CachedIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}

                </Grid>
            </Box>
        </Box>

    );
}