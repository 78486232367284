import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CustomLabel, Loadable } from '../../../common';
import { useEffect } from 'react';

function ToleranceMatchDialog(props) {
    const { open, handleClose, toleranceMatchPending, toleranceMatchResult, remitPayerMappingPending, actions: { remitPayerToleranceMatch, remitPayerMapping } } = props;

    const [mapping, setMapping] = useState([]);

    useEffect(() => {
        if (toleranceMatchResult) {
            const temp = toleranceMatchResult.map(item => {
                return {
                    ...item,
                    payerContracts: item.payerContracts.map(pc => {
                        return {
                            ...pc,
                            checked: false
                        }
                    })
                }
            });
            setMapping(temp);
        }
    }, [toleranceMatchResult]);


    useEffect(() => {
        if (open) {
            remitPayerToleranceMatch();
        }
    }, [open, remitPayerToleranceMatch]);

    const handleCheckboxChange = (_tIndex, _pcIndex, checked) => {
        const tempToleranceMatchResult = [...mapping];
        const temp = tempToleranceMatchResult.map((item, tIndex) => {
            if (tIndex === _tIndex) {
                return {
                    ...item,
                    payerContracts: item.payerContracts.map((pc, pcIndex) => {
                        if (_pcIndex === pcIndex) {
                            return {
                                ...pc,
                                checked
                            }
                        }
                        return {
                            ...pc,
                        }
                    })
                }
            }
            return item
        })

        setMapping(temp);
    }

    const handleRemitPayerMapping = () => {
        const payload = mapping.map(item => {
            return {
                id: item.id,
                payerContractIds: item.payerContracts.filter(pc => pc.checked === true).map(item => item.id)
            }
        });

        remitPayerMapping(payload, handleMappingCallback);
    }

    const handleMappingCallback = () => {
        handleClose(true);
    }

    return (
        (<Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Grid container className='align-items-center'>
                    <Grid item xs={11}>
                        <Typography variant="h6">Claim Payer Matching</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="close" onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={toleranceMatchPending || remitPayerMappingPending}>
                    <Grid container>
                        <Grid item xs={6} className="mb-2">
                            <ol className='lower-roman'>
                                {mapping?.map((item, index) =>
                                    <li key={`key-${index}`}>
                                        <CustomLabel label="Payer Name" data={item?.name} />
                                        <Grid>
                                            <h6>Contracts:</h6>
                                            <ul>
                                                {item?.payerContracts?.map((pc, pcIndex) => <li key={`contract-key-${pcIndex}`}>
                                                    <FormControlLabel
                                                        className='m-0'
                                                        control={
                                                            <Checkbox
                                                                checked={pc.checked}
                                                                onChange={(e) => handleCheckboxChange(index, pcIndex, e.target.checked)}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={pc?.name}
                                                    />
                                                </li>)}
                                            </ul>
                                        </Grid>
                                    </li>
                                )}
                            </ol>
                            {(!mapping || mapping?.length === 0) && <span>N/A</span>}
                        </Grid>
                    </Grid>
                </Loadable>
            </DialogContent>
            {(mapping && mapping.length > 0) &&
                <Grid container className='justify-content-end p-4'>
                    <Button onClick={() => handleClose(false)} variant="contained">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={`ml-3`}
                        onClick={handleRemitPayerMapping}
                        disabled={remitPayerMappingPending}
                    >
                        {(remitPayerMappingPending) ? 'Saving Changes' : 'Save'}
                    </Button>
                </Grid>
            }
        </Dialog >)
    );
}

export default ToleranceMatchDialog
