import React from 'react'
import { useState } from 'react'
import { CustomLabel, Loadable, PopoverLink } from '../../../../common';
import {
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Edit as EditIcon } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useEffect } from 'react';
import { ClaimStatusLogs } from '../ClaimStatusLogs';

const useStyles = makeStyles(theme => ({
    autoComplete: {
        width: '100%',
        "& .MuiFormControl-marginNormal": {
            marginTop: 0
        },
    },
    box:{
        display: 'inline-block',
    },
    underline: {
        textDecoration: 'underline',
        color: '#2739c1',
        cursor: 'pointer'
    },
    rightBox:{
        display: 'inline-block',
        float: 'right'
    },

}));

function ChangeStatusComponent({ claimAnalysis, changeStatusPending,claimStatusList,
    setShouldReloadGrid, actions: {updateSingleClaimStatus, getClaimAllDetails,getClaimStatuses, setClaimStatusHistoryDialog } }) {
    const classes = useStyles();

    const [isEdit, setEdit] = useState(false);
    const [isConfirmDialogOpen, setDialogOpen] = useState(false);
    const [status, setStatus] = useState(null);
    

    // useEffect(() => {
    //     getClaimStatuses()
    // }, [getClaimStatuses]);

    useEffect(() => {
        setStatus(claimAnalysis?.claimStatusId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimAnalysis,isEdit]);

    const handleChangeMode = (bool) => {
        setEdit(bool);
    }

    const changeStatusCallback = (response) => {       
        getClaimAllDetails(claimAnalysis?.id);
 
        setDialogOpen(false);
        setEdit(false);

        setShouldReloadGrid(true); //refresh claim grid to update contract name
    }

    const handleStatusChange = (value) => { 
        setDialogOpen(true);
        setStatus(value);
    }

    const handleConfirmDialogClose = () => {
        setDialogOpen(false);
        setEdit(false);
    }

    const handleConfirmChangeStatus = () => {
        updateSingleClaimStatus({},status?.id || status,claimAnalysis?.id ,changeStatusCallback);
    }

    const openClaimHistoryDialog = () => {
        setClaimStatusHistoryDialog(true);
    }

    return (<>
        {isEdit ? <Grid container alignItems='center'>
            <Grid item xs={7}>
                <Autocomplete
                    disableClearable
                    className={classes.autoComplete}
                    value={claimStatusList?.find(x=> x.value === status)?.name || ''}
                    onChange={(e, option) => handleStatusChange(option?.value)}
                    // isOptionEqualToValue={(option, value) => {
                    //     return option?.name === value?.name || option?.name === value
                    // }}
                    name={`status`}
                    options={claimStatusList}
                    getOptionLabel={(option) => option?.name || option}
                    renderInput={(params) =>
                        <TextField {...params}
                            required
                            name={`status`}
                            label="Status"
                            margin="normal"
                        />
                    }
                />
            </Grid>
            <Grid item xs={2}>
                <Tooltip title="Cancel">
                    <IconButton className='ml-2' aria-label="process" size='small' onClick={handleConfirmDialogClose}>
                        <CloseIcon style={{ fontSize: '20px' }} />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid> :
            <>
                <CustomLabel label="Status"
                    data={
                        <PopoverLink
                            columnData={claimStatusList?.find(x=> x.value === claimAnalysis?.claimStatusId)?.name || ''}
                            title={claimStatusList?.find(x=> x.value === claimAnalysis?.claimStatusId)?.name || ''}
                            customClasses='aligned-content'
                    
                    
                            />
                    }
                    type="claim-contract"
                />

                <Box className={classes.box}>
                    <Tooltip title="Change Status">
                        <IconButton
                            className='ml-2 p-1'
                            aria-label="Edit"
                            onClick={() => handleChangeMode(true)}
                            size="large">
                            <EditIcon style={{ fontSize: '22px' }} />
                        </IconButton>
                    </Tooltip>


                </Box>
                <Box className={classes.rightBox}>
                    <Typography onClick={openClaimHistoryDialog} color='primary' 
                        className={`${classes.underline}`} >
                        History
                    </Typography>
                </Box>
            </>
        }
        <Dialog open={isConfirmDialogOpen}>
            <Loadable loading={changeStatusPending}>
                <DialogTitle>
                    Claim Status
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to change the claim status to {claimStatusList?.find(x=> x.value === status)?.name || ''}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type='button' onClick={handleConfirmDialogClose}>
                        Cancel
                    </Button>
                    <Button
                        type='button'
                        onClick={handleConfirmChangeStatus}
                        color="primary"
                    >
                        Update
                    </Button>
                </DialogActions>
            </Loadable>
        </Dialog>
        <ClaimStatusLogs analysisId={claimAnalysis?.id} />
    </>);
}

export default ChangeStatusComponent