import React from 'react';
import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataTable, Loadable } from '../../../../common';
import { Card } from 'react-bootstrap';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
}));

const DEFAULT_SORT = [{ id: 'displayName', direction: 'asc' }];

export default function BookingDialog(props) {
    const {
        getRatesPending, shouldCallNegotiatedAPI, handleCallNegotiatedAPI, handleBack, ratesFields, ratesData, sort, order, filter,
        pricerConfigFromShoppables, handleRedirectToShoppables,
        actions: { setOrder, setSort, clearRates }
    } = props;
    const classes = useStyles();

    useEffect(() => {

        return () => {
            handleCallNegotiatedAPI(true);
            clearRates();
        }
    }, [handleCallNegotiatedAPI, clearRates]);

    const handleRedirectBack = () => {
        if (pricerConfigFromShoppables) {
            handleRedirectToShoppables();
        } else {
            handleBack();
        }
    }


    return (
        (<Loadable loading={getRatesPending}>
            <Grid item xs={12} className="mt-3">
                <Card variant="outlined">
                    <Card.Header as="h5" className='text-center'>Negotiated Rates</Card.Header>
                    <Card.Body className='text-dark px-5 py-4'>
                        <Grid container>
                            <Grid item md={12} className="col-md-12">
                                <DataTable
                                    name="pricer-config-rates-landing"
                                    fields={ratesFields}
                                    data={ratesData}
                                    // handleRefresh={handleRefresh}
                                    defaultSort={DEFAULT_SORT}
                                    loading={false}
                                    title="Negotiated Rates"
                                    order={order}
                                    onOrder={setOrder}
                                    sort={sort}
                                    onSort={setSort}
                                    stickyHeader={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid className='pt-2 justify-content-end' container>
                            <Button
                                size='sm'
                                onClick={handleRedirectBack}
                                variant="contained"
                                className='mr-2'>
                                Back
                            </Button>
                        </Grid>
                    </Card.Body>
                </Card>
            </Grid>
        </Loadable>)
    );
}