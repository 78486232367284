import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { Loadable } from '../../../common';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dropZone: {
        minHeight: 150,
        border: 'none',
        backgroundColor: '#EAEAEA',
        borderRadius: '20px'
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: '100%',
    },
}));

function DownloadTemplate(props) {
    const { downloadTemplatePending, open, handleClose, actions: { downloadTemplate } } = props;
    const classes = useStyles();
    const [type, setType] = useState('');

    const handleTypeChange = (e) => {
        setType(e.target.value);
    }

    const handleDownloadTemplate = () => {
        downloadTemplate(type, downloadCallback)
    }
    const downloadCallback = ({ file, fileName }) => {
        let url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();

        handleClose();
        setType('');
    }

    return (
        (<Dialog
                maxWidth="sm"
                fullWidth open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">Download Template</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={downloadTemplatePending}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="type">Select File Type</InputLabel>
                                <Select
                                    value={type}
                                    onChange={handleTypeChange}
                                    input={<Input id="type" />}
                                    inputProps={{
                                        name: "type",
                                        id: "type"
                                    }}
                                >
                                    <MenuItem
                                        value='Charge'
                                    >
                                        Charge
                                    </MenuItem>
                                    <MenuItem
                                        value='Payment'
                                    >
                                        Payment
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container className='justify-content-end pt-4'>
                            <Button type='button' onClick={handleClose} variant="contained" className='mr-3'>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDownloadTemplate}
                                disabled={type === ''}
                            >
                                Download
                            </Button>
                        </Grid>
                    </Grid>
                </Loadable>
            </DialogContent>
        </Dialog>)
    );
}

export default DownloadTemplate