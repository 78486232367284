import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { convertDateToLocal } from '../../util/date';

const useStyles = makeStyles(theme => {
    return {
        date: {
            fontSize: '11px',
        },
        text: {
            lineHeight: "1.3"
        },
        hr: {
            margin: "5px"
        },
        comments: {
            maxHeight: 120,
            overflowY: 'scroll',

        }
    }
});

function UserComments({ comments = [] }) {
    const classes = useStyles();

    return (
        <div className={classes.comments}>
            {comments.map(comment =>
                <>
                    <Grid key={comment.createdAt} container alignItems='center'>
                        <Grid item lg={3} md={4}>
                            <Typography>
                                <b>{comment.createdBy}</b>&nbsp;&nbsp; <span className={classes.date}>{convertDateToLocal(comment.createdAt)}</span>
                            </Typography>
                        </Grid>
                        <Grid item lg={9} md={8}>
                            <Typography className={classes.text}>
                                {comment.comment}
                            </Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr} />
                </>
            )}
        </div >
    )
}



export default UserComments;
