import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';

import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../partials/content/Portlet";
import { PagedTable } from '../../common';
import EditIcon from '@mui/icons-material/Edit';
import { PayerImport } from './PayerImport';
import { EnableDialog } from './EnableDialog';
import { PayerDialog } from './PayerDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import classNames from 'classnames';
import { ToleranceMatchDialog } from './ToleranceMatchDialog';
import { DependencyDialog } from './DependencyDialog';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { ROLE_ADMIN, ROLE_PARTNER_ADMIN, ROLE_SUPERADMIN } from '../../store/constants/roles';

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];
const SHOW_SEARCH_FILTERS = true;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },

    icon: {
        fontSize: 17,
        marginTop: 1,
    }
}));

export default function RemitPayersPage({
    intl,
    payerData, payerFields, payerContracts, userRole,
    actions: {
        remitPayersLanding,
        searchGetByName,
        searchReset,
        resetPagedTable,
        setRemitPayerEditDialog,
        setList,
        setRemitPayerEnableDialog,
        payersGetPayerContractsTiny,
        setRemitPayToleranceMatchDialog,
        setRemitDependencyDialog,
        setSelectedItems
    }
}) {
    const [isImportDialogOpen, setImportDialog] = useState(false);
    const [refresh, setRefresh] = useState(Promise.resolve());

    const classes = useStyles();

    useEffect(() => {
        const pageName = {
            name: 'remit-payers-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name);
            setSelectedItems([]);
        }
    }, [searchGetByName]);

    useEffect(() => {
        payersGetPayerContractsTiny()
    }, [payersGetPayerContractsTiny]);

    const handlePayerEdit = (payer) => {
        setRemitPayerEditDialog({ open: true, payer: payer, bulk: false });
    };

    // const handleCheckbox = (selectedRows, payer) => {
    //     updatePayerStatus(payer.id, !payer.active, handleRefresh);
    // };

    const handleAddPayer = () => {
        setRemitPayerEditDialog({ open: true, payer: null, bulk: false });
    }

    function handleLoad(sort, filter, pagination) {
        remitPayersLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    const handleClose = (shouldReloadGrid) => {
        setRemitPayerEditDialog(null);
        setRemitPayerEnableDialog(null);
        setRemitPayToleranceMatchDialog(null);
        if (shouldReloadGrid === true) {
            setList([]);
            handleRefresh();
        }
    }


    function handleImportPayerInfo() {
        setImportDialog(true);
    }

    function handleImportDialogClose() {
        setList([]);
        setImportDialog(false);
        handleRefresh();
    }

    function handlePayerConfirmation(isEnable) {
        setRemitPayerEnableDialog({
            open: true,
            isEnableClicked: isEnable
        })
    }

    function handleBulkEdit() {
        setRemitPayerEditDialog({ open: true, payer: null, bulk: true });
    }

    function handleToleranceMatch() {
        setRemitPayToleranceMatchDialog({ open: true });
    }

    function handleBulkDelete() {
        setRemitDependencyDialog({ open: true });
    }

    function tableLevelActions() {
        return (
            <>
                {(userRole === ROLE_SUPERADMIN || userRole === ROLE_PARTNER_ADMIN || userRole === ROLE_ADMIN) &&
                    <Grid item>
                        <Tooltip title="Delete Remit Payers">
                            <IconButton aria-label="delete" size='small'
                                onClick={handleBulkDelete}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
                <Grid item>
                    <Tooltip title="Import Remit Payers Count">
                        <IconButton aria-label="download" size='small'
                            onClick={handleImportPayerInfo}
                        >
                            <i className="fas fa-upload"></i>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Edit">
                        <IconButton aria-label="edit" size='small' onClick={() => handleBulkEdit()}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Enable">
                        <IconButton aria-label="enable" size='small' onClick={() => handlePayerConfirmation(true)}>
                            <CheckCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Disable">
                        <IconButton aria-label="disable" size='small' onClick={() => handlePayerConfirmation(false)}>
                            <i className={classNames("fa fa-solid fa-ban", classes.icon)}></i>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Payer Matching">
                        <IconButton aria-label="edit" size='small' onClick={() => handleToleranceMatch()}>
                            <i className={classNames("fas fa-equals", classes.icon)}></i>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    function payerInfoActions(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    aria-label="Edit"
                    onClick={() => handlePayerEdit(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
            </div>
        );
    }

    return (
        <>
            <Helmet title="Remit Payers" />
            <Portlet>
                <PortletHeader
                    title="Remit Payers"
                    handleRefresh={handleRefresh}
                    showSearchFilters={SHOW_SEARCH_FILTERS}
                    showSearchFiltersDropdown={false}
                    name="remit-payers-landing"
                />
                <PortletBody>
                    <div className="row">
                        <div className="col-md-12">
                            <PagedTable
                                name="remit-payers-landing"
                                fields={payerFields}
                                data={payerData}
                                onLoad={handleLoad}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                onAdd={handleAddPayer}
                                tableLevelActions={tableLevelActions}
                                hasCheckbox={true}
                                showSelectAll={true}
                                title="Remit Payer"
                                criteriasSaveEnabled={true}
                                showSearchFilters={SHOW_SEARCH_FILTERS}
                                hasActions={true}
                                renderActionColumn={payerInfoActions}
                            />
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
            <PayerImport open={isImportDialogOpen} handleClose={handleImportDialogClose} />
            <EnableDialog handleClose={handleClose} />
            <PayerDialog
                payerContracts={payerContracts}
                handleClose={handleClose}
            />
            <ToleranceMatchDialog
                handleClose={handleClose}
            />
            <DependencyDialog handleClose={handleClose} />
        </>
    );
}