import React, { useEffect, useState } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { IconButton, TablePagination, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DataTable, DataFilter, TablePaginationActions } from '../../../common';
import { DeleteConfirmation } from "../../../common/DeleteConfirmation";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
  textField: {
    width: 400,
  },
}));

export default function UsersPage(props) {
  const classes = useStyles();

  const search = useLocation().search;
  const persist = new URLSearchParams(search).get('persist');

  const {
    usersFilteredData, fields, sort, userListPending, userDeletePending,
    order, filter, actions: { setSort, setOrder, usersList, setFilter, userDelete }
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (!persist) {
      setFilter('');
    }
    usersList();
  }, [usersList]);

  const handleUserEdit = (user) => {
    props.history.push(`/admin/users/edit/${user.id}`);
  }

  const handleCallBack = () => {
    usersList();
  }

  const handleUserDelete = (user) => {
    if (user) {
      userDelete(user.id, handleCallBack);
    }
  }

  const handleSearchInput = (value) => {
    setFilter(value);
  }

  const handleAddUser = () => {
    props.history.push('/admin/users/edit/new');
  }

  function usersActions(rowData) {
    return (
      <div className='d-flex'>
        <IconButton
          color="primary"
          className='p-1'
          aria-label="Edit"
          onClick={() => handleUserEdit(rowData)}
          size="large">
          <Tooltip title="Edit">
            <EditIcon />
          </Tooltip>
        </IconButton>
        <DeleteConfirmation handleDelete={() => handleUserDelete(rowData)} />

      </div>
    );
  }

  function handleRefresh() {
    usersList();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (<>
    <Helmet title="User Management" />
    <Portlet>
      <PortletHeader
        title="Users"
        toolbar={
          <PortletHeaderToolbar>
            <DataFilter
              onChange={handleSearchInput}
              placeholder="Search"
              cssClass={classes.textField}
              filter={filter}
            />
          </PortletHeaderToolbar>
        }
      />
      <PortletBody>
        <DataTable
          fields={fields}
          data={usersFilteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          onAdd={handleAddUser}
          order={order}
          onOrder={setOrder}
          sort={sort}
          onSort={setSort}
          loading={userListPending || userDeletePending}
          name="User"
          handleRefresh={handleRefresh}
          showPagination={true}
          footerPagination={() => (
            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
              colSpan={fields.length + 2}
              count={usersFilteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          )}

          hasActions={true}
          renderActionColumn={usersActions}
        />
      </PortletBody>
    </Portlet>
  </>);
}
