import { Button, Grid, Popover, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CustomLabel, Loadable } from '..';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../partials/content/Portlet';

const useStyles = makeStyles(theme => ({
    portlet: {
        'box-shadow': 'none',
        marginBottom: 0,
        "& .kt-portlet__head": {
            borderColor: theme.palette.secondary.main,
            // marginLeft: theme.spacing(3.5),
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        pointerEvents: 'auto',
        width: 300,
    },
    underline: {
        textDecoration: 'underline'
    },
    button: {
        minWidth: 26,
        width: 26,
        textDecoration: 'none'
    },
    cursorPointer: {
        cursor: 'pointer'
    }
}));

function DetailsPopover({ isPopoverOpen, anchorEl, pending, title, items, actions: { paperEnter, popoverLeave } }) {
    const classes = useStyles();
    const [isEllipsisClicked, setEllipsesClicked] = useState(false);

    useEffect(() => {
        return () => {
            setEllipsesClicked(false);
        }
    }, [isPopoverOpen]);

    const handleEllipsisClicked = () => {
        setEllipsesClicked(true);
    }

    // Display ellipsis for displaying long description // ref claim adjustments
    function renderEllipseData(item) {
        return <CustomLabel label={item.label}
            data={
                isEllipsisClicked
                    ? item.longData : <>
                        <span>
                            {item.data}
                            <Button
                                className={classes.button}
                                onClick={handleEllipsisClicked}
                            >
                                ...
                            </Button>
                        </span>
                    </>
            }
        />
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <Popover
                // id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                PaperProps={{
                    onMouseEnter: paperEnter,
                    onMouseLeave: popoverLeave
                }}
                disableRestoreFocus
            >
                <Loadable loading={pending}>
                    <Portlet className={classes.portlet}>
                        {title && (
                            <PortletHeader
                                toolbar={
                                    <PortletHeaderToolbar>
                                        <Grid className='kt-portlet__head-label py-2'>
                                            <Grid className='kt-portlet__head-title'>
                                                {title}
                                            </Grid>
                                        </Grid>
                                    </PortletHeaderToolbar>
                                }
                            />
                        )}
                        <PortletBody>
                            <Grid container>
                                {items?.map((item, index) =>
                                    <Grid key={`grid-row-${index}`} item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                {item.type === 'LINK' ?
                                                    <Link
                                                        to={item.data}
                                                        className={classes.underline}
                                                    >
                                                        {item.label}
                                                    </Link>
                                                    :
                                                    item.type === 'ELLIPSE' ?
                                                        renderEllipseData(item)
                                                        :
                                                        item.type === 'TAB' ?
                                                            <Typography onClick={() => openInNewTab(`${item.data}`)} color='primary' className={`${classes.underline} ${classes.cursorPointer}`} >
                                                                {item.label}
                                                            </Typography> :
                                                            <CustomLabel
                                                                label={item.label}
                                                                data={item.data}
                                                            />
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </PortletBody>
                    </Portlet>
                </Loadable>
            </Popover>
        </>
    )
}

export default React.memo(DetailsPopover)