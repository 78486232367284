import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, IconButton, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';

import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../partials/content/Portlet";
import { PagedTable } from '../../common';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { DependencyDialog } from './DependencyDialog';
import { PayerIdMappingEditPage } from './PayerIdMappingEditPage';
import { CONTRACT_STATE_FINALIZED } from '../../store/constants/contract';
import { errorToastify } from '../../util/toast';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

const DEFAULT_SORT = [];

const useStyles = makeStyles((theme) => ({
}));

export default function PayerIdMappingPage({ intl, fields, data, mappingDownloadTemplatePending, mappingUploadPending, 
    checkboxStatus,  
    actions: {
        payerIdMappingLanding, setDependencyDialog, createEditReset, setEditDialog, 
        getTinyContractsByState, mappingDownloadTemplate, mappingUpload, setSelectedItems
    }}) {

    const [refresh, setRefresh] = useState(Promise.resolve());
    const uploadMappingRef = useRef(null);
    const [file, setFile] = useState(null);
    const [isPromptDialogOpen, setPromptDialog] = useState(false);

    const classes = useStyles();

    function handleLoad(sort, filter, pagination) {
        payerIdMappingLanding(pagination, sort, filter);
    }

    useEffect(() => {
        getTinyContractsByState(CONTRACT_STATE_FINALIZED);
    }, [getTinyContractsByState]);

    useEffect(() => {
        return () => {
            createEditReset();
            setSelectedItems([]);
        }
    }, [createEditReset]);

    const handleRefresh = () => {
        setRefresh(Promise.resolve());
    }

    const handleAddMapping = () => {
        setEditDialog({open: true, payerIdMapping: null, bulk: false});
    }

    const handleEditMapping = (rowData) => {
        setEditDialog({open: true, payerIdMapping: rowData, bulk: false});
    }

    function handleBulkEditMapping() {
        setEditDialog({ open: true, payerIdMapping: null, bulk: true });
    }

    const handleDeleteMapping = (rowData) => {
        setDependencyDialog({ open: true, payerIdMapping: rowData });
    }

    function handleDowloadTemplate() {
        mappingDownloadTemplate(downloadTemplateCallback);
    }

    function downloadTemplateCallback({ file, fileName }) {
        const displayName = fileName;
        let url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = url;
        a.download = `Payer_ID_Mapping_${displayName.replace(/\s/g, '')}`;
        a.click();
    }

    function handleFileUpload() {
        uploadMappingRef.current.click();
    }

    const handleFileUploadCallback = () => {
        setPromptDialog(true);
        setFile(null);
        handleRefresh();
        (document.getElementById('payer-id-mapping-upload-input') as HTMLInputElement).value = '';
    }

    const errorCallback = () => {
        setFile(null);
        (document.getElementById('payer-id-mapping-upload-input') as HTMLInputElement).value = '';
    }

    const handleFileChange = (event) => {
        if (event.target.files.length > 0 && event.target.files[0].type === 'text/csv') {
            setFile(event.target.files[0]);
            mappingUpload(event.target.files[0], handleFileUploadCallback, errorCallback);
        }
        else if (event.target.files.length > 0) {
            errorToastify('Invalid format, please make sure the file is in CSV format.');
        }
    }

    function handlePromptDialogClose() {
        setPromptDialog(false);
    }

    function mappingActions(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleEditMapping(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
                <IconButton
                    onClick={() => handleDeleteMapping(rowData)}
                    color={"primary"}
                    className='p-1'
                    aria-label="Delete"
                    size="large">
                    <Tooltip title="Delete" >
                        <DeleteIcon />
                    </Tooltip>
                </IconButton>
            </div>
        );
    }

    const mappingTableActions = () => {
        return (
            <>
                <Grid item>
                    <Tooltip title="Download Template">
                        <IconButton aria-label="download" size='small' onClick={handleDowloadTemplate} disabled={mappingDownloadTemplatePending}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Upload Payers">
                        <IconButton aria-label="upload" size='small'
                            onClick={handleFileUpload}
                            disabled={mappingUploadPending}
                        >
                            <UploadIcon />
                            <input
                                id='payer-id-mapping-upload-input'
                                onInput={handleFileChange}
                                ref={uploadMappingRef}
                                type="file"
                                hidden
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Payer Contract Mapping">
                        <IconButton aria-label="edit" size='small' onClick={handleBulkEditMapping}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    return (
        <>
            <Helmet title="Payer ID Mapping" />
            <Portlet>
                <PortletHeader
                    title="Payer ID Mapping"
                    handleRefresh={handleRefresh}
                    name="payer-id-mapping-landing"
                    showSearchFilters={false}
                />
                <PortletBody>
                    <div className="row">
                        <div className="col-md-12">
                            <PagedTable
                                name="payer-id-mapping-landing"
                                title="payerIdMapping"
                                fields={fields}
                                data={data}
                                onLoad={handleLoad}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                onAdd={handleAddMapping}
                                criteriasSaveEnabled={false}
                                hasActions={true}
                                renderActionColumn={mappingActions}
                                showSearchFilters={false}
                                tableLevelActions={mappingTableActions}
                                tableLevelLoading={mappingDownloadTemplatePending || mappingUploadPending}
                                hasCheckbox={true}
                                showSelectAll={true}
                            />
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
            <DependencyDialog handleRefresh={handleRefresh} />
            <PayerIdMappingEditPage handleRefresh={handleRefresh} />
            <Dialog open={isPromptDialogOpen} onClose={handlePromptDialogClose}>
                <DialogTitle>
                    Upload Payer IDs
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="success">The payers IDs has been uploaded successfully.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePromptDialogClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
