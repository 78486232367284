import React, { useState } from "react";

import {
    FormControl,
    TextField,
    Grid,
    IconButton,
    Checkbox,
    Typography,
    Chip,
    Tooltip,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import { Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
    formControlAuto: {
        margin: theme.spacing(2),
        marginTop: 0,
        width: '85%',
    },
    listGrid: {
        maxHeight: '300px',
        overflowY: 'auto'
    },
}));

export default function ContractAllowableCodeSelection({
    values, setFieldValue, casCodes
} : {
    values: any,
    setFieldValue: (field: string, value: any) => void,
    casCodes: any[]
}) {
    const [codes, setCodes] = useState('');
    const [validationError, setValidationError] = useState(null);

    const classes = useStyles();

    const casCodesFilterOption = createFilterOptions<any>({
        stringify: option => `${option.code} (${option.shortdesc})`,
    });

    const handleDeleteCascode = (index) => {
        const temp = [...values.contractOtherAllowableXref];
        temp.splice(index, 1)
        setFieldValue('contractOtherAllowableXref', temp);
    }

    const handleCodesChange = (e) => {
        setCodes(e.target.value);
    }

    const handleAddToList = () => {
        const regex = /^[0-9a-zA-Z,]*$/g;
        let newlyAddedItems = [];
        if (codes.match(regex)) {
            const splittedCodes = [...new Set(codes.split(','))];
            let hasError = false;
            for (let item of splittedCodes) {
                if (item === '1' || item === '2' || item === '3') {
                    hasError = true;
                }
                else {
                    const codeObj = casCodes.find(cc => cc.code.toLowerCase() === item.toLowerCase());
                    const isAlreadyExisting = values.contractOtherAllowableXref.find(cc => cc.code.toLowerCase() === item.toLowerCase());
                    if (codeObj && isAlreadyExisting === undefined) {
                        newlyAddedItems.push(codeObj);
                    }
                }
            }
            if (hasError) {
                setValidationError('Patient responsibility codes cannot be used in other allowables');
            }
            else {
                setFieldValue("contractOtherAllowableXref", [...values.contractOtherAllowableXref, ...newlyAddedItems])
                setValidationError(null);
                setCodes('');
            }
        } else {
            setValidationError('Codes are not pasted as per the required format');
        }
    }

    return (<>
        <Grid container>
            <Grid item xs={5}>
                <TextField
                    type="text"
                    multiline
                    label="Codes"
                    name="codes"
                    className={classes.formControlAuto}
                    placeholder="Type comma separated denial codes to be selected automatically"
                    margin="normal"
                    onChange={e => handleCodesChange(e)}
                    value={codes}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    helperText={validationError ?? 'e.g. p11,1,2,6,7'}
                    error={Boolean(validationError)}
                />
            </Grid>
            <Grid item xs={2}>
                <IconButton
                    color="primary"
                    onClick={handleAddToList}
                    edge="start"
                    aria-label="Add"
                    className='mt-3'
                    size="large">
                    <Tooltip title='Add Items from text field to selected list'>
                        <AddIcon />
                    </Tooltip>
                </IconButton>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={5}>
                <FormControl className={classes.formControlAuto}>
                    <Autocomplete
                        fullWidth
                        onChange={(e, value) => setFieldValue("contractOtherAllowableXref", value)}
                        filterOptions={casCodesFilterOption}
                        value={values.contractOtherAllowableXref}
                        multiple
                        options={casCodes}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option?.name}
                        isOptionEqualToValue={(option, value) => option?.code === value?.code}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {`${option?.code} (${option?.shortdesc})`}
                            </li>
                        )}
                        renderTags={(tagValue, getTagProps) => {
                            const numTags = tagValue.length;
                            return (
                                <Typography variant="caption">
                                    {tagValue.slice(0, 2).map((option, index) => (
                                        <Chip
                                            size="small"
                                            color="secondary"
                                            key={index}
                                            label={option?.code}
                                            {...getTagProps({ index })}
                                        />
                                    ))}
                                    {numTags > 2 && ` + ${numTags - 2}`}
                                </Typography>
                            )
                        }
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Contract Cascodes"
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            {values.contractOtherAllowableXref.length > 0 &&
                <Grid item xs={2}>
                    <Grid container className="align-items-end h-100">
                        <p><b>Total Selected:</b> {values.contractOtherAllowableXref.length}</p>
                    </Grid>
                </Grid>
            }
            <Grid item xs={12} className={`${classes.listGrid} pt-2`}>
                <ul>
                    {values.contractOtherAllowableXref.sort((a, b) => a?.code?.localeCompare(b?.code, 'en', { numeric: true })).map((option, index) => <li key={option?.code}>
                        <Grid container className="align-items-center">
                            <Grid item xs={7}>
                                {`${option?.code} - ${option?.shortdesc}`}
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton
                                    onClick={() => handleDeleteCascode(index)}
                                    edge="start"
                                    aria-label="Delete"
                                    className='p-2'
                                    size="large">
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </li>
                    )}
                </ul>
            </Grid>
        </Grid>
    </>);
}
