import { Formik } from 'formik';
import React from 'react'
import { MODEL_SEARCH_REQUEST_TYPE_CLAIMS, MODEL_SEARCH_REQUEST_TYPE_FORMSEARCH, MODEL_SEARCH_REQUEST_TYPE_GRIDSEARCH } from '../../../../../../store/constants/modeling';
import { Card } from 'react-bootstrap';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormHelperText,
    Grid,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, Autocomplete } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
    container: {
        marginLeft: theme.spacing(2),
    },
    autoComplete: {
        width: '90%',
    },
    textField: {
        width: '90%',
    },
}));

function EstimateForm(props) {
    const { displayTableForClaimSelection, initSearcharameters, tinyContracts, handleBacktoInitSearch, mode, children, modelId,
        intl, initialValues, selectedItems, filter, sort, page, formattedFilter, history, actions: { modelEstimate, analyzeModelEstimate } } = props;

    const classes = useStyles();

    const [isDialogOpen, setDialogOpen] = useState(false);

    const handleEstimate = (payload) => {
        if (mode === 'edit') {
            payload.modelId = modelId;
            delete payload.name;
            analyzeModelEstimate(payload, handleDialogOpen);
        } else {
            modelEstimate(payload, handleDialogOpen);
        }
    }

    const handleDialogOpen = (payload) => {
        setDialogOpen(true);
    }

    const handleEstimateCallback = (payload) => {
        history.push(`/cms/modeling`);
    }

    return (<>
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validate={values => {
                const errors = {};
                if (mode !== 'edit') {
                    if (!values.modelName) {
                        errors.modelName = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }
                }

                if (!values.baseContract) {
                    errors.baseContract = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                }
                if (!values.modelContract) {
                    errors.modelContract = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                }
                if (values.baseContract.id && (values.baseContract.id === values.modelContract.id)) {
                    errors.contractError = 'Base and Model Contract can not be same';
                }
                if (selectedItems.length === 0) {
                    errors.selectedItems = intl.formatMessage({
                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                }
                return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                let payload = {
                    name: values.modelName,
                    contractIds: [
                        values.modelContract.id, values.baseContract.id
                    ],
                }
                if (filter.length > 0 && selectedItems.length === page?.recordsPerPage) {
                    payload = {
                        ...payload,
                        inputRequest: {
                            page: {
                                currentPage: page?.currentPage,
                                totalPages: page?.totalPages,
                                recordsPerPage: page?.recordsPerPage,
                                totalRecords: page?.totalRecords,
                            },
                            sort: sort,
                            filter: formattedFilter
                        },
                        searchRequestType: MODEL_SEARCH_REQUEST_TYPE_GRIDSEARCH
                    }
                } else if (filter.length === 0 && selectedItems.length === page?.recordsPerPage) {
                    payload = {
                        ...payload,
                        searchParameters: initSearcharameters,
                        searchRequestType: MODEL_SEARCH_REQUEST_TYPE_FORMSEARCH
                    }
                } else {
                    payload = {
                        ...payload,
                        claimNumbers: selectedItems,
                        searchRequestType: MODEL_SEARCH_REQUEST_TYPE_CLAIMS
                    }
                }
                handleEstimate(payload)
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
            }) => (
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <Card.Header>
                            <Grid container alignItems='center'>
                                <Grid xs={6}><h5 className='m-0'>Claim Selection</h5></Grid>
                                <Grid xs={6}>
                                    {displayTableForClaimSelection &&
                                        <Grid container className='justify-content-end'>
                                            <Button
                                                variant="contained"
                                                onClick={handleBacktoInitSearch}
                                                className={`${classes.button} mr-3`}
                                                type='button'>
                                                Back
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                type='button'
                                                onClick={handleSubmit}
                                            >
                                                Analyze
                                            </Button>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Card.Header>
                        <Card.Body className='text-dark py-2 px-4'>
                            {mode === 'create' &&
                                <>
                                    <Grid item xs={12} className='px-1'>
                                        <Grid container>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    label="Model Name"
                                                    name="modelName"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.modelName}
                                                    helperText={touched.modelName && errors.modelName}
                                                    error={Boolean(touched.modelName && errors.modelName)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Autocomplete
                                                    className={classes.autoComplete}
                                                    value={values.baseContract}
                                                    disableClearable={false}
                                                    onChange={(e, value) => setFieldValue("baseContract", value)}
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    name={`baseContract`}
                                                    options={tinyContracts}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            name={`baseContract`}
                                                            label="Contract 1"
                                                            error={Boolean(touched.baseContract && errors.baseContract)}
                                                            helperText={touched.baseContract && errors.baseContract}
                                                            margin="normal" />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Autocomplete
                                                    className={classes.autoComplete}
                                                    value={values.modelContract}
                                                    disableClearable={false}
                                                    onChange={(e, value) => setFieldValue("modelContract", value)}
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    name={`modelContract`}
                                                    options={tinyContracts}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            name={`modelContract`}
                                                            label="Contract 2"
                                                            error={Boolean(touched.modelContract && errors.modelContract)}
                                                            helperText={touched.modelContract && errors.modelContract}
                                                            margin="normal" />
                                                    }
                                                />
                                                <Grid item xs={12}>
                                                    {(touched.modelContract || touched.baseContract) && <FormHelperText className='pt-1' error>{errors.contractError}</FormHelperText>}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {(touched.modelContract || touched.baseContract) && selectedItems.length === 0 && <FormHelperText className='pt-1' error>You need to select at least one claim before estimation</FormHelperText>}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <hr />
                                </>
                            }
                            {children}
                        </Card.Body>
                    </Card>
                </Grid>
            )}
        </Formik>
        <Dialog open={isDialogOpen} onClose={handleEstimateCallback}>
            <DialogTitle>
                Claim Model Estimation
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Alert severity="success">The request is submitted and progress can be monitored on execution logs page.</Alert>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleEstimateCallback}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}

export default withRouter(EstimateForm)
