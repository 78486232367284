import { IconButton, TablePagination, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { DataFilter, DataTable, TablePaginationActions } from '../../common';
import {
    Portlet, PortletBody, PortletHeader, PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { DependencyDialog } from './DependencyDialog';
import { PayerPlanDialog } from './PayerPlanDialog';

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    banIcon: {
        fontSize: 17,
        // color: '#d9534f'
    }
}));

export default function PayerPlanManagement(props) {
    const { 
        data, fields, sort, plansListPending, order, filter, userRole,
        actions: {
            payerPlansLanding, setFilter, setOrder, setSort, createPayerPlanEditReset, setPayerPlanDependencyDialog,
            setPayerPlanDialog,
        }
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);

    const classes = useStyles();

    function handleLoad(sort, filter, pagination) {
        payerPlansLanding(pagination, sort, filter);
    }

    useEffect(() => {
        payerPlansLanding();
    }, [payerPlansLanding]);

    useEffect(() => {
        return () => {
            createPayerPlanEditReset();
        }
    }, [createPayerPlanEditReset]);

    const handleRefresh = () => {
        payerPlansLanding();
    }

    const handleAddPayerPlan = () => {
        setPayerPlanDialog({ open: true, payerPlan: null, mode: 'create' });
    }

    const handleEditPayerPlan = (rowData) => {
        setPayerPlanDialog({ open: true, payerPlan: rowData, mode: 'edit' });
    }

    const handleDeletePayerPlan = (rowData) => {
        setPayerPlanDependencyDialog({open: true, planId: rowData.id, name: rowData.name})
    }

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    function payerPlanActions(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleEditPayerPlan(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                    <EditIcon />
                    </Tooltip>
                </IconButton>
                <IconButton
                    onClick={() => handleDeletePayerPlan(rowData)}
                    color={"primary"}
                    className='p-1'
                    aria-label="Delete"
                    size="large">
                    <Tooltip title={"Delete"} >
                        <DeleteIcon />
                    </Tooltip>
                </IconButton>
            
            </div>
        );
    }
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Helmet title="Plan Management" />
            <Portlet>
                <PortletHeader
                title="Plans"
                toolbar={
                    <PortletHeaderToolbar>
                    <DataFilter
                        onChange={handleSearchInput}
                        placeholder="Search"
                        filter={filter}
                    />
                    </PortletHeaderToolbar>
                }
                />
                <PortletBody>
                <DataTable
                    fields={fields}
                    data={data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    onAdd={handleAddPayerPlan}
                    order={order}
                    onOrder={setOrder}
                    sort={sort}
                    onSort={setSort}
                    loading={plansListPending}
                    name="Client"
                    handleRefresh={handleRefresh}
                    showPagination={true}
                    footerPagination={() => (
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                        colSpan={fields.length + 2}
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                    )}

                    hasActions={true}
                    renderActionColumn={payerPlanActions}
                />
                </PortletBody>
            </Portlet>
            <DependencyDialog handleRefresh={handleRefresh} />
            <PayerPlanDialog handleRefresh={handleRefresh} />
        </>
    );
}
