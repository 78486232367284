import { IconButton, MenuItem, TablePagination, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import PageviewIcon from '@mui/icons-material/Pageview';
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { DataFilter, DataTable, Loadable } from '../../../common';
import { EllipsisMenu } from "../../../common/EllipsisMenu";
import { TablePaginationActions } from '../../../common/tablePaginationActions';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { CodesDialog } from './CodesDialog';
import { DependencyDialog } from "./DependencyDialog";
import { RulesDialog } from "./RulesDialog";

const useStyles = makeStyles(theme => {
    return {
        underline: {
            textDecoration: 'underline',
        },
        textField: {
            width: 400,
        },
        button: {
            margin: theme.spacing(1),
        },
    }
});

export default function CodeServices(props) {
    const { codeServicePending, serviceDeletePending, fields, filteredData, order, sort, history,
        actions: { getCodeServices, setFilter, setSort, setOrder, serviceDelete, setRulesDialog, setCodeServiceDependencyDialog }
    } = props;
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState<string|boolean>(false);
    const [selectedServiceId, setSelectedServiceId] = useState('');
    const [selectedField, setSelectedField] = useState('');

    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = useState(0);

    useEffect(() => {
        getCodeServices();
        return () => {
            setFilter("");
        }
    }, [getCodeServices, setFilter]);

    const onhandleClickColumn = (field, data) => {
        if (data) {
            setOpenDialog(true);
            if (field === 'primaryCodes') {
                setSelectedField('Primary');
                setDialogTitle('Primary Codes');
            } else {
                setDialogTitle('Qualifier Codes');
                setSelectedField('Qualifier');
            }
            setSelectedServiceId(data.id);
        }
    }

    const handleClose = (open) => {
        setOpenDialog(open);
        setSelectedServiceId('');
    }

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleAddService() {
        history.push('/cms/codeservice/edit/new');
    }

    const handleServiceEdit = (service) => {
        history.push(`/cms/codeservice/edit/${service.id}`);
    }

    const handleCallBack = () => {
        getCodeServices();
    }

    const handleCodeServiceDeleteDependency = (service) => {
        setCodeServiceDependencyDialog({
            open: true,
            serviceId: service.id,
            serviceName: service.name
        });
    }

    const handleRefresh = () => {
        getCodeServices();
    }

    function handleOpenRulesDialog(rowData) {
        setRulesDialog({ open: true, serviceId: rowData.id, serviceName: rowData.name })
    }

    function codeServicesAction(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleServiceEdit(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
                <IconButton
                    onClick={() => handleCodeServiceDeleteDependency(rowData)}
                    color={"primary"}
                    className='p-1'
                    aria-label="Delete"
                    size="large">
                    <Tooltip title={"Delete Code Service"} >
                        <DeleteIcon />
                    </Tooltip>
                </IconButton>
                <div>
                    <EllipsisMenu renderMenuItems={
                        [
                            <MenuItem
                                className='justify-content-center'
                                onClick={() => handleOpenRulesDialog(rowData)}
                            >
                                <IconButton
                                    color="primary"
                                    className='p-1'
                                    aria-label="Edit"
                                    onClick={() => handleOpenRulesDialog(rowData)}
                                    size="large">
                                    <Tooltip title="View Rules">
                                        <PageviewIcon />
                                    </Tooltip>
                                </IconButton>
                            </MenuItem>
                        ]
                    }
                    />
                </div>
            </div>
        );
    }

    return (<>
        <Helmet title="Code Services" />
        <Loadable loading={codeServicePending || serviceDeletePending}>
            <CodesDialog
                title={dialogTitle}
                open={openDialog}
                handleClose={handleClose}
                selectedField={selectedField}
                serviceId={selectedServiceId}
            />
            <Portlet>
                <PortletHeader
                    title="Code Services"
                    toolbar={
                        <PortletHeaderToolbar>
                            <DataFilter
                                onChange={handleSearchInput}
                                placeholder="Search"
                                cssClass={classes.textField}
                            />
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <DataTable
                        size="medium"
                        fields={fields}
                        data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                        handleClickColumn={onhandleClickColumn}
                        order={order}
                        onOrder={setOrder}
                        sort={sort}
                        onSort={setSort}
                        showPagination={true}
                        footerPagination={() => (
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                colSpan={fields.length + 2}
                                count={filteredData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        )}
                        stickyHeader={true}
                        onAdd={handleAddService}
                        name="code service"
                        handleRefresh={handleRefresh}

                        hasActions={true}
                        renderActionColumn={codeServicesAction}
                    />
                </PortletBody>
            </Portlet>
        </Loadable>
        <RulesDialog />
        <DependencyDialog handleRefresh={handleRefresh} />
    </>);
}