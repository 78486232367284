import { Grid, IconButton, TablePagination, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { DataTable, PagedTable, TablePaginationActions, TraceDialog } from '../../../common';
import { Portlet, PortletBody, PortletHeader } from '../../../partials/content/Portlet';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const DEFAULT_SORT = [{ id: 'executedAt', direction: 'asc' }];

function JobStatisticsPage(props) {
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [metaData, setMetaData] = useState('');
    const [isMetaDataOpen, setMetaDataOpen] = useState(false);

    const {
        fields, jobStatisticsData, sort, order, getJobStatisticsPending,
        actions: {
            getJobStatistics,
            setSort, setOrder
        }
    } = props;

    useEffect(() => {
        getJobStatistics();
    }, [getJobStatistics]);

    const handleRefresh = () => {
        getJobStatistics();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleViewMetaData = (executionMetadata) => {
        setMetaData(executionMetadata);
        setMetaDataOpen(true);
    }

    const handleMetaDataClose = () => {
        setMetaDataOpen(false);
    }

    function statisticsAction(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleViewMetaData(rowData.executionMetadata)}
                    size="large">
                    <Tooltip title="View Metadata">
                        <InfoIcon />
                    </Tooltip>
                </IconButton>
            </div>
        );
    }

    return (<>
        <Helmet title="Job Statistics" />
        <Portlet>
            <PortletHeader
                title="Job Statistics"
                name="job-statistics-landing"
                toolbar={<></>}
            />
            <PortletBody>
                <Grid container>
                    <Grid item xs={12}>
                        <DataTable
                            name="modifiers-landing"
                            fields={fields}
                            data={jobStatisticsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                            handleRefresh={handleRefresh}
                            defaultSort={DEFAULT_SORT}
                            // tableLevelActions={tableLevelActions}
                            loading={getJobStatisticsPending}
                            title="modifiers"
                            order={order}
                            onOrder={setOrder}
                            sort={sort}
                            onSort={setSort}
                            stickyHeader={true}
                            showPagination={true}
                            footerPagination={() => (
                                <TablePagination
                                    rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                    colSpan={fields.length + 2}
                                    count={jobStatisticsData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            )}

                            hasActions={true}
                            renderActionColumn={statisticsAction}
                        />
                    </Grid>
                </Grid>
            </PortletBody>
        </Portlet>
        <TraceDialog open={isMetaDataOpen} handleClose={handleMetaDataClose} traces={metaData} title="Metadata" />
    </>);
}

export default JobStatisticsPage