import { DataGrid, GridColDef, GridValueGetter, useGridApiRef } from "@mui/x-data-grid";
import { PurePtr, useLocalStoragePtr, useStatePtr } from "@pure-ptr/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set } from "../store/actions/pages";
import { BooleanEquators, dateEquators, integerEquators, StringEquators } from "../store/constants/pages";
import { RootState } from "../store/reducers";
import { useWindowEvent } from "../util";
import { CombinedFilter, getPaginationFilter, getTableSearchFilter } from "../util/pagination";
import { Field } from "./dataTableHeader/DataTableHeader";
import { AdvancedTableFilters } from "./pagedTable";
import { PopoverLink } from "./popoverLink";

export type SortOptions = { id: string; direction: string }[]
export type PaginationFilters = { sort: SortOptions, filters: CombinedFilter, pagination: { currentPage: number; recordsPerPage: number; totalRecords?: number } }
interface XDataGridProps {
    selectedIdsPtr?: PurePtr<readonly (string|number)[]>;
    loading: boolean;
    data: any[];
    showSearchFilters:boolean;
    name: string;
    paginationFilters: PaginationFilters;
    fields: Field[];
    onLoad: (sort: any, filter: any, pagination: any) => void;
    defaultSort: SortOptions;
    criteriasSaveEnabled: boolean;
    refresh: Promise<void>;
    actions?: (params: any) => React.ReactNode;
    defaultHiddenFields?: string[];
    localStorageKey?: string;
}

const autosizeOptions = {
    includeHeaders: true,
    includeOutliers: true,
    expand: true,
};

export function XDataGrid({ 
    paginationFilters, defaultHiddenFields, selectedIdsPtr, loading, data, name, fields, onLoad,showSearchFilters, defaultSort, criteriasSaveEnabled, refresh, actions
}: XDataGridProps) {
    const apiRef = useGridApiRef();

    const columnDefs = useMemo(() => [
        ...translateLegacyFields(fields),
        ...(actions ? [createActionsField(actions)] : [])
    ], [fields, actions]);

    const autosizeOptions = useMemo(() => ({
        columns : columnDefs.map( x => x.field ).filter( x => x !== 'actions' ),
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
    }), [ fields ]);

    const initialColumnVisibility = useCallback(() =>
        Object.fromEntries( defaultHiddenFields.map( x => [ x, false ]))
    , [defaultHiddenFields]);

    const columnVisibilityModelPtr = useLocalStoragePtr( name + '-visibility', initialColumnVisibility ); 

    const resizeColumns = useCallback(() => {
        if( !loading && data.length > 0 ){
            // `sleep`/`setTimeout` is required because update rows is an
            // async function throttled to avoid choking on frequent changes.
            setTimeout(() => {
                apiRef.current.autosizeColumns( autosizeOptions )
            }, 0);
        }
    }, [ apiRef.current, loading, data ]);

    useEffect( resizeColumns, [ resizeColumns ]);
    useWindowEvent( 'resize', resizeColumns, 100 );

    return <>
        <DataGrid
            apiRef={apiRef}
            autosizeOptions={autosizeOptions}
            density="compact"
            columns={columnDefs}
            rows={data}
            disableColumnFilter
            loading={loading}

            rowSelectionModel={selectedIdsPtr?.value}
            onRowSelectionModelChange={x => selectedIdsPtr.set(x)}

            checkboxSelection={ Boolean(selectedIdsPtr) } 
            disableRowSelectionOnClick

            columnVisibilityModel={columnVisibilityModelPtr.value}
            onColumnVisibilityModelChange={x => columnVisibilityModelPtr.set(x)}

            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={ toPaginationModel( paginationFilters.pagination ) }
            paginationMode="server"
            onPaginationModelChange={ x => 
                onLoad( paginationFilters.sort, paginationFilters.filters, { ...paginationFilters.pagination, currentPage: x.page + 1, recordsPerPage: x.pageSize })
            }
            rowCount={paginationFilters.pagination.totalRecords ?? 0}

            sortingMode="server"
            sortModel={paginationFilters.sort.map(x => ({
                field: x.id,
                sort: x.direction as any,
            }))}
            onSortModelChange={arr => 
                onLoad(arr.map(x => ({ id: x.field, direction: x.sort })), paginationFilters.filters, paginationFilters.pagination)
            }
            
            slotProps={ slotProps}
        />

        <AdvancedTableFilters
            showSearchFilters={showSearchFilters}
            name={name}
            fields={fields}
            onLoad={( sort, filter, pagination ) => onLoad( sort, filter, { ...pagination, currentPage: 1 })}
            refresh={refresh}
            defaultSort={defaultSort}
            criteriasSaveEnabled={criteriasSaveEnabled} />
    </>;
}

function toPaginationModel( { currentPage = 1, recordsPerPage, totalRecords = 0 } : PaginationFilters["pagination"] ) {
    return {
        pageSize: recordsPerPage,
        page: Math.min( Math.floor( totalRecords / recordsPerPage ) + 1, currentPage ) - 1,
    }
}

const slotProps = {
    columnsManagement: {
      disableShowHideToggle: true,
      getTogglableColumns( columns : GridColDef[] ) {
        return columns
            .filter( column => column.field !== 'actions' && column.field !== '__check__' )
            .map( column => column.field );
      }
    },
}

export function renderHoverLink( href, hoverData ){
    return (params) => (
        <PopoverLink
            columnData={params.value}
            title={params.value}
            link={ href( params.row )} 
            hoverData={ hoverData( params.row )}
        />
    )
}

/**
 * Generates a hook for retrieving grid filters, sorting options, and pagination settings for a specified page.
 *
 * @template K - The key of the page in the RootState.
 * @param {K} page - The key representing the page in the RootState.
 * @param {Object} [defaults] - Optional default values for filters and sorting options.
 * @param {any[]} [defaults.filter] - Default filter values.
 * @param {SortOptions} [defaults.sort] - Default sorting options.
 * @returns {() => { sort: SortOptions, filters: CombinedFilter, pagination: { currentPage: number; recordsPerPage: number; totalRecords?: number } }} 
 *          A hook function that returns the current sorting options, combined filters, and pagination settings.
 */
export function pageGridFilters<K extends keyof RootState['pages']>( page : K, defaults? : { filter?: any[], sort?: SortOptions }) 
    : () => { sort: SortOptions, filters: CombinedFilter, pagination: { currentPage: number; recordsPerPage: number; totalRecords?: number } }
{
    return function useGridFilters() {
        const filter = useGlobalStore( x => x.pages[page].filter ),
            customFilter = useGlobalStore( x => x.pages[page].customFilter ),
            timeFilter = useGlobalStore( x => x.pages[page].timeFilter ),
            defaultFilter = useGlobalStore( x => x.pages[page].defaultFilter ),
            defaultSort = useGlobalStore( x => x.pages[page].defaultSort ),
            defaultCustomFilter = useGlobalStore( x => x.pages[page].defaultCustomFilter ),
            searchTerm = useGlobalStore( x => x.pages[page].searchTerm ),
            sort = useGlobalStore( x => x.pages[page].sort ),
            pagination = useGlobalStore( x => x.pages[page].page );

        const quickSearch = searchTerm ? getTableSearchFilter( searchTerm ) : null;

        const paginationFilters = getPaginationFilter(
            getDefault(filter, defaults?.filter ?? defaultFilter), 
            undefined, 
            timeFilter, 
            getDefault(customFilter, defaultCustomFilter)
                .filter( ( item : any ) => item.value !== 'both') as any
        );
        
        return {
            sort : getDefault(sort, defaults?.sort ?? defaultSort),
            filters : ( quickSearch ? {
                  ...paginationFilters,
                  operands: [
                      ...(paginationFilters?.operands || []),
                      ...quickSearch?.operands,
                  ],
              }
            : paginationFilters ) as any,
            pagination
        }
    }
}

type Initializer<T> = T | (() => T);

export function usePageStore<P extends keyof RootState['pages'], K extends keyof RootState['pages'][P]>( page : P, key : K, init? : Initializer<RootState['pages'][P][K]> ) : RootState['pages'][P][K] {
    const val = useSelector<RootState>( x => x.pages[page][key] );

    return getDefault( val, init );
}

export function usePageStorePtr<P extends keyof RootState['pages'], K extends keyof RootState['pages'][P]>( page : P, key : K, init? : Initializer<RootState['pages'][P][K]> ) : PurePtr<RootState['pages'][P][K]> {
    const dispatch = useDispatch(),
        val = useSelector<RootState>( x => x.pages[page][key] );

    return PurePtr.value( getDefault( val, init ), x => dispatch( set( page, key, x )));
}

/**
 * A utility function to create a typed selector hook for a specific page in the Redux store.
 * 
 * @template K - The key of the page in the RootState's pages object.
 * @template V - The type of the value returned by the selector function.
 * 
 * @param {K} page - The key of the page in the RootState's pages object.
 * 
 * @returns {function} - A hook function that takes a selector function and returns the selected value from the specified page's state.
 * 
 * @example
 * ```tsx
 * const useHomePageStore = pageStore('home');
 * ...
 * const someValue = useHomePageStore(x => x.someValue);
 * ```
 */
export function pageStore<K extends keyof RootState['pages']>( page : K, options? : any ) : {
    usePageStore<V>( selector : ( state : RootState['pages'][K] ) => V ) : V;
    useGridFilters() : ReturnType<ReturnType<typeof pageGridFilters>>;
}{
    return {
        usePageStore( selector : ( state : RootState['pages'][K] ) => any ){
            return useSelector<RootState, any>( x => selector(x.pages[page]) );
        },

        useGridFilters : pageGridFilters( page, options )
    }
}

/**
 * Creates a hook that returns a pointer to a specific value in the given Redux store's `pages` slice.
 *
 * @template K - The key of the `pages` slice in the `RootState`.
 * @template V - The key of the specific value within the `pages` slice.
 * 
 * @param {K} page - The key of the `pages` slice in the `RootState`.
 * @returns {function(V): PurePtr<RootState['pages'][K][V]>} - A function that takes a key and returns a pointer to the value in the Redux store.
 */
export function pageStorePtr<K extends keyof RootState['pages']>( page : K ) : <V extends keyof RootState['pages'][K]>( key : V, init?: any ) => PurePtr<RootState['pages'][K][V]> {
    return function usePageStorePtr( key : any, defaultVal? : any ){
        const value = useSelector<RootState, any>( x => x.pages[page][key] );
        const dispatch = useDispatch();
        return PurePtr.value( getDefault( value, defaultVal ) , x => dispatch( set( page, key, x )));
    }
}

export function useGlobalStore<T>( selector : ( state : RootState ) => T ) : T {
    return useSelector<RootState, T>( selector ?? ( x => x as any ));
}

/* TODO: Need reducer.

export function useGlobalStorePtr<K extends keyof RootState>( key : K ) : PurePtr<RootState[K]> {
    const value = useSelector<RootState, any>(  x => x[ key ] );
    const dispatch = useDispatch();
    return PurePtr.value( value, x => dispatch( x as any ));
}
*/

function getDefault<T>( arr : any, defaultArr : T | (() => T) ) : T {
    if( defaultArr === undefined ) return arr;
    
    const defaultValue = typeof defaultArr === 'function' ? ( defaultArr as any )() : defaultArr;
    
    return Array.isArray( defaultValue ) ?
        ( !arr?.length ? defaultArr : arr ) :
        arr ?? defaultArr;
}

function translateLegacyFields( fields : any[]) : GridColDef[]{
    const unknown = fields.filter( field => !field.xDataField );

    if( unknown.length > 0 ){
        console.error( 'Can\'t translate legacy fields:', unknown );
    }

    return fields
        .filter( x => x.xDataField && !x.options.hideFromGrid && !x.options.hidden )
        .map( x => x.xDataField );
}

export function createActionsField( getActions ) : GridColDef {
    return {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions' as any,
        width: 80,
        getActions
    }
}

type ExtendColumnDef = Partial<GridColDef> & { renderString: 'renderCell' | 'valueFormatter' | 'valueGetter' | false}

export function transformOptions( columnDef : Field, extend : ExtendColumnDef ) : Field {
    columnDef.xDataField = {
        field: columnDef.id,
        headerName: columnDef.label
    }

    const { xDataField } = columnDef;

    // If no type is provided, infer it from the equators
    const { equators } = columnDef.options;
    xDataField.type = extend?.type ?? (
        equators === StringEquators ? 'string' :
        equators === integerEquators ? 'number' :
        equators === BooleanEquators ? 'boolean' :
        equators === dateEquators ? 'date' : 
        'string'
    )

    // Handle nested fields a.b.c
    xDataField.valueGetter = createValueGetter( 
        xDataField.field, 
        extend.renderString === 'valueGetter' ? 
            ( value, row ) => columnDef.renderString( columnDef.options.returnWholeRow? row : value ) :
            extend?.valueGetter 
    );

    if( columnDef.renderString ){
        if( extend.renderString === undefined || extend.renderString === 'renderCell' ){
            xDataField.renderCell = ( params ) => columnDef.renderString( columnDef.options.returnWholeRow? params.row : params.value );
        }
        else if( extend.renderString === 'valueFormatter'){
            xDataField.valueFormatter = ( value, row ) => columnDef.renderString( columnDef.options.returnWholeRow? row : value );
        }
    }

    if( extend.renderCell || columnDef.options.renderCell ){
        xDataField.renderCell = columnDef.options.renderCell ?? extend.renderCell;
    }

    if( extend.valueFormatter ){
        xDataField.valueFormatter = extend.valueFormatter;
    }

    if( extend.width ){
        const width = parseInt( columnDef.width.match(/^\d+px$/)[0] );
        
        if( !isNaN(width) ){
            xDataField.width = width;
        }
    }

    xDataField.sortable = columnDef.options.sortable ?? true;
    xDataField.disableReorder = !columnDef.options.reorder;

    xDataField.hideable = columnDef.options.hideable;

    return columnDef as any;
}

function createValueGetter( field : string, getter? : GridValueGetter ) : GridValueGetter {
    const attrs = field.split('.'),
        simpleField = attrs.length === 1;

    if( simpleField ){
        return getter || (( value, row ) => row[field] );
    }

    return getter ? 
        ( value, row ) => (getter as any )( attrs.reduce( ( acc, x ) => acc?.[x], row)) :
        ( value, row ) => attrs.reduce( ( acc, x ) => acc?.[x], row);
}