import React from 'react';
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { Button, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Loadable, Container } from '../../../common'
import { PASSWORD_REGEX } from '../../../store/constants/pages';
import { toLocalStorage } from '../../../util/storage';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
}));

export default function ForcedResetPasswordComponent(props) {
    const { intl, changePasswordPending, sessionConstructPending, actions: { sessionChangePassword } } = props;
    const classes = useStyles();

    const handleCallback = () => {
        toLocalStorage("forceResetPW", null);
        props.history.push('/');
    }

    return (
        <Container>
            <Loadable loading={changePasswordPending || sessionConstructPending}>
                <div className="kt-login__head mb-0">
                    <div className="kt-login__title">
                        <h3>
                            {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
                            <FormattedMessage id="AUTH.INPUT.RESET_PASSWORD" />
                        </h3>
                    </div>
                </div>
                <Formik
                    initialValues={{
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                    }}
                    validate={values => {
                        const errors = {};

                        if (!values.oldPassword) {
                            errors.oldPassword = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.newPassword) {
                            errors.newPassword = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (!values.newPassword.match(PASSWORD_REGEX)) {
                            errors.newPassword = intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_PASSWORD"
                            });
                        }

                        if (!values.confirmPassword) {
                            errors.confirmPassword = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (values.newPassword !== values.confirmPassword) {
                            errors.confirmPassword =
                                "Password and Confirm Password didn't match.";
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        sessionChangePassword(values.oldPassword, values.newPassword, handleCallback);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                            <div className={classes.container}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <TextField
                                            required
                                            type="password"
                                            label="Old Password"
                                            name="oldPassword"
                                            className={classes.textField}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.oldPassword}
                                            helperText={touched.oldPassword && errors.oldPassword}
                                            error={Boolean(touched.oldPassword && errors.oldPassword)}
                                        />
                                        <TextField
                                            required
                                            type="password"
                                            label="New Password"
                                            name="newPassword"
                                            className={classes.textField}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.newPassword}
                                            helperText={touched.newPassword && errors.newPassword}
                                            error={Boolean(touched.newPassword && errors.newPassword)}
                                        />
                                        <TextField
                                            required
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            type="password"
                                            className={classes.textField}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirmPassword}
                                            helperText={touched.confirmPassword && errors.confirmPassword}
                                            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                        />
                                    </div>
                                </div>
                                <div className='text-center pt-2'>
                                    {/* <Button
                                                component={Link}
                                                to="/dashboard"
                                                variant="contained"
                                                color="default"
                                                className={classes.button}>
                                                Cancel
                                            </Button> */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        type="submit"
                                        disabled={changePasswordPending}
                                    >
                                        Change Password
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </Loadable>
        </Container>
    );
}