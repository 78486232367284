import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { deletePayerIdMapping, getPayerIdMappingDeleteDependencies, payerIdMappingDependencyReset, setPayerIdMappingDependencyDialog } from '../../../store/actions/payers';

export const DependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.payers.payerIdMappingDependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectPayerIdMapping = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.payerIdMapping) {
                    return dependencyDialog.payerIdMapping
                }
                return null
            }
        );

        const selectPayerIdMappingId = createSelector(
            selectPayerIdMapping,
            (mapping) => {
                if (mapping && mapping?.id) {
                    return mapping?.id
                }
                return null
            }
        );

        const selectPayerIdMappingName = createSelector(
            selectPayerIdMapping,
            (mapping) => {
                if (mapping && mapping?.payerName) {
                    return mapping?.payerName
                }
                return null
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            payerIdMappingId: selectPayerIdMappingId(state),
            dependencies: state.payers.payerIdMappingDependencies?.records ?? [],
            canDelete: state.payers.payerIdMappingDependencies?.canDelete ?? false,
            getDependenciesPending: state.payers.getPayerIdMappingDependencies.pending,
            deletePending: state.payers.deletePayerIdMappingRecord?.pending,
            payerName: selectPayerIdMappingName(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getDeleteDependencies: getPayerIdMappingDeleteDependencies,
            deletePayerIdMapping: deletePayerIdMapping,
            setDependencyDialog: setPayerIdMappingDependencyDialog,
            resetDependencies: payerIdMappingDependencyReset
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));