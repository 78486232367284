export const CLAIM_STATUS_LANDING = 'CLAIM_STATUS_LANDING';
export const CLAIM_STATUS_LANDING_PENDING = 'CLAIM_STATUS_LANDING_PENDING';
export const CLAIM_STATUS_LANDING_FULFILLED = 'CLAIM_STATUS_LANDING_FULFILLED';
export const CLAIM_STATUS_LANDING_REJECTED = 'CLAIM_STATUS_LANDING_REJECTED';

export const CLAIM_STATUS_CREATE = 'CLAIM_STATUS_CREATE';
export const CLAIM_STATUS_CREATE_PENDING = 'CLAIM_STATUS_CREATE_PENDING';
export const CLAIM_STATUS_CREATE_FULFILLED = 'CLAIM_STATUS_CREATE_FULFILLED';
export const CLAIM_STATUS_CREATE_REJECTED = 'CLAIM_STATUS_CREATE_REJECTED';

export const CLAIM_STATUS_UPDATE = 'CLAIM_STATUS_UPDATE';
export const CLAIM_STATUS_UPDATE_PENDING = 'CLAIM_STATUS_UPDATE_PENDING';
export const CLAIM_STATUS_UPDATE_FULFILLED = 'CLAIM_STATUS_UPDATE_FULFILLED';
export const CLAIM_STATUS_UPDATE_REJECTED = 'CLAIM_STATUS_UPDATE_REJECTED';

export const CLAIM_STATUS_CHANGE_PRECEDENCE = 'CLAIM_STATUS_CHANGE_PRECEDENCE';
export const CLAIM_STATUS_CHANGE_PRECEDENCE_PENDING = 'CLAIM_STATUS_CHANGE_PRECEDENCE_PENDING';
export const CLAIM_STATUS_CHANGE_PRECEDENCE_FULFILLED = 'CLAIM_STATUS_CHANGE_PRECEDENCE_FULFILLED';
export const CLAIM_STATUS_CHANGE_PRECEDENCE_REJECTED = 'CLAIM_STATUS_CHANGE_PRECEDENCE_REJECTED';

export const CLAIM_STATUS_GET = 'CLAIM_STATUS_GET';
export const CLAIM_STATUS_GET_PENDING = 'CLAIM_STATUS_GET_PENDING';
export const CLAIM_STATUS_GET_FULFILLED = 'CLAIM_STATUS_GET_FULFILLED';
export const CLAIM_STATUS_GET_REJECTED = 'CLAIM_STATUS_GET_REJECTED';

export const CLAIM_STATUS_DELETE_DEPENDENCIES = 'CLAIM_STATUS_DELETE_DEPENDENCIES';
export const CLAIM_STATUS_DELETE_DEPENDENCIES_PENDING = 'CLAIM_STATUS_DELETE_DEPENDENCIES_PENDING';
export const CLAIM_STATUS_DELETE_DEPENDENCIES_FULFILLED = 'CLAIM_STATUS_DELETE_DEPENDENCIES_FULFILLED';
export const CLAIM_STATUS_DELETE_DEPENDENCIES_REJECTED = 'CLAIM_STATUS_DELETE_DEPENDENCIES_REJECTED';

export const CLAIM_STATUS_DELETE = 'CLAIM_STATUS_DELETE';
export const CLAIM_STATUS_DELETE_PENDING = 'CLAIM_STATUS_DELETE_PENDING';
export const CLAIM_STATUS_DELETE_FULFILLED = 'CLAIM_STATUS_DELETE_FULFILLED';
export const CLAIM_STATUS_DELETE_REJECTED = 'CLAIM_STATUS_DELETE_REJECTED';

export const CLAIM_STATUS_SET_EDIT_DIALOG = 'CLAIM_STATUS_SET_EDIT_DIALOG';
export const CLAIM_STATUS_SET_DEPENDENCY_DIALOG = 'CLAIM_STATUS_SET_DEPENDENCY_DIALOG';
export const CLAIM_STATUS_CREATE_UPDATE_RESET = 'CLAIM_STATUS_CREATE_UPDATE_RESET';
export const CLAIM_STATUS_RESET = 'CLAIM_STATUS_RESET';
export const CLAIM_STATUS_SET_DEPENDENCY_RESET = 'CLAIM_STATUS_SET_DEPENDENCY_RESET';

export const claimStatusLanding = () => ({
    type: CLAIM_STATUS_LANDING
});

export const createClaimStatus = (values, callback, shouldReloadGrid) => ({
    type: CLAIM_STATUS_CREATE,
    payload: { values, callback, shouldReloadGrid }
});

export const editClaimStatus = (values, callback, shouldReloadGrid) => ({
    type: CLAIM_STATUS_UPDATE,
    payload: { values, callback, shouldReloadGrid }
});

export const createEditReset = () => ({
    type: CLAIM_STATUS_CREATE_UPDATE_RESET
});

export const getClaimStatus = (claimStatusId) => ({
    type: CLAIM_STATUS_GET,
    payload: { claimStatusId }
});

export const setClaimStatusEditDialog = (editDialog) => ({
    type: CLAIM_STATUS_SET_EDIT_DIALOG,
    payload: { editDialog }
});

export const claimStatusesReset = () => ({
    type: CLAIM_STATUS_RESET
});

export const changePrecedence = (options, callback) => ({
    type: CLAIM_STATUS_CHANGE_PRECEDENCE,
    payload: { options, callback }
});

export const getClaimStatusDeleteDependencies = (id) => ({
    type: CLAIM_STATUS_DELETE_DEPENDENCIES,
    payload: { id }
});

export const deleteClaimStatus = (values, callback) => ({
    type: CLAIM_STATUS_DELETE,
    payload: { values, callback }
});

export const setDependencyDialog = (dependencyDialog) => ({
    type: CLAIM_STATUS_SET_DEPENDENCY_DIALOG,
    payload: { dependencyDialog }
});

export const claimStatusesDependencyReset = () => ({
    type: CLAIM_STATUS_SET_DEPENDENCY_RESET
});