import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'

function LightDataTableComponent(props) {
    const { labels = [], data = [] } = props;
    return (
        <div>
            <Table aria-label="MultiProcedure Percentage Table">
                <TableHead>
                    <TableRow>
                        {labels?.map(label => <TableCell align='left' key={label.id}>{label.name} </TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow
                            key={`${index.toString()}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {labels?.map(label =>
                                <TableCell align='left' key={`${label.id}-${index}`}>
                                    {item[label.id]}
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default LightDataTableComponent