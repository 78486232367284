import React from 'react';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => {
    return {
        label: {
            fontSize: '1rem'
        },
        content: {
            wordBreak: 'break-word',
        }
    }
});

export default function CustomLabel(props) {
    const { label, data } = props
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.label} variant="subtitle2" display="inline" >{label && `${label}:`} </Typography>
            <Typography className={classes.content} variant="subtitle1" display="inline">{data}</Typography>
        </>
    );
}
