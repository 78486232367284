import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Popover,
    Tooltip,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { DataTable } from '../../../../../common';

import moment from 'moment';
import { withRouter } from "react-router-dom";
import { CustomLabel, Loadable } from '../../../../../common';
import {
    Portlet,
    PortletBody,
    PortletHeader
} from "../../../../../partials/content/Portlet";
import { GroupsDialog } from './GroupsDialog';

import { TablePagination } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import CopyIcon from '@mui/icons-material/FileCopy';
import { Card } from "react-bootstrap";
import { CustomClickableLabel } from "../../../../../common/CustomClickableLabel";
import { CustomLabelPopover } from "../../../../../common/CustomLabelPopover";
import { DeleteConfirmation } from "../../../../../common/DeleteConfirmation";
import { EllipsisMenu } from "../../../../../common/EllipsisMenu";
import { TablePaginationActions } from '../../../../../common/tablePaginationActions';
import { CONFIG_TYPE_EAPG, CONFIG_TYPE_IPPS, CONFIG_TYPE_OPPS, RULE_TYPE_CARVEOUT, RULE_TYPE_DEFAULT, RULE_TYPE_RULE } from "../../../../../store/constants/contract";
import { DATE_TIMESTAMP_FORMAT } from '../../../../../store/constants/date';
import { reorderArray } from "../../../../../util";
import { asDate } from '../../../../../util/date';
import { CodesCarveoutCodesDialog } from "./CarveoutCodesDialog";
import { CloneRule } from "./CloneRule";
import { RulePageTopFilter } from "./RulePageTopFilter";

const useStyles = makeStyles(theme => {
    return {
        textField: {
            width: 400,
        },
        portlet: {
            'box-shadow': 'none',
            marginBottom: 0,
            "& .kt-portlet__head": {
                borderColor: theme.palette.secondary.main,
                // marginLeft: theme.spacing(3.5),
            }
        },
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            pointerEvents: 'auto',
            width: 300,
        },
        underline: {
            textDecoration: 'underline',
        },
        button: {
            "&:hover": {
                color: 'white',
            },
            marginTop: theme.spacing(.5),
        },
        portletHeader: {
            marginLeft: "0px !important"
        },
        percentdisplay: {
            margin: 5,
            display: 'inline-block',
        },
        label: {
            fontSize: '1rem'
        },
        IconButton: { padding: 6 },
        ruleStatusFormControl: {
            marginLeft: theme.spacing(2),
            width: 200
        },
    }
});

function RuleView({ fields, filteredData, history, order, sort, serviceId, rulesListPending, feeSchedule, feeSchedulePending,
    contractId, codeServices, ruleEnablePending, ruleDisablePending, ruleChangePrecedencePending, serviceTypeObj,directoryId,
    actions: { setSort, setOrder, setFilter, getRules, getFeeschedule, getGroups, deleteRule, ruleReset, disableRule, enableRule,
        enableDisableRuleReset, changePrecedence, setCloneRuleDialog }
} : RouteComponentProps & {
    fields: any[],
    filteredData: any[],
    history: any,
    intl : any,
    order: any,
    sort: any,
    serviceId: number,
    rulesListPending: boolean,
    feeSchedule: any,
    feeSchedulePending: boolean,
    contractId: number,
    codeServices: any[],
    ruleEnablePending: boolean,
    ruleDisablePending: boolean,
    ruleChangePrecedencePending: boolean,
    serviceTypeObj: any,
    directoryId:string,
    actions: {
        setSort: (sort: any) => void,
        setOrder: (order: any) => void,
        setFilter: (filter: string) => void,
        getRules: (serviceId: number) => void,
        getFeeschedule: (feeScheduleId: number) => void,
        getGroups: (ruleId: number) => void,
        deleteRule: (ruleId: number, callback: () => void) => void,
        ruleReset: () => void,
        disableRule: (ruleId: number, callback: () => void) => void,
        enableRule: (ruleId: number, callback: () => void) => void,
        enableDisableRuleReset: () => void,
        changePrecedence: (data: any, callback: () => void) => void,
        setCloneRuleDialog: (data: any) => void
    }
}) {
    const classes = useStyles();

    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [page, setPage] = useState(0);
    const [openedPopover, setOpenedPopover] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorPosition, setAnchorPosition] = React.useState({ top: 0, left: 0 });

    const [openDialog, setOpenDialog] = useState(false);
    const [ruleId, setRuleId] = useState(null);

    const [isConfirmOpen, setConfirmOpen] = useState(false);
    const [ruleCurrentStatus, setRuleStatus] = useState(false);

    const [openCarveDialog, setOpenCarveDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState(false);
    const [tempRules, setTempRules] = useState([]);


    useEffect(() => {
        setTempRules([...filteredData])
    }, [filteredData])

    useEffect(() => {
        getRules(serviceId);

        return function cleanup() {
            setFilter('');
            ruleReset();
            enableDisableRuleReset();
        };
    }, [getRules, serviceId, setFilter]);

    const preventDefault = (event) => event.preventDefault();

    const popoverEnter = (event, rule, elemId) => {
        const element = document.getElementById(elemId)
        const rect = element.getBoundingClientRect();

        setAnchorPosition({
            top: rect.top,
            left: rect.left
        })

        const { id, feeScheduleId } = rule;
        // setAnchorEl(event.currentTarget);
        setOpenedPopover(true);
        if (feeScheduleId) {
            getGroups(id);
            getFeeschedule(feeScheduleId);
        }
    };

    const paperEnter = ({ currentTarget }) => {
        setOpenedPopover(true);
    };

    const popoverLeave = ({ currentTarget }) => {
        setOpenedPopover(false);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    const handleClose = (open) => {
        setOpenDialog(open);
        setRuleId(null)
    }

    const handleDialog = (event, ruleId) => {
        preventDefault(event);
        setRuleId(ruleId);
        setOpenDialog(true);
    }

    const handleRuleEdit = (rule) => {
        history.push(`/cms/contract/${contractId}/rule/${serviceId}/edit/${rule.id}/${directoryId || 'root'}`);
    }

    const handlePPSandEAPGConfigEdit = (config) => {
        if(config.ruleType.name === CONFIG_TYPE_IPPS) {
            history.push(`/cms/contract/edit/${contractId}?fromipps=true`);
        }
        else if(config.ruleType.name === CONFIG_TYPE_OPPS) {
            history.push(`/cms/contract/edit/${contractId}?fromopps=true`);
        }
        else if(config.ruleType.name === CONFIG_TYPE_EAPG) {
            history.push(`/cms/contract/edit/${contractId}?fromeapg=true`);
        }
    }

    const handleCopyRule = (rule) => {
        history.push(`/cms/contract/${contractId}/rule/${serviceId}/copy/${rule.id}/${directoryId || 'root'}`);
    }

    const getServiceDetails = name => {
        const codeService = codeServices.find(
            service => service.name === name
        );
        return codeService;
    };

    const handleCarveoutModalClose = (open) => {
        setOpenCarveDialog(open);
        setRuleId('');
    }

    const onHandleCarveoutClick = (date, label, ruleId) => {
        setOpenCarveDialog(true);
        setRuleId(ruleId)
    };

    const renderMultiProc = (rule) => {
        return rule.multiProcedurePercentageToRuleXref.map((perc, index) => {
            return (
                <span className={classes.percentdisplay}>{`${perc.precedence?.name} - ${perc.percentage}% ${rule.multiProcedurePercentageToRuleXref.length > index + 1 ? ',' : ''}`}</span>
            );
        });
    };

    const handleCloneDialogOpen = (ruleId, ruleName) => {
        setCloneRuleDialog({
            open: true,
            ruleId,
            ruleName,
            serviceTypeObj,
            contractId
        });
    }

    const handleCloneDialogClose = (reload) => {
        setCloneRuleDialog(null);
        if (reload) {
            handleRefresh();
        }
    }


    function renderCollapsibleItem(rule) {

        return (
            <>
                {rule && (
                    <Grid className="my-3">
                        <Grid container className="my-3">
                            <Grid item xs={12} className="mb-2">
                                <Grid container>
                                    <Grid item xs={6}>
                                        <CustomLabel label="Pay Source" data={rule.paySource?.name ?? "N/A"} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomLabel label="Method Name" data={rule.method?.name ?? "N/A"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Method Description" data={rule.method?.description} />
                                    </Grid>
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="RuleType Description" data={rule.ruleType?.description} />
                                    </Grid>
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Active" data={rule?.active ? 'Yes' : 'No'} />
                                    </Grid>
                                    {(rule.ruleType?.name === 'CARVEOUT') && (
                                        <Grid item xs={6} className="mb-2">
                                            <CustomClickableLabel onHandleCarveoutClick={onHandleCarveoutClick} ruleId={rule.id} label="CarveOut Codes" data={rule.carveoutCodes} />
                                        </Grid>
                                    )}
                                    {rule.codeService && (
                                        <Grid item xs={6} className="mb-2">
                                            <CustomLabelPopover
                                                label="Service"
                                                getDetails={getServiceDetails}
                                                data={rule.codeService?.name}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Use Paid Code" data={rule?.usePaidCode ? 'Yes' : 'No'} />
                                    </Grid>
                                    {(rule.paySource?.name === 'Schedule' && rule.useMultiProcedurePercent && rule.multiProcedurePercentageToRuleXref.length > 0) &&
                                        (
                                            <>
                                                <Grid item xs={6} className="mb-2">
                                                    <Typography variant="subtitle2" className={classes.label} display="inline" >Multiprocedure: </Typography>
                                                    <Typography variant="subtitle1" display="inline">{renderMultiProc(rule)}</Typography>
                                                </Grid>
                                            </>
                                        )}
                                    {rule.feeScheduleId && (
                                        <Grid item xs={6} className="mb-2">
                                            <Link
                                                id={`fee-schedule-text-${rule.id}`}
                                                to=""
                                                href="#"
                                                onClick={preventDefault}
                                                aria-owns="fee-schedule-mouseover-popover"
                                                aria-haspopup="true"
                                                onMouseEnter={(event) => popoverEnter(event, rule, `fee-schedule-text-${rule.id}`)}
                                                onMouseLeave={popoverLeave}
                                                className={classes.underline}
                                            >
                                                Fee Schedule
                                            </Link>
                                        </Grid>
                                    )}
                                    {rule.ruleType?.name === 'RULE' && rule.inpatientService === null && (
                                        <Grid item xs={6} className="mb-2">
                                            <Link
                                                to=""
                                                href="#"
                                                onClick={(event) => handleDialog(event, rule.id)}
                                                className={classes.underline}
                                            >
                                                Groups
                                            </Link>
                                        </Grid>
                                    )}

                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Pay In Addition" data={rule?.payInAddition ? "Yes" : "No"} />
                                    </Grid>
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Circuit Breaker" data={rule?.isCircuitBreaker ? "Yes" : "No"} />
                                    </Grid>
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Max Rate" data={rule?.maxRate ? "Yes" : "No"} />
                                    </Grid>
                                    {rule.maxRate &&
                                        <Grid item xs={6} className="mb-2">
                                            <CustomLabel label="Max Rate Value" data={rule?.maxRateValue} />
                                        </Grid>
                                    }
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Threshold" data={rule?.threshold ? "Yes" : "No"} />
                                    </Grid>
                                    {rule.threshold &&
                                        <Grid item xs={6} className="mb-2">
                                            <CustomLabel label="Threshold Value" data={rule?.thresholdValue} />
                                        </Grid>
                                    }
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Apply at Claim Level" data={rule?.applyAtClaimLevel ? "Yes" : "No"} />
                                    </Grid>
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Lesser Of" data={rule?.lesserOf ? "Yes" : "No"} />
                                    </Grid>
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Use NPI Rate" data={rule?.useNpiRate ? "Yes" : "No"} />
                                    </Grid>
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Use NPI Role" data={rule?.useNpiRole ? "Yes" : "No"} />
                                    </Grid>

                                </Grid>
                                <Grid container>
                                    {rule?.fromDate &&
                                        <Grid item xs={6} className="mb-2">
                                            <CustomLabel label="From Date" data={asDate(rule?.fromDate)} />
                                        </Grid>
                                    }
                                    {rule?.toDate &&
                                        <Grid item xs={6} className="mb-2">
                                            <CustomLabel label="To Date" data={asDate(rule?.toDate)} />
                                        </Grid>
                                    }
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Updated By" data={rule?.updatedBy} />
                                    </Grid>
                                    <Grid item xs={6} className="mb-2">
                                        <CustomLabel label="Updated At" data={moment.utc(rule?.updatedAt).local().format(DATE_TIMESTAMP_FORMAT)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </>
        )
    }

    const handleCallBack = () => {
        getRules(serviceId);
    }

    const handleServiceDelete = (rule) => {
        if (rule) {
            deleteRule(rule.id, handleCallBack);
        }
    }

    function ruleActions(rowData) {
        return (
            <div className='d-flex'>
                {rowData.hideActionsExceptEdit && (
                    <IconButton
                        color="primary"
                        className={classes.IconButton}
                        aria-label="Edit"
                        onClick={() => handlePPSandEAPGConfigEdit(rowData)}
                        size="large">
                        <Tooltip title="Edit">
                            <EditIcon />
                        </Tooltip>
                    </IconButton>
                )}
                {(!rowData.hideActionsExceptEdit) && (
                    <>
                        <IconButton
                            color="primary"
                            className={classes.IconButton}
                            aria-label="Edit"
                            onClick={() => handleRuleEdit(rowData)}
                            size="large">
                            <Tooltip title="Edit">
                                <EditIcon />
                            </Tooltip>
                        </IconButton>
                        <DeleteConfirmation handleDelete={() => handleServiceDelete(rowData)} />
                        <div>
                            <EllipsisMenu renderMenuItems={
                                [
                                    <MenuItem
                                        className='justify-content-center'
                                        onClick={() => handleStatusClick(rowData)}
                                    >
                                        {rowData.active ? <IconButton color="primary" className='p-1' aria-label="Copy" size="large">
                                            <Tooltip title={`Disable Rule`}>
                                                <i className="fa fa-times-circle text-secondary"></i>
                                            </Tooltip>
                                        </IconButton> : <IconButton color="primary" className='p-1' aria-label="Copy" size="large">
                                            <Tooltip title={`Enable Rule`}>
                                                <i className="fa fa-check-circle text-success"></i>
                                            </Tooltip>
                                        </IconButton>}
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem
                                        className='justify-content-center'
                                        onClick={() => handleCopyRule(rowData)}
                                    >
                                        <IconButton color="primary" className='p-1' aria-label="Copy" size="large">
                                            <Tooltip title={`Copy Rule`}>
                                                <CopyIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </MenuItem>,
                                    <Divider />,
                                    <MenuItem onClick={() => handleCloneDialogOpen(rowData.id, rowData.name)} className='justify-content-center'>
                                        <IconButton color="primary" className='p-1' aria-label="Copy" size="large">
                                            <Tooltip title={`Clone Rule`}>
                                                <i className="fa fa-clone text-primary font-16"></i>
                                            </Tooltip>
                                        </IconButton>
                                    </MenuItem>
                                ]
                            }
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }
    function handleRefresh() {
        getRules(serviceId);
    }

    const handleStatusClick = (rule) => {
        // Confirmation Dialog
        setRuleId(rule.id);
        setRuleStatus(rule.active);
        setConfirmOpen(true);
    }

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    }

    const handleRuleStatusChange = () => {
        handleConfirmClose();
        if (ruleCurrentStatus) {
            disableRule(ruleId, statusCallback)
        } else {
            enableRule(ruleId, statusCallback)
        }
    }

    const statusCallback = () => {
        setRuleId(null);
        handleRefresh();
    }

    const handleDragAndDrop = (prevIndex, newIndex) => {
        if (tempRules[prevIndex].hideDragAndDrop || tempRules[newIndex].hideDragAndDrop) {
            return;
        }
        const reorderedData = reorderArray(tempRules, prevIndex, newIndex);

        const carveOutRules = reorderedData.filter(d => d.ruleType.name === RULE_TYPE_CARVEOUT);
        const typeRules = reorderedData.filter(d => d.ruleType.name === RULE_TYPE_RULE);
        const defaultRules = reorderedData.filter(d => d.ruleType.name === RULE_TYPE_DEFAULT);

        const tempData = [...carveOutRules, ...typeRules, ...defaultRules]
        setTempRules(tempData);
        handleChangePrecedence(tempData);
    }

    const handleChangePrecedence = (rulesArray) => {
        const formattedData = rulesArray.map((rule, index) => {
            return {
                ruleId: rule.id,
                precedence: index + 1
            }
        });
        changePrecedence({ rulePrecedences: formattedData }, handleRefresh)
    }

    const handleAddRule = () => {
        history.push(`/cms/contract/${contractId}/rule/${serviceId}/edit/new`);
    }

    return (<>
        <Loadable loading={rulesListPending || ruleEnablePending || ruleDisablePending || ruleChangePrecedencePending}>
            <CodesCarveoutCodesDialog title={dialogTitle}
                open={openCarveDialog}
                handleClose={handleCarveoutModalClose}
                ruleId={ruleId}
            />
            <GroupsDialog open={openDialog} ruleId={ruleId} handleClose={handleClose} />
            <Card className="mt-3">
                <Card.Header>
                    <Grid container className="align-items-center">
                        <Grid xs={4} item>
                            <h5 className="mb-0">Rules</h5>
                        </Grid>
                        <Grid xs={8} item>
                            <Grid container className="justify-content-end align-items-center">
                                <RulePageTopFilter handleSearchInput={handleSearchInput} cssClass={classes.textField} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Card.Header>
                <Card.Body>
                    <Grid container>
                        <Grid item xs={12}>
                            <DataTable
                                fields={fields}
                                // data={tempRules}
                                data={tempRules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                                // data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                                showPagination={true}
                                footerPagination={() => (
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                        colSpan={fields.length + 3}
                                        count={filteredData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                )}
                                isCollapsible={true}
                                collapsibleItem={renderCollapsibleItem}
                                order={order}
                                onOrder={setOrder}
                                sort={sort}
                                onSort={setSort}
                                handleRefresh={handleRefresh}
                                onDragEnd={handleDragAndDrop}
                                grayRowKey={'active'}
                                isDnDEnabled={true}
                                onAdd={handleAddRule}
                                name='Rule'

                                hasActions={true}
                                renderActionColumn={ruleActions}
                            />
                        </Grid>
                    </Grid>
                </Card.Body>
            </Card>
        </Loadable>
        <Popover
            id="fee-schedule-mouseover-popover"
            className={classes.popover}
            classes={{
                paper: classes.paper,
            }}
            open={openedPopover}
            anchorReference="anchorPosition"
            anchorPosition={{ top: anchorPosition.top, left: anchorPosition.left + 50 }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            PaperProps={{
                onMouseEnter: paperEnter,
                onMouseLeave: popoverLeave
            }}
            disableRestoreFocus
        >
            <Loadable loading={feeSchedulePending}>
                <Portlet className={classes.portlet}>
                    <PortletHeader
                        className={classes.portletHeader}
                        title={feeSchedule?.name}
                        showTitle={true}
                        toolbar={<></>}
                    />
                    <PortletBody>
                        {feeSchedule && (
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <CustomLabel label="Effective From" data={asDate(feeSchedule.effectiveDate)} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <CustomLabel label="Effective Till" data={asDate(feeSchedule.expirationDate)} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <CustomLabel label="Description" data={feeSchedule.description} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid className="mt-4" item xs={12}>
                                            <Link
                                                to={`/cms/feeschedules/view/${feeSchedule.id}`}
                                                className={classes.underline}
                                            >
                                                More Details
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </PortletBody>
                </Portlet>
            </Loadable>
        </Popover>
        <Dialog open={isConfirmOpen} onClose={handleConfirmClose}>
            <DialogTitle>
                {ruleCurrentStatus ? 'Disable' : 'Enable'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to {ruleCurrentStatus ? 'Disable' : 'Enable'} this rule?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmClose}>
                    Cancel
                </Button>
                <Button
                    onClick={handleRuleStatusChange}
                    color="primary">
                    {ruleCurrentStatus ? 'Disable' : 'Enable'}
                </Button>
            </DialogActions>
        </Dialog>
        <CloneRule handleClose={handleCloneDialogClose} />
    </>);
}

export default withRouter(RuleView)
