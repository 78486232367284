import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Loadable } from '../../../../common';
import {
    Portlet, PortletBody,
    PortletHeader, PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import {
    Button,
    FormControlLabel,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import DeleteIcon from '@mui/icons-material/Delete';
import { BackButton } from "../../../../common/BackButton";
import { ConfigurationClearConfirmationDialog } from "../ClientsConfigurationClearConfirmationDialog";
import { AddClientsDialog } from "../ClientsDialog";

const useStyles = makeStyles(theme => ({
    deleteButton: {
        color: '#d9534f',
        marginLeft: '20px',
    },
    button: {
        margin: theme.spacing(1),
    },

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '80%',
    },
    textArea: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '80%',
    },
    switch: {
        margin: theme.spacing(1),
    },
    switchAccount: {
        margin: 0,
    },
    switchAccountFormLabel:{

        position:'absolute',
        bottom:0
    },
    margin: {
        margin: theme.spacing(1),
    },

    formControl: {
        margin: theme.spacing(1),
        width: '80%',
    },
    updatedSection:{
        display:'block'
    },
    approveIcon:{
        position:'absolute',
        bottom:'10px'
    },
    updatedSectionContainer:{
        marginLeft:'8px'
    }
    
}));

export default function ClientConfigurationPage(props) {
    const {
        intl, initialValues,initialValuesAccount, clientId, configuration, isEdit, client,updateAccountPending,
        isAccountEdit,
        getClientPending,getClientAccountPending, createClientPending,createClientAccountPending, updateClientPending, deleteClientPending,
        actions: {
            getAccountConfiguration,
            getConfiguration,
            createConfiguration,
            createAccountConfiguration,
            updateConfiguration,
            updateAccountConfiguration,
            deleteConfiguration,
            resetClientConfiguration,
            resetClientAccountConfiguration,
            getClient
        } } = props;

    const classes = useStyles();

    const [open, setOpen] = useState(false)
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

    useEffect(() => {
        getConfiguration(clientId);
        getAccountConfiguration(clientId)
        return () => {
            resetClientConfiguration();
            resetClientAccountConfiguration();
        }
    }, [clientId, getConfiguration, resetClientConfiguration,getAccountConfiguration]);

    useEffect(() => {
        getClient(clientId);
    }, [getClient, clientId]);

    const handleCallback = () => {
        props.history.push('/admin/clients?persist=true');
    }


    const handleDialogOpen = () => { setOpen(true) }
    const handleDialogClose = () => { setOpen(false) }
    const handleConfirmationDialogOpen = () => { setOpenConfirmationDialog(true) }
    const handleConfirmationDialogClose = () => { setOpenConfirmationDialog(false) }

    const handleDeleteConfiguration = () => {
        handleConfirmationDialogClose()
        deleteConfiguration(clientId, handleCallback)
    }

    return (<>
        <Loadable loading={getClientPending || createClientPending || updateClientPending || deleteClientPending}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={values => {
                    const errors = {};

                    return errors;
                }}
                onSubmit={(values, {  }) => {

                    let payload = {
                        prefixExcluded: values.prefixExcluded,
                        postfixExcluded: values.postfixExcluded,
                        fileExtensionsExcluded: values.fileExtensionsExcluded,
                        notifyOnFileNotReceived: values.notifyOnFileNotReceived,
                        notifyAfterDaysFileNotReceived: values.notifyAfterDaysFileNotReceived,
                        customClaimStatusEnabled: values.customClaimStatusEnabled,
                        clientId: clientId
                    }
                    if (isEdit) {
                        updateConfiguration(payload, handleCallback)
                    } else {
                        createConfiguration(payload, handleCallback)
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    dirty
                }) => (
                    <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                        <Portlet>
                            <PortletHeader
                                toolbar={
                                    <PortletHeaderToolbar className='w-100'>
                                        <Grid container alignItems="center">
                                            <Grid md={6} spacing={2} container alignItems="center" className="pl-3">
                                                <Typography className="text-dark" variant="h6">{`Configuration - ${client?.name}`}</Typography>
                                                {isEdit &&
                                                    <Tooltip title="Clear Configuration of this client">
                                                        <Button
                                                            size="small"
                                                            onClick={handleConfirmationDialogOpen}
                                                            className={classes.deleteButton}
                                                            startIcon={<DeleteIcon />}>
                                                            Clear
                                                        </Button>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                            <Grid md={6} container className="justify-content-end" >
                                                <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/admin/clients?persist=true" />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className='ml-3'
                                                    type="submit"
                                                    disabled={createClientPending || updateClientPending || (isEdit && !dirty)}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </PortletHeaderToolbar>
                                }
                            />
                            <PortletBody>
                                <div className={classes.container}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <TextField
                                                multiline
                                                minRows='1'
                                                maxRows='8'
                                                label="Prefix Excluded"
                                                name="prefixExcluded"
                                                className={classes.textArea}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.prefixExcluded}
                                                helperText='e.g. SLICED_;HEALTH_'
                                                error={Boolean(touched.prefixExcluded && errors.prefixExcluded)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                multiline
                                                minRows='1'
                                                maxRows='8'
                                                label="Postfix Excluded"
                                                name="postfixExcluded"
                                                className={classes.textArea}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.postfixExcluded}
                                                helperText='e.g. _SLICED;_HEALTH'
                                                error={Boolean(touched.postfixExcluded && errors.postfixExcluded)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                multiline
                                                minRows='1'
                                                maxRows='8'
                                                label="File Extensions Excluded"
                                                name="fileExtensionsExcluded"
                                                className={classes.textArea}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.fileExtensionsExcluded}
                                                helperText='e.g. exe;pdf;word'
                                                error={Boolean(touched.fileExtensionsExcluded && errors.fileExtensionsExcluded)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField
                                                label="Notify After Days File Not Received"
                                                name="notifyAfterDaysFileNotReceived"
                                                type="number"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.notifyAfterDaysFileNotReceived}
                                                helperText={touched.notifyAfterDaysFileNotReceived && errors.notifyAfterDaysFileNotReceived}
                                                error={Boolean(touched.notifyAfterDaysFileNotReceived && errors.notifyAfterDaysFileNotReceived)}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="notifyOnFileNotReceived"
                                                        checked={values.notifyOnFileNotReceived}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={classes.switch}
                                                        value={values.notifyOnFileNotReceived}
                                                    />
                                                }
                                                label="Notify On File Not Received"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="customClaimStatusEnabled"
                                                        checked={values.customClaimStatusEnabled}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={classes.switch}
                                                        value={values.customClaimStatusEnabled}
                                                    />
                                                }
                                                label="Enable Custom Claim Statuses"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </PortletBody>
                        </Portlet>
                    </form>
                )}
            </Formik>
        </Loadable>
        {/* Account Section  */}
        <Loadable loading={updateAccountPending || createClientAccountPending || getClientAccountPending}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValuesAccount}
                validate={values => {
                    
                    const errors = {};

                    if (!values.accountIdentifierNumbers) {
                        errors.accountIdentifierNumbers = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    return errors;
                }}
                onSubmit={(values, { }) => {

                    let payload = {
                        accountIdentifierNumbers:values?.accountIdentifierNumbers,
                        mergeClaims:values?.mergeClaims,
                        clientId:clientId

                    }

                    if (isAccountEdit) {
                        updateAccountConfiguration(payload, handleCallback)
                    }
                    else 
                    {
                        createAccountConfiguration(payload,handleCallback)
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    dirty
                }) => (
                    <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                        <Portlet>
                            <PortletHeader
                                toolbar={
                                    <PortletHeaderToolbar className='w-100'>
                                        <Grid container alignItems="center">
                                            <Grid md={6} spacing={2} container alignItems="center" className="pl-3">
                                                <Typography className="text-dark" variant="h6">Account</Typography>
                                              
                                            
                                            </Grid>
                                            <Grid md={6} container className="justify-content-end">
                                                <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/admin/clients?persist=true" />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className='ml-3'
                                                    type="submit"
                                                    disabled={!dirty}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </PortletHeaderToolbar>
                                }
                            />
                            <PortletBody>
                                <div className={classes.container}>
                                    <div className="row" style={{width:'90%'}}>
                                      
                                   
                                  
                                        <div className="col-md-6">
                                        <TextField
                                            type="number"
                                            required
                                            label="Account digit"
                                            name="accountIdentifierNumbers"
                                            className={classes.textField}
                                            
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.accountIdentifierNumbers}
                                            helperText={
                                                touched.accountIdentifierNumbers && errors.accountIdentifierNumbers
                                            }
                                            error={Boolean(
                                                touched.accountIdentifierNumbers && errors.accountIdentifierNumbers
                                            )}
                                        />

                                
                                              {initialValuesAccount?.updatedAt && 
                                               <Tooltip title="Validate Claims">
                                                    <IconButton size='small' onClick={handleDialogOpen} className={classes.approveIcon}>
                                                        <CheckCircleOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                }
                                            

                                               
                                        </div>

                                        <div className="col-md-6">
                                        <FormControlLabel
                                        className={classes.switchAccountFormLabel}
                                                control={
                                                    <Switch
                                                        name="mergeClaims"
                                                        checked={values.mergeClaims}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={classes.switchAccount}
                                                        value={values.mergeClaims}
                                                    />
                                                }
                                                label="Merge claims by account id."
                                            />
                                    </div> 


                         
                                    </div>

                                </div>
                                {initialValuesAccount?.updatedAt &&
                                <div className={classes.updatedSectionContainer}>
                                    <span className={classes.updatedSection}>
                                        <b>Updated At :</b> {initialValuesAccount?.updatedAt}
                                    </span>

                                    <span className={classes.updatedSection}>
                                        <b>Updated by :</b> &nbsp;{initialValuesAccount?.updatedBy}
                                    </span>
                                </div>
                                    }

                            </PortletBody>
                        </Portlet>
                    </form>
                )}
            </Formik>
        </Loadable>
        <AddClientsDialog open={open} handleClose={handleDialogClose} accountDigit={parseInt(initialValuesAccount?.accountIdentifierNumbers)} clientId={clientId}/>
        <ConfigurationClearConfirmationDialog open={openConfirmationDialog} handleClose={handleConfirmationDialogClose} handleSubmit={handleDeleteConfiguration}/>
    </>);
}

