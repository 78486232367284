import { TableRow } from '@mui/material';
import React from 'react';

function FeeScheduleDroppable({ children, item, classes, selectedFeeScheduleIds, handleRefresh, actions: { moveDirectoryOrFeeSchedule, resetSelectedFeeSchedule } }) {

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedItems = JSON.parse(e.dataTransfer.getData('items'));
        onDrop(droppedItems, e.target.id);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const onDrop = (droppedItems, folderId) => {
        const isExist = droppedItems.find(di => di === folderId);
        if (isExist) return
        const destinationDirectoryId = folderId.split('folder-')[1];
        if (!destinationDirectoryId) return
        let feeScheduleIds = [];
        let directoryIds = [];
        if (selectedFeeScheduleIds.length > 0) {
            for (let item of selectedFeeScheduleIds) {
                const splittedItem = item.split('-');
                if (splittedItem[0] === 'feeschedule') {
                    feeScheduleIds.push(splittedItem[1]);
                } else if (splittedItem[0] === 'folder') {
                    directoryIds.push(splittedItem[1])
                }
            }
        } else {
            for (let item of droppedItems) {
                const splittedItem = item.split('-');
                if (splittedItem[0] === 'feeschedule') {
                    feeScheduleIds.push(splittedItem[1]);
                } else if (splittedItem[0] === 'folder') {
                    directoryIds.push(splittedItem[1])
                }
            }
        }

        const payload = {
            feeScheduleIds,
            directoryIds,
            destinationDirectoryId
        }
        moveDirectoryOrFeeSchedule(payload, handleCallback);
    };

    const handleCallback = () => {
        handleRefresh();
        resetSelectedFeeSchedule();
    }

    const handleDragStart = (e) => {
        e.dataTransfer.setData('items', JSON.stringify([e.target.id]));
    };

    return (
        <>
            <TableRow id={`folder-${item.id}`} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleDrop} className={`${classes.trBody} fee-schedule-row`} key={item.id}>
                {children}
            </TableRow>
        </>
    )
}

export default FeeScheduleDroppable
