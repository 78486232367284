import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { acknowledge } from '../../../store/actions/claimAnalysis';
import AcknowledgeDialogComponent from './AcknowledgeDialog';
import { setV2 } from '../../../store/actions/pagesV2';

export const AcknowledgeDialog = connect(
    () => {
        const selectClaimNumber = (state, props) => props.claimNumber;
        
        const selectInitialValues = createSelector(
            (state, props) => props.selectedItemsPtr.value,
            (claimIds) => {
                return {
                    analysisId: claimIds,
                    acknowledge: true,
                    comment: '',
                }
            }
        )

        return (state, props) => ({
            acknowledgeClaimPending: state.claimAnalysis.acknowledge.pending,

            initialValues: selectInitialValues(state, props),
            claimNumber: selectClaimNumber(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            acknowledgeClaim: acknowledge,
        }, dispatch)
    })
)(injectIntl(AcknowledgeDialogComponent));