import React from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from "@mui/material";

export default function ConfirmationDialog(props) {
    const { 
        open, handleClose, handleSubmit,
        closeText, confirmText
    } = props;

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-dialog-title" fullWidth>
            <DialogTitle id="confirm-dialog-title">No dashboards are selected for the given user</DialogTitle>
            <DialogContent>
                <p>Are you sure you want to create user without assigning dashboard?</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    {closeText}
          </Button>
                <Button variant="contained" onClick={handleSubmit} color="secondary">
                    {confirmText}
          </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmationDialog.defaultProps = {
    closeText: 'Cancel',
    confirmText: 'Confirm',
}