import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Button,
    IconButton,
    TablePagination,
    Typography,
    Tooltip,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { DataTable, TablePaginationActions } from '../../../common';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function PayerImport(props) {
    const { open, payerImportPending, handleClose, fields, filteredData, 
        order, sort, payerExportPending, 
        actions: { importPayerInfo, payerImportErrorReset, setOrder, setSort, exportPayers }
    } = props;
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (open) {
            importPayerInfo();
        }
        return function cleanup() {
            payerImportErrorReset();
        };
    }, [open]);

    const handleExport = () => {
        if(filteredData?.length > 0) {
            exportPayers(filteredData?.map(a => a.id), downloadTemplateCallback);
        }
    }

    function downloadTemplateCallback({ file, fileName }) {
        let url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function tableLevelActions() {
        return (
            <>
                <Tooltip title="Export">
                    <IconButton aria-label="download" size='small' onClick={handleExport}
                        disabled={payerExportPending || filteredData === 0}
                    >
                        <GetAppIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    // function actionLevelGrid() {
    //     return <Grid container>
    //                 <Grid item md={12}>
    //                     <h6 className="">{`Total Records : ${(filteredData?.length > 0) ? filteredData?.length : 0}`}</h6>
    //                 </Grid>
    //             </Grid>
    // }

    return (
        (<Dialog fullWidth maxWidth='lg' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">Imported Payers</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <DataTable
                    fields={fields}
                    data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    order={order}
                    onOrder={setOrder}
                    sort={sort}
                    onSort={setSort}
                    loading={payerImportPending}
                    tableLevelActions={tableLevelActions}
                    // actionLevelGrid={actionLevelGrid}
                    showPagination={true}
                    footerPagination={() => (
                        <TablePagination
                            rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                            colSpan={fields.length + 2}
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    )}
                />
                {/* <br />
                <Grid item xs={1} className={`pull-right`} >
                    <Button variant='contained' color='primary' onClick={handleExport}>
                        Export
                    </Button>
                </Grid> */}
            </DialogContent>
        </Dialog>)
    );
}