import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';

function ExportButton({ page, filter, sort, shoppablesExportPending, shoppableDataLength, actions: { exportShoppable } }) {

    function handleExportShoppables() {
        const payload = {
            page: page,
            filter: filter || null,
            sort: sort || [],
        };
        exportShoppable(payload, downloadTemplateCallback);
    }
    function downloadTemplateCallback({ file, fileName }) {
        let url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    }


    return (
        <Tooltip title="Export">
            <IconButton aria-label="download" size='small' onClick={handleExportShoppables}
                disabled={shoppablesExportPending || shoppableDataLength === 0}
            >
                <GetAppIcon />
            </IconButton>
        </Tooltip>
    )
}

export default ExportButton
