import React, { useState, useEffect } from 'react';
import {
    Tooltip,
    IconButton,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    TablePagination,
    InputLabel,
    FormControl,
    FormHelperText,
    MenuItem,
    Select
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';
import { DataFilter, DataTable, TablePaginationActions } from '../../../common';
import { ImportDialog } from './ImportDialog';
import CloseIcon from '@mui/icons-material/Close';

const DEFAULT_SORT = [{ id: 'drg', direction: 'asc' }];
const SHOW_SEARCH_FILTERS = true;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },

    icon: {
        fontSize: 17,
        marginTop: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogContent: {
        padding: '0px',
    },
    formControl: {
        paddingRight: theme.spacing(1),
        width: '60%',
        marginRight:"20px",
        marginTop:"-10px"
    },
}));

export default function MasterImport({
    intl, lookupDialogOpen, hospitalServicesMasterData, hospitalServicesMasterFields, 
    userRole, order, filter, sort, lookupHospitalServicesPending, handleRefresh, selectedItems,
    facilities,
    actions: {
        hospitalServicesLookupFromMaster,
        hospitalServicesLookupErrorReset,
        setHopsitalServicesLookupDialog,
        setHopsitalServicesLookupImportConfirmationDialog,
        setOrder, setSort, setFilter,
        setSelectedItems
    }
}) {
    const [facilityError, setFacilityError] = useState(false);
    const [facilityValue, setFacilityValue] = useState('');


    useEffect(() => {
        if (lookupDialogOpen) {
            setFilter('');
            hospitalServicesLookupFromMaster();
        }
        return function cleanup() {
            hospitalServicesLookupErrorReset();
            setSelectedItems([]);
            setFacilityError(false)
        };
    }, [lookupDialogOpen]);

    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);

    const classes = useStyles();

    function handleImport() {
        if(!facilityValue)
        {
            setFacilityError(true)
            return
        }
        setHopsitalServicesLookupImportConfirmationDialog({open: true});
    }

    function handleLookupDialogClose() {
        setHopsitalServicesLookupDialog(null);
        handleRefresh();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchInput = (value) => {
        setFilter(value);
        setPage(0);
    }

    return (<>
        <Dialog fullWidth maxWidth='lg' open={lookupDialogOpen} onClose={handleLookupDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant="h6">Lookup DRGs</Typography>
                </Grid>
                <Grid item xs={4}>
                    <DataFilter
                        onChange={handleSearchInput}
                        placeholder="Search"
                        //cssClass={classes.textField}
                        filter={filter}
                    />
                </Grid>

                <Grid container item xs={4} justifyContent='right'><FormControl className={classes.formControl} error={facilityError}>
                                    <InputLabel htmlFor="facility">Select Facility</InputLabel>
                                    <Select
                                        value={facilityValue}
                                        onChange={(event) => {
                                            setFacilityError(false)
                                            setFacilityValue(event.target.value)}}
                                        inputProps={{
                                            name: "facility",
                                            id: "facility"
                                        }}
                                    >
                                        {facilities.map(({ id, name }) => (
                                            <MenuItem
                                                key={name}
                                                value={id}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {facilityError && (
                                        <FormHelperText error>Please select a facility</FormHelperText>
                                    )}
                                </FormControl>
                                
                                <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleLookupDialogClose}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                                </Grid>


            </Grid>
        </DialogTitle>
        <DialogContent dividers>
            <DataTable
                pageName='hospitalservices-master-landing'
                fields={hospitalServicesMasterFields}
                data={hospitalServicesMasterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                order={order}
                onOrder={setOrder}
                sort={sort}
                onSort={setSort}
                loading={lookupHospitalServicesPending}
                showPagination={true}
                hasCheckbox={true}
                showSelectAll={hospitalServicesMasterData.some(record => record.disabledCheckbox === true) ? false : true}
                footerPagination={() => (
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                        colSpan={hospitalServicesMasterFields.length + 2}
                        count={hospitalServicesMasterData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                )}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleLookupDialogClose} variant='contained'>
                Cancel
            </Button>
            <Button
                disabled={selectedItems?.length === 0 || lookupHospitalServicesPending}
                variant='contained'
                onClick={handleImport}
                color="primary">
                Import DRGs
            </Button>
        </DialogActions>
        </Dialog>
        <ImportDialog handleRefresh={handleRefresh} facilityValue={facilityValue}/>
    </>);
}