import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from '@mui/material';

import './eulaPage.scss';

function EulaPage(props) {

    const { eulaAcceptPending } = props;

    function handleAcceptCallback() {
        props.history.push('/');
    }

    function handleClose() {
        const { actions: { sessionLogout } } = props;
        sessionLogout();
    }

    function handleAgree() {
        const { actions: { sessionAcceptEula } } = props;
        sessionAcceptEula(handleAcceptCallback);
    }

    return (
        <React.Fragment>
            <Dialog
                open={true}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle id="scroll-dialog-title">PARTICIPATION AND END USER LICENSE AGREEMENT (EULA)</DialogTitle>
                <DialogContent dividers={true}>
                    <div className="MuiTypography-root MuiDialogContentText-root MuiTypography-body1 MuiTypography-colorTextSecondary">
                        <div>
                            <p>The foregoing Participation and End User License Agreement (the “Agreement”), constitutes a binding legal agreement betweenyou(defined below) and Sliced Health, LLC (“Sliced Health”). The terms and conditions contained herein apply toyour use of (including any access to) Sliced Health’s proprietary solutions including without limitation:</p>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <b><u>SlicedINSIGHTS</u> </b>:Includes access to SlicedHealth's SaaS-based proprietary business intelligence service which
                                    analyzes customer data and provides user-friendly dashboards.
                                    <ul>
                                        <li>Near real-time access to data</li>
                                        <li>Drill-down to detail</li>
                                        <li>Setup and bi-weekly review</li>
                                    </ul>
                                </li>
                                <li>
                                    <b><u>SlicedCONTRACTS</u> </b>:Includes access to Sliced Health's SaaS-based proprietary contract management service
                                    which analyzes historical payer payments to ensure compliance with contractual agreements. Alerts the health
                                    system or practice to payments outside of negotiated terms and enables easy management of contract
                                    renewals.
                                </li>
                                <li>
                                    <b><u>SlicedPRICER</u> </b>:Includes access to Sliced Health's SaaS-based proprietary price publishing service which enables
                                    Customer to meet the requirements of the 2019 Executive Order on Improving Price and Quality Transparency
                                    in American Healthcare to put patients first, as well as the 2021 Executive Order for Promoting Competition in
                                    the American Economy section 5. (p) (ii).
                                </li>
                                <li>  <b><u>SlicedESTIMATOR</u> </b>:
                                    Includes access to Sliced Health's SaaS-based proprietary price estimation service which
                                    enables Customer to meet the requirements of The Consolidation Appropriations Act of 2021 which protects
                                    patients from No Surprise Bill. Enables Good Faith Estimates to be delivered to patients, and includes the
                                    flexibility of consumer and business office use.
                                </li>
                                <div className='mt-3'>(together with all content, software, services and other materials available on or through such solution, successor
                                    solution(s) to such solution, the “Solution”). This Agreement hereby incorporates by this reference any additional terms
                                    and conditions posted by Sliced Health through the Solution, or otherwise made available to you by Sliced Health
                                    regarding the Solution.</div>
                            </ul>
                        </div>
                        <div>
                            <p>
                                If you are an individual accessing or using the Solution on behalf of, or for the benefit of, any corporation, partnership or other
                                entity with which you are associated (an “Organization”), then you are agreeing to this Agreement on behalf of yourself and such
                                Organization, and you represent and warrant that you have the legal authority to bind such Organization to this Agreement.
                                References to “you” and “your” in this Agreement will refer to both the individual using the Solution and to any such
                                Organization; however, if such Organization and Sliced Health enter into a separate written agreement relating to the Solution
                                or other subject matter of this Agreement (a “Master Agreement”), such separate Master Agreement will govern such
                                Organization's access to and use of the Solution and supersede this Agreement to the extent and as set forth in such Master
                                Agreement.
                            </p>
                            <p>
                                By clicking "I Agree" or by obtaining login credentials, or otherwise accessing (whether by obtaining a license key, downloading
                                or installing) this Solution, you represent that you are acting on behalf of yourself, as an individual, or your Organization, as
                                appropriate, and that you have read this Agreement, that you understand it, and that you accept it and agree to be bound by its
                                terms. We are relying upon your agreement in making the Solution available to you. If at any time you are not willing to be bound
                                by these terms and conditions, click the “I Do Not Agree” button, immediately cease and refrain from accessing or using the
                                Solution or related website(s).
                            </p>
                            <p>
                                THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION THAT, AS FURTHER SET FORTH IN SECTION 7
                                BELOW, REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS
                                OR ANY OTHER COURT PROCEEDINGS, OR CLASS ACTIONS OF ANY KIND.

                            </p>
                            <p>
                                <u>DISCLAIMER OF WARRANTIES:</u> YOU AGREE THAT SLICED HEALTH HAS MADE NO EXPRESS WARRANTIES TO YOU
                                REGARDING THE SOLUTION. THE SOLUTION AND DOCUMENTATION ARE LICENSED “AS IS,” AND SLICED HEALTH DISCLAIMS
                                ANY AND ALL OTHER WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY INCLUDING, WITHOUT LIMITATION, ANY
                                2
                                Ver: Updated May 15, 2023
                                IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY,
                                TIMELINESS, TITLE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, TO THE FULLEST EXTENT AUTHORIZED BY LAW.
                                WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, SLICED HEALTH DOES NOT WARRANT THAT THE SOLUTION
                                WILL MEET YOUR REQUIREMENTS OR THAT OPERATION OF THE SOLUTION WILL BE UNINTERRUPTED, TIMELY, SECURE,
                                OR ERROR-FREE, THAT DEFECTS OR ERRORS IN THE SOLUTION WILL BE CORRECTED OR THAT THE SOLUTION WILL BE
                                COMPATIBLE WITH FUTURE PRODUCTS, OR THAT ANY INFORMATION OR DATA STORED OR TRANSMITTED THROUGH THE
                                SOLUTION WILL NOT BE LOST, CORRUPTED OR DESTROYED.
                            </p>
                            <p>
                                <u>LIMITATION OF LIABILITY:</u> IN NO EVENT SHALL SLICED HEALTH BE LIABLE TO YOU OR ANY PARTY RELATED TO YOU FOR
                                ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES (INCLUDING, WITHOUT
                                LIMITATION, DAMAGES FOR PERSONAL INJURY, SICKNESS, DEATH, BUSINESS INTERRUPTION, LOSS OF PATIENT DATA OR
                                OTHER SUCH PECUNIARY LOSS), WHETHER UNDER A THEORY OF CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE),
                                PRODUCTS LIABILITY, OR OTHERWISE, EVEN IF SLICED HEALTH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                                DAMAGES. IN NO EVENT WILL SLICED HEALTH'S TOTAL AGGREGATE AND CUMULATIVE LIABILITY FOR ANY AND ALL
                                CLAIMS OF ANY KIND ARISING HEREUNDER EXCEED THE GREATER OF (I) THE AMOUNT OF FEES ACTUALLY RECEIVED BY
                                SLICED HEALTH FOR THE SOFTWARE GIVING RISE TO THE CLAIM IN THE TWELVE MONTHS PRECEDING THE CLAIM, OR (II)
                                ONE HUNDRED DOLLARS ($100.00). THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS
                                OF ITS ESSENTIAL PURPOSE. REMEDIES UNDER THIS AGREEMENT ARE EXCLUSIVE AND ARE LIMITED TO THOSE EXPRESSLY
                                PROVIDED FOR IN THIS AGREEMENT
                            </p>
                            <h5>(1)	GENERAL AGREEMENT</h5>
                            <ol className='lower-alpha'>
                                <li><b>Purpose. </b>
                                    Whether the Solution is presented independently or integrated with and/or made available by your
                                    Organization pursuant to a separate Master Agreement, this Agreement will state the terms and conditions under which
                                    you may obtain login credentials and access to the Solution and use the Solution.
                                </li>
                                <li><b>Updates. </b>
                                    Unless provided otherwise in a Master Agreement, this Agreement, along with any additional terms or policies
                                    incorporated herein by reference, represent the entire agreement between you and Sliced Health concerning the
                                    Solution, and this Agreement supersedes and replaces any prior representation, agreement or understanding you may
                                    have had with Sliced Health relating to the Solution. Sliced Health may reasonably revise and update this Agreement at
                                    any time. Prior notice of an update together with your continued usage of the Solution will mean you accept those
                                    changes. We encourage you to periodically review this Agreement to see if there have been any updates.
                                </li>
                            </ol>
                            <h5>(2)	LICENSE</h5>
                            <ol className='lower-alpha'>
                                <li>
                                    <b>License Grant. </b>
                                    The Solution is licensed (not sold) to end users. Subject to your full and ongoing compliance with the
                                    terms and conditions of this Agreement and the Master Agreement, if applicable, Sliced Health hereby grants you a
                                    personal, limited, non-exclusive, non-transferable, non-assignable, revocable license to use the Solution during the
                                    Term in machine-readable, object code form only as authorized in this Agreement. Notwithstanding the foregoing,
                                    Sliced Health shall be under no obligation to provide any specific updates, enhancements, modifications, revisions, or
                                    additions to the Solution. Your license to use the Solution is conditioned on the following license restrictions, and any
                                    use of the Solution in violation of any of these restrictions, or any of the other terms of this Agreement is a breach of
                                    this Agreement.
                                </li>
                                <li>
                                    <b>Restrictions. </b>
                                    You shall not, and shall not attempt to, use the Solution to do any of the following:
                                    <ol className='lower-roman'>
                                        <li>Use the Solution for any purpose that is fraudulent or otherwise tortious or unlawful;</li>
                                        <li>Reproduce, copy, display, modify, adapt, translate, create derivative works of, sell, rent, lease, loan, distribute or
                                            otherwise exploit any portion of (or any use of) the Solution except as expressly authorized herein, without Sliced
                                            Health's express prior written consent</li>
                                        <li>Reverse engineer, decompile, or disassemble any portion of the Solution;</li>
                                        <li>Any action intended to disable, bypass, defeat, avoid, remove, deactivate or otherwise circumvent any security or
                                            authentication measures in the Solution or Sliced Health's network or any technical measures Sliced Health have
                                            implemented to safeguard the stability of the Solution, or the confidentiality, integrity or availability of any
                                            information, content or data hosted or housed by Sliced Health;</li>
                                        <li>Remove or alter any copyright, trademark or other proprietary rights notice on the Solution; </li>
                                        <li>Frame or mirror any portion of the Solution, or otherwise incorporate any portion of the Solution into any product
                                            or service, without Sliced Health's express prior written consent;</li>
                                        <li>Access or search the Solution by any means other than the interfaces supported by Sliced Health (for example, by
                                            “data scraping,” "data mining" or any other activity with the purpose of obtaining lists of users or other information
                                            from the Solution or that uses web “bots” or similar data gathering or extraction methods); </li>
                                        <li>Provide access to the Solution, or otherwise make the Solution available, to any third party other than as permitted
                                            by this Agreement, without the prior written consent of Sliced Health; or </li>
                                        <li>Use the Solution in any way that violates applicable law or regulations.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <h5>(3) DISCLAIMERS</h5>
                            <ol className='lower-alpha'>
                                <li>
                                    <b>Use. </b>
                                    The Solution (including any information and materials made available to you in connection with using the Solution)
                                    is not for any third party's use or benefit and is not intended to be relied upon by you or any third party, including to
                                    operate or promote your business, obtain any regulatory or governmental approvals, or otherwise be used in
                                    connection with procuring services or other benefits from any entity
                                </li>
                                <li>
                                    <b>Third Party Materials; Links. </b>
                                    Certain Solution functionality may make available access to information, products,
                                    services and other materials made available by third parties (“Third-Party Materials”) or allow for the routing or
                                    transmission of such Third-Party Materials, including via links. By using such functionality, you are directing us to
                                    access, route and transmit to you the applicable Third-Party Materials. We neither control nor endorse, nor are we
                                    responsible for, any Third-Party Materials, including the accuracy, validity, timeliness, completeness, reliability,
                                    integrity, quality, legality, usefulness or safety of Third-Party Materials, or any intellectual property rights therein.
                                    Certain Third-Party Materials may, among other things, be inaccurate, misleading or deceptive. Nothing in this
                                    Agreement will be deemed to be a representation or warranty by Sliced Health with respect to any Third-Party
                                    Materials. We have no obligation to monitor Third-Party Materials, and we may block or disable access to any ThirdParty Materials (in whole or part) through the Solution at any time. In addition, the availability of any Third-Party
                                    Materials through the Solution does not imply our endorsement of, or our affiliation with, any provider of such ThirdParty Materials, nor does such availability create any legal relationship between you and any such provider.
                                </li>
                                <li>
                                    <b>Open Source. </b>
                                    The Solution may include open source components, which are licensed for use and distribution by us
                                    under applicable open source licenses. Use of these open source components is governed by and subject to the terms
                                    and conditions of the applicable open source license.
                                </li>
                                <li>
                                    <b>HIPAA. </b>
                                    You may not use the Solution in any way that violates HIPAA (hereinafter defined) or any regulations adopted
                                    under or implementing HIPAA or the HITECH Act (including, but not limited to, the Standards for Privacy of Individually
                                    Identifiable Health Information set forth at 45 CFR Parts 160 and 164, as amended). You agree to: (i) obtain from your
                                    patients all requisite written consents in order to utilize the capabilities of the Solution in compliance with applicable
                                    laws, rules and regulations, including, but not limited to HIPAA, and to carry out the purposes of this Agreement, (ii)
                                    use any data provided via the Solution, including but limited to PHI (hereinafter defined), plan eligibility data, formulary
                                    data and claim history data, solely to carry out treatment, payment and health care operations described in § 164.506(c)
                                    of the Standards for Privacy of Individually Identifiable Health Information, 45 C.F.R. Parts 160 and 164 (the "Privacy
                                    Rule"); and (iii) use all such data in compliance with the minimum necessary standard described in § 164.502(b) of the
                                    Privacy Rule. "PHI" means all protected health information as defined in the Health Insurance Portability and
                                    Accountability Act of 1996, as amended ("HIPAA"), and the regulations promulgated thereunder, that is provided or
                                    received, directly or indirectly, by you via the Solution. Sliced Health may use any data provided via the Solution,
                                    including but not limited to PHI, in connection with the provision of the Solution hereunder and as otherwise permitted
                                    by applicable laws (including without limitation, HIPAA). When using Solution, information will be transmitted over a
                                    medium that may be beyond the control and jurisdiction of Sliced Health and its suppliers and licensors. Accordingly,
                                    Sliced Health assumes no liability for or relating to the delay, failure, interruption, or corruption of any data or other
                                    information transmitted in connection with use of the Solution.

                                </li>
                                <li>
                                    <b>Patient Care. </b>
                                    Sliced Health has no interaction whatsoever with patients. Ensuring the health and welfare of patients
                                    shall at all times be the sole and exclusive responsibility of you, not Sliced Health. Sliced Health shall have no liability
                                    whatsoever concerning the care of patients. Neither Sliced Health nor the Solution gives medical advice, nor do they
                                    provide medical or diagnosis services. Use of the Solution is not a substitute for the professional judgment of healthcare
                                    4
                                    Ver: Updated May 15, 2023
                                    providers in diagnosing and treating patients. You acknowledge that you are solely responsible for verifying the
                                    accuracy of all information (including, without limitation, by obtaining all applicable patients' medical and medication
                                    history), and for all medical decisions or actions with respect to the medical care, treatment and well-being of your
                                    patients, including, without limitation, all your acts or omissions in treating the applicable patient.
                                </li>
                            </ol>
                            <h5>(4) INTELLECTUAL PROPERTY AND CONFIDENTIALITY.</h5>
                            <ol className='lower-alpha'>
                                <li>
                                    <b>Benchmarking Data. </b>
                                    By utilizing Sliced Health's Benchmarking Solution, you understand that you will be obligated to
                                    provide Sliced Health with certain data. You agree that Sliced Health will have the right to use, copy, prepare derivative
                                    works from, display, and/or distribute any or all of your data in de-identified form, that Sliced Health may authorize
                                    third parties to do so as well, and that Sliced Health may transfer those rights. You will not be able to revoke those
                                    rights and you will not be paid any fee or royalty in connection with them. Participant data will appear in benchmarking
                                    reports in aggregate, de-identified form. Except as set forth in this Agreement, Sliced Health will not disclose your data
                                    to any third party. Sliced Health does not guarantee the accuracy or completeness of any information contained in
                                    reports and you agree that any use of that information will be at your own risk.

                                </li>
                                <li>
                                    <b>Proprietary Rights to Solution and Trademarks. </b>
                                    . You acknowledge that the Solution and reports generated by Sliced
                                    Health are proprietary to Sliced Health and are protected under United States copyright and other intellectual property
                                    laws. Except for the limited, revocable license expressly granted to you herein, this Agreement does not grant you any
                                    ownership or other right or interest in or to the Solution or any other intellectual property rights of Sliced Health,
                                    whether by implication, estoppel, or otherwise.
                                </li>
                                <li>
                                    <b>Confidentiality and Misappropriation of Login Credentials. </b>
                                    The Solution is structured to allow access only to users
                                    with a valid password. You will be responsible for the assignment of access and usage privileges to users of the Solution,
                                    including but not limited to taking all reasonable steps to ensure that no unauthorized person shall have access to your
                                    Solution password or account. It is your sole responsibility to: (i) control the dissemination and use of activation codes
                                    and passwords; (ii) authorize, monitor, and control access to and use of your Solution account and password; (iii)
                                    promptly inform Sliced Health of any need to deactivate a password. Your assignment of access and usage privileges
                                    shall be in conformity with applicable laws and regulations, including but not limited to those related to proper
                                    licensure of users. You will be responsible for ensuring that the licensing information and the dates for permitted use
                                    of the Solution are correctly entered and updated. Sliced Health is responsible for neither the granting nor monitoring
                                    of user privileges to the Solution nor any incorrect entry or lack of updating of licensing information by you. You grant
                                    Sliced Health and all other persons or entities involved in the operation of the Solution the right to transmit, monitor,
                                    retrieve, store, and use your information in connection with the operation of Solution. Sliced Health cannot and does
                                    not assume any responsibility or liability for any information you submit, or your or third parties' use or misuse of
                                    information transmitted or received using Solution. You are solely responsible for any and all activities that occur under
                                    your account including all acts and omissions. Sliced Health will have no liability whatsoever in the event that your
                                    account or patient data is accessed by anyone other than you, using your login credentials. You agree to protect your
                                    password and applicable devices from all unauthorized use. If your login credentials are misappropriated or stolen, or
                                    if you suspect any improper or illegal usage of your license outside of your control you will promptly notify Sliced Health
                                    of such occurrence. You agree to cooperate with and assist Sliced Health in identifying and preventing any unauthorized
                                    use, or attempted use of the Solution. Sliced Health may assume that any communications it receives from your email
                                    or other address, or communications that are associated with your login credentials or your account on the Solution,
                                    have been made by you unless we receive written notice indicating otherwise.
                                </li>
                                <li>
                                    <b>Access. </b>
                                    In order to use the Solution, you need access to the world wide web, either directly or through devices that
                                    access web-based content, and that such access will not be available if you do not have an Internet connection. You
                                    acknowledge and agree that by using the Internet to use the Solution, you may incur charges from your wireless carrier,
                                    Internet service provider or other method of Internet access, depending upon your contract or plan with your provider.
                                    In addition, you must provide and are responsible for all equipment necessary to access the site. You may not bypass
                                    any measures that have been implemented to prevent or restrict access to the Solution. Any unauthorized access to the
                                    Solution by you (including any such access or use that involves in any way an account you may establish or any device
                                    you may use to access the Solution) shall terminate the license granted to you by Sliced Health
                                </li>
                            </ol>
                            <h5>(5) TERM AND TERMINATION. </h5>
                            <ol className='lower-alpha'>
                                <li>
                                    <b>General. </b>
                                    This Agreement is effective upon the earlier of your acceptance of the Agreement or upon your accessing and
                                    using the Solution, even if you have not expressly accepted this Agreement. This Agreement shall continue in effect until
                                    expiration or termination as provided herein (the “Term”). Without prejudice to any other rights, this Agreement and
                                    your access to the Solution will terminate automatically without notice to you: (i) upon termination of your Master
                                    5
                                    Ver: Updated May 15, 2023
                                    Agreement, (ii) upon your termination from the Organization, or (iii) if you breach or fail to comply with any of the
                                    terms or conditions described herein. You may terminate this Agreement at any time by providing written notice to
                                    Sliced Health and ceasing use of the Solution.

                                </li>
                                <li>
                                    <b>Suspension or Termination of Account. </b>
                                    Notwithstanding anything contained herein to the contrary, Sliced Health can
                                    immediately and without prior notice, suspend or terminate your access to the Solution if, in its sole discretion, Sliced
                                    Health believes: (i) there is risk to the security or privacy of your account; (ii) there is a threat to the security or integrity
                                    of Sliced Health's network or the Solution; (iii) suspension or termination is needed to protect the rights, property or
                                    safety of Sliced Health, its users or patients or is required by law.
                                </li>
                                <li>
                                    <b>No Liability for Termination or Suspension. </b>
                                    Sliced Health shall not be liable to you or any third party for any claims
                                    or damages arising out of any termination or suspension of your account or any other actions taken in connection with
                                    such termination or suspension, including the removal and deletion of your information that may be in Sliced Health's
                                    possession.
                                </li>
                            </ol>
                            <p>
                                <h5>(6) INDEMNIFICATION. </h5>
                                You hereby release and forever waive any and all claims you may have against Sliced Health, its
                                officers, directors, employees, agents, consultants, information providers, licensors or suppliers for losses or damages you
                                sustain in connection with your use of Solution. You agree to indemnify, defend and hold harmless Sliced Health from and against
                                all losses, liability, expenses, damages and costs including reasonable attorney's fees, arising out of or related to: (i) your use of
                                the Solution in breach of this Agreement, (ii) any allegation that information or materials provided by you, or Sliced Health's
                                receipt or use thereof, infringes any intellectual property right or misappropriates any trade secret of that third party, (iii) your
                                failure to comply with applicable law, (iv) your negligent or wrongful action or omission related to your use of the Solution, and
                                (v) any dispute between you and any patient or customer of yours.
                            </p>
                            <h5>(7) GENERAL TERMS.</h5>
                            <ol className='lower-alpha'>
                                <li>
                                    <b>Disclaimer of Warranties; Limitation of Liability. </b>
                                    The paragraphs entitled “Disclaimer of Warranties” and
                                    “Limitation of Liability” in the preamble are incorporated herein by reference. Without limiting the generality of the
                                    foregoing, Sliced Health shall have no liability for claims arising from or relating to: (i) use of the Solutions other than
                                    for its intended purpose; (ii) access or use of the Solution by any unauthorized user, (iii) use of the Solution in
                                    combination with any data, software, or hardware not approved by Sliced Health, or (iv) breach of this Agreement by
                                    you or your Organization.

                                </li>
                                <li>
                                    <b>Disruption of Services. </b>
                                    You acknowledge and agree that from time to time the Solution may be inaccessible or
                                    inoperable due to equipment malfunctions, periodic maintenance procedures, repairs or upgrades which Sliced Health
                                    may undertake from time to time, service malfunctions and causes beyond the reasonable control of Sliced Health or
                                    which are not reasonably foreseeable by Sliced Health, including, without limitation, interruption or failure of
                                    telecommunication or digital transmission links, including delays or failures due to your hardware, Internet service
                                    provider, hostile network attacks, network congestion or other failures.
                                </li>
                                <li>
                                    <b>Conflict Resolution. </b>
                                    Any controversy, claim or dispute arising out of or relating to the performance, construction,
                                    interpretation or enforcement of this Agreement, including disputes as to the scope of this clause, shall be resolved
                                    through good faith negotiations between the parties. If such efforts prove unsuccessful, all such controversies, claims
                                    or disputes shall be submitted to mandatory, final, confidential, binding arbitration pursuant to the Federal Arbitration
                                    Act, 9 U.S.C., § et seq. Arbitration shall be conducted in accordance with the Commercial Arbitration Rules of the
                                    American Arbitration Association in Atlanta, Georgia. The arbitration award shall be final and binding and it may be
                                    confirmed and enforced in any court of competent jurisdiction. Each party shall pay for all attorney fees it incurred in
                                    connection with the arbitration and shall share equally in the costs of the arbitration. In arbitration, there is no judge
                                    or jury and there is less discovery and less appellate review than in court. This Agreement shall be governed by and
                                    interpreted in accordance with the laws of the state of Georgia, without regard to the conflicts of law rules thereof.<b>YOU
                                        AND SLICED HEALTH WAIVE THE RIGHT TO PROSECUTE OR PARTICIPATE IN A CLASS ACTION, COLLECTIVE
                                        ACTION, OR OTHER REPRESENTATIVE ACTION. YOU AND SLICED HEALTH MAY SEEK RELIEF ONLY ON BEHALF
                                        OF THEMSELVES AND ONLY TO THE EXTENT NECESSARY TO REMEDY THEIR INDIVIDUAL CLAIMS. THIS CLASS
                                        ACTION WAIVER IS A MATERIAL AND ESSENTIAL PART OF AND CANNOT BE SEVERED FROM THIS ARBITRATION
                                        PROVISION.</b>
                                </li>
                                <li>
                                    <b>Waiver/Severability. </b>
                                    The waiver or failure of either party to exercise any right in any respect provided for herein shall
                                    not be deemed a waiver of any further right hereunder. If any provision of this Agreement is determined to be invalid
                                    or unenforceable under any applicable statute or rule of law, it is to that extent to be deemed omitted, and the balance
                                    of this Agreement shall remain enforceable.
                                </li>
                            </ol>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Disagree
                    </Button>
                    <Button disabled={eulaAcceptPending} onClick={handleAgree} color="primary" autoFocus>
                        {(eulaAcceptPending) ? 'Agree..' : 'Agree'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default EulaPage;