import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import { set } from '../../store/actions/pages';
import { createPayerPlanEditReset, payerPlansLanding, setPayerPlanDependencyDialog, setPayerPlanDialog } from '../../store/actions/payers';
import { dateEquators, StringEquators } from '../../store/constants/pages';
import { selectSessionRole } from '../../store/selectors/session';
import { createDateField, createIconDataField, createStringDataField } from '../../util/format';
import { createFilteredSortedDataSelector } from '../../util/selector';
import PayerPlanManagementComponent from './PayerPlanManagementPage';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createDateField('updatedAt', 'Updated At', { sortable: true, equators: dateEquators }),
        createStringDataField('updatedBy', 'Updated By', { sortable: true, equators: StringEquators }),
        createIconDataField('isMedicare', 'Medicare', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
    ]);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['payer-plan-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state) => state.pages['payer-plan-landing'].filter,
    selectSort,
    (state) => state.payers.payersPlansList,
);


export const PayerPlanManagementPage = connect(
    () => {
        return (state, props) => ({
            data: selectFilteredData(state),
            fields: selectFields(state as never),
            sort: selectSort(state),
            plansListPending: state.payers.payerPlansLanding.pending,
            order: state.pages['payer-plan-landing'].order,
            filter: state.pages['payer-plan-landing'].filter,
            userRole: selectSessionRole(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            payerPlansLanding: payerPlansLanding,
            setFilter: (value) => set('payer-plan-landing', 'filter', value),
            setOrder: (value) => set('payer-plan-landing', 'order', value),
            setSort: (value) => set('payer-plan-landing', 'sort', value),
            createPayerPlanEditReset: createPayerPlanEditReset,
            setPayerPlanDependencyDialog: setPayerPlanDependencyDialog,
            setPayerPlanDialog: setPayerPlanDialog,
    }, dispatch)
    })
)(injectIntl(PayerPlanManagementComponent));