import React, { useState } from 'react';
import { Grid, TablePagination } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataTable, TablePaginationActions, DataFilter, Loadable } from '../../../../common';
import { useEffect } from 'react';
import { renderErrorMessage } from '../../../../util/error';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    content: {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 300
    },
    grid: {
        // margin: theme.spacing(1),
    },
    popover: {
        pointerEvents: 'none',

    },
    paper: {
        pointerEvents: 'auto',
        width: 300,
    },
    chip: {
        '& span': {
            fontSize: "14px"
        }
    },
    switchLabel: {
        maxWidth: '200px',
        marginBottom: 0
    }
}));

export default function FilesGrid(props) {
    const {
        filteredData, fields, order, sort, statisticsGetFilesPending,
        actions: { setSort, setOrder, statisticsGetFiles, setFilter }
    } = props;

    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = useState(0);

    const handleSearchInput = (value) => {
        setFilter(value);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const classes = useStyles();

    useEffect(() => {
        statisticsGetFiles();
    }, [statisticsGetFiles]);


    const handleRefresh = () => {
        statisticsGetFiles();
    }

    return (
        (<Grid container style={{ padding: '10px' }}>
            <Grid item xs={7}>
                <h4 className='ml-2'>Files</h4>
            </Grid>
            <Grid item xs={5}>
                <DataFilter
                    onChange={handleSearchInput}
                    placeholder="Search"
                    cssClass={classes.textField}
                />
            </Grid>
            <Grid item xs={12}>
                <Loadable loading={statisticsGetFilesPending}>
                    <DataTable
                        size="medium"
                        fields={fields}
                        data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                        order={order}
                        onOrder={setOrder}
                        sort={sort}
                        onSort={setSort}
                        showPagination={true}
                        footerPagination={() => (
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                                colSpan={fields.length + 2}
                                count={filteredData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        )}
                        stickyHeader={true}
                        name="files statistics"
                        handleRefresh={handleRefresh}
                    />
                </Loadable>
            </Grid>
        </Grid>)
    );
}