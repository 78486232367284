import IconButton from '@mui/material/IconButton';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useEffect, useState } from "react";


import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import { Divider, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { BackButton } from "../../../../common/BackButton";
import { STATES } from "../../../../store/constants/clients";
import { asDate } from "../../../../util/date";

const useStyles = makeStyles(theme => ({
  portlet: {
    'box-shadow': 'none',
    margin: 8
  },
  error: {
    width: 400
  },
  icon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    float: 'right'
  },
  mask: {
    '-webkit-text-security': 'disc !important'
  },
  color: {
    color: '#48465b'
  },
  button: {
    margin: theme.spacing(1),
  },
  textSection: {
    whiteSpace: 'nowrap',
    width: '30%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  },
  copyIconButton:
  {
    padding: 0,
    marginLeft: '10px'
  }
}));


export default function ClientsViewComponent({ intl, client, clientId, actions: { clientGet } }) {
  const [show, setShow] = useState({});
  const [copyState, setCopyState] = useState({ ftpUrl: false, allowedIp: false, publicKey: false });
  const classes = useStyles();


  useEffect(() => {
    clientGet(clientId);
  }, [clientGet, clientId]);

  const showPassword = (e, idx) => {
    setShow({
      ...show,
      [idx]: true
    });
  }

  const hidePassword = (e, idx) => {
    setShow({
      ...show,
      [idx]: false
    });
  }

  const handleCopy = (name, value) => {
    setCopyState({ ...copyState, [name]: true })
    navigator.clipboard.writeText(value);


    setTimeout(() => {
      setCopyState({ ftpUrl: false, allowedIp: false, publicKey: false });
    }, 3000);

  }

  const showFtpUrl = (usingNewFtp) => {
    return usingNewFtp===true ? 'transfer.slicedhealth.com' : usingNewFtp!==undefined ? 'sftp.slicedhealth.com':''
  }

  return (
    (<Portlet>
      <PortletHeader
        title={client ? client.displayName : ""}
        toolbar={
          <PortletHeaderToolbar>
            <BackButton defaultRoute="/admin/clients?persist=true" />
          </PortletHeaderToolbar>
        }
      />
      <PortletBody className="pt-2">
        <div className={classes.color + " row py-2"} >
          <div className="col-md-5 py-1">
            <span className="font-weight-bold mr-1">Client Id: </span>
            <span >{client ? client.clientId : ""}</span>
          </div>
          <div className="col-md-4 py-1">
            <span className="font-weight-bold mr-1">Client Name: </span>
            <span >{client ? client.name : ""}</span>
          </div>
          <div className="col-md-5 py-1">
            <span className="font-weight-bold mr-1">Schema: </span>
            <span>{client ? client.schema : ""}</span>
          </div>
          <div className="col-md-4 py-1">
            <span className="font-weight-bold mr-1">Contact: </span>
            <span>{client ? client.contact : ""}</span>
          </div>

          <div className="col-md-5 py-1">
            <span className="font-weight-bold mr-1">Go Live: </span>
            <span>{asDate(client?.goLive)}</span>
          </div>
          <div className="col-md-4 py-1">
            <span className="font-weight-bold mr-1">Address: </span>
            <span>{client ? client.address : ""}</span>
          </div>
          <div className="col-md-5 py-1">
            <span className="font-weight-bold mr-1">Max Users: </span>
            <span>{client ? client.maxUsers : ""}</span>
          </div>
          <div className="col-md-4 py-1">
            <span className="font-weight-bold mr-1">Fiscal Year: </span>
            <span>{client ? client.fiscalYear : ""}</span>
          </div>
          <div className="col-md-5 py-1">
            <span className="font-weight-bold mr-1">State: </span>
            <span>{client ? STATES.find(item => item.abbreviation === client?.state)?.displayName ?? '' : ""}</span>
          </div>
          <div className="col-md-4 py-1">
            <span className="font-weight-bold mr-1">Timezone: </span>
            <span>{client ? client.timeZone : ""}</span>
          </div>

            <div className="col-md-4 py-1">
              <span className="font-weight-bold mr-1">FTP URL: </span>
              <span>{showFtpUrl(client?.useNewFTP) }</span>
              <IconButton
                className={classes.copyIconButton}
                onClick={() => handleCopy('ftpUrl',showFtpUrl(client?.useNewFTP))}
                // color={'primary'}

                color={copyState?.ftpUrl ? 'secondary' : 'primary'}
                size="large">
                <CopyIcon />
                {copyState?.ftpUrl && <span className='font-12 ml-2'>Copied</span>}
              </IconButton>
            </div>
        </div>


        {client && client.buckets.length > 0 ?
          <Portlet className={classes.portlet + ' ml-n1'} >
            <PortletHeader
              className="ml-n4"
              title="Buckets"
              showTitle={true}
              toolbar={<></>}
            />



            <PortletBody className="p-0">
              {client && client.buckets.map((bucket, idx) => (
                <React.Fragment key={idx}>
                  <div className={classes.color + " row ml-n1 py-3"}>
                    <div className="col-md-5 py-1">
                      <span className="font-weight-bold mr-1">Bucket Name:  </span>
                      <span >{bucket.name}</span>
                    </div>
                    <div className="col-md-4 py-1">
                      <span className="font-weight-bold mr-1">Parser: </span>
                      <span>{bucket.parser}</span>
                    </div>

                    <div className="col-md-5 py-1">
                      <span className="font-weight-bold mr-1">Ftp User: </span>
                      <span>{bucket.ftpUser.name}</span>
                    </div>

                    {(!client?.useNewFTP || client?.useNewFTP === false || bucket?.ftpUser?.hasPassword) &&
                      <div className="col-md-3 py-1">
                        <span className="font-weight-bold mr-1">Password: </span>
                        <span className={typeof show[idx] !== 'undefined' && show[idx] ? '' : classes.mask}>{bucket.ftpUser.password}</span>
                        {typeof show[idx] !== 'undefined' && show[idx] ?
                          <Tooltip title="Hide Password">
                            <VisibilityOffIcon onClick={(event) => hidePassword(event, idx)} className={classes.icon} />
                          </Tooltip>
                          :
                          <Tooltip title="Show Password">
                            <VisibilityIcon onClick={(event) => showPassword(event, idx)} className={classes.icon} />
                          </Tooltip>
                        }
                      </div>}


                    {client?.useNewFTP && bucket?.ftpUser?.allowedIp &&
                      <div className="col-md-12 py-1">
                        <span className="font-weight-bold mr-1">Allowed IP List: </span>
                        <span>{bucket?.ftpUser?.allowedIp}</span>
                        <IconButton
                          className={classes.copyIconButton}
                          onClick={() => handleCopy('allowedIp', bucket?.ftpUser?.allowedIp)}
                          color={copyState?.allowedIp ? 'secondary' : 'primary'}
                          size="large">
                          <CopyIcon />
                          {copyState?.allowedIp && <span className='font-12 ml-2'>Copied</span>}
                        </IconButton>
                      </div>}

                    {client?.useNewFTP && !bucket?.ftpUser?.hasPassword &&
                      <div className="col-md-12 py-1 d-flex">
                        <span className="font-weight-bold mr-1">Public Key: </span>
                        <span className={classes.textSection}>{bucket?.ftpUser?.publicKey}</span>
                        <IconButton
                          className="p-0"
                          onClick={() => handleCopy('publicKey', bucket?.ftpUser?.publicKey)}
                          color={copyState?.publicKey ? 'secondary' : 'primary'}
                          size="large">
                          <CopyIcon />
                          {copyState?.publicKey && <span className='font-12 ml-2'>Copied</span>}
                        </IconButton>

                      </div>}


                  </div>
                  {(idx !== (client.buckets.length - 1)) && <Divider />}
                </React.Fragment>
              ))}
            </PortletBody>
          </Portlet> :
          <h6 className={classes.color + " mt-4"}>No bucket found</h6>
        }
      </PortletBody>
    </Portlet>)
  );
}

