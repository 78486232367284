import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton, Grid, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, Alert, DialogActions, Button } from '@mui/material';


import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';

import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../partials/content/Portlet";
import { PagedTable } from '../../common';
import classNames from 'classnames';
import { Delete as DeleteIcon,Edit  as EditIcon} from '@mui/icons-material';
import { ROLE_ADMIN, ROLE_PARTNER_ADMIN, ROLE_SUPERADMIN } from '../../store/constants/roles';
import { DependencyDialog } from './DependencyDialog';
import {MasterImport} from './MasterImport';
import {ImportConfirmationDialog} from './ImportConfirmationDialog';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { EditDrgDialog } from './EditDrgDialog';

const DEFAULT_SORT = [{ id: 'drg', direction: 'asc' }];
const SHOW_SEARCH_FILTERS = true;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },

    icon: {
        fontSize: 17,
        marginTop: 1,
    },
}));

export default function HospitalServicesPage({
    intl, hospitalServicesData, hospitalServicesFields, checkboxStatus,
    userRole, history,
    facilities,
    syncPending,
    actions: {
        hospitalServicesLanding,
        searchGetByName,
        searchReset,
        resetPagedTable,
        setList,
        setSelectedItems,
        setHopsitalServicesDependencyDialog,
        setMasterSelectedItems,
        setHopsitalServicesImportConfirmationDialog,
        setHopsitalServicesLookupDialog,
        getFacilities,
        setEditDrgDialog,
        setSyncToShoppables
    }
}) {

    const [refresh, setRefresh] = useState(Promise.resolve());
    const [syncCallbackDialog, setSyncCallbackDialog] = useState(false);

    const classes = useStyles();

    const handleSyncCallbackDialogClose = () => {
        setSyncCallbackDialog(false);
    }

    const handleSyncCallbackDialogOpen = () => {
        setSyncCallbackDialog(true);
    }

    function handleLookupHospitalServices() {
        setHopsitalServicesLookupDialog({open:true});
    }

    function handleBulkDelete() {
        setHopsitalServicesDependencyDialog({ open: true });
    }

    function handleImportHospitalServices() {
        setHopsitalServicesImportConfirmationDialog({open:true});
    }

    function tableLevelActions() {
        return (
            <>
            <Grid item><Tooltip title="Sync to Shoppables">
            <IconButton aria-label="sync" size='small'
                            onClick={()=> setSyncToShoppables(handleSyncCallbackDialogOpen)}
                        >
                    <CloudSyncIcon />
                    </IconButton>
                    </Tooltip></Grid>
                <Grid item>
                    <Tooltip title="Import from Historical data">
                        <IconButton aria-label="upload" size='small'
                            onClick={handleImportHospitalServices}
                        >
                            <i className="fas fa-upload"></i>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Lookup from Master">
                        <IconButton aria-label="lookup" size='small'
                            onClick={handleLookupHospitalServices}
                        >
                            <i className="fas fa-search"></i>
                        </IconButton>
                    </Tooltip>
                </Grid>
                {(userRole === ROLE_SUPERADMIN || userRole === ROLE_PARTNER_ADMIN || userRole === ROLE_ADMIN) &&
                    <Grid item>
                        <Tooltip title="Delete Hospital services">
                            <IconButton aria-label="delete" size='small'
                                onClick={handleBulkDelete}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
                
            </>
        );
    }

    function handleLoad(sort, filter, pagination) {
        hospitalServicesLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        setList([]);
        setRefresh(Promise.resolve());
    }

    useEffect(() => {
        const pageName = {
            name: 'hospitalservices-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name);
            setSelectedItems([]);
        }
    }, [searchGetByName]);

    useEffect(() => {
        getFacilities()
    }, [getFacilities]);

    const hospitalServicesActions = (data) => (

        <IconButton
            color="primary"
            aria-label="Edit"
            onClick={() => {setEditDrgDialog({open:true,data})}}
            size="large"
        >
            <Tooltip title="Edit">
                <EditIcon />
            </Tooltip>
        </IconButton>
    );
    
    return (
        <>
            <Helmet title="Hospital Services" />
            <Portlet>
                <PortletHeader
                    title="Hospital Services"
                    handleRefresh={handleRefresh}
                    showSearchFilters={SHOW_SEARCH_FILTERS}
                    showSearchFiltersDropdown={false}
                    name="hospitalservices-landing"
                />
                <PortletBody>
                    <div className="row">
                        <div className="col-md-12">
                            <PagedTable
                                name="hospitalservices-landing"
                                fields={hospitalServicesFields}
                                data={hospitalServicesData}
                                onLoad={handleLoad}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                tableLevelActions={tableLevelActions}
                                hasCheckbox={true}
                                showSelectAll={true}
                                tableLevelLoading={syncPending}
                                title="Hospital Service"
                                criteriasSaveEnabled={true}
                                showSearchFilters={SHOW_SEARCH_FILTERS}
                                hasActions={true}
                                renderActionColumn={hospitalServicesActions}
                            />
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
            <DependencyDialog handleRefresh={handleRefresh} />
            <ImportConfirmationDialog handleRefresh={handleRefresh} />
            <MasterImport handleRefresh={handleRefresh} facilities={facilities?.filter((item)=>(item?.active && item))}/>
            <EditDrgDialog setEditDrgDialog={setEditDrgDialog} facilities={facilities} handleRefresh={handleRefresh}/>
            <Dialog open={syncCallbackDialog} onClose={handleSyncCallbackDialogClose}>
                <DialogTitle>
                    Sync to Shoppables
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="success">The hospital services successfully sync to shoppables.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSyncCallbackDialogClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}