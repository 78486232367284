import React, { useEffect } from 'react'
import { Loadable } from '../../../common';
import { Formik } from 'formik';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    Switch,
    FormControlLabel,
    Autocomplete,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ContractsSearchComponent } from '../../contractsPage/ContractsSearchComponent';
import { CONTRACT_STATE_FINALIZED } from '../../../store/constants/contract';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: '100%',
    },
    formControl: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    switch: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    autoComplete: {
        width: '100%'
    },
    selectionIcon:{
        width:"100%",
        margin:'0 !important',
        padding:'0 !important' 
    },
    selectionIconButton:{
        width:"100%",
        '&:hover': {
            background: 'none !important',
        }
    },
    selectionAutocomplete:{
        cursor:"pointer"
    },
    
}));

function PayerIdMappingEditPage({ open, handleRefresh, mode, intl, initialValues, createPending, 
    editPending, tinyContracts, selectedPayerIds, isBulk, 
    actions: { createPayerIdMapping, editPayerIdMapping, createEditReset, 
        setEditDialog, editBulkPayerIdMapping, setSelectedItems } } ) {

    const classes = useStyles();

    useEffect(() => {
        return function cleanup() {
            createEditReset();
        };
    }, [createEditReset]);

    function handleCallback(reload) {
        setSelectedItems([]);
        handleClose(reload);
    }

    const handleClose = (reload) => {
        setEditDialog(null);
        createEditReset();
        if(reload) {
            handleRefresh();
        }
    }

    return (<>
        {open && (
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{mode === 'edit' ? (isBulk ? `Edit Payer ID Mapping (${selectedPayerIds.length})` : 'Edit Payer ID Mapping') : 'Add Payer ID Mapping'}</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={createPending || editPending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors: any = {};
                            
                            if (!values.payerName && !isBulk) {
                                errors.payerName = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (!values.payerId && !isBulk) {
                                errors.payerId = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            let shouldReloadGrid = true;
                            let payload = {
                                payerName: values.payerName,
                                payerId: values.payerId,
                                contractId: values.contractId?.id ?? null,
                                active: values.active,
                                id: undefined
                            }
                            if (mode === 'create') {
                                createPayerIdMapping(payload, handleCallback, shouldReloadGrid);
                            }
                            else {
                                if (isBulk) {
                                    editBulkPayerIdMapping({
                                        active: values.active,
                                        contractId: values.contractId?.id ?? null,
                                        ids: selectedPayerIds
                                    }, handleCallback, shouldReloadGrid);
                                } else {
                                    editPayerIdMapping({
                                        ...payload,
                                        id: values.id,
                                    }, handleCallback, shouldReloadGrid);
                                }
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <div>
                                    <Grid container>
                                    {isBulk && (selectedPayerIds.length < 1) ? (
                                            <Grid container justifyContent="flex-end">
                                                <Grid item xs={12}>
                                                    <Typography>No payer Ids selected. Please select atleast one to update.</Typography>
                                                </Grid>
                                            </Grid>
                                        ) :
                                        <>
                                        {!isBulk && (<>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                label="Payer Name"
                                                name="payerName"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.payerName}
                                                helperText={touched.payerName && errors.payerName}
                                                error={Boolean(touched.payerName && errors.payerName)}
                                                inputProps={{ maxLength: 250 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                label="Payer ID"
                                                name="payerId"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.payerId}
                                                helperText={touched.payerId && errors.payerId}
                                                error={Boolean(touched.payerId && errors.payerId)}
                                                inputProps={{ maxLength: 5 }}
                                            />
                                        </Grid>
                                        </>)}
                                        <Grid item xs={12} >
                                                                    
                                            <ContractsSearchComponent handleSetContract={(value) => setFieldValue("contractId", value)} chipBoxClass={classes.selectionIcon} iconButtonClass={classes.selectionIconButton} icon={<Autocomplete

                                                className={`${classes.autoComplete} ${classes.selectionAutocomplete}`}
                                                onKeyDown={(e) => { e.preventDefault(); }}
                                                open={false}
                                                value={values.contractId}
                                                disableClearable={false}
                                                onChange={(obj, updatedContracts, event) => {
                                                    if (event === "clear") {
                                                        setFieldValue("contractId", null)                                                   
                                                        }}}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                options={tinyContracts}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        name={`contractId`}
                                                        label="Contract"
                                                        error={Boolean(touched.contractId && errors.contractId)}
                                                        helperText={touched.contractId && errors.contractId}
                                                        margin="normal" />
                                                }
                                            />} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                control={
                                                <Switch
                                                    name="active"
                                                    color="primary"
                                                    className={classes.switch}
                                                    checked={values.active}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.active}
                                                />
                                                }
                                                label="Active"
                                            />
                                        </Grid>
                                        </>
                                    }
                                    </Grid>
                                    <hr />
                                    <Grid container className='justify-content-end'>
                                        <Button onClick={handleClose} className={classes.switch} variant="contained">
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className={classes.button}
                                            disabled={createPending || editPending || (isBulk && selectedPayerIds.length < 1)}
                                        >
                                            {(createPending || editPending) ? 'Saving Changes' : 'Save'}
                                        </Button>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
        )}
    </>);
}

export default PayerIdMappingEditPage
