import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Topbar from "./Topbar";
import HMenu from "./HMenu/HMenu";
import AnimateLoading from "../../../app/partials/layout/AnimateLoading";
import KTHeader from "../../_assets/js/header";
import * as builder from "../../ducks/builder";
import { selectSelectedClientName } from "../../../app/store/selectors/clients";
import { selectIsSuperadminOrPartner } from '../../../app/store/selectors/session';
import { Chip } from "@mui/material";
import { ReleaseSnackbar } from "../../../app/common/ReleaseSnackbar";
class Header extends React.Component {
  headerCommonRef = React.createRef();

  componentDidMount() {
    let options = {};
    if (
      this.headerCommonRef.current.getAttribute("data-ktheader-minimize") ===
      "1"
    ) {
      options["minimize.desktop.on"] = "kt-header--minimize";
      options["offset.desktop"] = 130;
    }

    // eslint-disable-next-line no-undef
    new KTHeader(this.headerCommonRef.current, options);
  }

  render() {
    const { htmlClassService, menuHeaderDisplay, selectedClient, isSuperAdmin, release } = this.props;
    const headerAttributes = htmlClassService.attributes.header;
    return (
      <div
        className={`kt-header kt-grid__item ${this.props.headerClasses}`}
        id="kt_header"
        ref={this.headerCommonRef}
        {...headerAttributes}
      >
        <AnimateLoading />

        <div className="kt-header__topbar-item kt-header__topbar-item--user d-flex align-items-center">
          <div className="kt-header__topbar-user">
            <span className="kt-header__topbar-username">
              <Chip className="bg-transparent text-dark" style={{ fontSize: "13px", fontWeight: 500 }} label={selectedClient} />
            </span>
          </div>
        </div>

        {/* <!-- begin: Header Menu --> */}
        {menuHeaderDisplay && <HMenu htmlClassService={htmlClassService} />}
        {/* <!-- end: Header Menu --> */}
        {/* <!-- begin:: Header Topbar --> */}
        {/* <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden --> */}

        {!menuHeaderDisplay && <div />}

        <ReleaseSnackbar />

        <Topbar htmlClassService={htmlClassService} isSuperAdmin={isSuperAdmin} selectedClient={selectedClient} />

        {/* <!-- end:: Header Topbar --> */}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerClasses: builder.selectors.getClasses(store, {
    path: "header",
    toString: true
  }),
  menuHeaderDisplay: objectPath.get(
    store.builder.layoutConfig,
    "header.menu.self.display"
  ),
  fluid: objectPath.get(store.builder.layoutConfig, "header.self.width") === "fluid",
  selectedClient: selectSelectedClientName(store),
  isSuperAdmin: selectIsSuperadminOrPartner(store),
});

export default connect(mapStateToProps)(Header);
