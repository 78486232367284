import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_PUT,
    METHOD_DELETE
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const gethospitalServices = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/hospitalservices`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const updateHospitalServices = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/hospitalservice`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const syncToShoppable = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/hospitalservice/shoppables`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const gethospitalServicesFromMaster = (options) => {
    options = {
                page: {
                    currentPage: 1,
                    totalPages: 1,
                    recordsPerPage: 1000,
                    totalRecords: 1000
                },
                filter: null,
                sort: [
                    {
                        id: "drg",
                        direction: "asc"
                    }
                ]
            };
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/drgs`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const deleteHospitalServices = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/drgs`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const importHospitalServices = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/drgs/add`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const importHospitalServicesFromRedshift = () => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/drg/import`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        //body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

export {
    gethospitalServices,
    gethospitalServicesFromMaster,
    deleteHospitalServices,
    importHospitalServices,
    importHospitalServicesFromRedshift,
    updateHospitalServices,
    syncToShoppable
};