import {
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import Helmet from 'react-helmet';
import { Loadable, TableLevelAction } from '../../common';

import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import AddIcon from "@mui/icons-material/AddCircle";
import ReorderIcon from "@mui/icons-material/DragIndicator";
import PageviewIcon from '@mui/icons-material/Pageview';
import HeaderColumn from "../../common/dataTable/HeaderColumn";
import { RowCheckbox } from "../../common/dataTable/rowCheckbox";
import {
    Portlet,
    PortletBody,
    PortletHeader
} from "../../partials/content/Portlet";
import { ROLE_ADMIN, ROLE_PARTNER_ADMIN, ROLE_SUPERADMIN } from "../../store/constants/roles";
import { asDate } from '../../util/date';
import { DependencyDialog } from "./DependencyDialog";
import { DirectoryDependencyDialog } from "./DirectoryDependencyDialog";
import { DirectoryEditDialog } from "./DirectoryEditDialog";
import { FeeScheduleBreadCrumb } from "./FeeScheduleBreadCrumb";
import { FeeScheduleDraggable } from "./FeeScheduleDraggable";
import { FeeScheduleDroppable } from "./FeeScheduleDroppable";
import { FeeScheduleSelection } from "./feeScheduleSelectionComponent";
import { MoveItemsDialog } from "./moveItemsDialog";

const useStyles = makeStyles(theme => {
    return {
        root: {
            display: 'flex',
            overflowX: 'auto',
            flexDirection: 'column',
        },
        container: {
            flexGrow: 1,
        },
        textField: {
            width: 400,
        },
        portlet: {
            display: 'flex',
            flexGrow: 1,
        },
        tr: {
            "& th": {
                padding: '6px 24px 6px 16px',
                top: "0px",
                '@media (max-width: 1440px)': {
                    padding: "6px 5px 6px 5px"
                },
                '@media (max-width: 1280px)': {
                    padding: "3px 5px 3px 5px"
                },
                '@media (max-width: 1024px)': {
                    top: '0px',
                    padding: "6px 5px 6px 5px"
                },
                '@media (max-width: 830px)': {
                    top: '0px!important'
                },
                '@media screen and (max-width: 1279px) and (min-width: 1025px)': {
                    top: '0px!important'
                },
            },
        },
        trBody: {
            "& td": {
                '@media (max-width: 1440px)': {
                    padding: "6px 5px 6px 5px"
                },
                '@media (max-width: 1280px)': {
                    padding: "3px 5px 3px 5px"
                },
                '@media (max-width: 1024px)': {
                    padding: "6px 5px 6px 5px"
                }
            },
        },
        folderDiv: {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        searchIcon:{
            position:'relative',
            bottom:'8px'
        }
    }
});

export default function FeeSchedulePageV2({ intl, history, directoryId, fields, feeSchedules, directories, order, sort, getListPending, deletePending, userRole, movePending,
    actions: { setSort, setOrder, getFeeScheduleList, resetFeeSchedules, setFeeScheduleDepdendencyDialog, setFeeScheduleEditDialog,
        setDirectoryEditDialog, setMoveDialog, resetSelected, setFeeScheduleDirectoryDependencyDialog, getHierarchy }
}) {
    const classes = useStyles();

    const { id: columnOrder, direction } = (sort && sort.length > 0 && sort[0]) || {};

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        getHierarchy();
    }, [getHierarchy]);

    useEffect(() => {
        getFeeScheduleList(directoryId);
        return () => {
            resetFeeSchedules();
        }
    }, [directoryId, getFeeScheduleList, resetFeeSchedules]);

    const handleRedirectToDirectory = (directoryId) => {
        resetSelected();
        history.push(`/cms/feeschedules/${directoryId}`);
    }

    const handleView = (feeSchedule) => {
        history.push(`/cms/feeschedules/view/${feeSchedule.id}/null/${directoryId}`);
    }
    const handleEdit = (feeSchedule) => {
        history.push(`/cms/feeschedules/edit/${directoryId}/${feeSchedule.id || 'new'}`);
    }

    function handleRefresh() {
        getFeeScheduleList(directoryId);
        getHierarchy();
    }

    function handleFeeScheduleDeleteDependency({ id, name }) {
        setFeeScheduleDepdendencyDialog({ open: true, feeScheduleId: id, feeScheduleName: name })
    }

    const handleOpenAddMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseAddMenu = () => {
        setAnchorEl(null);
    };

    const handleAddFolder = () => {
        setDirectoryEditDialog({ open: true, mode: 'create', parentId: directoryId });
        handleCloseAddMenu();
    }

    const handleEditFolder = ({ id, name }) => {
        setDirectoryEditDialog({ open: true, mode: 'edit', parentId: directoryId, id, name });
    }

    const handleFolderDeleteDependencies = ({ id, name }) => {
        setFeeScheduleDirectoryDependencyDialog({ open: true, directoryId: id, directoryName: name })
    }

    // const handleAddItem = () => {
    //     setFeeScheduleEditDialog({ open: true, mode: 'create', type: 'Item', id: '', directoryId });
    //     handleCloseAddMenu();
    // }

    const handleMoveFiles = () => {
        setMoveDialog({ open: true });
    }
    const handleSearchClick = (rowData,dId=undefined) => {
        history.push(`/cms/feeschedules/view/${rowData?.id}/null/${dId || directoryId}`);
    }

    const tableLevelActions = () => {
        return <>
            <Grid item>
                <IconButton
                    id="add-fee-button"
                    aria-controls={open ? 'add-fee-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleOpenAddMenu}
                    className="p-1"
                    size="large">
                    <Tooltip title="Add">
                        <AddIcon />
                    </Tooltip>
                </IconButton>
                <Menu
                    id="add-fee-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseAddMenu}
                    MenuListProps={{
                        'aria-labelledby': 'add-fee-button',
                    }}
                    PaperProps={{
                        style: {
                            marginTop: 40,
                        }
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                    <MenuItem onClick={handleEdit}>Fee Schedule</MenuItem>
                    <MenuItem onClick={handleAddFolder}>Folder</MenuItem>
                </Menu>
            </Grid>
            <Grid item>
                <IconButton className='p-1' aria-label="Edit" onClick={handleMoveFiles} size="large">
                    <Tooltip title="Move Files">
                        <i className="fas fa-file-import"></i>
                    </Tooltip>
                </IconButton>
            </Grid>
            <Grid item className={`${classes.searchIcon}`}>


                <FeeScheduleSelection handleSetFeeSchedule={handleSearchClick}/>
            </Grid>
        </>;
    }

    const FeeScheduleActions = ({ rowData }) => {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleView(rowData)}
                    size="large">
                    <Tooltip title="View">
                        <PageviewIcon />
                    </Tooltip>
                </IconButton>
                <IconButton
                    color="primary"
                    className='p-1'
                    aria-label="Edit"
                    onClick={() => handleEdit(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
                {(userRole === ROLE_SUPERADMIN || userRole === ROLE_ADMIN || userRole === ROLE_PARTNER_ADMIN) &&
                    <IconButton
                        onClick={() => handleFeeScheduleDeleteDependency(rowData)}
                        color={"primary"}
                        className='p-1'
                        aria-label="Delete"
                        size="large">
                        <Tooltip title={"Delete Fee Schedule"} >
                            <DeleteIcon />
                        </Tooltip>
                    </IconButton>}
            </div>
        );
    }

    const handleSort = ({ id, direction, fieldType }) => {
        if (!setSort) {
            return;
        }

        const sorts = [...sort];
        const updatedSort = { id, fieldType, direction };
        const oldSort = sorts.find((sort) => {
            return sort.id === id;
        });

        // sort exists, toggle sort
        if (oldSort && oldSort.id === id) {
            const index = sorts.indexOf(oldSort);
            updatedSort.direction = oldSort.direction === 'asc' ? 'desc' : 'asc';
            sorts[index] = updatedSort;
        }
        // new sort
        else {
            updatedSort.direction = 'desc';
            sorts.splice(0, sorts.length);
            sorts.push(updatedSort);
        }
        setSort(sorts);
    }


    return (<>
        <FeeScheduleBreadCrumb directoryId={directoryId} handleRefresh={handleRefresh} />
        <Loadable loading={getListPending || deletePending || movePending}>
            <Helmet title="Fee Schedules" />
            <Portlet className={classes.root}>
                <PortletHeader
                    title="Fee Schedules"
                    toolbar={<></>}
                />
                <PortletBody>
                    {/* <Grid container justifyContent="center">
                        <Grid item xs={3}>
                            <FeeScheduleSelection handleSetFeeSchedule={() => { }} />
                        </Grid>
                    </Grid> */}
                    <Grid spacing={1} container>
                        <Grid item xs={12}>
                            <TableLevelAction
                                handleRefresh={handleRefresh}
                                actions={tableLevelActions}
                                name={''}
                                loading={false}
                                actionLevelGrid={null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Table stickyHeader={true}>
                                <TableHead>
                                    <TableRow className={`${classes.tr} fee-schedule-thr`}>
                                        <TableCell className="drag-cell"></TableCell>
                                        {fields.map((field, index) => (
                                            !field?.options.hideFromGrid && <HeaderColumn
                                                key={`column-${index}`}
                                                field={field}
                                                onSort={handleSort}
                                                direction={direction}
                                                columnOrder={columnOrder}
                                            />

                                        ))}
                                        <HeaderColumn field={{ label: 'Actions' }} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {directories?.length === 0 && feeSchedules?.length === 0 &&
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell colSpan={5}>No records found!</TableCell>
                                        </TableRow>
                                    }
                                    {directories?.map(item => {
                                        return (
                                            <FeeScheduleDroppable item={item} classes={classes} handleRefresh={handleRefresh}>
                                                <TableCell id={`folder-${item.id}`} className="drag-cell">
                                                    <IconButton
                                                        id={`folder-${item.id}`}
                                                        className='fee-schedule-rerder-button'
                                                        size="large"><ReorderIcon />
                                                    </IconButton>
                                                    <RowCheckbox rowDataId={`folder-${item.id}`} pageName={'feeSchedules-landing'} customClasses='p-1' />
                                                </TableCell >
                                                <TableCell id={`folder-${item.id}`} colSpan={4}>
                                                    <div id={`folder-${item.id}`} className={classes.folderDiv} onClick={() => handleRedirectToDirectory(item.id)}>
                                                        <IconButton id={`folder-${item.id}`} className="p-1 mr-2" size="large">
                                                            <i id={`folder-${item.id}`} style={{ color: '#FFCA35' }} className="fas fa-folder"></i>
                                                        </IconButton>
                                                        {item.name}
                                                    </div>
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className="pl-3">
                                                    <IconButton color="primary" className='p-1 invisible' aria-label="Edit" size="large">
                                                        <Tooltip title="View">
                                                            <PageviewIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                        className='p-1'
                                                        aria-label="Edit"
                                                        onClick={() => handleEditFolder(item)}
                                                        size="large">
                                                        <Tooltip title="Edit">
                                                            <EditIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                    {(userRole === ROLE_SUPERADMIN || userRole === ROLE_ADMIN || userRole === ROLE_PARTNER_ADMIN) &&
                                                        <IconButton
                                                            onClick={() => handleFolderDeleteDependencies(item)}
                                                            color={"primary"}
                                                            className='p-1'
                                                            aria-label="Delete"
                                                            size="large">
                                                            <Tooltip title={"Delete Folder"} >
                                                                <DeleteIcon />
                                                            </Tooltip>
                                                        </IconButton>}
                                                </TableCell>
                                            </FeeScheduleDroppable>
                                        );
                                    })}
                                    {feeSchedules?.map(item => {
                                        return (
                                            <FeeScheduleDraggable classes={classes} item={item}>
                                                <TableCell className="drag-cell">
                                                    <div className="d-flex">
                                                        <IconButton className='fee-schedule-rerder-button' size="large">
                                                            <ReorderIcon />
                                                        </IconButton>
                                                        <RowCheckbox rowDataId={`feeschedule-${item.id}`} pageName={'feeSchedules-landing'} customClasses='p-1' />
                                                    </div>
                                                </TableCell>
                                                <TableCell className="pl-4">{item.name}</TableCell>
                                                <TableCell>{item.type}</TableCell>
                                                <TableCell>{asDate(item.effectiveDate) || 'N/A'}</TableCell>
                                                <TableCell>{asDate(item.expirationDate) || 'N/A'}</TableCell>
                                                <TableCell>{!item?.hasRates ? 
                                                    <Tooltip title={'It has no fee schedule rates'}>
                                                        <i className={`fas fa-exclamation-circle text-danger ml-2 font-20`}></i>
                                                    </Tooltip> : item.hasDates ? 
                                                    <Tooltip title={'It has fee schedule rates with expiration dates'}>
                                                        <i className={`fas fa-exclamation-circle text-warning ml-2 font-20`}></i>
                                                    </Tooltip> : ''}</TableCell>
                                                <TableCell><FeeScheduleActions rowData={item} /> </TableCell>
                                            </FeeScheduleDraggable>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Grid>

                    </Grid>
                </PortletBody>
            </Portlet>
        </Loadable>
        <DependencyDialog handleRefresh={handleRefresh} />
        <DirectoryDependencyDialog handleRefresh={handleRefresh} />
        {/* <FeeScheduleEditDialog handleRefresh={handleRefresh} /> */}
        <DirectoryEditDialog handleRefresh={handleRefresh} />
        <MoveItemsDialog handleRefresh={handleRefresh} />
    </>);
}