import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import Alert from '@mui/material/Alert';
import { ProcessedClaimsDialog } from './ProcessedClaimsDialog';
import { useStatePtr } from '@pure-ptr/react';

export default function ProcessClaimDialog({ openPtr, intl, claimPageRecord, selectedItemsPtr, claimsData, filter, claimprocessError, significanceFlag,
    claimProcessPending, claimBulkProcessPending, handleProcessClaimCallback, claimBulkOnDemandPending,
    actions: { claimAnalysisProcess, claimAnalysisBulkProcess, processBulkOnDemand } }) {

    const [processClaimCallbackDialog, setProcessClaimCallbackDialog] = useState(false);

    const openProcessedClaimsDialogPtr = useStatePtr(false)
        .onChange( x => {
            if( !x ) {
                openPtr.set(false);
            }
        });

    const handleProcessClaimCallbackDialogClose = () => {
        setProcessClaimCallbackDialog(false);
    }

    const handleProcessClaimSuccessCallback = () => {
        selectedItemsPtr.set([]);
        setProcessClaimCallbackDialog(true);
        handleProcessClaimCallback();
    }

    const handleBulkOndemandCallback = () => {
        selectedItemsPtr.set([]);
        handleProcessClaimCallback();
        openProcessedClaimsDialogPtr.set(true);
    }

    const handleErrorCallback = () => {
        openPtr.set(false);
    }

    const selectedItems = selectedItemsPtr.value;

    const handleProcessClaimConfirmation = () => {
        if (selectedItems.length > 0) {
            if (claimsData.length === selectedItems.length && selectedItems.length > 5) {
               
                let operandsWithoutBoth = filter.operands.filter(obj => 
                    !obj.filters.some(filter => filter.value === "both")
                );
                
                operandsWithoutBoth={operator:filter.operator,operands:operandsWithoutBoth}
                const payload = {
                    filter:operandsWithoutBoth,
                }
                
                claimAnalysisBulkProcess(payload, significanceFlag, handleProcessClaimSuccessCallback, handleErrorCallback);

            } else {
                const claims = claimsData.filter(claim => selectedItems.some(y => y == claim.id));
                const payload = {
                    claimMetadata: claims.map((claim) => {
                        return {
                            claimNumber: claim.claimnumber,
                            contractId: claim.contractid,
                        }
                    }),
                };
                
                if (claims.length <= 5) {
                    processBulkOnDemand(payload, handleBulkOndemandCallback, handleErrorCallback)
                } else {
                    claimAnalysisProcess(payload, handleProcessClaimSuccessCallback, handleErrorCallback);
                }
            }
        }
    }

    return (
        (<div>
            <Dialog open={openPtr.value}>
                <DialogTitle>
                    Process Claims
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedItems?.length > 0 ?
                            `Are you sure you want to process ${claimsData.length === selectedItems.length ? `all (${claimPageRecord?.totalRecords})` : selectedItems.length} claims?` :
                            'No claims selected, please select atleast one claim to process'
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => openPtr.set( false ) }>
                        {selectedItems?.length > 0 ? 'Cancel' : 'Close'}
                    </Button>
                    {selectedItems?.length > 0 && (
                        <Button
                            onClick={handleProcessClaimConfirmation}
                            disabled={claimProcessPending || claimBulkProcessPending || claimBulkOnDemandPending}
                            color="primary">
                            {(claimProcessPending || claimBulkProcessPending || claimBulkOnDemandPending) ? 'Processing...' : 'Process Claims'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={processClaimCallbackDialog} onClose={handleProcessClaimCallbackDialogClose}>
                <DialogTitle>
                    Process Claims
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="success">The request is submitted and progress can be monitored on execution logs page.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleProcessClaimCallbackDialogClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <ProcessedClaimsDialog openPtr={openProcessedClaimsDialogPtr}/>
        </div>)
    );
}

