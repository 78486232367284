import { Formik, useFormikContext } from "formik";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Helmet from 'react-helmet';
import { Loadable, PopoverLink } from '../../common';
import { ImageUploader } from '../../common/ImageUploader';
import { RichTextEditor } from '../../common/RichTextEditor';


import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Switch,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ZoomInOutlined } from "@mui/icons-material";
import html2canvas from "html2canvas";
import { PreviewContent, PreviewContent as PreviewContentCopy } from "./PreviewContent";
import { SignatureLabelsArray } from "./SignatureLabelsArray";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
    },
    textField: {
        margin: theme.spacing(1),
        width: '80%'
    },
    label: {
        margin: theme.spacing(1),
    },
    switch: {
        margin: theme.spacing(1),
    },
    cardTop: {
        marginTop: theme.spacing(1),
    },
    textArea: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '80%',
    },
    checkbox: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(0),
    },
    helpClass: {
        fontSize: '13px'
    },
    disclaimerCard: {
        paddingTop: '0px'
    },
    blurryPreview: {
        width: '200px',
        height: '150px',
        position: 'relative',
        cursor: 'pointer',
        border: '3px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        margin: '10px 2px',
        display: 'grid',
        alignItems: 'center'
    },
    maximizeIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        cursor: 'pointer',
        transform: 'translate(-50%, -62%)',
        opacity: '1',
        // transition: 'opacity 0.3s',
        "&:hover": {
                opacity: '1',
            },
    },
    previewImg: {
        width: '190px'
    },
    previewRefDiv: {
        position : 'absolute', top: '-10000px'
    }
    
}));

export default function ClientBrandingPage(props) {

    const {
        initialValues, intl, mode, clientId,  
        getBrandingPending, createBrandingPending, updateBrandingPending, brand, 
        actions: { getBranding, createBranding, updateBranding, getBrand }
    } = props;

    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

    useEffect(() => {
        getBranding();
    }, [getBranding]);

    useEffect(() => {
        getBrand();
    }, [getBrand]);

    const [file, setFile] = useState(null);
    const [thumbnailSrc, setThumbnailSrc] = useState(null);
    const previewRef = useRef(null);
    const classes = useStyles();

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    }

    function handlePreviewDialogOpen() {
        setOpenPreviewDialog(true);
    }

    function handlePreviewDialogClose() {
        setOpenPreviewDialog(false);
    }

    function hoverColumn(text) {
        return (
            <PopoverLink
                columnData={<i className={`${classes.helpIcon} fas fa-question-circle text-info`}></i>}
                hoverData={[
                    {
                        data: text
                    }
                ]}
            />
        );
    }

    const generateThumbnail = useCallback(debounce(() => {
        
        if(previewRef.current === null) { return; }

        html2canvas(previewRef.current, {useCORS: true, scale: 0.5})
            .then((canvas) => {
                
                const dataUrl = canvas.toDataURL('image/png');
                setThumbnailSrc(dataUrl);
        })
        .catch((err) => {
            console.log('Error capturing thumbnail:', err);
        });

    }, 1000), []);

    const FormObserver = ({ onValuesChange }) => {
        const { values } = useFormikContext();

        useEffect(() => {
            if(onValuesChange) {
                onValuesChange(values);
            }
        }, [values, onValuesChange]);

        return null;

    }

    return (<>
        <Loadable loading={getBrandingPending || createBrandingPending || updateBrandingPending }>
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validate={values => {
            const errors = {};

                return errors;
            }}
            onSubmit={(values) => {
                
                let payload = {
                    ClientId: clientId,
                    logoPath: values.logoPath,
                    usePricerLogo: values.usePricerLogo ?? false,
                    signatureLabelList: values.signatureLabels.filter(x=> x !== '') ?? [],
                    disclaimer: values.disclaimer,
                }
                
                const formData = new FormData();
                
                if(payload.usePricerLogo) {
                    formData.append('File', null);
                }
                else if (file) {
                    formData.append('File', file);
                    payload.logoPath = '';
                }
                formData.append('Payload', JSON.stringify(payload));

                if (mode === 'create' || !values.id) {
                    createBranding(formData);
                }
                else {
                    updateBranding(formData);
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setValues,
                }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                    <Helmet title="Branding Configurations" />
                    <Portlet>
                    <PortletHeader
                        title={'Branding'}
                        toolbar={
                        <PortletHeaderToolbar>
                            <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={createBrandingPending || updateBrandingPending}
                            >
                            Save
                            </Button>
                        </PortletHeaderToolbar>
                        }
                    />
                    <PortletBody>
                        <div className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8} lg={8}>
                                <Card variant="outlined">
                                    <CardHeader title={<div>Branding Logo <span className={classes.helpClass}> {hoverColumn('This branding logo will be used in estimation history page.')} </span> </div>} />
                                    <CardContent>
                                        <ImageUploader 
                                            logoPath={values.logoPath}
                                            resizedImage={file}
                                            setResizedImage={setFile}
                                            disabled={values.usePricerLogo}
                                            pricerBucket={values.pricerBucket}
                                            brandLogoPath={brand?.logoPath ?? ''}
                                        />
                                        <FormControlLabel
                                            control={
                                            <Switch
                                                name="usePricerLogo"
                                                checked={values.usePricerLogo}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={classes.switch}
                                                value={values.usePricerLogo}
                                            />
                                            }
                                            label={<div>Use Pricer Logo <span className={classes.helpClass}> {hoverColumn('Import the pricer logo from pricer tool.')} </span> </div>} 
                                        />
                                    </CardContent>
                                </Card>
                                <br />
                                <Card variant="outlined">
                                    <CardHeader title={<div>Disclaimer <span className={classes.helpClass}> {hoverColumn('This disclaimer text will be used in estimation history page.')} </span> </div>} />
                                    <CardContent className={classes.disclaimerCard}>
                                        <RichTextEditor value={values.disclaimer} fieldName='disclaimer' setFieldValue={setFieldValue} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={8} lg={4}>
                                <Card variant="outlined" >
                                    <CardHeader title={<div>Signature Labels <span className={classes.helpClass}> {hoverColumn('These signature labels will be used in estimation history page.')} </span> </div>}
                                    />
                                    <CardContent>
                                        <SignatureLabelsArray
                                            values={values}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            touched={touched}
                                            errors={errors}
                                            setFieldValue={setFieldValue}
                                        />
                                    </CardContent>
                                </Card>
                                <Grid className={classes.blurryPreview} onClick={handlePreviewDialogOpen}>
                                    
                                    {/* <Skeleton className={`${classes.marginAuto} my-2`} variant="circular" width={40} height={40} />
                                    <Skeleton className={`mx-2 my-2`} variant="rounded" width={180} height={60} />
                                    <Skeleton className={`${classes.marginAuto} my-2`} variant="rounded" width={80} height={10} />
                                    <Skeleton className={`${classes.marginAuto} my-2`} variant="rounded" width={80} height={10} />
                                    <Skeleton className={`${classes.marginAuto} my-2`} variant="rounded" width={80} height={10} />
                                    */}

                                    <img src={thumbnailSrc} alt='Preview' className={classes.previewImg} />
                                    <IconButton className={classes.maximizeIcon} size="large">
                                        <ZoomInOutlined fontSize='large' /> Preview
                                    </IconButton>
                                </Grid>
                                <FormObserver onValuesChange={generateThumbnail} />
                            </Grid>
                        </Grid>
                        </div>
                    </PortletBody>
                    </Portlet>
                    <Dialog maxWidth='md' fullWidth open={openPreviewDialog} onClose={handlePreviewDialogClose}>
                        <DialogTitle className='py-3'>
                            Preview
                        </DialogTitle>
                        <DialogContent dividers >
                            <PreviewContent values={values} file={file} brand={brand} />
                        </DialogContent>
                        <DialogActions className='p-4'>
                            <Button variant="contained" onClick={handlePreviewDialogClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div ref={previewRef} className={classes.previewRefDiv}>
                        <PreviewContentCopy values={values} file={file} brand={brand} />
                    </div>
                </form>
            )}
        </Formik>
        </Loadable>
    </>);

}
