import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Loadable, PagedTable } from '../../../../../common';
import { ClaimSearch } from '../ClaimSearch';
import { MODEL_CLAIM_SELECTION_TYPE_ADVANCE } from '../../../../../store/constants/modeling';
import { EstimateForm } from './EstimateForm';

const DEFAULT_SORT = [{ id: 'claimnumber', direction: 'asc' }];

function ClaimSelection(props) {
    const {
        fields, tinyContracts, mode, model, modelId,
        analyzeEstimatePending, claimSelectionData, estimatePending, actions:
        { searchClaimsForModeling, modelClaimSelectionLanding, getContractsTiny, setSelected } } = props;

    const [isFirstCallOfClaimSelection, setFirstCallOfClaimSelection] = useState(true);
    const [displayTableForClaimSelection, setDisplayTableForClaimSelection] = useState(false);
    const [initSearcharameters, setInitSearchParameters] = useState(null);

    useEffect(() => {
        getContractsTiny();
    }, [getContractsTiny]);

    useEffect(() => {
        return () => {
            setSelected([]);
        }
    }, [setSelected]);


    const handleClaimSelectionLoad = (sort, filter, pagination) => {
        if (!isFirstCallOfClaimSelection) {
            modelClaimSelectionLanding(pagination, sort, filter);
        }
        setFirstCallOfClaimSelection(false);

    }

    const handleInitSearch = (values) => {
        let payload = {
            searchType: values.claimSelectionType,

        }
        if (values.claimSelectionType === MODEL_CLAIM_SELECTION_TYPE_ADVANCE) {
            payload = {
                ...payload,
                revCode: values.revCode,
                cpt: values.cpt,
                payerName: values.payerName,
                payerType: values.payerType,
                fromServiceDate: values.fromServiceDate === '' ? undefined : values.fromServiceDate,
                toServiceDate: values.toServiceDate === '' ? undefined : values.toServiceDate
            }
        } else {
            payload = {
                ...payload,
                equator: values.equator,
                claimNumbers: values.claimNumber
            }
        }
        setInitSearchParameters(payload); // setting for passing it to estimate API
        searchClaimsForModeling(payload, handleInitSearchCallback);
    }

    const handleInitSearchCallback = () => {
        setDisplayTableForClaimSelection(true);
    }

    const handleBacktoInitSearch = () => {
        setDisplayTableForClaimSelection(false);
        setFirstCallOfClaimSelection(true);
    }

    return (
        <div>
            <Loadable loading={estimatePending | analyzeEstimatePending}>
                {
                    displayTableForClaimSelection ?
                        <>
                            <EstimateForm
                                mode={mode} //for edit
                                modelId={modelId}//for edit
                                model={model}//for edit

                                displayTableForClaimSelection={displayTableForClaimSelection}
                                initSearcharameters={initSearcharameters}
                                tinyContracts={tinyContracts}
                                handleBacktoInitSearch={handleBacktoInitSearch}
                                fields={fields}
                            >
                                <Grid>
                                    <PagedTable
                                        name="model-claim-selection-landing"
                                        fields={fields}
                                        data={claimSelectionData}
                                        onLoad={handleClaimSelectionLoad}
                                        // refresh={refresh}
                                        defaultSort={DEFAULT_SORT}
                                        hasCheckbox={true}
                                        showSelectAll={true}
                                        title="Claim Selection"
                                    />
                                </Grid>
                            </EstimateForm>

                        </> :
                        <ClaimSearch handleInitSearch={handleInitSearch} mode={mode} model={model} />
                }
            </Loadable>
        </div >
    )
}

export default ClaimSelection

