import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSelector } from 'reselect';
import ClaimStatusEditPageComponent from './ClaimStatusEditPage';
import { createClaimStatus, createEditReset, editClaimStatus } from "../../../store/actions/claimStatus";

export const ClaimStatusEditPage = injectIntl(
    connect(() => {

        const selectEditDialog = (state) => state.claimStatus.editDialog;

        const selectClaimStatus = createSelector(
            selectEditDialog,
            (editDialog) => {
                if (editDialog && editDialog?.claimStatus) {
                    return editDialog?.claimStatus
                }
                return null
            }
        )

        const selectOpen = createSelector(
            selectEditDialog,
            (editDialog) => {
                if (editDialog && editDialog?.open) {
                    return true
                }
                return false
            }
        )

        const selectMode = createSelector(
            selectClaimStatus,
            (claimStatus) => {
                return claimStatus ? 'edit' : 'create';
            }
        )

        const selectInitialValues = createSelector(
            selectMode,
            selectClaimStatus,
            (mode, claimStatus) => {
                if (mode === 'edit' && claimStatus) {
                    return {
                        id: claimStatus.id,
                        name: claimStatus.name,
                        preRecovery: claimStatus.preRecovery
                    }
                }
                return {
                    name: '',
                    preRecovery: false
                }
            }
        )
        return (state, props) => ({
            open: selectOpen(state),
            initialValues: selectInitialValues(state),
            mode: selectMode(state),
            createPending: state.claimStatus.create.pending,
            editPending: state.claimStatus.update.pending
        })
    },
        (dispatch) => ({
            actions: bindActionCreators({
                createClaimStatus: createClaimStatus,
                editClaimStatus: editClaimStatus,
                createEditReset: createEditReset
            }, dispatch)
        })
    )(ClaimStatusEditPageComponent));