export const PAYERS_LIST = 'PAYERS_LIST';
export const PAYERS_PENDING = 'PAYERS_PENDING';
export const PAYERS_FULFILLED = 'PAYERS_FULFILLED';
export const PAYERS_REJECTED = 'PAYERS_REJECTED';

export const PAYERS_ERROR_RESET = 'PAYERS_ERROR_RESET';
export const PAYERS_ERROR_RESET_CLEAR = 'PAYERS_ERROR_RESET_CLEAR';
export const PAYERS_RESET = 'PAYERS_RESET';

export const PAYERS_UPDATE_STATUS = 'PAYERS_UPDATE_STATUS';
export const PAYERS_UPDATE_STATUS_PENDING = 'PAYERS_UPDATE_STATUS_PENDING';
export const PAYERS_UPDATE_STATUS_FULFILLED = 'PAYERS_UPDATE_STATUS_FULFILLED';
export const PAYERS_UPDATE_STATUS_REJECTED = 'PAYERS_UPDATE_STATUS_REJECTED';

export const PAYER_CREATE = 'PAYER_CREATE';
export const PAYER_CREATE_PENDING = 'PAYER_CREATE_PENDING';
export const PAYER_CREATE_FULFILLED = 'PAYER_CREATE_FULFILLED';
export const PAYER_CREATE_REJECTED = 'PAYER_CREATE_REJECTED';

export const PAYER_EDIT_RESET = 'PAYER_EDIT_RESET';

export const PAYER_UPDATE = 'PAYER_UPDATE';
export const PAYER_UPDATE_PENDING = 'PAYER_UPDATE_PENDING';
export const PAYER_UPDATE_FULFILLED = 'PAYER_UPDATE_FULFILLED';
export const PAYER_UPDATE_REJECTED = 'PAYER_UPDATE_REJECTED';

export const PAYER_CONTRACT_CREATE = 'PAYER_CONTRACT_CREATE';
export const PAYER_CONTRACT_CREATE_PENDING = 'PAYER_CONTRACT_CREATE_PENDING';
export const PAYER_CONTRACT_CREATE_FULFILLED = 'PAYER_CONTRACT_CREATE_FULFILLED';
export const PAYER_CONTRACT_CREATE_REJECTED = 'PAYER_CONTRACT_CREATE_REJECTED';

export const PAYER_CONTRACT_UPDATE = 'PAYER_CONTRACT_UPDATE';
export const PAYER_CONTRACT_UPDATE_PENDING = 'PAYER_CONTRACT_UPDATE_PENDING';
export const PAYER_CONTRACT_UPDATE_FULFILLED = 'PAYER_CONTRACT_UPDATE_FULFILLED';
export const PAYER_CONTRACT_UPDATE_REJECTED = 'PAYER_CONTRACT_UPDATE_REJECTED';

export const PAYER_CONTRACT_EDIT_RESET = 'PAYER_CONTRACT_EDIT_RESET';

export const PAYER_SELECT_ALL = 'PAYER_SELECT_ALL';
export const PAYER_SELECT_ALL_PENDING = 'PAYER_SELECT_ALL_PENDING';
export const PAYER_SELECT_ALL_FULFILLED = 'PAYER_SELECT_ALL_FULFILLED';
export const PAYER_SELECT_ALL_REJECTED = 'PAYER_SELECT_ALL_REJECTED';

export const PAYER_GET_PAYER_CONTRACTS_TINY = 'PAYER_GET_PAYER_CONTRACTS_TINY';
export const PAYER_GET_PAYER_CONTRACTS_TINY_PENDING = 'PAYER_GET_PAYER_CONTRACTS_TINY_PENDING';
export const PAYER_GET_PAYER_CONTRACTS_TINY_FULFILLED = 'PAYER_GET_PAYER_CONTRACTS_TINY_FULFILLED';
export const PAYER_GET_PAYER_CONTRACTS_TINY_REJECTED = 'PAYER_GET_PAYER_CONTRACTS_TINY_REJECTED';

export const PAYERS_TINY_LIST = 'PAYERS_TINY_LIST';
export const PAYERS_TINY_PENDING = 'PAYERS_TINY_PENDING';
export const PAYERS_TINY_FULFILLED = 'PAYERS_TINY_FULFILLED';
export const PAYERS_TINY_REJECTED = 'PAYERS_TINY_REJECTED';

export const PAYERS_SCORECARD_LIST = 'PAYERS_SCORECARD_LIST';
export const PAYERS_SCORECARD_PENDING = 'PAYERS_SCORECARD_PENDING';
export const PAYERS_SCORECARD_FULFILLED = 'PAYERS_SCORECARD_FULFILLED';
export const PAYERS_SCORECARD_REJECTED = 'PAYERS_SCORECARD_REJECTED';

export const PAYER_UPDATE_BULK = 'PAYER_UPDATE_BULK';
export const PAYER_UPDATE_BULK_PENDING = 'PAYER_UPDATE_BULK_PENDING';
export const PAYER_UPDATE_BULK_FULFILLED = 'PAYER_UPDATE_BULK_FULFILLED';
export const PAYER_UPDATE_BULK_REJECTED = 'PAYER_UPDATE_BULK_REJECTED';

export const PAYER_DELETE_BULK = 'PAYER_DELETE_BULK';
export const PAYER_DELETE_BULK_PENDING = 'PAYER_DELETE_BULK_PENDING';
export const PAYER_DELETE_BULK_FULFILLED = 'PAYER_DELETE_BULK_FULFILLED';
export const PAYER_DELETE_BULK_REJECTED = 'PAYER_DELETE_BULK_REJECTED';

export const PAYER_INFO_IMPORT = 'PAYER_INFO_IMPORT';
export const PAYER_INFO_IMPORT_PENDING = 'PAYER_INFO_IMPORT_PENDING';
export const PAYER_INFO_IMPORT_FULFILLED = 'PAYER_INFO_IMPORT_FULFILLED';
export const PAYER_INFO_IMPORT_REJECTED = 'PAYER_INFO_IMPORT_REJECTED';
export const PAYER_INFO_IMPORT_RESET = 'PAYER_INFO_IMPORT_RESET';

export const PAYER_INFO_EXPORT = 'PAYER_INFO_EXPORT';
export const PAYER_INFO_EXPORT_PENDING = 'PAYER_INFO_EXPORT_PENDING';
export const PAYER_INFO_EXPORT_FULFILLED = 'PAYER_INFO_EXPORT_FULFILLED';
export const PAYER_INFO_EXPORT_REJECTED = 'PAYER_INFO_EXPORT_REJECTED';

export const REMIT_PAYER_IMPORT = 'REMIT_PAYER_IMPORT';
export const REMIT_PAYER_IMPORT_PENDING = 'REMIT_PAYER_IMPORT_PENDING';
export const REMIT_PAYER_IMPORT_FULFILLED = 'REMIT_PAYER_IMPORT_FULFILLED';
export const REMIT_PAYER_IMPORT_REJECTED = 'REMIT_PAYER_IMPORT_REJECTED';
export const REMIT_PAYER_IMPORT_RESET = 'REMIT_PAYER_IMPORT_RESET';

export const REMIT_PAYER_EXPORT = 'REMIT_PAYER_EXPORT';
export const REMIT_PAYER_EXPORT_PENDING = 'REMIT_PAYER_EXPORT_PENDING';
export const REMIT_PAYER_EXPORT_FULFILLED = 'REMIT_PAYER_EXPORT_FULFILLED';
export const REMIT_PAYER_EXPORT_REJECTED = 'REMIT_PAYER_EXPORT_REJECTED';

export const REMIT_PAYER_ENABLE_ALL = 'REMIT_PAYER_ENABLE_ALL';
export const REMIT_PAYER_ENABLE_ALL_PENDING = 'REMIT_PAYER_ENABLE_ALL_PENDING';
export const REMIT_PAYER_ENABLE_ALL_FULFILLED = 'REMIT_PAYER_ENABLE_ALL_FULFILLED';
export const REMIT_PAYER_ENABLE_ALL_REJECTED = 'REMIT_PAYER_ENABLE_ALL_REJECTED';

export const REMIT_PAYER_CREATE = 'REMIT_PAYER_CREATE';
export const REMIT_PAYER_CREATE_PENDING = 'REMIT_PAYER_CREATE_PENDING';
export const REMIT_PAYER_CREATE_FULFILLED = 'REMIT_PAYER_CREATE_FULFILLED';
export const REMIT_PAYER_CREATE_REJECTED = 'REMIT_PAYER_CREATE_REJECTED';

export const REMIT_PAYER_UPDATE = 'REMIT_PAYER_UPDATE';
export const REMIT_PAYER_UPDATE_PENDING = 'REMIT_PAYER_UPDATE_PENDING';
export const REMIT_PAYER_UPDATE_FULFILLED = 'REMIT_PAYER_UPDATE_FULFILLED';
export const REMIT_PAYER_UPDATE_REJECTED = 'REMIT_PAYER_UPDATE_REJECTED';

export const REMIT_PAYER_BULK_UPDATE = 'REMIT_PAYER_BULK_UPDATE';
export const REMIT_PAYER_BULK_UPDATE_PENDING = 'REMIT_PAYER_BULK_UPDATE_PENDING';
export const REMIT_PAYER_BULK_UPDATE_FULFILLED = 'REMIT_PAYER_BULK_UPDATE_FULFILLED';
export const REMIT_PAYER_BULK_UPDATE_REJECTED = 'REMIT_PAYER_BULK_UPDATE_REJECTED';

export const REMIT_PAYER_TOLERANCE_MATCH = 'REMIT_PAYER_TOLERANCE_MATCH';
export const REMIT_PAYER_TOLERANCE_MATCH_PENDING = 'REMIT_PAYER_TOLERANCE_MATCH_PENDING';
export const REMIT_PAYER_TOLERANCE_MATCH_FULFILLED = 'REMIT_PAYER_TOLERANCE_MATCH_FULFILLED';
export const REMIT_PAYER_TOLERANCE_MATCH_REJECTED = 'REMIT_PAYER_TOLERANCE_MATCH_REJECTED';

export const REMIT_PAYER_CLAIM_MAPPING = 'REMIT_PAYER_CLAIM_MAPPING';
export const REMIT_PAYER_CLAIM_MAPPING_PENDING = 'REMIT_PAYER_CLAIM_MAPPING_PENDING';
export const REMIT_PAYER_CLAIM_MAPPING_FULFILLED = 'REMIT_PAYER_CLAIM_MAPPING_FULFILLED';
export const REMIT_PAYER_CLAIM_MAPPING_REJECTED = 'REMIT_PAYER_CLAIM_MAPPING_REJECTED';

export const CLAIM_PAYERS_DEPENDENCY = 'CLAIM_PAYERS_DEPENDENCY';
export const CLAIM_PAYERS_DEPENDENCY_PENDING = 'CLAIM_PAYERS_DEPENDENCY_PENDING';
export const CLAIM_PAYERS_DEPENDENCY_FULFILLED = 'CLAIM_PAYERS_DEPENDENCY_FULFILLED';
export const CLAIM_PAYERS_DEPENDENCY_REJECTED = 'CLAIM_PAYERS_DEPENDENCY_REJECTED';

export const CLAIM_PAYERS_DELETE = 'CLAIM_PAYERS_DELETE';
export const CLAIM_PAYERS_DELETE_PENDING = 'CLAIM_PAYERS_DELETE_PENDING';
export const CLAIM_PAYERS_DELETE_FULFILLED = 'CLAIM_PAYERS_DELETE_FULFILLED';
export const CLAIM_PAYERS_DELETE_REJECTED = 'CLAIM_PAYERS_DELETE_REJECTED';

export const REMIT_PAYERS_DEPENDENCY = 'REMIT_PAYERS_DEPENDENCY';
export const REMIT_PAYERS_DEPENDENCY_PENDING = 'REMIT_PAYERS_DEPENDENCY_PENDING';
export const REMIT_PAYERS_DEPENDENCY_FULFILLED = 'REMIT_PAYERS_DEPENDENCY_FULFILLED';
export const REMIT_PAYERS_DEPENDENCY_REJECTED = 'REMIT_PAYERS_DEPENDENCY_REJECTED';

export const REMIT_PAYERS_DELETE = 'REMIT_PAYERS_DELETE';
export const REMIT_PAYERS_DELETE_PENDING = 'REMIT_PAYERS_DELETE_PENDING';
export const REMIT_PAYERS_DELETE_FULFILLED = 'REMIT_PAYERS_DELETE_FULFILLED';
export const REMIT_PAYERS_DELETE_REJECTED = 'REMIT_PAYERS_DELETE_REJECTED';

export const PAYER_CONTRACT_DEPENDENCY = 'PAYER_CONTRACT_DEPENDENCY';
export const PAYER_CONTRACT_DEPENDENCY_PENDING = 'PAYER_CONTRACT_DEPENDENCY_PENDING';
export const PAYER_CONTRACT_DEPENDENCY_FULFILLED = 'PAYER_CONTRACT_DEPENDENCY_FULFILLED';
export const PAYER_CONTRACT_DEPENDENCY_REJECTED = 'PAYER_CONTRACT_DEPENDENCY_REJECTED';

export const PAYER_CONTRACT_ASSOCIATION = 'PAYER_CONTRACT_ASSOCIATION';
export const PAYER_CONTRACT_ASSOCIATION_PENDING = 'PAYER_CONTRACT_ASSOCIATION_PENDING';
export const PAYER_CONTRACT_ASSOCIATION_FULFILLED = 'PAYER_CONTRACT_ASSOCIATION_FULFILLED';
export const PAYER_CONTRACT_ASSOCIATION_REJECTED = 'PAYER_CONTRACT_ASSOCIATION_REJECTED';

export const CLAIM_PAYERS_SET_DEPENDENCY_DIALOG = 'CLAIM_PAYERS_SET_DIALOG';
export const REMIT_PAYERS_SET_DEPENDENCY_DIALOG = 'REMIT_PAYERS_SET_DIALOG';
export const PAYER_CONTRACT_SET_DEPENDENCY_DIALOG = 'PAYER_CONTRACT_SET_DIALOG';

export const REMIT_PAYER_SET_TOLERANCE_MATCH_DIALOG = 'REMIT_PAYER_SET_TOLERANCE_MATCH_DIALOG';
export const PAYER_INFO_SET_EDIT_DIALOG = 'PAYER_INFO_SET_EDIT_DIALOG';
export const PAYER_INFO_SET_ENABLE_DIALOG = 'PAYER_INFO_SET_ENABLE_DIALOG';

export const REMIT_PAYER_SET_EDIT_DIALOG = 'REMIT_PAYER_SET_EDIT_DIALOG';
export const REMIT_PAYER_SET_ENABLE_DIALOG = 'REMIT_PAYER_SET_ENABLE_DIALOG';

export const PAYER_PLAN_LANDING = 'PAYER_PLAN_LANDING';
export const PAYER_PLAN_LANDING_PENDING = 'PAYER_PLAN_LANDING_PENDING';
export const PAYER_PLAN_LANDING_FULFILLED = 'PAYER_PLAN_LANDING_FULFILLED';
export const PAYER_PLAN_LANDING_REJECTED = 'PAYER_PLAN_LANDING_REJECTED';

export const PAYER_PLAN_CREATE = 'PAYER_PLAN_CREATE';
export const PAYER_PLAN_CREATE_PENDING = 'PAYER_PLAN_CREATE_PENDING';
export const PAYER_PLAN_CREATE_FULFILLED = 'PAYER_PLAN_CREATE_FULFILLED';
export const PAYER_PLAN_CREATE_REJECTED = 'PAYER_PLAN_CREATE_REJECTED';

export const PAYER_PLAN_UPDATE = 'PAYER_PLAN_UPDATE';
export const PAYER_PLAN_UPDATE_PENDING = 'PAYER_PLAN_UPDATE_PENDING';
export const PAYER_PLAN_UPDATE_FULFILLED = 'PAYER_PLAN_UPDATE_FULFILLED';
export const PAYER_PLAN_UPDATE_REJECTED = 'PAYER_PLAN_UPDATE_REJECTED';

export const PAYER_PLAN_DELETE = 'PAYER_PLAN_DELETE';
export const PAYER_PLAN_DELETE_PENDING = 'PAYER_PLAN_DELETE_PENDING';
export const PAYER_PLAN_DELETE_FULFILLED = 'PAYER_PLAN_DELETE_FULFILLED';
export const PAYER_PLAN_DELETE_REJECTED = 'PAYER_PLAN_DELETE_REJECTED';

export const PAYER_PLAN_GET = 'PAYER_PLAN_GET';
export const PAYER_PLAN_GET_PENDING = 'PAYER_PLAN_GET_PENDING';
export const PAYER_PLAN_GET_FULFILLED = 'PAYER_PLAN_GET_FULFILLED';
export const PAYER_PLAN_GET_REJECTED = 'PAYER_PLAN_GET_REJECTED';

export const PAYER_PLAN_GET_TINY = 'PAYER_PLAN_GET_TINY';
export const PAYER_PLAN_GET_TINY_PENDING = 'PAYER_PLAN_GET_TINY_PENDING';
export const PAYER_PLAN_GET_TINY_FULFILLED = 'PAYER_PLAN_GET_TINY_FULFILLED';
export const PAYER_PLAN_GET_TINY_REJECTED = 'PAYER_PLAN_GET_TINY_REJECTED';

export const PAYER_PLAN_CREATE_UPDATE_RESET = 'PAYER_PLAN_CREATE_UPDATE_RESET';

export const PAYER_PLAN_DELETE_DEPENDENCIES = 'PAYER_PLAN_DELETE_DEPENDENCIES';
export const PAYER_PLAN_DELETE_DEPENDENCIES_PENDING = 'PAYER_PLAN_DELETE_DEPENDENCIES_PENDING';
export const PAYER_PLAN_DELETE_DEPENDENCIES_FULFILLED = 'PAYER_PLAN_DELETE_DEPENDENCIES_FULFILLED';
export const PAYER_PLAN_DELETE_DEPENDENCIES_REJECTED = 'PAYER_PLAN_DELETE_DEPENDENCIES_REJECTED';

export const PAYER_PLAN_SET_DEPENDENCY_DIALOG = 'PAYER_PLAN_SET_DEPENDENCY_DIALOG';
export const PAYER_PLAN_RESET_DEPENDENCIES = 'PAYER_PLAN_RESET_DEPENDENCIES';
export const PAYER_PLAN_SET_DIALOG = 'PAYER_PLAN_SET_DIALOG';

export const PAYER_ID_MAPPING_CREATE = 'PAYER_ID_MAPPING_CREATE';
export const PAYER_ID_MAPPING_CREATE_PENDING = 'PAYER_ID_MAPPING_CREATE_PENDING';
export const PAYER_ID_MAPPING_CREATE_FULFILLED = 'PAYER_ID_MAPPING_CREATE_FULFILLED';
export const PAYER_ID_MAPPING_CREATE_REJECTED = 'PAYER_ID_MAPPING_CREATE_REJECTED';

export const PAYER_ID_MAPPING_UPDATE = 'PAYER_ID_MAPPING_UPDATE';
export const PAYER_ID_MAPPING_UPDATE_PENDING = 'PAYER_ID_MAPPING_UPDATE_PENDING';
export const PAYER_ID_MAPPING_UPDATE_FULFILLED = 'PAYER_ID_MAPPING_UPDATE_FULFILLED';
export const PAYER_ID_MAPPING_UPDATE_REJECTED = 'PAYER_ID_MAPPING_UPDATE_REJECTED';

export const PAYER_ID_MAPPING_BULK_UPDATE = 'PAYER_ID_MAPPING_BULK_UPDATE';
export const PAYER_ID_MAPPING_BULK_UPDATE_PENDING = 'PAYER_ID_MAPPING_BULK_UPDATE_PENDING';
export const PAYER_ID_MAPPING_BULK_UPDATE_FULFILLED = 'PAYER_ID_MAPPING_BULK_UPDATE_FULFILLED';
export const PAYER_ID_MAPPING_BULK_UPDATE_REJECTED = 'PAYER_ID_MAPPING_BULK_UPDATE_REJECTED';

export const PAYER_ID_MAPPING_GET = 'PAYER_ID_MAPPING_GET';
export const PAYER_ID_MAPPING_GET_PENDING = 'PAYER_ID_MAPPING_GET_PENDING';
export const PAYER_ID_MAPPING_GET_FULFILLED = 'PAYER_ID_MAPPING_GET_FULFILLED';
export const PAYER_ID_MAPPING_GET_REJECTED = 'PAYER_ID_MAPPING_GET_REJECTED';

export const PAYER_ID_MAPPING_TINY = 'PAYER_ID_MAPPING_TINY';
export const PAYER_ID_MAPPING_TINY_PENDING = 'PAYER_ID_MAPPING_TINY_PENDING';
export const PAYER_ID_MAPPING_TINY_FULFILLED = 'PAYER_ID_MAPPING_TINY_FULFILLED';
export const PAYER_ID_MAPPING_TINY_REJECTED = 'PAYER_ID_MAPPING_TINY_REJECTED';

export const PAYER_ID_MAPPING_DELETE_DEPENDENCIES = 'PAYER_ID_MAPPING_DELETE_DEPENDENCIES';
export const PAYER_ID_MAPPING_DELETE_DEPENDENCIES_PENDING = 'PAYER_ID_MAPPING_DELETE_DEPENDENCIES_PENDING';
export const PAYER_ID_MAPPING_DELETE_DEPENDENCIES_FULFILLED = 'PAYER_ID_MAPPING_DELETE_DEPENDENCIES_FULFILLED';
export const PAYER_ID_MAPPING_DELETE_DEPENDENCIES_REJECTED = 'PAYER_ID_MAPPING_DELETE_DEPENDENCIES_REJECTED';

export const PAYER_ID_MAPPING_DELETE = 'PAYER_ID_MAPPING_DELETE';
export const PAYER_ID_MAPPING_DELETE_PENDING = 'PAYER_ID_MAPPING_DELETE_PENDING';
export const PAYER_ID_MAPPING_DELETE_FULFILLED = 'PAYER_ID_MAPPING_DELETE_FULFILLED';
export const PAYER_ID_MAPPING_DELETE_REJECTED = 'PAYER_ID_MAPPING_DELETE_REJECTED';

export const PAYER_ID_MAPPING_UPLOAD = 'PAYER_ID_MAPPING_UPLOAD';
export const PAYER_ID_MAPPING_UPLOAD_PENDING = 'PAYER_ID_MAPPING_UPLOAD_PENDING';
export const PAYER_ID_MAPPING_UPLOAD_FULFILLED = 'PAYER_ID_MAPPING_UPLOAD_FULFILLED';
export const PAYER_ID_MAPPING_UPLOAD_REJECTED = 'PAYER_ID_MAPPING_UPLOAD_REJECTED';

export const PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE = 'PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE';
export const PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_PENDING = 'PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_PENDING';
export const PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_FULFILLED = 'PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_FULFILLED';
export const PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_REJECTED = 'PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_REJECTED';

export const PAYER_ID_MAPPING_SET_EDIT_DIALOG = 'PAYER_ID_MAPPING_SET_EDIT_DIALOG';
export const PAYER_ID_MAPPING_SET_DEPENDENCY_DIALOG = 'PAYER_ID_MAPPING_SET_DEPENDENCY_DIALOG';
export const PAYER_ID_MAPPING_CREATE_UPDATE_RESET = 'PAYER_ID_MAPPING_CREATE_UPDATE_RESET';
export const PAYER_ID_MAPPING_SET_DEPENDENCY_RESET = 'PAYER_ID_MAPPING_SET_DEPENDENCY_RESET';

export const getPayers = () => {
    return {
        type: PAYERS_LIST
    }
};

export const getPayersTiny = () => {
    return {
        type: PAYERS_TINY_LIST
    }
};

export const updatePayerStatus = (id, enabled, callback) => {
    return {
        type: PAYERS_UPDATE_STATUS,
        payload: { id, enabled, callback },
    }
};

export const create = (payer, callback, shouldReloadGrid) => ({
    type: PAYER_CREATE,
    payload: { payer, callback, shouldReloadGrid },
});

export const update = (payer, callback, shouldReloadGrid) => ({
    type: PAYER_UPDATE,
    payload: { payer, callback, shouldReloadGrid },
});

export const createPayerContract = (payerContract, callback, shouldReloadGrid) => ({
    type: PAYER_CONTRACT_CREATE,
    payload: { payerContract, callback, shouldReloadGrid },
});

export const updatePayerContract = (payerContract, callback, shouldReloadGrid) => ({
    type: PAYER_CONTRACT_UPDATE,
    payload: { payerContract, callback, shouldReloadGrid },
});

export const errorReset = (...errorKeys) => ({
    type: PAYERS_ERROR_RESET,
    payload: { errorKeys },
});

export const selectDeselectAll = (payload, callback) => ({
    type: PAYER_SELECT_ALL,
    payload: { payload, callback },
});

export const getPayerContractsTiny = () => ({
    type: PAYER_GET_PAYER_CONTRACTS_TINY,
});

export const getPayersScoreCard = (options) => ({
    type: PAYERS_SCORECARD_LIST,
    payload: { options }
});
export const updateBulk = (payer, callback, shouldReloadGrid) => ({
    type: PAYER_UPDATE_BULK,
    payload: { payer, callback, shouldReloadGrid },
});

export const errorResetClear = () => ({
    type: PAYERS_ERROR_RESET_CLEAR,
});

export const payerContractEditReset = () => ({
    type: PAYER_CONTRACT_EDIT_RESET
});

export const payerEditReset = () => ({
    type: PAYER_EDIT_RESET
});

export const deleteBulk = (payer, callback, shouldReloadGrid) => ({
    type: PAYER_DELETE_BULK,
    payload: { payer, callback, shouldReloadGrid },
});

export const importPayerInfo = () => ({
    type: PAYER_INFO_IMPORT
    //payload: { options }
});

export const payerImportErrorReset = () => ({
    type: PAYER_INFO_IMPORT_RESET
});

export const exportPayer = (payerIds, callback) => ({
    type: PAYER_INFO_EXPORT,
    payload: {
        payerIds, callback
    }
});

export const setPayerInfoEditDialog = (payerInfoEditDialog) => ({
    type: PAYER_INFO_SET_EDIT_DIALOG,
    payload: { payerInfoEditDialog }
});

export const setPayerInfoEnableDialog = (infoEnableDialog) => ({
    type: PAYER_INFO_SET_ENABLE_DIALOG,
    payload: { infoEnableDialog }
});

export const importRemitPayer = () => ({
    type: REMIT_PAYER_IMPORT,
    //payload: { options }
});

export const remitPayerImportErrorReset = () => ({
    type: REMIT_PAYER_IMPORT_RESET
});

export const exportRemitPayer = (payerIds, callback) => ({
    type: REMIT_PAYER_EXPORT,
    payload: {
        payerIds, callback
    }
});

export const setRemitPayerEditDialog = (remitPayerEditDialog) => ({
    type: REMIT_PAYER_SET_EDIT_DIALOG,
    payload: { remitPayerEditDialog }
});

export const setRemitPayerEnableDialog = (remitPayerEnableDialog) => ({
    type: REMIT_PAYER_SET_ENABLE_DIALOG,
    payload: { remitPayerEnableDialog }
});

export const remitPayerSelectAll = (options, callback) => ({
    type: REMIT_PAYER_ENABLE_ALL,
    payload: { options, callback }
});

export const createRemitPayer = (payer, callback, shouldReloadGrid) => ({
    type: REMIT_PAYER_CREATE,
    payload: { payer, callback, shouldReloadGrid },
});

export const updateRemitPayer = (payer, callback, shouldReloadGrid) => ({
    type: REMIT_PAYER_UPDATE,
    payload: { payer, callback, shouldReloadGrid },
});

export const bulkUpdateRemitPayer = (payer, callback, shouldReloadGrid) => ({
    type: REMIT_PAYER_BULK_UPDATE,
    payload: { payer, callback, shouldReloadGrid },
});

export const remitPayerToleranceMatch = (callback) => ({
    type: REMIT_PAYER_TOLERANCE_MATCH,
    payload: { callback }
});

export const setRemitPayToleranceMatchDialog = (toleranceMatchDialog) => ({
    type: REMIT_PAYER_SET_TOLERANCE_MATCH_DIALOG,
    payload: { toleranceMatchDialog }
});

export const remitPayerMapping = (options, callback) => ({
    type: REMIT_PAYER_CLAIM_MAPPING,
    payload: { options, callback }
});

export const claimPayerDependency = (options, callback) => ({
    type: CLAIM_PAYERS_DEPENDENCY,
    payload: { options, callback }
});

export const setClaimDependencyDialog = (claimDependencyDialog) => ({
    type: CLAIM_PAYERS_SET_DEPENDENCY_DIALOG,
    payload: { claimDependencyDialog }
});

export const deleteClaimPayer = (options, callback) => ({
    type: CLAIM_PAYERS_DELETE,
    payload: { options, callback }
});

export const remitPayerDependency = (options, callback) => ({
    type: REMIT_PAYERS_DEPENDENCY,
    payload: { options, callback }
});

export const setRemitDependencyDialog = (remitDependencyDialog) => ({
    type: REMIT_PAYERS_SET_DEPENDENCY_DIALOG,
    payload: { remitDependencyDialog }
});

export const deleteRemitPayer = (options, callback) => ({
    type: REMIT_PAYERS_DELETE,
    payload: { options, callback }
});

export const getPayerContractDependency = (options, callback) => ({
    type: PAYER_CONTRACT_DEPENDENCY,
    payload: { options, callback }
});

export const setPayerContractDependencyDialog = (payerContractDependencyDialog) => ({
    type: PAYER_CONTRACT_SET_DEPENDENCY_DIALOG,
    payload: { payerContractDependencyDialog }
});

export const payerPlansLanding = () => ({
    type: PAYER_PLAN_LANDING,
});

export const createPayerPlan = (values, callback, shouldReloadGrid) => ({
    type: PAYER_PLAN_CREATE,
    payload: { values, callback, shouldReloadGrid }
});

export const editPayerPlan = (values, callback, shouldReloadGrid) => ({
    type: PAYER_PLAN_UPDATE,
    payload: { values, callback, shouldReloadGrid }
});

export const deletePayerPlan = (planId, callback) => ({
    type: PAYER_PLAN_DELETE,
    payload: { planId, callback }
});

export const createPayerPlanEditReset = () => ({
    type: PAYER_PLAN_CREATE_UPDATE_RESET
});

export const getPayerPlan = (planId) => ({
    type: PAYER_PLAN_GET,
    payload: { planId }
});

export const getPayerPlansTiny = (callback, newPlan) => ({
    type: PAYER_PLAN_GET_TINY,
    payload: { callback, newPlan }
});

export const getPayerPlanDeleteDependencies = (id) => ({
    type: PAYER_PLAN_DELETE_DEPENDENCIES,
    payload: { id }
});

export const setPayerPlanDependencyDialog = (payerPlanDependencyDialog) => ({
    type: PAYER_PLAN_SET_DEPENDENCY_DIALOG,
    payload: { payerPlanDependencyDialog }
});

export const resetPayerPlanDependencies = () => ({
    type: PAYER_PLAN_RESET_DEPENDENCIES,
});

export const setPayerPlanDialog = (payerPlanDialog) => ({
    type: PAYER_PLAN_SET_DIALOG,
    payload: { payerPlanDialog }
});

export const checkContractsAssociation = (options, callback) => ({
    type: PAYER_CONTRACT_ASSOCIATION,
    payload: { options, callback }
});

export const createPayerIdMapping = (values, callback, shouldReloadGrid) => ({
    type: PAYER_ID_MAPPING_CREATE,
    payload: { values, callback, shouldReloadGrid }
});

export const editPayerIdMapping = (values, callback, shouldReloadGrid) => ({
    type: PAYER_ID_MAPPING_UPDATE,
    payload: { values, callback, shouldReloadGrid }
});

export const editBulkPayerIdMapping = (values, callback, shouldReloadGrid) => ({
    type: PAYER_ID_MAPPING_BULK_UPDATE,
    payload: { values, callback, shouldReloadGrid },
});

export const payerIdMappingCreateEditReset = () => ({
    type: PAYER_ID_MAPPING_CREATE_UPDATE_RESET
});

export const getPayerIdMapping = (payerIdMappingId) => ({
    type: PAYER_ID_MAPPING_GET,
    payload: { payerIdMappingId }
});

export const setPayerIdMappingEditDialog = (editPayerIdMappingDialog) => ({
    type: PAYER_ID_MAPPING_SET_EDIT_DIALOG,
    payload: { editPayerIdMappingDialog }
});

export const getPayerIdMappingDeleteDependencies = (id) => ({
    type: PAYER_ID_MAPPING_DELETE_DEPENDENCIES,
    payload: { id }
});

export const deletePayerIdMapping = (id, callback) => ({
    type: PAYER_ID_MAPPING_DELETE,
    payload: { id, callback }
});

export const setPayerIdMappingDependencyDialog = (payerIdMappingDependencyDialog) => ({
    type: PAYER_ID_MAPPING_SET_DEPENDENCY_DIALOG,
    payload: { payerIdMappingDependencyDialog }
});

export const payerIdMappingDependencyReset = () => ({
    type: PAYER_ID_MAPPING_SET_DEPENDENCY_RESET
});

export const uploadPayerIdMapping = (file, callback, errorCallback) => ({
    type: PAYER_ID_MAPPING_UPLOAD,
    payload: { file, callback, errorCallback },
});

export const downloadPayerIdMapping = (callback) => ({
    type: PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE,
    payload: { callback }
});

export const getPayerIdMappingTiny = (values, callback) => ({
    type: PAYER_ID_MAPPING_TINY,
    payload: { values, callback }
});