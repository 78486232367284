import React, { useEffect, useState } from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import { DataTable, DataFilter } from '../../../common';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DeleteConfirmation } from "../../../common/DeleteConfirmation";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  textField: {
    width: 400,
  },
}));

export default function DashboardFiltersPage(props) {
  const classes = useStyles();
  const search = useLocation().search;
  const persist = new URLSearchParams(search).get('persist');

  const {
    dashboardsFilteredData, fields, sort, dashboardFilterListPending, dashboardFilterDeletePending,
    order, filter, actions: { setFilter, setSort, setOrder, dashboardFilterList, dashboardFilterDelete }
  } = props;


  useEffect(() => {
    dashboardFilterList();
    if (!persist) {
      setFilter('');
    }
  }, [dashboardFilterList]);

  const handleDashboardEdit = (dashboard) => {
    props.history.push(`/admin/dashboardfilters/edit/${dashboard.id}`);
  }

  const handleCallBack = () => {
    dashboardFilterList();
  }

  const handleDashboardDelete = (dashboard) => {
    if (dashboard) {
      dashboardFilterDelete(dashboard.id, handleCallBack);
    }
  }

  const handleSearchInput = (value) => {
    setFilter(value);
  }

  const handleAddDashboard = () => {
    props.history.push('/admin/dashboardfilters/edit/new');
  }

  function filterAction(rowData) {
    return (
      <div className='d-flex'>
        <IconButton
          color="primary"
          className='p-1'
          aria-label="Edit"
          onClick={() => handleDashboardEdit(rowData)}
          size="large">
          <Tooltip title="Edit">
            <EditIcon />
          </Tooltip>
        </IconButton>
        <DeleteConfirmation handleDelete={() => handleDashboardDelete(rowData)} />

      </div>
    );
  }

  function handleRefresh() {
    dashboardFilterList();
  }

  return (
    <>
      <Helmet title="Dashboard Configuration" />
      <Portlet>
        <PortletHeader
          title="Filters"
          toolbar={
            <PortletHeaderToolbar>
              <DataFilter
                onChange={handleSearchInput}
                placeholder="Search"
                cssClass={classes.textField}
                filter={filter}
              />
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <DataTable
            fields={fields}
            data={dashboardsFilteredData}
            onAdd={handleAddDashboard}
            order={order}
            onOrder={setOrder}
            sort={sort}
            onSort={setSort}
            loading={dashboardFilterListPending || dashboardFilterDeletePending}
            name="Filter"
            handleRefresh={handleRefresh}

            hasActions={true}
            renderActionColumn={filterAction}
          />
        </PortletBody>
      </Portlet>
    </>
  );
}
