import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import HospitalServicesPageComponent from "./HospitalServicesPage";

import { hospitalServicesLanding, resetPage, set } from '../../store/actions/pages';
import { dateEquators, integerEquators, StringEquators } from '../../store/constants/pages';
import { createDateField, createFloatingPoint, createIconDataField, createNumberDataField, createStringDataField } from '../../util/format';
import { list as getFacilities } from "../../store/actions/facilities";

import { setV2 } from '../../store/actions/pagesV2';
import { getByName, reset } from '../../store/actions/search';
import { selectSessionRole } from '../../store/selectors/session';
import { setHopsitalServicesDependencyDialog, setHopsitalServicesImportConfirmationDialog, setHopsitalServicesLookupDialog,setEditDrgDialog,setSyncToShoppables } from "../../store/actions/hospitalServices";

export const selectFacilityList = (state) => state.facilities.data;

const selectFields = createSelector(
    selectFacilityList,
    (facilities) => [
        createStringDataField('drg', 'DRG', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('type', 'Type', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('facilityName', 'Facility Name',{sortable: true,hideFromFilter:true,equators: StringEquators}),
        createStringDataField('facilityId', 'Facility',{ equators: StringEquators, hidden: true,isStrictDropdown:true,dropdownValues:facilities?.map((item)=>({label:item?.name,value:item?.id}))}),

        createStringDataField('title', 'Description', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createNumberDataField('weights', 'Weight', { sortable: true, equators: integerEquators }),
        createDateField('effectiveTo', 'Effective To', { sortable: true, equators: dateEquators }),
        createIconDataField('isEffective', 'Effective', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
        createIconDataField('isDiscontinued', 'Discontinued', { hideFromFilter: false, isStrictDropdown: true, icon: "fas fa-times-circle", checkedClass: 'text-danger', sortable: true }),
    ]
);

const selectHospitalServicesData = createSelector(
    (state) => state.pages['hospitalservices-landing'].list,
    (data) => data
);

export const selectCheckboxStatus = createSelector(
    selectHospitalServicesData,
    (hospitalServices: any) => {
        const enabledHospitalServices = hospitalServices?.filter(hospitalService => hospitalService.active).length;
        if (hospitalServices?.length === enabledHospitalServices) {
            return true;
        }
        return false;
    }
);


export const HospitalServicesPage = connect(
    () => {
        return (state, props) => ({
            hospitalServicesData: selectHospitalServicesData(state),
            hospitalServicesFields: selectFields(state as never),
            syncPending: state.hospitalServices.syncShoppables.pending,
            checkboxStatus: selectCheckboxStatus(state),
            facilities: selectFacilityList(state),
            userRole: selectSessionRole(state as never)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            hospitalServicesLanding: hospitalServicesLanding,
            searchGetByName: getByName,
            searchReset: reset,
            resetPagedTable: resetPage,
            setList: (value) => set('hospitalservices-landing', 'list', value),
            setSelectedItems: (value) => setV2('hospitalservices-landing', 'selected', value),
            setMasterSelectedItems: (value) => setV2('hospitalservices-master-landing', 'selected', value),
            setHopsitalServicesDependencyDialog: setHopsitalServicesDependencyDialog,
            setHopsitalServicesImportConfirmationDialog: setHopsitalServicesImportConfirmationDialog,
            setHopsitalServicesLookupDialog: setHopsitalServicesLookupDialog,
            setEditDrgDialog:setEditDrgDialog,
            getFacilities:getFacilities,
            setSyncToShoppables:setSyncToShoppables
        }, dispatch)
    })
)(injectIntl(HospitalServicesPageComponent));