import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { DataTable } from '../../../../common';
import Draggable from 'react-draggable';

function ServiceAdjustments(props) {
    const { open, paymentId, fields, filteredData, order, sort, getAdjusmentsPending,claimId,
        actions: { setClaimServiceAdjustments, getClaimServiceAdjustments, setSort, setOrder } } = props;

    const [refresh, setRefresh] = useState(Promise.resolve());

    useEffect(() => {
        if (open) {
            getClaimServiceAdjustments(paymentId,claimId);
        }
    }, [open, refresh])


    const closeAdjustmentHandler = () => {
        const payload = { open: false }
        setClaimServiceAdjustments(payload);
    }

    const handleRefresh = () => {
        setRefresh(Promise.resolve());
    }

    const hoverData = (adjustment) => {
        const hoverColumns = new Map();

        const groupCodeColumns = [
            {
                label: "Grouping ",
                data: adjustment?.grouping,
            }
        ];

        const reasonShortDescriptionColumns = [
            {
                label: "Reason Description ",
                data: adjustment?.reasonDescription,
            }
        ];

        hoverColumns.set('groupCode', groupCodeColumns);
        hoverColumns.set('reasonShortDescription', reasonShortDescriptionColumns);

        return hoverColumns;
    }

    function PaperComponent(props) {
        return (
            <Draggable
                handle="#service-adjustments-dialog"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        (<div>
            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="md"
                open={open}
                onClose={closeAdjustmentHandler}
                PaperComponent={PaperComponent}
                aria-labelledby="service-adjustments-dialog"
            >
                <DialogTitle style={{ cursor: 'move' }} id="service-adjustments-dialog">
                    <Grid container alignItems='center'>
                        <Grid item xs={11}>
                            Service Adjustments
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <IconButton aria-label="close" onClick={closeAdjustmentHandler} size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <DataTable
                        fields={fields}
                        data={filteredData}
                        order={order}
                        onOrder={setOrder}
                        sort={sort}
                        onSort={setSort}
                        loading={getAdjusmentsPending}
                        handleRefresh={handleRefresh}
                        hoverData={hoverData}
                    />
                </DialogContent>
            </Dialog>
        </div>)
    );
}

export default ServiceAdjustments