import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { deleteClaimByContractId } from '../../../store/actions/claimAnalysis';
import { deleteReset, getStates, setContractDependencyDialog, setCopyContractDialog, setStateDialog, tiny } from '../../../store/actions/contracts';
import { getContractsV2,getHierarchy,setDirectoryEditDialog,setContractsV2DirectoryDependencyDialog,setMoveDialog ,resetContracts} from '../../../store/actions/contractsv2';
import { selectContractStates } from "../../../store/selectors/contracts";
import { set } from '../../../store/actions/pages';
import { setV2 } from '../../../store/actions/pagesV2';
import { StringEquators } from '../../../store/constants/pages';
import { list as getFacilities } from "../../../store/actions/facilities";

import { selectSessionRole } from '../../../store/selectors/session';
import { createPlainDateField, createStringDataField,createStringFieldFromArrayMapping,createContractStateField, createDateField } from '../../../util/format';
import { createFilteredSortedDataSelector } from '../../../util/selector';
import ContractsPageV2Component from './ContractsPageV2';

const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

export const selectContracts = state => state.contractsv2.contracts;

const selectDirectoryId = (state, props) => props.match.params.directoryId;
export const selectFacilityList = (state) => state.facilities.data;

const selectFields = createSelector(
    selectFacilityList,
    selectContractStates,
    (facilitiesArray,contractStates) => [
        createStringDataField('name', 'Contract Name', { sortable: true }),
        createPlainDateField('effectiveDate', 'Effective Date', { fieldType: 'Date' }),
        createPlainDateField('terminationDate', 'Termination Date', { fieldType: 'Date' }),
        createDateField('finalizedDate', 'Finalized Date', { fieldType: 'Date' }),
        createStringFieldFromArrayMapping('contractFacilityXref', 'Facilities', facilitiesArray, 'facilityId', { hideFromFilter: true, truncate: true }),
        createContractStateField('contractState.name', 'State', {
            secondLevel: 'name', sortable: true, equators: StringEquators, isStrictDropdown: true, hideFromFilter: true,
            dropdownValues: contractStates.map(item => ({ label: item.name, value: item.name }))
        }),
    ]
);

const selectFilteredFields = createSelector(
        selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state) => state.pages['contractsv2-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createSelector(
    selectFilteredFields,
    (state) => state.pages['contractsv2-landing'].filter,
    selectSort,
    selectContracts,
    (fields, filter, sort, data) => {
        return data
    }
);

const selectContractScs = createSelector(selectContracts,
    (data) => {
        return data?.contracts ?? []
    }
);

const selectDirectories = createSelector(selectContracts,
    (data) => {
        return data?.directories ?? []
    }
);


const filteredContracts = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`facilities-landing`].filter,
    selectSort,
    selectContractScs,
);

const filteredDirectories = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`facilities-landing`].filter,
    selectSort,
    selectDirectories,
);



export const ContractsPageV2 = connect(
    () => {
        return (state:any, props) => ({
            getListPending: state.contractsv2.getContracts.pending,
            movePending: state.contractsv2.move.pending,
            fields: selectFields(state as never),
            filteredData: selectFilteredData(state as never),
            // contracts:state.contractsv2.contracts?.contracts,
            contracts:filteredContracts(state),

            // directories: state.contractsv2.contracts?.directories,
            directories: filteredDirectories(state),

            order: state.pages['contractsv2-landing'].order,
            filter: state.pages['contractsv2-landing'].filter,
            sort: selectSort(state),
            userRole: selectSessionRole(state),
            directoryId: selectDirectoryId(state, props)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setOrder: (value) => set('contractsv2-landing', 'order', value),
            setSort: (value) => set('contractsv2-landing', 'sort', value),
            resetSelected: (value) => setV2('contractsv2-landing', 'selected', []),
            getContractsList: getContractsV2,
            resetContracts:resetContracts,
            setDirectoryEditDialog: setDirectoryEditDialog,
            setMoveDialog: setMoveDialog,
            setContractsV2DirectoryDependencyDialog,
            getHierarchy: getHierarchy,
            getFacilities,
            deleteReset,
            setCopyContractDialog,
            setContractDependencyDialog,
            deleteClaimByContractId,
            setStateDialog,
            getStates
        }, dispatch)
    })
)(injectIntl(ContractsPageV2Component));