import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import {IgnoreFiltersAcknowledgementDialog} from './IgnoreFiltersAcknowledgementDialog';


export const IgnoreFiltersDialog = connect(
    () => {

        return (state, props) => ({
            open:state.pages['claimAnalysis-landing']?.showIgnoredFilterDialog
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            
        }, dispatch)
    })
)(injectIntl(IgnoreFiltersAcknowledgementDialog));