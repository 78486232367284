import React, { useState } from "react";
import { Grid } from '@mui/material';
import { PagedTable } from '../../common';

import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../partials/content/Portlet";
import { CustomLabel } from '../../common';
import { createFloatingPoint } from '../../util/format';
import Helmet from 'react-helmet';



const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];

export default function ContractedRatesPage(props) {
    const { fields, data,
        actions: { contractedRatesLanding }
    } = props;
    const [refresh] = useState(Promise.resolve());

    function handleLoad(sort, filter, pagination) {
        contractedRatesLanding(pagination, sort, filter);
    }

    function renderCollapsibleItem(contractedRateItem) {
        return (
            <>
                {contractedRateItem && (
                    <Grid className="my-3">
                        <Grid container className="my-4">
                            <Grid item xs={12} className="mb-2">
                                <Grid container>
                                    <Grid item xs={6}>
                                        <CustomLabel label="Facility Allowed Amount" data={`$ ${createFloatingPoint(contractedRateItem.facAllowedAmount)}`} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomLabel label="Non-Facility Allowed Amount" data={`$ ${createFloatingPoint(contractedRateItem.nonfacAllowedAmount)}`} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </>
        )
    }

    return (
        <>
            <Helmet title="Contracted Rates" />
            <Portlet>
                <PortletHeader
                    title="Contracted Rates"
                />
                <PortletBody>
                    <PagedTable
                        name="contracted-rates-landing"
                        fields={fields}
                        data={data}
                        onLoad={handleLoad}
                        refresh={refresh}
                        defaultSort={DEFAULT_SORT}
                        isCollapsible={true}
                        collapsibleItem={renderCollapsibleItem}
                    />
                </PortletBody>
            </Portlet>
        </>
    );
}