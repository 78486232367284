import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    FormControl,
    DialogContentText,
    DialogActions,
    InputLabel,
    Select,
    Input,
    MenuItem,
    FormHelperText,
    FormControlLabel,
    Switch,
    InputAdornment,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Formik } from "formik";
import { DateRangeControl, Loadable, PopoverLink } from '../../../common';
import { errorToastify } from '../../../util/toast';
import { StringEquators } from '../../../store/constants/pages';
import { Card } from 'react-bootstrap';

const filterFields = [{
    label: 'Remit Date',
    value: 'remitdate'
}, {
    label: 'Service Date',
    value: 'servicedate',
}];

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    textFieldPayer: {
        width: '95%',
        marginLeft: theme.spacing(1),
    },
    autoComplete: {
        width: '90%',
        marginLeft: theme.spacing(1),
    },
    textField: {
        width: '90%',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    alphaField: {
        width: '80%',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    switchFormControl: {
        marginLeft: 0,
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
    },
    checkboxlabel: {
        marginLeft: 0
    },
    formControl: {
        marginLeft: theme.spacing(1),
        width: '90%',
        marginTop: theme.spacing(1),
    },
    claimAmountHeading: {
        color: '#646c9a',
        margin: 0
    }
}));

export default function PayerContractAnalysisDialog(props) {
    const {
        payerContract, history,
        open, handleClose, intl, initialValues, claimAnalysisCountPending,
        claimAnalysisExecutePending, claimsCount,
        actions: { claimExecuteCount, claimExecute }
    } = props;
    const classes = useStyles();
    const [openExecuteConfirmationDialog, SetOpenExecuteConfirmationDialog] = useState(false);
    const [executionPayload, setExecutionPayload] = useState({});
    const [claimCount, setClaimCount] = useState(0);


    function handleCallback({ count }) {
        setClaimCount(count);
        if (count === 0) {
            errorToastify('No Claims Found, please try different filter.')
            return;
        }
        SetOpenExecuteConfirmationDialog(true);
    }

    function handleConfirmationClose() {
        SetOpenExecuteConfirmationDialog(false);
    }

    function handleConfirmExecution() {
        SetOpenExecuteConfirmationDialog(false);
        claimExecute(executionPayload, handleExecuteConfirmation);
    }

    function handleExecuteConfirmation({ id }) {
        history.push(`/cms/executions?executionId=${id}`);
    }

    function handleDialogClose() {
        handleClose();
    }

    const setDates = (setFieldValue) => (startDate, endDate, isEnabled, type) => {
        setFieldValue('dateType', type);
        if (type === 'remitdate') {
            setFieldValue('startRemitDate', startDate);
            setFieldValue('endRemitDate', endDate);
        } else if (type === 'servicedate') {
            setFieldValue('startServiceDate', startDate);
            setFieldValue('endServiceDate', endDate);
        }
    }

    function hoverColumn(text) {
        return (
            <PopoverLink
                columnData={<i className={`${classes.helpIcon} fas fa-question-circle text-info`}></i>}
                hoverData={[
                    {
                        data: text
                    }
                ]}
            />
        );
    }

    return (
        (<Dialog maxWidth="md" fullWidth open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Process Claims</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={claimAnalysisCountPending || claimAnalysisExecutePending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};
                            if (!values.visitType) {
                                errors.visitType = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (values.claimAmount && !values.equator) {
                                errors.equator = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (values.equator === 'alpha_between') {
                                if (values.equator && values.equator === 'alpha_between' && !values.startsAt) {
                                    errors.startsAt = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                                if (values.equator && values.equator === 'alpha_between' && !values.endsAt) {
                                    errors.endsAt = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                            } else if (values.equator && !values.claimAmount) {
                                errors.claimAmount = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            let payload = {
                                payerContractId: payerContract?.id,
                                visitType: values.visitType,
                                equator: values.equator,
                                svcRevenuecode: values.svcRevenuecode,
                                pxcode: values.pxcode,
                                drg: values.drg,
                                patientlastName: values.patientlastName,
                                claimAmount: values.equator === 'alpha_between' ? `${values.startsAt}&${values.endsAt}` : values.claimAmount,
                                noListed: values.noListed
                            }
                            if (values.dateType === 'remitdate') {
                                payload = {
                                    ...payload,
                                    startRemitDate: values.startRemitDate,
                                    endRemitDate: values.endRemitDate,
                                }
                            } else if (values.dateType === 'servicedate') {
                                payload = {
                                    ...payload,
                                    startServiceDate: values.startServiceDate,
                                    endServiceDate: values.endServiceDate,
                                }
                            }

                            setExecutionPayload(payload);
                            claimExecuteCount(payload, handleCallback);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                <div className={classes.root}>
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                label="Payer Contract"
                                                name="payerContract"
                                                className={classes.textFieldPayer}
                                                margin="normal"
                                                disabled
                                                value={payerContract?.displayName}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="visitType">Visit Type</InputLabel>
                                                <Select
                                                    value={values.visitType}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.visitType && errors.visitType)}
                                                    input={<Input id="visitType" />}
                                                    inputProps={{
                                                        name: "visitType",
                                                        id: "visitType"
                                                    }}
                                                >
                                                    <MenuItem value='inpatient'>Inpatient</MenuItem>
                                                    <MenuItem value='outpatient'>Outpatient</MenuItem>
                                                    <MenuItem value='professional'>Professional</MenuItem>
                                                    <MenuItem value="all">All</MenuItem>
                                                </Select>
                                                <FormHelperText error>{touched.visitType && errors.visitType}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DateRangeControl
                                                filterName="Remit/Service Date"
                                                setDates={setDates(setFieldValue)}
                                                hasCustomField={true}
                                                fields={filterFields}
                                                style={{ marginLeft: '8px', marginTop: '8px', width: '90%' }}
                                                defaultControlEnabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="CPT Code"
                                                name="pxcode"
                                                className={classes.textField}
                                                margin="normal"
                                                value={values.pxcode}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.pxcode && errors.pxcode}
                                                error={Boolean(touched.pxcode && errors.pxcode)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="DRG"
                                                name="drg"
                                                className={classes.textField}
                                                margin="normal"
                                                value={values.drg}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.drg && errors.drg}
                                                error={Boolean(touched.drg && errors.drg)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Rev Code"
                                                name="svcRevenuecode"
                                                className={classes.textField}
                                                margin="normal"
                                                value={values.svcRevenuecode}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.svcRevenuecode && errors.svcRevenuecode}
                                                error={Boolean(touched.svcRevenuecode && errors.svcRevenuecode)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField
                                                label="Patient Last Name"
                                                name="patientlastName"
                                                className={classes.textField}
                                                margin="normal"
                                                value={values.patientlastName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.patientlastName && errors.patientlastName}
                                                error={Boolean(touched.patientlastName && errors.patientlastName)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                className={classes.switchFormControl}
                                                control={
                                                    <Switch
                                                        name="noListed"
                                                        color="primary"
                                                        checked={values.noListed}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.noListed}
                                                    />
                                                }
                                                label="No Listed"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Card variant="outlined">
                                        <Card.Header> <h5 className={classes.claimAmountHeading}>Claim Amount</h5></Card.Header>
                                        <Card.Body>
                                            <Grid container>
                                                <Grid item xs={6} md={6} >
                                                    <FormControl className={classes.formControl}>
                                                        <InputLabel htmlFor="equator">Select Equator</InputLabel>
                                                        <Select
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.equator}
                                                            error={Boolean(touched.equator && errors.equator)}
                                                            inputProps={{
                                                                name: "equator",
                                                                id: "equator"
                                                            }}
                                                        >
                                                            {StringEquators.equatorArr.map(({ value, label }) => (
                                                                <MenuItem
                                                                    key={label}
                                                                    value={value}
                                                                >
                                                                    {label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText error>{touched.equator && errors.equator}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                {values.equator === 'alpha_between' ? <>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            type='number'
                                                            name="startsAt"
                                                            className={classes.alphaField}
                                                            label='Claim Amount Starts at'
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.startsAt}
                                                            helperText={touched.startsAt && errors.startsAt}
                                                            error={Boolean(touched.startsAt && errors.startsAt)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            type='number'
                                                            name="endsAt"
                                                            className={classes.alphaField}
                                                            label='Claim Amount Ends at'
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.endsAt}
                                                            helperText={touched.endsAt && errors.endsAt}
                                                            error={Boolean(touched.endsAt && errors.endsAt)}
                                                        />
                                                    </Grid>
                                                </> :
                                                    <Grid item xs={6} md={6}>
                                                        <TextField
                                                            type='number'
                                                            label="Claim Amount"
                                                            name="claimAmount"
                                                            className={classes.textField}
                                                            margin="normal"
                                                            value={values.claimAmount}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            helperText={touched.claimAmount && errors.claimAmount}
                                                            error={Boolean(touched.claimAmount && errors.claimAmount)}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">
                                                                    <span>
                                                                        {hoverColumn('Only single numeric value is allowed')}
                                                                    </span>
                                                                </InputAdornment>
                                                            }}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Card.Body>
                                    </Card>
                                    <hr />
                                    <Grid container justifyContent="flex-end">
                                        <Button
                                            onClick={handleDialogClose}
                                            variant="contained"
                                            className={classes.button}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            type="submit"
                                            disabled={claimAnalysisCountPending || claimAnalysisExecutePending}
                                        >
                                            {(claimAnalysisCountPending || claimAnalysisExecutePending) ? 'Processing...' : 'Process'}
                                        </Button>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
            <Dialog open={openExecuteConfirmationDialog} onClose={handleConfirmationClose}>
                <DialogTitle>
                    Process Claims?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Are you sure you want to process ${claimCount} claims?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmationClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmExecution}
                        color="primary">
                        Process
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>)
    );
}