import React from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { toAbsoluteUrl } from "../../../../../_metronic";
import { Button, Grid, Paper } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    grid: {
        margin: '0 auto',
        padding: '6% 2rem 1rem'
    },
    backButton: {
        color: "#5d78ff !important",
        padding: "0",
        "&:focus": {
            backgroundColor: 'white'
        }
    }
}));


export default function ResetPasswordConfirmation({ history, verifyTokenError, resetPasswordError, verifyTokenErrorMessage, resetPasswordErrorMessage }) {

    const classes = useStyles();

    const handleBack = () => {
        history.goBack();
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid className={classes.grid} item xs={6}>
                    <Paper className={classes.paper}>
                        <div className="kt-login__logo">
                            <a href="/#">
                                <img alt="logo" src={toAbsoluteUrl("/media/logos/logo-mini-md.v3.png?v=4")} />
                            </a>
                        </div>
                        <div className="kt-login__head">
                            <div className="kt-login__title py-4">
                                <h3>
                                    {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
                                    {(resetPasswordError || verifyTokenError) && (<FormattedMessage id="AUTH.PASSWORD.ERROR" />)}
                                    {(!resetPasswordError && !verifyTokenError) && (<FormattedMessage id="AUTH.PASSWORD.SUCCESS" />)}
                                </h3>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            {(resetPasswordError || verifyTokenError) && (
                                <div className="col-lg-12">
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text">{ verifyTokenError ? verifyTokenErrorMessage : resetPasswordError? resetPasswordErrorMessage : ''}</div>
                                    </div>
                                    {verifyTokenError && 
                                        <Link
                                            to="/auth/login"
                                            className="kt-link"
                                        >
                                            Back to Login
                                        </Link>
                                    }
                                    {resetPasswordError && 
                                        <Button
                                            onClick={handleBack}
                                            variant="text"
                                            className={classes.backButton}
                                        >
                                            Back
                                        </Button>
                                    }
                                </div>
                            )}
                            {(!resetPasswordError && !verifyTokenError) && (
                                <div className="col-lg-12">
                                    <div role="alert" className="alert alert-success">
                                        <div className="alert-text">Password has been change successfuly</div>
                                    </div>
                                    <Link
                                        to="/auth/login"
                                        className="kt-link"
                                    >
                                        Back to Login
                                    </Link>

                                </div>
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}