import {
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Typography,
    
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete } from '@mui/material';
import { Formik } from "formik";
import React, { useEffect,useState } from "react";
import { Loadable } from "../../../common";
import { ContractsSearchComponent } from "../ContractsSearchComponent";

const useStyles = makeStyles(theme => ({
    textField: {
        width: "85%"
    },
    formControlAuto: {
        marginTop: theme.spacing(5),
        width: "85%"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    dropZone: {
        minHeight: 150,
        border: "none",
        backgroundColor: "#EAEAEA",
        borderRadius: "20px"
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: "100%"
    },
    contractSelection:{
        '& .MuiAutocomplete-endAdornment':{display:"none"}
    },
    selectionAutocomplete:{
        cursor:"pointer",
        width:"100%"
    },
    selectionIcon:{
        width:"100%",
        margin:'0 !important',
        padding:'0 !important' 
    },
    selectionIconButton:{
        width:"100%",
        '&:hover': {
        background: 'none !important',
    },

    '& .MuiIconButton-label':{
        width:"100% !important",
        display:"block"
    },

     },
     
     contractsChip:{
        margin:"0 3px 5px 0",
    }
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function UploadDialog(props) {
    const {
        intl,
        open,
        handleClose,
        uploadFilePending,
        initialValues,
        tinyContracts,
        handleCancel,
        actions: {getTinyContracts ,createContractFamily,resetSelectedContracts,setSelectedContracts}
    } = props;
    const classes = useStyles();
    const [isSliced, setIsSliced] = useState(true)

    useEffect(() => {
        getTinyContracts();

        return () => {
            resetSelectedContracts()
        };
    }, [getTinyContracts]);

    const returnContractsMappingData = (data) => {
        return isSliced ?  data.slice(0, 2) : data
      }

    return (
        (<Dialog
                maxWidth="md"
                fullWidth
                open={open}
                onClose={handleCancel}
                aria-labelledby="form-dialog-title"
            >
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            Add Contract Family
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleCancel}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={uploadFilePending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors:any = {};

                            if (!values.name) {
                                errors.name = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (values.name.length>250) {
                                errors.name = "Contract family name should not exceed 250 characters.";
    
                            }    

                            if (values.contracts.length < 2) {
                                errors.contracts = "Select at least two contracts.";
                            }
                            

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {

                           const contractFamilyPrecedence=values?.contracts.map((item,index)=>({contractId:item?.id,precedence:index}))
                           const payload={
                            "name": values?.name,
                            "description": values?.description,
                            "active": true,
                            "contractFamilyXref": contractFamilyPrecedence

                           }
                            createContractFamily(payload,handleClose)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            label="Name"
                                            name="name"
                                            className={classes.textField}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            helperText={
                                                touched.name && errors.name
                                            }
                                            error={Boolean(
                                                touched.name && errors.name
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                        
                                            label="Description"
                                            name="description"
                                            className={classes.textField}
                                            margin="normal"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.description}
                                            helperText={
                                                touched.description &&
                                                errors.description
                                            }
                                            error={Boolean(
                                                touched.description &&
                                                    errors.description
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl
                                            className={classes.formControlAuto}
                                        >
                                            <ContractsSearchComponent isMulti={true} handleSetContract={(selected) =>
                                                setFieldValue(
                                                    "contracts",
                                                    [...tinyContracts]
                                                        .filter((item) => selected?.includes(String(item?.id)))
                                                        .sort(
                                                            (a, b) =>
                                                                selected.indexOf(String(a?.id)) - selected.indexOf(String(b?.id))
                                                        )
                                                )
                                            }
                                                chipBoxClass={classes.selectionIcon} iconButtonClass={classes.selectionIconButton} icon={<Autocomplete

                                                    onKeyDown={(e) => { e.preventDefault(); }}
                                                    className={`${classes.selectionAutocomplete}`}

                                                    // filterOptions={filterOptions}
                                                    onChange={(obj, updatedContracts, event) => {
                                                        if (event === "clear") {
                                                            setFieldValue("contracts", [])
                                                            resetSelectedContracts()
                                                        }
                                                        if (event === "removeOption") {
                                                            setFieldValue("contracts", updatedContracts)
                                                            setSelectedContracts(updatedContracts?.map((item) => String(item?.id)))

                                                        }
                                                    }}
                                                    open={false}
                                                    value={values.contracts}
                                                    multiple
                                                    options={tinyContracts}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => option.name}
                                                    renderOption={(option:any, { selected }) => (
                                                        <React.Fragment>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option?.name}
                                                        </React.Fragment>
                                                    )}
                                                    renderTags={(tagValue, getTagProps) => {
                                                        const numTags = tagValue.length;
                                                        return (
                                                            <Typography variant="caption">
                                                                {returnContractsMappingData(tagValue)?.map((option, index) => (
                                                                    <Chip
                                                                        size="small"
                                                                        color="secondary"
                                                                        key={index}
                                                                        label={option.name}
                                                                        {...getTagProps({ index })}
                                                                    // className={classes.contractsChip}
                                                                    />
                                                                ))}
                                                                {numTags > 2 && <Chip data-testid="CloseIcon" size="small" sx={{cursor:'pointer'}} onClick={()=>setIsSliced(!isSliced)} label={isSliced ? ` +${numTags - 2}` : '...'}/> }
                                                            </Typography>
                                                        )
                                                    }
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Contracts"

                                                            error={Boolean(touched.contracts && errors.contracts)}
                                                            helperText={touched.contracts && errors.contracts}
                                                        />
                                                    )}
                                                />} />



                                            {/* <Autocomplete
                                                onChange={(e, value) =>
                                                    setFieldValue(
                                                        "contracts",
                                                        value
                                                    )
                                                }
                                                value={values.contracts}
                                                multiple
                                                options={tinyContracts}
                                                disableCloseOnSelect
                                                getOptionLabel={option =>
                                                    option.name
                                                }
                                                renderOption={(
                                                    props,
                                                    option,
                                                    { selected }
                                                ) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={
                                                                checkedIcon
                                                            }
                                                            style={{
                                                                marginRight: 8
                                                            }}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                    </li>
                                                )}
                                                renderTags={(
                                                    tagValue,
                                                    getTagProps
                                                ) => {
                                                    const numTags =
                                                        tagValue.length;
                                                    return (
                                                        <Typography variant="caption">
                                                            {tagValue
                                                                .slice(0, 2)
                                                                .map(
                                                                    (
                                                                        option,
                                                                        index
                                                                    ) => (
                                                                        <Chip
                                                                            size="small"
                                                                            color="secondary"
                                                                            key={
                                                                                index
                                                                            }
                                                                            label={
                                                                                option.name
                                                                            }
                                                                            {...getTagProps(
                                                                                {
                                                                                    index
                                                                                }
                                                                            )}
                                                                        />
                                                                    )
                                                                )}
                                                            {numTags > 2 &&
                                                                ` + ${numTags -
                                                                    2}`}
                                                        </Typography>
                                                    );
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                    
                                                        {...params}
                                                        label="Contracts"
                                                        error={Boolean(touched.contracts && errors.contracts)}
                                                        helperText={touched.contracts && errors.contracts}
                                                    />
                                                )}
                                            /> */}
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        container
                                        className="justify-content-end pt-2"
                                    >
                                        <Button type="button" onClick={handleCancel} variant="contained" className="mr-3">
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            Create
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>)
    );
}

export default UploadDialog;
