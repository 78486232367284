import {
    METHOD_PUT,
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_DELETE,
} from '../constants/api';
import {
    headersAuthSendReceiveJson, headersAuthReceiveJson,
    headersAuthReceiveOctet,
    headersAuth
} from '../headers';
import { apiHandleOctetResponseWithFileName, apiHandleResponse } from '../../util/api';

const getPayers = (options = null) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payers`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const updatePayerStatus = (id, enabled) => {
    return fetch(`${SERVER_URL}/api/v1/payer/status`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify({ id, enabled }),
    }).then(apiHandleResponse);
};

const createPayer = (payer) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payer`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payer)
    }).then(apiHandleResponse);
};

const updatePayer = (payer) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payer`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payer)
    }).then(apiHandleResponse);
};

const deletePayers = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payers`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const updateBulkPayer = (payer) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payers`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payer)
    }).then(apiHandleResponse);
};

const createPayerContract = (payerContract) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payercontract`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payerContract)
    }).then(apiHandleResponse);
};

const updatePayerContract = (payerContract) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payercontract`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payerContract)
    }).then(apiHandleResponse);
};

const selectDeselectAll = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/payer/status/all`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payload)
    }).then(apiHandleResponse);
};

const listPayerContracts = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payercontracts`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getPayerContractsTiny = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/payercontracts/tiny`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getPayersTiny = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/payers/tiny`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getPayersScoreCard = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payerscorecards`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const deletePayerContracts = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payercontracts`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const importPayers = () => {
    return fetch(`${SERVER_URL}/api/v2/cms/payers/import`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        //body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getRemitPayers = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/remitpayers/list`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const importRemitPayers = () => {
    return fetch(`${SERVER_URL}/api/v2/cms/remitpayers/import`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        // body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const remitPayersStatusAll = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/remitpayer/status/all`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const remitPayersCreate = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/remitpayer`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const remitPayersUpdate = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/remitpayer`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const remitPayersDelete = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/remitpayers`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};


const remitPayersBulkUpdate = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/remitpayers`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const remitPayerToleranceMatch = (options = null) => {
    return fetch(`${SERVER_URL}/api/v1/cms/remitpayers/tolerancematch`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const mapRemitPayer = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/remitpayers`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getPayersDependency = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payers/dependencies`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getRemitPayersDependency = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/remitpayers/dependencies`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const deleteRemitPayers = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/remitpayers`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getPayerContractDependency = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payercontracts/dependencies`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const listPayerPlans = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/payerplans`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getPayerPlansTiny = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/payerplans/tiny`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getPayerPlan = (planId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payerplan/${planId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createPayerPlan = (payerPlan) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payerplan`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payerPlan)
    }).then(apiHandleResponse);
};

const updatePayerPlan = (payerPlan) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payerplan`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(payerPlan)
    }).then(apiHandleResponse);
};

const deletePayerPlan = (planId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payerplan/${planId}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getPayerPlanDependency = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/payerplan/dependencies/${id}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const exportPayers = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/payers/export`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options)
    }).then(apiHandleOctetResponseWithFileName);
};

const exportRemitPayers = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/remitpayers/export`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options)
    }).then(apiHandleOctetResponseWithFileName);
};

const getPayersStats = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/payer/stats`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getRemitPayersStats = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/remitpayer/stats`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getClientFilesStats = (options, payload) => {
    const { clientId } = payload
    return fetch(`${SERVER_URL}/api/v2/statistics/clientfilesfailurestatistics?clientId=${clientId}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};
const getStlLoadErrors = (options) => {
    return fetch(`${SERVER_URL}/api/v2/statistics/stlloaderrors`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const checkContractsAssociation = (ids, payerContractId) => {
    return fetch(payerContractId ? `${SERVER_URL}/api/v2/cms/payercontract/checkassociation/${payerContractId}` : `${SERVER_URL}/api/v2/cms/payercontract/checkassociation`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(ids),
    }).then(apiHandleResponse);
};

const getPayerIdMappingList = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/payers${options?.filter?.search ? '/tiny':''}`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getPayerIdMapping = (payerIdMappingId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/payers/${payerIdMappingId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getPayerIdMappingTiny = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/payers/tiny`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const createPayerIdMapping = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/payer`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const editPayerIdMapping = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/payer`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const bulkEditPayerIdMapping = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/payers`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getPayerIdMappingDependency = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/payer/dependencies/${id}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const deletePayerIdMapping = (id) => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/payer/${id}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const uploadPayerIdMapping = (file) => {
    const formData = new FormData();
    formData.append('estimatorPayersCsv', file);
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/payers/upload`, {
        method: METHOD_POST,
        headers: headersAuth(),
        body: formData,
    }).then(apiHandleResponse);
};

const downloadPayerIdTemplate = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/estimator/payers/export`, {
        method: METHOD_GET,
        headers: headersAuthReceiveOctet(),
    }).then(apiHandleOctetResponseWithFileName);
};

export {
    getPayers,
    updatePayerStatus,
    createPayer,
    updatePayer,
    selectDeselectAll,
    listPayerContracts,
    createPayerContract,
    updatePayerContract,
    getPayerContractsTiny,
    getPayersTiny,
    getPayersScoreCard,
    updateBulkPayer,
    deletePayerContracts,
    importPayers,
    getRemitPayers,
    importRemitPayers,
    remitPayersStatusAll,
    remitPayersCreate,
    remitPayersUpdate,
    remitPayersBulkUpdate,
    remitPayerToleranceMatch,
    mapRemitPayer,
    getPayersDependency,
    deletePayers,
    remitPayersDelete,
    getRemitPayersDependency,
    deleteRemitPayers,
    getPayerContractDependency,
    listPayerPlans,
    getPayerPlansTiny,
    getPayerPlan,
    createPayerPlan,
    updatePayerPlan,
    deletePayerPlan,
    getPayerPlanDependency,
    exportPayers,
    exportRemitPayers,
    getPayersStats,
    getRemitPayersStats,
    getClientFilesStats,
    checkContractsAssociation,
    getStlLoadErrors,
    getPayerIdMappingList,
    getPayerIdMapping,
    createPayerIdMapping,
    editPayerIdMapping,
    bulkEditPayerIdMapping,
    getPayerIdMappingDependency,
    deletePayerIdMapping,
    uploadPayerIdMapping,
    downloadPayerIdTemplate,
    getPayerIdMappingTiny
};