import React, { useEffect } from 'react';
import { Portlet } from '../../../../../partials/content/Portlet';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AnalysisResultsDialog } from '../AnalysisResultsDialog';
import { BasicDetailsSection } from '../../../../ClaimAnalysisPage/ClaimViewPage/BasicDetailsSection';

const useStyles = makeStyles(theme => {
    return {
        portlet: {
            'box-shadow': 'none',
        },
        lineItemGrid: {
            padding: '0px 24px',
        },
        lineItemBox: {
            padding: '0 24px 24px 24px',
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
            maxHeight: '500px',
            overflowX: 'hidden',
            overflowY: 'auto',
            marginBottom: '15px'
        },
    }
});

function ModelResultsDetailsPage(props) {

    const { claimIds, 
        actions: { getModelAllDetails, modelAnalysisLanding }} = props;

    const classes = useStyles();

    useEffect(() => {
        getModelAllDetails(claimIds);

        modelAnalysisLanding({ analysisIds: claimIds });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimIds]);


    const refreshLineItems = () => {
        modelAnalysisLanding({ analysisIds: claimIds });
    }

    return (
        <>
            <Portlet className={classes.portlet}>

                <BasicDetailsSection isFromModel={true} claimId={claimIds} />

                <Grid className={classes.lineItemGrid}>
                    <Grid className={classes.lineItemBox}>
                        <AnalysisResultsDialog refreshLineItems={refreshLineItems} />
                    </Grid>
                </Grid>
            </Portlet>
        </>
    )
}

export default ModelResultsDetailsPage
