import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { set, setMulti } from '../../store/actions/pages';
import { createSelector } from 'reselect';
import { injectIntl } from "react-intl";
import { selectSearches } from '../../store/selectors/search';

import SearchTitleCompoment from './SearchTitle';

export const SearchTitle = connect(
    () => {

        const selectPageName:any = (state, props) => props.name;

        const selectTable = createSelector(
            (state, props) => props.name,
            (state) => state.pages,
            (name:any, pages) => {
                return pages[name];
            }
        );

        const selectRawFilters = createSelector(
            selectTable,
            (table) => table.filter,
        );

        const selectSelectedFilter = createSelector(
            selectTable,
            (table) => table.selectedFilter,
        );

        const selectIsSearchAPICompleted = createSelector(
            selectTable,
            (table) => table.isSearchApiCompleted,
        );

        const selectPending = createSelector(
            (state, props) => props.name,
            (state) => state.pages,
            (state) => state.search.getByName.pending,
            (name:any, pages, searchPending) => {
                return pages[name].pending || searchPending
            }
        );

        return (state:any, props) => ({
            searchFilters: selectSearches(state),
            rawFilters: selectRawFilters(state, props),
            name: selectPageName(state, props),
            isSearchApiCompleted: selectIsSearchAPICompleted(state, props),
            selectedFilter: selectSelectedFilter(state, props),
            pending: selectPending(state, props),
            tableFilters:selectTable(state,props),
            userSession:state.session.user.id
        });
    },
    (dispatch, props:any) => ({
        actions: bindActionCreators({
            setFilter: (value) => set(props.name, 'filter', value),
            setSelectedFilter: (value) => set(props.name, 'selectedFilter', value),
            setWaitForCriteriaFilter: (value) => set(props.name, 'waitForCriteriaFilter', value),
            setMulti: obj => setMulti(props.name, obj),
        }, dispatch)
    })
)(injectIntl(SearchTitleCompoment));