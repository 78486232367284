import React, { useEffect, useState } from 'react';
import {
    Portlet, PortletBody, PortletHeader
} from "../../partials/content/Portlet";
import { Grid, IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PagedTable } from '../../common/pagedTable';
import Helmet from 'react-helmet';
import { PayerContractDialog } from './PayerContractDialog';
import { PayerContractAnalysisDialog } from './PayerContractAnalysisDialog';
import { Edit as EditIcon } from '@mui/icons-material';
import DeleteConfirmation from '../../common/DeleteConfirmation/DeleteConfirmation';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { DependencyDialog } from './DependencyDialog';
import { ROLE_ADMIN, ROLE_PARTNER_ADMIN, ROLE_SUPERADMIN } from '../../store/constants/roles';

const DEFAULT_SORT = [{ id: 'displayName', direction: 'asc' }];

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    banIcon: {
        fontSize: 17,
        // color: '#d9534f'
    }
}));

export default function PayerContracts(props) {
    const [open, setOpen] = useState(false);
    const [openAnalysisDialog, setOpenAnalysisDialog] = useState(false);
    const [payerpayerContract, setPayerpayerContract] = useState(null);
    const classes = useStyles();

    const {
        hasClaimProcessorAccess,
        data, fields,
        history,
        bulkDeletePending, userRole,
        actions: {
            payerContractsLanding,
            payerContractEditReset,
            deleteClaimByPayerContractId,
            setSelected,
            setPayerContractDependencyDialog
        }
    } = props;

    const [refresh, setRefresh] = useState(Promise.resolve());

    useEffect(() => {
        return () => {
            setSelected([]);
        }
    }, [setSelected])

    const handleAddPayer = () => {
        setOpen(true);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
        setSelected([]);
    }

    const handleClose = (shouldReloadGrid) => {
        setOpen(false);
        setPayerpayerContract(null);
        payerContractEditReset();
        if (shouldReloadGrid === true) {
            handleRefresh();
        }
    }

    function handleLoad(sort, filter, pagination) {
        payerContractsLanding(pagination, sort, filter);
    }

    const handlePayerContractEdit = (payerpayerContract) => {
        setPayerpayerContract(payerpayerContract);
        setOpen(true);
    }

    const handlePlayConfirmation = (payerpayerContract) => {
        setPayerpayerContract(payerpayerContract);
        setOpenAnalysisDialog(true);
    }

    function handleAnalysisClose() {
        setOpenAnalysisDialog(false);
        setPayerpayerContract(null);
    }

    function payerContractsActions(rowData) {
        return (
            <div className='d-flex'>
                <IconButton
                    color="primary"
                    className="p-1"
                    aria-label="Edit"
                    onClick={() => handlePayerContractEdit(rowData)}
                    size="large">
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
                {(hasClaimProcessorAccess) && (
                    <IconButton
                        color="primary"
                        aria-label="Process Claims"
                        onClick={() => handlePlayConfirmation(rowData)}
                        size="large">
                        <Tooltip title="Process Claims">
                            <PlayCircleOutlineIcon />
                        </Tooltip>
                    </IconButton>
                )}
                <DeleteConfirmation tooltip='Delete Analyses' handleDelete={() => handlePayerContractDelete(rowData)} customIcon={<i className={`${classes.banIcon} ml-1 fas fa-ban`}></i>} />
            </div>
        );
    }

    const handlePayerContractDelete = (payerContract) => {
        deleteClaimByPayerContractId(payerContract.id, handleRefresh)
    }

    const handleBulkDelete = () => {
        setPayerContractDependencyDialog({ open: true });
    }

    const payerContractsTableLevelAction = () => {
        return (
            <>
                {(userRole === ROLE_SUPERADMIN || userRole === ROLE_ADMIN || userRole === ROLE_PARTNER_ADMIN) &&
                    <Grid item>
                        <Tooltip title="Delete Claim Payers">
                            <IconButton aria-label="delete" size='small'
                                onClick={handleBulkDelete}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </>
        );
    }

    return (
        <>
            <Helmet title="Payer Contracts" />
            <Portlet>
                <PortletHeader
                    title="Payer Contracts"
                    name="payers-contracts-landing"
                />
                <PortletBody>
                    <Grid container>
                        <Grid item md={12}>
                            <PagedTable
                                name="payers-contracts-landing"
                                fields={fields}
                                data={data}
                                onLoad={handleLoad}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                onAdd={handleAddPayer}
                                // onPlay={hasClaimProcessorAccess ? handlePlayConfirmation : undefined}
                                title="payer contract"
                                tableLevelActions={payerContractsTableLevelAction}
                                tableLevelLoading={bulkDeletePending}
                                hasCheckbox={true}
                                showSelectAll={true}

                                hasActions={true}
                                renderActionColumn={payerContractsActions}
                            />
                        </Grid>
                    </Grid>
                </PortletBody>
                <PayerContractDialog payerContract={payerpayerContract} open={open} handleClose={handleClose} />
                <PayerContractAnalysisDialog history={history} payerContract={payerpayerContract} open={openAnalysisDialog} handleClose={handleAnalysisClose} setOpenAnalysisDialog={setOpenAnalysisDialog} />
                <DependencyDialog handleClose={handleClose} />
            </Portlet>
        </>
    );
}