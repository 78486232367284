import React, { useEffect } from 'react'
import { Loadable } from '../../../common';
import { Formik } from 'formik';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    Switch,
    FormControlLabel
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    textField: {
        width: '100%',
    },
    formControl: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    switch: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
}));

function ClaimStatusEditPage({ open, handleClose, mode, intl, initialValues, createPending, editPending,
    actions: { createClaimStatus, editClaimStatus, createEditReset } } ) {

    const classes = useStyles();

    useEffect(() => {
        return function cleanup() {
            createEditReset();
        };
    }, [createEditReset]);

    function handleCallback(reload) {
        handleClose(reload);
    }

    return (<>
        {open && (
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{mode === 'edit' ? 'Edit Claim Status' : 'Add Claim Status'}</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={createPending || editPending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors: any = {};
                            
                            if (!values.name) {
                                errors.name = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            let shouldReloadGrid = true;
                            let payload = {
                                name: values.name,
                                preRecovery: values.preRecovery,
                                id: undefined
                            }
                            if (mode === 'create') {
                                createClaimStatus(payload, handleCallback, shouldReloadGrid);
                            }
                            else {
                                payload = {
                                    ...payload,
                                    id: values.id,
                                }
                                editClaimStatus(payload, handleCallback, shouldReloadGrid);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <div>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                label="Name"
                                                name="name"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                helperText={touched.name && errors.name}
                                                error={Boolean(touched.name && errors.name)}
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                control={
                                                <Switch
                                                    name="preRecovery"
                                                    color="primary"
                                                    className={classes.switch}
                                                    checked={values.preRecovery}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.preRecovery}
                                                />
                                                }
                                                label="Pre-Recovery"
                                            />
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid container className='justify-content-end'>
                                        <Button onClick={handleClose} className={classes.switch} variant="contained">
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className={classes.button}
                                            disabled={createPending || editPending}
                                        >
                                            {(createPending || editPending) ? 'Saving Changes' : 'Save'}
                                        </Button>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
        )}
    </>);
}

export default ClaimStatusEditPage
