import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, Alert, DialogActions, Button, Typography } from '@mui/material';

export const IgnoreFiltersAcknowledgementDialog = ({ open, intl,setclaimSearchIgnoredDialogStatus }) => {
    return <Dialog open={open}
        maxWidth="xs"
        fullWidth
    // onClose={handleSyncCallbackDialogClose}
    >
        <DialogTitle sx={{ color: "deeppink" }}>
            Search All Claims
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <Typography>
                    Search all Analyzed Claims ?
                </Typography>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
            onClick={()=>setclaimSearchIgnoredDialogStatus({showIgnoredFilterDialog:false,ignoreAcknowledgement:true})}
            >
                OK
            </Button>
            <Button
            onClick={()=>setclaimSearchIgnoredDialogStatus({showIgnoredFilterDialog:false})}
            >
                Cancel
            </Button>
        </DialogActions>
    </Dialog>
}