import sagaMiddleware from 'redux-saga';
import { fork } from 'redux-saga/effects';

export const reduxSagaMiddleware = sagaMiddleware();

export function initSagas() {
    reduxSagaMiddleware.run(function* sagas() {
        yield fork(require('./sagas/session').default);
        yield fork(require('./sagas/clients').default);
        yield fork(require('./sagas/users').default);
        yield fork(require('./sagas/roles').default);
        yield fork(require('./sagas/dashboards').default);
        yield fork(require('./sagas/dashboardFilters').default);
        yield fork(require('./sagas/forgotPassword').default);
        yield fork(require('./sagas/statistics').default);
        yield fork(require('./sagas/dashboardDataSources').default);
        yield fork(require('./sagas/dashboardTypes').default);
        yield fork(require('./sagas/pages').default);
        yield fork(require('./sagas/partners').default);
        yield fork(require('./sagas/contracts').default);
        yield fork(require('./sagas/rules').default);
        yield fork(require('./sagas/services').default);
        yield fork(require('./sagas/payers').default);
        yield fork(require('./sagas/claimAnalysis').default);
        yield fork(require('./sagas/settings').default);
        yield fork(require('./sagas/facilities').default);
        yield fork(require('./sagas/search').default);
        yield fork(require('./sagas/chargeMaster').default);
        yield fork(require('./sagas/shoppables').default);
        yield fork(require('./sagas/pricers').default);
        yield fork(require('./sagas/associated').default);
        yield fork(require('./sagas/allowables').default);
        yield fork(require('./sagas/brand').default);
        yield fork(require('./sagas/pricerPayers').default);
        yield fork(require('./sagas/feeScheduleItems').default);
        yield fork(require('./sagas/vendors').default);
        yield fork(require('./sagas/npiRoles').default);
        yield fork(require('./sagas/executions').default);
        yield fork(require('./sagas/modifiers').default);
        yield fork(require('./sagas/uploads').default);
        yield fork(require('./sagas/controlRoom').default);
        yield fork(require('./sagas/modeling').default);
        yield fork(require('./sagas/drg').default);
        yield fork(require('./sagas/opps').default);
        yield fork(require('./sagas/apikey').default);
        yield fork(require('./sagas/appId').default);

        yield fork(require('./sagas/eapg').default);
        yield fork(require('./sagas/feeScheduleV2').default);
        yield fork(require('./sagas/contractFamily').default);
        yield fork(require('./sagas/hospitalServices').default);
        yield fork(require('./sagas/contractsv2').default);
        yield fork(require('./sagas/claimStatus').default);

    });
}