import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";

import ReleaseSnackbarComponent from './ReleaseSnackbar';
import { selectRelease } from '../../store/selectors/settings';

export const ReleaseSnackbar = connect(
    () => {

        return (state, props) => ({
            release: selectRelease(state)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(ReleaseSnackbarComponent));