import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TablePagination,
    Button,
    Typography,
    Tooltip,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { DataTable, TablePaginationActions } from '../../../common';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function HistoricalImport(props) {
    const { open, importHospitalServicesPending, handleRefresh, fields, filteredData, 
        order, sort,
        actions: { importHospitalServicesFromHistorical, hospitalServicesImportErrorReset, setOrder, setSort,
            setHopsitalServicesImportDialog
        }
    } = props;

    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (open) {
            importHospitalServicesFromHistorical();
        }
        return function cleanup() {
            hospitalServicesImportErrorReset();
        };
    }, [open]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClose = () => {
        setHopsitalServicesImportDialog(null);
        handleRefresh();
    }

    function tableLevelActions() {
        return (
            <>
            </>
        );
    }


    return (
        (<Dialog fullWidth maxWidth='lg' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">Imported Hospital Services</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <DataTable
                    fields={fields}
                    data={filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    order={order}
                    onOrder={setOrder}
                    sort={sort}
                    onSort={setSort}
                    loading={importHospitalServicesPending}
                    tableLevelActions={tableLevelActions}
                    showPagination={true}
                    footerPagination={() => (
                        <TablePagination
                            rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                            colSpan={fields.length + 2}
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    )}
                />
            </DialogContent>
        </Dialog>)
    );
}