import {
    METHOD_GET,
    METHOD_POST,
    SERVER_URL,
} from '../constants/api';
import {
    headersReceiveJson,
    headersSendReceiveJson,
    headersAuthReceiveOctet,
    headersAuthSendReceiveOctet,
} from '../headers';
import { apiHandleResponse, apiHandleOctetResponse } from '../../util/api';

const lookup = (keyword) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/lookup`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify(keyword),
    }).then(apiHandleResponse);
};

const getCharges = (chargePayload) => {
    return fetch(`${SERVER_URL}/api/v2/pricer/associatedcharges`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify(chargePayload),
    }).then(apiHandleResponse);
};

const getAllowables = (chargeCode) => {
    return fetch(`${SERVER_URL}/api/v1/pricer/allowable/${chargeCode}`, {
        method: METHOD_GET,
        headers: headersReceiveJson(),
    }).then(apiHandleResponse);
};

const lookupV2 = (keyword) => {
    return fetch(`${SERVER_URL}/api/v2/pricer/lookup`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify(keyword),
    }).then(apiHandleResponse);
};

const getFacilities = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/facilities/active`, {
        method: METHOD_GET,
        headers: headersReceiveJson(),
    }).then(apiHandleResponse);
};

const getDepartments = (departmentPayload) => {
    return fetch(`${SERVER_URL}/api/v2/pricer/departments`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify(departmentPayload),
    }).then(apiHandleResponse);
};

const getNegotiatedRates = (negotiatedPayload) => {
    return fetch(`${SERVER_URL}/api/v4/pricer/negotiatedrates`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify(negotiatedPayload),
    }).then(apiHandleResponse);
};

const getAllowablesRates = (allowablePayload) => {
    return fetch(`${SERVER_URL}/api/v2/pricer/allowablerates`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify(allowablePayload),
    }).then(apiHandleResponse);
};

const getPayPercentage = (brandId) => {
    return fetch(`${SERVER_URL}​/api/v2/pricer/selfpaypercentage/${brandId}`, {
        method: METHOD_GET,
        headers: headersReceiveJson(),
    }).then(apiHandleResponse);
};

const exportCsv = () => {
    return fetch(`${SERVER_URL}/api/v1/pricer/report`, {
        method: METHOD_GET,
        headers: headersAuthReceiveOctet(),
    }).then(response => {
        const headers = response.headers;
        if (!response.ok) {
            return apiHandleResponse(response);
        }

        //getting file name
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = '';
        if (contentDisposition && contentDisposition.includes('attachment')) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        return response.blob().then(blob => {
            return { payload: blob, headers, filename };
        });
    });
};

const exportMachineReadbleFile = (exportPayload) => {
    return fetch(`${SERVER_URL}/api/v2/pricer/report`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveOctet(),
        body: JSON.stringify(exportPayload),
    }).then(apiHandleOctetResponse);
};

const getOop = (oopPayload) => {
    return fetch(`${SERVER_URL}/api/v2/pricer/oop`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify(oopPayload),
    }).then(apiHandleResponse);
};

const addBooking = (bookingPayload) => {
    return fetch(`${SERVER_URL}/api/v3/pricer/healthtalkai/request`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify(bookingPayload),
    }).then(apiHandleResponse);
};

const getChargeMasterStats = () => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/config/cdm/statistics`, {
        method: METHOD_GET,
        headers: headersReceiveJson(),
    }).then(apiHandleResponse);
};

const getShoppablesStats = () => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/config/shoppable/statistics`, {
        method: METHOD_GET,
        headers: headersReceiveJson(),
    }).then(apiHandleResponse);
};

const updateShoppableConfiguration = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/config/shoppable/chargemaster`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        // body: JSON.stringify(oopPayload),
    }).then(apiHandleResponse);
};

const getPricerConfigContracts = () => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/config/payercontracts/latest`, {
        method: METHOD_GET,
        headers: headersReceiveJson(),
    }).then(apiHandleResponse);
};

const processContractsForRates = (payload) => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/config/negotiatedrate/payercontracts`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const getConfigNegotiatedRates = (payload) => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/config/negotiatedrates/statistics`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const getNegotiatedRatesState = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/config/negotiatedrates/state`, {
        method: METHOD_GET,
        headers: headersReceiveJson(),
    }).then(apiHandleResponse);
};

const configCleanupAndProcess = (payload) => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/config/negotiatedrate/cleanprocess`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
        body: JSON.stringify(payload),
    }).then(apiHandleResponse);
};

const publishMRFs = () => {
    return fetch(`${SERVER_URL}/api/v2/cms/pricer/csv/refresh`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getNegotiatedRatesSignOff = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/config/negotiatedrates/signoff`, {
        method: METHOD_GET,
        headers: headersSendReceiveJson(),
    }).then(apiHandleResponse);
};

const negotiatedRatesSignOff = (clientId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/pricer/config/negotiatedrates/signoff`, {
        method: METHOD_POST,
        headers: headersSendReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    lookup,
    getCharges,
    getAllowables,
    // V2
    lookupV2,
    getFacilities,
    getDepartments,
    getNegotiatedRates,
    getAllowablesRates,
    getPayPercentage,
    exportCsv,
    getOop,
    addBooking,
    exportMachineReadbleFile,
    getChargeMasterStats,
    getShoppablesStats,
    updateShoppableConfiguration,
    getPricerConfigContracts,
    processContractsForRates,
    getConfigNegotiatedRates,
    getNegotiatedRatesState,
    configCleanupAndProcess,
    publishMRFs,
    getNegotiatedRatesSignOff,
    negotiatedRatesSignOff
};
