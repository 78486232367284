import { Snackbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import pkg from "../../../../package.json";
const UIVersion = pkg.version;

const useStyles = makeStyles(theme => ({
    button: {
        textDecoration: 'underline',
        color: '#2739c1',
        cursor: 'pointer',
        marginLeft: '3px',
        marginRight: '3px',
    },
    alert: {
        '& .MuiAlert-message': {
            display: 'flex',
            alignItems: 'center'
        },
        backgroundColor: '#EBFFF5'
    }
}));

function ReleaseSnackbar(props) {
    const { release } = props;
    const classes = useStyles();

    const [isSnackOpen, setSnackOpen] = useState(false);

    useEffect(() => {
        if (release && release.version) {
            const beOfficialReleaseVersion = release?.version?.split('.')[2];
            const feOfficialReleaseVersion = UIVersion?.split('.')[2];
            if (beOfficialReleaseVersion !== feOfficialReleaseVersion) {
                setSnackOpen(true);
            }
        }

    }, [release]);

    const handleRefresh = () => {
        if ('caches' in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });
        }
        window.location.reload();
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isSnackOpen}
            onClose={() => setSnackOpen(false)}
            key={'topcenter'}
        >

            <MuiAlert elevation={6} className={classes.alert} icon={false} onClose={() => setSnackOpen(false)} severity="info" >
                A new version of the app is detected. <Typography className={classes.button} onClick={handleRefresh}>Click here </Typography> to reload.
            </MuiAlert>
        </Snackbar>
    )
}

export default ReleaseSnackbar
