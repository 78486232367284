import React, { useEffect, useState } from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import { DataTable, DataFilter } from '../../../common';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DeleteConfirmation } from "../../../common/DeleteConfirmation";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  textField: {
    width: 400,
  },
}));

export default function DashboardDataSourcesPage(props) {
  const classes = useStyles();
  const search = useLocation().search;
  const persist = new URLSearchParams(search).get('persist');

  const {
    dashboardsFilteredData, fields, sort, dashboardDataSourceListPending, dashboardDataSourceDeletePending,
    order, filter, actions: { setFilter, setSort, setOrder, dashboardDataSourceList, dashboardDataSourceDelete }
  } = props;


  useEffect(() => {
    dashboardDataSourceList();
    if (!persist) {
      setFilter('');
    }
  }, [dashboardDataSourceList]);

  const handleDashboardEdit = (dashboard) => {
    props.history.push(`/admin/dashboardDataSources/edit/${dashboard.id}`);
  }

  const handleCallBack = () => {
    dashboardDataSourceList();
  }

  const handleDashboardDelete = (dashboard) => {
    if (dashboard) {
      dashboardDataSourceDelete(dashboard.id, handleCallBack);
    }
  }

  const handleSearchInput = (value) => {
    setFilter(value);
  }

  const handleAddDashboardDataSource = () => {
    props.history.push('/admin/dashboardDataSources/edit/new');
  }

  function dataSourceAction(rowData) {
    return (
      <div className='d-flex'>
        <IconButton
          color="primary"
          className='p-1'
          aria-label="Edit"
          onClick={() => handleDashboardEdit(rowData)}
          size="large">
          <Tooltip title="Edit">
            <EditIcon />
          </Tooltip>
        </IconButton>
        <DeleteConfirmation handleDelete={() => handleDashboardDelete(rowData)} />

      </div>
    );
  }

  function handleRefresh() {
    dashboardDataSourceList();
  }

  return (
    <>
      <Helmet title="Dashboard Configuration" />
      <Portlet>
        <PortletHeader
          title="DataSources"
          toolbar={
            <PortletHeaderToolbar>
              <DataFilter
                onChange={handleSearchInput}
                placeholder="Search"
                cssClass={classes.textField}
                filter={filter}
              />
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          <DataTable
            fields={fields}
            data={dashboardsFilteredData}
            onAdd={handleAddDashboardDataSource}
            order={order}
            onOrder={setOrder}
            sort={sort}
            onSort={setSort}
            loading={dashboardDataSourceListPending || dashboardDataSourceDeletePending}
            name="DataSoure"
            handleRefresh={handleRefresh}

            renderActionColumn={dataSourceAction}
            hasActions={true}
          />
        </PortletBody>
      </Portlet>
    </>
  );
}
