import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { flag } from '../../../store/actions/claimAnalysis';
import FlagDialogComponent from './FlagDialog';
import { setV2 } from '../../../store/actions/pagesV2';

export const FlagDialog = connect(
    () => {
        const selectClaimNumber = (state, props) => props.claimNumber;

        const selectDefaultFlag = (state, props) => state.claimAnalysis.flagToggle;
        const selectIsBulkFlagged = (state, props) => state.claimAnalysis.isBulkFlagged;

        const selectInitialValues = createSelector(
            (state, props) => props.selectedItemsPtr.value,
            selectDefaultFlag,
            (claimIds, defaultToggle) => {
                return {
                    analysisId: claimIds,
                    flag: defaultToggle,
                    followupAlert: '', //date field
                    setFollowUpDate: true // toggle for adding date field
                }
            }
        );

        return (state, props) => ({
            flagClaimPending: state.claimAnalysis.flag.pending,

            initialValues: selectInitialValues(state, props),
            claimNumber: selectClaimNumber(state, props),
            isBulkFlagged: selectIsBulkFlagged(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            flagClaims: flag,
        }, dispatch)
    })
)(injectIntl(FlagDialogComponent));