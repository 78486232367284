import React, { useEffect } from "react";

import { Chip, Divider, Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { CustomLabel, DataTable, Loadable } from '../../../common';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import { BackButton } from "../../../common/BackButton";
import { MODULE_TYPE_VENDOR } from "../../../store/constants/contract";
import { asDate } from "../../../util/date";
import { DocumentView } from "../../contractsPage/ContractViewPage/DocumentView";

const useStyles = makeStyles(theme => {
  return {
    root: {
      color: '#48465b',
    },
    button: {
      margin: theme.spacing(1),
    },
    label: {
      fontSize: '1rem'
    },
    portlet: {
      'box-shadow': 'none',
      "& .kt-portlet__head": {
        borderColor: theme.palette.secondary.main,
      }
    },
    portletSection: {
      'box-shadow': 'none',
      "& .kt-portlet__head": {
        borderColor: theme.palette.secondary.main,
        paddingLeft: 0
      }
    }
  }
});

export default function VendorView({ contractsGetPending, contractsGetAlertUsersPending, payerInfoGetPending, contract,
  contractId, vendorContactFields, clientId, alertUsers, servicesTab, facilityXRef,
  actions: { contractGetAlertUsers, getContract, getConfigs, getFacilities }
}) {
  const classes = useStyles();

  function usersCallback() {
    getContract(contractId);
  }

  useEffect(() => {
    getFacilities();
  }, [getFacilities])


  // useEffect(() => {
  //   getConfigs();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [contractId]);

  useEffect(() => {
    contractGetAlertUsers(clientId, usersCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractGetAlertUsers, contractId]);

  return (
    <Loadable loading={payerInfoGetPending || contractsGetPending || contractsGetAlertUsersPending}>
      <Portlet className={classes.portlet}>
        <PortletHeader
          title='Vendor'
          showTitle={true}
          toolbar={
            <PortletHeaderToolbar>
              <BackButton defaultRoute="/vendor" />

            </PortletHeaderToolbar>
          }
        />
        <PortletBody>
          {contract && (
            <>
              <Grid className={classes.root} container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <CustomLabel label="Name" data={contract.name} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomLabel label="Effective Date" data={asDate(contract.effectiveDate)} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <CustomLabel label="Termination Date" data={asDate(contract.terminationDate)} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomLabel label="Type" data={contract.isPayer ? 'Payer' : 'Vendor'} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <CustomLabel label="Description" data={contract.description} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomLabel label="Notes" data={contract.notes} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <CustomLabel label="Location" data={contract.location} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomLabel label="Department" data={contract.department} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomLabel label="Resource Type" data={contract.resourceType} />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomLabel label="GL Codes" data={contract.glCode} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.label} variant="subtitle2" display="inline" >Alert Receipients : </Typography>
                  <Typography variant="subtitle1" display="inline">
                    {alertUsers && alertUsers.map((user, index) => (
                      <React.Fragment key={index}>
                        <Chip title={user.email} variant="outlined" size="small" color="primary" label={user.name} /> &nbsp;
                      </React.Fragment>
                    ))}
                  </Typography>
                </Grid>
                <Grid item xs={12} className="mt-2">
                  <Typography className={classes.label} variant="subtitle2" display="inline" >Facilities : </Typography>
                  <Typography variant="subtitle1" display="inline">
                    {facilityXRef && facilityXRef.map((user, index) => (
                      <React.Fragment key={index}>
                        <Chip title={user.email} variant="outlined" size="small" color="primary" label={user.name} /> &nbsp;
                      </React.Fragment>
                    ))}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Portlet className={classes.portletSection}>
                        <PortletHeader
                          title="Terms"
                          showTitle={true}
                          toolbar={<></>}
                        />
                        <PortletBody className="pl-0 pb-0">
                          {contract && contract.terms.map(({ renew, alert, notification }, index) => (
                            <React.Fragment key={index} >
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <CustomLabel label="Renew" data={asDate(renew)} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <CustomLabel label="Alert" data={asDate(alert)} />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <CustomLabel label="Nofification" data={notification ? 'On' : 'Off'} />
                                  </Grid>
                                </Grid>
                              </Grid>
                              {index !== (contract.terms.length - 1) && (
                                <Grid item xs={12} className="my-3">
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Divider />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            </React.Fragment>
                          ))}
                        </PortletBody>
                      </Portlet>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} className="">
                      <DocumentView
                        data={contract.documentMetadata}
                        contractId={contractId}
                        module={MODULE_TYPE_VENDOR}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} className="">
                      <Portlet className={classes.portletSection}>
                        <PortletHeader
                          title="Vendor Contacts"
                          showTitle={true}
                          toolbar={<></>}
                        />
                        <PortletBody className="pl-0">
                          <DataTable
                            fields={vendorContactFields}
                            data={contract.vendorContacts}
                          />
                        </PortletBody>
                      </Portlet>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </PortletBody>
      </Portlet>
    </Loadable >
  );
}
