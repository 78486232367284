import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { DependentsList, Loadable } from '../../../common';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

interface DirectoryDependencyDialogProps {
    open: boolean;
    intl: any;
    directoryId: string;
    directoryDependencies: any[];
    directoryDependencyPending: boolean;
    directoryDeletePending: boolean;
    directoryName: string;
    handleRefresh: (refresh: boolean) => void;
    actions: {
        setContractsV2DirectoryDependencyDialog: (dialog: any) => void;
        getContractsV2DirectoryDependencies: (directoryId: string) => void;
        deleteContractsV2ScheduleDirectory: (directoryId: string, callback: () => void) => void;
        resetDirectoryDependencies: () => void;
    };
}

function DirectoryDependencyDialog({
    open,
    intl,
    directoryId,
    directoryDependencies,
    directoryDependencyPending,
    directoryDeletePending,
    directoryName,
    handleRefresh,
    actions: {
        setContractsV2DirectoryDependencyDialog,
        getContractsV2DirectoryDependencies,
        deleteContractsV2ScheduleDirectory,
        resetDirectoryDependencies
    }
}: DirectoryDependencyDialogProps) {

    const classes = useStyles();

    useEffect(() => {
        if (open && directoryId) {
            getContractsV2DirectoryDependencies(directoryId)
        }
    }, [open, directoryId, getContractsV2DirectoryDependencies]);

    const handleDialogClose = () => {
        resetDirectoryDependencies();
        setContractsV2DirectoryDependencyDialog(null);
    }

    const handleDeleteCallback = () => {
        setContractsV2DirectoryDependencyDialog(null);
        handleRefresh(true);
    }

    const handleDelete = () => {
        if (directoryDependencies.length > 0) return
        deleteContractsV2ScheduleDirectory(directoryId, handleDeleteCallback);
    }

    return (
        (<Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={directoryDependencyPending || directoryDeletePending}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Dependencies ({directoryName})
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleDialogClose}
                                size="large">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    <Grid>
                        {directoryDependencies.length > 0 ?
                            <Typography>Cannot delete this Contract's directory. Please delete all the dependencies first.</Typography> :
                            <Typography>No dependency found. Please click to delete</Typography>
                        }
                    </Grid>
                    {directoryDependencies.length > 0 && <Grid className='pt-2'>
                        <DependentsList dependents={directoryDependencies} />
                    </Grid>}
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        disabled={directoryDependencyPending || directoryDeletePending || directoryDependencies.length > 0}
                        color="primary"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Loadable >
        </Dialog>)
    );
}

export default DirectoryDependencyDialog
