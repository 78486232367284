import React, { useEffect } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';

import { Loadable } from '../../../../common';
import { extractMultipleValues } from '../../../../util';
import { alphaNumericPattern } from '../../../../util/validation';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../../partials/content/Portlet";

import {
  Input,
  Button,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import {
  VALUE_TYPE_SINGLE, VALUE_TYPE_MULTIPLE,
  PARAMETER_TYPE_STATIC, PARAMETER_TYPE_DYNAMIC
} from '../../../../store/constants/filters';
import { BackButton } from "../../../../common/BackButton";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '80%',
  },
  textArea: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  formControlNarrow: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '80%',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%',
  },
}));

export default function DashboardFiltersEditPage(props) {
  const {
    intl, initialValues, dashboardFiltersGetPending, dashboardFilterId,
    dashboardFiltersCreatePending, dashboardFiltersUpdatePending, mode,
    dataTypes, dataSources, dataSourceFields, dashboardDataSourceGetFieldsPending,
    actions: { dashboardFilterGet, dashboardFilterCreate,
      dashboardFilterUpdate, dashboardDataSourcesList, dashboardDataSourcesGetFields,
      dashboardFilterErrorReset,
    }
  } = props;

  const classes = useStyles();
  const isEdit = mode === 'edit';

  const handleCallback = () => {
    props.history.push('/admin/dashboardfilters?persist=true');
  }

  useEffect(() => {
    if (mode === 'edit') {
      dashboardFilterGet(dashboardFilterId);
    }
  }, [mode, dashboardFilterGet, dashboardFilterId]);

  useEffect(() => {
    if (mode !== 'edit') {
      dashboardDataSourcesList();
    }
  }, [mode, dashboardDataSourcesList]);

  useEffect(() => {
    return function cleanup() {
      dashboardFilterErrorReset();
    }
  }, [dashboardFilterErrorReset]);

  const handleDataSourceChange = (handleChange, event) => {
    handleChange(event);
    dashboardDataSourcesGetFields(event.target.value);
  }

  return (
    <Loadable loading={dashboardFiltersGetPending || dashboardFiltersCreatePending || dashboardFiltersUpdatePending || dashboardDataSourceGetFieldsPending}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={values => {
          const errors = {};

          if (!values.name) {
            errors.name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (!values.name.match(alphaNumericPattern)) {
            errors.name = "Only alpha numeric values are allowed";
          }

          if (!values.datatype) {
            errors.datatype = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.displayName) {
            errors.displayName = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.parameterName1) {
            errors.parameterName1 = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (values.valuetype === VALUE_TYPE_SINGLE) {
            if (values.type === PARAMETER_TYPE_DYNAMIC) {
              if (!values.dataSourceId) {
                errors.dataSourceId = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!values.fieldName) {
                errors.fieldName = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }
            }
          } else {
            if (!values.datavalueMultiple) {
              errors.datavalueMultiple = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
          }

          if (values.datatype === 'Datetime') {
            if (!values.parameterName2) {
              errors.parameterName2 = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
          }

          if (!values.valuetype) {
            errors.valuetype = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          let payload = {
            name: values.name,
            datatype: values.datatype,
            description: values.description,
            metadata: values.metadata,
            valuetype: values.valuetype,
            displayName: values.displayName,
            parameterName1: values.parameterName1,
            parameterName2: values.parameterName2,
          }

          if (values.valuetype === VALUE_TYPE_SINGLE) {
            payload = {
              ...payload,
              type: values.type,
            }

            if (values.type === PARAMETER_TYPE_STATIC) {
              payload = {
                ...payload,
                datavalue: values.datavalueSingle,
              }
            } else {
              payload = {
                ...payload,
                datasourceId: values.dataSourceId,
                fieldName: values.fieldName,
              }
            }
          }

          if (values.valuetype === VALUE_TYPE_MULTIPLE) {
            payload = {
              ...payload,
              datavalue: extractMultipleValues(values.datavalueMultiple),
            }
          }

          if (mode === 'create') {
            dashboardFilterCreate(payload, handleCallback);
          }
          else {
            payload = {
              ...payload,
              id: values.id,
            }
            dashboardFilterUpdate(values.id, payload, handleCallback);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
            <Portlet>
              <PortletHeader
                title={isEdit ? 'Edit Filter' : 'Add new Filter'}
                toolbar={
                  <PortletHeaderToolbar>
                    <BackButton text={dirty ? "Cancel" : "Back"} defaultRoute="/admin/dashboardfilters?persist=true" />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      type="submit"
                      disabled={dashboardFiltersCreatePending || dashboardFiltersUpdatePending || (isEdit && !dirty)}
                    >
                      {(dashboardFiltersCreatePending || dashboardFiltersUpdatePending) ? 'Saving Changes' : 'Save Changes'}
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className={classes.container}>
                  <div className="row">
                    <div className="col-md-5">
                      <TextField
                        required
                        label="Name"
                        name="name"
                        disabled={isEdit}
                        className={classes.textField}
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        helperText={touched.name && errors.name}
                        error={Boolean(touched.name && errors.name)}
                      />
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="datatype">Select DataType</InputLabel>
                        <Select
                          value={values.datatype}
                          onChange={handleChange}
                          error={Boolean(touched.datatype && errors.datatype)}
                          input={<Input id="datatype" />}
                          inputProps={{
                            name: "datatype",
                            id: "datatype"
                          }}
                        >
                          {dataTypes.map(datatype => (
                            <MenuItem
                              key={datatype}
                              value={datatype}
                            >
                              {datatype}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        required
                        label="Display Name"
                        name="displayName"
                        className={classes.textField}
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.displayName}
                        helperText={touched.displayName && errors.displayName}
                        error={Boolean(touched.displayName && errors.displayName)}
                      />
                      <TextField
                        required
                        label="Parameter Name 1"
                        name="parameterName1"
                        className={classes.textField}
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.parameterName1}
                        helperText={touched.parameterName1 && errors.parameterName1}
                        error={Boolean(touched.parameterName1 && errors.parameterName1)}
                      />
                      {(values.datatype === 'Datetime') && (
                        <TextField
                          required
                          label="Parameter Name 2"
                          name="parameterName2"
                          className={classes.textField}
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.parameterName2}
                          helperText={touched.parameterName2 && errors.parameterName2}
                          error={Boolean(touched.parameterName2 && errors.parameterName2)}
                        />
                      )}
                      <TextField
                        label="Description"
                        name="description"
                        className={classes.textField}
                        margin="normal"
                        multiline
                        rows="3"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                      />
                    </div>
                    <div className="col-md-5">
                      <div className="row">
                        <div className="col-md-12">
                          <FormControl className={classes.formControlNarrow}>
                            <FormLabel>Value Type</FormLabel>
                            <RadioGroup
                              className={classes.group}
                              row
                              aria-label="valuetype"
                              name="valuetype"
                              value={values.valuetype}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value={VALUE_TYPE_SINGLE}
                                control={<Radio />}
                                label={VALUE_TYPE_SINGLE}
                              />
                              <FormControlLabel
                                value={VALUE_TYPE_MULTIPLE}
                                control={<Radio />}
                                label={VALUE_TYPE_MULTIPLE}
                              />
                            </RadioGroup>
                          </FormControl>
                          {(values.valuetype === VALUE_TYPE_SINGLE) ? (
                            <>
                              <FormControl className={classes.formControlNarrow}>
                                <FormLabel>Parameter Type</FormLabel>
                                <RadioGroup
                                  className={classes.group}
                                  row
                                  aria-label="type"
                                  name="type"
                                  value={values.type}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    value={PARAMETER_TYPE_STATIC}
                                    control={<Radio />}
                                    label={PARAMETER_TYPE_STATIC}
                                  />
                                  <FormControlLabel
                                    value={PARAMETER_TYPE_DYNAMIC}
                                    control={<Radio />}
                                    label={PARAMETER_TYPE_DYNAMIC}
                                  />
                                </RadioGroup>
                              </FormControl>
                              {(values.type === PARAMETER_TYPE_STATIC) ? (
                                <TextField
                                  label="Static default value"
                                  name="datavalueSingle"
                                  className={classes.textField}
                                  margin="normal"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.datavalueSingle}
                                  helperText={touched.datavalueSingle && errors.datavalueSingle}
                                  error={Boolean(touched.datavalueSingle && errors.datavalueSingle)}
                                />
                              ) : (
                                <>
                                  <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="dataSourceId">Select DataSource</InputLabel>
                                    <Select
                                      value={values.dataSourceId}
                                      onChange={(event) => handleDataSourceChange(handleChange, event)}
                                      error={Boolean(touched.dataSourceId && errors.dataSourceId)}
                                      input={<Input id="dataSourceId" />}
                                      inputProps={{
                                        name: "dataSourceId",
                                        id: "dataSourceId"
                                      }}
                                    >
                                      {dataSources.map(({ id, name }) => (
                                        <MenuItem
                                          key={id}
                                          value={id}
                                        >
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <FormControl className={classes.formControl}>
                                    <Autocomplete
                                      onChange={(event, value) => setFieldValue("fieldName", value)}
                                      value={values.fieldName}
                                      options={dataSourceFields}
                                      getOptionLabel={(option) => option}
                                      renderInput={(params) => (
                                        <TextField {...params}
                                          label="Select Field"
                                          error={Boolean(touched.fieldName && errors.fieldName)}
                                          helperText={touched.fieldName && errors.fieldName}
                                          name="fieldName"
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </>
                              )}
                            </>
                          ) : (
                            <TextField
                              required
                              label="Static multiple default values"
                              name="datavalueMultiple"
                              className={classes.textField}
                              multiline
                              rows="4"
                              margin="normal"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.datavalueMultiple}
                              helperText={touched.datavalueMultiple && errors.datavalueMultiple}
                              error={Boolean(touched.datavalueMultiple && errors.datavalueMultiple)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </form>
        )}
      </Formik>
    </Loadable>
  );
}
