import { createReducer } from '../../util';
import * as actions from '../actions/payers';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    payersList: [],
    tiny: [],
    scoreCardList: [],
    payerImports: null,
    remitPayerImports: null,
    toleranceMatchResult: null,
    payerInfoEditDialog: null, //{open:true,payer:obj,bulk:true}
    infoEnableDialog: null, //{open:true,isEnableClicked:true}
    remitPayerEditDialog: null, //{open:true,payer:obj,bulk:true}
    remitPayerEnableDialog: null, //{open:true,isEnableClicked:true}
    toleranceMatchDialog: null,
    claimDependencyDialog: null, //{open:true}
    remitDependencyDialog: null, //{open:true}
    payerContractDependencyDialog: null, //{open:true}
    claimPayersDependency: { records: [] },
    remitPayersDependency: { records: [] },
    payerContractsDependency: { records: [] },
    payerContracts: [],
    payersPlansList: [],
    payerPlan: null,
    payerPlansTiny: [],
    payerPlanDependencyDialog: null,
    payerPlanDependencies: [],
    payerPlanDialog: null, //{open:true,payerPlan:obj, mode: 'edit'}
    payerIdMapping: null,
    payerIdMappingTiny: [],
    editPayerIdMappingDialog: null, //{open:true,payerIdMapping:obj}
    payerIdMappingDependencies: null,
    payerIdMappingDependencyDialog: null, //{open:true,payerIdMapping:obj}
    list: {
        ...initialStatusState,
    },
    updateStatus: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    all: {
        ...initialStatusState,
    },
    payerCreateContract: {
        ...initialStatusState,
    },
    payerUpdateContract: {
        ...initialStatusState,
    },
    getPayerContractsTiny: {
        ...initialStatusState,
    },
    getPayersTiny: {
        ...initialStatusState,
    },
    updateBulk: {
        ...initialStatusState,
    },
    getPayersScoreCard: {
        ...initialStatusState
    },
    deleteBulk: {
        ...initialStatusState,
    },
    getPayerImport: {
        ...initialStatusState
    },
    getRemitPayerImport: {
        ...initialStatusState
    },
    remitPayerSelectAll: {
        ...initialStatusState
    },
    remitPayerUpdate: {
        ...initialStatusState
    },
    remitPayerCreate: {
        ...initialStatusState
    },
    remitPayerBulkEdit: {
        ...initialStatusState
    },
    toleranceMatch: {
        ...initialStatusState
    },
    remitPayerMapping: {
        ...initialStatusState
    },
    getClaimPayerDependency: {
        ...initialStatusState
    },
    deleteClaimPayers: {
        ...initialStatusState
    },
    getRemitPayerDependency: {
        ...initialStatusState
    },
    deleteRemitPayers: {
        ...initialStatusState
    },
    getPayerContractDependency: {
        ...initialStatusState
    },
    payerPlansLanding: {
        ...initialStatusState,
    },
    createPayerPlan: {
        ...initialStatusState,
    },
    updatePayerPlan: {
        ...initialStatusState,
    },
    deletePayerPlan: {
        ...initialStatusState,
    },
    getPayerPlansTiny: {
        ...initialStatusState,
    },
    getPayerPlan: {
        ...initialStatusState,
    },
    getPayerPlanDependencies: {
        ...initialStatusState
    },
    exportPayer: {
        ...initialStatusState
    },
    exportRemitPayer: {
        ...initialStatusState
    },
    checkAssociation: {
        ...initialStatusState
    },
    createPayerIdMapping: {
        ...initialStatusState
    },
    updatePayerIdMapping: {
        ...initialStatusState
    },
    bulkUpdatePayerIdMapping: {
        ...initialStatusState
    },
    getPayerIdMapping: {
        ...initialStatusState
    },
    getPayerIdMappingTiny: {
        ...initialStatusState
    },
    getPayerIdMappingDependencies: {
        ...initialStatusState
    },
    deletePayerIdMappingRecord: {
        ...initialStatusState
    },
    uploadPayerIdMapping: {
        ...initialStatusState
    },
    downloadPayerIdMapping: {
        ...initialStatusState
    },
};


export default createReducer(initialState, {
    [actions.PAYERS_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
    }),
    [actions.PAYERS_FULFILLED]: (state, payersList) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        payersList,
    }),
    [actions.PAYERS_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYERS_UPDATE_STATUS_PENDING]: (state) => ({
        ...state,
        updateStatus: {
            pending: true,
        },
    }),
    [actions.PAYERS_UPDATE_STATUS_FULFILLED]: (state, payload) => ({
        ...state,
        payersList: state.payersList.map(
            (payer, i) => payer.id === payload.id ? { ...payer, active: payload.enabled } : payer //update payer status on the base of ID
        ),
        updateStatus: {
            ...initialStatusState,
        },
    }),
    [actions.PAYERS_UPDATE_STATUS_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateStatus: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYER_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.PAYER_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        }
    }),
    [actions.PAYER_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYER_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.PAYER_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        }
    }),
    [actions.PAYER_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYER_EDIT_RESET]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        },
    }),
    [actions.PAYER_CONTRACT_CREATE_PENDING]: (state) => ({
        ...state,
        payerCreateContract: {
            pending: true,
        },
    }),
    [actions.PAYER_CONTRACT_CREATE_FULFILLED]: (state) => ({
        ...state,
        payerCreateContract: {
            ...initialStatusState,
        }
    }),
    [actions.PAYER_CONTRACT_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        payerCreateContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYER_CONTRACT_UPDATE_PENDING]: (state) => ({
        ...state,
        payerUpdateContract: {
            pending: true,
        },
    }),
    [actions.PAYER_CONTRACT_UPDATE_FULFILLED]: (state) => ({
        ...state,
        payerUpdateContract: {
            ...initialStatusState,
        }
    }),
    [actions.PAYER_CONTRACT_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        payerUpdateContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYER_CONTRACT_EDIT_RESET]: (state) => ({
        ...state,
        payerUpdateContract: {
            ...initialStatusState,
        },
        payerCreateContract: {
            ...initialStatusState,
        },
    }),
    [actions.PAYER_SELECT_ALL_PENDING]: (state) => ({
        ...state,
        all: {
            pending: true,
        },
    }),
    [actions.PAYER_SELECT_ALL_FULFILLED]: (state) => ({
        ...state,
        all: {
            ...initialStatusState,
        }
    }),
    [actions.PAYER_SELECT_ALL_REJECTED]: (state, errorMessage) => ({
        ...state,
        all: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYER_GET_PAYER_CONTRACTS_TINY_PENDING]: (state) => ({
        ...state,
        getPayerContractsTiny: {
            pending: true,
        },
    }),
    [actions.PAYER_GET_PAYER_CONTRACTS_TINY_FULFILLED]: (state, payerContracts) => ({
        ...state,
        getPayerContractsTiny: {
            ...initialStatusState,
        },
        payerContracts,
    }),
    [actions.PAYER_GET_PAYER_CONTRACTS_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayerContractsTiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYERS_TINY_PENDING]: (state) => ({
        ...state,
        getPayersTiny: {
            pending: true,
        },
    }),
    [actions.PAYERS_TINY_FULFILLED]: (state, tiny) => ({
        ...state,
        getPayersTiny: {
            ...initialStatusState,
        },
        tiny,
    }),
    [actions.PAYERS_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayersTiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYERS_SCORECARD_PENDING]: (state) => ({
        ...state,
        getPayersScoreCard: {
            pending: true,
        },
    }),
    [actions.PAYERS_SCORECARD_FULFILLED]: (state, scoreCardList) => ({
        ...state,
        getPayersScoreCard: {
            ...initialStatusState,
        },
        scoreCardList,
    }),
    [actions.PAYERS_SCORECARD_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayersScoreCard: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_UPDATE_BULK_PENDING]: (state) => ({
        ...state,
        updateBulk: {
            pending: true,
        },
    }),
    [actions.PAYER_UPDATE_BULK_FULFILLED]: (state) => ({
        ...state,
        updateBulk: {
            ...initialStatusState,
        }
    }),
    [actions.PAYER_UPDATE_BULK_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateBulk: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.PAYER_DELETE_BULK_PENDING]: (state) => ({
        ...state,
        deleteBulk: {
            pending: true,
        },
    }),
    [actions.PAYER_DELETE_BULK_FULFILLED]: (state) => ({
        ...state,
        deleteBulk: {
            ...initialStatusState,
        }
    }),
    [actions.PAYER_DELETE_BULK_REJECTED]: (state, errorMessage) => ({
        ...state,
        deleteBulk: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.PAYER_INFO_IMPORT_PENDING]: (state) => ({
        ...state,
        getPayerImport: {
            pending: true,
        },
    }),
    [actions.PAYER_INFO_IMPORT_FULFILLED]: (state, payerImports) => ({
        ...state,
        getPayerImport: {
            ...initialStatusState,
        },
        payerImports
    }),
    [actions.PAYER_INFO_IMPORT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayerImport: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.REMIT_PAYER_IMPORT_PENDING]: (state) => ({
        ...state,
        getRemitPayerImport: {
            pending: true,
        },
    }),
    [actions.REMIT_PAYER_IMPORT_FULFILLED]: (state, remitPayerImports) => ({
        ...state,
        getRemitPayerImport: {
            ...initialStatusState,
        },
        remitPayerImports
    }),
    [actions.REMIT_PAYER_IMPORT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getRemitPayerImport: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.PAYER_INFO_IMPORT_RESET]: (state) => ({
        ...state,
        getPayerImport: {
            ...initialStatusState,
        },
    }),
    [actions.REMIT_PAYER_IMPORT_RESET]: (state) => ({
        ...state,
        getRemitPayerImport: {
            ...initialStatusState,
        },
    }),

    [actions.PAYER_INFO_EXPORT_PENDING]: (state) => ({
        ...state,
        exportPayer: {
            pending: true,
        },
    }),
    [actions.PAYER_INFO_EXPORT_FULFILLED]: (state) => ({
        ...state,
        exportPayer: {
            ...initialStatusState
        },
    }),
    [actions.PAYER_INFO_EXPORT_REJECTED]: (state, errorMessage) => ({
        ...state,
        exportPayer: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.REMIT_PAYER_EXPORT_PENDING]: (state) => ({
        ...state,
        exportRemitPayer: {
            pending: true,
        },
    }),
    [actions.REMIT_PAYER_EXPORT_FULFILLED]: (state) => ({
        ...state,
        exportRemitPayer: {
            ...initialStatusState
        },
    }),
    [actions.REMIT_PAYER_EXPORT_REJECTED]: (state, errorMessage) => ({
        ...state,
        exportRemitPayer: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_INFO_SET_EDIT_DIALOG]: (state, { payerInfoEditDialog }) => ({
        ...state,
        payerInfoEditDialog
    }),
    [actions.PAYER_INFO_SET_ENABLE_DIALOG]: (state, { infoEnableDialog }) => ({
        ...state,
        infoEnableDialog
    }),

    [actions.REMIT_PAYER_SET_EDIT_DIALOG]: (state, { remitPayerEditDialog }) => ({
        ...state,
        remitPayerEditDialog
    }),
    [actions.REMIT_PAYER_SET_ENABLE_DIALOG]: (state, { remitPayerEnableDialog }) => ({
        ...state,
        remitPayerEnableDialog
    }),

    [actions.REMIT_PAYER_ENABLE_ALL_PENDING]: (state) => ({
        ...state,
        remitPayerSelectAll: {
            pending: true,
        },
    }),
    [actions.REMIT_PAYER_ENABLE_ALL_FULFILLED]: (state) => ({
        ...state,
        remitPayerSelectAll: {
            ...initialStatusState,
        }
    }),
    [actions.REMIT_PAYER_ENABLE_ALL_REJECTED]: (state, errorMessage) => ({
        ...state,
        remitPayerSelectAll: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),


    [actions.REMIT_PAYER_CREATE_PENDING]: (state) => ({
        ...state,
        remitPayerCreate: {
            pending: true,
        },
    }),
    [actions.REMIT_PAYER_CREATE_FULFILLED]: (state) => ({
        ...state,
        remitPayerCreate: {
            ...initialStatusState,
        }
    }),
    [actions.REMIT_PAYER_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        remitPayerCreate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.REMIT_PAYER_UPDATE_PENDING]: (state) => ({
        ...state,
        remitPayerUpdate: {
            pending: true,
        },
    }),
    [actions.REMIT_PAYER_UPDATE_FULFILLED]: (state) => ({
        ...state,
        remitPayerUpdate: {
            ...initialStatusState,
        }
    }),
    [actions.REMIT_PAYER_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        remitPayerUpdate: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),
    [actions.REMIT_PAYER_BULK_UPDATE_PENDING]: (state) => ({
        ...state,
        remitPayerBulkEdit: {
            pending: true,
        },
    }),
    [actions.REMIT_PAYER_BULK_UPDATE_FULFILLED]: (state) => ({
        ...state,
        remitPayerBulkEdit: {
            ...initialStatusState,
        }
    }),
    [actions.REMIT_PAYER_BULK_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        remitPayerBulkEdit: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.REMIT_PAYER_TOLERANCE_MATCH_PENDING]: (state) => ({
        ...state,
        toleranceMatch: {
            pending: true,
        },
    }),
    [actions.REMIT_PAYER_TOLERANCE_MATCH_FULFILLED]: (state, toleranceMatchResult) => ({
        ...state,
        toleranceMatch: {
            ...initialStatusState,
        },
        toleranceMatchResult
    }),
    [actions.REMIT_PAYER_TOLERANCE_MATCH_REJECTED]: (state, errorMessage) => ({
        ...state,
        toleranceMatch: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.REMIT_PAYER_CLAIM_MAPPING_PENDING]: (state) => ({
        ...state,
        remitPayerMapping: {
            pending: true,
        },
    }),
    [actions.REMIT_PAYER_CLAIM_MAPPING_FULFILLED]: (state, toleranceMatchResult) => ({
        ...state,
        remitPayerMapping: {
            ...initialStatusState,
        },
        toleranceMatchResult
    }),
    [actions.REMIT_PAYER_CLAIM_MAPPING_REJECTED]: (state, errorMessage) => ({
        ...state,
        remitPayerMapping: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.CLAIM_PAYERS_DEPENDENCY_PENDING]: (state) => ({
        ...state,
        getClaimPayerDependency: {
            pending: true,
        },
    }),
    [actions.CLAIM_PAYERS_DEPENDENCY_FULFILLED]: (state, claimPayersDependency) => ({
        ...state,
        getClaimPayerDependency: {
            ...initialStatusState,
        },
        claimPayersDependency
    }),
    [actions.CLAIM_PAYERS_DEPENDENCY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getClaimPayerDependency: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),
    [actions.CLAIM_PAYERS_DELETE_PENDING]: (state) => ({
        ...state,
        deleteClaimPayers: {
            pending: true,
        },
    }),
    [actions.CLAIM_PAYERS_DELETE_FULFILLED]: (state) => ({
        ...state,
        deleteClaimPayers: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_PAYERS_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deleteClaimPayers: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.REMIT_PAYERS_DEPENDENCY_PENDING]: (state) => ({
        ...state,
        getRemitPayerDependency: {
            pending: true,
        },
    }),
    [actions.REMIT_PAYERS_DEPENDENCY_FULFILLED]: (state, remitPayersDependency) => ({
        ...state,
        getRemitPayerDependency: {
            ...initialStatusState,
        },
        remitPayersDependency
    }),
    [actions.REMIT_PAYERS_DEPENDENCY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getRemitPayerDependency: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.REMIT_PAYERS_DELETE_PENDING]: (state) => ({
        ...state,
        deleteRemitPayers: {
            pending: true,
        },
    }),
    [actions.REMIT_PAYERS_DELETE_FULFILLED]: (state) => ({
        ...state,
        deleteRemitPayers: {
            ...initialStatusState,
        },
    }),
    [actions.REMIT_PAYERS_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deleteRemitPayers: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.PAYER_CONTRACT_DEPENDENCY_PENDING]: (state) => ({
        ...state,
        getPayerContractDependency: {
            pending: true,
        },
    }),
    [actions.PAYER_CONTRACT_DEPENDENCY_FULFILLED]: (state, payerContractsDependency) => ({
        ...state,
        getPayerContractDependency: {
            ...initialStatusState,
        },
        payerContractsDependency
    }),
    [actions.PAYER_CONTRACT_DEPENDENCY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayerContractDependency: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.REMIT_PAYER_SET_TOLERANCE_MATCH_DIALOG]: (state, { toleranceMatchDialog }) => ({
        ...state,
        toleranceMatchDialog
    }),
    [actions.CLAIM_PAYERS_SET_DEPENDENCY_DIALOG]: (state, { claimDependencyDialog }) => ({
        ...state,
        claimDependencyDialog
    }),
    [actions.REMIT_PAYERS_SET_DEPENDENCY_DIALOG]: (state, { remitDependencyDialog }) => ({
        ...state,
        remitDependencyDialog
    }),
    [actions.PAYER_CONTRACT_SET_DEPENDENCY_DIALOG]: (state, { payerContractDependencyDialog }) => ({
        ...state,
        payerContractDependencyDialog
    }),

    [actions.PAYERS_RESET]: () => ({ ...initialState }),
    [actions.PAYERS_ERROR_RESET_CLEAR]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
        update: {
            ...initialStatusState,
        },
        updateBulk: {
            ...initialStatusState,
        }
    }),

    [actions.PAYER_PLAN_LANDING]: (state) => ({
        ...state,
        payerPlansLanding: {
            pending: true,
        },
    }),
    [actions.PAYER_PLAN_LANDING_FULFILLED]: (state, payersPlansList) => ({
        ...state,
        payerPlansLanding: {
            ...initialStatusState,
        },
        payersPlansList,
    }),
    [actions.PAYER_PLAN_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        payerPlansLanding: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_PLAN_CREATE_PENDING]: (state) => ({
        ...state,
        createPayerPlan: {
            pending: true,
        },
    }),
    [actions.PAYER_PLAN_CREATE_FULFILLED]: (state) => ({
        ...state,
        createPayerPlan: {
            ...initialStatusState,
        },
    }),
    [actions.PAYER_PLAN_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        createPayerPlan: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_PLAN_UPDATE_PENDING]: (state) => ({
        ...state,
        updatePayerPlan: {
            pending: true,
        },
    }),
    [actions.PAYER_PLAN_UPDATE_FULFILLED]: (state) => ({
        ...state,
        updatePayerPlan: {
            ...initialStatusState,
        },
    }),
    [actions.PAYER_PLAN_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        updatePayerPlan: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_PLAN_DELETE_PENDING]: (state) => ({
        ...state,
        deletePayerPlan: {
            pending: true,
        },
    }),
    [actions.PAYER_PLAN_DELETE_FULFILLED]: (state) => ({
        ...state,
        deletePayerPlan: {
            ...initialStatusState,
        },
    }),
    [actions.PAYER_PLAN_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deletePayerPlan: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_PLAN_GET_PENDING]: (state) => ({
        ...state,
        getPayerPlan: {
            pending: true,
        },
    }),
    [actions.PAYER_PLAN_GET_FULFILLED]: (state, payerPlan) => ({
        ...state,
        getPayerPlan: {
            ...initialStatusState,
        },
        payerPlan
    }),
    [actions.PAYER_PLAN_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayerPlan: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_PLAN_GET_TINY_PENDING]: (state) => ({
        ...state,
        getPayerPlansTiny: {
            pending: true,
        },
    }),
    [actions.PAYER_PLAN_GET_TINY_FULFILLED]: (state, payerPlansTiny) => ({
        ...state,
        getPayerPlansTiny: {
            ...initialStatusState,
        },
        payerPlansTiny
    }),
    [actions.PAYER_PLAN_GET_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayerPlansTiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYER_PLAN_CREATE_UPDATE_RESET]: (state) => ({
        ...state,
        updatePayerPlan: {
            ...initialStatusState,
        },
        createPayerPlan: {
            ...initialStatusState,
        },
    }),

    [actions.PAYER_PLAN_SET_DEPENDENCY_DIALOG]: (state, { payerPlanDependencyDialog }) => ({
        ...state,
        payerPlanDependencyDialog
    }),

    [actions.PAYER_PLAN_RESET_DEPENDENCIES]: (state) => ({
        ...state,
        payerPlanDependencies: []
    }),

    [actions.PAYER_PLAN_DELETE_DEPENDENCIES_PENDING]: (state) => ({
        ...state,
        getPayerPlanDependencies: {
            pending: true,
        },
    }),
    [actions.PAYER_PLAN_DELETE_DEPENDENCIES_FULFILLED]: (state, payerPlanDependencies) => ({
        ...state,
        getPayerPlanDependencies: {
            ...initialStatusState,
        },
        payerPlanDependencies
    }),
    [actions.PAYER_PLAN_DELETE_DEPENDENCIES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayerPlanDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYER_PLAN_SET_DIALOG]: (state, { payerPlanDialog }) => ({
        ...state,
        payerPlanDialog
    }),

    [actions.PAYER_CONTRACT_ASSOCIATION_PENDING]: (state) => ({
        ...state,
        checkAssociation: {
            pending: true,
        },
    }),
    [actions.PAYER_CONTRACT_ASSOCIATION_FULFILLED]: (state) => ({
        ...state,
        checkAssociation: {
            ...initialStatusState,
        }
    }),
    [actions.PAYER_CONTRACT_ASSOCIATION_REJECTED]: (state, errorMessage) => ({
        ...state,
        checkAssociation: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.PAYER_ID_MAPPING_CREATE_PENDING]: (state) => ({
        ...state,
        createPayerIdMapping: {
            pending: true,
        },
    }),
    [actions.PAYER_ID_MAPPING_CREATE_FULFILLED]: (state) => ({
        ...state,
        createPayerIdMapping: {
            ...initialStatusState,
        },
    }),
    [actions.PAYER_ID_MAPPING_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        createPayerIdMapping: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_ID_MAPPING_UPDATE_PENDING]: (state) => ({
        ...state,
        updatePayerIdMapping: {
            pending: true,
        },
    }),
    [actions.PAYER_ID_MAPPING_UPDATE_FULFILLED]: (state) => ({
        ...state,
        updatePayerIdMapping: {
            ...initialStatusState,
        },
    }),
    [actions.PAYER_ID_MAPPING_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        updatePayerIdMapping: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_ID_MAPPING_BULK_UPDATE_PENDING]: (state) => ({
        ...state,
        bulkUpdatePayerIdMapping: {
            pending: true,
        },
    }),
    [actions.PAYER_ID_MAPPING_BULK_UPDATE_FULFILLED]: (state) => ({
        ...state,
        bulkUpdatePayerIdMapping: {
            ...initialStatusState,
        }
    }),
    [actions.PAYER_ID_MAPPING_BULK_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        bulkUpdatePayerIdMapping: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.PAYER_ID_MAPPING_GET_PENDING]: (state) => ({
        ...state,
        getPayerIdMapping: {
            pending: true,
        },
    }),
    [actions.PAYER_ID_MAPPING_GET_FULFILLED]: (state, payerIdMapping) => ({
        ...state,
        getPayerIdMapping: {
            ...initialStatusState,
        },
        payerIdMapping
    }),
    [actions.PAYER_ID_MAPPING_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayerIdMapping: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_ID_MAPPING_CREATE_UPDATE_RESET]: (state) => ({
        ...state,
        updatePayerIdMapping: {
            ...initialStatusState,
        },
        createPayerIdMapping: {
            ...initialStatusState,
        },
    }),

    [actions.PAYER_ID_MAPPING_SET_EDIT_DIALOG]: (state, { editPayerIdMappingDialog }) => ({
        ...state,
        editPayerIdMappingDialog
    }),

    [actions.PAYER_ID_MAPPING_DELETE_DEPENDENCIES_PENDING]: (state) => ({
        ...state,
        getPayerIdMappingDependencies: {
            pending: true,
        },
    }),
    [actions.PAYER_ID_MAPPING_DELETE_DEPENDENCIES_FULFILLED]: (state, payerIdMappingDependencies) => ({
        ...state,
        getPayerIdMappingDependencies: {
            ...initialStatusState,
        },
        payerIdMappingDependencies
    }),
    [actions.PAYER_ID_MAPPING_DELETE_DEPENDENCIES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayerIdMappingDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_ID_MAPPING_SET_DEPENDENCY_RESET]: (state) => ({
        ...state,
        payerIdMappingDependencies: null
    }),

    [actions.PAYER_ID_MAPPING_DELETE_PENDING]: (state) => ({
        ...state,
        deletePayerIdMappingRecord: {
            pending: true,
        },
    }),
    [actions.PAYER_ID_MAPPING_DELETE_FULFILLED]: (state) => ({
        ...state,
        deletePayerIdMappingRecord: {
            ...initialStatusState,
        },
    }),
    [actions.PAYER_ID_MAPPING_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deletePayerIdMappingRecord: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_ID_MAPPING_SET_DEPENDENCY_DIALOG]: (state, { payerIdMappingDependencyDialog }) => ({
        ...state,
        payerIdMappingDependencyDialog
    }),

    [actions.PAYER_ID_MAPPING_UPLOAD_PENDING]: (state) => ({
        ...state,
        uploadPayerIdMapping: {
            pending: true,
        },
    }),
    [actions.PAYER_ID_MAPPING_UPLOAD_FULFILLED]: (state) => ({
        ...state,
        uploadPayerIdMapping: {
            ...initialStatusState,
        },
    }),
    [actions.PAYER_ID_MAPPING_UPLOAD_REJECTED]: (state, errorMessage) => ({
        ...state,
        uploadPayerIdMapping: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_PENDING]: (state) => ({
        ...state,
        downloadPayerIdMapping: {
            pending: true,
        },
    }),
    [actions.PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_FULFILLED]: (state) => ({
        ...state,
        downloadPayerIdMapping: {
            ...initialStatusState,
        },
    }),
    [actions.PAYER_ID_MAPPING_DOWNLOAD_TEMPLATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        downloadPayerIdMapping: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_ID_MAPPING_TINY_PENDING]: (state) => ({
        ...state,
        getPayerIdMappingTiny: {
            pending: true,
        },
    }),
    [actions.PAYER_ID_MAPPING_TINY_FULFILLED]: (state, payerIdMappingTiny) => ({
        ...state,
        getPayerIdMappingTiny: {
            ...initialStatusState,
        },
        payerIdMappingTiny
    }),
    [actions.PAYER_ID_MAPPING_TINY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayerIdMappingTiny: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
});