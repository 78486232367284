import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import ChangeStatusComponentPage from './UpdateStatusComponent';
import { getClaimAllDetails, overrideContract,removeOverriddenContract,getClaimStatuses,updateSingleClaimStatus, setClaimStatusHistoryDialog } from '../../../../store/actions/claimAnalysis';


export const selectClaimStatusDropdownValues = (state) => state.claimAnalysis.claimStatuses?.map((item)=>({label:item.name,value:item.id,name:item.name}));

export const ChangeStatusComponent = connect(
    () => {

      

        return (state, props) => ({
            claimStatusList:selectClaimStatusDropdownValues(state,props),
            changeStatusPending:state.claimAnalysis.updateSingleClaimStatus.pending

        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            getClaimAllDetails: getClaimAllDetails,
            getClaimStatuses,
            updateSingleClaimStatus,
            setClaimStatusHistoryDialog: setClaimStatusHistoryDialog
        }, dispatch)
    })
)(injectIntl(ChangeStatusComponentPage));