import {
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    SERVER_URL,
} from '../constants/api';
import {
    headersAuth,
    headersAuthReceiveJson, headersAuthSendReceiveJson
} from '../../store/headers';
import { apiHandleResponse } from '../../util/api';

const getClients = () => {
    return fetch(`${SERVER_URL}/api/v2/client`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getClientsTiny = () => {
    return fetch(`${SERVER_URL}/api/v2/client/tiny`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createClient = (client) => {
    return fetch(`${SERVER_URL}/api/v2/client`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(client)
    }).then(apiHandleResponse);
};

const updateClient = (client) => {
    return fetch(`${SERVER_URL}/api/v2/client`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(client)
    }).then(apiHandleResponse);
};

const getClient = (clientId) => {
    return fetch(`${SERVER_URL}/api/v2/client/id=${clientId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getParsers = () => {
    return fetch(`${SERVER_URL}/api/v1/client/parsers`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const bucketAvailability = (bucketName) => {
    return fetch(`${SERVER_URL}/api/v1/client/buckets/${bucketName}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const ftpuserAvailability = (username) => {
    return fetch(`${SERVER_URL}/api/v1/client/check/ftpuser/${username}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};


const deactivateClient = (clientId) => {
    return fetch(`${SERVER_URL}/api/v2/client/${clientId}`, {
        method: METHOD_DELETE,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};


const activateClient = (clientId) => {
    return fetch(`${SERVER_URL}/api/v2/client/activate/${clientId}`, {
        method: METHOD_POST,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getConfiguration = (clientId) => {
    return fetch(`${SERVER_URL}/api/v2/client/configuration/${clientId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getAccountConfiguration = (clientId) => {
    return fetch(`${SERVER_URL}/api/v2/client/account/configuration/${clientId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createConfiguration = (clientConfigs) => {
    return fetch(`${SERVER_URL}/api/v2/client/configuration`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(clientConfigs)
    }).then(apiHandleResponse);
};


const addClaims = (claims) => {
    return fetch(`${SERVER_URL}/api/v2/client/account/configuration/validate`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(claims)
    }).then(apiHandleResponse);
};

const createAccountConfiguration = (clientAccountConfigs) => {
    return fetch(`${SERVER_URL}/api/v2/client/account/configuration`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(clientAccountConfigs)
    }).then(apiHandleResponse);
};


const editConfiguration = (clientConfigs) => {
    return fetch(`${SERVER_URL}/api/v2/client/configuration`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(clientConfigs)
    }).then(apiHandleResponse);
};

const editAccountConfiguration = (clientConfigs) => {
    return fetch(`${SERVER_URL}/api/v2/client/account/configuration`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(clientConfigs)
    }).then(apiHandleResponse);
};

const deleteConfiguration = (clientId) => {
    return fetch(`${SERVER_URL}/api/v2/client/configuration/${clientId}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getTimezones = () => {
    return fetch(`${SERVER_URL}/api/v2/client/timezones`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getClientProducts = (clientId) => {
    return fetch(`${SERVER_URL}/api/v2/client/product/${clientId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getClientBranding = () => {
    return fetch(`${SERVER_URL}/api/v1/client/branding`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const createClientBranding = (branding) => {
    return fetch(`${SERVER_URL}/api/v1/client/branding`, {
        method: METHOD_POST,
        headers: headersAuth(),
        body: branding
    }).then(apiHandleResponse);
};

const updateClientBranding = (branding) => {
    return fetch(`${SERVER_URL}/api/v1/client/branding`, {
        method: METHOD_PUT,
        headers: headersAuth(),
        body: branding
    }).then(apiHandleResponse);
};

const getClientBrandingDetails = (facilityId) => {
    return fetch(facilityId ? `${SERVER_URL}/api/v1/client/branding/details/${facilityId}` 
        : `${SERVER_URL}/api/v1/client/branding/details`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getClientsCustomClaimStatus = (clientId) => {
    return fetch(`${SERVER_URL}/api/v2/client/configuration/claimstatus/${clientId}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

export {
    getClients,
    getClientsTiny,
    getClient,
    createClient,
    updateClient,
    getParsers,
    bucketAvailability,
    ftpuserAvailability,
    deactivateClient,
    activateClient,
    getConfiguration,
    createConfiguration,
    editConfiguration,
    deleteConfiguration,
    getTimezones,
    getClientProducts,
    getAccountConfiguration,
    editAccountConfiguration,
    createAccountConfiguration,
    addClaims,
    getClientBranding,
    createClientBranding,
    updateClientBranding,
    getClientBrandingDetails,
    getClientsCustomClaimStatus
};