import React from 'react';
import { FieldArray } from 'formik';
import { Grid, TextField, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles(theme => ({
    iconButton: {
        marginTop: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
    },
    textField: {
        margin: theme.spacing(1),
        width: '80%'
    },
    label: {
        margin: theme.spacing(1),
    }
}));

export default function FooterLinkArray(props) {
    const classes = useStyles();
    const { footerLinks, handleBlur, handleChange, touched, errors } = props;

    function getHelperText(key, index) {
        if(touched.footerLinks) {
            if(touched.footerLinks[index]) {
                return touched.footerLinks[index][key];
            }
            return false;
        }
        return false;
    }

    function getError(key, index) {
        if(errors.footerLinks) {
            if(errors.footerLinks[index]) {
                return errors.footerLinks[index][key];
            }
            return false;
        }
        return false;
    }
    return (
        (<FieldArray
            name="footerLinks"
            render={({ remove }) => (
                footerLinks.map((link, index) => {
                    return (
                        (<Grid container key={index}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Link Label"
                                    name={`footerLinks.${index}.label`}
                                    className={classes.textField}
                                    margin="normal"
                                    value={link.label}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    helperText={getHelperText('label', index) && getError('label', index)}
                                    error={Boolean(getHelperText('label', index) && getError('label', index))}                         
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <TextField
                                    label="Link URL"
                                    className={classes.textField}
                                    margin="normal"
                                    name={`footerLinks.${index}.url`}
                                    value={link.url}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    helperText={getHelperText('url', index) && getError('url', index)}
                                    error={Boolean(getHelperText('url', index) && getError('url', index))}   
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <IconButton
                                    edge="start"
                                    color="secondary"
                                    aria-label="Delete"
                                    onClick={() => remove(index)}
                                    className={classes.iconButton}
                                    size="large">
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>)
                    );
                })
            )}
        >
        </FieldArray>)
    );
}