import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/executions';
import * as API from '../api/executions';
import { sessionErrorHandling } from './session.js';

function* getList(action) {
    try {
        const { executionId } = action.payload;
        yield put({ type: actions.STOP_EXECUTION_PENDING });
        const payload = yield call(API.stopExecution, executionId);
        yield put({ type: actions.STOP_EXECUTION_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage, innerException, errorDetails } = (error && error.payload) || { error: '', errorDetails: '', innerException: '' };

        yield put({ type: actions.STOP_EXECUTION_REJECTED, payload: { errorMessage, innerException, errorDetails } });
        yield call(sessionErrorHandling, error);
    }
    finally {
        const { callback } = action.payload;
        if (callback) {
            callback();
        }
    }
}

function* claimExecutionCount(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_EXECUTE_COUNT_PENDING });
        const {
            payload,
            callback,
        } = action.payload;

        const response = yield call(API.getClaimExecutionCount, payload);
        yield put({ type: actions.CLAIM_ANALYSIS_EXECUTE_COUNT_FULFILLED, payload: response });

        if (callback) {
            callback(response);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_EXECUTE_COUNT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* executeClaims(action) {
    try {
        yield put({ type: actions.CLAIM_ANALYSIS_EXECUTE_PENDING });
        const {
            payload,
            callback,
        } = action.payload;

        const response = yield call(API.executeClaims, payload);
        yield put({ type: actions.CLAIM_ANALYSIS_EXECUTE_FULFILLED, payload: response });

        if (callback) {
            callback(response);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CLAIM_ANALYSIS_EXECUTE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* downloadReportById(action) {
    try {
        yield put({ type: actions.EXECUTION_DOWNLOAD_REPORT_BY_ID_PENDING });
        const {
            id,
            callback,
        } = action.payload;

        const response = yield call(API.downloadReportById, id);
        yield put({ type: actions.EXECUTION_DOWNLOAD_REPORT_BY_ID_FULFILLED });

        if (callback) {
            callback(response);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.EXECUTION_DOWNLOAD_REPORT_BY_ID_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export function* reset() {
    yield put({ type: actions.EXECUTION_RESET });
}

function* pricerStopExecution(action) {
    try {
        const { executionId } = action.payload;
        yield put({ type: actions.PRICER_STOP_EXECUTION_PENDING });
        const payload = yield call(API.pricerStopExecution, executionId);
        yield put({ type: actions.PRICER_STOP_EXECUTION_FULFILLED, payload });

    } catch (error) {
        const { error: errorMessage, innerException, errorDetails } = (error && error.payload) || { error: '', errorDetails: '', innerException: '' };

        yield put({ type: actions.PRICER_STOP_EXECUTION_REJECTED, payload: { errorMessage, innerException, errorDetails } });
        yield call(sessionErrorHandling, error);
    }
    finally {
        const { callback } = action.payload;
        if (callback) {
            callback();
        }
    }
}

function* pricerExecuteClaims(action) {
    try {
        yield put({ type: actions.PRICER_CLAIM_ANALYSIS_EXECUTE_PENDING });
        const {
            payload,
            callback,
        } = action.payload;

        const response = yield call(API.pricerExecuteClaims, payload);
        yield put({ type: actions.PRICER_CLAIM_ANALYSIS_EXECUTE_FULFILLED, payload: response });

        if (callback) {
            callback(response);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_CLAIM_ANALYSIS_EXECUTE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* pricerDownloadReportById(action) {
    try {
        yield put({ type: actions.PRICER_EXECUTION_DOWNLOAD_REPORT_BY_ID_PENDING });
        const {
            id,
            callback,
        } = action.payload;

        const response = yield call(API.pricerDownloadReportById, id);
        yield put({ type: actions.PRICER_EXECUTION_DOWNLOAD_REPORT_BY_ID_FULFILLED });

        if (callback) {
            callback(response);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.PRICER_EXECUTION_DOWNLOAD_REPORT_BY_ID_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.STOP_EXECUTION, getList);
    yield takeEvery(actions.CLAIM_ANALYSIS_EXECUTE_COUNT, claimExecutionCount);
    yield takeEvery(actions.CLAIM_ANALYSIS_EXECUTE, executeClaims);
    yield takeEvery(actions.EXECUTION_DOWNLOAD_REPORT_BY_ID, downloadReportById)
    yield takeEvery(actions.PRICER_STOP_EXECUTION, pricerStopExecution);
    yield takeEvery(actions.PRICER_CLAIM_ANALYSIS_EXECUTE, pricerExecuteClaims);
    yield takeEvery(actions.PRICER_EXECUTION_DOWNLOAD_REPORT_BY_ID, pricerDownloadReportById)
}