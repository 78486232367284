import React, { useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import TreeItem from '@mui/lab/TreeItem';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '200px',
    marginTop: 2,
  },
  icon: {
    fontSize: '10px',
    color: '#C8C8C8',
  },
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  rootNode: {
    paddingBottom: 5,
  },
  checkbox: {
    paddingTop: 2,
    paddingBottom: 2,
  },
}));

export default function CheckboxTree(props) {
  const classes = useStyles();
  const { permissionTree, selected, setSelected } = props;
  const { tree, mainNodes } = permissionTree;
  const [expanded, setExpanded] = React.useState(mainNodes);

  // console.log({selected});

  // useEffect(() => {
  //   evaluateAndSelectParent();
  // }, selected);

  // function evaluateAndSelectParent(){
  //   console.log({mainNodes});
  //   console.log({tree});
  // }

  function getChildById(node, id) {
    let array = [];

    //returns an array of nodes ids: clicked node id and all children node ids
    function getAllChild(nodes) {
      if (nodes === null) return [];
      array.push(nodes.id);
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    //returns the node object that was selected
    function getNodeById(nodes, id) {
      if (nodes.id === id) {
        return nodes;
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach((node) => {
          if (!!getNodeById(node, id)) {
            result = getNodeById(node, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, id));
  }

  function getParentById(node, id) {
    function getNodeAndParent(nodes, id, parent) {
      if (nodes.id === id) {
        return { currentNode: nodes, parent };
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach((node) => {
          if (!!getNodeAndParent(node, id)) {
            result = getNodeAndParent(node, id, nodes);
          }
        });
        return result;
      }

      return null;
    }

    return getNodeAndParent(node, id)
  }

  function getOnChange(checked, nodes) {
    //gets all freshly selected or unselected nodes
    const allNode = getChildById(tree, nodes.id);
    //combines newly selected nodes with existing selection
    //or filters out newly deselected nodes from existing selection
    let array = checked
      ? [...selected, ...allNode]
      : selected.filter((value) => !allNode.includes(value));

    // mark or unmark parent
    function markParent(nodeId) {
      const { parent } = getParentById(tree, nodeId);
      if (parent && parent.id !== 'root') {
        const siblings = parent.children.map(item => item.id);
        const filteredArr = siblings.filter(sib => array.some(item => item === sib));
        //all siblings are checked, mark parent as checked
        if (siblings.sort().toString() === filteredArr.sort().toString()) {
          array = array.filter(item => item !== parent.id).concat([parent.id]);
        } else { //any sibling is unchecked, mark parent as unchecked
          array = array.filter(item => item !== parent.id)
        }
        markParent(parent.id); // this is recursion to mark grandparent as well
      }
    }
    markParent(nodes.id);

    setSelected(array);
  }

  const renderTree = (nodes) => {
    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={
          nodes.id !== 'root' ?
            <FormControlLabel className={classes.formLabel} label={nodes.name} control={
              <Checkbox
                className={classes.checkbox}
                indeterminate={validateIndetermination(nodes.children)}
                indeterminateIcon={<IndeterminateCheckBoxIcon color='secondary' />}
                checked={selected.some((item) => item === nodes.id)}
                onChange={(event) =>
                  getOnChange(event.currentTarget.checked, nodes)
                }
              />
            } /> : <div className={classes.rootNode}>{nodes.name}</div>
        }
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    )
  }

  function validateIndetermination(children) {
    if (children) {
      if (children.every(child => selected.includes(child.id))) {
        return false
      } else if (children.find(child => selected.some(item => item === child.id))) {
        return true
      }
    }
    return false
  }

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultExpanded={expanded}
      multiSelect
    >
      {renderTree(tree)}
    </TreeView>
  );
}