import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { switchClient } from '../../../../store/actions/dashboards';
import { getClientProducts, listTiny } from '../../../../store/actions/clients';
import { selectClientListTiny, selectSelectedClient } from "../../../../store/selectors/clients";
import { selectIsSuperAdmin, selectIsPartner, selectIsPartnerUser } from "../../../../store/selectors/session";
import { set } from '../../../../store/actions/pages';
import { list } from '../../../../store/actions/settings';
import SwitchClientComponent from './SwitchClient';
import { withRouter } from "react-router-dom";
import { resetPageFilter } from '../../../../store/actions/pages';
import { resetPricerFilter } from '../../../../store/actions/pricers';

export const SwitchClient = withRouter(injectIntl(
    connect(
        (state:any) => ({
            clients: selectClientListTiny(state),
            isSuperAdmin: selectIsSuperAdmin(state),
            isPartner: selectIsPartner(state),
            isPartnerUser: selectIsPartnerUser(state),
            loading: state.dashboards.listByClientId.pending,
            selectedClient: selectSelectedClient(state),
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                switchClient: switchClient,
                clientListTiny: listTiny,
                resetPageFilter: resetPageFilter,
                resetPricerFilter: resetPricerFilter,
                getClientProducts: getClientProducts,
                resetSearchCriteria: () => set('claimAnalysis-landing', 'selectedFilter', null),
                getSettings:list,
            }, dispatch)
        })
    )(SwitchClientComponent)));