import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import PayerStatisticsPageComponent from './PayerStatisticsPage';

import { payersStatsLanding, remitPayersStatsLanding,clientFilesStatsLanding, resetPage,stlLoadErrorsLanding } from '../../store/actions/pages';
import { dateEquators, StringEquators } from '../../store/constants/pages';
import { selectPayerContracts } from '../../store/selectors/payers';
import { createNumberDataField, createPlainDateField, createStringDataField,createStatusField } from '../../util/format';
import { selectClientListTiny } from '../../store/selectors/clients';

import { getByName, reset } from '../../store/actions/search';
import { selectSessionRole } from '../../store/selectors/session';

const selectFields = createSelector(
    () => [
        createStringDataField('payerName', 'Payer Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('payerType', 'Type', { sortable: true, hideFromFilter: true }),
        createStringDataField('clientName', 'Client', { sortable: true, equators: StringEquators }),
        createNumberDataField('totalClaimsCount', 'Count of Records per Payer', { sortable: true, hideFromFilter: true }),
        createPlainDateField('minCreationDate', 'Min Date', { sortable: true, hideFromFilter: true }),
        createPlainDateField('maxCreationDate', 'Max Date', { sortable: true, hideFromFilter: true }),
        createPlainDateField('creationDate', 'Import Date', { sortable: false, equators: dateEquators, hideFromGrid: true })
    ]
);

const selectRemitFields = createSelector(
    () => [
        createStringDataField('payerName', 'Payer Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('clientName', 'Client', { sortable: true, equators: StringEquators }),
        createNumberDataField('totalClaimsCount', 'Count of Remittance Records per Payer', { sortable: true, hideFromFilter: true }),
        createPlainDateField('minCheckeftDate', 'Min Date', { sortable: true, hideFromFilter: true }),
        createPlainDateField('maxCheckeftDate', 'Max Date', { sortable: true, hideFromFilter: true }),
        createPlainDateField('checkeftDate', 'Import Date', { sortable: false, equators: dateEquators, hideFromGrid: true })
    ]
);

const selectClientFilesFields = createSelector(
    () => [
        createStringDataField('fileName', 'File Name', { sortable: true, equators: StringEquators }),
        createStringDataField('state', 'Status', { sortable: false,hideFromFilter:true}),
        createPlainDateField('processingStartAt', 'Timestamp', { sortable: false,hideFromFilter:true}),
    ]
);

const selectSTLErrorFields = createSelector(
    () => [
        createPlainDateField('startTime', 'Start Time', { sortable: false, equators: dateEquators }),
        createStringDataField('rawFieldValue', 'Raw Field Value', { sortable: true, equators: StringEquators }),
        createStringDataField('errReason', 'Error', { sortable: true, equators: StringEquators}),

    ]
);

const selectPayersData = createSelector(
    (state) => state.pages['payers-stats-landing'].list,
    (data) => data
);

const selectRemitPayersData = createSelector(
    (state) => state.pages['remit-payers-stats-landing'].list,
    (data) => data
);
const selectClientFilesData = createSelector(
    (state) => state.pages['client-files-stats-landing'].list,
    (data) => data
);

const selectStlErrorsData = createSelector(
    (state) => state.pages['stl-load-errors-landing'].list,
    (data) => data
);


const selectSelectedClient = createSelector(
    selectClientListTiny,
    (state) => state.dashboards.selectedClient,
    (clients: any, selectedClient: number) =>
        clients?.find((item) => item?.clientId === selectedClient)?.clientId
);

export const PayerStatisticsPage = connect(
    () => {
        return (state, props) => ({
            payerListPending: state.payers.list.pending,
            clientFilesPending: state.pages["client-files-stats-landing"].pending,
            stlErrorsPending: state.pages["stl-load-errors-landing"].pending,
            payerData: selectPayersData(state),
            remitPayerData: selectRemitPayersData(state),
            clients: selectClientListTiny(state),
            clientFilesData: selectClientFilesData(state),
            payerFields: selectFields(state as never),
            remitPayerFields: selectRemitFields(state as never),
            clientFilesFields: selectClientFilesFields(state as never),
            stlErrorFields: selectSTLErrorFields(state as never),
            stlErrorsData: selectStlErrorsData(state),
            payerContracts: selectPayerContracts(state),
            userRole: selectSessionRole(state),
            selectedClient:selectSelectedClient(state)

        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            payersStatsLanding: payersStatsLanding,
            clientFilesStatsLanding,
            stlLoadErrorsLanding,
            remitPayersStatsLanding: remitPayersStatsLanding,
            searchGetByName: getByName,
            searchReset: reset,
            resetPagedTable: resetPage
        }, dispatch)
    })
)(injectIntl(PayerStatisticsPageComponent));