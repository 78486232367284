import React from 'react';
import DateRangeControl from './DateRangeControl';
import { PurePtr } from '@pure-ptr/react';

export { default as DateRangeControl } from './DateRangeControl';
import DateRangeIcon from '@mui/icons-material/DateRange';

export type DateRange = {
    startdate: string;
    enddate: string;
};

export const DateRangePicker = ({ valuePtr, label, disabled, variant = "standard"} :{
    valuePtr : PurePtr<DateRange | null>,
    label : string,
    disabled? : boolean,
    variant? : "outlined" | "filled" | "standard"
}) => {
    const { value } = valuePtr;

    return (
        <DateRangeControl 
            setDates={(startdate, enddate, enabled, customField, relativeFilter ) => {
                valuePtr.set( enabled ? { startdate, enddate } : null);
            }} 
            
            style={{
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
            }}

            refresh={function (): void {
            } } 

            variant={variant}
            size="small"
            icon={<DateRangeIcon style={{ alignSelf: 'baseline'}}/>}
            
            filterName={label} 
            
            fields={[{
                value: 'date',
                label,
            }]} 
            
            customFilter={ value ? [
                { 
                    equator: 'greater_than_or_equals_to',
                    field: 'date',
                    value: value.startdate ?? '',
                },
                { 
                    equator: 'less_than_or_equals_to',
                    field: 'date',
                    value: value.enddate ?? '',
                },
            ] : []} 
            
            hasCustomField={true} 
            
            relativeFilter={''} 
            
            relativeFilterValue={''} 
            
            setRelativeFilter={function (value: string): void {
            } } 
            
            disabled={ disabled }        
        />
    );
};